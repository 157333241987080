import { postValidateMobileToken } from '@mobile/api';
import { AuthError } from '@mobile/api/customError';
import { mobileTokenStorage, telepharmcyStorage } from '@mobile/utils';
import { useMutation } from '@tanstack/react-query';

import { useNavigate } from 'react-router-dom';

const useValidateMobileToken = () => {
  const navigate = useNavigate();

  const validateMobileToken = (mobileAccessToken: string) => {
    return postValidateMobileToken({ mobileToken: mobileAccessToken });
  };

  return useMutation({
    mutationFn: validateMobileToken,
    onSuccess: (data) => {
      mobileTokenStorage.setAccessToken(data.token);
      telepharmcyStorage.setTelepharmacyId(data.pharmacy_id);

      return navigate(`/mobile/request/detail/${data.tpp_id}`, {
        state: { prescriptionId: data.prescription_id },
      });
    },
    onError: (error) => {
      if (error instanceof AuthError) {
        return navigate('/mobile/auth-error');
      }

      return navigate('/mobile/error');
    },
  });
};

export default useValidateMobileToken;
