export const COMMON_PATHNAME = {
  LINK_GATE: '/linkGate',
  LOGIN: '/login',
  NEW_RESERVATION: '/home/newReservation',
  CONFIRMED_TREATMENT: '/home/confirmedTreatment',
  COMPLETE_TREATMENT: '/home/completeTreatment',
  COMPLETE_TREATMENT_DETAIL: '/home/completeTreatment/completeTreatmentDetail',
  TIME_MANAGEMENT: '/home/timeManagement',
};

export const DOCTOR_ADMIN_PATHNAME = {
  FINISH_TELEMEDICINE: '/home/confirmedTreatment/finishTelemedicine',
};

export const PHARMACIST_ADMIN_PATHNAME = {
  NEW_RESERVATION_REQUESTED: '/home/newReservation/requested',
  NEW_RESERVATION_ACCEPTED: '/home/newReservation/accepted',
  NEW_RESERVATION_ACCEPTED_DETAIL:
    '/home/newReservation/accepted/newReservationAcceptedDetail',
  CONFIRMED_TREATMENT_CONFIRMED: '/home/confirmedTreatment/confirmed',
  CONFIRMED_TREATMENT_CONFIRMED_DETAIL:
    '/home/confirmedTreatment/confirmed/confirmedTreatmentConfirmedDetail',
  CONFIRMED_TREATMENT_ONGOING_QUICK: '/home/confirmedTreatment/ongoing-quick',
  CONFIRMED_TREATMENT_ONGOING_QUICK_DETAIL:
    '/home/confirmedTreatment/ongoing-quick/confirmedTreatmentOngoingQuickDetail',
  CONFIRMED_TREATMENT_ONGOING_PARCEL: '/home/confirmedTreatment/ongoing-parcel',
  CONFIRMED_TREATMENT_ONGOING_PARCEL_DETAIL:
    '/home/confirmedTreatment/ongoing-parcel/confirmedTreatmentOngoingParcelDetail',
};

export const MARKETING_ADMIN_PATHNAME = {
  SEND_FCM: '/home/sendFCM',
};
