import { Font, TextInput } from '@components/atoms';
import { memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  EFindIdPasswordFormFields,
  IFindIdPasswordFormData,
} from '../../hooks/find-id-password-form/find-id-password-form.type';

type IFindIdPasswordFormViewProps = {
  formattedTime: string;
  shouldTriggerTimer: boolean;
};

export const FindIdPasswordFormView = memo<IFindIdPasswordFormViewProps>(
  ({ formattedTime, shouldTriggerTimer }) => {
    const { control, formState } = useFormContext<IFindIdPasswordFormData>();

    const errors = formState.errors?.[EFindIdPasswordFormFields.PHONE_NUMBER];
    const errorMessage =
      formState?.errors?.[EFindIdPasswordFormFields.PHONE_NUMBER]?.message;

    return (
      <Controller
        control={control}
        name={EFindIdPasswordFormFields.PHONE_NUMBER}
        render={({ field: { onChange, value } }) => {
          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 8,
                paddingBottom: 20,
              }}
            >
              <TextInput
                value={value}
                onChange={onChange}
                placeholder="휴대폰 번호를 입력해주세요"
                type="text"
                fontSize={16}
                title="휴대폰 번호"
                errorMessage={errorMessage}
                disabled={shouldTriggerTimer}
              />
              {errors && value.length > 0 && (
                <div style={{ paddingLeft: 20 }}>
                  <Font fontType="body2" color="state/distructive">
                    {errorMessage}
                  </Font>
                </div>
              )}
              {shouldTriggerTimer && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: 8,
                  }}
                >
                  <Font fontType="body2" color="state/distructive">
                    {formattedTime}
                  </Font>
                  <Font fontType="body1" color="border/outline">
                    |
                  </Font>
                  <Font fontType="body2" color="fill/medium">
                    제한시간 만료, 메시지를 다시 보내주세요
                  </Font>
                </div>
              )}
            </div>
          );
        }}
      />
    );
  },
);
