import { Response } from '@constants/apis/types';
import fetchAPI from '@utils/fetchAPI';
import { TelepharmacyItemType } from 'types/telepharmacyType';

const MY_DOCTOR_TYPE = 'myDoctor';

export const getTelepharmacyItem = (
  tpprocessId: number,
): Promise<Response<TelepharmacyItemType>> => {
  return fetchAPI.get(
    `pharmacist-api/telepharmacy/tpprocess/${tpprocessId}`,
    {},
    MY_DOCTOR_TYPE,
  );
};
