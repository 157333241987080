import styled from 'styled-components';
import { COLOR } from '../color';

const BoxContainer = styled.div<{
  width?: number | string;
  minWidth?: number;
  maxWidth?: number;
  height?: number | string;
  minHeight?: number;
  maxHeight?: number;
}>`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => (typeof width === 'number' ? `${width}px` : width)};
  min-width: ${({ minWidth, width }) => (minWidth ? `${minWidth}px` : width)};
  max-width: ${({ maxWidth, width }) => (maxWidth ? `${maxWidth}px` : width)};
  height: ${({ height }) =>
    typeof height === 'number' ? `${height}px` : height};
  min-height: ${({ minHeight, height }) =>
    minHeight ? `${minHeight}px` : height};
  max-height: ${({ maxHeight, height }) =>
    maxHeight ? `${maxHeight}px` : height};
  background-color: ${COLOR['fill/white']};
  border-radius: 16px;
  padding: 20px;
`;

export default BoxContainer;
