import { Button, COLOR, Font, ToggleButton } from '@components/atoms';
import { formatters } from '@functions';
import { commonHooks } from '@hooks';
import useMedicineManagementCommonModalHook from '@pages/wrapper/modals/medicineManagmentCommonModal/hooks';
import useMedicineUnitManagementEditModal from '@pages/wrapper/modals/medicineUnitManagementEditModal/hooks';
import { GetManagedMedicineItemType } from '@services/myDoctor/types';
import { useState } from 'react';

import SvgIcon from '@components/atoms/images';
import {
  findCompoundingFee,
  medicineGenericCodeKoreanUnit,
} from '@components/organisms/managementMedicineTable/config/domain';
import { isNil } from 'lodash';
import { LISTITEM_FLEX } from '../../index';
import { ListContent } from '../index';
import * as Style from './index.style';

function ListItem({ item }: { item: GetManagedMedicineItemType }) {
  const { openModal: openEditModal, setManagementMedicineUnitTargetMedicine } =
    useMedicineUnitManagementEditModal();
  const {
    openModal: openMedicineSoldOutConfirmModal,
    setManagementMedicineModalType,
    setManagementMedicineTargetMedicine,
  } = useMedicineManagementCommonModalHook();
  const { useQuery } = commonHooks;

  const [isOpenAccordion, setIsOpenAccordion] = useState(false);
  const { priceFormatter } = formatters;

  const { pharmacy_medicine, unit, quantity, price, extra } = item;
  const {
    generic_name_code,
    is_active,
    standard_code,
    standard_code_display,
    master_code,
    name,
  } = pharmacy_medicine;
  const genericNameCodeCompoundingFee = findCompoundingFee(generic_name_code);

  const handleClickChangeAutoOrderStatus = () => {
    if (is_active) {
      setManagementMedicineModalType('soldOut');
      setManagementMedicineTargetMedicine(item);
      openMedicineSoldOutConfirmModal();
    } else {
      setManagementMedicineModalType('cancelSoldOut');
      setManagementMedicineTargetMedicine(item);
      openMedicineSoldOutConfirmModal();
    }
  };

  const handleClickEditManagedMedicine = () => {
    openEditModal();

    setManagementMedicineUnitTargetMedicine(item);
  };

  const handleClickDeleteManagedMedicine = () => {
    setManagementMedicineModalType('delete');
    setManagementMedicineTargetMedicine(item);
    openMedicineSoldOutConfirmModal();
  };

  const handleToggleMedicineExtraInfo = () => {
    setIsOpenAccordion((prev) => !prev);
  };

  return (
    <>
      <Style.ListItem onClick={handleToggleMedicineExtraInfo}>
        <ListContent.Element flex={LISTITEM_FLEX[0].flex} isPointer>
          <Style.AccordionToggleButtonArea isOpenAccordion={isOpenAccordion}>
            <SvgIcon icon="chevronDown" width={20} color="fill/dark" />
          </Style.AccordionToggleButtonArea>
        </ListContent.Element>

        <ListContent.Regular flex={LISTITEM_FLEX[1].flex} isPointer>
          <Font fontType="body2_medium" color="fill/black">
            {master_code}
          </Font>
        </ListContent.Regular>

        <ListContent.Regular flex={LISTITEM_FLEX[2].flex} isPointer>
          {(
            <Font fontType="body2_medium" color="fill/black">
              {name && name.length > 16
                ? `${name?.slice(0, 14)}...`
                : name || '-'}
            </Font>
          ) || (
            <Font fontType="body3_medium" color="state/distructive">
              {`의약품명이 비어 있습니다.\n삭제 후 다시 등록해 주세요.`}
            </Font>
          )}
        </ListContent.Regular>

        <ListContent.Regular flex={LISTITEM_FLEX[3].flex} isPointer>
          <Style.AccordionTextArea>
            <Font fontType="body2_medium" color="fill/dark">
              {priceFormatter.commaFormatter(String(price))}원
            </Font>
            <Font fontType="body3" color="fill/medium">
              {quantity}
              {medicineGenericCodeKoreanUnit[generic_name_code]}당
            </Font>
          </Style.AccordionTextArea>
        </ListContent.Regular>

        <ListContent.Regular flex={LISTITEM_FLEX[4].flex} isPointer>
          <Style.AccordionTextArea>
            <Font fontType="body2_medium" color="fill/dark">
              {extra.preparation_fee && extra.preparation_fee[0]
                ? `${priceFormatter.commaFormatter(
                    String(extra.preparation_fee[0].price),
                  )}원`
                : '-'}
            </Font>
            <Font fontType="body3" color="fill/medium">
              {extra.preparation_fee && extra.preparation_fee[0]
                ? `${extra.preparation_fee[0].quantity}일`
                : '조제료 없음'}
            </Font>
          </Style.AccordionTextArea>
        </ListContent.Regular>

        <ListContent.Regular flex={LISTITEM_FLEX[5].flex} isPointer>
          <Style.AccordionTextArea>
            <Font fontType="body2_medium" color="fill/dark">
              {extra.preparation_fee
                ? extra.preparation_fee[0]
                  ? `${priceFormatter.commaFormatter(
                      String(
                        Number(price) *
                          1 *
                          1 *
                          extra.preparation_fee[0].quantity +
                          (extra.preparation_fee[0].price || 0),
                      ),
                    )}원`
                  : '-'
                : `${
                    genericNameCodeCompoundingFee &&
                    priceFormatter.commaFormatter(
                      String(price * genericNameCodeCompoundingFee[0].quantity),
                    )
                  }원`}
            </Font>
            <Font fontType="body3" color="fill/medium">
              {extra.preparation_fee
                ? extra.preparation_fee[0]
                  ? `${extra.preparation_fee[0].quantity}${medicineGenericCodeKoreanUnit[generic_name_code]} (1/1/${extra.preparation_fee[0].quantity})`
                  : ''
                : `${quantity}${
                    medicineGenericCodeKoreanUnit[generic_name_code]
                  } ${
                    genericNameCodeCompoundingFee &&
                    `(1/1/${genericNameCodeCompoundingFee[0].quantity})`
                  }`}
            </Font>
          </Style.AccordionTextArea>
        </ListContent.Regular>

        <ListContent.Element flex={LISTITEM_FLEX[6].flex} isPointer>
          <ToggleButton
            isActive={is_active}
            onClickToggle={(e) => {
              e?.stopPropagation();
              handleClickChangeAutoOrderStatus();
            }}
          />
        </ListContent.Element>

        <ListContent.Element flex={LISTITEM_FLEX[7].flex} isPointer>
          <Button
            width="60px"
            height="32px"
            backgroundColor={COLOR['label/lightBlue']}
            title={
              <Font fontType="body2_medium" color="fill/accent">
                수정
              </Font>
            }
            onClick={(e) => {
              e.stopPropagation();
              handleClickEditManagedMedicine();
            }}
          />
          <Button
            width="60px"
            height="32px"
            backgroundColor={COLOR['fill/white']}
            borderColor={COLOR['border/outline']}
            title={
              <Font fontType="body2" color="fill/dark">
                삭제
              </Font>
            }
            onClick={(e) => {
              e.stopPropagation();
              handleClickDeleteManagedMedicine();
            }}
          />
        </ListContent.Element>
      </Style.ListItem>

      <Style.ManagementMedicineExtraInfoContainer
        isOpenAccordion={isOpenAccordion}
        accordionHeight={
          extra.preparation_fee
            ? `${extra.preparation_fee.length * 30 + 80}px`
            : genericNameCodeCompoundingFee
            ? `${genericNameCodeCompoundingFee.length * 30 + 80}px`
            : 'fit-content'
        }
      >
        <Style.ManagementMedicineExtraInfoArea>
          <Style.ManagementMedicineExtraInfoTitleArea>
            <Style.ManagementMedicineExtraInfoTitle>
              <Font fontType="h5" color="fill/black">
                약가
              </Font>
            </Style.ManagementMedicineExtraInfoTitle>

            <Style.ManagementMedicineExtraInfoTitle>
              <Font fontType="h5" color="fill/black">
                조제료
              </Font>
            </Style.ManagementMedicineExtraInfoTitle>

            <Style.ManagementMedicineExtraInfoResultTitleArea>
              <Font fontType="h5" color="fill/black">
                예상결제액
              </Font>
            </Style.ManagementMedicineExtraInfoResultTitleArea>
          </Style.ManagementMedicineExtraInfoTitleArea>

          <Style.ManagementMedicineExtraInfoDivider />

          <Style.ManagementMedicineExtraInfoContentArea>
            <Style.ManagementMedicineExtraInfoRowTextArea>
              <Style.ManagementMedicineExtraUnitText>
                <Font fontType="body2" color="fill/dark">
                  {pharmacy_medicine.generic_name_code
                    ? `${quantity}${
                        medicineGenericCodeKoreanUnit[
                          pharmacy_medicine.generic_name_code
                        ]
                      }당`
                    : null}
                </Font>
              </Style.ManagementMedicineExtraUnitText>
              <Font fontType="body2_medium" color="fill/dark">
                {priceFormatter.commaFormatter(String(price))}원
              </Font>
            </Style.ManagementMedicineExtraInfoRowTextArea>

            <Style.ManagementMedicineExtraInfoTextArea>
              {extra.preparation_fee
                ? extra.preparation_fee.map((preparationFee) => {
                    return (
                      <Style.ManagementMedicineExtraInfoRowTextArea
                        key={preparationFee.quantity}
                      >
                        <Style.ManagementMedicineExtraUnitText>
                          <Font fontType="body2" color="fill/dark">
                            {preparationFee.quantity}일
                          </Font>
                        </Style.ManagementMedicineExtraUnitText>
                        <Font fontType="body2_medium" color="fill/black">
                          {!isNil(preparationFee.price)
                            ? priceFormatter.commaFormatter(
                                String(preparationFee.price),
                              )
                            : '-'}
                          원
                        </Font>
                      </Style.ManagementMedicineExtraInfoRowTextArea>
                    );
                  })
                : genericNameCodeCompoundingFee &&
                  genericNameCodeCompoundingFee.map((preparationFee) => {
                    return (
                      <Style.ManagementMedicineExtraInfoRowTextArea
                        key={preparationFee.quantity}
                      >
                        <Style.ManagementMedicineExtraUnitText>
                          <Font fontType="body2" color="fill/dark">
                            {preparationFee.quantity}일
                          </Font>
                        </Style.ManagementMedicineExtraUnitText>
                        <Font fontType="body2_medium" color="fill/black">
                          {!isNil(preparationFee.price)
                            ? priceFormatter.commaFormatter(
                                String(preparationFee.price),
                              )
                            : '-'}
                          원
                        </Font>
                      </Style.ManagementMedicineExtraInfoRowTextArea>
                    );
                  })}
            </Style.ManagementMedicineExtraInfoTextArea>

            <Style.ManagementMedicineExtraInfoResultTitleArea>
              {extra.preparation_fee
                ? extra.preparation_fee.map((preparationFee) => {
                    const totalPrice =
                      Number(price) * 1 * 1 * preparationFee.quantity +
                      (preparationFee.price || 0);

                    return (
                      <Style.ManagementMedicineExtraInfoResultTextArea
                        key={preparationFee.quantity}
                      >
                        <Font fontType="body2" color="fill/dark">
                          {preparationFee.quantity}
                          {
                            medicineGenericCodeKoreanUnit[
                              pharmacy_medicine.generic_name_code
                            ]
                          }
                          (1/1/
                          {preparationFee.quantity})
                        </Font>
                        <Font fontType="body2_medium" color="fill/black">
                          {priceFormatter.commaFormatter(String(totalPrice))}원
                        </Font>
                        <Font fontType="body2" color="fill/medium">
                          {`총 약가(${priceFormatter.commaFormatter(
                            String(price),
                          )} * 1 * 1 * ${preparationFee.quantity}) ${
                            isNil(preparationFee.price)
                              ? ''
                              : `+ 조제료(${priceFormatter.commaFormatter(
                                  String(preparationFee.price),
                                )})`
                          }`}
                        </Font>
                      </Style.ManagementMedicineExtraInfoResultTextArea>
                    );
                  })
                : genericNameCodeCompoundingFee &&
                  genericNameCodeCompoundingFee.map((preparationFee) => {
                    const totalPrice =
                      Number(price) * 1 * 1 * preparationFee.quantity;

                    return (
                      <Style.ManagementMedicineExtraInfoResultTextArea
                        key={preparationFee.quantity}
                      >
                        <Font fontType="body2" color="fill/dark">
                          {preparationFee.quantity}
                          {
                            medicineGenericCodeKoreanUnit[
                              pharmacy_medicine.generic_name_code
                            ]
                          }
                          (1/1/
                          {preparationFee.quantity})
                        </Font>
                        <Font fontType="body2_medium" color="fill/black">
                          {priceFormatter.commaFormatter(String(totalPrice))}원
                        </Font>
                        <Font fontType="body2" color="fill/medium">
                          {`총 약가(${priceFormatter.commaFormatter(
                            String(price),
                          )} * 1 * 1 * ${preparationFee.quantity})`}
                        </Font>
                      </Style.ManagementMedicineExtraInfoResultTextArea>
                    );
                  })}
            </Style.ManagementMedicineExtraInfoResultTitleArea>
          </Style.ManagementMedicineExtraInfoContentArea>
        </Style.ManagementMedicineExtraInfoArea>
      </Style.ManagementMedicineExtraInfoContainer>
    </>
  );
}

export default ListItem;
