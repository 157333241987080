import { ReactNode } from 'react';
import * as Style from './index.style';

function CompleteTreatmentPage({ children }: { children: ReactNode }) {
  return (
    <Style.Container>
      <Style.CardArea>{children}</Style.CardArea>
    </Style.Container>
  );
}

export default CompleteTreatmentPage;
