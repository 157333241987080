import { useState } from 'react';
import { useTimer } from 'use-timer';

export const useFindIdPasswordTimer = () => {
  const [shouldTriggerTimer, setShouldTriggerTimer] = useState(false);
  const startTimer = () => {
    setShouldTriggerTimer(true);
  };
  const { time, reset } = useTimer({
    timerType: 'DECREMENTAL',
    endTime: 0,
    initialTime: 60 * 5,
    autostart: shouldTriggerTimer,
  });

  const resetTimer = () => {
    setShouldTriggerTimer(false);
    reset();
  };

  const formattedTime = formatTime(time);

  return { formattedTime, startTimer, shouldTriggerTimer, resetTimer };
};
function formatTime(seconds: number) {
  const minutes = Math.floor(seconds / 60); // 초를 분으로 변환
  const remainingSeconds = seconds % 60; // 남은 초 계산

  // 초가 두 자리가 되도록 앞에 0을 붙임
  const formattedSeconds =
    remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds;

  return `${minutes}:${formattedSeconds}`;
}
