import {
  coldChainBlackImage,
  deliveryMethodParcel,
  deliveryMethodPickup,
  deliveryMethodQuick,
} from '@assets';
import { Button, COLOR, Font } from '@components/atoms';
import SvgIcon from '@components/atoms/images';
import { commonCode } from '@constants';
import { useInterval } from '@hooks/commonHooks';
import { myDoctorAPI } from '@services';
import { calcAutorejectMinute } from '@utils/timeCalc';
import { isNil } from 'lodash';
import moment from 'moment-timezone';
import printJS from 'print-js';
import { Circle } from 'rc-progress';
import { memo, MouseEvent, useEffect, useState } from 'react';
import { useTpProgressItem } from '../telepharmacyDetailInformation/hooks';
import { TelepharmacyListItemType } from './index.d';
import * as Style from './index.style';

const { COMMON_CODE } = commonCode;

function TelepharmacyListItem({
  isSelected,
  item,
  onClick,
}: TelepharmacyListItemType) {
  const { data: tpProgressItem } = useTpProgressItem({
    telepharmacyId: item.id ? String(item.id) : null,
  });

  const [autoRejectMinute, setAutorejectMinute] = useState<number>(
    calcAutorejectMinute(item),
  );

  useInterval(() => {
    setAutorejectMinute(calcAutorejectMinute(item));
  }, 5000);

  let iconURL;
  switch (item.method) {
    case COMMON_CODE.TP_METHOD.PARCEL:
      iconURL = deliveryMethodParcel;
      break;
    case COMMON_CODE.TP_METHOD.PICKUP:
      iconURL = deliveryMethodPickup;
      break;
    case COMMON_CODE.TP_METHOD.QUICK:
    case COMMON_CODE.TP_METHOD.ECONOMY_QUICK:
      iconURL = deliveryMethodQuick;
      break;
    case COMMON_CODE.TP_METHOD.COLD_CHAIN:
      iconURL = coldChainBlackImage;
      break;
    default:
      iconURL = deliveryMethodPickup;
      break;
  }

  const fetchPrescriptionData = async (tp_id: number | undefined) => {
    const { data } = await myDoctorAPI.getTelepharmacyTpprocessPrescriptions(
      Number(tp_id),
    );
    return data;
  };

  return (
    <Style.Container isSelected={isSelected} onClick={() => onClick()}>
      <Style.TopArea>
        <Font fontType="body2" color={isSelected ? 'blue500' : 'fill/medium'}>
          {autoRejectMinute <= 0 ? 0 : autoRejectMinute}분 후 만료 예정
        </Font>
        <Font fontType="body2" color={isSelected ? 'blue500' : 'fill/medium'}>
          {item.telemedicine?.telemedicine_hash}
        </Font>
      </Style.TopArea>

      <Style.MiddleArea>
        <MemoReservationProgress
          iconURL={iconURL}
          autoRejectTime={item.auto_reject_time}
          requestTime={item.requested_time}
          isSelected={isSelected}
        />
        <Style.MiddleTextArea>
          <Font fontType="h3" color={'fill/black'}>
            {item.patient?.name} 환자
          </Font>
          {tpProgressItem?.tm?.symptom_keyword_history && (
            <Style.SymptomContainer isSelected={isSelected}>
              <Font
                fontType="body3_medium"
                color={isSelected ? 'fill/white' : 'greyscale6'}
              >
                {
                  tpProgressItem?.tm?.symptom_keyword_history[0]
                    ?.symptom_keyword?.name
                }
              </Font>
            </Style.SymptomContainer>
          )}
        </Style.MiddleTextArea>

        <Style.PrescriptionButtonArea>
          {item.has_subscription && (
            <Button
              onClick={async (event: MouseEvent<HTMLButtonElement>) => {
                event.stopPropagation();
                event.preventDefault();
                const prescriptionData = await fetchPrescriptionData(item.id);
                printJS({
                  printable: prescriptionData[0].image,
                  type: 'pdf',
                  showModal: true,
                });
              }}
              width="68px"
              height="32px"
              padding="5px 12px 5px 9px"
              borderColor={COLOR['border/outline']}
              backgroundColor={COLOR['fill/white']}
              borderRadius="6px"
              title={
                <Style.PrintButtonContainer>
                  <SvgIcon icon="faxIcon" color="fill/dark" width={16} />
                  <Font fontType="body2" color="fill/dark">
                    인쇄
                  </Font>
                </Style.PrintButtonContainer>
              }
            />
          )}
        </Style.PrescriptionButtonArea>
      </Style.MiddleArea>
    </Style.Container>
  );
}

export default TelepharmacyListItem;

function ReservationProgress({
  iconURL,
  autoRejectTime,
  requestTime,
  isSelected,
}: {
  iconURL: string;
  autoRejectTime: string | undefined;
  requestTime: string | undefined;
  isSelected: boolean;
}) {
  const [progress, setProgress] = useState(360);

  useEffect(() => {
    if (!isNil(autoRejectTime) && !isNil(requestTime)) {
      const momentRequestTime = moment.tz(requestTime, 'Asia/Seoul');
      const endTime = moment.tz(autoRejectTime, 'Asia/Seoul');

      const totalDuration = endTime.diff(momentRequestTime, 'second');

      const updateProgress = () => {
        const now = moment.tz('Asia/Seoul');
        const elapsed = endTime.diff(now, 'second');
        const newProgress = Math.max(
          (elapsed / totalDuration) * 100,
          0,
        ).toFixed(2);

        const editProgress =
          Number(newProgress) > 100 ? 100 : Number(newProgress);

        setProgress(Number(editProgress));
      };

      updateProgress();
      const interval = setInterval(updateProgress, 1000);

      return () => clearInterval(interval);
    }
  }, [autoRejectTime, requestTime]);

  const isAllowExpired = progress <= 50;

  return (
    <Style.TimerArea isSelected={isSelected}>
      <Style.DeliveryImage src={iconURL} alt="DeliveryImage" />
      <Circle
        percent={progress}
        strokeWidth={6}
        strokeColor={
          isAllowExpired ? COLOR['state/distructive'] : COLOR['fill/accent']
        }
        trailWidth={6}
        trailColor={COLOR['border/outline']}
        gapPosition="bottom"
        style={{
          width: '48px',
          height: '48px',
          transform: 'scaleX(-1)',
        }}
      />
    </Style.TimerArea>
  );
}

const MemoReservationProgress = memo(ReservationProgress);
