import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '@stores';
import { GetReadyModalType } from './index.d';

const initialState: GetReadyModalType = {
  isVisible: false,
  title: '',
};

export const getReadyModal = createSlice({
  name: 'modal/getReadyModal',
  initialState,
  reducers: {
    openModal_getReadyModal: (state, action) => {
      state.isVisible = true;
      state.title = action.payload.title;
    },
    closeModal_getReadyModal: (state) => {
      state.isVisible = false;
    },
  },
});

export const { openModal_getReadyModal, closeModal_getReadyModal } =
  getReadyModal.actions;

export const selectGetReadyModalVisible = (state: RootState) =>
  state.getReadyModal.isVisible;
export const selectGetReadyModalTitle = (state: RootState) =>
  state.getReadyModal.title;

export default getReadyModal.reducer;
