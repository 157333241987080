import { IconDetailProps } from '@components/atoms/images';
import { COLOR } from '../color';

function HistoryIcon({ width, height, color }: IconDetailProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 17 16"
      fill="none"
    >
      <path
        d="M6.08333 4.00002C5.71514 4.00002 5.41667 4.2985 5.41667 4.66669C5.41667 5.03488 5.71514 5.33335 6.08333 5.33335L11.4167 5.33335C11.7849 5.33335 12.0833 5.03488 12.0833 4.66669C12.0833 4.2985 11.7849 4.00002 11.4167 4.00002L6.08333 4.00002Z"
        fill={COLOR[color]}
      />
      <path
        d="M5.41667 7.33335C5.41667 6.96516 5.71514 6.66669 6.08333 6.66669L11.4167 6.66669C11.7849 6.66669 12.0833 6.96516 12.0833 7.33335C12.0833 7.70154 11.7849 8.00002 11.4167 8.00002L6.08333 8.00002C5.71514 8.00002 5.41667 7.70154 5.41667 7.33335Z"
        fill={COLOR[color]}
      />
      <path
        d="M6.08333 9.33335C5.71514 9.33335 5.41667 9.63183 5.41667 10C5.41667 10.3682 5.71514 10.6667 6.08333 10.6667H9.41667C9.78486 10.6667 10.0833 10.3682 10.0833 10C10.0833 9.63183 9.78486 9.33335 9.41667 9.33335H6.08333Z"
        fill={COLOR[color]}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.75 2.66669C2.75 1.56212 3.64543 0.666687 4.75 0.666687H12.75C13.8546 0.666687 14.75 1.56212 14.75 2.66669V13.3334C14.75 14.4379 13.8546 15.3334 12.75 15.3334H4.75C3.64543 15.3334 2.75 14.4379 2.75 13.3334V2.66669ZM4.75 2.00002H12.75C13.1182 2.00002 13.4167 2.2985 13.4167 2.66669V13.3334C13.4167 13.7015 13.1182 14 12.75 14H4.75C4.38181 14 4.08333 13.7015 4.08333 13.3334V2.66669C4.08333 2.2985 4.38181 2.00002 4.75 2.00002Z"
        fill={COLOR[color]}
      />
    </svg>
  );
}

export default HistoryIcon;
