import { IconDetailProps } from '@components/atoms/images';
import { COLOR } from '../color';

export default function DuplicateIcon({
  width,
  height,
  color,
}: IconDetailProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 12 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.33333 5.33301V13.333C1.33333 13.7012 1.63181 13.9997 2 13.9997H7.33333C7.70152 13.9997 8 13.7012 8 13.333H4C2.89543 13.333 2 12.4376 2 11.333V4.66634C1.63181 4.66634 1.33333 4.96482 1.33333 5.33301ZM7.33333 15.333H2C0.895431 15.333 0 14.4376 0 13.333V5.33301C0 4.22844 0.895431 3.33301 2 3.33301H3.33333V11.333C3.33333 11.7012 3.63181 11.9997 4 11.9997H9.33333V13.333C9.33333 14.4376 8.4379 15.333 7.33333 15.333Z"
        fill={COLOR[color]}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 1.99984H4C3.63181 1.99984 3.33333 2.29831 3.33333 2.6665V11.3332C3.33333 11.7014 3.63181 11.9998 4 11.9998H10C10.3682 11.9998 10.6667 11.7014 10.6667 11.3332V2.6665C10.6667 2.29831 10.3682 1.99984 10 1.99984ZM4 0.666504C2.89543 0.666504 2 1.56193 2 2.6665V11.3332C2 12.4377 2.89543 13.3332 4 13.3332H10C11.1046 13.3332 12 12.4377 12 11.3332V2.6665C12 1.56193 11.1046 0.666504 10 0.666504H4Z"
        fill={COLOR[color]}
      />
    </svg>
  );
}
