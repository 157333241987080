import { useState } from 'react';

export enum ELoginType {
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
}

export const useSelectLoginType = () => {
  const [selectedLoginType, setSelectedLoginType] = useState<ELoginType>(
    ELoginType.EMAIL,
  );
  const updateLoginType = (loginType: ELoginType) => {
    setSelectedLoginType(loginType);
  };
  return { updateLoginType, selectedLoginType };
};
