import { commonHooks } from '@hooks';
import { myDoctorAPI } from '@services';
import { useAppDispatch, useAppSelector } from '@stores/hooks';
import {
  closeModal_modifyMedicineGuideModal,
  selectModifyMedicineGuideModalItem,
  selectModifyMedicineGuideModalVisible,
} from '@stores/modalStore/modifyMedicineGuideModal';
import { getTelepharmacyCount } from '@stores/telepharmacyStore/telepharmacyCount';
import { FormEvent, useCallback, useEffect, useState } from 'react';

const useModifyMedicineGuideModalHook = () => {
  const dispatch = useAppDispatch();
  const { useInput } = commonHooks;
  const isVisible = useAppSelector(selectModifyMedicineGuideModalVisible);
  const item = useAppSelector(selectModifyMedicineGuideModalItem);
  const initialMedicineGuide = item.medicine_guide;
  const [isPageLoading, setIsPageLoading] = useState(false);

  // 약제 종류
  const [internal, setInternal] = useState(false);
  const [external, setExternal] = useState(false);
  const [injection, setInjection] = useState(false);

  // 투약 일수
  const [medicine_day_num, onChangeMedicine_day_num, setMedicine_day_num] =
    useInput('');
  const [
    isMedicine_day_num_not_decided_yet,
    setIsMedicine_day_num_not_decided_yet,
  ] = useState(false);

  // 투약 횟수
  const [morning, setMorning] = useState(false);
  const [lunch, setLunch] = useState(false);
  const [dinner, setDinner] = useState(false);
  const [beforeSleep, setBeforeSleep] = useState(false);
  const [medicine_period_num, setMedicine_period_num] = useState<number>(0);

  // 필요 시
  const [whenever_patient_want, setWhenever_patient_want] = useState(false);

  // 추가 안내 참고
  const [is_supplement_info_exposed, setIs_supplement_info_exposed] =
    useState(false);

  // 투약 시점
  const [before_meal, setBefore_meal] = useState(false);
  const [after_meal, setAfter_meal] = useState(false);

  // 추가 안내
  const [supplement_info, setSupplement_info] = useState('');

  const onChangeSupplement_info = (e: FormEvent<HTMLTextAreaElement>) => {
    if (e.currentTarget.value.length > 500) return;
    setSupplement_info(e.currentTarget.value);
  };

  const closeModal = useCallback(() => {
    setIsPageLoading(false);
    setInternal(true);
    setExternal(false);
    setInjection(false);
    setMedicine_day_num('');
    setMorning(false);
    setLunch(false);
    setDinner(false);
    setBeforeSleep(false);
    setMedicine_period_num(0);
    setWhenever_patient_want(false);
    setIs_supplement_info_exposed(false);
    setBefore_meal(false);
    setAfter_meal(false);
    setSupplement_info('');
    setIsMedicine_day_num_not_decided_yet(false);
    dispatch(closeModal_modifyMedicineGuideModal());
  }, [dispatch, setMedicine_day_num]);

  const modifyMedicineGuide = () => {
    if (Number(medicine_day_num) < 0) {
      alert('투약 일수는 0일 이상이어야 합니다.');
      return;
    }

    if (Number(medicine_day_num) > 365) {
      alert('투약 일수는 최대 365일 까지만 가능합니다.');
      return;
    }

    if (
      window.confirm(
        `입력하신 내용으로 ${item.tm?.patient?.name} 환자의 복약 안내를 수정하시겠습니까?`,
      )
    ) {
      setIsPageLoading(true);

      const tpp_id = item.id ? item.id : 0;
      const medicine_guide_id = item.medicine_guide?.id
        ? item.medicine_guide.id
        : 0;

      const medicineGuideParams = {
        medicine_type: [] as string[],
        medicine_day_num: Number(medicine_day_num),
        whenever_patient_want,
        is_supplement_info_exposed,
        medicine_by_meal: [] as string[],
        supplement_info,
        tpp_id,
        medicine_time: [] as string[],
      };

      if (internal) medicineGuideParams.medicine_type.push('internal');
      if (external) medicineGuideParams.medicine_type.push('external');
      if (injection) medicineGuideParams.medicine_type.push('injection');

      if (before_meal || after_meal) {
        if (before_meal)
          medicineGuideParams.medicine_by_meal.push('before_meal');
        if (after_meal) medicineGuideParams.medicine_by_meal.push('after_meal');
      }

      if (morning) medicineGuideParams.medicine_time.push('morning');
      if (lunch) medicineGuideParams.medicine_time.push('lunch');
      if (dinner) medicineGuideParams.medicine_time.push('evening');
      if (beforeSleep) medicineGuideParams.medicine_time.push('night');

      myDoctorAPI
        .putMedicineGuide(medicineGuideParams, medicine_guide_id)
        .then((responseJSON) => {
          console.log(4466, responseJSON);
          if (responseJSON.serverStatus === 200) {
            alert('복약 안내가 수정되었습니다.');
            closeModal();
            dispatch(getTelepharmacyCount()).then(() =>
              window.location.reload(),
            );
          } else {
            alert('복약 안내에 이상이 없는지 확인해 주세요.');
          }
        })
        .catch((error) => {
          console.log(123, error);
          alert('복약 안내 요청에 실패했습니다.');
        })
        .finally(() => setIsPageLoading(false));
    }
  };

  const buttonVisible: boolean =
    external ||
    injection ||
    (internal && whenever_patient_want) ||
    (internal &&
      medicine_day_num.length > 0 &&
      (morning || lunch || dinner || beforeSleep)) ||
    (internal && isMedicine_day_num_not_decided_yet);

  useEffect(() => {
    if (initialMedicineGuide?.is_supplement_info_exposed)
      setIs_supplement_info_exposed(true);
    if (initialMedicineGuide?.medicine_by_meal?.includes('before_meal'))
      setBefore_meal(true);
    if (initialMedicineGuide?.medicine_by_meal?.includes('after_meal'))
      setAfter_meal(true);
    if (initialMedicineGuide?.medicine_day_num)
      setMedicine_day_num(String(initialMedicineGuide?.medicine_day_num));
    if (initialMedicineGuide?.medicine_period_num)
      setMedicine_period_num(initialMedicineGuide?.medicine_period_num);
    if (initialMedicineGuide?.medicine_type?.includes('internal'))
      setInternal(true);
    if (initialMedicineGuide?.medicine_type?.includes('external'))
      setExternal(true);
    if (initialMedicineGuide?.medicine_type?.includes('injection'))
      setInjection(true);
    if (initialMedicineGuide?.supplement_info)
      setSupplement_info(initialMedicineGuide?.supplement_info);
    if (initialMedicineGuide?.whenever_patient_want)
      setWhenever_patient_want(true);
    if (item.medicine_guide?.medicine_time?.includes('morning'))
      setMorning(true);
    if (item.medicine_guide?.medicine_time?.includes('lunch')) setLunch(true);
    if (item.medicine_guide?.medicine_time?.includes('evening'))
      setDinner(true);
    if (item.medicine_guide?.medicine_time?.includes('night'))
      setBeforeSleep(true);
  }, [
    initialMedicineGuide?.is_supplement_info_exposed,
    initialMedicineGuide?.medicine_by_meal,
    initialMedicineGuide?.medicine_day_num,
    initialMedicineGuide?.medicine_period_num,
    initialMedicineGuide?.medicine_time,
    initialMedicineGuide?.medicine_type,
    initialMedicineGuide?.supplement_info,
    initialMedicineGuide?.whenever_patient_want,
    item.medicine_guide?.medicine_time,
    setMedicine_day_num,
  ]);

  useEffect(() => {
    let count = 0;
    if (morning) count += 1;
    if (lunch) count += 1;
    if (dinner) count += 1;
    if (beforeSleep) count += 1;
    setMedicine_period_num(count);
  }, [beforeSleep, dinner, lunch, morning]);

  return {
    isPageLoading,
    isVisible,
    item,
    closeModal,
    modifyMedicineGuide,
    internal,
    setInternal,
    external,
    setExternal,
    injection,
    setInjection,
    medicine_day_num,
    onChangeMedicine_day_num,
    setMedicine_day_num,
    isMedicine_day_num_not_decided_yet,
    setIsMedicine_day_num_not_decided_yet,
    morning,
    setMorning,
    lunch,
    setLunch,
    dinner,
    setDinner,
    beforeSleep,
    setBeforeSleep,
    medicine_period_num,
    setMedicine_period_num,
    whenever_patient_want,
    setWhenever_patient_want,
    is_supplement_info_exposed,
    setIs_supplement_info_exposed,
    before_meal,
    setBefore_meal,
    after_meal,
    setAfter_meal,
    supplement_info,
    setSupplement_info,
    onChangeSupplement_info,
    buttonVisible,
  };
};

export default useModifyMedicineGuideModalHook;
