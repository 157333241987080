import { BColor } from '@components/meraki-ui/BColor';
import { IconDetailProps } from '@components/meraki-ui/BIcon/svg.type';

export default function XIcon({ width, height, color }: IconDetailProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.33474 2.33474C2.78105 1.88842 3.50467 1.88842 3.95098 2.33474L10 8.38376L16.049 2.33474C16.4953 1.88842 17.219 1.88842 17.6653 2.33474C18.1116 2.78105 18.1116 3.50467 17.6653 3.95098L11.6162 10L17.6653 16.049C18.1116 16.4953 18.1116 17.219 17.6653 17.6653C17.219 18.1116 16.4953 18.1116 16.049 17.6653L10 11.6162L3.95098 17.6653C3.50467 18.1116 2.78105 18.1116 2.33474 17.6653C1.88842 17.219 1.88842 16.4953 2.33474 16.049L8.38376 10L2.33474 3.95098C1.88842 3.50467 1.88842 2.78105 2.33474 2.33474Z"
        fill={BColor[color]}
      />
    </svg>
  );
}
