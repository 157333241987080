import { checkBoxIcon, checkFilledBoxIcon } from '@assets/index';
import { Button, COLOR, Font } from '@components/atoms';

import { priceFormatter } from '@functions/formatters';
import { AuthError } from '@mobile/api/customError';
import useAcceptTP from '@mobile/api/hooks/useAcceptTP';
import useTPProcessDetail from '@mobile/api/hooks/useTPProcessDetail';
import AcceptanceHeader from '@mobile/components/common/AcceptanceHeader';
import { Mixpanel } from '@utils/mixpanel';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import BottomCTA from './components/BottomCTA';
import SubstitutePrescriptionInfo from './components/SubstitutePrescriptionInfo';
import usePharmacyCost from './hooks/usePharmacyCost';

export type TPAcceptLocationState = {
  state: {
    patientName: string;
    reservationCode: string;
    prescriptionId: string;
    mixpanelData: {
      'TP ID': number;
      'PH ID': string | null;
      'Symptom Keyword': string;
      'Delivery Method': string;
      'OCR Success': boolean;
      'Non-insured Medicine': (string | null | undefined)[];
    };
  };
};

function TPAccept() {
  const { id } = useParams();
  const tppId = Number(id);

  const { cost, handleChangeInput, handleCheckZeroCost, isZeroCost } =
    usePharmacyCost();
  const {
    data: tpData,
    isError: isTppProcessError,
    error: tppProcessError,
  } = useTPProcessDetail();
  const { mutate: acceptTPMutate } = useAcceptTP();

  const {
    state: { prescriptionId, patientName, reservationCode, mixpanelData },
  } = useLocation() as TPAcceptLocationState;
  const navigate = useNavigate();

  const [isSubstituteSelected, setIsSubstituteSelected] = useState(false); // false = 처방전대로 조제, true = 동일성분 대체조제
  const [substituteReasonTitle, setSubstituteReasonTitle] = useState<
    'sameIngredientDifferentManufacturer' | 'otherOption'
  >('sameIngredientDifferentManufacturer');
  const [substituteReasonContent, setSubstituteReasonContent] = useState('');
  const substituteReasonRef = useRef<HTMLTextAreaElement>(null);

  const isButtonDisabled = useMemo(() => {
    if (cost === '') return true;

    if (isSubstituteSelected === false) return false;

    if (substituteReasonTitle === 'sameIngredientDifferentManufacturer')
      return false;

    if (substituteReasonContent.length === 0) return true;

    if (substituteReasonContent.length > 0 && cost !== '') {
      return false;
    }

    return true;
  }, [
    isSubstituteSelected,
    substituteReasonTitle,
    substituteReasonContent,
    cost,
  ]);

  const goBack = () => {
    return navigate(`/mobile/request/detail/${tppId}`, {
      state: { prescriptionId },
    });
  };

  const acceptTP = () => {
    acceptTPMutate({
      amount: cost,
      tppId: String(tppId),
      isSubstituteSelected,
      substituteReasonContent: isSubstituteSelected
        ? substituteReasonContent
        : '',
    });
  };

  useEffect(() => {
    Mixpanel.track('[Modal] Accept TP Requested (Mobile)', mixpanelData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isTppProcessError) {
      if (tppProcessError instanceof AuthError) {
        navigate('/mobile/auth-error');
      }
    }
  }, [isTppProcessError, navigate, tppProcessError]);

  return (
    <>
      <AcceptanceHeader title="조제 수락" goBack={goBack} />

      <Container>
        <PatientInfo>
          <InfoText>{`${patientName} (${tpData?.telemedicine_hash?.toUpperCase()})`}</InfoText>
        </PatientInfo>

        <Content>
          <Wrapper>
            <SubstitutePrescriptionInfo
              tmId={tpData?.tm?.id}
              isSubstitutedPatient={tpData?.is_substituted_patient}
            />
          </Wrapper>

          <Wrapper>
            <LabelContainer>
              <Font fontType="body1_medium" color="fill/black">
                대체조제 여부
              </Font>
              <Font fontType="body1_medium" color="state/distructive">
                *
              </Font>
            </LabelContainer>
            <SubstituteReasonContent>
              <SubstituteSelectButtonContainer>
                <Button
                  width="50%"
                  padding="9px 0"
                  borderColor={
                    isSubstituteSelected
                      ? COLOR['border/outline']
                      : COLOR['fill/accent']
                  }
                  borderRadius="8px"
                  backgroundColor={
                    isSubstituteSelected
                      ? COLOR['fill/white']
                      : COLOR['label/lightBlue']
                  }
                  title={
                    <Font
                      fontType="body2_medium"
                      color={isSubstituteSelected ? 'fill/dark' : 'fill/accent'}
                    >
                      처방전대로 조제
                    </Font>
                  }
                  onClick={() => {
                    setIsSubstituteSelected((prev) => !prev);
                  }}
                />
                <Button
                  width="50%"
                  padding="9px 0"
                  borderColor={
                    isSubstituteSelected
                      ? COLOR['fill/accent']
                      : COLOR['border/outline']
                  }
                  borderRadius="8px"
                  backgroundColor={
                    isSubstituteSelected
                      ? COLOR['label/lightBlue']
                      : COLOR['fill/white']
                  }
                  title={
                    <Font
                      fontType="body2_medium"
                      color={isSubstituteSelected ? 'fill/accent' : 'fill/dark'}
                    >
                      동일성분 약으로 대체조제
                    </Font>
                  }
                  onClick={() => {
                    setIsSubstituteSelected((prev) => !prev);
                  }}
                />
              </SubstituteSelectButtonContainer>

              {isSubstituteSelected && (
                <SubstituteReasonContainer>
                  <SubstituteReasonArea>
                    <LabelContainer>
                      <Font fontType="body1_medium" color="fill/black">
                        대체조제 사유
                      </Font>
                      <Font fontType="body1_medium" color="state/distructive">
                        *
                      </Font>
                    </LabelContainer>
                    <SubstituteReasonRadiosArea>
                      <SubstituteReasonRadioArea
                        onClick={() =>
                          setSubstituteReasonTitle(
                            'sameIngredientDifferentManufacturer',
                          )
                        }
                      >
                        <SubstituteReasonRadio
                          type="radio"
                          id="0"
                          name="substituteReason"
                          value="0"
                          checked={
                            substituteReasonTitle ===
                            'sameIngredientDifferentManufacturer'
                          }
                        />
                        <SubstituteReasonLabel htmlFor="0">
                          <Font fontType="body1_medium" color="fill/black">
                            동일 성분의 다른 제약사 약으로 조제됩니다.
                          </Font>
                        </SubstituteReasonLabel>
                      </SubstituteReasonRadioArea>
                      <SubstituteReasonRadioArea
                        onClick={() => setSubstituteReasonTitle('otherOption')}
                      >
                        <SubstituteReasonRadio
                          type="radio"
                          id="1"
                          name="substituteReason"
                          value="1"
                          checked={substituteReasonTitle === 'otherOption'}
                        />
                        <SubstituteReasonLabel htmlFor="1">
                          <Font fontType="body1_medium" color="fill/black">
                            기타 (직접 입력)
                          </Font>
                        </SubstituteReasonLabel>
                      </SubstituteReasonRadioArea>
                    </SubstituteReasonRadiosArea>

                    {substituteReasonTitle === 'otherOption' && (
                      <SubstituteReasonTextareaArea
                        ref={substituteReasonRef}
                        placeholder="대체조제 사유를 입력해주세요"
                        value={substituteReasonContent}
                        onChange={(e) =>
                          setSubstituteReasonContent(e.target.value)
                        }
                      />
                    )}
                  </SubstituteReasonArea>
                </SubstituteReasonContainer>
              )}
            </SubstituteReasonContent>
          </Wrapper>

          <Wrapper>
            <LabelContainer>
              <Font fontType="body1_medium" color="fill/black">
                약제비
              </Font>
              <Font fontType="body1_medium" color="state/distructive">
                *
              </Font>
            </LabelContainer>
            <InputContainer>
              <Input
                type="tel"
                value={`${priceFormatter.prettyPriceFormatter(cost)}`}
                onChange={handleChangeInput}
                placeholder="숫자만 입력해주세요"
                disabled={isZeroCost}
              />
              <Font fontType="body1_medium" color="fill/dark">
                원
              </Font>
            </InputContainer>
            <CheckBoxContainer>
              {isZeroCost ? (
                <CheckBox
                  src={checkFilledBoxIcon}
                  onClick={handleCheckZeroCost}
                />
              ) : (
                <CheckBox src={checkBoxIcon} onClick={handleCheckZeroCost} />
              )}
              <Font fontType="body1_medium" color="fill/dark">
                약제비가 0원인 조제입니다.
              </Font>
            </CheckBoxContainer>
          </Wrapper>
        </Content>

        <BottomCTA
          isButtonDisabled={isButtonDisabled}
          goBack={goBack}
          accept={acceptTP}
        />
      </Container>
    </>
  );
}

export default TPAccept;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 auto;
  padding-top: var(--acceptance-header-height);
  padding-bottom: 80px;
  max-width: 720px;
  height: 100vh;
  height: 100dvh;
`;

const PatientInfo = styled.div`
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

const InfoText = styled.div`
  color: #1e2024;

  font-family: Pretendard, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
`;

const Content = styled.div`
  display: flex;
  padding: 0px 20px 40px 20px;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  flex: 1 0 0;
  align-self: stretch;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const SubstituteReasonContainer = styled.div`
  width: 100%;
`;

const SubstituteSelectButtonContainer = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
`;

const SubstituteReasonContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
`;

const SubstituteReasonArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const SubstituteReasonRadiosArea = styled.fieldset`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const SubstituteReasonRadioArea = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px 12px;
`;

const SubstituteReasonRadio = styled.input`
  width: 18px;
  height: 18px;
  cursor: pointer;
`;

const SubstituteReasonLabel = styled.label`
  cursor: pointer;
`;

const SubstituteReasonTextareaArea = styled.textarea`
  width: 100%;
  height: 120px;
  padding: 12px 16px;
  border: 1px solid ${COLOR['border/outline']};
  border-radius: 8px;
  resize: none;
  color: ${COLOR['fill/black']};
  ::placeholder {
    font-size: 16px;
    color: ${COLOR['fill/medium']};
  }
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`;

const Input = styled.input`
  display: flex;
  height: 52px;
  padding: 0px 16px;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid #dddfe3;
  background: #ffffff;
  font-size: 16px;

  &::placeholder {
    color: #9fa1a8;
    font-weight: 400;
  }
`;

const CheckBoxContainer = styled.div`
  display: flex;
  padding: 8px 0px;
  align-items: center;
  gap: 12px;
`;

const CheckBox = styled.img`
  width: 20px;
  height: 20px;
`;
