import { BColor, ColorType } from '@components/meraki-ui/BColor';
import { BTheme } from '@components/meraki-ui/BTheme';
import { SizeReturnType, SizeType } from '@components/meraki-ui/type';

export type ButtonSizeType =
  | SizeType
  | 'chip'
  | 'xsSquare'
  | 'smSquare'
  | 'mdSquare'
  | 'lgSquare'
  | 'xlSquare'
  | 'heightForFullWidth';

export type ButtonType =
  | 'default'
  | 'primary'
  | 'secondary'
  | 'error'
  | 'warning'
  | 'success'
  | 'disabled'
  | 'PlainDefault'
  | 'plainPrimary'
  | 'plainBorder'
  | 'register';

export type ButtonReturnType = {
  iconColor: ColorType;
  iconHoverColor?: ColorType;
  iconActiveColor?: ColorType;

  textColor: ColorType;
  textHoverColor?: ColorType;
  textActiveColor?: ColorType;

  backgroundColor: ColorType;
  backgroundHoverColor?: ColorType;
  backgroundActiveColor?: ColorType;

  border?: string;
  hoverBorder?: string;
};

export const buttonProps: Record<ButtonType, ButtonReturnType> = {
  default: {
    backgroundColor: 'mono02',
    backgroundHoverColor: 'mono03',
    backgroundActiveColor: 'mono04',
    iconColor: 'mono08',
    textColor: 'mono08',
  },
  primary: {
    backgroundColor: 'primary08',
    backgroundHoverColor: 'primary09',
    backgroundActiveColor: 'primary10',
    iconColor: 'mono01',
    textColor: 'mono01',
  },
  secondary: {
    backgroundColor: 'primary02',
    backgroundHoverColor: 'primary03',
    backgroundActiveColor: 'primary04',
    iconColor: 'primary08',
    textColor: 'primary08',
  },
  error: {
    backgroundColor: 'error08',
    backgroundHoverColor: 'error09',
    iconColor: 'mono01',
    textColor: 'mono01',
  },
  warning: {
    backgroundColor: 'warning08',
    backgroundHoverColor: 'warning09',
    backgroundActiveColor: 'warning10',
    iconColor: 'black90',
    textColor: 'black90',
  },
  success: {
    backgroundColor: 'successMain',
    backgroundHoverColor: 'successDark',
    iconColor: 'mono01',
    textColor: 'mono01',
  },
  disabled: {
    backgroundColor: 'mono05',
    iconColor: 'mono01',
    textColor: 'mono01',
  },
  register: {
    backgroundColor: 'black90',
    backgroundHoverColor: 'mono10',
    iconColor: 'mono01',
    textColor: 'mono01',
  },
  PlainDefault: {
    backgroundColor: 'transparent',
    backgroundHoverColor: 'mono02',
    backgroundActiveColor: 'mono03',
    iconColor: 'mono08',
    iconHoverColor: 'mono09',
    iconActiveColor: 'mono10',
    textColor: 'mono08',
    textHoverColor: 'mono09',
    textActiveColor: 'mono10',
  },
  plainPrimary: {
    backgroundColor: 'transparent',
    backgroundHoverColor: 'primary02',
    backgroundActiveColor: 'primary03',
    iconColor: 'primary08',
    iconHoverColor: 'primary09',
    iconActiveColor: 'primary10',
    textColor: 'primary08',
    textHoverColor: 'primary09',
    textActiveColor: 'primary10',
  },
  plainBorder: {
    backgroundColor: 'transparent',
    backgroundHoverColor: 'primary01',
    backgroundActiveColor: 'primary02',
    iconColor: 'primary08',
    textColor: 'primary08',
    border: `1px solid ${BColor.primary06}`,
    hoverBorder: `1px solid ${BColor.primary07}`,
  },
} as const;

export const sizeProps: Record<ButtonSizeType, SizeReturnType> = {
  chip: {
    padding: `${BTheme.space[2]} ${BTheme.space[4]}`,
    fontSize: 12,
  },
  xs: {
    padding: `${BTheme.space[6]} ${BTheme.space[8]}`,
    fontSize: 14,
  },
  sm: {
    padding: `${BTheme.space[8]} ${BTheme.space[12]}`,
    fontSize: 14,
  },
  md: {
    padding: `${BTheme.space[12]} ${BTheme.space[16]}`,
    fontSize: 18,
  },
  lg: {
    padding: `${BTheme.space[16]} ${BTheme.space[20]}`,
    fontSize: 24,
  },
  xl: {
    padding: `${BTheme.space[20]} ${BTheme.space[24]}`,
    fontSize: 28,
  },

  xsSquare: {
    padding: `${BTheme.space[5]} ${BTheme.space[5]}`,
    fontSize: 12,
  },
  smSquare: {
    padding: `${BTheme.space[8]} ${BTheme.space[8]}`,
    fontSize: 14,
  },
  mdSquare: {
    padding: `${BTheme.space[12]} ${BTheme.space[12]}`,
    fontSize: 18,
  },
  lgSquare: {
    padding: `${BTheme.space[16]} ${BTheme.space[16]}`,
    fontSize: 24,
  },
  xlSquare: {
    padding: `${BTheme.space[20]} ${BTheme.space[20]}`,
    fontSize: 28,
  },
  heightForFullWidth: {
    padding: '0',
    fontSize: 18,
  },
};
