import { Button, COLOR, Font } from '@components/atoms';
import SvgIcon from '@components/atoms/images';
import {
  medicineCategoryList,
  MedicineCategoryType,
} from '@components/organisms/managementMedicineTable/config/domain';
import * as Style from './index.style';

interface MedicineSearchProps {
  selectedMedicineCategory: MedicineCategoryType;
  handleClickMedicineCategory: (medicine: MedicineCategoryType) => void;
}

function MedicineCategory({
  selectedMedicineCategory,
  handleClickMedicineCategory,
}: MedicineSearchProps) {
  return (
    <Style.ModalBodyContentArea>
      <Style.SubTitleArea>
        <Style.SubTitleLeftArea>
          <Font fontType="body1_medium" color="fill/black">
            카테고리
          </Font>
          <Style.RequiredUI>
            <Font fontType="body3_medium" color="blue500">
              필수
            </Font>
          </Style.RequiredUI>
        </Style.SubTitleLeftArea>
      </Style.SubTitleArea>

      <Style.MedicineCategoryButtonsArea>
        {medicineCategoryList.map((medicine) => {
          const { id, label, codes } = medicine;
          const isSelected = selectedMedicineCategory.label === label;

          return (
            <Button
              key={id}
              width="none"
              padding="9px 16px"
              borderRadius="12px"
              backgroundColor={
                isSelected ? COLOR['label/lightBlue'] : COLOR['fill/white']
              }
              borderColor={
                isSelected ? COLOR['fill/accent'] : COLOR['border/outline']
              }
              title={
                <Style.MedicineSelectedButtonArea>
                  {isSelected && (
                    <SvgIcon
                      icon="checkCircleIcon"
                      width={18}
                      color="fill/accent"
                    />
                  )}
                  <Font
                    fontType="body1"
                    color={isSelected ? 'fill/accent' : 'fill/dark'}
                  >
                    {label}
                  </Font>
                </Style.MedicineSelectedButtonArea>
              }
              onClick={() => handleClickMedicineCategory(medicine)}
            />
          );
        })}
      </Style.MedicineCategoryButtonsArea>
    </Style.ModalBodyContentArea>
  );
}

export default MedicineCategory;
