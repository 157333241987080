import styled from 'styled-components';

export const TitleArea = styled.div`
  width: 85%;
  overflow-wrap: break-word;
`;

export const PatientNameArea = styled.div``;

export const PatientInfoArea = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  // height: 150px;
`;

export const InformationLine = styled.div`
  display: flex;
  padding-left: 8px;
`;

export const InformationLineMargin = styled.div`
  height: 12px;
`;

export const InformationTitle = styled.div`
  width: 150px;
`;

export const InformationColumnArea = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
