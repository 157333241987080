import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '@stores';
import { AcceptTelepharmacyModalType } from './index.d';

const initialState: AcceptTelepharmacyModalType = {
  isVisible: false,
  item: {},
};

export const acceptTelepharmacyModal = createSlice({
  name: 'modal/acceptTelepharmacyModal',
  initialState,
  reducers: {
    openModal_acceptTelepharmacyModal: (state, action) => {
      state.isVisible = true;
      state.item = action.payload.item;
    },
    closeModal_acceptTelepharmacyModal: (state) => {
      state.isVisible = false;
      state.item = {};
    },
  },
});

export const {
  openModal_acceptTelepharmacyModal,
  closeModal_acceptTelepharmacyModal,
} = acceptTelepharmacyModal.actions;

export const selectAcceptTelepharmacyModalVisible = (state: RootState) =>
  state.acceptTelepharmacyModal.isVisible;
export const selectAcceptTelepharmacyModalItem = (state: RootState) =>
  state.acceptTelepharmacyModal.item;

export default acceptTelepharmacyModal.reducer;
