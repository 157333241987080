import { BColor } from '@components/meraki-ui/BColor';
import { IconDetailProps } from '@components/meraki-ui/BIcon/svg.type';

export default function ClassificationIcon({
  width,
  height,
  color,
}: IconDetailProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 5.5C12 4.67157 11.3284 4 10.5 4H9.5C8.67157 4 8 4.67157 8 5.5V6.5C8 7.32843 8.67157 8 9.5 8V9H4C3.72386 9 3.5 9.22386 3.5 9.5V10.5C3.5 10.7761 3.72386 11 4 11C4.27614 11 4.5 10.7761 4.5 10.5V10H9.5V10.5C9.5 10.7761 9.72386 11 10 11C10.2761 11 10.5 10.7761 10.5 10.5V10H15.5V10.5C15.5 10.7761 15.7239 11 16 11C16.2761 11 16.5 10.7761 16.5 10.5V9.5C16.5 9.22386 16.2761 9 16 9H10.5V8C11.3284 8 12 7.32843 12 6.5V5.5ZM9.5 7C9.22386 7 9 6.77614 9 6.5V5.5C9 5.22386 9.22386 5 9.5 5H10.5C10.7761 5 11 5.22386 11 5.5V6.5C11 6.77614 10.7761 7 10.5 7H9.5ZM18 13.5C18 12.6716 17.3284 12 16.5 12H15.5C14.6716 12 14 12.6716 14 13.5V14.5C14 15.3284 14.6716 16 15.5 16H16.5C17.3284 16 18 15.3284 18 14.5V13.5ZM16.5 13C16.7761 13 17 13.2239 17 13.5V14.5C17 14.7761 16.7761 15 16.5 15H15.5C15.2239 15 15 14.7761 15 14.5V13.5C15 13.2239 15.2239 13 15.5 13H16.5ZM12 13.5C12 12.6716 11.3284 12 10.5 12H9.5C8.67157 12 8 12.6716 8 13.5V14.5C8 15.3284 8.67157 16 9.5 16H10.5C11.3284 16 12 15.3284 12 14.5V13.5ZM10.5 13C10.7761 13 11 13.2239 11 13.5V14.5C11 14.7761 10.7761 15 10.5 15H9.5C9.22386 15 9 14.7761 9 14.5V13.5C9 13.2239 9.22386 13 9.5 13H10.5ZM6 13.5C6 12.6716 5.32843 12 4.5 12H3.5C2.67157 12 2 12.6716 2 13.5V14.5C2 15.3284 2.67157 16 3.5 16H4.5C5.32843 16 6 15.3284 6 14.5V13.5ZM4.5 13C4.77614 13 5 13.2239 5 13.5V14.5C5 14.7761 4.77614 15 4.5 15H3.5C3.22386 15 3 14.7761 3 14.5V13.5C3 13.2239 3.22386 13 3.5 13H4.5Z"
        fill={BColor[color]}
      />
    </svg>
  );
}
