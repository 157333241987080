import { Font } from '@components/atoms';

import { DebouncedFunc } from 'lodash';
import { memo } from 'react';
import * as Style from '../../../../index.style';
import {
  LoginEmailInputComponent,
  LoginPasswordInputComponent,
  LoginPhoneNumberInputComponent,
} from '../../components';
import { ELoginType } from '../../hooks';

type ILoginFormViewProps = {
  openFindIdPasswordModal: Function;
  selectedLoginType: ELoginType;
  shouldTriggerTimer: boolean;
  formattedTime: string;
  submitEmail: DebouncedFunc<() => void>;
  submitPhone: DebouncedFunc<() => void>;
};

export const LoginFormView = memo<ILoginFormViewProps>(
  ({
    openFindIdPasswordModal,
    selectedLoginType,
    shouldTriggerTimer,
    formattedTime,
    submitEmail,
    submitPhone,
  }) => {
    if (selectedLoginType === ELoginType.PHONE) {
      return (
        <div
          style={{
            display: 'flex',
            gap: 20,
            flexDirection: 'column',
          }}
        >
          <Style.TextInputArea>
            <LoginPhoneNumberInputComponent submitPhone={submitPhone} />
          </Style.TextInputArea>
          {shouldTriggerTimer && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 8,
              }}
            >
              <Font fontType="body2" color="state/distructive">
                {formattedTime}
              </Font>
              <Font fontType="body1" color="border/outline">
                |
              </Font>
              <Font fontType="body2" color="fill/medium">
                제한시간 만료, 메시지를 다시 보내주세요
              </Font>
            </div>
          )}
        </div>
      );
    }

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Style.TextInputArea>
          <LoginEmailInputComponent />
        </Style.TextInputArea>
        <LoginPasswordInputComponent submitEmail={submitEmail} />
        <Style.TextInputArea />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <div
            onClick={() => {
              openFindIdPasswordModal();
            }}
            style={{
              cursor: 'pointer',
            }}
          >
            <Font fontType="body1_medium" color="greyscale5" underline>
              아이디 • 비밀번호 찾기
            </Font>
          </div>
        </div>
      </div>
    );
  },
);
