import { IconDetailProps } from '@components/atoms/images';
import { COLOR } from '../color';

function DownloadIcon({ width, height, color }: IconDetailProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 13.9997C2 13.6315 2.29848 13.333 2.66667 13.333L13.3333 13.333C13.7015 13.333 14 13.6315 14 13.9997C14 14.3679 13.7015 14.6663 13.3333 14.6663L2.66667 14.6663C2.29848 14.6663 2 14.3679 2 13.9997Z"
        fill={COLOR[color]}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.027 7.97358C12.2873 8.23393 12.2873 8.65604 12.027 8.91639L11.5556 8.44499L11.0842 7.97358C11.3445 7.71323 11.7666 7.71323 12.027 7.97358ZM8.00001 11.0578L11.0842 7.97358C11.0842 7.97356 11.0842 7.97358 11.5556 8.44499C12.027 8.91639 12.027 8.91637 12.027 8.91639L8.47141 12.472C8.21106 12.7323 7.78895 12.7323 7.5286 12.472L3.97302 8.91639C3.71267 8.65604 3.71267 8.23393 3.97302 7.97358C4.23337 7.71323 4.65548 7.71323 4.91583 7.97358L8.00001 11.0578Z"
        fill={COLOR[color]}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99998 12.333C7.63179 12.333 7.33331 12.0345 7.33331 11.6663L7.33333 1.99967C7.33333 1.63148 7.63181 1.33301 8 1.33301C8.36819 1.33301 8.66666 1.63148 8.66666 1.99968L8.66665 11.6663C8.66665 12.0345 8.36817 12.333 7.99998 12.333Z"
        fill={COLOR[color]}
      />
    </svg>
  );
}

export default DownloadIcon;
