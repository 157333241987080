/* eslint-disable max-classes-per-file */
export class ApiError extends Error {
  status: number;

  config: RequestInit;

  constructor(message: string, status: number, config: RequestInit) {
    super(message);
    this.name = 'API Error';
    this.status = status;
    this.config = config;
  }
}

export class AuthError extends Error {
  status: number;

  config: RequestInit;

  constructor(message: string, status: number, config: RequestInit) {
    super(message);
    this.name = 'Auth Error';
    this.status = status;
    this.config = config;
  }
}
