import { BColor } from '@components/meraki-ui/BColor';
import { IconDetailProps } from '@components/meraki-ui/BIcon/svg.type';

export default function ChatQuotesIcon({
  width,
  height,
  color,
}: IconDetailProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.67789 13.4284C4.88828 13.6394 4.99363 13.9332 4.96523 14.2298C4.90159 14.8945 4.75502 15.5863 4.56677 16.2289C5.96166 15.9065 6.81358 15.5325 7.20082 15.3363C7.42062 15.2249 7.67381 15.1986 7.91182 15.2624C8.56966 15.4388 9.27076 15.5344 10 15.5344C13.9956 15.5344 17 12.7269 17 9.53442C17 6.34197 13.9956 3.53442 10 3.53442C6.0044 3.53442 3 6.34197 3 9.53442C3 11.0025 3.6173 12.3648 4.67789 13.4284ZM4.18489 17.3334C4.17816 17.3347 4.17142 17.336 4.16466 17.3374C4.07283 17.3555 3.97922 17.3733 3.88382 17.3907C3.75 17.4153 3.61264 17.4391 3.47172 17.4622C3.27271 17.4948 3.12037 17.2866 3.19898 17.1008C3.25034 16.9795 3.30097 16.8523 3.3503 16.7205C3.38164 16.6367 3.41245 16.5511 3.44259 16.4638C3.44389 16.4601 3.44518 16.4563 3.44648 16.4525C3.69422 15.7328 3.89596 14.9055 3.96979 14.1345C2.74306 12.9043 2 11.2953 2 9.53442C2 5.66843 5.58172 2.53442 10 2.53442C14.4183 2.53442 18 5.66843 18 9.53442C18 13.4004 14.4183 16.5344 10 16.5344C9.18324 16.5344 8.39508 16.4273 7.65284 16.2283C7.13337 16.4915 6.01434 16.9707 4.18489 17.3334Z"
        fill={BColor[color]}
      />
      <path
        d="M9.06576 8.29502C8.76869 7.83724 8.25304 7.53442 7.66667 7.53442C6.74619 7.53442 6 8.28062 6 9.20109C6 10.1216 6.74619 10.8678 7.66667 10.8678C7.99594 10.8678 8.30291 10.7723 8.5614 10.6075C8.42994 10.9964 8.1864 11.4111 7.78358 11.8283C7.62374 11.9939 7.62837 12.2577 7.79392 12.4175C7.95947 12.5773 8.22324 12.5727 8.38308 12.4072C9.87032 10.8668 9.67687 9.19295 9.06576 8.29502Z"
        fill={BColor[color]}
      />
      <path
        d="M13.0658 8.29502C12.7687 7.83724 12.253 7.53442 11.6667 7.53442C10.7462 7.53442 10 8.28062 10 9.20109C10 10.1216 10.7462 10.8678 11.6667 10.8678C11.9959 10.8678 12.3029 10.7723 12.5614 10.6075C12.4299 10.9964 12.1864 11.4111 11.7836 11.8283C11.6237 11.9939 11.6284 12.2577 11.7939 12.4175C11.9595 12.5773 12.2232 12.5727 12.3831 12.4072C13.8703 10.8668 13.6769 9.19295 13.0658 8.29502Z"
        fill={BColor[color]}
      />
    </svg>
  );
}
