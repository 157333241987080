import { COLOR } from '@components/atoms/color';
import styled from 'styled-components';
import { TelemedicineContainerType } from './index.d';

export const Container = styled.div<TelemedicineContainerType>`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px 24px;
  border-bottom: 1px solid ${COLOR['border/divider']};
  background-color: ${(props) =>
    props.isSelected ? COLOR['label/lightBlue'] : COLOR['fill/white']};
  cursor: pointer;
`;

export const TopArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const MiddleArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const MiddleTextArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 123px;
`;

export const TimerArea = styled.div<{ isSelected: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 48px;
  width: 48px;
  height: 48px;
  background-color: ${(props) =>
    props.isSelected ? COLOR['label/lightBlue'] : COLOR.greyscale2};
  border-radius: 50%;
`;

export const DeliveryImage = styled.img`
  position: absolute;
  width: 32px;
  height: 32px;
`;

export const SymptomContainer = styled.div<{ isSelected: boolean }>`
  display: flex;
  justify-content: center;
  padding: 2px 8px;
  width: fit-content;
  border-radius: 4px;
  background-color: ${({ isSelected }) =>
    isSelected ? COLOR['fill/accent'] : COLOR.greyscale2};
`;

export const PrescriptionButtonArea = styled.div``;

export const PrintButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;
