import { serverURL } from '@constants/index';
import { REST_AUTH_TOKEN } from '@constants/tokenList';
import { getLocalStorageState } from '@utils/storageHandler';
import axios from 'axios';
import { detect } from 'detect-browser';

type APIMethodType = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

interface FetchProps {
  path: string;
  method: APIMethodType;
  params?: Record<string, unknown>;
  data?: Record<string, unknown>;
}

const browser = detect();

export const defaultHeaders = {
  headers: {
    'Content-Type': 'application/json',

    Accept: 'application/json',
    'X-OS-Type': browser?.name || '',
    'X-App-Type': `doctor_admin_${
      process.env.NODE_ENV === 'development' ? 'DEV' : process.env.NODE_ENV
    }`,
    'X-Device-Name': browser?.os ? `${browser?.os} pc` : 'pc',
    'X-App-Version': '1.0.0',
    'X-Ip-Address': '',
  },
};

export default async function fetchForClient<T = unknown>({
  path,
  method,
  params,
  data,
}: FetchProps): Promise<T> {
  const baseURL = serverURL.ServerConstant();
  const url = `${baseURL}${path}`;

  const { headers } = defaultHeaders;

  const accessToken = getLocalStorageState(REST_AUTH_TOKEN.ACCESS_TOKEN);

  const Authorization = accessToken ? `Bearer ${accessToken}` : '';

  const response = await axios({
    // headers,
    headers: {
      ...headers,
      Authorization,
    },
    url,
    method,

    params: params ?? null,
    data: method !== 'GET' ? data : data ?? null,
  });

  if (response.data) {
    return response.data as T;
  }

  throw new Error('error');
}
