import { AxiosRequestConfig } from 'axios';

export type BaseConfig = Partial<AxiosRequestConfig>;

export interface APIOption<K> {
  endPoint: string;
  config?: BaseConfig;
  params?: K;
  body?: K;
}

export const enum APIMethod {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  DELETE = 'delete',
  PATCH = 'patch',
}

export const enum APIResponseStatus {
  getSuccess = 200,
  postSuccess = 201,
}

export type APIResponse<T> = { data: T; status: APIResponseStatus };
