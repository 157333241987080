import { Button, COLOR, Font } from '@components/atoms';
import { DebouncedFunc } from 'lodash';
import { memo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import {
  ELoginType,
  EmailLoginFormData,
  EmailLoginFormFields,
  PhoneLoginFormData,
  PhoneLoginFormFields,
} from '../../hooks';

type ILoginCtaViewProps = {
  openSignupModal: () => void;
  selectedLoginType: ELoginType;
  submitEmail: DebouncedFunc<() => void>;
  submitPhone: DebouncedFunc<() => void>;
  shouldTriggerTimer?: boolean;
  retry: () => void;
};

export const LoginCtaView = memo<ILoginCtaViewProps>(
  ({
    openSignupModal,
    selectedLoginType,
    submitEmail,
    submitPhone,
    shouldTriggerTimer,
    retry,
  }) => {
    const navigate = useNavigate();
    const { formState: emailLoginFormState } =
      useFormContext<EmailLoginFormData>();
    const { formState: phoneLoginFormState } =
      useFormContext<PhoneLoginFormData>();

    const emailLoginError =
      emailLoginFormState.errors?.[EmailLoginFormFields.EMAIL] ||
      emailLoginFormState.errors?.[EmailLoginFormFields.PASSWORD];

    const phoneLoginError =
      phoneLoginFormState.errors?.[PhoneLoginFormFields.PHONE_NUMBER];

    const emailLoginCtaDisabled =
      emailLoginError ||
      Object.keys(emailLoginFormState.dirtyFields).length !== 2;
    const phoneLoginCtaDisabled =
      phoneLoginError ||
      Object.keys(phoneLoginFormState.dirtyFields).length !== 1;

    const disabled =
      selectedLoginType === ELoginType.EMAIL
        ? emailLoginCtaDisabled
        : phoneLoginCtaDisabled;

    const hasVerifyCodeSent = shouldTriggerTimer;

    if (hasVerifyCodeSent) {
      return (
        <LoginButtonArea>
          <div
            style={{
              flex: 1,
            }}
          >
            <Button
              width="100%"
              padding="16px 12px"
              borderRadius="12px"
              title={
                <Font fontType="body1" color="fill/dark">
                  이전으로
                </Font>
              }
              onClick={() => {
                navigate('/home', { replace: true });
              }}
              backgroundColor={COLOR['fill/white']}
              borderColor={COLOR['border/outline']}
            />
          </div>
          <div style={{ flex: 2 }}>
            <Button
              width="100%"
              padding="16px 12px"
              borderRadius="12px"
              title={
                <Font fontType="body1" color="fill/accent">
                  인증메시지 재전송
                </Font>
              }
              onClick={retry}
              backgroundColor={COLOR['label/lightBlue']}
            />
          </div>
        </LoginButtonArea>
      );
    }

    return (
      <LoginButtonArea>
        <div
          style={{
            flex: 1,
          }}
        >
          <Button
            width="100%"
            padding="16px 12px"
            borderRadius="12px"
            title={
              <Font fontType="body1" color="fill/dark">
                회원가입
              </Font>
            }
            onClick={() => openSignupModal()}
            backgroundColor={COLOR['fill/white']}
            borderColor={COLOR['border/outline']}
          />
        </div>
        <div style={{ flex: 2 }}>
          <Button
            width="100%"
            padding="16px 12px"
            borderRadius="12px"
            title={
              <Font
                fontType="body1"
                color={disabled ? 'disabled/foreground' : 'fill/white'}
              >
                로그인
              </Font>
            }
            onClick={() => {
              if (disabled) return;
              if (selectedLoginType === ELoginType.EMAIL) {
                submitEmail();
              }
              if (selectedLoginType === ELoginType.PHONE) {
                submitPhone();
              }
            }}
            backgroundColor={
              disabled ? COLOR['disabled/background'] : COLOR['fill/accent']
            }
          />
        </div>
      </LoginButtonArea>
    );
  },
);

const LoginButtonArea = styled.div`
  width: 100%;
  display: flex;
  gap: 12px;
`;
