import styled from 'styled-components';

type Props = {
  hospitalName?: string;
  doctorName?: string;
  hospitalCallNumber?: string;
};

function HospitalInfo({
  doctorName = '-',
  hospitalCallNumber = '-',
  hospitalName = '-',
}: Props) {
  return (
    <Container>
      <Title>병원 정보</Title>
      <ContentsWrapper>
        <Row>
          <Key>병원</Key>
          <Value>{hospitalName}</Value>
        </Row>
        <Row>
          <Key>의사</Key>
          <Value>{doctorName}</Value>
        </Row>
        <Row>
          <Key>병원 연락처</Key>
          <Value>{hospitalCallNumber}</Value>
        </Row>
      </ContentsWrapper>
    </Container>
  );
}

export default HospitalInfo;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  padding: 20px;
  border-top: 4px solid #f1f2f4;
  border-bottom: none;
  width: 100%;
`;

const Title = styled.div`
  color: #1e2024;

  font-family: Pretendard, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
`;

const ContentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

const Row = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
`;

const Key = styled.div`
  width: 120px;
  color: #9fa1a8;

  font-family: Pretendard, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

const Value = styled.div`
  flex: 1 0 0;
  color: #474a52;

  font-family: Pretendard, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
`;
