import { GetManagedMedicineItemType } from '@services/myDoctor/types';
import { useAppDispatch, useAppSelector } from '@stores/hooks';
import {
  closeModal_medicineManagementCommonModal,
  ConfirmModalType,
  openModal_medicineManagementCommonModal,
  selectMedicineManagementCommonModalModalType,
  selectMedicineManagementCommonModalTargetMedicine,
  selectMedicineManagementCommonModalVisible,
  set_modalType_medicineManagementCommonModal,
  set_targetMedicine_medicineManagementCommonModal,
} from '@stores/modalStore/medicineManangementCommonModal';
import { useCallback } from 'react';

const useMedicineManagementCommonModalHook = () => {
  const dispatch = useAppDispatch();
  const isVisible = useAppSelector(selectMedicineManagementCommonModalVisible);
  const modalType = useAppSelector(
    selectMedicineManagementCommonModalModalType,
  );
  const targetMedicine = useAppSelector(
    selectMedicineManagementCommonModalTargetMedicine,
  );

  const closeModal = useCallback(() => {
    dispatch(closeModal_medicineManagementCommonModal());
  }, [dispatch]);

  const openModal = useCallback(() => {
    dispatch(openModal_medicineManagementCommonModal());
  }, [dispatch]);

  const setManagementMedicineModalType = (type: ConfirmModalType) => {
    dispatch(set_modalType_medicineManagementCommonModal(type));
  };

  const setManagementMedicineTargetMedicine = (
    medicine: GetManagedMedicineItemType,
  ) => {
    dispatch(set_targetMedicine_medicineManagementCommonModal(medicine));
  };

  return {
    isVisible,
    modalType,
    targetMedicine,
    closeModal,
    openModal,
    setManagementMedicineModalType,
    setManagementMedicineTargetMedicine,
  };
};

export default useMedicineManagementCommonModalHook;
