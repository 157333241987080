import ChevronLeft from '@assets/svg/ChevronLeft';
import ChevronRightWhite from '@assets/svg/ChevronRightWhite';
import WhiteClose from '@assets/svg/WhiteClose';
import { Font } from '@components/atoms';
import { useEffect, useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { BeatLoader } from 'react-spinners';
import styled from 'styled-components';

type Props = {
  onLoadSuccess: ({ numPages }: pdfjs.PDFDocumentProxy) => void;
  onNextPage: () => void;
  onPrevPage: () => void;
  onToggle: () => void;
  path: string;
  pageNumber: number;
  prescriptionNumPages: number;
};

function PDFViewer({
  path,
  onLoadSuccess,
  onNextPage,
  onPrevPage,
  onToggle,
  pageNumber,
  prescriptionNumPages,
}: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (ref.current) {
      setWidth(ref.current?.offsetWidth);
    }
  }, [ref]);

  return (
    <Container ref={ref}>
      <Header>
        <WhiteClose onClick={onToggle} />
      </Header>

      <Document
        file={path}
        onLoadSuccess={onLoadSuccess}
        loading={<BeatLoader size={15} loading color="#3478F6" />}
      >
        <PDFView pageNumber={pageNumber} width={width} height={500} />

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '12px',
            gap: '8px',
          }}
        >
          {pageNumber > 1 ? <ChevronLeft onClick={onPrevPage} /> : null}

          <Font color="fill/white" fontType="body2">
            {prescriptionNumPages}페이지 중 {pageNumber}번
          </Font>

          {pageNumber < prescriptionNumPages ? (
            <ChevronRightWhite onClick={onNextPage} />
          ) : null}
        </div>
      </Document>

      <ButtonContainer>
        <CloseButton onClick={onToggle}>닫기</CloseButton>
      </ButtonContainer>
    </Container>
  );
}

export default PDFViewer;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  max-width: 720px;
  height: 100%;
  margin: 0 auto;

  display: flex;

  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  gap: 86px;

  overflow-y: scroll;

  background-color: #1e2024;

  z-index: 200;
`;

const Header = styled.div`
  width: 100%;
  height: 48px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const PDFView = styled(Page)`
  display: flex;
  position: relative;
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 8px 20px 28px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const CloseButton = styled.button`
  display: flex;
  width: 100%;
  padding: 16px 12px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  text-align: center;

  color: #ffffff;
  text-align: center;

  font-family: Pretendard, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;

  border-radius: 12px;
  background: #474a52;
`;
