import { ButtonContainer, Title } from './index.style';

interface SmallButtonInterface {
  title: string;
  onClick: Function;
}

function SmallButton({ title, onClick }: SmallButtonInterface) {
  return (
    <ButtonContainer onClick={() => onClick()}>
      <Title>{title}</Title>
    </ButtonContainer>
  );
}

export default SmallButton;
