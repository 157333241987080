import styled from 'styled-components';
import { BlankItemType } from './index.d';

export const HorizontalBlank = styled.div<BlankItemType>`
  width: ${(props) => props.size}px;
`;

export const VerticalBlank = styled.div<BlankItemType>`
  height: ${(props) => props.size}px;
`;
