import { COLOR, ColorType } from '@components/atoms/color';
import SvgIcon from '@components/atoms/images';
import {
  getDotTimeFormatter,
  getSubInjectTimeFormatter,
} from '@functions/formatters/timeFormatter';
import { ChangeEvent, forwardRef } from 'react';
import styled from 'styled-components';

interface DatePickerInputProps {
  value: string;
  onChange: (value: string) => void;
  onClick: () => void;
  height?: number | string;
  width?: number | string;
  fontSize?: number;
  fontWeight?: 400 | 500 | 600 | 700 | 800;
  padding?: string;
  iconWidth?: number;
  iconColor?: ColorType;
  textColor?: string;

  exceptDay?: boolean;
}

const DatePickerInput = forwardRef<HTMLInputElement, DatePickerInputProps>(
  (
    {
      value,
      onChange,
      onClick,
      padding,
      height,
      iconWidth,
      iconColor,
      fontWeight,
      width,
      textColor,
      fontSize,
      exceptDay,
    },
    ref,
  ) => {
    const onDatePickerChange = (e: ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value);
    };

    return (
      <>
        <InputContainer
          height={height}
          width={width}
          fontSize={fontSize}
          textColor={textColor}
          fontWeight={fontWeight}
          padding={padding}
          ref={ref}
          type="text"
          value={
            exceptDay
              ? getDotTimeFormatter(value)
              : getSubInjectTimeFormatter(value)
          }
          onClick={onClick}
          onChange={onDatePickerChange}
        />
        <IconContainer>
          <SvgIcon
            icon="calendar"
            width={iconWidth ?? 17}
            color={iconColor ?? 'fill/black'}
          />
        </IconContainer>
      </>
    );
  },
);

const InputContainer = styled.input<
  Omit<DatePickerInputProps, 'value' | 'onChange' | 'iconWidth'>
>`
  display: flex;
  width: ${(props) => props.width ?? 260}px;
  max-width: ${(props) => props.width ?? 260}px;
  height: ${(props) => props.height ?? 38}px;
  font-size: ${(props) => props.fontSize ?? 12}px;
  font-weight: ${(props) => props.fontWeight ?? 400};
  color: ${(props) => props.textColor ?? COLOR['fill/black']};
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  align-items: center;
  padding: ${(props) => props.padding ?? '0 12px'};
`;

const IconContainer = styled.div`
  display: flex;
  position: absolute;
  align-items: center;
  top: 11px;
  right: 12px;
`;

export default DatePickerInput;
