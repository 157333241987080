import * as Style from './index.style';

interface AllowToastProps {
  icon?: JSX.Element;
  title: JSX.Element;
}

function AllowToast({ icon, title }: AllowToastProps) {
  return (
    <Style.AllowToastArea>
      {icon || null}
      {title}
    </Style.AllowToastArea>
  );
}

export default AllowToast;
