import { Mixpanel } from '@utils/mixpanel';

const confirmedTreatmentMixpanelEvents = {
  pageDeliveryRequestedList: '[Page] Delivery Requested List',
  pageDeliveryRequestedDetail: '[Page] Delivery Requested Detail',
} as const;

interface ConfirmedTreatmentMixpanelEventsMapProps {
  pageDeliveryRequestedList: ({
    listCount,
    pharmacyId,
    deliveryMethod,
  }: {
    listCount: number;
    pharmacyId: string;
    deliveryMethod: string;
  }) => void;
  pageDeliveryRequestedDetail: ({
    tppId,
    phId,
    tmId,
    deliveryMethod,
    symptomKeyword,
    tmGuideType,
  }: {
    tppId: string;
    phId: string;
    tmId: string;
    deliveryMethod: string;
    symptomKeyword: string;
    tmGuideType: string;
  }) => void;
}

export const trackConfirmedTreatment: ConfirmedTreatmentMixpanelEventsMapProps =
  {
    pageDeliveryRequestedList: ({ listCount, pharmacyId, deliveryMethod }) =>
      Mixpanel.track(
        confirmedTreatmentMixpanelEvents.pageDeliveryRequestedList,
        {
          'Number of List': listCount,
          'PH ID': pharmacyId,
          'Delivery Method': deliveryMethod,
        },
      ),
    pageDeliveryRequestedDetail: ({
      tppId,
      phId,
      tmId,
      deliveryMethod,
      symptomKeyword,
      tmGuideType,
    }) =>
      Mixpanel.track(
        confirmedTreatmentMixpanelEvents.pageDeliveryRequestedDetail,
        {
          'TPP ID': tppId,
          'PH ID': phId,
          'TM ID': tmId,
          'Delivery Method':
            deliveryMethod === '방문' ? '방문수령' : deliveryMethod,
          'Symptom Keyword': symptomKeyword,
          'TM Guide Type': tmGuideType,
        },
      ),
  };
