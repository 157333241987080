import { useAppDispatch, useAppSelector } from '@stores/hooks';
import {
  closeModal_signupModal,
  selectSignupModalVisible,
} from '@stores/modalStore/signupModal';
import { useCallback } from 'react';

const useSignupModalHook = () => {
  const dispatch = useAppDispatch();
  const isVisible = useAppSelector(selectSignupModalVisible);

  const closeModal = useCallback(() => {
    dispatch(closeModal_signupModal());
  }, [dispatch]);

  return { isVisible, closeModal };
};

export default useSignupModalHook;
