import styled from 'styled-components';

export const TitleArea = styled.div``;

export const DescriptionArea = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
`;

export const CustomerCenterButtonArea = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
