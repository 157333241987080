import styled from 'styled-components';

export const DefaultListContent = styled.div<{
  flex: number;
  flexDirection: 'row' | 'column';
}>`
  height: 60px;
  flex: ${({ flex }) => flex};
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection};
  align-items: center;
  justify-content: center;
  text-align: center;
`;
