import { BColor } from '@components/meraki-ui/BColor';
import { IconDetailProps } from '@components/meraki-ui/BIcon/svg.type';

export default function ExclamationInCircleIcon({
  width,
  height,
  color,
}: IconDetailProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM10 6C9.46459 6 9.04623 6.46229 9.0995 6.99504L9.45025 10.5025C9.47849 10.7849 9.71616 11 10 11C10.2838 11 10.5215 10.7849 10.5498 10.5025L10.9005 6.99504C10.9538 6.46228 10.5354 6 10 6ZM10.0015 12C9.44926 12 9.00154 12.4477 9.00154 13C9.00154 13.5523 9.44926 14 10.0015 14C10.5538 14 11.0015 13.5523 11.0015 13C11.0015 12.4477 10.5538 12 10.0015 12Z"
        fill={BColor[color]}
      />
    </svg>
  );
}
