import { newLogo } from '@assets/index';
import { Font } from '@components/atoms';
import { openModal_changeServerURLModal } from '@stores/modalStore/changeServerURLModal';
import { trackLoginMixpanelEvents } from '@utils/mixpanel/login';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { commonHooks } from '@hooks/index';
import { useAppDispatch, useAppSelector } from '@stores/hooks';
import { selectUserInformationIsLogin } from '@stores/userInformationStore';
import { setSessionStorageState } from '@utils/storageHandler';
import { FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  FindIdPasswordContainer,
  LoginContainer,
  ResetPasswordContainer,
} from './containers';
import { useFindIdPasswordForm } from './containers/find-id-password/hooks';
import {
  ELoginType,
  useLoginForm,
  useSelectLoginType,
} from './containers/login/hooks';
import * as Style from './index.style';

export enum EFormType {
  'LOGIN' = 'LOGIN',
  'SIGN_UP' = 'SIGN_UP',
  'PHONE_LOGIN' = 'PHONE_LOGIN',
  'RESET_PASSWORD' = 'RESET_PASSWORD',
  'FIND_ID_PASSWORD' = 'FIND_ID_PASSWORD',
  'AUTHENTICATION' = 'AUTHENTICATION',
}

function LoginPage() {
  const [formType, setFormType] = useState<EFormType>(EFormType.LOGIN);
  const updateFormType = (type: EFormType) => {
    setFormType(type);
  };

  const [firstLoginEmail, setFirstLoginEmail] = useState<string>('');
  const [firstLoginPassword, setFirstLoginPassword] = useState<string>('');

  // 회원가입후 첫로그인시 비밀번호재설정로직..
  const { useQuery, useMount } = commonHooks;
  const query = useQuery();
  useMount(() => {
    const queryEmail = query.get('id');
    const queryPassword = query.get('password');

    if (queryEmail && queryPassword) {
      setFormType(EFormType.RESET_PASSWORD);
      setSessionStorageState('email', queryEmail);
      setFirstLoginEmail(queryEmail);
      setFirstLoginPassword(queryPassword);
    }
  });

  const dispatch = useAppDispatch();

  const [logoClickCount, setLogoClickCount] = useState<number>(0);
  const increaseLogoClickCount = useCallback(() => {
    if (
      process.env.REACT_APP_SERVER === 'DEV' ||
      process.env.REACT_APP_SERVER === 'LOCAL'
    ) {
      setLogoClickCount((value) => value + 1);
      if (logoClickCount >= 5) {
        dispatch(openModal_changeServerURLModal());
        setLogoClickCount(0);
      }
    }
  }, [dispatch, logoClickCount]);

  const isLogin = useAppSelector(selectUserInformationIsLogin);
  const navigate = useNavigate();
  useEffect(() => {
    if (isLogin) {
      navigate('/home', { replace: true });
    }
  });

  useEffect(() => {
    trackLoginMixpanelEvents('pageLogin');
  });

  const { methods: findIdPasswordMethods } = useFindIdPasswordForm();

  const { emailLoginMethods, phoneLoginMethods } = useLoginForm();

  const { updateLoginType, selectedLoginType } = useSelectLoginType();

  const methods = useMemo(() => {
    if (selectedLoginType === ELoginType.EMAIL) {
      return emailLoginMethods;
    }
    return phoneLoginMethods;
  }, [emailLoginMethods, phoneLoginMethods, selectedLoginType]);

  return (
    <Style.Container>
      <Style.LoginArea>
        <Style.LogoArea onClick={() => increaseLogoClickCount()}>
          <Font fontType="h3" color="fill/white">
            대한민국 대표 비대면 진료 플랫폼
          </Font>
          <Style.MainLogo src={newLogo} />
        </Style.LogoArea>
        <div style={{ display: 'flex', height: 640, width: 480 }}>
          {formType === EFormType.LOGIN && (
            // @ts-ignore
            <FormProvider {...methods}>
              <LoginContainer
                updateFormType={updateFormType}
                selectedLoginType={selectedLoginType}
                updateLoginType={updateLoginType}
              />
            </FormProvider>
          )}
          {formType === EFormType.FIND_ID_PASSWORD && (
            <FormProvider {...findIdPasswordMethods}>
              <FindIdPasswordContainer updateFormType={updateFormType} />
            </FormProvider>
          )}
          {formType === EFormType.RESET_PASSWORD && (
            <ResetPasswordContainer updateFormType={updateFormType} />
          )}
        </div>
      </Style.LoginArea>
    </Style.Container>
  );
}

export default LoginPage;
