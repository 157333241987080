import { IconDetailProps } from '@components/atoms/images';

function SpeakerIcon({ width, height, color }: IconDetailProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M13.7287 20.8117C13.6577 20.3933 13.4347 20.0208 13.1002 19.7708L10.0796 17.4902C9.85665 17.3218 9.53736 17.3677 9.37011 17.5922L7.84967 19.6331C7.68749 19.8525 7.72297 20.1586 7.93076 20.3321L10.6574 22.623C10.9514 22.8679 11.3213 23.0005 11.7014 23.0005H11.8434C12.2792 23.0005 12.6897 22.8322 12.9938 22.5209L13.2675 22.2454C13.6425 21.8678 13.8148 21.3321 13.7287 20.8117Z"
        fill="#1750D3"
      />
      <path
        d="M20.0132 12.6023C18.0569 13.2707 13.6831 14.9442 11.0122 17.3983L6.08091 12.4339L5.71094 12.0615C8.1487 9.37262 9.81105 4.96944 10.475 3L20.0132 12.6023Z"
        fill="#99C5FF"
      />
      <path
        d="M11.0124 17.3983C10.8857 17.5106 10.759 17.6279 10.6424 17.7504L8.02219 20.3882C7.52552 20.8831 6.8768 21.1331 6.22808 21.1331C5.57936 21.1331 4.93064 20.8831 4.43396 20.3882L2.74121 18.6841C1.75293 17.6892 1.75293 16.0667 2.74121 15.0717L5.00159 12.7962L5.36143 12.4339C5.48307 12.3166 5.59963 12.189 5.71113 12.0615L6.0811 12.4339L11.0124 17.3983ZM21.6554 11.5972C21.4983 11.837 21.2905 12.0359 21.0473 12.1941C20.9003 12.286 20.7381 12.3625 20.5658 12.4186C20.4137 12.4696 20.2262 12.5258 20.0134 12.6023L10.4752 2.99999C10.5512 2.7857 10.6069 2.59692 10.6576 2.44385C10.7134 2.27038 10.7894 2.10711 10.8806 1.95914C11.0377 1.71424 11.2354 1.50505 11.4736 1.34688C12.2896 0.800949 13.3843 0.908095 14.0735 1.60709L21.397 8.97974C22.0913 9.67364 22.1977 10.7757 21.6554 11.5972Z"
        fill="#4785FF"
      />
    </svg>
  );
}

export default SpeakerIcon;
