import { COLOR } from '@components/atoms';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  background-color: ${COLOR['fill/dark']};
`;

export const Content = styled.div`
  border-bottom: 1px solid ${COLOR['border/divider']};
  overflow-x: scroll;
  display: flex;
  flex-direction: row;
  gap: 30px;
  padding: 12px 16px;
  width: 720px;
  overflow: auto;
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  justify-content: center;
`;

export const QrCodeBottomContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const BiggerQrCodeModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
`;

export const BiggerQrCodeModalTopContainer = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const BiggerQrCodeModalTitleContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const BiggerQrCodeModalContent = styled.div<{
  isQrCodeMoreThanOne: boolean;
}>`
  display: grid;
  grid-template-columns: ${({ isQrCodeMoreThanOne }) =>
    isQrCodeMoreThanOne ? '1fr 1fr' : '1fr'};
  gap: 20px;
  max-height: 622px;
`;

export const PrescriptionErrorCase = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  width: 100%;
  background-color: ${COLOR['fill/white']};
`;
