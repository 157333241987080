import { Mixpanel } from '@utils/mixpanel';

const newReservationRequestedMixpanelEvents = {
  pageTPRequested: '[Page] TP Requested',
} as const;

interface NewReservationRequestedMixpanelEventsProps {
  pageTPRequested: ({
    tppId,
    pharmacyId,
    deliveryMethod,
    symptomKeyword,
    tmGuideType,
    isAutoConfirmed,
  }: {
    tppId: string | null;
    pharmacyId: string | null;
    deliveryMethod: string | null;
    symptomKeyword: string | null;
    tmGuideType: string | null;
    isAutoConfirmed: boolean;
  }) => void;
}

export const trackNewReservationRequested: NewReservationRequestedMixpanelEventsProps =
  {
    pageTPRequested: ({
      tppId,
      pharmacyId,
      deliveryMethod,
      symptomKeyword,
      tmGuideType,
      isAutoConfirmed,
    }) =>
      Mixpanel.track(newReservationRequestedMixpanelEvents.pageTPRequested, {
        'TPP ID': tppId,
        'PH ID': pharmacyId,
        'Delivery Method': deliveryMethod,
        'Symptom Keyword': symptomKeyword,
        'TM Guide Type': tmGuideType,
        'Is Auto Confirmed': isAutoConfirmed,
      }),
  };
