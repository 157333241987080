import { useAppDispatch, useAppSelector } from '@stores/hooks';
import {
  changeSymptomImageModalIndex,
  closeModal_symptomImageModal,
  selectSymptomImageModalIndex,
  selectSymptomImageModalItem,
  selectSymptomImageModalVisible,
} from '@stores/modalStore/symptomImageModal';
import { useCallback, useEffect, useState } from 'react';

const useSymptomImageModalHook = () => {
  const dispatch = useAppDispatch();
  const isVisible = useAppSelector(selectSymptomImageModalVisible);
  const item = useAppSelector(selectSymptomImageModalItem);
  const index = useAppSelector(selectSymptomImageModalIndex);

  const [imageLength, setImageLength] = useState<number>(4);

  useEffect(() => {
    let imageCount = 0;
    if (item?.tm?.symptom_image1) imageCount += 1;
    if (item?.tm?.symptom_image2) imageCount += 1;
    if (item?.tm?.symptom_image3) imageCount += 1;
    if (item?.tm?.symptom_image4) imageCount += 1;

    setImageLength(imageCount);

    return () => setImageLength(4);
  }, [
    item?.tm?.symptom_image1,
    item?.tm?.symptom_image2,
    item?.tm?.symptom_image3,
    item?.tm?.symptom_image4,
  ]);

  const closeModal = useCallback(() => {
    dispatch(closeModal_symptomImageModal());
  }, [dispatch]);

  const minusImageIndex = useCallback(() => {
    dispatch(changeSymptomImageModalIndex(index - 1));
  }, [dispatch, index]);

  const plusImageIndex = useCallback(() => {
    if (index + 1 < imageLength) {
      dispatch(changeSymptomImageModalIndex(index + 1));
    }
  }, [dispatch, index, imageLength]);

  let imageIndex;

  switch (index) {
    case 0:
      imageIndex = item?.tm?.symptom_image1;
      break;
    case 1:
      imageIndex = item?.tm?.symptom_image2;
      break;
    case 2:
      imageIndex = item?.tm?.symptom_image3;
      break;
    case 3:
      imageIndex = item?.tm?.symptom_image4;
      break;
    default:
      break;
  }

  return {
    isVisible,
    closeModal,
    imageIndex,
    minusImageIndex,
    plusImageIndex,
  };
};

export default useSymptomImageModalHook;
