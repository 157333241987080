import useFindIdPasswordModalHook from './hooks';
import FindIdPasswordModalTemplate from './templates';

function FindIdPasswordModal() {
  const { isVisible, closeModal } = useFindIdPasswordModalHook();

  return (
    <FindIdPasswordModalTemplate
      isVisible={isVisible}
      closeModal={closeModal}
    />
  );
}

export default FindIdPasswordModal;
