import { Font } from '@components/atoms';
import {
  TextInputContainer,
  TextInputTitle,
} from '@components/atoms/textInput/index.style';
import { ChangeEvent, FocusEvent, KeyboardEvent } from 'react';
import styled from 'styled-components';
import { COLOR } from '../color';

type TextAreaType = {
  value: string | number;
  title?: string;
  type: 'text' | 'password' | 'number' | 'date';
  height?: number | string;
  width?: number | string;
  fontSize?: number | string;

  placeholder?: string;
  disabled?: boolean;
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onKeyPress?: (e: KeyboardEvent<HTMLTextAreaElement>) => void;
  onFocus?: (e: FocusEvent<HTMLTextAreaElement, Element>) => void;
  onBlur?: (e: FocusEvent<HTMLTextAreaElement, Element>) => void;
  backgroundColor?: string;
  padding?: number;
};

export default function TextArea({
  title,
  onChange,
  value,
  placeholder,
  onKeyPress,
  width,
  height,
  fontSize,
  onFocus,
  onBlur,
  backgroundColor,
  padding,
  type,
  disabled,
}: TextAreaType) {
  return (
    <TextInputContainer>
      {title && (
        <TextInputTitle>
          <Font fontType="body2" color="fill/black">
            {title}
          </Font>
        </TextInputTitle>
      )}
      <TextAreaComponent
        value={value}
        onChange={onChange}
        onKeyPress={onKeyPress}
        placeholder={placeholder}
        width={width}
        height={height}
        fontSize={fontSize}
        onFocus={onFocus}
        onBlur={onBlur}
        backgroundColor={backgroundColor}
        padding={padding}
        disabled={disabled}
      />
    </TextInputContainer>
  );
}

export type TextAreaContentType = Pick<
  TextAreaType,
  'width' | 'height' | 'fontSize' | 'backgroundColor' | 'padding'
>;

const TextAreaComponent = styled.textarea<TextAreaContentType>`
  width: ${(props) => (props.width ? `${props.width}px` : '100%')};
  height: ${(props) => (props.height ? props.height : 48)}px;
  padding: ${(props) => (props.padding ? props.padding : 20)}px;
  border-width: 1px;
  border-color: ${COLOR['border/outline']};
  font: 400;
  font-size: ${(props) => props.fontSize || '18px'};
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  border-radius: 4px;
  font-size: ${(props) => props.fontSize || '18px'};
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : ''};
  &::-webkit-input-placeholder {
    color: ${COLOR['border/outline']};
  }
  &:-ms-input-placeholder {
    color: ${COLOR['border/outline']};
  }
  &::placeholder {
    color: ${COLOR['border/outline']};
  }
`;
