import { COLOR } from '@components/atoms';
import styled from 'styled-components';

export const ToggleWrapper = styled.div`
  width: 335px;
  height: 48px;
  border-radius: 12px;
  background-color: ${COLOR.greyscale2};
  display: flex;
  align-items: center;
  padding: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
`;

export const FontArea = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 164px;
  height: 40px;
`;

export const ToggleCircle = styled.div<{ isActive: boolean }>`
  position: absolute;
  display: flex;
  width: 164px;
  height: 40px;
  border-radius: 8px;
  padding: 0 8px;
  background-color: ${COLOR['fill/white']};
  transition: transform 0.3s ease;

  transform: ${(props) =>
    props.isActive ? 'translateX(164px)' : 'translateX(0)'};
`;
