import { useAppDispatch, useAppSelector } from '@stores/hooks';
import {
  closeCommonModal,
  openCommonModal,
  selectCommonModalVisible,
} from '@stores/modalStore/commonModal';
import { useCallback } from 'react';

interface UseCommonModalProps {
  leftButtonCallback?: () => void;
  rightButtonCallback?: () => void;
}

const useCommonModal = ({
  leftButtonCallback,
  rightButtonCallback,
}: UseCommonModalProps) => {
  const dispatch = useAppDispatch();
  const isVisible = useAppSelector(selectCommonModalVisible);

  const closeModal = useCallback(() => {
    dispatch(closeCommonModal());
  }, [dispatch]);

  const openModal = useCallback(() => {
    dispatch(openCommonModal());
  }, [dispatch]);

  // TODO toolkit이 아닌 zustand 같은 라이브러리로 함수를 전역으로 관리하는 것이 가능한지 서칭 필요
  const clickLeftButton = useCallback(() => {
    if (leftButtonCallback) {
      // dispatch(setLeftButtonCallback(leftButtonCallback));
    }
  }, [leftButtonCallback]);

  // TODO toolkit이 아닌 zustand 같은 라이브러리로 함수를 전역으로 관리하는 것이 가능한지 서칭 필요
  const clickRightButton = useCallback(() => {
    if (rightButtonCallback) {
      // dispatch(setRightButtonCallback(rightButtonCallback));
    }
  }, [rightButtonCallback]);

  return {
    isVisible,
    closeModal,
    openModal,
    clickLeftButton,
    clickRightButton,
  };
};

export default useCommonModal;
