import BFlex from '@components/meraki-ui/BFlex';
import BIcon from '@components/meraki-ui/BIcon';
import BTypography from '@components/meraki-ui/BTypography';
import { memo, ReactNode } from 'react';

interface BModalHeaderProps {
  title: string | ReactNode;
  onOpenChange: () => void;
}

function BModalHeader({ title, onOpenChange }: BModalHeaderProps) {
  return (
    <BFlex borderBottomExisted width="100%">
      <BFlex
        width="100%"
        padding="2rem 2rem 1.5rem 2rem"
        justifyContent="space-between"
      >
        <BTypography isBold text={title} size={24} color="mono08" />
        <BFlex alignItems="flex-start" padding="0 0 0 2rem">
          <BIcon icon="x" onClick={onOpenChange} color="mono08" />
        </BFlex>
      </BFlex>
    </BFlex>
  );
}

export default memo(BModalHeader);
