import styled from 'styled-components';

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 30px;
  background-color: pink;
  border-radius: 4px;
  cursor: pointer;
`;

export const Title = styled.span`
  font-size: 15px;
  font-weight: 500;
`;
