import { BoxContainer } from '@components/atoms';
import styled from 'styled-components';
import { styleVariables } from '@constants';

const { BREAK_POINTS } = styleVariables;

export const ContentItem = styled(BoxContainer)`
  display: flex;
  flex-direction: column;
  padding: 32px;
  border-radius: 16px;
  box-shadow: 1px 1px 20px 1px rgba(196, 196, 196, 0.3);
  width: 600px;
  margin-bottom: 12px;
  margin-right: 12px;
`;

export const RightContentItem = styled(ContentItem)`
  width: 500px;
  @media (max-width: ${BREAK_POINTS.size1440}px) {
    width: 600px;
  }
`;

export const ContentHeaderArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ContentHeaderTitle = styled.div``;

export const ContentHeaderButton = styled.div`
  display: flex;
  align-items: center;
`;

export const ContentBodyArea = styled.div``;

export const ContentBodyContainer = styled.div`
  display: flex;
  padding: 12px 0;
`;

export const ContentBodyTitleArea = styled.div`
  display: flex;
  width: 180px;
  align-items: flex-start;
`;

export const ContentBodyTitle = styled.div``;

export const ContentBodyTextArea = styled.div`
  display: flex;
  width: 260px;
`;

export const ContentBodyText = styled.div`
  margin-right: 16px;
  width: 260px;
  height: 100%;
  word-wrap: break-word;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
