export function getNotificationPermissionStatus() {
  if ('Notification' in window) {
    if (Notification.permission === 'default') {
      return 'default - 사용자 선택 사항을 알 수 없으므로 브라우저는 값이 거부된 것처럼 작동합니다.';
    }
    if (Notification.permission === 'granted') {
      return 'granted - 사용자가 알림 표시를 수락합니다.';
    }
    if (Notification.permission === 'denied') {
      return 'denied - 사용자가 알림 표시를 거부합니다.';
    }
  }

  return '이 브라우저는 알림을 지원하지 않습니다.';
}

export function getBrowserInfo() {
  if (window.navigator === undefined) {
    return 'Unknown browser';
  }

  const { userAgent } = navigator;

  if (userAgent.includes('Firefox')) {
    return 'Firefox';
  }
  if (userAgent.includes('Edg')) {
    return 'Edge';
  }
  if (userAgent.includes('Chrome') && !userAgent.includes('Edg')) {
    return 'Chrome';
  }
  if (userAgent.includes('Safari') && !userAgent.includes('Chrome')) {
    return 'Safari';
  }
  if (userAgent.includes('Opera') || userAgent.includes('OPR')) {
    return 'Opera';
  }
  return 'Unknown browser';
}

export function getOS() {
  const platform = navigator.platform.toLowerCase();

  if (platform.indexOf('win') !== -1) {
    return 'Windows';
  }
  if (platform.indexOf('mac') !== -1) {
    return 'macOS';
  }
  return 'Windows';
}
