import { useAppDispatch, useAppSelector } from '@stores/hooks';
import {
  closeModal_medicineUnitManagementModal,
  openModal_medicineUnitManagementModal,
  selectedMedicineUnitManagementModalVisible,
  set_selectedMedicineCategory,
  setSelectedMedicineCategory,
} from '@stores/modalStore/medicineUnitManagementModal';

import { useCallback } from 'react';

const useMedicineUnitManagementModalHook = () => {
  const dispatch = useAppDispatch();
  const isVisible = useAppSelector(selectedMedicineUnitManagementModalVisible);
  const selectedCategory = useAppSelector(setSelectedMedicineCategory);

  const closeModal = useCallback(() => {
    dispatch(closeModal_medicineUnitManagementModal());
  }, [dispatch]);

  const openModal = useCallback(() => {
    dispatch(openModal_medicineUnitManagementModal());
  }, [dispatch]);

  const setSelectedCategory = useCallback(
    (category: string) => {
      dispatch(set_selectedMedicineCategory(category));
    },
    [dispatch],
  );

  return {
    isVisible,
    selectedCategory,
    closeModal,
    openModal,
    setSelectedCategory,
  };
};

export default useMedicineUnitManagementModalHook;
