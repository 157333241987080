import { BColor } from '@components/meraki-ui/BColor';
import { IconDetailProps } from '@components/meraki-ui/BIcon/svg.type';

export default function ChevronDownIcon({
  width,
  height,
  color,
}: IconDetailProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5692 8.28577C15.8071 8.69573 15.6659 9.21995 15.2539 9.45665L10.0309 12.4566L9.16937 10.972L14.3923 7.97203C14.8044 7.73534 15.3313 7.8758 15.5692 8.28577Z"
        fill={BColor[color]}
      />
      <path
        d="M9.96875 12.4565L4.74581 9.45654C4.33373 9.21985 4.19255 8.69563 4.43046 8.28566C4.66837 7.8757 5.1953 7.73523 5.60737 7.97193L10.8303 10.9719L9.96875 12.4565Z"
        fill={BColor[color]}
      />
    </svg>
  );
}
