import { COLOR } from '@components/atoms';
import styled from 'styled-components';

export const DefaultListContent = styled.div<{
  flex: number;
  flexDirection: 'row' | 'column';
}>`
  height: 56px;
  flex: ${({ flex }) => flex};
  display: flex;

  flex-direction: ${({ flexDirection }) => flexDirection};

  word-break: break-all;
  position: relative;
  align-items: center;

  border-right: 1px solid ${COLOR['border/divider']};
  border-bottom: 1px solid ${COLOR['border/divider']};

  &:last-child {
    border-right: none;
  }
`;
