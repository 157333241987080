import { BColor } from '@components/meraki-ui/BColor';
import { IconDetailProps } from '@components/meraki-ui/BIcon/svg.type';

export default function DropdownIcon({
  width,
  height,
  color,
}: IconDetailProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.24775 13.3992L4.45152 7.91778C3.88576 7.2712 4.34494 6.25928 5.20409 6.25928H14.7966C15.6557 6.25928 16.1149 7.2712 15.5491 7.91778L10.7529 13.3992C10.3545 13.8545 9.64616 13.8545 9.24775 13.3992Z"
        fill={BColor[color]}
      />
    </svg>
  );
}
