import { BColor } from '@components/meraki-ui/BColor';
import { IconDetailProps } from '@components/meraki-ui/BIcon/svg.type';

export default function UserIDIcon({ width, height, color }: IconDetailProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.5 4C8.22386 4 8 4.22386 8 4.5C8 4.77614 8.22386 5 8.5 5H11.5C11.7761 5 12 4.77614 12 4.5C12 4.22386 11.7761 4 11.5 4H8.5Z"
        fill={BColor[color]}
      />
      <path
        d="M13 10C13 11.6569 11.6569 13 10 13C8.34315 13 7 11.6569 7 10C7 8.34315 8.34315 7 10 7C11.6569 7 13 8.34315 13 10Z"
        fill={BColor[color]}
      />
      <path
        d="M6.5 2C5.11929 2 4 3.11929 4 4.5V16C4 17.1046 4.89543 18 6 18H14C15.1046 18 16 17.1046 16 16V4.5C16 3.11929 14.8807 2 13.5 2H6.5ZM5 4.5C5 3.67157 5.67157 3 6.5 3H13.5C14.3284 3 15 3.67157 15 4.5V15.295C14.8152 15.1446 14.5619 14.9719 14.2236 14.8028C13.392 14.387 12.0628 14 10 14C7.9372 14 6.60796 14.387 5.77639 14.8028C5.43811 14.9719 5.18477 15.1446 5 15.295V4.5Z"
        fill={BColor[color]}
      />
    </svg>
  );
}
