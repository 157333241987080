import { BColor } from '@components/meraki-ui/BColor';
import { IconDetailProps } from '@components/meraki-ui/BIcon/svg.type';

export default function PauseInCircleIcon({
  width,
  height,
  color,
}: IconDetailProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10C17 13.866 13.866 17 10 17ZM10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18Z"
        fill={BColor[color]}
      />
      <path
        d="M7 8.25C7 7.55964 7.55964 7 8.25 7C8.94036 7 9.5 7.55964 9.5 8.25V11.75C9.5 12.4404 8.94036 13 8.25 13C7.55964 13 7 12.4404 7 11.75V8.25ZM10.5 8.25C10.5 7.55964 11.0596 7 11.75 7C12.4404 7 13 7.55964 13 8.25V11.75C13 12.4404 12.4404 13 11.75 13C11.0596 13 10.5 12.4404 10.5 11.75V8.25Z"
        fill={BColor[color]}
      />
    </svg>
  );
}
