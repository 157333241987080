import { QUERY_KEY } from '@constants/react-query';
import { myDoctorAPI } from '@services/myDoctor';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import { isNil } from 'es-toolkit';

interface UseFetchPrescriptionDetailListProps {
  tmId: number | undefined;
  prescriptionId: number | undefined;
}

export const useSuspensePrescriptionDetailList = ({
  tmId,
  prescriptionId,
}: UseFetchPrescriptionDetailListProps) => {
  return useSuspenseQuery({
    queryKey: [QUERY_KEY.TP_PRESCRIPTION, prescriptionId, tmId],
    queryFn: () =>
      !isNil(tmId) && !isNil(prescriptionId)
        ? myDoctorAPI.getPrescriptionDetailList({
            tmId,
            prescriptionId: String(prescriptionId),
          })
        : null,
    select: (data) => data?.data,
  });
};

export const usePrescriptionDetailList = ({
  tmId,
  prescriptionId,
}: UseFetchPrescriptionDetailListProps) => {
  return useQuery({
    queryKey: [QUERY_KEY.TP_PRESCRIPTION, prescriptionId, tmId],
    queryFn: () =>
      myDoctorAPI.getPrescriptionDetailList({
        tmId: tmId || 0,
        prescriptionId: String(prescriptionId),
      }),
    select: (data) => data?.data,
    enabled: !isNil(tmId) && !isNil(prescriptionId),
  });
};
