import { Button, COLOR, Font } from '@components/atoms';
import { memo } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import {
  EResetPasswordFormFields,
  IResetPasswordFormData,
  useResetPasswordSubmit,
} from '../../hooks';

type IResetPasswordCtaViewProps = {};

export const ResetPasswordCtaView = memo<IResetPasswordCtaViewProps>(({}) => {
  const { submit } = useResetPasswordSubmit();

  const { formState } = useFormContext<IResetPasswordFormData>();

  const error =
    formState.errors?.[EResetPasswordFormFields.PASSWORD] ||
    formState.errors?.[EResetPasswordFormFields.PASSWORD_CONFIRM];
  const disabled = error || Object.keys(formState.dirtyFields).length !== 2;

  return (
    <LoginButtonArea>
      <Button
        width="100%"
        padding="16px 12px"
        borderRadius="12px"
        title={
          <Font
            fontType="body1"
            color={disabled ? 'disabled/foreground' : 'fill/white'}
          >
            설정 완료
          </Font>
        }
        onClick={() => {
          if (disabled) return;
          submit();
          // submit();
        }}
        backgroundColor={
          disabled ? COLOR['disabled/background'] : COLOR['fill/accent']
        }
      />
    </LoginButtonArea>
  );
});

const LoginButtonArea = styled.div`
  width: 100%;
  display: flex;
`;
