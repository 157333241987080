import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { EmailLoginFormFields, PhoneLoginFormFields } from './login-form.type';

export const useLoginForm = () => {
  const emailLoginSchemaShape = {
    [EmailLoginFormFields.EMAIL]: yup
      .string()
      .matches(
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
        '이메일 형식에 맞지 않아요 (예시 sample@merakiplace.kr)',
      ),
    // .email('이메일 형식에 맞지 않아요 (예시 sample@merakiplace.kr)'),
    [EmailLoginFormFields.PASSWORD]: yup.string(),
    // .matches(
    //   /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>])[A-Za-z\d!@#$%^&*(),.?":{}|<>]{8,}$/,
    //   '영문/숫자/특수문자를 포함하여 8자 이상 입력해주세요',
    // ),
  };

  const phoneLoginSchemaShape = {
    [PhoneLoginFormFields.PHONE_NUMBER]: yup
      .string()
      .matches(/^\d+$/, '숫자만 입력해주세요') // 숫자가 아닌 문자열에 대한 예외 처리
      .matches(
        /^(01[016789]{1})-?[0-9]{3,4}-?[0-9]{4}$/,
        '휴대폰 번호 형식에 맞지 않아요 (예시: 01012345678)',
      ),
  };

  const emailLoginSchema = yup.object().shape(emailLoginSchemaShape);
  const phoneLoginSchema = yup.object().shape(phoneLoginSchemaShape);

  const emailLoginDefaultValues = {
    [EmailLoginFormFields.EMAIL]: '',
    [EmailLoginFormFields.PASSWORD]: '',
  };

  const phoneLoginDefaultValues = {
    [PhoneLoginFormFields.PHONE_NUMBER]: '',
  };

  const emailLoginMethods = useForm({
    defaultValues: {
      ...emailLoginDefaultValues,
    },
    mode: 'all',
    resolver: yupResolver(emailLoginSchema),
  });

  const phoneLoginMethods = useForm({
    defaultValues: {
      ...phoneLoginDefaultValues,
    },
    mode: 'all',
    resolver: yupResolver(phoneLoginSchema),
  });

  return {
    emailLoginMethods,
    phoneLoginMethods,
  };
};
