import { BColor } from '@components/meraki-ui/BColor';
import { IconDetailProps } from '@components/meraki-ui/BIcon/svg.type';

export default function ChatDefaultIcon({
  width,
  height,
  color,
}: IconDetailProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.00991 13.2146C5.20718 13.4124 5.30596 13.6879 5.27934 13.966C5.21967 14.5893 5.08223 15.2379 4.90572 15.8405C6.21363 15.5382 7.01241 15.1875 7.3755 15.0035C7.5816 14.8991 7.81901 14.8744 8.04217 14.9343C8.65899 15.0996 9.31637 15.1893 10.0001 15.1893C13.7465 15.1893 16.5636 12.5568 16.5636 9.56346C16.5636 6.5701 13.7465 3.93764 10.0001 3.93764C6.2537 3.93764 3.43666 6.5701 3.43666 9.56346C3.43666 10.94 4.01547 12.2173 5.00991 13.2146ZM4.54766 16.876C4.54135 16.8773 4.53502 16.8785 4.52869 16.8798C4.44259 16.8968 4.35482 16.9135 4.26536 16.9298C4.13989 16.9528 4.0111 16.9752 3.87896 16.9968C3.69236 17.0274 3.54952 16.8322 3.62323 16.658C3.67139 16.5443 3.71886 16.425 3.76512 16.3014C3.7945 16.2229 3.82339 16.1425 3.85165 16.0607C3.85287 16.0572 3.85408 16.0537 3.8553 16.0502C4.08759 15.3753 4.27675 14.5996 4.34597 13.8767C3.19575 12.7232 2.49902 11.2145 2.49902 9.56346C2.49902 5.93856 5.85738 3 10.0001 3C14.1429 3 17.5012 5.93856 17.5012 9.56346C17.5012 13.1884 14.1429 16.1269 10.0001 16.1269C9.2343 16.1269 8.49529 16.0265 7.79934 15.8399C7.31226 16.0867 6.26302 16.536 4.54766 16.876Z"
        fill={BColor[color]}
      />
      <path
        d="M6.24957 7.21937C6.24957 6.96045 6.45947 6.75055 6.71839 6.75055H13.2819C13.5408 6.75055 13.7507 6.96045 13.7507 7.21937C13.7507 7.47829 13.5408 7.68819 13.2819 7.68819H6.71839C6.45947 7.68819 6.24957 7.47829 6.24957 7.21937ZM6.24957 9.56346C6.24957 9.30454 6.45947 9.09465 6.71839 9.09465H13.2819C13.5408 9.09465 13.7507 9.30454 13.7507 9.56346C13.7507 9.82239 13.5408 10.0323 13.2819 10.0323H6.71839C6.45947 10.0323 6.24957 9.82239 6.24957 9.56346ZM6.24957 11.9076C6.24957 11.6486 6.45947 11.4387 6.71839 11.4387H10.4689C10.7279 11.4387 10.9378 11.6486 10.9378 11.9076C10.9378 12.1665 10.7279 12.3764 10.4689 12.3764H6.71839C6.45947 12.3764 6.24957 12.1665 6.24957 11.9076Z"
        fill={BColor[color]}
      />
    </svg>
  );
}
