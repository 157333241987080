import { BColor } from '@components/meraki-ui/BColor';
import { IconDetailProps } from '@components/meraki-ui/BIcon/svg.type';

export default function UserIcon({ width, height, color }: IconDetailProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 10C11.6569 10 13 8.65685 13 7C13 5.34315 11.6569 4 10 4C8.34315 4 7 5.34315 7 7C7 8.65685 8.34315 10 10 10ZM12 7C12 8.10457 11.1046 9 10 9C8.89543 9 8 8.10457 8 7C8 5.89543 8.89543 5 10 5C11.1046 5 12 5.89543 12 7Z"
        fill={BColor[color]}
      />
      <path
        d="M16 15C16 16 15 16 15 16H5C5 16 4 16 4 15C4 14 5 11 10 11C15 11 16 14 16 15ZM15 14.9965C14.9986 14.7497 14.8462 14.0104 14.1679 13.3321C13.5156 12.6798 12.2891 12 9.99999 12C7.71088 12 6.48435 12.6798 5.8321 13.3321C5.15375 14.0104 5.00142 14.7497 5 14.9965H15Z"
        fill={BColor[color]}
      />
    </svg>
  );
}
