import { Button, COLOR, Font } from '@components/atoms';
import SvgIcon from '@components/atoms/images';
import BModal from '@components/meraki-ui/BModal';
import {
  medicineCategoryList,
  medicineGenericCodeKoreanUnit,
  medicineGenericCodeUnit,
  medicineNameConfig,
} from '@components/organisms/managementMedicineTable/config/domain';
import { commonHooks } from '@hooks';
import { myDoctorAPI } from '@services/myDoctor';
import { ManagedMedicineItemType } from '@services/myDoctor/types';
import { useAppDispatch, useAppSelector } from '@stores/hooks';
import { getManagementMedicineList } from '@stores/telepharmacyStore/telepharmacyList';
import { selectUserInformationPharmacistData } from '@stores/userInformationStore';
import { useEffect, useRef, useState } from 'react';
import useMedicineManagementEditModalHook from './hooks';
import * as Style from './index.style';

function formatNumberWithCommas(value: string) {
  if (!value) return '';
  return new Intl.NumberFormat().format(Number(value));
}

function convertToInsuranceCode(code: string) {
  const insuranceCode = code.slice(3, -1);

  return parseInt(insuranceCode, 10);
}

function MedicineManagementEditModal() {
  const pharmacist = useAppSelector(selectUserInformationPharmacistData);
  const { isVisible, targetMedicine, closeModal } =
    useMedicineManagementEditModalHook();

  const [selectedMedicineCategory, setSelectedMedicineCategory] = useState(
    medicineCategoryList[0],
  );
  const [selectedMedicine, setSelectedMedicine] =
    useState<ManagedMedicineItemType | null>(null);
  const [medicineSearchInputValue, setMedicineSearchInputValue] = useState('');
  const [isMedicineSumInputAreaFocus, setIsMedicineSumInputAreaFocus] =
    useState(false);
  const [medicineSumInputValue, setMedicineSumInputValue] = useState('');
  const [isDisabledAddMedicineButton, setIsDisabledAddMedicineButton] =
    useState(true);

  const medicineSumInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (targetMedicine && targetMedicine?.id) {
      const findMedicineCategoryList = medicineCategoryList.find((item) =>
        item.codes.includes(targetMedicine.pharmacy_medicine.generic_name_code),
      );

      if (findMedicineCategoryList) {
        setSelectedMedicineCategory(findMedicineCategoryList);
      }

      setSelectedMedicine({
        id: targetMedicine.id,
        standard_code: targetMedicine.pharmacy_medicine.standard_code,
        generic_name_code: targetMedicine.pharmacy_medicine.generic_name_code,
        korean_product_name:
          medicineNameConfig[targetMedicine.pharmacy_medicine.standard_code],
        product_total_quantity: String(targetMedicine.quantity),
        dosage_form:
          medicineGenericCodeKoreanUnit[
            targetMedicine.pharmacy_medicine.generic_name_code
          ],
        packaging_type: targetMedicine.unit,
        special_general_type: '전문의약품',
        generic_name: null,
        master_code: targetMedicine.pharmacy_medicine.master_code, // TODO : master_code 추가
        default: false, // TODO : default 추가
        name: targetMedicine.pharmacy_medicine.name,
      });
      setMedicineSearchInputValue(
        `(${targetMedicine.pharmacy_medicine.master_code}) ${targetMedicine.pharmacy_medicine.name}`,
      );
      setMedicineSumInputValue(String(targetMedicine.price));
    }
  }, [targetMedicine]);

  const handleChangeMedicineSumInput = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const inputValue = e.target.value;
    const filteredValue = inputValue.replace(/[^0-9]/g, '');
    setMedicineSumInputValue(filteredValue);
  };

  const init = () => {
    setMedicineSearchInputValue('');
    setSelectedMedicine(null);
    setMedicineSumInputValue('');
    setIsMedicineSumInputAreaFocus(false);
    setSelectedMedicineCategory(medicineCategoryList[0]);
  };

  const { useQuery } = commonHooks;
  const query = useQuery();
  const dispatch = useAppDispatch();
  const [limit, setLimit] = useState<number>(200);
  const [isLoading, setIsLoading] = useState(false);

  const pageQuery = query.get('page');
  const page = Number(pageQuery) || 0;

  const handleAddMedicine = async () => {
    if (!selectedMedicine) return;

    setIsLoading(true);
    const {
      standard_code,
      generic_name_code,
      dosage_form,
      product_total_quantity,
    } = selectedMedicine;

    try {
      await myDoctorAPI
        .patchManagementMedicine({
          id: selectedMedicine.id,
          pharmacy_medicine: {
            id: selectedMedicine.id,
            standard_code: selectedMedicine.standard_code,
            created: null,
            modified: null,
            generic_name_code: selectedMedicine.generic_name_code,
            is_active: targetMedicine?.pharmacy_medicine.is_active || true,
            pharmacy: Number(pharmacist.pharmacy_id) || 0, // 약국 id
            master_code: selectedMedicine.master_code,
            name: selectedMedicine.name || selectedMedicine.korean_product_name,
          },
          created: null,
          modified: null,
          unit: medicineGenericCodeUnit[selectedMedicine.generic_name_code], // PEN
          quantity: Number(product_total_quantity) || 1,
          price: Number(medicineSumInputValue),
          is_deleted: false,
        })
        .then(() => {
          dispatch(
            getManagementMedicineList({
              id: pharmacist.pharmacy_id || 0,
              offset: page * limit,
              limit,
            }),
          );
        })
        .then(() => {
          setIsLoading(false);
          closeModal();
        })
        .finally(() => {
          setIsLoading(false);
        });
    } catch (e) {
      setIsLoading(false);
      console.log('handleAddMedicine error', e);
    }
  };

  useEffect(() => {
    if (isMedicineSumInputAreaFocus) {
      medicineSumInputRef.current?.focus();
    }
  }, [isMedicineSumInputAreaFocus]);

  useEffect(() => {
    if (
      medicineSumInputValue.length >= 3 &&
      selectedMedicine?.korean_product_name
    ) {
      setIsDisabledAddMedicineButton(false);
    } else {
      setIsDisabledAddMedicineButton(true);
    }
  }, [medicineSumInputValue, selectedMedicine]);

  return (
    <BModal
      open={isVisible}
      width={480}
      padding={30}
      onOpenChange={() => {
        init();
        closeModal();
      }}
    >
      <Style.ModalArea>
        <Style.TitleArea>
          <Font fontType="h1">의약품 가격 수정</Font>
          <SvgIcon
            icon="xLg"
            width={16}
            height={16}
            color="fill/black"
            onClick={closeModal}
          />
        </Style.TitleArea>

        <Style.ModalBodyArea>
          <Style.ModalBodyWarningArea>
            <SvgIcon
              icon="infoIcon"
              color="state/distructive"
              width={16}
              height={16}
            />
            <Font fontType="body2" color="state/distructive">
              의약품 가격은 사입가가 아닌 조제료가 포함된 판매가로 등록해
              주세요.
            </Font>
          </Style.ModalBodyWarningArea>

          <Style.MedicineCategoryButtonsArea>
            <Style.SubTitleArea>
              <Font fontType="body1_medium" color="fill/black">
                등록된 의약품
              </Font>
            </Style.SubTitleArea>
            <Style.MedicineSearchDisabledInputArea>
              <Style.MedicineSearchDisabledInput>
                <Font fontType="body1_medium" color="fill/medium">
                  {selectedMedicineCategory.label}
                </Font>
                <Font fontType="h3" color="fill/black">
                  {medicineSearchInputValue}
                </Font>
              </Style.MedicineSearchDisabledInput>
            </Style.MedicineSearchDisabledInputArea>
          </Style.MedicineCategoryButtonsArea>

          <Style.ModalBodyContentArea>
            <Style.SubTitleArea>
              <Font fontType="body1_medium" color="fill/black">
                처방 단위당 금액
              </Font>
              <Style.RequiredUI>
                <Font fontType="body3_medium" color="blue500">
                  필수
                </Font>
              </Style.RequiredUI>
            </Style.SubTitleArea>

            <Style.MedicineSumInputsArea>
              <Style.MedicineUnitInputArea>
                <Style.MedicineSearchInput
                  value={`${
                    selectedMedicine
                      ? `${selectedMedicine.product_total_quantity}${selectedMedicine.dosage_form}`
                      : ''
                  }`}
                  disabled
                />
              </Style.MedicineUnitInputArea>
              <Style.MedicineSumInputArea
                isFocus={isMedicineSumInputAreaFocus}
                onClick={() => setIsMedicineSumInputAreaFocus(true)}
              >
                <Style.MedicineSearchInput
                  placeholder="숫자만 입력해주세요"
                  ref={medicineSumInputRef}
                  value={formatNumberWithCommas(medicineSumInputValue)}
                  onChange={handleChangeMedicineSumInput}
                  onBlur={() => setIsMedicineSumInputAreaFocus(false)}
                />
              </Style.MedicineSumInputArea>
              <Font fontType="body1_medium" color="fill/black">
                원
              </Font>
            </Style.MedicineSumInputsArea>
          </Style.ModalBodyContentArea>
        </Style.ModalBodyArea>

        <Style.ModalFooterArea>
          <Button
            width="204px"
            height="56px"
            borderRadius="12px"
            backgroundColor={COLOR['fill/white']}
            borderColor={COLOR['border/outline']}
            title={
              <Font fontType="body1" color="fill/dark">
                취소
              </Font>
            }
            onClick={closeModal}
          />
          <Button
            width="204px"
            height="56px"
            borderRadius="12px"
            backgroundColor={
              isDisabledAddMedicineButton
                ? COLOR['disabled/background']
                : COLOR['fill/accent']
            }
            title={
              <Font
                fontType="body1"
                color={
                  isDisabledAddMedicineButton
                    ? 'disabled/foreground'
                    : 'fill/white'
                }
              >
                저장하기
              </Font>
            }
            onClick={handleAddMedicine}
            disabled={isDisabledAddMedicineButton}
          />
        </Style.ModalFooterArea>
      </Style.ModalArea>
    </BModal>
  );
}

export default MedicineManagementEditModal;
