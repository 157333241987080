import { Font } from '@components/atoms';

import { HeaderLeftPartItemType } from './index.d';
import * as Style from './index.style';

function HeaderLeftPartItem({
  patientName,
  reservationNum,
}: HeaderLeftPartItemType) {
  return (
    <Style.HeaderPatientName>
      <Font fontType="display2" color="fill/black">
        {`${patientName} 환자 ${reservationNum ? `(${reservationNum})` : ''}`}
      </Font>
    </Style.HeaderPatientName>
  );
}

export default HeaderLeftPartItem;
