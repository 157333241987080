import styled from 'styled-components';
import { IsSelectedType } from './index.d';

export const ButtonContainer = styled.div<IsSelectedType>`
  display: flex;
  flex-direction: row;
  padding: 8px 0;
  width: 100%;
  align-items: center;
  border-radius: 8px;
`;

export const RadioBox = styled.input`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  width: 18px;
  height: 18px;
  cursor: pointer;
`;

export const LabelBox = styled.label`
  cursor: pointer;
`;
