import { useState, useEffect, useCallback } from 'react';

export const useDateInput = (inputDate?: string) => {
  const [date, setDate] = useState<Date | null>(new Date());

  const onDateChange = useCallback((dateParam: Date | null) => {
    setDate(dateParam);
  }, []);

  const onDateInputChange = useCallback((value: string) => {
    setDate(new Date(value));
  }, []);

  useEffect(() => {
    if (inputDate) {
      onDateInputChange(inputDate);
    }
  }, [inputDate, onDateInputChange]);

  return {
    date,
    onDateChange,
    onDateInputChange,
  };
};
