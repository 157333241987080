import moment from 'moment';
import { TimeType, UserInformationType } from 'types/index.d';

// 오늘 요일
// moment().format("ddd").toUpperCase()
// 현재 시각(24h)
// moment().add(1, "days").format("HH:mm:ss")

export const DAY_KOREAN = ['일', '월', '화', '수', '목', '금', '토'];

// 시간 HH:mm으로 변경
export const getTimeHHmm = (time: string | any) => {
  if (typeof time === 'string') return time.slice(0, 5);
  return '';
};

// 요일 전환기
export const changeWeekDayToKorean = (
  week_day: TimeType.week_dayType = 'MON',
) => {
  let result;
  switch (week_day) {
    case 'MON':
      result = '월';
      break;
    case 'TUE':
      result = '화';
      break;
    case 'WED':
      result = '수';
      break;
    case 'THU':
      result = '목';
      break;
    case 'FRI':
      result = '금';
      break;
    case 'SAT':
      result = '토';
      break;
    case 'SUN':
      result = '일';
      break;
    default:
      break;
  }

  return result;
};

// 오늘 요일의 진료 시간 리턴
export const getTodayTreatmentTime = (
  treatmentTimeList: Array<TimeType.GetTodayTreatmentTimeType> = [],
  day = 0,
): TimeType.GetTodayTreatmentTimeType => {
  let result: TimeType.GetTodayTreatmentTimeType = {};
  const today = moment().add(day, 'days').format('ddd').toUpperCase();

  treatmentTimeList.forEach((item) => {
    if (today === item.week_day) {
      result = item;
    }
  });

  return result;
};

// 예약이 가능한지 리턴 -> 오늘, 내일 중 진료가 가능한 날이 있어야함
// TREATMENT : 진료가능(예약도), RESERVATION: 예약만 가능, NONE: 아예 불가.
export const getVisibleTreatment = (
  treatmentTimeList: Array<TimeType.GetTodayTreatmentTimeType> = [],
) => {
  let result: 'TREATMENT' | 'RESERVATION' | 'NONE' = 'NONE';
  // const today = moment().format("ddd").toUpperCase();
  // const tommorow = moment().add(1, "days").format("ddd").toUpperCase();
  const treatmentTime = getTodayTreatmentTime(treatmentTimeList);
  const tommorowTreatmentTIme = getTodayTreatmentTime(treatmentTimeList, 1);

  const startTime = `${moment().format('YYYY-MM-DD')} ${treatmentTime.start}`;
  const endTime = `${moment().format('YYYY-MM-DD')} ${treatmentTime.end}`;

  if (treatmentTime.off || moment().isAfter(endTime)) {
    if (tommorowTreatmentTIme.off === false) {
      result = 'RESERVATION';
    }
  } else if (moment().isBetween(startTime, endTime)) {
    result = 'TREATMENT';
  } else if (moment().isBefore(startTime)) {
    result = 'RESERVATION';
  }

  return result;
};

export const getTreatmentTimeList = (
  treatmentTimeList: Array<TimeType.GetTodayTreatmentTimeType> = [],
): {
  today: Array<any>;
  tommorow: Array<any>;
} => {
  const todayResult: Array<any> = [];
  const tommorowResult: Array<any> = [];

  const treatmentTime = getTodayTreatmentTime(treatmentTimeList);
  const tommorowTreatmentTIme = getTodayTreatmentTime(treatmentTimeList, 1);

  const todayStartTime = `${moment().format('YYYY-MM-DD')} ${
    treatmentTime.start
  }`;
  const todayEndTime = `${moment().format('YYYY-MM-DD')} ${treatmentTime.end}`;
  const tommorowStartTime = `${moment().add(1, 'days').format('YYYY-MM-DD')} ${
    tommorowTreatmentTIme.start
  }`;
  const tommorowEndTime = `${moment().add(1, 'days').format('YYYY-MM-DD')} ${
    tommorowTreatmentTIme.end
  }`;

  for (
    let time = moment(todayStartTime);
    moment(todayEndTime).isAfter(time);
    time = moment(time).add(20, 'minutes')
  ) {
    todayResult.push(time.format('YYYY-MM-DD HH:mm:ss'));
  }
  for (
    let time = moment(tommorowStartTime);
    moment(tommorowEndTime).isAfter(time);
    time = moment(time).add(20, 'minutes')
  ) {
    tommorowResult.push(time.format('YYYY-MM-DD HH:mm:ss'));
  }

  return {
    today: todayResult,
    tommorow: tommorowResult,
  };
};

export const isLunchTime = (
  todayTreatmentTime: TimeType.GetTodayTreatmentTimeType = {},
) => {
  let result = false;

  const startTime = `${moment().format('YYYY-MM-DD')} ${
    todayTreatmentTime.lunch_start
  }`;
  const endTime = `${moment().format('YYYY-MM-DD')} ${
    todayTreatmentTime.lunch_end
  }`;

  if (moment().isBetween(startTime, endTime)) {
    result = true;
  }

  return result;
};

export const getInjectTimeFormatter = (time?: string) => {
  if (time === undefined) return '';
  const afternoon = moment(time).format('a') === 'pm' ? '오후' : '오전';
  const day = DAY_KOREAN[moment(time).day()];

  // return `${moment(time).format('YYYY.M.DD')}.(${day}) ${afternoon} ${moment(
  //   time,
  // ).format('hh:mm')}`;
  return `${moment(time).format('YYYY-MM-DD')}(${day}) ${moment(time).format(
    'HH:mm',
  )}`;
};

export const getSubInjectTimeFormatter = (time?: string) => {
  if (time === undefined) return '';
  const day = DAY_KOREAN[moment(time).day()];

  return `${moment(time).format('YYYY.M.DD')}.(${day})`;
};

export const getDefaultTimeFormatter = (time?: string) => {
  return `${moment(time).format('YYYY-M-DD')}`;
};

export const getDotTimeFormatter = (time?: string) => {
  return `${moment(time).format('YYYY.MM.DD')}`;
};

export const dateTextFormatter = (timeDate: string | null | undefined) => {
  let dateText;

  switch (timeDate) {
    case null || undefined:
      dateText = '오늘';
      break;
    case moment().subtract(1, 'days').format('YYYY-MM-DD'):
      dateText = '어제';
      break;
    case moment().format('YYYY-MM-DD'):
      dateText = '오늘';
      break;
    case moment().add(1, 'days').format('YYYY-MM-DD'):
      dateText = '내일';
      break;
    default:
      dateText = timeDate;
      break;
  }

  return dateText;
};

export const weekDayArray = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];

export const getWorkingTimeSortedByWeekDay = (
  day_1: UserInformationType.PharmacistWorkingTimeType,
  day_2: UserInformationType.PharmacistWorkingTimeType,
) => {
  const day_1_index = weekDayArray.indexOf(day_1.week_day);
  const day_2_index = weekDayArray.indexOf(day_2.week_day);
  return day_1_index < day_2_index ? -1 : 1;
};

export const getPMAM = (date: string | undefined) =>
  moment(date).format('a') === 'am' ? '오전' : '오후';

export const getKoreanDate = ({
  isYear = true,
  date,
}: {
  isYear?: boolean;
  date: string;
}) => {
  if (isYear) return moment(date).format('YYYY년 MM월 DD일');
  return moment(date).format('MM월 DD일');
};
