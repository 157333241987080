import { logoutSupporter } from '@functions/supporters';
import useIntervalWhen from '@pages/loginPage/containers/find-id-password/hooks/find-id-password-submit/use-interval.hook';
import { myDoctorAPI } from '@services/myDoctor';
import { useAppDispatch } from '@stores/hooks';
import { fetchLogin } from '@stores/userInformationStore';
import {
  trackFailLoginMixpanelEvents,
  trackSuccessLoginMixpanelEvents,
} from '@utils/mixpanel/login';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import {
  SubmitErrorHandler,
  SubmitHandler,
  useFormContext,
} from 'react-hook-form';
import { PhoneLoginFormData } from '../login-form';

export const useLoginSubmitPhone = (
  startTimer: () => void,
  resetTimer: () => void,
) => {
  const { handleSubmit } = useFormContext<PhoneLoginFormData>();
  const [tokenFromServer, setTokenFromServer] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoginSuccess, setIsLoginSuccess] = useState<boolean | undefined>(
    undefined,
  );

  const updateErrorMessage = (message: string) => {
    setErrorMessage(message);
  };

  const retry = () => {
    resetTimer();
    setTokenFromServer('');
    setErrorMessage('');
    setIsLoginSuccess(undefined);
  };

  const clearMessage = () => {
    setErrorMessage('');
  };

  const storeToken = (token: string) => {
    setTokenFromServer(token);
  };
  const sendVerifycode = useCallback(
    async (phoneNumber: string) => {
      setErrorMessage('');

      try {
        const response = await myDoctorAPI.postUserAuthenticate({
          phone_number: phoneNumber,
          profile_type: 'PHARMACIST',
        });

        if (response.serverStatus === 201) {
          startTimer();
          storeToken(response.token);
        } else {
          if (response.serverStatus === 429) {
            setErrorMessage(
              '너무 많은 인증 요청이 발생하고 있어요.\n잠시 후에 다시 시도해주세요.',
            );

            return;
          }

          const message = response[0];
          if (message === '일치하는 계정 정보가 없습니다.') {
            setErrorMessage(
              '해당 휴대폰 번호로 가입된 계정이 없어요\n혹시 번호가 바뀌었다면 나만의닥터 고객센터로 문의주세요',
            );
          }

          if (
            message ===
            '이미 요청중인 인증 요청 정보가 있습니다. 5분 후 다시 시도해주세요.'
          ) {
            setErrorMessage(
              '이미 요청중인 인증 요청 정보가 있습니다.\n5분 후 다시 시도해주세요.',
            );
          }
          // alert(response[0]);
        }
      } catch (e) {
        console.log(e);
      }
    },
    [startTimer],
  );

  const onFormError: SubmitErrorHandler<PhoneLoginFormData> = useCallback(
    (errors) => {
      console.log(errors);
    },
    [],
  );

  const onFormSubmit: SubmitHandler<PhoneLoginFormData> = useCallback(
    async (data) => {
      const { PHONE_NUMBER } = data;
      try {
        await sendVerifycode(PHONE_NUMBER);
      } catch (e) {
        console.log('e from loginsubmitphone', e);
      }
    },
    [sendVerifycode],
  );

  const submit = debounce(() => {
    handleSubmit(onFormSubmit, onFormError)();
  }, 500);

  const dispatch = useAppDispatch();
  const login = useCallback(
    async (
      email: string,
      password: string,
      mobileAuthInfo: {
        accessToken: string;
        authToken: string;
        refreshToken: string;
        userPk: string;
        userType: string;
        email?: string;
      },
    ) => {
      await logoutSupporter.logout();
      const response = await dispatch(
        fetchLogin({ email, password, mobileAuthInfo }),
      );
      const loginData = response.payload as {
        isLogin: boolean;
        message: string;
        userID: string;
        userType: string;
      };
      if (loginData?.isLogin) {
        trackSuccessLoginMixpanelEvents(loginData?.userID, loginData?.userType);
      } else {
        trackFailLoginMixpanelEvents(loginData?.message);
      }
    },
    [dispatch],
  );

  const verifyAuthentication = async () => {
    try {
      console.log('startPolling');
      const response = await myDoctorAPI.postVerifyAuthenticateFromMobile({
        token: tokenFromServer,
      });

      console.log(999, response);

      if (response.serverStatus === 200) {
        console.log(6069, response);
        setIsLoginSuccess(true);

        const formatUserType: {
          [key: string]: string;
        } = {
          doctor: 'USER_TYPE__DOCTOR',
          nurse: 'USER_TYPE__NURSE',
          pharmacist: 'USER_TYPE__PHARMACIST',
          patient: 'USER_TYPE__PATIENT',
        };

        await login('', '', {
          accessToken: response.accessToken,
          authToken: response.authToken,
          refreshToken: response.refreshToken,
          userPk: response.user.pk,
          userType: formatUserType?.[response.user.type],
          email: response.user.email,
        });
      } else if (response.serverStatus === 400) {
        console.log(555555, response);
        const message = response[0];
        if (message === '거절된 토큰입니다.') {
          resetTimer();
          setIsLoginSuccess(false);
        }
      }
      console.log('postVerifyAuthenticateFromMobile response :', response);
    } catch (e) {
      console.log('error from verifyAuthentication');
    }
  };

  useEffect(() => {
    const rejectAuthentication = async (token: string) => {
      try {
        if (tokenFromServer.length === 0) return;
        console.log('clear token', tokenFromServer);
        await myDoctorAPI.postVerifyAuthentication({
          token: tokenFromServer || token,
          rejected: true,
        });
      } catch (e) {
        console.log('e from verifyPostAuthentication', e);
      }
    };
    return () => {
      if (tokenFromServer.length === 0) return;
      rejectAuthentication(tokenFromServer);
    };
  }, [tokenFromServer]);

  useIntervalWhen(
    () => {
      verifyAuthentication();
    },
    4000,
    tokenFromServer.length > 0,
    true,
    isLoginSuccess !== undefined,
  );

  return {
    submit,
    tokenFromServer,
    errorMessage,
    isLoginSuccess,
    retry,
    clearMessage,
    updateErrorMessage,
  };
};
