import { Button, COLOR, Font } from '@components/atoms';
import SvgIcon from '@components/atoms/images';
import AllowToast from '@components/organisms/toast/allowToast';
import { QUERY_KEY, queryClient } from '@constants/react-query';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { toast } from 'react-toastify';
import {
  PrescriptionQrCodeImage,
  postPrescriptionQrCodeReportFailure,
} from '../../apis';
import * as Style from './index.style';

type ModalQrCodeInfoProps = PrescriptionQrCodeImage;

function ModalQrCodeInfo({
  id,
  prescription_id,
  page_index,
  qr_index,
  image,
  is_recognition_failure_reported,
}: ModalQrCodeInfoProps) {
  const [isQrCodeErrorReporting, setIsQrCodeErrorReporting] = useState(
    is_recognition_failure_reported,
  );

  const { mutateAsync: clickPrescriptionQrCodeFailReport } = useMutation({
    mutationFn: () =>
      postPrescriptionQrCodeReportFailure({
        prescriptionId: prescription_id,
        pageIndex: page_index,
        qrIndex: qr_index,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.TP_PRESCRIPTION_QR_CODE_IMAGE, prescription_id],
      });
      toast(
        <AllowToast
          icon={
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                height: '49px',
              }}
            >
              <SvgIcon
                icon="checkCircleIcon"
                color="state/success"
                width={24}
              />
            </div>
          }
          title={
            <div>
              <Font fontType="h3" color="fill/black">
                오류 제보 완료
              </Font>
              <Font fontType="body2" color="fill/dark">
                제보해주셔서 감사합니다.
              </Font>
            </div>
          }
        />,
        { position: 'top-center' },
      );
    },
    onError: () => {
      toast(
        <AllowToast
          icon={
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                height: '49px',
              }}
            >
              <SvgIcon icon="closeIcon" color="state/distructive" width={24} />
            </div>
          }
          title={
            <div>
              <Font fontType="h3" color="fill/black">
                오류 제보 실패
              </Font>
              <Font fontType="body2" color="fill/dark">
                잠시 후 다시 시도해주세요.
              </Font>
            </div>
          }
        />,
        { position: 'top-center' },
      );
      setIsQrCodeErrorReporting(false);
    },
  });

  return (
    <Style.BiggerQrCodeContainer>
      <Style.ImgContainer>
        <Style.QrImage src={image} alt={`QR코드 ${page_index}-${qr_index}`} />
      </Style.ImgContainer>
      <Style.QrCodeBottomContainer>
        <Font fontType="body2" color="fill/medium">
          {`QR코드 ${page_index + 1}-${qr_index + 1}`}
        </Font>
        <Button
          disabled={isQrCodeErrorReporting}
          title={
            <Font
              fontType="body2"
              color={
                isQrCodeErrorReporting ? 'disabled/foreground' : 'fill/dark'
              }
            >
              {isQrCodeErrorReporting ? '제보 완료' : '오류 제보'}
            </Font>
          }
          borderColor={
            isQrCodeErrorReporting ? 'none' : COLOR['border/outline']
          }
          backgroundColor={
            isQrCodeErrorReporting
              ? COLOR['disabled/background']
              : COLOR['fill/white']
          }
          padding="5px 12px"
          onClick={() => {
            clickPrescriptionQrCodeFailReport();
            setIsQrCodeErrorReporting(true);
          }}
        />
      </Style.QrCodeBottomContainer>
    </Style.BiggerQrCodeContainer>
  );
}

export default ModalQrCodeInfo;
