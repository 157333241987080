import { QUERY_KEY } from '@constants/react-query';
import { getSubstitutePrescriptionInfo } from '@mobile/api';
import { useSuspenseQuery } from '@tanstack/react-query';

interface UseSubstitutePrescriptionInfoProps {
  tmId: number;
  pharmacyId: number;
  tppId: string;
}

const useSubstitutePrescriptionInfo = ({
  tmId,
  pharmacyId,
  tppId,
}: UseSubstitutePrescriptionInfoProps) => {
  return useSuspenseQuery({
    queryKey: [QUERY_KEY.TP_SUBSTITUTE_PRESCRIPTION, tmId, pharmacyId],
    queryFn: () => {
      return getSubstitutePrescriptionInfo({
        tm_id: tmId,
        pharmacy_id: pharmacyId,
        tpp_id: Number(tppId),
      });
    },
    select: (data) => data.data.data,
  });
};

export default useSubstitutePrescriptionInfo;
