import { BColor } from '@components/meraki-ui/BColor';
import { IconDetailProps } from '@components/meraki-ui/BIcon/svg.type';

export default function ReplayInCircleIcon({
  width,
  height,
  color,
}: IconDetailProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM8.79062 7.09313C8.63821 6.98427 8.43774 6.96972 8.27121 7.05542C8.10467 7.14112 8 7.31271 8 7.5V12.5C8 12.6873 8.10467 12.8589 8.27121 12.9446C8.43774 13.0303 8.63821 13.0157 8.79062 12.9069L12.2906 10.4069C12.422 10.313 12.5 10.1615 12.5 10C12.5 9.83853 12.422 9.68699 12.2906 9.59313L8.79062 7.09313Z"
        fill={BColor[color]}
      />
    </svg>
  );
}
