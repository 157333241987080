import { IconDetailProps } from '@components/atoms/images';

function MedicineIcon({ width, height, color }: IconDetailProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M3.33337 7.91667C3.33337 5.61548 5.19885 3.75 7.50004 3.75H12.5C14.8012 3.75 16.6667 5.61548 16.6667 7.91667V16.6667C16.6667 18.0474 15.5474 19.1667 14.1667 19.1667H5.83337C4.45266 19.1667 3.33337 18.0474 3.33337 16.6667V7.91667Z"
        fill="#B2F0CC"
      />
      <rect
        x="3.33337"
        y="7.91699"
        width="13.3333"
        height="8.33333"
        fill="#00C785"
      />
      <path
        d="M7.75645 12.7567H9.32696V14.3272C9.32696 14.6988 9.6285 15.0003 10 15.0003C10.3716 15.0003 10.6731 14.6988 10.6731 14.3272V12.7567H12.2436C12.6152 12.7567 12.9167 12.4552 12.9167 12.0837C12.9167 11.7121 12.6152 11.4106 12.2436 11.4106H10.6731V9.84007C10.6731 9.46853 10.3716 9.16699 10 9.16699C9.6285 9.16699 9.32696 9.46853 9.32696 9.84007V11.4106H7.75645C7.38491 11.4106 7.08337 11.7121 7.08337 12.0837C7.08337 12.4552 7.38491 12.7567 7.75645 12.7567Z"
        fill="white"
      />
      <path
        d="M5 1.66634C5 1.2061 5.3731 0.833008 5.83333 0.833008H14.1667C14.6269 0.833008 15 1.2061 15 1.66634V3.33301C15 3.79325 14.6269 4.16634 14.1667 4.16634H5.83333C5.3731 4.16634 5 3.79325 5 3.33301V1.66634Z"
        fill="#00C785"
      />
    </svg>
  );
}

export default MedicineIcon;
