import { Blank, Button, COLOR, Font } from '@components/atoms';
import {
  ModalCommonStructure as Modal,
  PageLoader,
} from '@components/molecules';
import { commonCode } from '@constants';
import { formatters } from '@functions';
import { useAppDispatch, useAppSelector } from '@stores/hooks';
import {
  closeModal_recheckRequestModal,
  selectRecheckRequestModalItem,
  selectRecheckRequestModalVisible,
} from '@stores/modalStore/recheckRequestModal';
import useFinishTelepharmacyModalHook from '../finishTelepharmacyModal/hooks';
import * as Style from './index.style';

const modalCustomStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0',
    width: '480px',
    backgroundColor: COLOR['fill/white'],
    maxHeight: '80vh',
    borderRadius: '16px',
  },
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 101,
  },
};

const { COMMON_CODE } = commonCode;

function RecheckRequestModal() {
  const { finishTelepharmacy, isPageLoading } =
    useFinishTelepharmacyModalHook();
  const { phoneFormatter } = formatters;

  const dispatch = useAppDispatch();

  const isOpen = useAppSelector(selectRecheckRequestModalVisible);
  const item = useAppSelector(selectRecheckRequestModalItem);

  const closeModal = () => {
    dispatch(closeModal_recheckRequestModal());
  };

  let deliveryTypeText = '';
  switch (item.method) {
    case COMMON_CODE.TP_METHOD.QUICK:
    case COMMON_CODE.TP_METHOD.ECONOMY_QUICK:
      deliveryTypeText = '퀵';
      break;
    case COMMON_CODE.TP_METHOD.PARCEL:
    case COMMON_CODE.TP_METHOD.COLD_CHAIN:
      deliveryTypeText = '택배';
      break;
    default:
      break;
  }

  let pharmacyProductPaymentText = '없음';
  if (
    item.pharmacy_product_payment &&
    item.pharmacy_product_payment.items &&
    item.pharmacy_product_payment.items.length > 0
  ) {
    pharmacyProductPaymentText = `${item.pharmacy_product_payment.items[0].pharmacy_product_name} 포함 ${item.pharmacy_product_payment.items.length}건`;
  }

  return (
    <>
      {isPageLoading && <PageLoader />}
      <Modal
        isOpen={isOpen}
        isRecheckModal
        modalStyle={modalCustomStyle}
        onRequestClose={() => closeModal()}
        modalHeader={
          <Style.TitleArea>
            <Font fontType="h1" color="fill/dark">
              {deliveryTypeText} 수거 요청 전에 아래 항목들을 다시 한번
              확인해주세요!
            </Font>
          </Style.TitleArea>
        }
        modalBody={
          <>
            <Style.PatientInfoArea>
              <Style.InformationLine>
                <Style.InformationTitle>
                  <Font fontType="body1" color="fill/medium">
                    환자명
                  </Font>
                </Style.InformationTitle>
                <Style.InformationColumnArea>
                  <Font fontType="body1" color="fill/dark">
                    {item.patientName}
                  </Font>
                </Style.InformationColumnArea>
              </Style.InformationLine>
              <Style.InformationLine>
                <Style.InformationTitle>
                  <Font fontType="body1" color="fill/medium">
                    연락처
                  </Font>
                </Style.InformationTitle>
                <Style.InformationColumnArea>
                  <Font fontType="body1" color="fill/dark">
                    {phoneFormatter.prettyPhoneNumberFormatter(
                      item.patientPhone,
                    )}
                  </Font>
                </Style.InformationColumnArea>
              </Style.InformationLine>
              <Style.InformationLineMargin />
              <Style.InformationLine>
                <Style.InformationTitle>
                  <Font fontType="body1" color="fill/medium">
                    약국 요청사항
                  </Font>
                </Style.InformationTitle>
                <Style.InformationColumnArea>
                  <Font fontType="body1" color="fill/dark">
                    {item.pharmacy_request_detail || '요청사항 없음'}
                  </Font>
                </Style.InformationColumnArea>
              </Style.InformationLine>
              <Style.InformationLine>
                <Style.InformationTitle>
                  <Font fontType="body1" color="fill/medium">
                    추가 구매상품
                  </Font>
                </Style.InformationTitle>
                <Style.InformationColumnArea>
                  <Font fontType="body1" color="fill/dark">
                    {pharmacyProductPaymentText}
                  </Font>
                </Style.InformationColumnArea>
              </Style.InformationLine>
              <Style.InformationLineMargin />
              <Style.InformationLine>
                <Style.InformationTitle>
                  <Font fontType="body1" color="fill/medium">
                    배송할 주소
                  </Font>
                </Style.InformationTitle>
                <Style.InformationColumnArea>
                  <Font fontType="body1" color="fill/dark">
                    {item.patientAddress}
                  </Font>
                </Style.InformationColumnArea>
              </Style.InformationLine>
              <Style.InformationLine>
                <Style.InformationTitle>
                  <Font fontType="body1" color="fill/medium">
                    현관 비밀번호
                  </Font>
                </Style.InformationTitle>
                <Style.InformationColumnArea>
                  <Font fontType="body1" color="fill/dark">
                    {item?.address_entrance_password ||
                      '비밀번호 없이 출입 가능'}
                  </Font>
                </Style.InformationColumnArea>
              </Style.InformationLine>
              <Style.InformationLine>
                <Style.InformationTitle>
                  <Font fontType="body1" color="fill/medium">
                    배송 요청사항
                  </Font>
                </Style.InformationTitle>
                <Style.InformationColumnArea>
                  <Font fontType="body1" color="fill/dark">
                    {item.addressMemo || '요청사항 없음'}
                  </Font>
                </Style.InformationColumnArea>
              </Style.InformationLine>
            </Style.PatientInfoArea>
          </>
        }
        modalFooter={
          <>
            <Button
              onClick={() => {
                closeModal();
              }}
              backgroundColor={COLOR['fill/light']}
              width="194px"
              height="60px"
              borderRadius="8px"
              title={
                <Style.ButtonContainer>
                  <Blank appoint="Horizontal" size={4} />
                  <Font fontType="h3" color="fill/dark">
                    확인하고 올게요
                  </Font>
                </Style.ButtonContainer>
              }
            />

            <Blank appoint="Horizontal" size={12} />
            <Button
              onClick={() =>
                finishTelepharmacy({ defaultMedicineGuide: item.guideData })
              }
              backgroundColor={COLOR['fill/accent']}
              width="194px"
              height="60px"
              borderRadius="8px"
              title={
                <Style.ButtonContainer>
                  <Blank appoint="Horizontal" size={4} />
                  <Font fontType="h3" color="fill/white">
                    확인했어요
                  </Font>
                </Style.ButtonContainer>
              }
            />
          </>
        }
      />
    </>
  );
}

export default RecheckRequestModal;
