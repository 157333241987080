import { BColor } from '@components/meraki-ui/BColor';
import { IconDetailProps } from '@components/meraki-ui/BIcon/svg.type';

export default function IDCardIcon({ width, height, color }: IconDetailProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 5C16.5523 5 17 5.44772 17 6V14C17 14.5523 16.5523 15 16 15H4C3.44772 15 3 14.5523 3 14V6C3 5.44772 3.44772 5 4 5H16ZM4 4C2.89543 4 2 4.89543 2 6V14C2 15.1046 2.89543 16 4 16H16C17.1046 16 18 15.1046 18 14V6C18 4.89543 17.1046 4 16 4H4Z"
        fill={BColor[color]}
      />
      <path
        d="M4 7.5C4 7.22386 4.22386 7 4.5 7H6.5C6.77614 7 7 7.22386 7 7.5V8.5C7 8.77614 6.77614 9 6.5 9H4.5C4.22386 9 4 8.77614 4 8.5V7.5Z"
        fill={BColor[color]}
      />
      <path
        d="M4 10.5C4 10.2239 4.22386 10 4.5 10H9.5C9.77614 10 10 10.2239 10 10.5C10 10.7761 9.77614 11 9.5 11H4.5C4.22386 11 4 10.7761 4 10.5ZM4 12.5C4 12.2239 4.22386 12 4.5 12H5.5C5.77614 12 6 12.2239 6 12.5C6 12.7761 5.77614 13 5.5 13H4.5C4.22386 13 4 12.7761 4 12.5ZM7 12.5C7 12.2239 7.22386 12 7.5 12H8.5C8.77614 12 9 12.2239 9 12.5C9 12.7761 8.77614 13 8.5 13H7.5C7.22386 13 7 12.7761 7 12.5ZM10 12.5C10 12.2239 10.2239 12 10.5 12H11.5C11.7761 12 12 12.2239 12 12.5C12 12.7761 11.7761 13 11.5 13H10.5C10.2239 13 10 12.7761 10 12.5ZM13 12.5C13 12.2239 13.2239 12 13.5 12H14.5C14.7761 12 15 12.2239 15 12.5C15 12.7761 14.7761 13 14.5 13H13.5C13.2239 13 13 12.7761 13 12.5Z"
        fill={BColor[color]}
      />
    </svg>
  );
}
