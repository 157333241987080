import styled from 'styled-components';

export const TitleArea = styled.div`
  display: flex;
  align-items: center;
`;

export const DescriptionArea = styled.div`
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const DescriptionTitleArea = styled.div`
  margin-bottom: 5px;
`;

export const DescriptionDetailArea = styled.div`
  display: flex;
  margin: 30px 0;
  &:last-child {
    margin: 0;
  }
`;

export const DescriptionDetailLeftArea = styled.div`
  margin-right: 8px;
  padding-top: 3px;
`;

export const DescriptionDetailMiddleArea = styled.div`
  margin-right: 72px;
`;

export const DescriptionDetailMiddleTextArea = styled.div``;

export const DescriptionDetailRightArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
  width: 300px;
`;

export const CheckBoxArea = styled.div`
  display: flex;
  margin-right: 16px;
  position: relative;
`;

export const CheckBoxIconArea = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: -20px;
  top: 5px;
  cursor: pointer;
`;

export const InputArea = styled.div`
  display: flex;
  align-items: center;
`;

export const MultiLineInputArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TooltipMessageArea = styled.div``;

export const TooltipMessage = styled.div``;

export const TooltipImage = styled.img`
  width: 12px;
  height: 12px;
  margin-right: 4px;
`;
