import { BColor } from '@components/meraki-ui/BColor';
import { IconDetailProps } from '@components/meraki-ui/BIcon/svg.type';

export default function BulbIcon({ width, height, color }: IconDetailProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 8C4 4.68629 6.68629 2 10 2C13.3137 2 16 4.68629 16 8C16 9.69124 15.2996 11.2198 14.1744 12.3098C13.9714 12.5064 13.8152 12.7107 13.7213 12.9287L12.9592 14.6978C12.8802 14.8812 12.6997 15 12.5 15C12.7761 15 13 15.2239 13 15.5C13 15.7761 12.7761 16 12.5 16C12.7761 16 13 16.2239 13 16.5C13 16.7761 12.7761 17 12.5 17L12.2764 17.4472C12.107 17.786 11.7607 18 11.382 18H8.61803C8.23926 18 7.893 17.786 7.72361 17.4472L7.5 17C7.22386 17 7 16.7761 7 16.5C7 16.2239 7.22386 16 7.5 16C7.22386 16 7 15.7761 7 15.5C7 15.2239 7.22386 15 7.5 15C7.30032 15 7.11979 14.8812 7.04079 14.6978L6.27873 12.9287C6.18481 12.7107 6.02858 12.5064 5.82563 12.3098C4.7004 11.2198 4 9.69124 4 8ZM10 3C7.23858 3 5 5.23858 5 8C5 9.40952 5.58259 10.6821 6.52143 11.5916C6.784 11.846 7.03464 12.1559 7.19714 12.5331L7.82903 14H12.171L12.8029 12.5331C12.9654 12.1559 13.216 11.846 13.4786 11.5916C14.4174 10.6821 15 9.40953 15 8C15 5.23858 12.7614 3 10 3Z"
        fill={BColor[color]}
      />
    </svg>
  );
}
