import { coldChainImage, parcelImage, pickupImage, quickImage } from '@assets';
import { Font } from '@components/atoms';
import { QUICK_LISTITEM_FLEX } from '@components/organisms/pickupTelepharmacyTable';
import {
  convertSubstitutedPatientStatus,
  convertSubstitutedPharmacyStatus,
} from '@components/organisms/utils/domain';
import { commonCode } from '@constants';
import { formatters } from '@functions';
import { myDoctorAPI } from '@services';
import { useState } from 'react';
import { MyDoctorModelType } from 'types/index.d';
import { ListContent, StatusTag as QuickStatusTag } from '../index';
import * as Style from './index.style';

const { COMMON_CODE } = commonCode;

function ListItem({
  item,
}: {
  item: MyDoctorModelType.TelepharmacyModelType.TelepharmacyListItemModelType;
}) {
  const { phoneFormatter, priceFormatter } = formatters;
  const [isButtonVisible, setIsButtonVisible] = useState(false);

  let deliveryType;
  let iconURL;

  switch (item.method) {
    case COMMON_CODE.TP_METHOD.PARCEL:
      deliveryType = '택배';
      iconURL = parcelImage;
      break;
    case COMMON_CODE.TP_METHOD.PICKUP:
      deliveryType = '방문';
      iconURL = pickupImage;
      break;
    case COMMON_CODE.TP_METHOD.QUICK:
    case COMMON_CODE.TP_METHOD.ECONOMY_QUICK:
      deliveryType = '퀵';
      iconURL = quickImage;
      break;
    case COMMON_CODE.TP_METHOD.COLD_CHAIN:
      deliveryType = '냉장';
      iconURL = coldChainImage;
      break;
    default:
      deliveryType = '택배';
      iconURL = parcelImage;
      break;
  }

  const fetchPrescriptionData = async (tp_id: number | undefined) => {
    const { data } = await myDoctorAPI.getTelepharmacyTpprocessPrescriptions(
      Number(tp_id),
    );
    return data;
  };

  const isEmptyObject = (param: any) => {
    if (param === undefined) return true;
    return Object.keys(param).length === 0 && param.constructor === Object;
  };

  const isEmptyObjectPharmacyProductPayment = isEmptyObject(
    item.pharmacy_product_payment,
  );

  const commerceTotalAmount =
    !isEmptyObjectPharmacyProductPayment &&
    item.pharmacy_product_payment &&
    item.pharmacy_product_payment.total_amount
      ? item.pharmacy_product_payment.total_amount
      : 0;

  const isSubstitutedTextColorRed = item.is_substituted_patient === false;

  return (
    <Style.ListItem
      onMouseEnter={() => setIsButtonVisible(true)}
      onMouseLeave={() => setIsButtonVisible(false)}
      to={`/home/confirmedTreatment/ongoing-quick/confirmedTreatmentOngoingQuickDetail?id=${item.id}`}
    >
      <ListContent.Regular flex={QUICK_LISTITEM_FLEX[0].flex}>
        <Style.ListItemLayout>
          <QuickStatusTag status={item.delivery_status} item={item} />
        </Style.ListItemLayout>
      </ListContent.Regular>

      <ListContent.Element flex={QUICK_LISTITEM_FLEX[1].flex}>
        <Style.ListItemLayout>
          <Style.SubstitutedArea>
            <Font
              fontType="body2"
              color={
                isSubstitutedTextColorRed ? 'state/distructive' : 'fill/black'
              }
            >
              {`환자 : ${convertSubstitutedPatientStatus(
                item.is_substituted_patient,
              )}`}
            </Font>
            <Font
              fontType="body2"
              color={
                isSubstitutedTextColorRed ? 'state/distructive' : 'fill/black'
              }
            >
              {`약사 : ${
                item.auto_confirmed
                  ? convertSubstitutedPatientStatus(item.is_substituted_patient)
                  : convertSubstitutedPharmacyStatus(
                      item.is_substituted_pharmacy,
                    )
              }`}
            </Font>
          </Style.SubstitutedArea>
        </Style.ListItemLayout>
      </ListContent.Element>

      <ListContent.Element flex={QUICK_LISTITEM_FLEX[2].flex}>
        <Style.ListItemColumnLayout>
          <Font fontType="body2_medium" color="fill/black">
            {item.patient?.name}
          </Font>
          <Font fontType="body3" color="fill/medium">
            {item.telemedicine ? (
              <>{item.telemedicine.telemedicine_hash}</>
            ) : null}
          </Font>
        </Style.ListItemColumnLayout>
      </ListContent.Element>

      <ListContent.Regular flex={QUICK_LISTITEM_FLEX[3].flex}>
        <Style.ListItemLayout>
          <Font fontType="body2_medium" color="fill/black">
            {phoneFormatter.prettyPhoneNumberFormatter(
              item.patient?.phone || item.requester?.phone,
            )}
          </Font>
        </Style.ListItemLayout>
      </ListContent.Regular>

      <ListContent.Element flex={QUICK_LISTITEM_FLEX[4].flex}>
        <Style.ListItemLayout>
          <Font fontType="body2">
            {`${priceFormatter.commaFormatter(
              item.amount ? String(item.amount) : '0',
            )}원`}
          </Font>
        </Style.ListItemLayout>
      </ListContent.Element>

      <ListContent.Regular flex={QUICK_LISTITEM_FLEX[5].flex}>
        <Style.ListItemLayout>
          <Font fontType="body2">{item.address_string || '-'}</Font>
        </Style.ListItemLayout>
      </ListContent.Regular>
    </Style.ListItem>
  );
}

export default ListItem;
