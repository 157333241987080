import { Font } from '../index';
import { TextButtonType } from './index.d';
import { ButtonContainer, LabelBox, RadioBox } from './index.style';

function TextButton({ radioName, title, onClick, isSelected }: TextButtonType) {
  return (
    <ButtonContainer isSelected={isSelected}>
      <RadioBox
        type="radio"
        name={radioName}
        id={title}
        onClick={() => onClick()}
      />
      <LabelBox htmlFor={title}>
        <Font fontType="body1_medium" color="fill/black">
          {title}
        </Font>
      </LabelBox>
    </ButtonContainer>
  );
}

export default TextButton;
