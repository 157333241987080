import { createSlice } from '@reduxjs/toolkit';
import { GetManagedMedicineItemType } from '@services/myDoctor/types';
import { RootState } from '@stores';

export type ConfirmModalType =
  | 'soldOut'
  | 'cancelSoldOut'
  | 'delete'
  | 'alreadyExist'
  | 'allUnitAdded';
interface InitialStateType {
  isVisible: boolean;
  modalType: ConfirmModalType | null;
  targetMedicine: GetManagedMedicineItemType | null;
}

const initialState: InitialStateType = {
  isVisible: false,
  modalType: null,
  targetMedicine: null,
};

export const medicineManagementCommonModal = createSlice({
  name: 'modal/medicineManagementCommonModal',
  initialState,
  reducers: {
    openModal_medicineManagementCommonModal: (state) => {
      state.isVisible = true;
    },
    closeModal_medicineManagementCommonModal: (state) => {
      state.isVisible = false;
    },
    set_modalType_medicineManagementCommonModal: (state, action) => {
      state.modalType = action.payload;
    },
    set_targetMedicine_medicineManagementCommonModal: (state, action) => {
      state.targetMedicine = action.payload;
    },
  },
});

export const {
  openModal_medicineManagementCommonModal,
  closeModal_medicineManagementCommonModal,
  set_modalType_medicineManagementCommonModal,
  set_targetMedicine_medicineManagementCommonModal,
} = medicineManagementCommonModal.actions;

export const selectMedicineManagementCommonModalVisible = (state: RootState) =>
  state.medicineManagementCommonModal.isVisible;

export const selectMedicineManagementCommonModalModalType = (
  state: RootState,
) => state.medicineManagementCommonModal.modalType;

export const selectMedicineManagementCommonModalTargetMedicine = (
  state: RootState,
) => state.medicineManagementCommonModal.targetMedicine;

export default medicineManagementCommonModal.reducer;
