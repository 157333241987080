import { COLOR } from '@components/atoms';
import styled from 'styled-components';

export const WorkingTimeListArea = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

export const WorkingTimeTitleArea = styled.div`
  display: flex;
  // justify-content: flex-start;
  // align-items: center;
`;

export const WorkingTimeTitleIconArea = styled.div`
  margin-right: 6px;
  height: 22px;
  display: flex;
  align-items: center;
`;

export const WorkingTimeTitleTextArea = styled.div``;

export const WorkingTimeDescriptionArea = styled.div`
  display: flex;
`;

export const WorkingTimeDescriptionTextArea = styled.div``;

export const WorkingTimeDescriptionText = styled.div`
  margin-bottom: 4px;
`;

export const WorkingTimeButtonArea = styled.div`
  padding-top: 2px;
`;

export const WorkingTimeChangeArea = styled.div``;

export const WorkingTimeChangeHeaderArea = styled.div`
  display: flex;
  margin-bottom: 8px;
`;

export const WorkingTimeChangeHeaderTitleArea = styled.div`
  margin-right: 40px;
`;

export const WorkingTimeChangeHeaderCheckboxArea = styled.div``;

export const WorkingTimeChangeBodyArea = styled.div`
  display: flex;
`;

export const WorkingTimeChangeBodyTextInputArea = styled.div``;

export const WorkingTimeChangeBodyDropdownArea = styled.div`
  position: relative;
`;

export const IconButtonItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: 12px;
  margin-right: 12px;
`;

export const WorkingTimeChangeBodyDropdownList = styled.div`
  width: 100px;
  height: 100px;
  background-color: ${COLOR['fill/white']};
  border-radius: 8px;
  filter: drop-shadow(0px 1px 20px rgba(0, 0, 0, 0.25));
  position: absolute;
  top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999;
`;

export const WorkingTimeChangeBodyDropdownItem = styled.div`
  width: 100%;
  padding: 9px 0;
  cursor: pointer;
  color: ${COLOR['fill/dark']};
  &:hover {
    color: ${COLOR['fill/black']};
  }
  text-align: center;
`;

export const WorkingTimeChangeBodyTextArea = styled.div`
  display: flex;
  align-items: center;
  margin: 0 8px;
`;

export const WorkingTimeChangeFooterArea = styled.div`
  margin-top: 20px;
`;

export const WorkingTimeChangeFooterButtonArea = styled.div``;
