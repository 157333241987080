import { RefObject } from 'react';

interface PdfViewerProps {
  width?: string;
  height?: string;
  iframeRef?: RefObject<HTMLIFrameElement>;
  link: string | undefined;
}

export default function PdfViewer({
  width = '100%',
  height = '900px',
  link,
  iframeRef,
}: PdfViewerProps) {
  return (
    <>
      {link ? (
        <iframe
          src={`${link}#zoom=95&navpanes=0&scrollbar=0`}
          ref={iframeRef}
          width={width}
          height={height}
          title="PDF Viewer"
          style={{ borderRadius: '10px' }}
        />
      ) : (
        <p>Loading PDF...</p>
      )}
    </>
  );
}
