import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { EResetPasswordFormFields } from './reset-password-form.type';

export const useResetPasswordForm = () => {
  const resetPasswordFormSchema = {
    [EResetPasswordFormFields.PASSWORD]: yup
      .string()
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>])[A-Za-z\d!@#$%^&*(),.?":{}|<>]{8,}$/,
        '영문/숫자/특수문자를 포함하여 8자 이상 입력해주세요',
      ),
    [EResetPasswordFormFields.PASSWORD_CONFIRM]: yup
      .string()
      .oneOf(
        [yup.ref(EResetPasswordFormFields.PASSWORD)],
        '비밀번호가 일치하지 않습니다.',
      ),
  };

  const schema = yup.object().shape(resetPasswordFormSchema);

  const defaultValue = {
    [EResetPasswordFormFields.PASSWORD]: '',
    [EResetPasswordFormFields.PASSWORD_CONFIRM]: '',
  };

  const methods = useForm({
    defaultValues: {
      ...defaultValue,
    },
    mode: 'all',
    resolver: yupResolver(schema),
  });

  return {
    methods,
  };
};
