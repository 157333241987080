import { BColor } from '@components/meraki-ui/BColor';
import { IconDetailProps } from '@components/meraki-ui/BIcon/svg.type';

export default function AlarmIcon({ width, height, color }: IconDetailProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5163 7.26449C10.5163 6.97944 10.2853 6.74836 10.0002 6.74836C9.71516 6.74836 9.48408 6.97944 9.48408 7.26449L9.48409 10.2581H6.38732C6.10227 10.2581 5.87119 10.4891 5.87119 10.7742C5.87119 11.0592 6.10227 11.2903 6.38732 11.2903H10.0002C10.1371 11.2903 10.2684 11.2359 10.3652 11.1392C10.462 11.0424 10.5164 10.9111 10.5163 10.7742L10.5163 7.26449Z"
        fill={BColor[color]}
      />
      <path
        d="M8.45183 2.51613C8.45183 2.23108 8.68291 2 8.96796 2H11.0325C11.3175 2 11.5486 2.23108 11.5486 2.51613C11.5486 2.80118 11.3175 3.03226 11.0325 3.03226V3.62162C12.4362 3.82248 13.7098 4.42666 14.7319 5.313C14.7361 5.30853 14.7403 5.30412 14.7447 5.29976L15.1097 4.93481L14.7447 4.56985C14.5431 4.36829 14.5431 4.04149 14.7447 3.83993C14.9463 3.63837 15.2731 3.63837 15.4746 3.83993L16.9345 5.29976C17.136 5.50132 17.136 5.82812 16.9345 6.02968C16.7329 6.23124 16.4061 6.23124 16.2045 6.02968L15.8396 5.66472L15.4746 6.02968C15.4703 6.03403 15.4659 6.03828 15.4614 6.04244C16.5608 7.31011 17.226 8.96441 17.226 10.7742C17.226 14.7649 13.9909 18 10.0002 18C6.00952 18 2.77441 14.7649 2.77441 10.7742C2.77441 7.13391 5.46602 4.12273 8.96796 3.62162V3.03226C8.68291 3.03226 8.45183 2.80118 8.45183 2.51613ZM10.0002 4.58065C9.83963 4.58065 9.68057 4.58675 9.52324 4.59871C6.32578 4.84195 3.80667 7.51413 3.80667 10.7742C3.80667 14.1948 6.57962 16.9677 10.0002 16.9677C13.4208 16.9677 16.1938 14.1948 16.1938 10.7742C16.1938 7.51413 13.6747 4.84195 10.4772 4.59871C10.3199 4.58675 10.1608 4.58065 10.0002 4.58065Z"
        fill={BColor[color]}
      />
    </svg>
  );
}
