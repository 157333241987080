import styled from 'styled-components';

export const ModalBodyContentArea = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

export const SubTitleArea = styled.div`
  display: flex;
  gap: 2px;
  width: 88px;
  padding: 8px 0px;
`;

export const MedicineCategoryButtonsArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  width: 360px;
`;

export const MedicineSelectedButtonArea = styled.div`
  display: flex;
  gap: 4px;
`;
