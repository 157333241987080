import { ReactBootstrapIcons } from '@assets';
import { COLOR, Font } from '@components/atoms';
import { useAppSelector } from '@stores/hooks';
import { selectPharmacyAutoConfirmed } from '@stores/telepharmacyStore/telepharmacyList';
import { selectUserInformationPharmacistData } from '@stores/userInformationStore';
import { trackNewReservationRequested } from '@utils/mixpanel/newReservation/requested';
import { useEffect } from 'react';
import { EmptyArea, EmptyFontArea, EmptyIconArea } from './index.style';

function NoData({ title }: { title: string }) {
  const pharmacist = useAppSelector(selectUserInformationPharmacistData);
  const isPharmacyAutoConfirmed = useAppSelector(selectPharmacyAutoConfirmed);

  useEffect(() => {
    if (
      !pharmacist.pharmacy_id ||
      isPharmacyAutoConfirmed.auto_confirmed === undefined
    )
      return;

    trackNewReservationRequested.pageTPRequested({
      tppId: null,
      pharmacyId: String(pharmacist.pharmacy_id),
      deliveryMethod: null,
      symptomKeyword: null,
      tmGuideType: null,
      isAutoConfirmed: isPharmacyAutoConfirmed.auto_confirmed,
    });
  }, [isPharmacyAutoConfirmed.auto_confirmed, pharmacist.pharmacy_id]);

  return (
    <EmptyArea>
      <EmptyIconArea>
        <ReactBootstrapIcons.Clipboard size={32} color={COLOR['fill/dark']} />
      </EmptyIconArea>
      <EmptyFontArea>
        <Font fontType="h1" color="fill/dark">
          {title}
        </Font>
      </EmptyFontArea>
    </EmptyArea>
  );
}

export default NoData;
