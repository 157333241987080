import { ReactBootstrapIcons } from '@assets';
import { Font } from '@components/atoms';
import { formatters } from '@functions';
import { MouseEvent } from 'react';
import { PlusMinusTextInputType } from './index.d';
import * as Style from './index.style';

function PlusMinusTextInput({
  minValue,
  maxValue,
  term,
  unitText,
  value,
  setValue,
}: PlusMinusTextInputType) {
  const { priceFormatter } = formatters;
  const onChangeValue = (e: MouseEvent<SVGElement>, type: 'plus' | 'minus') => {
    e.preventDefault();
    if (type === 'plus') {
      if ((maxValue && value < maxValue) || !maxValue)
        setValue((v) => v + term);
    } else if ((minValue && value > minValue) || !minValue)
      setValue((v) => v - term);
  };
  return (
    <Style.Container>
      <Style.IconArea>
        <ReactBootstrapIcons.DashLg
          onClick={(e) => onChangeValue(e, 'minus')}
        />
      </Style.IconArea>
      <Style.TextInputArea>
        <Font fontType="body2" color="fill/black">
          {priceFormatter.prettyPriceFormatter(String(value))}
        </Font>
        <Font fontType="body2" color="fill/black">
          {unitText}
        </Font>
      </Style.TextInputArea>
      <Style.IconArea>
        <ReactBootstrapIcons.PlusLg onClick={(e) => onChangeValue(e, 'plus')} />
      </Style.IconArea>
    </Style.Container>
  );
}

export default PlusMinusTextInput;
