import { IconDetailProps } from '@components/atoms/images';
import { COLOR } from '../color';

function PriceIcon({ width, height, color }: IconDetailProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2071 21.2929L21.2929 15.2071C21.7456 14.7544 22 14.1403 22 13.5C22 12.8597 21.7456 12.2456 21.2929 11.7929L12.6716 3.17157C11.9214 2.42143 10.904 2 9.84315 2L4.56066 2C3.88153 2 3.23022 2.26978 2.75 2.75C2.26978 3.23022 2 3.88153 2 4.56066L2 9.84315C2 10.904 2.42143 11.9214 3.17157 12.6716L11.7929 21.2929C12.2456 21.7456 12.8597 22 13.5 22C14.1403 22 14.7544 21.7456 15.2071 21.2929ZM6.5 8.5C7.60457 8.5 8.5 7.60457 8.5 6.5C8.5 5.39543 7.60457 4.5 6.5 4.5C5.39543 4.5 4.5 5.39543 4.5 6.5C4.5 7.60457 5.39543 8.5 6.5 8.5Z"
        fill={COLOR[color]}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.46729 12.8125C8.22517 12.6797 7.92125 12.7684 7.78847 13.0105C7.65569 13.2526 7.74433 13.5565 7.98645 13.6893L9.22831 14.3703L8.75687 14.8418C8.56161 15.037 8.56161 15.3536 8.75687 15.5489C8.95213 15.7441 9.2687 15.7441 9.46397 15.5489L10.1416 14.8712L13.4665 16.6944C13.66 16.8006 13.9002 16.7672 14.0576 16.6124C14.2149 16.4576 14.2521 16.2179 14.1491 16.0227L12.367 12.6458L12.7309 12.2819L16.1279 14.048C16.3232 14.1496 16.562 14.1114 16.716 13.954C16.8699 13.7966 16.9028 13.557 16.7969 13.364L14.9737 10.0392L15.8279 9.18489C16.0232 8.98963 16.0232 8.67305 15.8279 8.47779C15.6327 8.28253 15.3161 8.28254 15.1208 8.4778L14.4728 9.12582L13.7918 7.88397C13.659 7.64185 13.3551 7.55321 13.113 7.68599C12.8709 7.81877 12.7822 8.12269 12.915 8.36481L13.7362 9.86239L12.542 11.0566L11.0065 10.2583C10.8125 10.1574 10.5754 10.1943 10.4213 10.3494C10.2671 10.5046 10.2316 10.7419 10.3337 10.9353L11.1384 12.4602L9.96488 13.6338L8.46729 12.8125ZM10.8782 14.1346L12.4878 15.0173L11.6269 13.3859L10.8782 14.1346ZM13.4725 11.5404L15.1286 12.4014L14.2371 10.7757L13.4725 11.5404Z"
        fill="white"
      />
    </svg>
  );
}

export default PriceIcon;
