import { ReactNode } from 'react';
import * as Style from './index.style';

const ListContent = {
  Element: ({
    children,
    flex,
    flexDirection = 'row',
    border = '',
    ...props
  }: {
    children?: ReactNode;
    flex: number;
    flexDirection?: 'row' | 'column';
    border?: string;
    isPointer?: boolean;
    justifyContent?: string;
    [key: string]: any;
  }) => {
    return (
      <Style.DefaultListContent
        flexDirection={flexDirection}
        flex={flex}
        border={border}
        {...props}
      >
        {children}
      </Style.DefaultListContent>
    );
  },
  Regular: ({
    children,
    flex,
    flexDirection = 'row',
    border = '',
    ...props
  }: {
    children?: ReactNode;
    flex: number;
    flexDirection?: 'row' | 'column';
    border?: string;
    [key: string]: any;
  }) => {
    return (
      <Style.DefaultListContent
        flexDirection={flexDirection}
        flex={flex}
        border={border}
        {...props}
      >
        {children}
      </Style.DefaultListContent>
    );
  },
  Bold: ({
    children,
    flex,
    flexDirection = 'row',
    border = '',
    ...props
  }: {
    children: ReactNode;
    flex: number;
    flexDirection?: 'row' | 'column';
    border?: string;
    [key: string]: any;
  }) => {
    return (
      <Style.DefaultListContent
        flexDirection={flexDirection}
        flex={flex}
        border={border}
        {...props}
      >
        {children}
      </Style.DefaultListContent>
    );
  },
};

export default ListContent;
