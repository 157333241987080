import { IconDetailProps } from '@components/atoms/images';
import { COLOR } from '../color';

function FaxIcon({ width, height, color }: IconDetailProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M14.5982 2H1.40175C0.995102 2 0.666656 2.33422 0.666656 2.74801V9.46419C0.666656 9.87798 0.995102 10.2122 1.40175 10.2122H3.60703V14H12.4047V10.2122H14.5982C15.0049 10.2122 15.3333 9.87798 15.3333 9.46419V2.74403C15.3294 2.33422 15.001 2 14.5982 2ZM10.9384 12.5H5.0733V9.45623H10.9384V12.5ZM12.3969 7.98011H3.59921V6.48806H12.3969V7.98011ZM13.132 4.98409C12.7253 4.98409 12.3969 4.64987 12.3969 4.23607C12.3969 3.82228 12.7253 3.48806 13.132 3.48806C13.5386 3.48806 13.867 3.82228 13.867 4.23607C13.867 4.64987 13.5347 4.98409 13.132 4.98409Z"
        fill={COLOR[color]}
      />
    </svg>
  );
}

export default FaxIcon;
