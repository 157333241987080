import { notifyOtherClients } from '@hooks/featureHooks/useSocket';
import { postAcceptTelepharmacy } from '@mobile/api';
import { useMutation } from '@tanstack/react-query';

import { useNavigate } from 'react-router-dom';

interface PostAcceptTelepharmacyParams {
  tppId: string;
  amount: string;
  isSubstituteSelected: boolean;
  substituteReasonContent: string;
}

const useAcceptTP = () => {
  const navigate = useNavigate();

  return useMutation({
    mutationFn: ({
      tppId,
      amount,
      isSubstituteSelected,
      substituteReasonContent,
    }: PostAcceptTelepharmacyParams) => {
      return postAcceptTelepharmacy({
        tppId,
        amount,
        isSubstituteSelected,
        substituteReasonContent,
      });
    },
    onSuccess: () => {
      alert('조제 수락에 성공하였습니다.');
      notifyOtherClients('telepharmacy');
      return navigate(`/mobile/request`);
    },
    onError: () => {
      alert('조제 수락에 실패하였습니다.');
    },
  });
};

export default useAcceptTP;
