import { IconDetailProps } from '@components/atoms/images';

function ChangeIcon({ width, height, color }: IconDetailProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.8778 12.8557L13.8778 10.4284C13.8778 10.053 14.3212 9.85977 14.5904 10.1178L19.0346 14.3792C19.2136 14.5509 19.21 14.8407 19.0267 15.0077L14.5824 19.056C14.3107 19.3036 13.8778 19.1083 13.8778 18.7381L13.8778 16.2767L5.01121 16.2767C4.54483 16.2767 4.16675 15.8938 4.16675 15.4215L4.16675 13.7109C4.16675 13.2386 4.54483 12.8557 5.01121 12.8557L13.8778 12.8557Z"
        fill="#FFB200"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.12207 7.14415L6.12207 9.57142C6.12207 9.94679 5.67869 10.14 5.40955 9.88196L0.96532 5.62057C0.786288 5.44891 0.789945 5.15906 0.97325 4.99208L5.41748 0.943764C5.68923 0.696224 6.12207 0.891547 6.12207 1.26171L6.12207 3.72307L14.9887 3.72307C15.4551 3.72307 15.8332 4.10599 15.8332 4.57834V6.28888C15.8332 6.76123 15.4551 7.14415 14.9887 7.14415L6.12207 7.14415Z"
        fill="#FFE066"
      />
    </svg>
  );
}

export default ChangeIcon;
