import { Response } from '@constants/apis/types';
import fetchAPI from '@utils/fetchAPI';

const MY_DOCTOR_TYPE = 'myDoctor';

export interface GetSubstitutePrescriptionInfoResponse {
  medicine_list: string[];
  multiplied_price: number;
  price: number;
  preparation_fee: number;
  whole_price: number;
  quantity: number;
  total_dosing_days: number;
}

export interface GetSubstitutePrescriptionInfoParams {
  tm_id: number;
  pharmacy_id: number;
  tpp_id: number;
}

export const getSubstitutePrescriptionInfo = (
  params: GetSubstitutePrescriptionInfoParams,
): Promise<Response<GetSubstitutePrescriptionInfoResponse>> => {
  return fetchAPI.get(
    'pharmacist-api/telepharmacy/tpprocess/check-substitution/',
    params,
    MY_DOCTOR_TYPE,
  );
};
