import { COLOR, Font } from '@components/atoms';
import InfoIcon from '@components/atoms/images/InfoIcon';
import { EFormType } from '@pages/loginPage';
import { memo } from 'react';
import { useFindIdPasswordSubmit, useFindIdPasswordTimer } from './hooks';
import { FindIdPasswordCtaView, FindIdPasswordFormView } from './views';

type IFindIdPasswordContainerProps = {
  updateFormType: (type: EFormType) => void;
};

export const FindIdPasswordContainer = memo<IFindIdPasswordContainerProps>(
  ({ updateFormType }) => {
    const { formattedTime, startTimer, shouldTriggerTimer, resetTimer } =
      useFindIdPasswordTimer();

    const { submit, errorMessage, isLoginSuccess, retry } =
      useFindIdPasswordSubmit(startTimer, resetTimer);

    if (isLoginSuccess !== undefined && !isLoginSuccess) {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: 40,
            backgroundColor: 'white',
            borderTopRightRadius: 16,
            borderBottomRightRadius: 16,
            width: 480,
          }}
        >
          <div style={{ flex: 1 }}>
            <div
              style={{
                paddingBottom: 30,
                gap: 8,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Font fontType="display2">로그인 요청 거절</Font>
              <Font fontType="body1_medium" color="fill/medium">
                휴대폰 번호 로그인 요청이 거절되었습니다.
              </Font>
            </div>
          </div>
          <FindIdPasswordCtaView
            updateFormType={updateFormType}
            submit={submit}
            shouldRetry
            retry={retry}
          />
        </div>
      );
    }

    return (
      // @ts-ignore
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: 40,
          backgroundColor: 'white',
          borderTopRightRadius: 16,
          borderBottomRightRadius: 16,
          width: 480,
        }}
      >
        <div style={{ flex: 1 }}>
          <div
            style={{
              paddingBottom: 30,
              gap: 8,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Font fontType="display2">본인 인증</Font>
            <Font fontType="body1_medium" color="fill/medium">
              본인 확인을 위해
              <span
                style={{
                  fontSize: 16,
                  letterSpacing: '-0.02em',
                  color: COLOR['fill/accent'],
                }}
              >
                {' '}
                카카오톡 메시지
              </span>
              {
                '가 전송됩니다.\n메시지의 인증수락 버튼을 누르면 자동로그인이 진행됩니다.\n(카카오톡 발송 실패 시 문자로 대체 발송됩니다.)'
              }
            </Font>
          </div>
          {errorMessage && (
            <div
              style={{
                paddingBottom: 30,
              }}
            >
              <div
                style={{
                  backgroundColor: '#FFF0F0',
                  borderRadius: 8,
                  padding: '8px 12px',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: 8,
                }}
              >
                <InfoIcon width={16} height={16} color="state/distructive" />
                <Font fontType="body2" color="state/distructive">
                  {errorMessage}
                </Font>
              </div>
            </div>
          )}
          <FindIdPasswordFormView
            formattedTime={formattedTime}
            shouldTriggerTimer={shouldTriggerTimer}
          />
        </div>
        <FindIdPasswordCtaView
          updateFormType={updateFormType}
          submit={submit}
        />
      </div>
    );
  },
);
