import { COLOR } from '@components/atoms';
import styled from 'styled-components';

export const ModalBodyArea = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

export const ModalBodyContentArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const SubTitleArea = styled.div`
  display: flex;
  gap: 2px;
  min-width: 88px;
  padding: 8px 0px;
`;

export const HasCompoundingFeeButtonContent = styled.div`
  display: flex;
  gap: 3px;
`;

export const UnitAddButtonArea = styled.div`
  display: flex;
  gap: 4px;
`;

export const ModalBodyTabArea = styled.div`
  display: flex;
  gap: 8px;
`;

export const ModalBodyNoticeArea = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  padding: 14px 16px;
  background-color: ${COLOR['fill/light']};
  border-radius: 8px;
`;

export const ModalBodyUnitPriceArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const ModalBodyNoticeTextArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const ModalBodyNoticeLinkArea = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  cursor: pointer;
`;

export const NoticeLink = styled.a`
  text-decoration: underline;
  color: ${COLOR['fill/dark']};
  cursor: pointer;
`;

export const MedicinePriceEachUnitRow = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const UnitDropDownArea = styled.div`
  position: relative;
  border-radius: 8px;
`;

export const MedicineUnitInputArea = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100px;
  padding: 14px 16px;
  border: 1px solid ${COLOR['border/outline']};
  border-radius: 8px;
  background-color: ${COLOR['fill/white']};
`;

export const UnitDropDown = styled.div<{ isScroll: boolean }>`
  position: absolute;
  overflow-y: ${(props) => (props.isScroll ? 'scroll' : 'hidden')};
  width: 100px;
  max-height: 208px;
  top: 58px;
  border-radius: 8px;
  border: 1px solid ${COLOR['border/outline']};
  background-color: ${COLOR['fill/white']};
  z-index: 1;
`;

export const MedicineSumInputArea = styled.input`
  width: 302px;
  gap: 8px;
  padding: 12px 16px;
  width: 100%;
  border-radius: 8px;
  font-size: 16px;
  line-height: 24px;
  border: 1px solid ${COLOR['border/outline']};
  ::placeholder {
    color: ${COLOR['fill/medium']};
  }
  :disabled {
    background-color: ${COLOR['fill/light']};
    color: ${COLOR['disabled/foreground']};
  }
`;

export const EmptyArea = styled.div`
  width: 24px;
  height: 24px;
`;

export const MedicinePriceEachUnitRowDeleteButtonArea = styled.div`
  cursor: pointer;
`;

export const EmptyAddMedicineUnitArea = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 50px;
  padding: 14px 16px;
  background-color: ${COLOR['fill/light']};
  border-radius: 8px;
`;
