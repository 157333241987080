export default function HospitalIcon() {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1086_3577)">
        <path
          d="M28.5 4.5H7.5C5.8425 4.5 4.515 5.8425 4.515 7.5L4.5 28.5C4.5 30.1575 5.8425 31.5 7.5 31.5H28.5C30.1575 31.5 31.5 30.1575 31.5 28.5V7.5C31.5 5.8425 30.1575 4.5 28.5 4.5ZM27 21H21V27H15V21H9V15H15V9H21V15H27V21Z"
          fill="#474747"
        />
      </g>
      <defs>
        <clipPath id="clip0_1086_3577">
          <rect width="36" height="36" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
