import { IconDetailProps } from '@components/atoms/images';
import { COLOR } from '../color';

function InfoIcon({ width, height, color }: IconDetailProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 17"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.666504 8.49984C0.666504 4.44975 3.94975 1.1665 7.99984 1.1665C12.0499 1.1665 15.3332 4.44975 15.3332 8.49984C15.3332 12.5499 12.0499 15.8332 7.99984 15.8332C3.94975 15.8332 0.666504 12.5499 0.666504 8.49984ZM8.83317 5.49984C8.83317 5.96007 8.46007 6.33317 7.99984 6.33317C7.5396 6.33317 7.1665 5.96007 7.1665 5.49984C7.1665 5.0396 7.5396 4.6665 7.99984 4.6665C8.46007 4.6665 8.83317 5.0396 8.83317 5.49984ZM7.99984 12.3332C8.36803 12.3332 8.6665 12.0347 8.6665 11.6665L8.6665 7.6665C8.6665 7.29831 8.36803 6.99984 7.99984 6.99984C7.63165 6.99984 7.33317 7.29831 7.33317 7.6665L7.33317 11.6665C7.33317 12.0347 7.63165 12.3332 7.99984 12.3332Z"
        fill={COLOR[color]}
      />
    </svg>
  );
}

export default InfoIcon;
