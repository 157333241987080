import { myDoctorAPI } from '@services';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@stores';
import { TelepharmacyCountType } from './index.d';

const initialState: TelepharmacyCountType = {
  telepharmacyCount: {
    requested: 0,
    requested_expire_min: 5,
    requested_expire_in: 0,
    accepted: -1,
    pre_confirmed: -1,
    confirmed: -1,
    finished_quick: -1,
    finished_parcel: -1,
  },
};

export const getTelepharmacyCount = createAsyncThunk(
  'telepharmacyCount/getTelepharmacyCount',
  async () => {
    const { data } = await myDoctorAPI.getTelepharmacyCount();
    return data;
  },
);

export const telepharmacyCount = createSlice({
  name: 'telepharmacyCount',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTelepharmacyCount.fulfilled, (state, action) => {
      // 체크 필요함, 다른 필드도 체크해야 하나
      if (typeof action.payload?.requested === 'number') {
        state.telepharmacyCount = action.payload;
      }
    });
  },
});

// export const selectTelepharmacyCount_requested = (state: RootState) =>
//   state.telepharmacyCount.telepharmacyCount.requested;
export const selectTelepharmacyCount_requested_expire_min = (
  state: RootState,
) => state.telepharmacyCount.telepharmacyCount.requested_expire_min;
export const selectTelepharmacyCount_requested_expire_in = (state: RootState) =>
  state.telepharmacyCount.telepharmacyCount.requested_expire_in;
// export const selectTelepharmacyCount_accepted = (state: RootState) =>
//   state.telepharmacyCount.telepharmacyCount.accepted;
export const selectTelepharmacyCount_pre_confirmed = (state: RootState) =>
  state.telepharmacyCount.telepharmacyCount.pre_confirmed;
// export const selectTelepharmacyCount_confirmed = (state: RootState) =>
//   state.telepharmacyCount.telepharmacyCount.confirmed;

export default telepharmacyCount.reducer;
