import { Font } from '@components/atoms';
import { useNavigate } from 'react-router-dom';
import * as Style from './index.style';

function Pagination({
  maxPage,
  page,
  limit,
}: {
  maxPage: number;
  page: number;
  limit: number;
}) {
  const navigate = useNavigate();
  const pageArray: Array<{ title: number | string; onClick: Function }> = [];
  const basePage = page - (page % 10);

  const goPage = (clickedPage: number) => {
    navigate(`?page=${clickedPage}&limit=${limit}`);
  };

  if (page > 0)
    pageArray.push({
      title: '이전',
      onClick: () => (basePage < 10 ? goPage(0) : goPage(basePage - 10)),
    });
  for (let i = basePage; i < maxPage && i < basePage + 10; i += 1) {
    pageArray.push({ title: i + 1, onClick: () => goPage(i) });
  }
  if (maxPage > page + 1) {
    pageArray.push({
      title: '다음',
      onClick: () =>
        basePage + 10 > maxPage ? goPage(maxPage - 1) : goPage(basePage + 10),
    });
  }

  return (
    <Style.PaginationArea>
      <Style.PaginationContainer>
        {pageArray.map((item, index) => {
          const isClicked = page + 1 === item.title;
          return (
            <Style.PaginationItem
              isClicked={isClicked}
              isFirst={index === 0}
              isLast={index === pageArray.length - 1}
              key={item.title}
              onClick={() => item.onClick()}
            >
              <Font
                fontType="h4"
                color={isClicked ? 'fill/white' : 'fill/accent'}
              >
                {item.title}
              </Font>
            </Style.PaginationItem>
          );
        })}
      </Style.PaginationContainer>
    </Style.PaginationArea>
  );
}

export default Pagination;
