import { IconDetailProps } from '@components/atoms/images';
import { COLOR } from '../color';

function SearchIcon({ width, height, color }: IconDetailProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4215 12.5997C10.2926 13.4772 8.87404 13.9998 7.33342 13.9998C3.65152 13.9998 0.666748 11.0151 0.666748 7.33317C0.666748 3.65127 3.65152 0.666504 7.33342 0.666504C11.0153 0.666504 14.0001 3.65127 14.0001 7.33317C14.0001 8.87373 13.4775 10.2922 12.6 11.4211L17.0893 15.9105C17.4148 16.2359 17.4148 16.7635 17.0893 17.089C16.7639 17.4144 16.2363 17.4144 15.9108 17.089L11.4215 12.5997ZM12.3334 7.33317C12.3334 10.0946 10.0948 12.3332 7.33342 12.3332C4.57199 12.3332 2.33341 10.0946 2.33341 7.33317C2.33341 4.57175 4.57199 2.33317 7.33342 2.33317C10.0948 2.33317 12.3334 4.57175 12.3334 7.33317Z"
        fill={COLOR[color]}
      />
    </svg>
  );
}

export default SearchIcon;
