import { BColor } from '@components/meraki-ui/BColor';
import { IconDetailProps } from '@components/meraki-ui/BIcon/svg.type';

export default function CreditCardIcon({
  width,
  height,
  color,
}: IconDetailProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 6C2 4.89543 2.89543 4 4 4H16C17.1046 4 18 4.89543 18 6V14C18 15.1046 17.1046 16 16 16H4C2.89543 16 2 15.1046 2 14V6ZM4 5C3.44772 5 3 5.44772 3 6V7H17V6C17 5.44772 16.5523 5 16 5H4ZM17 9H3V14C3 14.5523 3.44772 15 4 15H16C16.5523 15 17 14.5523 17 14V9Z"
        fill={BColor[color]}
      />
      <path
        d="M4 12C4 11.4477 4.44772 11 5 11H6C6.55228 11 7 11.4477 7 12V13C7 13.5523 6.55228 14 6 14H5C4.44772 14 4 13.5523 4 13V12Z"
        fill={BColor[color]}
      />
    </svg>
  );
}
