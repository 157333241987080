import styled from 'styled-components';

type Props = {
  goBack: () => void;
  accept: () => void;
  isButtonDisabled: boolean;
};

function BottomCTA({ goBack, accept, isButtonDisabled }: Props) {
  return (
    <Container>
      <ButtonWrapper>
        <RejectButton onClick={goBack}>취소</RejectButton>
        <AcceptButton
          isButtonDisabled={isButtonDisabled}
          disabled={isButtonDisabled}
          onClick={accept}
        >
          수락 완료
        </AcceptButton>
      </ButtonWrapper>
    </Container>
  );
}

export default BottomCTA;

const Container = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  width: 100%;
  padding: 12px 20px 28px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;

  border-top: 1px solid var(--border-divider, #f1f2f4);
  background: var(--fill-white, #fff);
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`;

const RejectButton = styled.button`
  border: 1px solid #dddfe3;
  display: flex;
  width: 100px;
  padding: 16px 12px;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border-radius: 12px;

  color: #474a52;
  text-align: center;

  font-family: Pretendard, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;

  letter-spacing: -0.32px;
`;

const AcceptButton = styled.button<{ isButtonDisabled: boolean }>`
  border: none;
  display: flex;
  padding: 16px 12px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;

  border-radius: 12px;
  background: ${({ isButtonDisabled }) =>
    isButtonDisabled ? '#F9FAFA' : '#2f6eff'};

  color: ${({ isButtonDisabled }) =>
    isButtonDisabled ? '#C3C5CB' : '#ffffff'};
  text-align: center;

  font-family: Pretendard, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
`;
