import useCustomerCenterModalHook from './hooks';
import CustomerCenterModalTemplate from './templates';

function CustomerCenterModal() {
  const { isVisible, closeModal } = useCustomerCenterModalHook();

  return (
    <CustomerCenterModalTemplate
      isVisible={isVisible}
      closeModal={closeModal}
    />
  );
}

export default CustomerCenterModal;
