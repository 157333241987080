import { BColor } from '@components/meraki-ui/BColor';
import { IconDetailProps } from '@components/meraki-ui/BIcon/svg.type';

export default function HeartIcon({ width, height, color }: IconDetailProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 5.84204L9.37284 5.19742C7.89904 3.68256 5.19988 4.20548 4.22531 6.10874C3.76697 7.00385 3.66391 8.29591 4.5 9.94538C5.30525 11.534 6.97921 13.4356 10 15.5078C13.0208 13.4356 14.6947 11.534 15.5 9.94538C16.3361 8.29591 16.233 7.00385 15.7747 6.10874C14.8001 4.20548 12.101 3.68256 10.6272 5.19742L10 5.84204ZM10 16.5625C-3.41649 7.69736 5.86891 0.776741 9.84628 4.4377C9.89845 4.48572 9.94971 4.53557 10 4.58725C10.0503 4.53557 10.1015 4.48573 10.1537 4.4377C14.1311 0.776732 23.4165 7.69735 10 16.5625Z"
        fill={BColor[color]}
      />
    </svg>
  );
}
