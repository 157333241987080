import { QUERY_KEY } from '@constants/react-query';
import { getPharmacyInfo } from '@mobile/api';
import { telepharmcyStorage } from '@mobile/utils';

import { useQuery } from '@tanstack/react-query';

const usePharmacyInfo = () => {
  return useQuery({
    queryFn: () =>
      getPharmacyInfo({
        pharmacy_id: Number(telepharmcyStorage.telepharmacyId),
      }),
    queryKey: [QUERY_KEY.TP_PHARMACY_INFO],
    retry: false,
  });
};

export default usePharmacyInfo;
