import { BColor } from '@components/meraki-ui/BColor';
import { IconDetailProps } from '@components/meraki-ui/BIcon/svg.type';

export default function HealthWellIcon({
  width,
  height,
  color,
}: IconDetailProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.89062 9.78516C4.89062 9.53906 5.03906 9.39258 5.25781 9.39062C5.48047 9.39258 5.60742 9.53516 5.60547 9.77344V11H6.26562V9.64844C6.26562 9.16406 5.97461 8.85156 5.52734 8.85156C5.21094 8.85156 4.98828 9.00586 4.89062 9.26562H4.86719V8.17188H4.23047V11H4.89062V9.78516Z"
        fill={BColor[color]}
      />
      <path
        d="M7.94172 10.4414C8.25422 10.4395 8.50812 10.1875 8.50812 9.87109C8.50812 9.55859 8.25422 9.30469 7.94172 9.30469C7.62727 9.30469 7.37336 9.55859 7.37141 9.87109C7.37336 10.1875 7.62727 10.4395 7.94172 10.4414Z"
        fill={BColor[color]}
      />
      <path
        d="M9.29734 11H10.0005L10.3364 9.74219H10.3637L10.6997 11H11.4028L11.9614 8.87891H11.2973L11.0122 10.2422H10.9966L10.6723 8.87891H10.0278L9.71141 10.25H9.69578L9.39891 8.87891H8.73875L9.29734 11Z"
        fill={BColor[color]}
      />
      <path
        d="M13.0084 11.0391C13.5573 11.0391 13.9205 10.7734 14.0006 10.3594H13.3873C13.3346 10.4844 13.1998 10.5625 13.0202 10.5625C12.7526 10.5625 12.5885 10.3848 12.5866 10.1133V10.1016H14.0084V9.93359C14.0084 9.23047 13.5807 8.85156 12.985 8.85156C12.3483 8.85156 11.9381 9.29102 11.9381 9.94922C11.9381 10.625 12.3444 11.0391 13.0084 11.0391ZM12.5866 9.71484C12.5963 9.49609 12.7682 9.32812 13.0006 9.32812C13.2272 9.32812 13.3913 9.48828 13.3952 9.71484H12.5866Z"
        fill={BColor[color]}
      />
      <path
        d="M14.8484 8.17188H14.1883V11H14.8484V8.17188Z"
        fill={BColor[color]}
      />
      <path
        d="M15.7705 8.17188H15.1103V11H15.7705V8.17188Z"
        fill={BColor[color]}
      />
      <circle cx="10" cy="10" r="7.5" stroke={BColor[color]} />
    </svg>
  );
}
