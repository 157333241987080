import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { myDoctorAPI } from '@services';
import { UserInformationType } from 'types/index.d';

const initialState: UserInformationType.DoctorOffScheduleType = {
  doctorOffScheduleResult: {
    count: 0,
    next: '',
    previous: '',
    results: [],
  },
};

export const getDoctorOffScheduleList = async ({
  limit = 60,
  offset,
}: UserInformationType.DoctorOffScheduleRequestParameterType) => {
  const doctorOffScheduleRequestParameter: UserInformationType.DoctorOffScheduleRequestParameterType =
    {
      limit,
      offset,
    };

  const result = await myDoctorAPI.getDoctorInfoOffSchedule(
    doctorOffScheduleRequestParameter,
  );

  return result;
};

export const getDoctorOffSchedule = createAsyncThunk(
  'doctorOffSchedule/getDoctorOffSchedule',
  async ({
    limit,
    offset,
  }: UserInformationType.DoctorOffScheduleRequestParameterType) => {
    const result = await getDoctorOffScheduleList({
      limit,
      offset,
    });
    return result;
  },
);

export const doctorOffSchedule = createSlice({
  name: 'doctorOffSchedule',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDoctorOffSchedule.fulfilled, (state, action) => {
      if (typeof action?.payload?.count === 'number') {
        state.doctorOffScheduleResult = action.payload;
      }
    });
  },
});

export default doctorOffSchedule.reducer;
