/* eslint-disable func-names */
/* eslint-disable no-param-reassign */
const getType = (arg: any) => {
  if (typeof arg === 'object') {
    return Array.isArray(arg) ? 'array' : 'object';
  }

  return 'scalar';
};

const processing = (key: any, value: any, context: any) => {
  switch (getType(value)) {
    case 'object':
      context = context.concat(buildObject(value, key));
      break;
    case 'array':
      context = context.concat(buildArray(value, key));
      break;
    default:
      context.push({ key, value });
      break;
  }
  return context;
};

const buildObject = (params: any, head: any) => {
  let parts: any = [];
  head = getType(head) !== 'array' ? [head] : head;

  Object.keys(params).forEach((key) => {
    const seg = [...head];
    seg.push(key);
    parts = processing(seg, params[key], parts);
  });

  return parts;
};

const buildArray = (params: any, head: any) => {
  let parts: any = [];
  head = getType(head) !== 'array' ? [head] : head;
  params.forEach((value: any, index: any) => {
    const seg = [...head];
    seg.push(index);
    parts = processing(seg, value, parts);
  });

  return parts;
};

export default function (params: any) {
  if (!params) {
    return '';
  }

  let parts: any = [];
  Object.keys(params).forEach((key) => {
    parts = processing(key, params[key], parts);
  });

  return parts
    .map((part: any) => {
      const buildKey = (key: any) => {
        if (getType(key) !== 'array') {
          return key;
        }

        const head = key.shift();
        return head + key.map((val: any) => `[${val}]`).join('');
      };

      return `${encodeURIComponent(buildKey(part.key))}=${encodeURIComponent(
        part.value,
      )}`;
    })
    .join('&');
}
