import BFlex from '@components/meraki-ui/BFlex';
import BIcon from '@components/meraki-ui/BIcon';
import BTypography from '@components/meraki-ui/BTypography';
import { memo } from 'react';

function ReRoutingPage() {
  return (
    <BFlex
      height="100vh"
      width="100vw"
      alignItems="center"
      justifyContent="center"
      backgroundColor="backgroundColor"
      isColumn
      gap={32}
    >
      <BIcon icon="loading_logo" />

      <BIcon icon="loading" />

      <BFlex alignItems="center" isColumn>
        <BTypography
          text="파트너 페이지로 이동하고 있어요."
          size={28}
          color="mono08"
          isBold
        />
        <BTypography
          text="잠시만 기다려주세요!"
          size={28}
          color="mono08"
          isBold
        />
      </BFlex>
    </BFlex>
  );
}

export default memo(ReRoutingPage);
