import { Button, COLOR, Font } from '@components/atoms';
import SvgIcon from '@components/atoms/images';
import BModal from '@components/meraki-ui/BModal';
import NotificationToastUI from '@components/organisms/toast/notificationToast/components/notificationToastUI';
import { useAppSelector } from '@stores/hooks';
import { selectUserInformationPharmacistData } from '@stores/userInformationStore';
import { useMutation } from '@tanstack/react-query';
import fetchAPI from '@utils/fetchAPI';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import useMedicineUnitManagementModalHook from '../medicineUnitManagementModal/hooks';
import useMedicineSoldOutAlertModalHook from './hooks';
import * as Style from './index.style';

function MedicineSoldOutAlertModal() {
  const pharmacist = useAppSelector(selectUserInformationPharmacistData);
  const { isVisible, targetMedicine, closeModal } =
    useMedicineSoldOutAlertModalHook();
  const { setSelectedCategory } = useMedicineUnitManagementModalHook();
  const { mutateAsync: deleteWegovyActive } = useMutation({
    mutationFn: () =>
      fetchAPI.delete(
        `pharmacist-api/telepharmacy/wegovy-active/invalidate-cache/`,
        { pharmacy_id: pharmacist.pharmacy_id },
        'myDoctor',
      ),
  });

  const navigate = useNavigate();

  const handleRejectType = () => {
    closeModal();
    setSelectedCategory('다이어트 주사');
    navigate('/home/medicineManagement');
    toast(
      <NotificationToastUI
        icon={
          <div>
            <SvgIcon icon="noticeIcon" width={24} color="yellow900" />
          </div>
        }
        title={
          <Font fontType="h3" color="fill/black">
            의약품 품절 처리 안내
          </Font>
        }
        description={
          <>
            <Font fontType="body2" color="state/distructive">
              재고 부족 등의 사유로 ‘위고비프리필드펜(세마글루티드)’가 품절
              처리되었습니다.
            </Font>
            <Font fontType="body2" color="fill/black">
              1일 뒤 자동으로 품절 처리가 해제될 예정이며, 바로 품절 해제가
              필요하시다면 ‘의약품 관리’에서 직접 해제하실 수 있습니다.
            </Font>
          </>
        }
      />,
      {
        autoClose: false,
        toastId: 'notification-wegovy',
      },
    );
  };

  const handleAutoReject = async () => {
    await deleteWegovyActive().then(() => {
      closeModal();
      setSelectedCategory('다이어트 주사');
      navigate('/home/medicineManagement');
      toast(
        <NotificationToastUI
          icon={
            <div>
              <SvgIcon icon="noticeIcon" width={24} color="yellow900" />
            </div>
          }
          title={
            <Font fontType="h3" color="fill/black">
              의약품 품절 처리 안내
            </Font>
          }
          description={
            <>
              <Font fontType="body2" color="state/distructive">
                ‘위고비프리필드펜(세마글루티드)’ 조제 요청이 2회 이상 자동
                거절되어 품절 처리되었습니다.
              </Font>
              <Font fontType="body2" color="fill/black">
                1일 뒤 자동으로 품절 처리가 해제될 예정이며, 바로 품절 해제가
                필요하시다면 ‘의약품 관리’에서 직접 해제하실 수 있습니다.
              </Font>
            </>
          }
        />,
        {
          autoClose: false,
          toastId: 'notification-wegovy',
        },
      );
    });
  };

  return (
    <BModal
      open={isVisible}
      width={480}
      padding={30}
      onOpenChange={() => closeModal()}
    >
      <Style.ModalArea>
        <Style.ModalBodyArea>
          <Font fontType="h1" color="fill/black">
            의약품 품절 처리 안내
          </Font>
          <Style.ModalContentArea>
            <Font fontType="body1_medium" color="state/distructive">
              {targetMedicine === 'reject' &&
                `재고 부족 등의 사유로 ‘위고비프리필드펜(세마글루티드)’가 품절
              처리되었습니다.`}

              {targetMedicine === 'auto-reject' &&
                `‘위고비프리필드펜(세마글루티드)’ 조제 요청이 2회 이상 자동 거절되어 품절 처리되었습니다.`}
            </Font>
            <Font fontType="body1" color="fill/dark">
              1일 뒤 자동으로 품절 처리가 해제될 예정이며, 바로 품절 해제가
              필요하시다면 ‘의약품 관리’에서 직접 해제하실 수 있습니다.
            </Font>
          </Style.ModalContentArea>
        </Style.ModalBodyArea>
        <Style.ModalFooterArea>
          <Button
            width="204px"
            height="56px"
            borderRadius="12px"
            backgroundColor={COLOR['fill/white']}
            borderColor={COLOR['border/outline']}
            title={
              <Font fontType="body1" color="fill/dark">
                닫기
              </Font>
            }
            onClick={closeModal}
          />
          <Button
            width="204px"
            height="56px"
            borderRadius="12px"
            backgroundColor={COLOR['fill/accent']}
            title={
              <Font fontType="body1" color="fill/white">
                의약품 관리 이동
              </Font>
            }
            onClick={() => {
              // eslint-disable-next-line @typescript-eslint/no-unused-expressions
              targetMedicine === 'auto-reject'
                ? handleAutoReject()
                : handleRejectType();
            }}
          />
        </Style.ModalFooterArea>
      </Style.ModalArea>
    </BModal>
  );
}

export default MedicineSoldOutAlertModal;
