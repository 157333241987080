import { IconDetailProps } from '@components/atoms/images';
import { COLOR } from '../color';

function CheckboxIcon({ width, height, color }: IconDetailProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.3821 4.99782C16.7285 5.30089 16.7636 5.82736 16.4605 6.17372L8.80428 14.9237C8.64699 15.1035 8.42012 15.2071 8.18127 15.2083C7.94241 15.2095 7.71453 15.1081 7.55546 14.9299L3.54504 10.4373C3.23855 10.094 3.26843 9.56719 3.61177 9.2607C3.95511 8.95421 4.4819 8.98409 4.78839 9.32743L8.17089 13.1166L15.2062 5.07622C15.5093 4.72985 16.0358 4.69476 16.3821 4.99782Z"
        fill={COLOR[color]}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5 1.66667H2.5C2.03976 1.66667 1.66667 2.03976 1.66667 2.5V17.5C1.66667 17.9602 2.03976 18.3333 2.5 18.3333H17.5C17.9602 18.3333 18.3333 17.9602 18.3333 17.5V2.5C18.3333 2.03976 17.9602 1.66667 17.5 1.66667ZM2.5 0C1.11929 0 0 1.11929 0 2.5V17.5C0 18.8807 1.11929 20 2.5 20H17.5C18.8807 20 20 18.8807 20 17.5V2.5C20 1.11929 18.8807 0 17.5 0H2.5Z"
        fill={COLOR[color]}
      />
    </svg>
  );
}

export default CheckboxIcon;
