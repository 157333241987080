import { BColor } from '@components/meraki-ui/BColor';
import { IconDetailProps } from '@components/meraki-ui/BIcon/svg.type';

export default function PressureIcon({
  width,
  height,
  color,
}: IconDetailProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 4C5.77614 4 6 4.22386 6 4.5L6 11.5C6 11.7761 5.77614 12 5.5 12C5.22386 12 5 11.7761 5 11.5L5 4.5C5 4.22386 5.22386 4 5.5 4Z"
        fill={BColor[color]}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.5 4C8.77614 4 9 4.22386 9 4.5L9 15.5C9 15.7761 8.77614 16 8.5 16C8.22386 16 8 15.7761 8 15.5L8 4.5C8 4.22386 8.22386 4 8.5 4Z"
        fill={BColor[color]}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5 4C11.7761 4 12 4.22386 12 4.5L12 15.5C12 15.7761 11.7761 16 11.5 16C11.2239 16 11 15.7761 11 15.5L11 4.5C11 4.22386 11.2239 4 11.5 4Z"
        fill={BColor[color]}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5 8C14.7761 8 15 8.22386 15 8.5L15 15.5C15 15.7761 14.7761 16 14.5 16C14.2239 16 14 15.7761 14 15.5L14 8.5C14 8.22386 14.2239 8 14.5 8Z"
        fill={BColor[color]}
      />
    </svg>
  );
}
