import { IconDetailProps } from '@components/atoms/images';
import { COLOR } from '../color';

function CalendarIcon({ width, height, color }: IconDetailProps) {
  return (
    <svg
      width={width}
      height={height ?? width}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.0698 0.666992H2.06982C0.965255 0.666992 0.0698242 1.56242 0.0698242 2.66699V14.667C0.0698242 15.7716 0.965255 16.667 2.06982 16.667H14.0698C15.1744 16.667 16.0698 15.7716 16.0698 14.667V2.66699C16.0698 1.56242 15.1744 0.666992 14.0698 0.666992ZM1.06982 4.52414C1.06982 4.05075 1.51754 3.66699 2.06982 3.66699H14.0698C14.6221 3.66699 15.0698 4.05075 15.0698 4.52414V14.8098C15.0698 15.2832 14.6221 15.667 14.0698 15.667H2.06982C1.51754 15.667 1.06982 15.2832 1.06982 14.8098V4.52414Z"
        fill={COLOR[color]}
      />
      <path
        d="M7.06982 10.667C7.62211 10.667 8.06982 10.2193 8.06982 9.66699C8.06982 9.11471 7.62211 8.66699 7.06982 8.66699C6.51754 8.66699 1.06982 8.66699 1.06982 8.66699V10.667C1.06982 10.667 6.79368 10.667 7.06982 10.667Z"
        fill={COLOR[color]}
      />
      <path
        d="M9.06982 7.66699C9.34597 7.66699 15.0698 7.66699 15.0698 7.66699V5.66699C15.0698 5.66699 9.62211 5.66699 9.06982 5.66699C8.51754 5.66699 8.06982 6.11471 8.06982 6.66699C8.06982 7.21928 8.51754 7.66699 9.06982 7.66699Z"
        fill={COLOR[color]}
      />
    </svg>
  );
}

export default CalendarIcon;
