import { Font, TextInput } from '@components/atoms';
import { memo, useCallback } from 'react';

import { DebouncedFunc } from 'lodash';
import { Controller, useFormContext } from 'react-hook-form';
import {
  EmailLoginFormData,
  EmailLoginFormFields
} from '../../hooks';

type ILoginPasswordInputComponentProps = {
  submitEmail: DebouncedFunc<() => void>;
};

export const LoginPasswordInputComponent =
  memo<ILoginPasswordInputComponentProps>(({ submitEmail }) => {
    const { control, formState } = useFormContext<EmailLoginFormData>();

    const errors = formState.errors?.[EmailLoginFormFields.PASSWORD];
    const errorMessage =
      formState?.errors?.[EmailLoginFormFields.PASSWORD]?.message;

    const onPressEnter = useCallback(
      (e: any) => {
        if (e.key === 'Enter') {
          submitEmail();
        }
      },
      [submitEmail],
    );

    return (
      <Controller
        control={control}
        name={EmailLoginFormFields.PASSWORD}
        render={({ field: { onChange, value } }) => {
          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 8,
                paddingBottom: 20,
              }}
            >
              <TextInput
                value={value}
                onChange={onChange}
                onKeyPress={onPressEnter}
                placeholder="비밀번호를 입력해주세요"
                type="password"
                fontSize={16}
                title="비밀번호"
                errorMessage={errorMessage}
              />
              {errors && value.length > 0 && (
                <div style={{ paddingLeft: 20 }}>
                  <Font fontType="body2" color="state/distructive">
                    {errorMessage}
                  </Font>
                </div>
              )}
            </div>
          );
        }}
      />
    );
  });
