import { COLOR } from '@components/atoms';
import { Z_INDEX } from '@constants/styleVariables';
import { createGlobalStyle } from 'styled-components';
import ResetCSS from '../resetCSS';

const GlobalStyle = createGlobalStyle`
  ${ResetCSS}
  * {
    box-sizing: border-box;
    scroll-behavior: smooth;
  }

  @font-face {
    font-family: "Pretendard";
    src: url('https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: fallback;
  }

  @font-face {
    font-family: "Pretendard";
    src: url('https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: fallback;
  }


  @font-face {
    font-family: "Pretendard";
    src: url('https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: fallback;
  }

  @font-face {
    font-family: "Pretendard";
    src: url('https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: fallback;
  }

  body {
    font-family: "Pretendard";
    background-color: ${COLOR['bg/secondary']};
  }

  html, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
    font-family: "Pretendard";
  }
  
  
  
  input {
    :focus {
      outline-width: 0;
    }
  }

  .Toastify__toast-container--top-right {
    top: 100px;
    right: 79px;
  }

  .Toastify__toast-container--top-center {
    top: 100px;
  }

  .Toastify__toast-container  {
    display: flex;
    flex-direction: column;
    gap: 10px;
    z-index: ${Z_INDEX.toast} !important;
  }

  .Toastify__toast {
    width: 360px;
    border-radius: 12px;
    box-shadow: 0px 4px 6px 0px rgba(30, 32, 36, 0.10), 0px 2px 4px 0px rgba(30, 32, 36, 0.06);
    padding: 0;
    margin: 0;
  }
  .Toastify__toast-body {
    top: 56px;
    padding: 0;
  }

  @media only screen and (max-width: 480px) {
    .Toastify__toast {
      width: 240px;
      padding: 0;
      margin: 0;
      border-radius: 8px;
      box-shadow: 0px 4px 6px 0px rgba(30, 32, 36, 0.10), 0px 2px 4px 0px rgba(30, 32, 36, 0.06);
    }

    .Toastify__toast-container--top-center {
      top: 56px;
      align-items: center;

    }
  }


  .ReactModal__Body--open {
    overflow: hidden;
  }
`;

export default GlobalStyle;
