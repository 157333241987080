import { Button, COLOR, Font } from '@components/atoms';
import {
  CallOutMessage,
  ModalCommonStructure as Modal,
} from '@components/molecules';
import { trackLoginMixpanelEvents } from '@utils/mixpanel/login';
import { FindIdPasswordModalTemplateType } from './index.d';
import * as Style from './index.style';

function FindIdPasswordModalTemplate({
  isVisible,
  closeModal,
}: FindIdPasswordModalTemplateType) {
  const onClick = () => {
    trackLoginMixpanelEvents('clickFindIDAndPW')();
    if (window) {
      const w: any = window;
      try {
        // 채널톡 채팅창의 띄웁니다.
        w.ChannelIO('showMessenger');
      } catch (e) {
        // 문제가 있으면 새 창으로 채널톡 라운지를 오픈합니다.
        w.open('https://my-doctor-pt.channel.io/lounge', '_blank');
      }
      closeModal();
    }
  };

  return (
    <Modal
      isOpen={isVisible}
      onRequestClose={() => closeModal()}
      modalHeader={
        <Style.TitleArea>
          <Font fontType="h1">아이디/비밀번호 찾기</Font>
        </Style.TitleArea>
      }
      modalBody={
        <>
          <Style.DescriptionArea>
            <CallOutMessage backgroundColor={COLOR.yellow50}>
              <Font fontType="body1">
                아아디/비밀번호 찾기는 고객센터를 통해 요청하실 수 있습니다.
                (고객센터 운영시간: 오전 10시 - 오후 7시)
              </Font>
            </CallOutMessage>
          </Style.DescriptionArea>
          <Style.CustomerCenterButtonArea>
            <Button
              padding="16px 12px"
              onClick={onClick}
              backgroundColor={COLOR.blue50}
              title={
                <Font fontType="h4" color="fill/accent">
                  나만의닥터 고객센터 연결하기
                </Font>
              }
            />
          </Style.CustomerCenterButtonArea>
        </>
      }
    />
  );
}

export default FindIdPasswordModalTemplate;
