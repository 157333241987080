import { COLOR } from '@components/atoms';
import styled from 'styled-components';

export const DefaultListContent = styled.div<{
  flex: number;
  flexDirection: 'row' | 'column';
  border?: string;
  isPointer?: boolean;
}>`
  height: 56px;
  flex: ${({ flex }) => flex};
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  flex-direction: ${({ flexDirection }) => flexDirection};
  padding: 8px 16px;

  word-break: break-all;

  border-bottom: ${({ border }) =>
    border === 'none' ? 'none' : `1px solid ${COLOR['border/divider']}`};

  cursor: ${({ isPointer }) => (isPointer ? 'pointer' : 'default')};
`;
