import { notice } from '@assets/index';
import styled from 'styled-components';

function AuthError() {
  return (
    <Container>
      <Img src={notice} />
      <Title>{'이 접수 요청은\n더 이상 유효하지 않습니다.'}</Title>
      <Description>
        {'만료되었거나 수락된 접수 요청은\n접근할 수 없어요.'}
      </Description>
    </Container>
  );
}

export default AuthError;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  max-width: 720px;
  height: 100vh;
  height: 100dvh;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 8px;
`;

const Img = styled.img`
  width: 88px;
  height: 88px;
`;

const Title = styled.div`
  color: #474a52;
  text-align: center;
  font-family: Pretendard, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  white-space: pre-line;
`;

const Description = styled.div`
  color: #9fa1a8;
  text-align: center;

  font-family: Pretendard, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  white-space: pre-line;
`;
