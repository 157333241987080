import { COLOR } from '@components/atoms';
import styled from 'styled-components';

export const ModalBodyArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const TitleArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${COLOR['border/divider']};
`;

export const SubTitleArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const PaymentPriceExpectArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const PaymentPriceExpectMedicineListArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const PaymentPriceExpectMedicineArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 14px 16px;
  background-color: ${COLOR['fill/light']};
  border-radius: 8px;
`;

export const PaymentPriceExpectMedicineHeaderArea = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const NotFilledNoticeArea = styled.div`
  padding: 14px 16px;
  background-color: ${COLOR['fill/light']};
  border-radius: 8px;
`;
