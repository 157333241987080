import { BoxContainer, COLOR } from '@components/atoms';
import styled from 'styled-components';

export const TabContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
`;

export const TabArea = styled.div<{ isActive: boolean }>`
  display: flex;
  padding: 0.9375rem 0.5rem;
  align-items: center;
  border-bottom: ${({ isActive }) =>
    isActive ? `2px solid ${COLOR['fill/black']}` : `2px solid transparent`};
  text-align: center;
  cursor: pointer;
  &:hover {
    color: ${COLOR['fill/black']};
    border-bottom: ${({ isActive }) =>
      isActive
        ? `2px solid ${COLOR['fill/black']}`
        : `2px solid ${COLOR['fill/medium']}`};
    span {
      color: ${COLOR['fill/black']};
    }
  }
`;

export const RegisterMedicineCount = styled.div<{
  isSelected: boolean;
  isEmpty: boolean;
}>`
  padding: 2px 4px;
  border-radius: 4px;
  background-color: ${({ isSelected, isEmpty }) =>
    isEmpty
      ? COLOR.greyscale2
      : isSelected
      ? COLOR['fill/accent']
      : COLOR['label/lightBlue']};
`;

export const TabLabelText = styled.span<{ isClickedMenu: boolean }>`
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.01em;
  white-space: nowrap;
  color: ${({ isClickedMenu }) =>
    isClickedMenu ? COLOR['fill/black'] : COLOR.greyscale4};
`;

export const TabContentArea = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PageText = styled.div`
  margin-bottom: 20px;
`;

export const PageTitleArea = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
`;

export const PageTitleItem = styled.div`
  margin-right: 8px;
  &:last-child {
    cursor: pointer;
    margin-right: 0;
  }
`;

export const PageTitleIcon = styled.img`
  width: 32px;
  height: 32px;
`;

export const ListArea = styled(BoxContainer)`
  width: 1024px;
  padding: 30px;
  &.smallListArea {
    width: 902px;
  }
`;

export const HeaderArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const TitleArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const WarningArea = styled.div`
  display: flex;
  gap: 4px;
`;

export const OptionArea = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  align-items: center;
  justify-content: space-between;
`;

export const OptionLeftArea = styled.div``;

export const OptionRightArea = styled.div`
  position: relative;
`;

export const OptionRightAreaDropdownList = styled.div`
  width: 140px;
  height: 120px;
  background-color: ${COLOR['fill/white']};
  border-radius: 8px;
  filter: drop-shadow(0px 1px 20px rgba(0, 0, 0, 0.25));
  position: absolute;
  top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 999;
`;

export const OptionRightAreaDropdownItem = styled.div`
  width: 100%;
  cursor: pointer;
  color: ${COLOR['fill/dark']};
  &:hover {
    color: ${COLOR['fill/black']};
  }
  text-align: center;
`;

export const ListContainer = styled.div``;

export const ListDefaultItem = styled.div`
  display: flex;
  flex-direction: row;
  height: 56px;
  width: 100%;
`;

export const ListHeaderItem = styled(ListDefaultItem)`
  background-color: ${COLOR['fill/dark']};

  text-align: center;
  white-space: break-spaces;
`;

export const IconButtonItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  justify-content: space-between;
`;

export const LoaderArea = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 20px 0;
`;

export const EmptyContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 0.75rem;
  padding: 3.25rem 1.875rem;
`;

export const TooltipMessageArea = styled.div``;

export const TooltipMessage = styled.div``;

export const TooltipImage = styled.img`
  width: 12px;
  height: 12px;
  margin-right: 4px;
`;
