import moment from 'moment';
import { TelepharmacyListItemModelType } from 'types/myDoctorModelType/telepharmacyModelType';

export const calcAutorejectMinute = (item: TelepharmacyListItemModelType) =>
  parseInt(
    String((moment(item.auto_reject_time).unix() - moment().unix()) / 60 + 1),
    10,
  );

export const calcWaitingMinute = (item: TelepharmacyListItemModelType) =>
  parseInt(
    String((moment().unix() - moment(item.requested_time).unix()) / 60 + 1),
    10,
  );

export const formatWaitingTime = (minutes: number) => {
  if (minutes <= 60) {
    return `${minutes}분 대기`;
  }
  if (minutes < 1440) {
    const hours = Math.ceil(minutes / 60);
    return `${hours}시간 이상 대기`;
  }
  const days = Math.ceil(minutes / 1440);
  return `${days}일 이상 대기`;
};
