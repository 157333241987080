import { Blank, Button, COLOR, Font } from '@components/atoms';
import SvgIcon from '@components/atoms/images';
import printJS from 'print-js';
import { TelepharmacyType } from 'types/index';
import * as Style from './index.style';

function PrescriptionItemHeader({
  item,
  numPages,
}: {
  item: TelepharmacyType.TelepharmacyItemType;
  numPages: number | null;
}) {
  const isMultiplePrescription = numPages && numPages > 1;

  return (
    <Style.ContentHeaderItem>
      <Style.ContentHeaderArea>
        <Style.ContentHeaderTitle>
          <Font fontType="display2" color="fill/black">
            처방전
            {isMultiplePrescription ? ` (총 ${numPages}장)` : ''}
          </Font>
        </Style.ContentHeaderTitle>
        <Style.ContentHeaderButton>
          <Button
            onClick={() => {
              window.open(item.tm?.prescriptions[0].image, '_blank');
            }}
            padding="9px 16px 9px 12px"
            borderColor={COLOR['border/outline']}
            borderRadius="8px"
            backgroundColor="fill/white"
            title={
              <Style.ButtonContainer>
                <SvgIcon
                  icon="receiptIcon"
                  width={16}
                  height={16}
                  color="fill/dark"
                />
                <Blank appoint="Horizontal" size={8} />
                <Font fontType="body2" color="fill/dark">
                  처방전 원본
                </Font>
              </Style.ButtonContainer>
            }
          />
          <Blank appoint="Horizontal" size={12} />
          <Button
            onClick={() =>
              printJS({
                printable: item.tm?.prescriptions[0].image,
                type: 'pdf',
                showModal: true,
              })
            }
            padding="9px 16px 9px 12px"
            borderColor={COLOR['border/outline']}
            borderRadius="8px"
            backgroundColor="fill/white"
            title={
              <Style.ButtonContainer>
                <SvgIcon
                  icon="faxIcon"
                  color="fill/dark"
                  width={16}
                  height={16}
                />
                <Blank appoint="Horizontal" size={8} />
                <Font fontType="body2" color="fill/dark">
                  인쇄
                </Font>
              </Style.ButtonContainer>
            }
          />
        </Style.ContentHeaderButton>
      </Style.ContentHeaderArea>
    </Style.ContentHeaderItem>
  );
}

export default PrescriptionItemHeader;
