import { Font } from '@components/atoms';
import { commonCode } from '@constants';
import { StatusTagStyleType } from './index.d';
import { StatusTagContainer } from './index.style';

const { COMMON_CODE } = commonCode;

function StatusTag({ status }: { status?: string }) {
  const data: StatusTagStyleType = {
    title: '',
    borderColor: 'state/success',
    backgroundColor: 'state/success',
    titleColor: 'fill/white',
  };
  switch (status) {
    case COMMON_CODE.TP_STATUS.ACCEPTED:
      data.title = '결제대기';
      data.backgroundColor = 'fill/white';
      data.borderColor = 'fill/white';
      data.titleColor = 'fill/accent';
      break;
    default:
      data.title = '결제대기';
      data.backgroundColor = 'fill/white';
      data.borderColor = 'fill/white';
      data.titleColor = 'fill/accent';
      break;
  }

  return (
    <StatusTagContainer data={data}>
      <Font fontType="h5" color={data.titleColor}>
        {data.title}
      </Font>
    </StatusTagContainer>
  );
}

export default StatusTag;
