import { ColorType } from '@components/atoms/color';
import SvgIcon from '@components/atoms/images';
import { ReactNode } from 'react';
import { DescriptionContainer, TextArea } from './index.style';

interface CallOutMessageProps {
  iconColor?: ColorType;
  borderColor?: string;
  backgroundColor?: string;
  children?: ReactNode;
}

function CallOutMessage({
  iconColor = 'state/warning',
  borderColor = undefined,
  backgroundColor = undefined,
  children,
}: CallOutMessageProps) {
  return (
    <DescriptionContainer
      borderColor={borderColor}
      backgroundColor={backgroundColor}
    >
      <SvgIcon color={iconColor} icon="exclamation" width={16} />
      <TextArea>{children}</TextArea>
    </DescriptionContainer>
  );
}

export default CallOutMessage;
