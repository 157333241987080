import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '@stores';
import { GlobalNavBarType } from './index.d';

const initialState: GlobalNavBarType = {
  isOpen: false,
};

export const globalNavBar = createSlice({
  name: 'globalNavBar',
  initialState,
  reducers: {
    open_globalNavBar: (state) => {
      state.isOpen = true;
    },
    close_globalNavBar: (state) => {
      state.isOpen = false;
    },
  },
});

export const { open_globalNavBar, close_globalNavBar } = globalNavBar.actions;

export const selectGlobalNavBarOpen = (state: RootState) =>
  state.globalNavBar.isOpen;

export default globalNavBar.reducer;
