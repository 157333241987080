import { BaseAPI } from '@apis/config';
import { GetPharmacyDashboardParams, PharmacyDashboard } from './index.type';

class PharmacyPaymentApi extends BaseAPI {
  async getDashBoard(
    params: GetPharmacyDashboardParams,
  ): Promise<PharmacyDashboard> {
    return this.get({
      endPoint: `/dashboard`,
      params,
    });
  }

  async exportDashBoard(params: GetPharmacyDashboardParams): Promise<string> {
    return this.get({
      endPoint: '/dashboard/export/',
      params,
    });
  }
}

export const pharmacyPaymentApi = new PharmacyPaymentApi(
  '/v1/pharmacy',
  'payment',
);
