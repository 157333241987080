import { Button, COLOR, Font } from '@components/atoms';
import SvgIcon from '@components/atoms/images';
import { loadingSpinner } from '@components/atoms/lottie';
import Modal from '@components/molecules/modal';
import { isNil } from 'es-toolkit';
import Lottie from 'lottie-react';
import { Dispatch, SetStateAction, Suspense, useEffect, useState } from 'react';
import { GetPrescriptionQrCodeImageResponse } from './apis';
import ModalQrCodeInfo from './components/modalQrCodeInfo';
import QrCodeInfo from './components/qrCodeInfo';
import * as Style from './index.style';

interface PrescriptionQrCodeImageProps {
  prescriptionId: number | undefined;
  qrCodeList?: GetPrescriptionQrCodeImageResponse;
  isError?: boolean;
}

function PrescriptionQrCodeImage({
  prescriptionId,
  qrCodeList,
  isError,
}: PrescriptionQrCodeImageProps) {
  const [isBiggerQrCodeModalOpen, setIsBiggerQrCodeModalOpen] = useState(false);
  const [isShowBiggerQrCodeButton, setIsShowBiggerQrCodeButton] =
    useState(false);

  const handleToggleBiggerQrCodeModal = () => {
    setIsBiggerQrCodeModalOpen((prev) => !prev);
  };

  return (
    <Style.Container>
      <Style.Header>
        <Font fontType="h5" color="fill/white">
          처방전 QR코드
        </Font>

        {isShowBiggerQrCodeButton && (
          <Button
            title={
              <Font fontType="body2" color="fill/dark">
                QR 크게 보기
              </Font>
            }
            backgroundColor={COLOR['fill/white']}
            borderColor={COLOR['border/outline']}
            padding="5px 12px"
            onClick={() => {
              setIsBiggerQrCodeModalOpen(true);
            }}
          />
        )}
      </Style.Header>
      <Style.Content>
        {!isNil(prescriptionId) && (
          <Suspense
            fallback={
              <Style.LoadingContainer>
                <Lottie
                  animationData={loadingSpinner}
                  style={{ width: 48, aspectRatio: 1 }}
                />
              </Style.LoadingContainer>
            }
          >
            <PrescriptionQrCodeImageContent
              prescriptionId={prescriptionId}
              isBiggerQrCodeModalOpen={isBiggerQrCodeModalOpen}
              setIsShowBiggerQrCodeButton={setIsShowBiggerQrCodeButton}
              handleToggleBiggerQrCodeModal={handleToggleBiggerQrCodeModal}
              qrCodeList={qrCodeList}
              isError={isError}
            />
          </Suspense>
        )}
      </Style.Content>
    </Style.Container>
  );
}

interface PrescriptionQrCodeImageContentProps {
  prescriptionId: number;
  isBiggerQrCodeModalOpen: boolean;
  setIsShowBiggerQrCodeButton: Dispatch<SetStateAction<boolean>>;
  handleToggleBiggerQrCodeModal: () => void;
  qrCodeList?: GetPrescriptionQrCodeImageResponse;
  isError?: boolean;
}

function PrescriptionQrCodeImageContent({
  prescriptionId,
  isBiggerQrCodeModalOpen,
  setIsShowBiggerQrCodeButton,
  handleToggleBiggerQrCodeModal,
  isError,
  qrCodeList,
}: PrescriptionQrCodeImageContentProps) {
  useEffect(() => {
    if (isError) {
      setIsShowBiggerQrCodeButton(false);
    }
    if (qrCodeList && qrCodeList.length > 0) {
      setIsShowBiggerQrCodeButton(true);
    }
  }, [qrCodeList, isError, setIsShowBiggerQrCodeButton]);

  return (
    <>
      <Modal
        isOpen={isBiggerQrCodeModalOpen}
        customContentStyle={{
          minWidth: '420px',
          padding: '30px',
          borderRadius: '16px',
        }}
        onClose={handleToggleBiggerQrCodeModal}
      >
        <Style.BiggerQrCodeModalContainer>
          <Style.BiggerQrCodeModalTopContainer>
            <Style.BiggerQrCodeModalTitleContainer>
              <Font fontType="h1" color="fill/black">
                처방전 QR 코드
              </Font>
              <Font fontType="body1" color="fill/accent">
                {`총 ${qrCodeList?.length}개`}
              </Font>
            </Style.BiggerQrCodeModalTitleContainer>
            <div
              style={{
                width: 48,
                height: 48,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onClick={handleToggleBiggerQrCodeModal}
            >
              <SvgIcon icon="closeIcon" width={24} color="fill/black" />
            </div>
          </Style.BiggerQrCodeModalTopContainer>
          <Style.BiggerQrCodeModalContent
            isQrCodeMoreThanOne={(qrCodeList?.length || 0) > 1}
          >
            {qrCodeList?.map((qrCode) => {
              return (
                <ModalQrCodeInfo
                  key={`${qrCode.id}-${
                    qrCode.is_recognition_failure_reported ? 'true' : 'false'
                  }`}
                  {...qrCode}
                />
              );
            })}
          </Style.BiggerQrCodeModalContent>
        </Style.BiggerQrCodeModalContainer>
      </Modal>

      {qrCodeList && qrCodeList.length > 0 ? (
        qrCodeList.map((qrCode) => {
          return (
            <QrCodeInfo
              key={`${qrCode.id}-${
                qrCode.is_recognition_failure_reported ? 'true' : 'false'
              }`}
              {...qrCode}
              handleToggleBiggerQrCodeModal={handleToggleBiggerQrCodeModal}
            />
          );
        })
      ) : (
        <Style.PrescriptionErrorCase>
          <Font fontType="body2_medium" color="state/distructive">
            처방전 QR 코드를 불러오지 못했습니다.
          </Font>
          <Font fontType="body2_medium" color="state/distructive">
            아래의 처방전을 확인해주세요.
          </Font>
        </Style.PrescriptionErrorCase>
      )}
    </>
  );
}

export default PrescriptionQrCodeImage;
