import { Button, COLOR, Font } from '@components/atoms';
import SvgIcon from '@components/atoms/images';
import * as Style from './index.style';

function UpdateNudge() {
  const handleClickUpdate = () => {
    if (typeof window !== 'undefined') {
      window.location.reload();
    }
  };

  return (
    <Style.UpdateNudgeContainer>
      <div>
        <Font fontType="h3" color="fill/white">
          더 나은 서비스 제공을 위해 새로운 버전이 업데이트되었어요!
        </Font>

        <Font fontType="body2" color="fill/white">
          [업데이트 하기] 버튼을 누르면 1초 만에 업데이트가 가능해요.
        </Font>
      </div>

      <Button
        title={
          <Style.ReloadButtonArea>
            <SvgIcon icon="resetIcon" width={16} color="fill/white" />
            <Font fontType="body2_medium" color="fill/white">
              업데이트 하기
            </Font>
          </Style.ReloadButtonArea>
        }
        borderRadius="8px"
        onClick={handleClickUpdate}
        backgroundColor={COLOR['fill/accent']}
      />
    </Style.UpdateNudgeContainer>
  );
}

export default UpdateNudge;
