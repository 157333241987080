import { Mixpanel } from '@utils/mixpanel';

const newReservationAcceptedMixpanelEvents = {
  pageNewReservationAcceptedDetail: '[Page] TP Accepted Detail',
  pageTPAcceptedList: '[Page] TP Accepted List',
} as const;

interface NewReservationAcceptedMixpanelEventsMapProps {
  pageNewReservationAcceptedDetail: ({
    tppId,
    phId,
    tmId,
    deliveryMethod,
    symptomKeyword,
    tmGuideType,
  }: {
    tppId: string;
    phId: string;
    tmId: string;
    deliveryMethod: string;
    symptomKeyword: string;
    tmGuideType: string;
  }) => void;
  pageTPAcceptedList: ({
    listCount,
    pharmacyId,
  }: {
    listCount: number;
    pharmacyId: string;
  }) => void;
}

export const trackNewReservationAccepted: NewReservationAcceptedMixpanelEventsMapProps =
  {
    pageNewReservationAcceptedDetail: ({
      tppId,
      phId,
      tmId,
      deliveryMethod,
      symptomKeyword,
      tmGuideType,
    }) =>
      Mixpanel.track(
        newReservationAcceptedMixpanelEvents.pageNewReservationAcceptedDetail,
        {
          'TPP ID': tppId,
          'PH ID': phId,
          'TM ID': tmId,
          'Delivery Method':
            deliveryMethod === '방문' ? '방문수령' : deliveryMethod,
          'Symptom Keyword': symptomKeyword,
          'TM Guide Type': tmGuideType,
        },
      ),
    pageTPAcceptedList: ({ listCount, pharmacyId }) =>
      Mixpanel.track(newReservationAcceptedMixpanelEvents.pageTPAcceptedList, {
        'Number of List': listCount,
        'PH ID': pharmacyId,
      }),
  };
