import { IconDetailProps } from '@components/atoms/images';

function DrugIcon({ width, height, color }: IconDetailProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M15.7839 2.21691C14.7928 1.22992 13.451 0.675781 12.0522 0.675781C10.6534 0.675781 9.3116 1.22992 8.32044 2.21691L2.16889 8.36846C1.19828 9.36209 0.658534 10.6983 0.666718 12.0873C0.674901 13.4763 1.23036 14.8061 2.21261 15.7882C3.19486 16.7704 4.52471 17.3257 5.91371 17.3337C7.30272 17.3417 8.63888 16.8018 9.63239 15.831L15.7839 9.68041C16.7709 8.68925 17.3251 7.34744 17.3251 5.94867C17.3251 4.54989 16.7709 3.20808 15.7839 2.21691Z"
        fill="#FFCC00"
      />
      <path
        d="M15.7864 9.68295L12.7153 12.7634L5.24622 5.2943L8.31735 2.21389C9.30781 1.22343 10.6512 0.666992 12.0519 0.666992C12.7454 0.666992 13.4322 0.8036 14.073 1.06902C14.7138 1.33443 15.296 1.72346 15.7864 2.21389C16.2768 2.70431 16.6659 3.28653 16.9313 3.92731C17.1967 4.56808 17.3333 5.25485 17.3333 5.94842C17.3333 6.64199 17.1967 7.32876 16.9313 7.96954C16.6659 8.61031 16.2768 9.19253 15.7864 9.68295Z"
        fill="#FFE066"
      />
    </svg>
  );
}

export default DrugIcon;
