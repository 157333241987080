import { COLOR } from '@components/atoms';
import { SPACING_VARIABLES, Z_INDEX } from '@constants/styleVariables';
import styled from 'styled-components';

export const Container = styled.div<{
  acceptedIsActive: boolean;
  isRequestedPage: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: ${({ acceptedIsActive }) =>
    acceptedIsActive ? 'center' : 'none'};
  width: 100vw;
`;

export const ListContainer = styled.div`
  position: fixed;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: ${SPACING_VARIABLES.lnbWidth}px;
  height: calc(100% - ${SPACING_VARIABLES.headerHeight}px);
  background-color: ${COLOR['fill/white']};
  z-index: ${Z_INDEX.lnb};
`;

export const ListArea = styled.div<{ isShowAds: boolean }>`
  padding: 0;
  height: ${({ isShowAds }) => {
    const spacing = isShowAds
      ? `${SPACING_VARIABLES.headerHeight + SPACING_VARIABLES.lnbAdWidth}`
      : SPACING_VARIABLES.headerHeight;

    return `calc(100% - ${spacing}px)`;
  }};
  border-radius: 0;
  box-shadow: none;
`;

export const ListTitleArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
`;

export const ListIcon = styled.div`
  cursor: pointer;
`;

export const AlmostAutoRejectContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  padding: 8px 16px;
  background-color: ${COLOR['fill/dark']};
`;

export const ListFilterButtonArea = styled.div`
  display: flex;
  align-items: center;
  padding-right: 4px;
`;

export const TelepharmacyListArea = styled.div<{ isShowAds: boolean }>`
  overflow-y: auto;
  height: 100%;
`;

export const TelepharmacyListDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${COLOR['fill/light']};
  margin: 16px 0;
`;

export const CardArea = styled.div<{ acceptedIsActive: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: ${({ acceptedIsActive }) =>
    acceptedIsActive ? 'center' : 'none'};
  margin-left: ${({ acceptedIsActive }) =>
    acceptedIsActive ? 0 : SPACING_VARIABLES.lnbWidth}px;
  position: relative;
`;

export const TooltipMessageArea = styled.div``;

export const TooltipMessage = styled.div``;

export const TooltipImage = styled.img`
  width: 12px;
  height: 12px;
  margin-right: 4px;
`;

export const EmptyContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 16px 24px;
  padding: 14px 16px;
  background-color: ${COLOR['fill/light']};
  border-radius: 12px;
`;
