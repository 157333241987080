import { IconDetailProps } from '@components/atoms/images';
import { COLOR } from '../color';

function CautionIcon({ width, height, color }: IconDetailProps) {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="9.99999" cy="10" r="9.16667" fill={COLOR[color]} />
      <rect
        x="8.95833"
        y="12.7084"
        width="2.08333"
        height="2.08333"
        rx="1.04167"
        fill="white"
      />
      <rect
        x="9.16666"
        y="5.20837"
        width="1.66667"
        height="6.66667"
        rx="0.833333"
        fill="white"
      />
    </svg>
  );
}

export default CautionIcon;
