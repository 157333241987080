import { useAppDispatch } from '@stores/hooks';
import { firstPasswordSetting } from '@stores/userInformationStore';
import { debounce } from 'lodash';
import { useCallback } from 'react';
import {
  SubmitErrorHandler,
  SubmitHandler,
  useFormContext,
} from 'react-hook-form';
import { IResetPasswordFormData } from '../reset-password-form';

export const useResetPasswordSubmit = () => {
  const { handleSubmit } = useFormContext<IResetPasswordFormData>();
  const dispatch = useAppDispatch();

  const resetPassword = useCallback(
    async (password: string, passwordConfirm: string) => {
      dispatch(firstPasswordSetting({ password, passwordConfirm }));
    },
    [dispatch],
  );

  const onFormError: SubmitErrorHandler<IResetPasswordFormData> = useCallback(
    (errors) => {
      console.log(errors);
    },
    [],
  );

  const onFormSubmit: SubmitHandler<IResetPasswordFormData> = useCallback(
    async (data) => {
      const { PASSWORD_CONFIRM, PASSWORD } = data;

      const response = await resetPassword(PASSWORD, PASSWORD_CONFIRM);
    },
    [resetPassword],
  );

  const submit = debounce(() => {
    handleSubmit(onFormSubmit, onFormError)();
  }, 500);

  return { submit };
};
