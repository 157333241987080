import BlackClose from '@assets/svg/BlackClose';
import { useLayoutEffect, useRef } from 'react';
import styled from 'styled-components';

type Props = {
  title: string;
  goBack: () => void;
};

function AcceptanceHeader({ title, goBack }: Props) {
  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (ref.current) {
      document.documentElement.style.setProperty(
        '--acceptance-header-height',
        `${ref.current.offsetHeight}px`,
      );
    }
  }, []);

  return (
    <Container ref={ref}>
      <Text>{title}</Text>
      <BlackClose onClick={goBack} />
    </Container>
  );
}

export default AcceptanceHeader;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  height: 52px;
  padding: 0px 20px;
  gap: 20px;
  border-bottom: 1px solid #f1f2f4;
  z-index: 100;
  background-color: #ffffff;
`;

const Text = styled.div`
  flex: 1 0 0;
  color: #1e2024;
  font-family: Pretendard, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
`;
