import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '@stores';
import { CustomerCenterModalType } from './index.d';

const initialState: CustomerCenterModalType = {
  isVisible: false,
};

export const customerCenterModal = createSlice({
  name: 'modal/customerCenterModal',
  initialState,
  reducers: {
    openModal_customerCenterModal: (state) => {
      state.isVisible = true;
    },
    closeModal_customerCenterModal: (state) => {
      state.isVisible = false;
    },
  },
});

export const { openModal_customerCenterModal, closeModal_customerCenterModal } =
  customerCenterModal.actions;

export const selectCustomerCenterModalVisible = (state: RootState) =>
  state.customerCenterModal.isVisible;

export default customerCenterModal.reducer;
