import { Font } from '@components/atoms';
import { BreadcrumbPartItemType } from './index.d';
import * as Style from './index.style';

function BreadcrumbPartItem({
  pageTextGray,
  pageTextBlack,
}: BreadcrumbPartItemType) {
  return (
    <Style.PageText>
      <Font fontType="h4">{pageTextGray}</Font>
      <Font fontType="h4" color="fill/black">
        {' '}
        {pageTextBlack}
      </Font>
    </Style.PageText>
  );
}

export default BreadcrumbPartItem;
