import styled from 'styled-components';
import { COLOR } from '../color';

export const ToggleWrapper = styled.div<{ isActive: boolean }>`
  width: 48px;
  height: 24px;
  border-radius: 12px;
  background-color: ${(props) =>
    props.isActive ? COLOR.green700 : COLOR['fill/mediumLight']};
  display: flex;
  align-items: center;
  padding: 2px;
  cursor: pointer;
  transition: background-color 0.3s ease;
`;

export const ToggleCircle = styled.div<{ isActive: boolean }>`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${COLOR['fill/white']};
  transition: transform 0.3s ease;
  transform: ${(props) =>
    props.isActive ? 'translateX(24px)' : 'translateX(0)'};
`;
