import SvgIcon from '@components/atoms/images';
import { toast } from 'react-toastify';
import * as Style from './index.style';

interface NotificationToastProps {
  icon: JSX.Element;
  title: JSX.Element;
  description: JSX.Element;
  button?: JSX.Element;
  showCloseButton?: boolean;
  executeWhenCloseButtonClicked?: () => void;
}

function NotificationToastUI({
  icon,
  title,
  description,
  button,
  showCloseButton = true,
  executeWhenCloseButtonClicked,
}: NotificationToastProps) {
  return (
    <Style.NotificationToastArea>
      <Style.NotificationArea>
        {icon}
        <Style.NotificationContentArea>
          <Style.NotificationTextArea>
            {title}
            {description}
          </Style.NotificationTextArea>
          {button && button}
        </Style.NotificationContentArea>
      </Style.NotificationArea>
      {showCloseButton && (
        <Style.CloseButtonArea
          onClick={() => {
            toast.dismiss();
            if (executeWhenCloseButtonClicked) {
              executeWhenCloseButtonClicked();
            }
          }}
        >
          <SvgIcon icon="closeIcon" width={20} color="fill/medium" />
        </Style.CloseButtonArea>
      )}
    </Style.NotificationToastArea>
  );
}

export default NotificationToastUI;
