import { Font } from '@components/atoms';
import { ReactNode } from 'react';
import * as Style from './index.style';

const ListContent = {
  Element: ({
    children,
    flex,
    flexDirection = 'row',
  }: {
    children?: ReactNode;
    flex: number;
    flexDirection?: 'row' | 'column';
  }) => {
    return (
      <Style.DefaultListContent flexDirection={flexDirection} flex={flex}>
        {children}
      </Style.DefaultListContent>
    );
  },
  Regular: ({
    children,
    flex,
    flexDirection = 'row',
  }: {
    children?: ReactNode;
    flex: number;
    flexDirection?: 'row' | 'column';
  }) => {
    return (
      <Style.DefaultListContent flexDirection={flexDirection} flex={flex}>
        <Font fontType="body2">{children}</Font>
      </Style.DefaultListContent>
    );
  },
  Bold: ({
    children,
    flex,
    flexDirection = 'row',
  }: {
    children: ReactNode;
    flex: number;
    flexDirection?: 'row' | 'column';
  }) => {
    return (
      <Style.DefaultListContent flexDirection={flexDirection} flex={flex}>
        <Font fontType="h5">{children}</Font>
      </Style.DefaultListContent>
    );
  },
};

export default ListContent;
