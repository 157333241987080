import { COLOR } from '@components/atoms';
import { QUERY_KEY, queryClient } from '@constants/react-query';
import { getDefaultTimeFormatter } from '@functions/formatters/timeFormatter';
import Content from '@pages/timeManagementPage/registerOffDayModal/Content';
import Footer from '@pages/timeManagementPage/registerOffDayModal/Footer';
import Header from '@pages/timeManagementPage/registerOffDayModal/Header';
import { useDateInput } from '@pages/timeManagementPage/registerOffDayModal/useDateInput';
import { myDoctorAPI } from '@services/myDoctor';
import { useAppSelector } from '@stores/hooks';
import { selectUserInformationUserData } from '@stores/userInformationStore';
import { useMutation } from '@tanstack/react-query';
import { logOnError } from '@utils/log';

import { ChangeEvent, useCallback, useState } from 'react';
import Modal from 'react-modal';

import styled from 'styled-components';
import {
  DoctorOffScheduleItemType,
  DoctorOffScheduleListType,
} from 'types/userInformationType';

const height = 494 / 16;
const width = 480 / 16;

const modalStyle = {
  content: {
    top: `calc(50% - ${height / 2}rem)`,
    left: `calc(50% - ${width / 2}rem)`,
    right: 'auto',
    bottom: 'auto',
    backgroundColor: COLOR['fill/white'],
    borderRadius: '16px',
    width: `${width}rem`,
    padding: 0,
    margin: 0,
  },

  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    zIndex: 100,
  },
};

interface RegisterOffDayModalProps {
  offDayModalVisible: boolean;
  closeOffDayModal: () => void;
}

export default function RegisterOffDayModal({
  offDayModalVisible,
  closeOffDayModal,
}: RegisterOffDayModalProps) {
  const userData = useAppSelector(selectUserInformationUserData);

  const { mutateAsync, reset } = useMutation({
    mutationKey: [QUERY_KEY.PH_OFF_SCHEDULE],
    mutationFn: (params: Omit<DoctorOffScheduleItemType, 'id'>) => {
      return myDoctorAPI.postDoctorInfoOffSchedule(params);
    },
    onSuccess: (data) => {
      queryClient.setQueryData<DoctorOffScheduleListType | undefined>(
        [QUERY_KEY.PH_OFF_SCHEDULE],
        (scheduleList) => {
          if (scheduleList?.results) {
            scheduleList.results.push(data);
          }
          return scheduleList;
        },
      );
    },
  });

  const {
    date: startDate,
    onDateChange: onStartDateChange,
    onDateInputChange: onStartDateInputChange,
  } = useDateInput();

  const [offDayName, setOffDayName] = useState<string>('');

  const onChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setOffDayName(() => e.target.value);
  }, []);

  const onClick = useCallback(async () => {
    try {
      const response = await mutateAsync({
        doctor: `${userData?.user_id ?? ''}`,
        description: offDayName,
        the_day: getDefaultTimeFormatter(startDate?.toString()),
        start_time: '00:00:00',
        end_time: '23:59:00',
        is_enabled: true,
      });

      reset();

      closeOffDayModal();
    } catch (err) {
      logOnError('err', err as Error);
    }
  }, [
    closeOffDayModal,
    mutateAsync,
    offDayName,
    reset,
    startDate,
    userData?.user_id,
  ]);

  return (
    <Modal
      isOpen={offDayModalVisible}
      style={modalStyle}
      onRequestClose={closeOffDayModal}
      appElement={document.getElementById('root') as HTMLElement}
    >
      <MainContainer>
        <Header closeOffDayModal={closeOffDayModal} />
        <Content
          startDate={startDate}
          onStartDateChange={onStartDateChange}
          onStartDateInputChange={onStartDateInputChange}
          // endDate={endDate}
          // onEndDateChange={onEndDateChange}
          // onEndDateInputChange={onEndDateInputChange}
          onChange={onChange}
          offDayName={offDayName}
        />
        <Footer height={88 / 16} onClick={onClick} />
      </MainContainer>
    </Modal>
  );
}

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: space-between;
`;
