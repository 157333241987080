import { BColor } from '@components/meraki-ui/BColor';
import { IconDetailProps } from '@components/meraki-ui/BIcon/svg.type';

export default function CalendarIcon({
  width,
  height,
  color,
}: IconDetailProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 9C11 8.44772 11.4477 8 12 8H17V10H12C11.4477 10 11 9.55228 11 9Z"
        fill={BColor[color]}
      />
      <path
        d="M3 11H7C7.55228 11 8 11.4477 8 12C8 12.5523 7.55228 13 7 13H3V11Z"
        fill={BColor[color]}
      />
      <path
        d="M5.5 2C5.77614 2 6 2.22386 6 2.5V3H14V2.5C14 2.22386 14.2239 2 14.5 2C14.7761 2 15 2.22386 15 2.5V3H16C17.1046 3 18 3.89543 18 5V16C18 17.1046 17.1046 18 16 18H4C2.89543 18 2 17.1046 2 16V5C2 3.89543 2.89543 3 4 3H5V2.5C5 2.22386 5.22386 2 5.5 2ZM3 6V16C3 16.5523 3.44772 17 4 17H16C16.5523 17 17 16.5523 17 16V6H3Z"
        fill={BColor[color]}
      />
    </svg>
  );
}
