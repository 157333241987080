import BFlex from '@components/meraki-ui/BFlex';
import { usePersonalInfo } from '@hooks/featureHooks/usePersonalInfo';
import ReRoutingPage from '@pages/ReRoutingPage';
import parse from 'html-react-parser';
import { useMemo } from 'react';
import styled from 'styled-components';

function TermsOfService() {
  const { personalInfoReport } = usePersonalInfo();

  const content = useMemo(
    () => personalInfoReport?.data?.content,
    [personalInfoReport?.data?.content],
  );

  return (
    <>
      {content ? (
        <BFlex isColumn padding="2rem 3rem">
          <StyledTermsOfService>{parse(content)}</StyledTermsOfService>
        </BFlex>
      ) : (
        <ReRoutingPage />
      )}
    </>
  );
}

const StyledTermsOfService = styled.div`
  h2 {
    font-size: 1.5rem;
    font-weight: bold;
  }

  p {
    margin: 1rem 0;
  }

  strong {
    font-weight: bold;
  }

  ol,
  ul {
    margin: 1rem 0;
    padding-left: 2.5rem;
  }

  li {
    margin: 0.5rem 0;
  }
`;

export default TermsOfService;
