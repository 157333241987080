import { Button, COLOR, Font } from '@components/atoms';
import {
  medicineCategoryList,
  MedicineCategoryType,
} from '@components/organisms/managementMedicineTable/config/domain';
import * as Style from './index.style';

interface MedicineSearchProps {
  selectedMedicineCategory: MedicineCategoryType;
  handleClickMedicineCategory: (medicine: MedicineCategoryType) => void;
}

function MedicineCategory({
  selectedMedicineCategory,
  handleClickMedicineCategory,
}: MedicineSearchProps) {
  return (
    <Style.ModalBodyContentArea>
      <Style.SubTitleArea>
        <Font fontType="body1_medium" color="fill/black">
          분류
        </Font>
        <Font fontType="body1_medium" color="state/distructive">
          *
        </Font>
      </Style.SubTitleArea>

      <Style.MedicineCategoryButtonsArea>
        {medicineCategoryList.map((medicine) => {
          const { id, label, codes } = medicine;
          const isSelected = selectedMedicineCategory.label === label;

          return (
            <Button
              key={id}
              width="none"
              padding="9px 16px 9px 12px"
              borderRadius="8px"
              backgroundColor={
                isSelected ? COLOR['label/lightBlue'] : COLOR['fill/white']
              }
              borderColor={
                isSelected ? COLOR['fill/accent'] : COLOR['border/outline']
              }
              title={
                <Style.MedicineSelectedButtonArea>
                  <Font
                    fontType="body2"
                    color={isSelected ? 'fill/accent' : 'fill/dark'}
                  >
                    {label}
                  </Font>
                </Style.MedicineSelectedButtonArea>
              }
              onClick={() => handleClickMedicineCategory(medicine)}
            />
          );
        })}
      </Style.MedicineCategoryButtonsArea>
    </Style.ModalBodyContentArea>
  );
}

export default MedicineCategory;
