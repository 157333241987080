import { mobileParcel, mobilePickup, mobileQuick } from '@assets/index';
import { COLOR, Font } from '@components/atoms';
import SvgIcon from '@components/atoms/images';
import { COMMON_CODE } from '@constants/commonCode';
import Badge from '@mobile/components/common/Badge';
import styled from 'styled-components';

type Props = {
  deliveryMethod?: string;
  patientName?: string;
  isSubstitutedPatient: boolean | null | undefined;
  symptom?: string;
  reservationCode?: string;
};

function PatientInfo({
  deliveryMethod = '-',
  patientName = '-',
  isSubstitutedPatient,
  symptom = '-',
  reservationCode = '-',
}: Props) {
  const renderImage = () => {
    if (deliveryMethod === COMMON_CODE.TP_METHOD.PARCEL) return mobileParcel;
    if (deliveryMethod === COMMON_CODE.TP_METHOD.QUICK) return mobileQuick;
    return mobilePickup;
  };

  return (
    <Container>
      <PatientInfoContainer>
        <Image src={renderImage()} />
        <Contents>
          <NameWrapper>
            <Text>{`${patientName} 환자`}</Text>
            <ReservationCode>{reservationCode.toUpperCase()}</ReservationCode>
          </NameWrapper>
          <BadgeArea>
            <Badge type="default" text={symptom} />
          </BadgeArea>
        </Contents>
      </PatientInfoContainer>
      <SubstitutedPatientContainer>
        <SvgIcon
          icon={isSubstitutedPatient ? 'changeIcon' : 'infoIcon'}
          width={18}
          color={isSubstitutedPatient ? 'fill/accent' : 'green700'}
        />
        <Font fontType="h5" color="fill/black">
          {isSubstitutedPatient
            ? '대체조제에 동의한 환자입니다.'
            : '처방전 그대로 조제를 희망하는 환자입니다.'}
        </Font>
      </SubstitutedPatientContainer>
    </Container>
  );
}

export default PatientInfo;

const Container = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 12px 20px 20px 20px;
  align-items: center;
  gap: 20px;
  border-bottom: 4px solid #f1f2f4;
`;

const PatientInfoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
`;

const SubstitutedPatientContainer = styled.div`
  display: flex;
  gap: 12px;
  padding: 14px 16px;
  width: 100%;
  border-radius: 8px;
  background-color: ${COLOR['fill/light']};
`;

const Image = styled.img`
  width: 56px;
  height: 56px;
`;

const Contents = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  flex: 1 0 0;
  align-self: stretch;
`;

const Text = styled.div`
  color: #1e2024;

  font-family: Pretendard, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
`;

const BadgeArea = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const NameWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const ReservationCode = styled.div`
  color: #9fa1a8;

  font-family: Pretendard, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.32px;
`;
