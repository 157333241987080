import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '@stores';
import { CancelTelepharmacyModalType } from './index.d';

const initialState: CancelTelepharmacyModalType = {
  isVisible: false,
  item: {},
};

export const cancelTelepharmacyModal = createSlice({
  name: 'modal/cancelTelepharmacyModal',
  initialState,
  reducers: {
    openModal_cancelTelepharmacyModal: (state, action) => {
      state.isVisible = true;
      state.item = action.payload.item;
    },
    closeModal_cancelTelepharmacyModal: (state) => {
      state.isVisible = false;
      state.item = {};
    },
  },
});

export const {
  openModal_cancelTelepharmacyModal,
  closeModal_cancelTelepharmacyModal,
} = cancelTelepharmacyModal.actions;

export const selectCancelTelepharmacyModalVisible = (state: RootState) =>
  state.cancelTelepharmacyModal.isVisible;
export const selectCancelTelepharmacyModalItem = (state: RootState) =>
  state.cancelTelepharmacyModal.item;

export default cancelTelepharmacyModal.reducer;
