import { ReactBootstrapIcons } from '@assets';
import {
  Blank,
  Button,
  CheckButton,
  COLOR,
  Font,
  TextInput,
} from '@components/atoms';
import { PageLoader } from '@components/molecules';
import { formatters } from '@functions';
import { myDoctorAPI } from '@services';
import { useAppDispatch } from '@stores/hooks';
import { getPharmacyWorkingTimeData } from '@stores/userInformationStore';
import { logOnError } from '@utils/log';
import { FormEvent, useEffect, useState } from 'react';
import { UserInformationType } from 'types/index.d';
import { PharmacistWorkingTimeType } from 'types/userInformationType';
import * as Style from './index.style';

const TimeType = {
  WORKING_START: 'working_start',
  WORKING_END: 'working_end',
  LUNCH_START: 'lunch_start',
  LUNCH_END: 'lunch_end',
};

type WorkingTimeListItemProps = {
  workingTimeItem: UserInformationType.PharmacistWorkingTimeType;
  pharmacyId: number;
};

function WorkingTimeListItem({
  workingTimeItem,
  pharmacyId,
}: WorkingTimeListItemProps) {
  const dispatch = useAppDispatch();
  const { timeFormatter } = formatters;
  const [isPageLoading, setIsPageLoading] = useState(false);

  const [isListClicked, setIsListClicked] = useState(false);
  const [isWorkingTimeCheckBoxClicked, setIsWorkingTimeCheckBoxClicked] =
    useState(false);
  const [workingTimeStartHour, setWorkingTimeStartHour] = useState('00');
  const [workingTimeStartMinute, setWorkingTimeStartMinute] = useState('00');
  const [workingTimeEndHour, setWorkingTimeEndHour] = useState('00');
  const [workingTimeEndMinute, setWorkingTimeEndMinute] = useState('00');
  const [
    isWorkingTimeStartMinuteDropdownClicked,
    setIsWorkingTimeStartMinuteDropdownClicked,
  ] = useState(false);
  const [
    isWorkingTimeEndMinuteDropdownClicked,
    setIsWorkingTimeEndMinuteDropdownClicked,
  ] = useState(false);

  const clickDropdownFilter = (type: string) => {
    switch (type) {
      case TimeType.WORKING_START:
        setIsWorkingTimeStartMinuteDropdownClicked(
          !isWorkingTimeStartMinuteDropdownClicked,
        );
        setIsWorkingTimeEndMinuteDropdownClicked(false);
        break;
      case TimeType.WORKING_END:
        setIsWorkingTimeStartMinuteDropdownClicked(false);
        setIsWorkingTimeEndMinuteDropdownClicked(
          !isWorkingTimeEndMinuteDropdownClicked,
        );
        break;
      case TimeType.LUNCH_START:
        setIsWorkingTimeStartMinuteDropdownClicked(false);
        setIsWorkingTimeEndMinuteDropdownClicked(false);
        break;
      case TimeType.LUNCH_END:
        setIsWorkingTimeStartMinuteDropdownClicked(false);
        setIsWorkingTimeEndMinuteDropdownClicked(false);
        break;
      default:
        break;
    }
  };

  const onChangeHour = (e: FormEvent<HTMLInputElement>, type: string) => {
    if (Number(e.currentTarget.value) > 24) return;
    if (Number.isNaN(+e.currentTarget.value)) e.currentTarget.value = '00';

    switch (type) {
      case TimeType.WORKING_START:
        setWorkingTimeStartHour(e.currentTarget.value);
        break;
      case TimeType.WORKING_END:
        setWorkingTimeEndHour(e.currentTarget.value);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setWorkingTimeStartHour(
      workingTimeItem.start ? workingTimeItem.start.slice(0, 2) : '00',
    );
    setWorkingTimeStartMinute(
      workingTimeItem.start ? workingTimeItem.start.slice(3, 5) : '00',
    );
    setWorkingTimeEndHour(
      workingTimeItem.end ? workingTimeItem.end.slice(0, 2) : '00',
    );
    setWorkingTimeEndMinute(
      workingTimeItem.end ? workingTimeItem.end.slice(3, 5) : '00',
    );

    if (workingTimeItem.off) setIsWorkingTimeCheckBoxClicked(true);

    return () => {
      setWorkingTimeStartHour('00');
      setWorkingTimeStartMinute('00');
      setWorkingTimeEndHour('00');
      setWorkingTimeEndMinute('00');
      setIsWorkingTimeCheckBoxClicked(false);
    };
  }, [workingTimeItem]);

  const calculateAbsoluteTime = (hour: string, minute: string) => {
    return Number(hour) * 60 + Number(minute);
  };

  const validateFormatOfHour = (hour: string) => {
    if (hour.length === 0) return '00';
    if (hour.length === 1) return `0${hour}`;
    return hour;
  };

  const finishChangeWorkingTime = async () => {
    const absoluteWorkingStartTime = calculateAbsoluteTime(
      workingTimeStartHour,
      workingTimeStartMinute,
    );
    const absoluteWorkingEndTime = calculateAbsoluteTime(
      workingTimeEndHour,
      workingTimeEndMinute,
    );

    if (absoluteWorkingStartTime >= absoluteWorkingEndTime) {
      alert('영업 시작시간이 종료시간보다 이른 시간 수 없습니다.');
      return;
    }
    const changedWorkingTimeData = {
      start: `${validateFormatOfHour(
        workingTimeStartHour,
      )}:${workingTimeStartMinute}:00`,
      end: `${validateFormatOfHour(
        workingTimeEndHour,
      )}:${workingTimeEndMinute}:00`,
      off: isWorkingTimeCheckBoxClicked,
    };

    if (
      window.confirm(
        `${timeFormatter.changeWeekDayToKorean(
          workingTimeItem.week_day,
        )}요일 영업시간을 변경하시겠습니까?`,
      )
    ) {
      try {
        setIsPageLoading(true);
        await myDoctorAPI.changePharmacyWorkingTime(workingTimeItem.id, {
          week_day: workingTimeItem.week_day,
          ...changedWorkingTimeData,
        } as PharmacistWorkingTimeType);

        alert('영업시간이 변경되었습니다.');

        dispatch(getPharmacyWorkingTimeData(pharmacyId));
      } catch (err) {
        alert('영업시간 변경에 실패했습니다. 다시 시도해주세요.');
        logOnError('err', err as Error);
      } finally {
        setIsPageLoading(false);
        setIsListClicked(false);
        setIsWorkingTimeCheckBoxClicked(false);
      }
    }
  };

  return (
    <>
      {isPageLoading && <PageLoader />}
      <Style.WorkingTimeListArea>
        <Style.WorkingTimeTitleArea>
          <Style.WorkingTimeTitleIconArea>
            <ReactBootstrapIcons.Stopwatch
              color={COLOR['fill/dark']}
              size={14}
            />
          </Style.WorkingTimeTitleIconArea>
          <Style.WorkingTimeTitleTextArea>
            <Font fontType="body2_medium">
              {timeFormatter.changeWeekDayToKorean(workingTimeItem.week_day)}
              요일
            </Font>
          </Style.WorkingTimeTitleTextArea>
        </Style.WorkingTimeTitleArea>
        <Blank appoint="Horizontal" size={80} />
        <Style.WorkingTimeDescriptionArea>
          {!isListClicked ? (
            <Style.WorkingTimeDescriptionTextArea>
              {workingTimeItem.off ? (
                <Style.WorkingTimeDescriptionText>
                  <Font fontType="body2" color="state/distructive">
                    휴무
                  </Font>
                </Style.WorkingTimeDescriptionText>
              ) : (
                <>
                  <Style.WorkingTimeDescriptionText>
                    <Font fontType="body2_medium" color="fill/black">
                      영업시간:{' '}
                      {timeFormatter.getTimeHHmm(workingTimeItem.start)} ~{' '}
                      {timeFormatter.getTimeHHmm(workingTimeItem.end)}
                    </Font>
                  </Style.WorkingTimeDescriptionText>
                </>
              )}
            </Style.WorkingTimeDescriptionTextArea>
          ) : (
            <Style.WorkingTimeChangeArea>
              <Style.WorkingTimeChangeHeaderArea>
                <Style.WorkingTimeChangeHeaderTitleArea>
                  <Font fontType="body2" color="fill/black">
                    영업시간
                  </Font>
                </Style.WorkingTimeChangeHeaderTitleArea>
                <Style.WorkingTimeChangeHeaderCheckboxArea>
                  <CheckButton
                    title="휴무"
                    isSelected={isWorkingTimeCheckBoxClicked}
                    onClick={() =>
                      setIsWorkingTimeCheckBoxClicked((current) => !current)
                    }
                  />
                </Style.WorkingTimeChangeHeaderCheckboxArea>
              </Style.WorkingTimeChangeHeaderArea>
              <Style.WorkingTimeChangeBodyArea>
                <Style.WorkingTimeChangeBodyTextInputArea>
                  <TextInput
                    value={workingTimeStartHour}
                    type="text"
                    onChange={(e) => onChangeHour(e, TimeType.WORKING_START)}
                    height={38}
                    placeholder="00"
                    width={100}
                    fontSize={14}
                    disabled={isWorkingTimeCheckBoxClicked}
                  />
                </Style.WorkingTimeChangeBodyTextInputArea>
                <Style.WorkingTimeChangeBodyTextArea>
                  <Font fontType="body2_medium" color="fill/black">
                    :
                  </Font>
                </Style.WorkingTimeChangeBodyTextArea>
                <Style.WorkingTimeChangeBodyDropdownArea>
                  <Button
                    width="100px"
                    height="38px"
                    borderColor={COLOR['border/outline']}
                    backgroundColor={COLOR['fill/white']}
                    title={
                      <Style.IconButtonItem>
                        <Font fontType="body2_medium" color="fill/black">
                          {workingTimeStartMinute}
                        </Font>
                        <Blank appoint="Horizontal" size={8} />
                        {isWorkingTimeStartMinuteDropdownClicked ? (
                          <ReactBootstrapIcons.ChevronUp
                            size={14}
                            color={COLOR['fill/black']}
                          />
                        ) : (
                          <ReactBootstrapIcons.ChevronDown
                            size={14}
                            color={COLOR['fill/black']}
                          />
                        )}
                      </Style.IconButtonItem>
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      clickDropdownFilter(TimeType.WORKING_START);
                    }}
                    onBlur={() => {
                      setIsWorkingTimeStartMinuteDropdownClicked(false);
                    }}
                    disabled={isWorkingTimeCheckBoxClicked}
                  />
                  {isWorkingTimeStartMinuteDropdownClicked ? (
                    <Style.WorkingTimeChangeBodyDropdownList
                      onMouseDown={(event) => event.preventDefault()}
                    >
                      <Style.WorkingTimeChangeBodyDropdownItem
                        onClick={() => {
                          setWorkingTimeStartMinute('00');
                          clickDropdownFilter(TimeType.WORKING_START);
                        }}
                      >
                        00
                      </Style.WorkingTimeChangeBodyDropdownItem>
                      <Blank appoint="Vertical" size={16} />
                      <Style.WorkingTimeChangeBodyDropdownItem
                        onClick={() => {
                          setWorkingTimeStartMinute('20');
                          clickDropdownFilter(TimeType.WORKING_START);
                        }}
                      >
                        20
                      </Style.WorkingTimeChangeBodyDropdownItem>
                      <Blank appoint="Vertical" size={16} />
                      <Style.WorkingTimeChangeBodyDropdownItem
                        onClick={() => {
                          setWorkingTimeStartMinute('40');
                          clickDropdownFilter(TimeType.WORKING_START);
                        }}
                      >
                        40
                      </Style.WorkingTimeChangeBodyDropdownItem>
                    </Style.WorkingTimeChangeBodyDropdownList>
                  ) : null}
                </Style.WorkingTimeChangeBodyDropdownArea>
                <Style.WorkingTimeChangeBodyTextArea>
                  <Font fontType="body2_medium" color="fill/black">
                    부터
                  </Font>
                </Style.WorkingTimeChangeBodyTextArea>
              </Style.WorkingTimeChangeBodyArea>
              <Blank appoint="Vertical" size={8} />
              <Style.WorkingTimeChangeBodyArea>
                <Style.WorkingTimeChangeBodyTextInputArea>
                  <TextInput
                    value={workingTimeEndHour}
                    type="text"
                    onChange={(e) => onChangeHour(e, TimeType.WORKING_END)}
                    height={38}
                    placeholder="00"
                    width={100}
                    disabled={isWorkingTimeCheckBoxClicked}
                    fontSize={14}
                  />
                </Style.WorkingTimeChangeBodyTextInputArea>
                <Style.WorkingTimeChangeBodyTextArea>
                  <Font fontType="body2_medium" color="fill/black">
                    :
                  </Font>
                </Style.WorkingTimeChangeBodyTextArea>
                <Style.WorkingTimeChangeBodyDropdownArea>
                  <Button
                    width="100px"
                    height="38px"
                    borderColor={COLOR['border/outline']}
                    backgroundColor={COLOR['fill/white']}
                    disabled={isWorkingTimeCheckBoxClicked}
                    title={
                      <Style.IconButtonItem>
                        <Font fontType="body2_medium" color="fill/black">
                          {workingTimeEndMinute}
                        </Font>
                        <Blank appoint="Horizontal" size={8} />
                        {isWorkingTimeEndMinuteDropdownClicked ? (
                          <ReactBootstrapIcons.ChevronUp
                            size={14}
                            color={COLOR['fill/black']}
                          />
                        ) : (
                          <ReactBootstrapIcons.ChevronDown
                            size={14}
                            color={COLOR['fill/black']}
                          />
                        )}
                      </Style.IconButtonItem>
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      clickDropdownFilter(TimeType.WORKING_END);
                    }}
                    onBlur={() => {
                      setIsWorkingTimeEndMinuteDropdownClicked(false);
                    }}
                  />
                  {isWorkingTimeEndMinuteDropdownClicked ? (
                    <Style.WorkingTimeChangeBodyDropdownList
                      onMouseDown={(event) => event.preventDefault()}
                    >
                      <Style.WorkingTimeChangeBodyDropdownItem
                        onClick={() => {
                          setWorkingTimeEndMinute('00');
                          clickDropdownFilter(TimeType.WORKING_END);
                        }}
                      >
                        00
                      </Style.WorkingTimeChangeBodyDropdownItem>
                      <Blank appoint="Vertical" size={16} />
                      <Style.WorkingTimeChangeBodyDropdownItem
                        onClick={() => {
                          setWorkingTimeEndMinute('20');
                          clickDropdownFilter(TimeType.WORKING_END);
                        }}
                      >
                        20
                      </Style.WorkingTimeChangeBodyDropdownItem>
                      <Blank appoint="Vertical" size={16} />
                      <Style.WorkingTimeChangeBodyDropdownItem
                        onClick={() => {
                          setWorkingTimeEndMinute('40');
                          clickDropdownFilter(TimeType.WORKING_END);
                        }}
                      >
                        40
                      </Style.WorkingTimeChangeBodyDropdownItem>
                    </Style.WorkingTimeChangeBodyDropdownList>
                  ) : null}
                </Style.WorkingTimeChangeBodyDropdownArea>
                <Style.WorkingTimeChangeBodyTextArea>
                  <Font fontType="body2_medium" color="fill/black">
                    까지
                  </Font>
                </Style.WorkingTimeChangeBodyTextArea>
              </Style.WorkingTimeChangeBodyArea>
              <Blank appoint="Vertical" size={16} />
              <Style.WorkingTimeChangeFooterArea>
                <Style.WorkingTimeChangeFooterButtonArea>
                  <Button
                    width="72px"
                    height="32px"
                    backgroundColor={COLOR['fill/accent']}
                    title={
                      <Font fontType="body3" color="fill/white">
                        변경완료
                      </Font>
                    }
                    onClick={finishChangeWorkingTime}
                  />
                </Style.WorkingTimeChangeFooterButtonArea>
              </Style.WorkingTimeChangeFooterArea>
            </Style.WorkingTimeChangeArea>
          )}
        </Style.WorkingTimeDescriptionArea>
        <Blank appoint="Horizontal" size={20} />
        {!isListClicked ? (
          <Style.WorkingTimeButtonArea>
            <Button
              width="32px"
              height="20px"
              backgroundColor={COLOR['fill/light']}
              title={<Font fontType="body3">변경</Font>}
              onClick={() => setIsListClicked(true)}
            />
          </Style.WorkingTimeButtonArea>
        ) : null}
      </Style.WorkingTimeListArea>
    </>
  );
}

export default WorkingTimeListItem;
