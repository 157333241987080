import { IconDetailProps } from '@components/atoms/images';
import { COLOR } from '../color';

function DotIcon({ width, height, color }: IconDetailProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 5 4"
      fill="none"
    >
      <circle cx="2.5" cy="2" r="2" fill={COLOR[color]} />
    </svg>
  );
}

export default DotIcon;
