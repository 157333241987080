import Font from '../font';
import * as Style from './index.style';

interface ToggleTabProps {
  isActive: boolean;
  onClickToggle: () => void;
}

function ToggleTab({ isActive, onClickToggle }: ToggleTabProps) {
  return (
    <Style.ToggleWrapper onClick={onClickToggle}>
      <Style.ToggleCircle isActive={isActive} />
      <Style.FontArea>
        <Font
          fontType={isActive ? 'body1_medium' : 'h4'}
          color={isActive ? 'fill/medium' : 'fill/dark'}
        >
          OFF
        </Font>
      </Style.FontArea>
      <Style.FontArea>
        <Font
          fontType={isActive ? 'h4' : 'body1_medium'}
          color={isActive ? 'fill/accent' : 'fill/medium'}
        >
          ON
        </Font>
      </Style.FontArea>
    </Style.ToggleWrapper>
  );
}

export default ToggleTab;
