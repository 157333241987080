import { ReactNode } from 'react';
import useGlobalNavBarHook from './hooks';
import GlobalNavBarTemplate from './templates';
import { GNBLayout, GNBMain } from './templates/index.style';

function GlobalNavBar({ children }: { children: ReactNode }) {
  const {
    windowWidth,
    requestedCount,
    acceptedCount,
    preConfirmedCount,
    confirmedCount,
    finishedQuickCount,
    finishedParcelCount,
    isOpen,
    openGNB,
    closeGNB,
    handleGNB,
    userData,
    pharmacistData,
    pathname,
    openCustomerCenterModal,
    openCompanyInfoModal,
    onLogout,
    openGetReadyModal,
    isConfirmedLinkButtonClicked,
    onConfirmedLinkButtonClicked,
    isDescriptionDropdownClicked,
    onDescriptionDropdownClicked,
    navigateToPage,
    completedParcelCount,
    finishedPickupCount,
  } = useGlobalNavBarHook();

  return (
    <GNBLayout>
      <GlobalNavBarTemplate
        windowWidth={windowWidth}
        requestedCount={requestedCount}
        acceptedCount={acceptedCount}
        preConfirmedCount={preConfirmedCount}
        confirmedCount={confirmedCount}
        finishedQuickCount={finishedQuickCount}
        finishedParcelCount={finishedParcelCount}
        isOpen={isOpen}
        openGNB={openGNB}
        closeGNB={closeGNB}
        handleGNB={handleGNB}
        userData={userData}
        pharmacistData={pharmacistData}
        pathname={pathname}
        openCustomerCenterModal={openCustomerCenterModal}
        openCompanyInfoModal={openCompanyInfoModal}
        onLogout={onLogout}
        openGetReadyModal={openGetReadyModal}
        isConfirmedLinkButtonClicked={isConfirmedLinkButtonClicked}
        onConfirmedLinkButtonClicked={onConfirmedLinkButtonClicked}
        isDescriptionDropdownClicked={isDescriptionDropdownClicked}
        onDescriptionDropdownClicked={onDescriptionDropdownClicked}
        navigateToPage={navigateToPage}
        completedParcelCount={completedParcelCount}
        finishedPickupCount={finishedPickupCount}
      />
      <GNBMain isOpen={isOpen}>{children}</GNBMain>
    </GNBLayout>
  );
}

export default GlobalNavBar;
