import {
  getDotTimeFormatter,
  getSubInjectTimeFormatter,
} from '@functions/formatters/timeFormatter';
import React, { ChangeEvent, forwardRef, useCallback, useState } from 'react';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';
import { BColor } from '../BColor';
import BFlex from '../BFlex';
import BIcon from '../BIcon';

interface BDatePickerInputProps {
  value: string;
  onChange: (value: string) => void;
  onClick: () => void;
  height?: number | string;
  width?: number | string;
  fontSize?: number;
  fontWeight?: 400 | 500 | 600 | 700 | 800;
  padding?: string;
  textColor?: string;
  placeholder?: string;

  exceptDay?: boolean;
}

const BDatePickerInput = forwardRef<HTMLInputElement, BDatePickerInputProps>(
  (
    {
      value,
      onChange,
      onClick,
      padding,
      height,
      placeholder,
      fontWeight,
      width,
      textColor,
      fontSize,
      exceptDay,
    },
    ref,
  ) => {
    const onDatePickerChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        onChange(e.target.value);
      },
      [onChange],
    );

    const [isFocused, setIsFocused] = useState<boolean>(false);

    const onFocus = useCallback(() => {
      setIsFocused(true);
    }, []);

    const onBlur = useCallback(() => {
      setIsFocused(false);
    }, []);

    return (
      <BFlex>
        <InputContainer
          height={height}
          width={width}
          fontSize={fontSize}
          textColor={textColor}
          fontWeight={fontWeight}
          padding={padding}
          placeholder={placeholder}
          ref={ref}
          type="text"
          onFocus={onFocus}
          onBlur={onBlur}
          isFocused={isFocused}
          value={
            exceptDay
              ? getDotTimeFormatter(value)
              : getSubInjectTimeFormatter(value)
          }
          onClick={onClick}
          onChange={onDatePickerChange}
        />
        <IconContainer>
          <BIcon icon="calendar" color="mono08" />
        </IconContainer>
      </BFlex>
    );
  },
);

const InputContainer = styled.input<
  Omit<BDatePickerInputProps, 'value' | 'onChange' | 'iconWidth'> & {
    isFocused: boolean;
  }
>`
  display: flex;
  position: relative;
  flex: 1;
  //min-width: 100%;

  background-color: ${BColor.mono01};
  height: ${(props) => props.height ?? 44}px;
  font-size: ${(props) => props.fontSize ?? 14}px;
  font-weight: ${(props) => props.fontWeight ?? 400};
  border-radius: 8px;
  border: 1px solid ${BColor.mono08};
  align-items: center;
  padding: ${(props) => props.padding ?? '0 12px'};
  color: ${BColor.mono08};

  &::placeholder {
    color: ${BColor.mono08};
  }
`;

const IconContainer = styled.div`
  display: flex;
  position: absolute;
  align-items: center;
  top: 11px;
  right: 12px;
`;

export default BDatePickerInput;
