import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@stores';
import { VersionInfoType } from './index.d';

const initialState: VersionInfoType = {
  isVersionUp: false,
  version: '',
};

export const versionInfo = createSlice({
  name: 'versionInfo',
  initialState,
  reducers: {
    set_versionInfo: (state, action: PayloadAction<VersionInfoType>) => {
      state.isVersionUp = action.payload.isVersionUp;
      state.version = action.payload.version;
    },
  },
});

export const { set_versionInfo } = versionInfo.actions;

export const selectIsVersionUp = (state: RootState) =>
  state.versionInfo.isVersionUp;
export const selectVersion = (state: RootState) => state.versionInfo.version;

export default versionInfo.reducer;
