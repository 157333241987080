import { Font } from '@components/atoms';
import { EFormType } from '@pages/loginPage';
import { memo } from 'react';
import { FormProvider } from 'react-hook-form';

import { useResetPasswordForm } from './hooks';
import { ResetPasswordCtaView, ResetPasswordFormView } from './views';

type IResetPasswordContainerProps = {
  updateFormType: (type: EFormType) => void;
};

export const ResetPasswordContainer = memo<IResetPasswordContainerProps>(
  ({ updateFormType }) => {
    const { methods } = useResetPasswordForm();

    return (
      // @ts-ignore
      <FormProvider {...methods}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: 40,
            backgroundColor: 'white',
            borderTopRightRadius: 16,
            borderBottomRightRadius: 16,
            width: 480,
          }}
        >
          <div style={{ flex: 1 }}>
            <div
              style={{
                paddingBottom: 30,
                gap: 8,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Font fontType="display2">비밀번호 설정</Font>
              <Font fontType="body1_medium" color="fill/medium">
                {
                  '영문/숫자/특수문자를 모두 포함하여\n최소 8자 이상의 비밀번호를 설정해주세요.'
                }
              </Font>
            </div>
            <ResetPasswordFormView />
          </div>
          <ResetPasswordCtaView />
        </div>
      </FormProvider>
    );
  },
);
