import { Font } from '@components/atoms';
import {
  MedicineCompoundingFeeConfigValueType,
  medicineGenericCodeKoreanUnit,
} from '@components/organisms/managementMedicineTable/config/domain';
import { priceFormatter } from '@functions/formatters';
import { ManagedMedicineItemType } from '@services/myDoctor/types';
import { isNil } from 'lodash';
import * as Style from './index.style';

interface PaymentPriceExpectProps {
  isShowPaymentPriceExpectArea: boolean | null;
  hasCompoundingFee: boolean | null;
  medicineUnitPrice: string;
  selectedMedicine: ManagedMedicineItemType | null;
  medicineCompoundingFees: MedicineCompoundingFeeConfigValueType[] | null;
}

function PaymentPriceExpect({
  isShowPaymentPriceExpectArea,
  hasCompoundingFee,
  medicineUnitPrice,
  selectedMedicine,
  medicineCompoundingFees,
}: PaymentPriceExpectProps) {
  return (
    <Style.ModalBodyArea>
      <Style.TitleArea>
        <Font fontType="h2" color="fill/black">
          예상 결제 금액
        </Font>

        <Font fontType="body2" color="fill/medium">
          나만의닥터 앱에서 환자에게 안내되는 금액과 자동수락되는 금액은 아래와
          같이 계산돼요.
        </Font>
      </Style.TitleArea>

      <Style.Divider />

      {isShowPaymentPriceExpectArea ? (
        <Style.PaymentPriceExpectArea>
          <Style.SubTitleArea>
            <Font fontType="h3" color="fill/black">
              {selectedMedicine?.korean_product_name || ''}
            </Font>
            <Font fontType="body2" color="fill/medium">
              {selectedMedicine?.master_code || ''}
            </Font>
          </Style.SubTitleArea>

          {medicineCompoundingFees && (
            <Style.PaymentPriceExpectMedicineListArea>
              {medicineCompoundingFees.map((medicineCompoundingFee) => {
                const { quantity, price } = medicineCompoundingFee;
                const totalPrice =
                  Number(medicineUnitPrice) * 1 * 1 * quantity +
                  (hasCompoundingFee ? price || 0 : 0);

                return (
                  <Style.PaymentPriceExpectMedicineArea key={quantity}>
                    <Style.PaymentPriceExpectMedicineHeaderArea>
                      <Font fontType="body1_medium" color="fill/dark">
                        {selectedMedicine?.generic_name_code
                          ? `${quantity}${
                              medicineGenericCodeKoreanUnit[
                                selectedMedicine.generic_name_code
                              ]
                            } (1/1/${quantity})`
                          : null}
                      </Font>
                      <Font fontType="h3" color="fill/accent">
                        {`${priceFormatter.commaFormatter(
                          String(totalPrice),
                        )}원`}
                      </Font>
                    </Style.PaymentPriceExpectMedicineHeaderArea>
                    <Font fontType="body3" color="fill/medium">
                      {`산출 기준 : 총 약가(${priceFormatter.commaFormatter(
                        medicineUnitPrice,
                      )} * 1 * 1 * ${quantity}) ${
                        !isNil(price) && hasCompoundingFee
                          ? `+ 조제료(${priceFormatter.commaFormatter(
                              String(price),
                            )})`
                          : ''
                      }`}
                    </Font>
                  </Style.PaymentPriceExpectMedicineArea>
                );
              })}
            </Style.PaymentPriceExpectMedicineListArea>
          )}
        </Style.PaymentPriceExpectArea>
      ) : (
        <Style.NotFilledNoticeArea>
          <Font fontType="body2" color="fill/medium" center>
            모든 필수 정보 입력 시
          </Font>
          <Font fontType="body2" color="fill/medium" center>
            예상 결제 금액을 알려드려요
          </Font>
        </Style.NotFilledNoticeArea>
      )}
    </Style.ModalBodyArea>
  );
}

export default PaymentPriceExpect;
