import { timeFormatter } from '@functions/formatters';
import moment from 'moment';

export function formatDate(requestTime?: string) {
  let reservationTime;
  const requestedTimeDate = requestTime
    ? moment(requestTime).format('YYYY-MM-DD')
    : undefined;
  const requestedTimeDateText =
    timeFormatter.dateTextFormatter(requestedTimeDate);

  if (requestTime) {
    reservationTime = `${requestedTimeDateText} ${moment(requestTime).format(
      'HH:mm',
    )}`;
  } else {
    reservationTime = `${requestedTimeDateText} 가능한 빨리`;
  }

  return reservationTime;
}

export function formatTime(time?: string) {
  const date = new Date(String(time));

  const options: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
    timeZone: 'Asia/Seoul',
  };

  let formattedTime = date.toLocaleTimeString('ko-KR', options);

  formattedTime = formattedTime
    .replace('오전', '오전 ')
    .replace('오후', '오후 ');

  return formattedTime;
}
