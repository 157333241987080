import { COLOR } from '@components/atoms';
import styled from 'styled-components';

export const DescriptionContainer = styled.div<{
  backgroundColor: string | undefined;
  borderColor: string | undefined;
}>`
  width: 100%;
  border-radius: 8px;
  background-color: ${({ backgroundColor }) =>
    backgroundColor || COLOR['fill/light']};
  padding: 12px;
  display: flex;
  align-items: center;
  border: ${({ borderColor }) =>
    borderColor ? `1px solid ${borderColor}` : 'none'};
`;

export const TextArea = styled.div`
  margin-left: 0.75rem;
  align-items: center;
`;
