import { BColor } from '@components/meraki-ui/BColor';
import { IconDetailProps } from '@components/meraki-ui/BIcon/svg.type';

export default function ArrowRightInCircleIcon({
  width,
  height,
  color,
}: IconDetailProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM6.5 10.5C6.22386 10.5 6 10.2761 6 10C6 9.72386 6.22386 9.5 6.5 9.5L12.2929 9.5L10.1464 7.35355C9.95118 7.15829 9.95118 6.84171 10.1464 6.64645C10.3417 6.45118 10.6583 6.45118 10.8536 6.64645L13.8536 9.64645C14.0488 9.84171 14.0488 10.1583 13.8536 10.3536L10.8536 13.3536C10.6583 13.5488 10.3417 13.5488 10.1464 13.3536C9.95118 13.1583 9.95118 12.8417 10.1464 12.6464L12.2929 10.5L6.5 10.5Z"
        fill={BColor[color]}
      />
    </svg>
  );
}
