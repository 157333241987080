import { Blank, Font } from '@components/atoms';
import { progressInformationPartItemType } from './index.d';
import * as Style from './index.style';
import ProgressStatus from './progressStatus';
import ProgressText from './progressText';

function ProgressInformationPartItem({
  item,
  deliveryType,
  iconURL,
}: progressInformationPartItemType) {
  return (
    <Style.RightContentItem>
      <Style.ContentHeaderArea>
        <Style.ContentHeaderTitle>
          <Font fontType="h1" color="fill/black">
            진행상황
          </Font>
        </Style.ContentHeaderTitle>
      </Style.ContentHeaderArea>
      <Blank appoint="Vertical" size={12} />
      <Style.ContentProgressArea>
        <Style.ContentBodyArea>
          <ProgressText
            tpStatus={item.status}
            deliveryStatus={item.delivery_status}
            paymentStatus={item.payment_status}
            item={item}
          />
        </Style.ContentBodyArea>
        <Style.ContentBodyArea>
          <ProgressStatus
            tpStatus={item.status}
            deliveryStatus={item.delivery_status}
            paymentStatus={item.payment_status}
          />
        </Style.ContentBodyArea>
      </Style.ContentProgressArea>
    </Style.RightContentItem>
  );
}

export default ProgressInformationPartItem;
