import { IconDetailProps } from '@components/atoms/images';
import { COLOR } from '../color';

function TimeIcon({ width, height, color }: IconDetailProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 19.1667C15.0627 19.1667 19.1667 15.0626 19.1667 9.99999C19.1667 4.93738 15.0627 0.833328 10 0.833328C4.93743 0.833328 0.833374 4.93738 0.833374 9.99999C0.833374 15.0626 4.93743 19.1667 10 19.1667ZM10.8334 6.04166C10.8334 5.58142 10.4603 5.20833 10 5.20833C9.5398 5.20833 9.16671 5.58142 9.16671 6.04166V10C9.16671 10.221 9.25452 10.433 9.41083 10.5893L12.2099 13.388C12.5354 13.7134 13.063 13.7133 13.3884 13.3879C13.7138 13.0624 13.7138 12.5348 13.3883 12.2094L10.8334 9.65478L10.8334 6.04166Z"
        fill={COLOR[color]}
      />
    </svg>
  );
}

export default TimeIcon;
