import { BColor } from '@components/meraki-ui/BColor';
import { IconDetailProps } from '@components/meraki-ui/BIcon/svg.type';

export default function SearchIcon({ width, height, color }: IconDetailProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.8066 11.7579C13.3997 10.9505 13.75 9.95365 13.75 8.875C13.75 6.18261 11.5674 4 8.875 4C6.18261 4 4 6.18261 4 8.875C4 11.5674 6.18261 13.75 8.875 13.75C9.95394 13.75 10.951 13.3995 11.7586 12.8061L11.7579 12.8066C11.78 12.8366 11.8047 12.8653 11.8318 12.8925L14.7197 15.7803C15.0126 16.0732 15.4874 16.0732 15.7803 15.7803C16.0732 15.4874 16.0732 15.0126 15.7803 14.7197L12.8925 11.8318C12.8653 11.8047 12.8366 11.78 12.8066 11.7579ZM13 8.875C13 11.1532 11.1532 13 8.875 13C6.59683 13 4.75 11.1532 4.75 8.875C4.75 6.59683 6.59683 4.75 8.875 4.75C11.1532 4.75 13 6.59683 13 8.875Z"
        fill={BColor[color]}
      />
    </svg>
  );
}
