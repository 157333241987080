import { BColor } from '@components/meraki-ui/BColor';
import { IconDetailProps } from '@components/meraki-ui/BIcon/svg.type';

export default function ChangeIcon({ width, height, color }: IconDetailProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.50258 8.13409C3.22501 7.6558 3.38973 7.04421 3.87049 6.76807L9.96392 3.26807L10.9691 5.00012L4.87564 8.50012C4.39489 8.77626 3.78014 8.61238 3.50258 8.13409Z"
        fill={BColor[color]}
      />
      <path
        d="M10.0361 3.26807L16.1296 6.76807C16.6103 7.04421 16.775 7.6558 16.4975 8.13409C16.2199 8.61238 15.6052 8.77626 15.1244 8.50012L9.03098 5.00012L10.0361 3.26807Z"
        fill={BColor[color]}
      />
      <path
        d="M16.4974 11.8662C16.775 12.3444 16.6103 12.956 16.1295 13.2322L10.0361 16.7322L9.03093 15.0001L15.1244 11.5001C15.6051 11.224 16.2199 11.3879 16.4974 11.8662Z"
        fill={BColor[color]}
      />
      <path
        d="M9.96387 16.7322L3.87044 13.2322C3.38968 12.956 3.22496 12.3444 3.50253 11.8662C3.78009 11.3879 4.39484 11.224 4.8756 11.5001L10.969 15.0001L9.96387 16.7322Z"
        fill={BColor[color]}
      />
    </svg>
  );
}
