import { Font } from '@components/atoms';
import SvgIcon from '@components/atoms/images';
import BFlex from '@components/meraki-ui/BFlex';
import { TelepharmacyListItem } from '@components/organisms';
import { pathnameList } from '@constants';
import { SPACING_VARIABLES } from '@constants/styleVariables';
import { commonHooks } from '@hooks';
import { useInterval } from '@hooks/commonHooks';
import { useAppDispatch, useAppSelector } from '@stores/hooks';
import { openModal_noticeModal } from '@stores/modalStore/noticeModal';
import {
  getTelepharmacyCount,
  selectTelepharmacyCount_requested_expire_min,
} from '@stores/telepharmacyStore/telepharmacyCount';
import {
  getTelepharmacyList_requested,
  selectTelepharmacyCount_requested,
  selectTelepharmacyList_requested,
} from '@stores/telepharmacyStore/telepharmacyList';
import { storageHandler } from '@utils';
import { calcAutorejectMinute } from '@utils/timeCalc';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { TelepharmacyListItemModelType } from 'types/myDoctorModelType/telepharmacyModelType';
import * as Style from './index.style';
import LNBCarousel from './LNBCarousel';

const { PHARMACIST_ADMIN_PATHNAME } = pathnameList;

function NewReservationPage({ children }: { children: ReactNode }) {
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { getLocalStorageState } = storageHandler;
  const { useQuery } = commonHooks;
  const [isLoading, setIsLoading] = useState(true);
  const query = useQuery();

  const isShowAds = window.innerHeight > SPACING_VARIABLES.windowOSMinHeight;
  const isRequestedPage = pathname.includes('/requested');

  const [acceptedIsActive, setAcceptedIsActive] = useState<boolean>(false);
  const requestedTelepharmacyCount = useAppSelector(
    selectTelepharmacyCount_requested,
  );
  const requestedTelepharmacyList = useAppSelector(
    selectTelepharmacyList_requested,
  );
  const autoRejectAlertMinute = useAppSelector(
    selectTelepharmacyCount_requested_expire_min,
  );
  const [almostAutoRejectCount, setAlmostAutoRejectCount] = useState<number>(0);
  const updateAlmostAutoRejectCount = useCallback(() => {
    let count = 0;
    if (requestedTelepharmacyList) {
      requestedTelepharmacyList.forEach(
        (item: TelepharmacyListItemModelType) => {
          if (calcAutorejectMinute(item) <= autoRejectAlertMinute) {
            count += 1;
          }
        },
      );
    }
    setAlmostAutoRejectCount(count);
  }, [
    setAlmostAutoRejectCount,
    requestedTelepharmacyList,
    autoRejectAlertMinute,
  ]);

  useCallback(() => {
    updateAlmostAutoRejectCount();
  }, [updateAlmostAutoRejectCount]);
  useInterval(() => {
    updateAlmostAutoRejectCount();
  }, 5000);
  const telepharmacyId = Number(query.get('id'));

  useEffect(() => {
    if (
      requestedTelepharmacyList &&
      requestedTelepharmacyList.length === 0 &&
      pathname === `/home/newReservation/requested`
    ) {
      navigate(`/home/newReservation/requested`);
    } else if (
      !telepharmacyId &&
      requestedTelepharmacyList &&
      requestedTelepharmacyList.length > 0 &&
      pathname.indexOf('/home/newReservation/requested') > -1
    ) {
      navigate(`?id=${requestedTelepharmacyList[0].id}`);
    }
  }, [navigate, pathname, requestedTelepharmacyList, telepharmacyId]);

  useEffect(() => {
    if (pathname.indexOf('/home/newReservation/requested') > -1) {
      setIsLoading(true);
      Promise.all([
        dispatch(getTelepharmacyCount()),
        dispatch(getTelepharmacyList_requested({ limit: 60, offset: 0 })),
      ]).then(() => {
        setIsLoading(false);
      });
    }
    return () => setIsLoading(false);
  }, [dispatch, pathname]);

  useEffect(() => {
    const popUpKey = 'pharmacistAdminPopup';
    const popUpValue = 'show2';
    if (getLocalStorageState(popUpKey) !== popUpValue) {
      dispatch(openModal_noticeModal());
    }
  }, [dispatch, getLocalStorageState]);

  useEffect(() => {
    if (pathname.includes('/accepted')) {
      setAcceptedIsActive(true);
    } else {
      setAcceptedIsActive(false);
    }

    return () => setAcceptedIsActive(false);
  }, [pathname]);

  return (
    <Style.Container
      acceptedIsActive={acceptedIsActive}
      isRequestedPage={isRequestedPage}
    >
      {pathname === PHARMACIST_ADMIN_PATHNAME.NEW_RESERVATION_REQUESTED ? (
        <Style.ListContainer>
          <Style.ListArea isShowAds={isShowAds}>
            <Style.ListTitleArea>
              <Font fontType="h2" color="fill/black">
                접수대기
              </Font>
              <Font fontType="h2" color="fill/accent">
                {`${requestedTelepharmacyCount}건`}
              </Font>
            </Style.ListTitleArea>

            <Style.TelepharmacyListArea isShowAds={isShowAds}>
              {almostAutoRejectCount > 0 ? (
                <>
                  <Style.AlmostAutoRejectContainer>
                    <SvgIcon icon="cautionIcon" color="yellow900" width={20} />
                    <Font fontType="body2_medium" color="fill/white">
                      {almostAutoRejectCount}건의 요청이 {autoRejectAlertMinute}
                      분 내 만료됩니다.
                    </Font>
                  </Style.AlmostAutoRejectContainer>
                </>
              ) : null}
              {requestedTelepharmacyCount ? (
                requestedTelepharmacyList?.map((item, index) => (
                  <div key={item.id}>
                    <TelepharmacyListItem
                      isSelected={item.id === telepharmacyId}
                      item={item}
                      onClick={() => {
                        navigate(
                          `/home/newReservation/requested?id=${item.id}`,
                        );
                      }}
                    />
                  </div>
                ))
              ) : (
                <Style.EmptyContainer>
                  <Font fontType="body2" color="fill/medium">
                    접수 대기 중인 조제가 없습니다.
                  </Font>
                </Style.EmptyContainer>
              )}
            </Style.TelepharmacyListArea>
          </Style.ListArea>

          {pathname === PHARMACIST_ADMIN_PATHNAME.NEW_RESERVATION_REQUESTED &&
          isShowAds ? (
            <BFlex width={320} height={320} borderRadius={0} padding={24}>
              <LNBCarousel />
            </BFlex>
          ) : undefined}
        </Style.ListContainer>
      ) : undefined}
      <Style.CardArea acceptedIsActive={acceptedIsActive}>
        {children}
      </Style.CardArea>
    </Style.Container>
  );
}

export default NewReservationPage;
