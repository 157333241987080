import { Font } from '@components/atoms';
import { loadingSpinner } from '@components/atoms/lottie';
import { useSuspensePrescriptionDetailList } from '@hooks/featureHooks/useFetchPrescriptionDetailList';
import Lottie from 'lottie-react';
import { Suspense, useMemo } from 'react';
import { PageStatus } from '../telepharmacyDetailInformation';
import * as Style from './index.style';
import { ListItem } from './sections';
import ListContent from './sections/listContent';

export const OCR_INFO_LISTITEM_FLEX = [
  { flex: 170, title: '보험코드' },
  { flex: 410, title: '약품명' },
  { flex: 140, title: '투약 정보' },
];

interface PrescriptionContents {
  drug_code: string;
  drug_name: string;
  one_dose: string;
  dosing_per_day: string;
  total_dosing_days: string;
  generic_name: string;
}

export type PrescriptionContentsResult = Record<
  'prescription_contents',
  PrescriptionContents
>;

interface OcrResultTableProps {
  tmId: number | undefined;
  prescriptionId: number | undefined;
  pageType: PageStatus;
}

function OcrResultTable({
  tmId,
  prescriptionId,
  pageType,
}: OcrResultTableProps) {
  return (
    <Style.ListContainer>
      <Style.ListHeaderItem>
        {OCR_INFO_LISTITEM_FLEX.map((item, index) => (
          <ListContent.Element key={index} flex={item.flex} border="none">
            <Font fontType="h5" color="fill/white">
              {item.title}
            </Font>
          </ListContent.Element>
        ))}
      </Style.ListHeaderItem>
      <Suspense
        fallback={
          <Style.LoaderArea>
            <Lottie
              animationData={loadingSpinner}
              style={{ width: 48, aspectRatio: 1 }}
              loop
            />
          </Style.LoaderArea>
        }
      >
        <OcrResultTableContent
          tmId={tmId}
          prescriptionId={prescriptionId}
          pageType={pageType}
        />
      </Suspense>
    </Style.ListContainer>
  );
}

function OcrResultTableContent({
  tmId,
  prescriptionId,
  pageType,
}: OcrResultTableProps) {
  const { data: prescriptionDetailList } = useSuspensePrescriptionDetailList({
    tmId,
    prescriptionId,
  });

  const canShowPrescriptionDetailList = useMemo(() => {
    return (
      prescriptionDetailList &&
      prescriptionDetailList.length > 0 &&
      prescriptionDetailList?.every(
        (prescriptionDetail) => !!prescriptionDetail,
      )
    );
  }, [prescriptionDetailList]);

  return (
    <>
      {canShowPrescriptionDetailList ? (
        prescriptionDetailList?.map((prescriptionDetail) => {
          return (
            <ListItem
              key={prescriptionDetail.id}
              item={prescriptionDetail}
              prescriptionId={prescriptionId}
              pageType={pageType}
            />
          );
        })
      ) : (
        <Style.PrescriptionErrorCase>
          <Font fontType="body2_medium" color="state/distructive">
            처방전 데이터를 불러오지 못했습니다.
          </Font>
          <Font fontType="body2_medium" color="state/distructive">
            아래의 처방전을 확인해주세요.
          </Font>
        </Style.PrescriptionErrorCase>
      )}
    </>
  );
}

export default OcrResultTable;
