/* eslint-disable @typescript-eslint/return-await */
import { serverURL, tokenList } from '@constants';
import { refreshToken } from '@services';
import { httpBuildQuery, storageHandler } from '@utils';
import { detect } from 'detect-browser';
import { FetchAPIType } from 'types/index.d';
// import publicIp from 'public-ip';

const browser = detect();

export const callAPI = async ({
  method,
  route,
  params,
  type,
  contentType,
}: FetchAPIType.CallAPIType) => {
  const { baseURL, option } = callAPIOption({ method, type, contentType });

  if (
    process.env.REACT_APP_SERVER === 'LOCAL' ||
    process.env.REACT_APP_SERVER === 'DEV'
  ) {
    console.log(
      'API CALL',
      `\n method: ${method} \n baseURL: ${baseURL} \n route: ${route} \n params: ${JSON.stringify(
        params,
      )}`,
    );
  }

  const response = await callAPIResponse({
    method,
    route,
    params,
    option,
    baseURL,
    contentType,
  });

  const refinedData = await checkToken({
    method,
    route,
    params,
    type,
    contentType,
    response,
  });

  return refinedData;
};

export default {
  async get(
    route: string,
    params: object,
    type: FetchAPIType.APIType,
    contentType:
      | 'application/json'
      | 'multipart/form-data' = 'application/json',
  ) {
    const responseData = await callAPI({
      method: 'GET',
      route,
      params,
      type,
      contentType,
    });
    return responseData;
  },
  async post(
    route: string,
    params: object,
    type: FetchAPIType.APIType,
    contentType:
      | 'application/json'
      | 'multipart/form-data' = 'application/json',
  ) {
    const responseData = await callAPI({
      method: 'POST',
      route,
      params,
      type,
      contentType,
    });
    return responseData;
  },
  async patch(
    route: string,
    params: object,
    type: FetchAPIType.APIType,
    contentType:
      | 'application/json'
      | 'multipart/form-data' = 'application/json',
  ) {
    const responseData = await callAPI({
      method: 'PATCH',
      route,
      params,
      type,
      contentType,
    });
    return responseData;
  },
  async put(
    route: string,
    params: object,
    type: FetchAPIType.APIType,
    contentType:
      | 'application/json'
      | 'multipart/form-data' = 'application/json',
  ) {
    const responseData = await callAPI({
      method: 'PUT',
      route,
      params,
      type,
      contentType,
    });
    return responseData;
  },
  async delete(
    route: string,
    params: object,
    type: FetchAPIType.APIType,
    contentType:
      | 'application/json'
      | 'multipart/form-data' = 'application/json',
  ) {
    const responseData = await callAPI({
      method: 'DELETE',
      route,
      params,
      type,
      contentType,
    });
    return responseData;
  },
};

const callAPIOption = ({
  method,
  type,
  contentType,
}: FetchAPIType.CallAPIOptionType) => {
  const { REST_AUTH_TOKEN } = tokenList;
  const { getLocalStorageState } = storageHandler;
  let baseURL: string;
  let option: object;

  const ACCESS_TOKEN = getLocalStorageState(REST_AUTH_TOKEN.ACCESS_TOKEN);
  const actor = getLocalStorageState('actor');

  // const ipAddress = await publicIp.v4({
  //   fallbackUrls: ['https://ifconfig.co/ip'],
  // });

  const headerInfo = {
    'X-OS-Type': browser?.name || '',
    'X-App-Type': `pharmacist_admin_${process.env.REACT_APP_SERVER}`,
    'X-Device-Name': browser?.os ? `${browser?.os} pc` : 'pc',
    'X-Screen-Width': String(window.screen.width) || '',
    'X-App-Version': '1.0.0',
    'X-Ip-Address': '',
    // 임시 토큰 발급 시 사용
    ...(actor ? { 'X-Impersonated-Actor': actor } : {}),
  };

  switch (type) {
    case 'myDoctor':
      baseURL = serverURL.ServerConstant();
      if (contentType === 'application/json') {
        option = {
          method,
          headers: {
            ...headerInfo,
            'Content-Type': contentType,
            Accept: 'application/json',
            Authorization: ACCESS_TOKEN ? `Bearer ${ACCESS_TOKEN}` : '',
          },
        };
      } else {
        option = {
          method,
          headers: {
            ...headerInfo,
            Accept: 'application/json',
            Authorization: ACCESS_TOKEN ? `Bearer ${ACCESS_TOKEN}` : '',
          },
        };
      }
      break;
    default:
      baseURL = '';
      option = {};
      break;
  }

  return { baseURL, option };
};

const callAPIResponse = async ({
  method,
  route,
  params,
  option,
  baseURL,
  contentType,
}: FetchAPIType.CallAPIResponseType) => {
  let response;

  // const baseURL1 = 'http://10.200.170.75:8000/';
  // const baseURL1 = 'http://192.168.45.212:8000/';

  if (method === 'GET') {
    if (params) {
      const urlParams = httpBuildQuery(params);
      const url = `${baseURL}${route}?${urlParams}`;
      response = await fetch(url, option);
    } else {
      response = await fetch(`${baseURL}${route}`, option);
    }
  } else {
    response = await fetch(`${baseURL}${route}`, {
      ...option,
      body:
        contentType === 'application/json' ? JSON.stringify(params) : params,
    });
  }

  return response;
};

const checkToken = async ({
  method,
  route,
  params,
  type,
  contentType,
  response,
}: FetchAPIType.CheckTokenType) => {
  const { removeAllLocalStorageState } = storageHandler;
  const serverStatus = response.status;
  const responseJSON = await response.json();
  responseJSON.serverStatus = serverStatus;

  if (response.status === 403) {
    alert(
      '해당 작업을 진행할 권한이 없어요. 문제가 계속 지속될 경우 고객센터에 문의해주세요.',
    );
    return;
  }

  if (response.status === 401) {
    if (
      responseJSON.detail ===
      '자격 인증데이터(authentication credentials)가 제공되지 않았습니다.'
    ) {
      removeAllLocalStorageState();
      return responseJSON;
    }
    if (responseJSON.code === 'token_not_valid') {
      if (responseJSON.messages === undefined) {
        removeAllLocalStorageState();
        return responseJSON;
      }
      if (responseJSON.messages[0].token_class === 'AccessToken') {
        return await refreshToken({
          method,
          route,
          params,
          type,
          contentType,
        });
      }
      removeAllLocalStorageState();
      return responseJSON;
    }
    removeAllLocalStorageState();
    return responseJSON;
  }

  return responseJSON;
};
