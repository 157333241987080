import { Font } from '@components/atoms';
import { loadingSpinner } from '@components/atoms/lottie';
import { commonHooks } from '@hooks';
import Lottie from 'lottie-react';
import { ReactNode, useState } from 'react';
import { TelepharmacyListItemModelType } from 'types/myDoctorModelType/telepharmacyModelType';
import ListContent from './components/listContent';
import Pagination from './components/pagination';
import * as Style from './index.style';

interface TableHeaderItem {
  flex: number | string;
  title: string;
}

interface TableProps {
  isTableItemsLoading: boolean;
  tableHeaderItems: TableHeaderItem[];
  telepharmacyCount: number;
  telepharmacyData: TelepharmacyListItemModelType[] | undefined;
  emptyMessage: string;
  renderTableBodyItem: (item: TelepharmacyListItemModelType) => ReactNode;
}

function TelepharmacyTable({
  isTableItemsLoading,
  tableHeaderItems,
  telepharmacyCount,
  telepharmacyData,
  emptyMessage,
  renderTableBodyItem,
}: TableProps) {
  const { useQuery } = commonHooks;
  const query = useQuery();

  const [limit, setLimit] = useState<number>(30);

  const pageQuery = query.get('page');
  const page = Number(pageQuery) || 0;
  const maxPage = telepharmacyCount
    ? Math.floor(telepharmacyCount / limit) + 1
    : 0;

  return (
    <>
      <Style.ListContainer>
        <Style.ListHeaderItem>
          {tableHeaderItems.map((item, index) => {
            return (
              <ListContent.Bold key={index} flex={item.flex} border="none">
                {item.title}
              </ListContent.Bold>
            );
          })}
        </Style.ListHeaderItem>
        {isTableItemsLoading ? (
          <Style.LoaderArea>
            <Lottie
              animationData={loadingSpinner}
              style={{ width: 48, aspectRatio: 1 }}
              loop
            />
          </Style.LoaderArea>
        ) : telepharmacyData && telepharmacyData?.length > 0 ? (
          telepharmacyData?.map((item) => {
            return renderTableBodyItem(item);
          })
        ) : (
          <Style.EmptyMessageContainer>
            <Font fontType="body2_medium" color="fill/medium">
              {emptyMessage}
            </Font>
          </Style.EmptyMessageContainer>
        )}
      </Style.ListContainer>
      <Pagination page={page} maxPage={maxPage} limit={limit} />
    </>
  );
}

export default TelepharmacyTable;
