import { QUERY_KEY } from '@constants/react-query';
import { getTPProcessRequestList } from '@mobile/api';
import { useQuery } from '@tanstack/react-query';

const useTPprocessRequestList = () => {
  return useQuery({
    queryFn: getTPProcessRequestList,
    queryKey: [QUERY_KEY.TP_PROCESS_LIST],
    retry: false,
  });
};

export default useTPprocessRequestList;
