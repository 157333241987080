import { serverURL as serverUrl, tokenList } from '@constants';
import { supporters } from '@functions';
import { callAPI, storageHandler } from '@utils';
import { FetchAPIType } from 'types/index.d';

const { REST_AUTH_TOKEN } = tokenList;

const refreshToken = async (
  callAPIData: FetchAPIType.CallAPIType,
): Promise<any> => {
  const refreshResult = await refreshTokenAPI();

  if (refreshResult) {
    return callAPI(callAPIData);
  }

  alert('로그인이 만료되었습니다.');
  return callAPI(callAPIData);
};

const refreshTokenAPI = async () => {
  const { logoutSupporter } = supporters;
  const { getLocalStorageState, setLocalStorageState } = storageHandler;
  const REFRESH_TOKEN = await getLocalStorageState(
    REST_AUTH_TOKEN.REFRESH_TOKEN,
  );
  const serverURL = serverUrl.ServerConstant();
  const option = {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
      Accept: 'application/json',
    },
  };

  const response = await fetch(`${serverURL}rest-auth/token/refresh/`, {
    ...option,
    body: JSON.stringify({ refresh: REFRESH_TOKEN }),
  });

  if (response.status === 200) {
    const responseJSON = await response.json();

    await setLocalStorageState(
      REST_AUTH_TOKEN.ACCESS_TOKEN,
      responseJSON.access,
    );

    return true;
  }
  await logoutSupporter.logout();

  return false;
};

export default refreshToken;
