import BButton from '@components/meraki-ui/BButton';
import { BColor } from '@components/meraki-ui/BColor';
import BFlex from '@components/meraki-ui/BFlex';
import BModal from '@components/meraki-ui/BModal';
import BModalFooter from '@components/meraki-ui/BModal/BModalFooter';
import BModalHeader from '@components/meraki-ui/BModal/BModalHeader';
import BTypography from '@components/meraki-ui/BTypography';
import styled from 'styled-components';

export interface INotice {
  id: number;
  title: string;
  contents: {
    id: number;
    title: string;
    content: string;
    content_images: { image_url: string; name: string }[];
  }[];
  main_button_title: string;
  main_button_link: string;
  sub_button_title?: string;
  sub_button_link?: string;
}

interface BNoticeModalProps {
  open: boolean;
  notice?: INotice;
  onCancel: () => void;
  onOpenChange: () => void;
}

function BNoticeModal({
  open,
  onOpenChange,
  onCancel,
  notice,
}: BNoticeModalProps) {
  return (
    <BModal open={open} onOpenChange={onOpenChange} width={600} padding={0}>
      <BModalHeader title={notice?.title} onOpenChange={onOpenChange} />
      <BFlex
        padding="1.5rem 2.5rem"
        maxHeight={622}
        isOverflow
        isColumn
        gap={32}
      >
        {notice?.contents?.map((content) => (
          <BFlex key={content?.id} gap={24} isColumn padding="0 0 2rem 0">
            <BFlex gap={12} isColumn>
              <BTypography
                text={content?.title}
                size={18}
                isBold
                color="mono08"
                justNoWrap
              />
              <div style={{ whiteSpace: 'break-spaces' }}>
                <BTypography text={content?.content} size={16} color="mono08" />
              </div>
            </BFlex>

            <BFlex gap={12} flexWrap width="100%">
              {content?.content_images.map((i) => (
                <BFlex key={i?.name} isColumn gap={8} isFull height={166.67}>
                  <ImageContainer src={i?.image_url} alt="서브이미지" />
                  <BTypography size={14} color="mono07" text={i?.name} />
                </BFlex>
              ))}
            </BFlex>
          </BFlex>
        ))}
      </BFlex>

      <BModalFooter
        borderTopExisted
        buttonText={
          <BFlex width="100%" gap={20} padding="1.25rem 2rem 2rem">
            {notice?.sub_button_title ? (
              <a
                href={`${notice?.sub_button_link}`}
                target="_blank"
                style={{ width: '100%', textDecoration: 'none' }}
                rel="noreferrer"
              >
                <BButton
                  text={notice?.sub_button_title}
                  buttonType="default"
                  size="heightForFullWidth"
                  height={60}
                />
              </a>
            ) : undefined}

            {notice?.main_button_title ? (
              <a
                href={`${notice?.main_button_link}`}
                target="_blank"
                style={{ width: '100%', textDecoration: 'none' }}
                rel="noreferrer"
              >
                <BButton
                  text={notice?.main_button_title ?? '다시 보지 않기'}
                  buttonType="primary"
                  size="heightForFullWidth"
                  height={60}
                />
              </a>
            ) : undefined}
          </BFlex>
        }
      />
      <NeverOpenAgain onClick={onCancel}>
        <BTypography isBold text="다시 보지 않기" size={18} color="mono07" />
      </NeverOpenAgain>
    </BModal>
  );
}

const NeverOpenAgain = styled.div`
  width: 100%;
  margin-bottom: 1.5rem;
  text-align: center;
  cursor: pointer;
`;

const ImageContainer = styled.img`
  border-radius: 0.5rem;
  background-color: ${BColor.mono03};
  flex: 1;
  min-height: 166.67px;
  max-height: 166.67px;
  object-fit: cover;
`;

export default BNoticeModal;
