import { useAppDispatch, useAppSelector } from '@stores/hooks';
import {
  closeModal_pharmacyAutoConfirmedModal,
  openModal_pharmacyAutoConfirmedModal,
  selectPharmacyAutoConfirmedModalTargetMedicine,
  selectPharmacyAutoConfirmedModalType,
  selectPharmacyAutoConfirmedModalVisible,
  set_modalType_pharmacyAutoConfirmedModal,
  set_targetMedicine_pharmacyAutoConfirmedModal,
} from '@stores/modalStore/pharmacyAutoConfirmedModalStore';
import { useCallback } from 'react';

const usePharmacyAutoConfirmedModalHook = () => {
  const dispatch = useAppDispatch();
  const isVisible = useAppSelector(selectPharmacyAutoConfirmedModalVisible);
  const modalType = useAppSelector(selectPharmacyAutoConfirmedModalType);
  const targetMedicine = useAppSelector(
    selectPharmacyAutoConfirmedModalTargetMedicine,
  );

  const closeModal = useCallback(() => {
    dispatch(closeModal_pharmacyAutoConfirmedModal());
  }, [dispatch]);

  const openModal = useCallback(() => {
    dispatch(openModal_pharmacyAutoConfirmedModal());
  }, [dispatch]);

  const setPharmacyAutoConfirmedModalType = (type: string) => {
    dispatch(set_modalType_pharmacyAutoConfirmedModal(type));
  };

  const setPharmacyAutoConfirmedTargetMedicine = (medicine: any) => {
    dispatch(set_targetMedicine_pharmacyAutoConfirmedModal(medicine));
  };

  return {
    isVisible,
    modalType,
    targetMedicine,
    closeModal,
    openModal,
    setPharmacyAutoConfirmedModalType,
    setPharmacyAutoConfirmedTargetMedicine,
  };
};

export default usePharmacyAutoConfirmedModalHook;
