import {
  Action,
  combineReducers,
  configureStore,
  ThunkAction,
} from '@reduxjs/toolkit';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import globalNavBarReducer from './globalNavBarStore';
import acceptTelepharmacyModalReducer from './modalStore/acceptTelepharmacyModal';
import cancelTelepharmacyModalReducer from './modalStore/cancelTelepharmacyModal';
import changeServerURLModalReducer from './modalStore/changeServerURLModal';
import commonModalReducer from './modalStore/commonModal';
import companyInfoModalReducer from './modalStore/companyInfoModal';
import customerCenterModalReducer from './modalStore/customerCenterModal';
import findIdPasswordModalReducer from './modalStore/findIdPasswordModal';
import finishTelepharmacyModalReducer from './modalStore/finishTelepharmacyModal';
import getReadyModalReducer from './modalStore/getReadyModal';
import medicineManagementEditModalReducer from './modalStore/medicineManagementEditModal';
import medicineManagementModalReducer from './modalStore/medicineManagementModal';
import medicineManagementCommonModalReducer from './modalStore/medicineManangementCommonModal';
import medicineSoldOutAlertModalReducer from './modalStore/medicineSoldOutAlertModalStore';
import medicineUnitManagementEditModalReducer from './modalStore/medicineUnitManagementEditModal';
import medicineUnitManagementModalReducer from './modalStore/medicineUnitManagementModal';
import modifyMedicineGuideModalReducer from './modalStore/modifyMedicineGuideModal';
import noticeModalReducer from './modalStore/noticeModal';
import pharmacyAutoConfirmedModalReducer from './modalStore/pharmacyAutoConfirmedModalStore';
import recheckRequestModalReducer from './modalStore/recheckRequestModal';
import rejectTelepharmacyModalReducer from './modalStore/rejectTelepharmacyModal';
import setFirstLoginModalReducer from './modalStore/setFirstLoginModal';
import signupModalReducer from './modalStore/signupModal';
import symptomImageModalReducer from './modalStore/symptomImageModal';
import telepharmacyCountReducer from './telepharmacyStore/telepharmacyCount';
import telepharmacyListReducer from './telepharmacyStore/telepharmacyList';
import userInformationReducer from './userInformationStore';
import versionInfoReducer from './versionStore';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [''],
};

const rootReducer = combineReducers({
  userInformation: userInformationReducer,
  commonModal: commonModalReducer,
  setFirstLoginModal: setFirstLoginModalReducer,
  findIdPasswordModal: findIdPasswordModalReducer,
  signupModal: signupModalReducer,
  customerCenterModal: customerCenterModalReducer,
  companyInfoModal: companyInfoModalReducer,
  rejectTelepharmacyModal: rejectTelepharmacyModalReducer,
  acceptTelepharmacyModal: acceptTelepharmacyModalReducer,
  cancelTelepharmacyModal: cancelTelepharmacyModalReducer,
  symptomImageModal: symptomImageModalReducer,
  medicineManagementModal: medicineManagementModalReducer,
  medicineManagementEditModal: medicineManagementEditModalReducer,
  medicineManagementCommonModal: medicineManagementCommonModalReducer,
  finishTelepharmacyModal: finishTelepharmacyModalReducer,
  noticeModal: noticeModalReducer,
  getReadyModal: getReadyModalReducer,
  changeServerURLModal: changeServerURLModalReducer,
  modifyMedicineGuideModal: modifyMedicineGuideModalReducer,
  globalNavBar: globalNavBarReducer,
  telepharmacyCount: telepharmacyCountReducer,
  telepharmacyList: telepharmacyListReducer,
  recheckRequestModal: recheckRequestModalReducer,
  pharmacyAutoConfirmedModal: pharmacyAutoConfirmedModalReducer,
  medicineUnitManagementModal: medicineUnitManagementModalReducer,
  medicineUnitManagementEditModal: medicineUnitManagementEditModalReducer,
  medicineSoldOutAlertModal: medicineSoldOutAlertModalReducer,
  versionInfo: versionInfoReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
