import { COLOR } from '@components/atoms';
import styled from 'styled-components';

export const TextAreaContainer = styled.div<{ isSelected: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  width: 100%;
  border-radius: 8px;
`;

export const RadioBox = styled.input<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  border: 1px solid
    ${(props) =>
      props.isSelected ? COLOR['fill/accent'] : COLOR['fill/medium']};
  width: 18px;
  height: 18px;
  cursor: pointer;
`;

export const LabelBox = styled.label`
  cursor: pointer;
`;

export const TopArea = styled.div`
  display: flex;
  align-items: center;
`;

export const BottomArea = styled.div`
  display: flex;
  width: 100%;
  padding-left: 54px;
  padding-right: 20px;
  margin-top: 20px;
`;
