import { IconDetailProps } from '@components/atoms/images';
import { COLOR } from '../color';

function ChevronDown({ width, height, color }: IconDetailProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={width}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4714 6.19526C12.7317 6.45561 12.7317 6.87772 12.4714 7.13807L12 6.66667L11.5286 6.19526C11.7889 5.93491 12.211 5.93491 12.4714 6.19526ZM7.99996 9.72386L11.5286 6.19526C11.5286 6.19524 11.5286 6.19526 12 6.66667C12.4714 7.13807 12.4714 7.13805 12.4714 7.13807L8.47136 11.1381C8.21101 11.3984 7.7889 11.3984 7.52855 11.1381L3.52855 7.13807C3.2682 6.87772 3.2682 6.45561 3.52855 6.19526C3.7889 5.93491 4.21101 5.93491 4.47136 6.19526L7.99996 9.72386Z"
        fill={COLOR[color]}
      />
    </svg>
  );
}

export default ChevronDown;
