import { QUERY_KEY } from '@constants/react-query';
import { useQuery } from '@tanstack/react-query';
import { isNil } from 'es-toolkit';
import { getPrescriptionQrCodeImage } from '../apis';

interface UsePrescriptionQrCodeImageProps {
  prescriptionId: number | undefined;
}

const usePrescriptionQrCodeImage = ({
  prescriptionId,
}: UsePrescriptionQrCodeImageProps) => {
  return useQuery({
    queryKey: [QUERY_KEY.TP_PRESCRIPTION_QR_CODE_IMAGE, prescriptionId],
    queryFn: () => {
      return getPrescriptionQrCodeImage(prescriptionId || 3585);
    },
    enabled: !isNil(prescriptionId),
    select: (data) => data?.data,
    refetchOnMount: true,
  });
};

export default usePrescriptionQrCodeImage;
