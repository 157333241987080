import { BColor } from '@components/meraki-ui/BColor';
import { IconDetailProps } from '@components/meraki-ui/BIcon/svg.type';

export default function WaterDropIcon({
  width,
  height,
  color,
}: IconDetailProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.20917 2.80033C9.68924 2.29451 10 2 10 2C10.1089 2.36292 10.2336 2.70838 10.3712 3.03827C11.1828 4.98375 12.4443 6.38779 13.5675 7.63791C14.8778 9.09631 16 10.3452 16 12C16 15.3137 13.3137 18 10 18C6.68629 18 4 15.3137 4 12C4 8.66801 7.57979 4.51709 9.20917 2.80033ZM9.62337 3.82128C9.10047 4.38814 8.44636 5.13752 7.79425 5.98996C7.06754 6.9399 6.35825 7.9985 5.83423 9.06023C5.3048 10.1329 5 11.1381 5 12C5 14.7614 7.23858 17 10 17C12.7614 17 15 14.7614 15 12C15 10.7988 14.2043 9.84288 12.8186 8.3007L12.7891 8.26786C11.7489 7.11013 10.4995 5.71973 9.62337 3.82128Z"
        fill={BColor[color]}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.55279 9.77639C7.37354 8.13488 8.27026 7.02263 8.64645 6.64645L9.35355 7.35355C9.06307 7.64403 8.22646 8.66512 7.44721 10.2236L6.55279 9.77639Z"
        fill={BColor[color]}
      />
    </svg>
  );
}
