import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '@stores';
import { CompanyInfoModalType } from './index.d';

const initialState: CompanyInfoModalType = {
  isVisible: false,
};

export const companyInfoModal = createSlice({
  name: 'modal/companyInfoModal',
  initialState,
  reducers: {
    openModal_companyInfoModal: (state) => {
      state.isVisible = true;
    },
    closeModal_companyInfoModal: (state) => {
      state.isVisible = false;
    },
  },
});

export const { openModal_companyInfoModal, closeModal_companyInfoModal } =
  companyInfoModal.actions;

export const selectCompanyInfoModalVisible = (state: RootState) =>
  state.companyInfoModal.isVisible;

export default companyInfoModal.reducer;
