import { COLOR } from '@components/atoms';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const ListDefaultItem = styled(Link)`
  display: flex;
  flex-direction: row;
  height: 56px;
  width: 100%;
  text-decoration: none;
  color: inherit;
`;

export const ListItem = styled(ListDefaultItem)`
  background-color: ${COLOR['fill/white']};

  cursor: pointer;
  &:visited {
    background-color: ${COLOR.blue50};
  }
  &:hover {
    background-color: ${COLOR['fill/light']};
  }
  &:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  position: relative;
`;

export const SubstitutedArea = styled.div`
  display: flex;
  flex-direction: column;
`;

export const IconButtonItem = styled.div`
  display: flex;
  padding: 5px 12px 5px 9px;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

export const ListItemIcon = styled.img`
  width: 16px;
  height: 16px;
`;

export const ListItemLayout = styled.div`
  padding: 0 16px;
`;

export const ListItemColumnLayout = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px;
`;

export const ButtonItemArea = styled.div`
  display: flex;
  position: absolute;
  top: 8px;
  right: 8px;
`;

export const ButtonItem = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${COLOR['fill/dark']};
  width: 40px;
  height: 40px;
  &:hover {
    background-color: ${COLOR['fill/black']};
  }
`;
