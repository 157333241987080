import { BColor } from '@components/meraki-ui/BColor';
import { IconDetailProps } from '@components/meraki-ui/BIcon/svg.type';

export default function UploadIcon({ width, height, color }: IconDetailProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.4375 11.8483C3.67912 11.8483 3.875 12.0605 3.875 12.3223V14.6921C3.875 15.2156 4.26675 15.64 4.75 15.64H15.25C15.7332 15.64 16.125 15.2156 16.125 14.6921V12.3223C16.125 12.0605 16.3209 11.8483 16.5625 11.8483C16.8041 11.8483 17 12.0605 17 12.3223V14.6921C17 15.7391 16.2165 16.5879 15.25 16.5879H4.75C3.7835 16.5879 3 15.7391 3 14.6921V12.3223C3 12.0605 3.19588 11.8483 3.4375 11.8483Z"
        fill={BColor[color]}
      />
      <path
        d="M9.69064 3.55068C9.8615 3.36559 10.1385 3.36559 10.3094 3.55068L12.9344 6.39443C13.1052 6.57953 13.1052 6.87962 12.9344 7.06471C12.7635 7.24981 12.4865 7.24981 12.3156 7.06471L10.4375 5.03006V13.365C10.4375 13.6267 10.2416 13.8389 10 13.8389C9.75838 13.8389 9.5625 13.6267 9.5625 13.365V5.03006L7.68436 7.06471C7.5135 7.24981 7.2365 7.24981 7.06564 7.06471C6.89479 6.87962 6.89479 6.57953 7.06564 6.39443L9.69064 3.55068Z"
        fill={BColor[color]}
      />
    </svg>
  );
}
