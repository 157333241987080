import { Button, COLOR, Font } from '@components/atoms';
import { ModalCommonStructure as Modal } from '@components/molecules';
import { CompanyInfoModalTemplateType } from './index.d';
import * as Style from './index.style';

function CompanyInfoModalTemplate({
  isVisible,
  closeModal,
}: CompanyInfoModalTemplateType) {
  return (
    <Modal
      isOpen={isVisible}
      onRequestClose={() => closeModal()}
      modalHeader={
        <Style.TitleArea>
          <Font fontType="h1">사업자 정보 안내</Font>
        </Style.TitleArea>
      }
      modalBody={
        <>
          <Style.DescriptionArea>
            <Font fontType="body1">
              (주)메라키플레이스
              <br />
              • 대표: 선재원, 손웅래
              <br />
              • 사업자등록번호: 554-86-02396
              <br />
              • 통신판매업신고: 2024-서울영등포-2562
              <br />
              • 주소: 서울특별시 영등포구 의사당대로 83,4,5,6,8,17,19층
              (여의도동, 오투타워)
              <br />
              • 이메일: help@merakiplace.kr
              <br />
              • 전화번호: 1544-6172
              <br />
            </Font>
          </Style.DescriptionArea>
        </>
      }
      modalFooter={
        <Style.FooterContainer>
          <Button
            onClick={() => closeModal()}
            backgroundColor={COLOR['fill/accent']}
            width="100%"
            height="56px"
            borderRadius="12px"
            title={
              <Font fontType="body1" color="fill/white">
                확인
              </Font>
            }
          />
        </Style.FooterContainer>
      }
    />
  );
}

export default CompanyInfoModalTemplate;
