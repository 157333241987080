import { priceFormatter } from '@functions/formatters';
import { ChangeEvent, useEffect, useState } from 'react';

const usePharmacyCost = () => {
  const [cost, setCost] = useState('');
  const [isZeroCost, setIsZeroCost] = useState(false);

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    setCost(priceFormatter.uncommaFormatter(e.target.value));
  };

  const handleCheckZeroCost = () => {
    setIsZeroCost((prev) => !prev);
  };

  useEffect(() => {
    if (isZeroCost === true) {
      setCost('0');
    } else {
      setCost('');
    }
  }, [isZeroCost]);

  return {
    cost,
    isZeroCost,
    handleChangeInput,
    handleCheckZeroCost,
  };
};

export default usePharmacyCost;
