import { Button, COLOR, Font } from '@components/atoms';
import { ModalCommonStructure as Modal } from '@components/molecules';
import { trackLoginMixpanelEvents } from '@utils/mixpanel/login';
import { SignupModalTemplateType } from './index.d';
import * as Style from './index.style';

function SignupModalTemplate({
  isVisible,
  closeModal,
}: SignupModalTemplateType) {
  const onClick = () => {
    window.open('https://form.typeform.com/to/uHXLLPVk', '_blank');
    trackLoginMixpanelEvents('clickSignUp')();
  };

  return (
    <Modal
      isOpen={isVisible}
      onRequestClose={() => closeModal()}
      modalHeader={
        <Style.TitleArea>
          <Font fontType="h1" color="fill/dark">
            회원가입 준비물 안내
          </Font>
        </Style.TitleArea>
      }
      modalBody={
        <>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
            <Font fontType="body1_medium">
              약사 회원가입을 위해 아래 2가지를 미리 준비해주세요.
            </Font>

            <div
              style={{
                paddingTop: 14,
                paddingBottom: 14,
                paddingLeft: 16,
                paddingRight: 16,
                backgroundColor: COLOR['fill/light'],
                borderRadius: 12,
                display: 'flex',
                flexDirection: 'column',
                gap: 12,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: 8,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                >
                  <circle
                    cx="10.0002"
                    cy="10.4997"
                    r="9.16667"
                    fill="#4785FF"
                  />
                  <path
                    d="M10.6019 14.6667C10.3241 14.6667 10.0772 14.5721 9.86111 14.3829C9.66049 14.1938 9.56019 13.9755 9.56019 13.7282V7.94444C9.56019 7.74074 9.46759 7.68982 9.28241 7.79167L8.86574 8.00992C8.80401 8.03902 8.74228 8.06085 8.68056 8.0754C8.61883 8.0754 8.5571 8.0754 8.49537 8.0754C8.20216 8.0754 7.96296 7.9881 7.77778 7.81349C7.59259 7.62434 7.5 7.39153 7.5 7.11508C7.5 6.73677 7.71605 6.46032 8.14815 6.28571L9.93056 5.60913C10.1312 5.53638 10.3086 5.5 10.463 5.5C10.8179 5.5 11.1034 5.60185 11.3194 5.80556C11.5509 6.00926 11.6667 6.27844 11.6667 6.6131L11.6435 13.7718C11.6435 14.0337 11.5432 14.252 11.3426 14.4266C11.1574 14.5866 10.9105 14.6667 10.6019 14.6667Z"
                    fill="white"
                  />
                </svg>

                <Font fontType="h4" color="fill/accent">
                  약사 면허증
                </Font>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: 8,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                >
                  <circle
                    cx="10.0002"
                    cy="10.4997"
                    r="9.16667"
                    fill="#4785FF"
                  />
                  <path
                    d="M7.97863 14.6667C7.69246 14.6667 7.44393 14.57 7.23307 14.3767C7.03726 14.1834 6.93936 13.9455 6.93936 13.663C6.93936 13.321 7.08998 13.0088 7.39122 12.7263L9.17606 11.0981C9.83879 10.4885 10.2982 9.9904 10.5542 9.60381C10.8103 9.20235 10.9383 8.80089 10.9383 8.39943C10.9383 8.05745 10.8329 7.78237 10.622 7.57421C10.4262 7.36604 10.1551 7.26196 9.80867 7.26196C9.4773 7.26196 9.20619 7.35861 8.99532 7.55191C8.78445 7.7452 8.64136 8.00541 8.56605 8.33252C8.52087 8.55556 8.4079 8.74142 8.22716 8.89011C8.06148 9.02393 7.85061 9.09084 7.59455 9.09084C7.32344 9.09084 7.09751 9.00162 6.91676 8.8232C6.73602 8.64477 6.65318 8.42917 6.66824 8.1764C6.69837 7.38835 7.01467 6.74899 7.61715 6.25831C8.23469 5.75277 9.00285 5.5 9.92163 5.5C10.8555 5.5 11.5935 5.76764 12.1357 6.30292C12.693 6.82333 12.9717 7.49987 12.9717 8.33252C12.9717 8.85293 12.8361 9.35104 12.565 9.82685C12.309 10.2878 11.9173 10.7785 11.3902 11.2989L10.125 12.5479C9.88398 12.7858 9.93669 12.9047 10.2831 12.9047L12.3391 12.8601C12.6403 12.8452 12.8813 12.927 13.0621 13.1054C13.2428 13.269 13.3332 13.492 13.3332 13.7745C13.3332 14.0422 13.2503 14.2578 13.0846 14.4213C12.919 14.5849 12.7006 14.6667 12.4295 14.6667H7.97863Z"
                    fill="white"
                  />
                </svg>

                <Font fontType="h4" color="fill/accent">
                  약국 사업자등록증
                </Font>
              </div>
            </div>
          </div>

          <div
            style={{
              height: 1,
              width: '100%',
              paddingTop: 30,
              paddingBottom: 30,
            }}
          >
            <div
              style={{
                backgroundColor: COLOR['border/divider'],
                height: 1,
                width: '100%',
              }}
            />
          </div>

          <Button
            width="100%"
            padding="16px 12px"
            title={
              <Font fontType="h4" color="fill/white">
                약사 회원가입 하기
              </Font>
            }
            onClick={onClick}
            backgroundColor={COLOR['fill/accent']}
            borderRadius="12px"
          />
        </>
      }
    />
  );
}

export default SignupModalTemplate;
