import { BColor } from '@components/meraki-ui/BColor';
import { IconDetailProps } from '@components/meraki-ui/BIcon/svg.type';

export default function CheckIcon({ width, height, color }: IconDetailProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.7997 4.79231C15.3031 4.28896 16.1191 4.28896 16.6225 4.79231C17.1197 5.28948 17.1258 6.09177 16.6408 6.59643L9.78002 15.1724C9.77012 15.1848 9.75954 15.1966 9.74833 15.2078C9.24498 15.7111 8.42889 15.7111 7.92554 15.2078L3.37751 10.6598C2.87416 10.1564 2.87416 9.34033 3.37751 8.83698C3.88086 8.33363 4.69695 8.33363 5.2003 8.83698L8.7981 12.4348L14.7655 4.83086C14.7761 4.81734 14.7875 4.80446 14.7997 4.79231Z"
        fill={BColor[color]}
      />
    </svg>
  );
}
