import { BColor } from '@components/meraki-ui/BColor';
import { IconDetailProps } from '@components/meraki-ui/BIcon/svg.type';

export default function MinusIcon({ width, height, color }: IconDetailProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="8" fill="#474747" />
      <rect
        x="6.44434"
        y="9.55566"
        width="7.11111"
        height="0.888889"
        rx="0.444444"
        fill={BColor[color]}
      />
    </svg>
  );
}
