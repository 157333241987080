import { IconDetailProps } from '@components/atoms/images';
import { COLOR } from '../color';

function NoticeIcon({ width, height, color }: IconDetailProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M17 2H7C5.67441 2.00159 4.40356 2.52889 3.46622 3.46625C2.52888 4.40361 2.00159 5.67448 2 7.00011V20.0004C2.00008 20.3959 2.11743 20.7825 2.3372 21.1113C2.55697 21.4401 2.86929 21.6964 3.23469 21.8478C3.60009 21.9991 4.00216 22.0387 4.39006 21.9616C4.77797 21.8845 5.1343 21.694 5.414 21.4144C5.48126 21.3473 5.53851 21.2708 5.584 21.1874L7.21 18.2143C7.38669 18.076 7.60457 18.0006 7.829 18.0003H17C18.3261 18.0003 19.5979 17.4735 20.5355 16.5358C21.4732 15.5981 22 14.3263 22 13.0002V7.00011C21.9984 5.67448 21.4711 4.40361 20.5338 3.46625C19.5964 2.52889 18.3256 2.00159 17 2Z"
        fill={COLOR[color]}
      />
      <path
        d="M12 11.0475C11.7474 11.0475 11.5052 10.9472 11.3266 10.7686C11.1479 10.59 11.0476 10.3478 11.0476 10.0952V7.23803C11.0476 6.98544 11.1479 6.7432 11.3266 6.56459C11.5052 6.38598 11.7474 6.28564 12 6.28564C12.2526 6.28564 12.4948 6.38598 12.6734 6.56459C12.852 6.7432 12.9524 6.98544 12.9524 7.23803V10.0952C12.9524 10.3478 12.852 10.59 12.6734 10.7686C12.4948 10.9472 12.2526 11.0475 12 11.0475Z"
        fill="#EBF5FF"
      />
      <path
        d="M12 13.9048C12.526 13.9048 12.9524 13.4784 12.9524 12.9524C12.9524 12.4264 12.526 12 12 12C11.474 12 11.0476 12.4264 11.0476 12.9524C11.0476 13.4784 11.474 13.9048 12 13.9048Z"
        fill="#EBF5FF"
      />
    </svg>
  );
}

export default NoticeIcon;
