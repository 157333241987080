import { useAppSelector } from '@stores/hooks';
import { selectPharmacyWorkingTimeData } from '@stores/userInformationStore';
import { isPharmacyOpenNow } from '@utils/domain';
import { useEffect, useState } from 'react';

const useWorkingTimeCheck = () => {
  const pharmacist_working_time = useAppSelector(selectPharmacyWorkingTimeData);
  const [isPharmacyOpen, setIsPharmacyOpen] = useState<boolean>(true);

  useEffect(() => {
    const interval = setInterval(() => {
      if (pharmacist_working_time && pharmacist_working_time.length > 0) {
        setIsPharmacyOpen(isPharmacyOpenNow(pharmacist_working_time));
      }
    }, 60 * 1 * 1000);

    if (pharmacist_working_time && pharmacist_working_time.length > 0) {
      setIsPharmacyOpen(isPharmacyOpenNow(pharmacist_working_time));
    }

    return () => clearInterval(interval);
  }, [pharmacist_working_time]);

  return {
    isPharmacyOpen,
  };
};

export default useWorkingTimeCheck;
