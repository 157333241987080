export const prettyPhoneNumberFormatter = (phoneNumber: string | undefined) => {
  let result = phoneNumber;
  if (phoneNumber && phoneNumber.length === 11) {
    result = phoneNumber.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
  } else if (phoneNumber && phoneNumber.length === 10) {
    result = phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
  }
  return result;
};

export const lastFourPhoneNumberFormatter = (
  phoneNumber: string | undefined,
): string => {
  return phoneNumber ? phoneNumber.slice(-4) : '';
};
