import { useEffect, useState } from 'react';
import { MyDoctorModelType } from 'types/index.d';
import { myDoctorAPI } from '@services';

const useFetchCancelReason = ({ type }: { type: 'TM' | 'TP' }) => {
  const [cancelReasonList, setCancelReasonList] = useState<
    MyDoctorModelType.CancelReasonModelType.CancelReasonModelType[]
  >([]);

  useEffect(() => {
    async function fetchCancelReasonData() {
      const cancelReason =
        type === 'TM'
          ? await myDoctorAPI.getTmCancelReason()
          : await myDoctorAPI.getTpCancelReason();
      setCancelReasonList(cancelReason.data.results);
    }
    fetchCancelReasonData();
    return () => setCancelReasonList([]);
  }, [type]);

  return cancelReasonList;
};

export default useFetchCancelReason;
