import styled from 'styled-components';

export const ModalArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const TitleArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ModalBodyArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
`;

export const ModalContentArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
`;

export const ModalFooterArea = styled.div`
  display: flex;
  gap: 12px;
`;
