import { useAppDispatch, useAppSelector } from '@stores/hooks';
import {
  closeModal_customerCenterModal,
  selectCustomerCenterModalVisible,
} from '@stores/modalStore/customerCenterModal';
import { useCallback } from 'react';

const useCustomerCenterModalHook = () => {
  const dispatch = useAppDispatch();
  const isVisible = useAppSelector(selectCustomerCenterModalVisible);

  const closeModal = useCallback(() => {
    dispatch(closeModal_customerCenterModal());
  }, [dispatch]);

  return { isVisible, closeModal };
};

export default useCustomerCenterModalHook;
