import { IconDetailProps } from '@components/atoms/images';
import { COLOR } from '../color';

function AddCircleIcon({ width, height, color }: IconDetailProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99996 15.3333C12.05 15.3333 15.3333 12.0501 15.3333 7.99999C15.3333 3.9499 12.05 0.666656 7.99996 0.666656C3.94987 0.666656 0.666626 3.9499 0.666626 7.99999C0.666626 12.0501 3.94987 15.3333 7.99996 15.3333ZM7.99996 4.16666C8.36815 4.16666 8.66663 4.46513 8.66663 4.83332V7.33332L11.1666 7.33332C11.5348 7.33332 11.8333 7.6318 11.8333 7.99999C11.8333 8.36818 11.5348 8.66666 11.1666 8.66666H8.66663V11.1667C8.66663 11.5348 8.36815 11.8333 7.99996 11.8333C7.63177 11.8333 7.33329 11.5348 7.33329 11.1667V8.66666H4.83329C4.4651 8.66666 4.16663 8.36818 4.16663 7.99999C4.16663 7.6318 4.4651 7.33332 4.83329 7.33332H7.33329L7.33329 4.83332C7.33329 4.46513 7.63177 4.16666 7.99996 4.16666Z"
        fill={COLOR[color]}
      />
    </svg>
  );
}

export default AddCircleIcon;
