import { IconDetailProps } from '@components/atoms/images';
import { COLOR } from '../color';

function CheckCircleIcon({ width, height, color }: IconDetailProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99999 15.3332C12.0501 15.3332 15.3333 12.0499 15.3333 7.99984C15.3333 3.94975 12.0501 0.666504 7.99999 0.666504C3.9499 0.666504 0.666656 3.94975 0.666656 7.99984C0.666656 12.0499 3.9499 15.3332 7.99999 15.3332ZM11.5017 6.27218C11.7442 5.99509 11.7161 5.57392 11.439 5.33146C11.1619 5.08901 10.7407 5.11709 10.4983 5.39418L7.0575 9.32649L5.49733 7.57874C5.25214 7.30407 4.83071 7.28017 4.55604 7.52536C4.28137 7.77056 4.25747 8.19199 4.50266 8.46666L6.56516 10.7771C6.69241 10.9197 6.87472 11.0008 7.0658 10.9998C7.25689 10.9989 7.43838 10.916 7.56421 10.7722L11.5017 6.27218Z"
        fill={COLOR[color]}
      />
    </svg>
  );
}

export default CheckCircleIcon;
