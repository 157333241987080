import { Font } from '@components/atoms';
import { commonCode } from '@constants';
import moment from 'moment';
import { TelepharmacyType } from 'types/index.d';
import { StatusTagStyleType } from './index.d';
import { StatusTagContainer } from './index.style';

const { COMMON_CODE } = commonCode;

function StatusTag({
  status,
  item,
}: {
  status?: string;
  item: TelepharmacyType.TelepharmacyItemType;
}) {
  const quickPickEstimatedTime = item.tp_delivery?.quick_pick_estimated_time
    ? moment(item.tp_delivery?.quick_pick_estimated_time).unix()
    : moment().unix();

  const waitingMinute = item.tp_delivery?.quick_pick_estimated_time
    ? parseInt(String((quickPickEstimatedTime - moment().unix()) / 60 + 1), 10)
    : '-';

  const data: StatusTagStyleType = {
    title: '',
    subTitle: '',
    borderColor: 'state/success',
    backgroundColor: 'state/success',
    titleColor: 'fill/white',
  };
  switch (status) {
    case COMMON_CODE.DELIVERY_STATUS.REQUESTED:
      data.title = '기사님 연결 중';
      data.backgroundColor = 'fill/white';
      data.borderColor = 'fill/white';
      data.titleColor = 'fill/dark';
      break;
    case COMMON_CODE.DELIVERY_STATUS.DEFAULT:
      data.title = '기사님 연결 중';
      data.backgroundColor = 'fill/white';
      data.borderColor = 'fill/white';
      data.titleColor = 'fill/dark';
      break;
    case COMMON_CODE.DELIVERY_STATUS.REJECTED:
      data.title = '기사님 연결 중';
      data.subTitle = '(연결지연)';
      data.backgroundColor = 'fill/white';
      data.borderColor = 'fill/white';
      data.titleColor = 'fill/dark';
      break;
    case COMMON_CODE.DELIVERY_STATUS.STARTED:
      data.title = `${waitingMinute}분 뒤 방문예정`;
      data.backgroundColor = 'fill/white';
      data.borderColor = 'fill/white';
      data.titleColor = 'fill/dark';
      break;
    default:
      data.title = '-';
      data.backgroundColor = 'fill/white';
      data.borderColor = 'fill/white';
      data.titleColor = 'fill/dark';
      break;
  }

  return (
    <StatusTagContainer data={data}>
      <Font fontType="body2_medium" color={data.titleColor}>
        {data.title}
        <br />
        {item.delivery_info?.quick_picker_phone || ''}
      </Font>

      {data.subTitle && data.subTitle.length && (
        <Font fontType="h5" color={data.titleColor}>
          {data.subTitle}
        </Font>
      )}
    </StatusTagContainer>
  );
}

export default StatusTag;
