import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '@stores';

interface InitialStateType {
  isVisible: boolean;
  targetMedicine: null | 'reject' | 'auto-reject';
}

const initialState: InitialStateType = {
  isVisible: false,
  targetMedicine: null,
};

export const medicineSoldOutAlertModal = createSlice({
  name: 'modal/medicineSoldOutAlertModalStore',
  initialState,
  reducers: {
    openModal_medicineSoldOutAlertModal: (state) => {
      state.isVisible = true;
    },
    closeModal_medicineSoldOutAlertModal: (state) => {
      state.isVisible = false;
    },
    set_targetMedicine_medicineSoldOutAlertModal: (state, action) => {
      state.targetMedicine = action.payload;
    },
  },
});

export const {
  openModal_medicineSoldOutAlertModal,
  closeModal_medicineSoldOutAlertModal,
  set_targetMedicine_medicineSoldOutAlertModal,
} = medicineSoldOutAlertModal.actions;

export const selectMedicineSoldOutAlertModalVisible = (state: RootState) =>
  state.medicineSoldOutAlertModal.isVisible;

export const selectMedicineSoldOutAlertModalTargetMedicine = (
  state: RootState,
) => state.medicineSoldOutAlertModal.targetMedicine;

export default medicineSoldOutAlertModal.reducer;
