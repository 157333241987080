import { ReactBootstrapIcons } from '@assets';
import { COLOR, Font } from '@components/atoms';
import SvgIcon from '@components/atoms/images';
import { commonCode } from '@constants';
import { ProgressStatusType } from './index.d';
import {
  Container,
  ProgressStatusArea,
  ProgressStatusBar,
  ProgressStatusBarArea,
  ProgressStatusContainer,
  ProgressStatusIconArea,
  ProgressStatusTextArea,
} from './index.style';

const { COMMON_CODE } = commonCode;

export const getTpStatusText = ({
  tpStatus,
  deliveryStatus,
  paymentStatus,
}: ProgressStatusType) => {
  let statusText = TP_FLOW_STATUS_CODE.TP_REQUESTED;

  if (tpStatus === COMMON_CODE.TP_STATUS.REQUESTED) {
    statusText = TP_FLOW_STATUS_CODE.TP_REQUESTED;
  } else if (
    tpStatus === COMMON_CODE.TP_STATUS.ACCEPTED ||
    tpStatus === COMMON_CODE.TP_STATUS.PRE_CONFIRMED
  ) {
    statusText = TP_FLOW_STATUS_CODE.TP_ACCEPTED;
  } else if (tpStatus === COMMON_CODE.TP_STATUS.CONFIRMED) {
    statusText = TP_FLOW_STATUS_CODE.TP_CONFIRMED;
  } else if (tpStatus === COMMON_CODE.TP_STATUS.REJECTED) {
    statusText = TP_FLOW_STATUS_CODE.TP_REJECTED;
  } else if (tpStatus === COMMON_CODE.TP_STATUS.P_CANCEL) {
    statusText = TP_FLOW_STATUS_CODE.TP_P_CANCEL;
  } else if (tpStatus === COMMON_CODE.TP_STATUS.PH_CANCEL) {
    statusText = TP_FLOW_STATUS_CODE.TP_PH_CANCEL;
  } else if (tpStatus === COMMON_CODE.TP_STATUS.FINISHED) {
    if (
      deliveryStatus === COMMON_CODE.DELIVERY_STATUS.REQUESTED ||
      deliveryStatus === COMMON_CODE.DELIVERY_STATUS.DEPATCHED ||
      deliveryStatus === COMMON_CODE.DELIVERY_STATUS.DEFAULT
    ) {
      statusText = TP_FLOW_STATUS_CODE.DELIVERY_REQUESTED;
    } else if (deliveryStatus === COMMON_CODE.DELIVERY_STATUS.COLLECTED) {
      statusText = TP_FLOW_STATUS_CODE.DELIVERY_COLLECTED;
    } else if (deliveryStatus === COMMON_CODE.DELIVERY_STATUS.STARTED) {
      statusText = TP_FLOW_STATUS_CODE.DELIVERY_STARTED;
    } else if (deliveryStatus === COMMON_CODE.DELIVERY_STATUS.FINISHED) {
      statusText = TP_FLOW_STATUS_CODE.DELIVERY_FINISHED;
    } else if (deliveryStatus === COMMON_CODE.DELIVERY_STATUS.REJECTED) {
      statusText = TP_FLOW_STATUS_CODE.DELIVERY_REJECTED;
    } else if (deliveryStatus === COMMON_CODE.DELIVERY_STATUS.CANCEL) {
      statusText = TP_FLOW_STATUS_CODE.DELIVERY_CANCEL;
    } else if (
      deliveryStatus === COMMON_CODE.DELIVERY_STATUS.PICKUP_REQUESTED
    ) {
      statusText = TP_FLOW_STATUS_CODE.DELIVERY_REQUESTED;
    } else if (deliveryStatus === COMMON_CODE.DELIVERY_STATUS.PICKUP_FINISHED) {
      statusText = TP_FLOW_STATUS_CODE.DELIVERY_FINISHED;
    }
  }

  // 결제 실패 및 취소 시
  if (paymentStatus === COMMON_CODE.PAYMENT_STATUS.FAILED) {
    statusText = TP_FLOW_STATUS_CODE.PAYMENT_FAILED;
  } else if (paymentStatus === COMMON_CODE.PAYMENT_STATUS.CANCELED) {
    statusText = TP_FLOW_STATUS_CODE.PAYMENT_CANCELED;
  }

  return statusText;
};

export const TP_FLOW_STATUS_CODE: Record<string, string> = {
  TP_REQUESTED: '접수대기',
  TP_ACCEPTED: '결제대기',
  TP_CONFIRMED: '조제대기',
  TP_REJECTED: '접수취소',
  TP_P_CANCEL: '환자취소',
  TP_PH_CANCEL: '조제취소',
  DELIVERY_REQUESTED: '수거대기',
  DELIVERY_STARTED: '수거대기',
  DELIVERY_COLLECTED: '배송 중',
  DELIVERY_FINISHED: '배송완료',
  DELIVERY_REJECTED: '배송지연',
  DELIVERY_CANCEL: '배송취소',
  PAYMENT_FAILED: '결제실패',
  PAYMENT_CANCELED: '결제취소',
  PICKUP_FINISHED: '수령완료',
  PICKUP_REQUESTED: '수령 대기',
}; // TODO 취소, 거절 => 빨간  / 대기, ~즁, 지연 => `fill/dark` / 완료 => 초록

function ProgressStatus({
  tpStatus,
  deliveryStatus,
  paymentStatus,
}: ProgressStatusType) {
  // 추후 접수취소, 결제취소, 조제취소 관련된 status 논의 필요
  // 배송장애, 배송취소, 배송거절 고려 필요
  // 수거완료와 배송시작일 때 look 고려 필요
  const statusText = getTpStatusText({
    tpStatus,
    deliveryStatus,
    paymentStatus,
  });

  return (
    <Container>
      <ProgressStatusContainer>
        <ProgressStatusArea>
          <ProgressStatusIconArea>
            {statusText === TP_FLOW_STATUS_CODE.TP_REQUESTED ? (
              <ReactBootstrapIcons.CircleFill
                size={16}
                color={COLOR['state/success']}
              />
            ) : statusText === TP_FLOW_STATUS_CODE.TP_REJECTED ? (
              <SvgIcon
                width={16}
                height={16}
                icon="xLg"
                color="state/distructive"
              />
            ) : statusText === TP_FLOW_STATUS_CODE.TP_PH_CANCEL ||
              statusText === TP_FLOW_STATUS_CODE.TP_P_CANCEL ||
              statusText === TP_FLOW_STATUS_CODE.DELIVERY_CANCEL ||
              statusText === TP_FLOW_STATUS_CODE.PAYMENT_FAILED ||
              statusText === TP_FLOW_STATUS_CODE.PAYMENT_CANCELED ? (
              <SvgIcon
                width={16}
                height={16}
                icon="check_large"
                color="state/distructive"
              />
            ) : (
              <SvgIcon
                width={16}
                height={16}
                icon="check_large"
                color="state/success"
              />
            )}
          </ProgressStatusIconArea>
          {/* 접수 */}
          <ProgressStatusTextArea>
            {statusText === TP_FLOW_STATUS_CODE.TP_REQUESTED ? (
              <Font fontType="body2" color="state/success">
                접수대기
              </Font>
            ) : statusText === TP_FLOW_STATUS_CODE.TP_REJECTED ? (
              <Font fontType="body2" color="state/distructive">
                접수취소
              </Font>
            ) : statusText === TP_FLOW_STATUS_CODE.TP_PH_CANCEL ||
              statusText === TP_FLOW_STATUS_CODE.TP_P_CANCEL ||
              statusText === TP_FLOW_STATUS_CODE.DELIVERY_CANCEL ? (
              <Font fontType="body2" color="fill/black">
                접수대기
              </Font>
            ) : statusText === TP_FLOW_STATUS_CODE.PAYMENT_FAILED ||
              statusText === TP_FLOW_STATUS_CODE.PAYMENT_CANCELED ? (
              <Font fontType="body2" color="fill/black">
                접수완료
              </Font>
            ) : (
              <Font fontType="body2" color="state/success">
                접수완료
              </Font>
            )}
          </ProgressStatusTextArea>
        </ProgressStatusArea>
        <ProgressStatusBarArea>
          {statusText === TP_FLOW_STATUS_CODE.TP_REQUESTED ||
          statusText === TP_FLOW_STATUS_CODE.TP_REJECTED ? (
            <ProgressStatusBar borderColor="disabled/foreground" />
          ) : statusText === TP_FLOW_STATUS_CODE.TP_PH_CANCEL ||
            statusText === TP_FLOW_STATUS_CODE.TP_P_CANCEL ||
            statusText === TP_FLOW_STATUS_CODE.DELIVERY_CANCEL ||
            statusText === TP_FLOW_STATUS_CODE.PAYMENT_FAILED ||
            statusText === TP_FLOW_STATUS_CODE.PAYMENT_CANCELED ? (
            <ProgressStatusBar borderColor="state/distructive" />
          ) : (
            <ProgressStatusBar />
          )}
        </ProgressStatusBarArea>
        {/* 결제 */}
        <ProgressStatusArea>
          <ProgressStatusIconArea>
            {statusText === TP_FLOW_STATUS_CODE.TP_REQUESTED ||
            statusText === TP_FLOW_STATUS_CODE.TP_REJECTED ? (
              <ReactBootstrapIcons.CircleFill
                size={16}
                color={COLOR['disabled/foreground']}
              />
            ) : statusText === TP_FLOW_STATUS_CODE.TP_PH_CANCEL ? (
              <SvgIcon
                width={16}
                height={16}
                icon="xLg"
                color="state/distructive"
              />
            ) : statusText === TP_FLOW_STATUS_CODE.TP_P_CANCEL ||
              statusText === TP_FLOW_STATUS_CODE.DELIVERY_CANCEL ||
              statusText === TP_FLOW_STATUS_CODE.PAYMENT_FAILED ||
              statusText === TP_FLOW_STATUS_CODE.PAYMENT_CANCELED ? (
              <SvgIcon
                width={16}
                height={16}
                icon="check_large"
                color="state/distructive"
              />
            ) : statusText === TP_FLOW_STATUS_CODE.TP_ACCEPTED ? (
              <ReactBootstrapIcons.CircleFill
                size={16}
                color={COLOR['state/success']}
              />
            ) : (
              <SvgIcon
                width={16}
                height={16}
                icon="check_large"
                color="state/success"
              />
            )}
          </ProgressStatusIconArea>
          <ProgressStatusTextArea>
            {statusText === TP_FLOW_STATUS_CODE.TP_REQUESTED ||
            statusText === TP_FLOW_STATUS_CODE.TP_REJECTED ? (
              <Font fontType="body3" color="disabled/foreground">
                결제완료
              </Font>
            ) : statusText === TP_FLOW_STATUS_CODE.TP_PH_CANCEL ||
              statusText === TP_FLOW_STATUS_CODE.DELIVERY_CANCEL ? (
              <Font fontType="body2" color="fill/black">
                결제완료
              </Font>
            ) : statusText === TP_FLOW_STATUS_CODE.TP_P_CANCEL ? (
              <Font fontType="body2" color="state/distructive">
                환자취소
              </Font>
            ) : statusText === TP_FLOW_STATUS_CODE.PAYMENT_CANCELED ? (
              <Font fontType="body2" color="state/distructive">
                결제취소
              </Font>
            ) : statusText === TP_FLOW_STATUS_CODE.TP_ACCEPTED ? (
              <Font fontType="body2" color="state/success">
                결제대기
              </Font>
            ) : statusText === TP_FLOW_STATUS_CODE.PAYMENT_FAILED ? (
              <Font fontType="body2" color="state/distructive">
                결제실패
              </Font>
            ) : (
              <Font fontType="body2" color="state/success">
                결제완료
              </Font>
            )}
          </ProgressStatusTextArea>
        </ProgressStatusArea>
        <ProgressStatusBarArea>
          {statusText === TP_FLOW_STATUS_CODE.TP_REQUESTED ||
          statusText === TP_FLOW_STATUS_CODE.TP_REJECTED ||
          statusText === TP_FLOW_STATUS_CODE.TP_ACCEPTED ||
          statusText === TP_FLOW_STATUS_CODE.TP_P_CANCEL ||
          statusText === TP_FLOW_STATUS_CODE.PAYMENT_FAILED ||
          statusText === TP_FLOW_STATUS_CODE.PAYMENT_CANCELED ? (
            <ProgressStatusBar borderColor="disabled/foreground" />
          ) : statusText === TP_FLOW_STATUS_CODE.TP_PH_CANCEL ||
            statusText === TP_FLOW_STATUS_CODE.DELIVERY_CANCEL ? (
            <ProgressStatusBar borderColor="state/distructive" />
          ) : (
            <ProgressStatusBar />
          )}
        </ProgressStatusBarArea>
        {/* 조제 */}
        <ProgressStatusArea>
          <ProgressStatusIconArea>
            {statusText === TP_FLOW_STATUS_CODE.TP_REQUESTED ||
            statusText === TP_FLOW_STATUS_CODE.TP_ACCEPTED ||
            statusText === TP_FLOW_STATUS_CODE.TP_REJECTED ||
            statusText === TP_FLOW_STATUS_CODE.TP_P_CANCEL ||
            statusText === TP_FLOW_STATUS_CODE.PAYMENT_FAILED ||
            statusText === TP_FLOW_STATUS_CODE.PAYMENT_CANCELED ? (
              <ReactBootstrapIcons.CircleFill
                size={14}
                color={COLOR['disabled/foreground']}
              />
            ) : statusText === TP_FLOW_STATUS_CODE.TP_CONFIRMED ? (
              <ReactBootstrapIcons.CircleFill
                size={16}
                color={COLOR['state/success']}
              />
            ) : statusText === TP_FLOW_STATUS_CODE.TP_PH_CANCEL ? (
              <SvgIcon
                width={16}
                height={16}
                icon="xLg"
                color="state/distructive"
              />
            ) : statusText === TP_FLOW_STATUS_CODE.DELIVERY_CANCEL ? (
              <SvgIcon
                width={16}
                height={16}
                icon="check_large"
                color="state/distructive"
              />
            ) : (
              <SvgIcon
                width={16}
                height={16}
                icon="check_large"
                color="state/success"
              />
            )}
          </ProgressStatusIconArea>
          <ProgressStatusTextArea>
            {statusText === TP_FLOW_STATUS_CODE.TP_REQUESTED ||
            statusText === TP_FLOW_STATUS_CODE.TP_REJECTED ||
            statusText === TP_FLOW_STATUS_CODE.TP_ACCEPTED ||
            statusText === TP_FLOW_STATUS_CODE.TP_P_CANCEL ||
            statusText === TP_FLOW_STATUS_CODE.PAYMENT_FAILED ||
            statusText === TP_FLOW_STATUS_CODE.PAYMENT_CANCELED ? (
              <Font fontType="body2" color="disabled/foreground">
                조제완료
              </Font>
            ) : statusText === TP_FLOW_STATUS_CODE.TP_PH_CANCEL ? (
              <Font fontType="body2" color="state/distructive">
                조제취소
              </Font>
            ) : statusText === TP_FLOW_STATUS_CODE.TP_CONFIRMED ? (
              <Font fontType="body2" color="state/success">
                조제대기
              </Font>
            ) : statusText === TP_FLOW_STATUS_CODE.DELIVERY_CANCEL ? (
              <Font fontType="body2" color="fill/black">
                조제완료
              </Font>
            ) : (
              <Font fontType="body2" color="state/success">
                조제완료
              </Font>
            )}
          </ProgressStatusTextArea>
        </ProgressStatusArea>
        <ProgressStatusBarArea>
          {statusText === TP_FLOW_STATUS_CODE.TP_REQUESTED ||
          statusText === TP_FLOW_STATUS_CODE.TP_REJECTED ||
          statusText === TP_FLOW_STATUS_CODE.TP_ACCEPTED ||
          statusText === TP_FLOW_STATUS_CODE.TP_CONFIRMED ||
          statusText === TP_FLOW_STATUS_CODE.TP_P_CANCEL ||
          statusText === TP_FLOW_STATUS_CODE.TP_PH_CANCEL ||
          statusText === TP_FLOW_STATUS_CODE.PAYMENT_FAILED ||
          statusText === TP_FLOW_STATUS_CODE.PAYMENT_CANCELED ? (
            <ProgressStatusBar borderColor="disabled/foreground" />
          ) : statusText === TP_FLOW_STATUS_CODE.DELIVERY_CANCEL ? (
            <ProgressStatusBar borderColor="state/distructive" />
          ) : (
            <ProgressStatusBar />
          )}
        </ProgressStatusBarArea>
        {/* 수거 */}
        <ProgressStatusArea>
          <ProgressStatusIconArea>
            {statusText === TP_FLOW_STATUS_CODE.TP_REQUESTED ||
            statusText === TP_FLOW_STATUS_CODE.TP_ACCEPTED ||
            statusText === TP_FLOW_STATUS_CODE.TP_CONFIRMED ||
            statusText === TP_FLOW_STATUS_CODE.TP_REJECTED ||
            statusText === TP_FLOW_STATUS_CODE.TP_P_CANCEL ||
            statusText === TP_FLOW_STATUS_CODE.TP_PH_CANCEL ||
            statusText === TP_FLOW_STATUS_CODE.PAYMENT_FAILED ||
            statusText === TP_FLOW_STATUS_CODE.PAYMENT_CANCELED ? (
              <ReactBootstrapIcons.CircleFill
                size={16}
                color={COLOR['disabled/foreground']}
              />
            ) : statusText === TP_FLOW_STATUS_CODE.DELIVERY_REQUESTED ||
              statusText === TP_FLOW_STATUS_CODE.DELIVERY_STARTED ||
              statusText === TP_FLOW_STATUS_CODE.DELIVERY_REJECTED ? (
              <ReactBootstrapIcons.CircleFill
                size={16}
                color={COLOR['state/success']}
              />
            ) : statusText === TP_FLOW_STATUS_CODE.DELIVERY_CANCEL ? (
              <SvgIcon
                width={16}
                height={16}
                icon="xLg"
                color="state/distructive"
              />
            ) : (
              <SvgIcon
                width={16}
                height={16}
                icon="check_large"
                color="state/success"
              />
            )}
          </ProgressStatusIconArea>
          <ProgressStatusTextArea>
            {statusText === TP_FLOW_STATUS_CODE.TP_REQUESTED ||
            statusText === TP_FLOW_STATUS_CODE.TP_REJECTED ||
            statusText === TP_FLOW_STATUS_CODE.TP_ACCEPTED ||
            statusText === TP_FLOW_STATUS_CODE.TP_CONFIRMED ||
            statusText === TP_FLOW_STATUS_CODE.TP_P_CANCEL ||
            statusText === TP_FLOW_STATUS_CODE.TP_PH_CANCEL ||
            statusText === TP_FLOW_STATUS_CODE.PAYMENT_FAILED ||
            statusText === TP_FLOW_STATUS_CODE.PAYMENT_CANCELED ? (
              <Font fontType="body2" color="disabled/foreground">
                수거완료
              </Font>
            ) : statusText === TP_FLOW_STATUS_CODE.DELIVERY_CANCEL ? (
              <Font fontType="body2" color="state/distructive">
                배송취소
              </Font>
            ) : statusText === TP_FLOW_STATUS_CODE.DELIVERY_REQUESTED ||
              statusText === TP_FLOW_STATUS_CODE.DELIVERY_STARTED ||
              statusText === TP_FLOW_STATUS_CODE.DELIVERY_REJECTED ? (
              <Font fontType="body2" color="state/success">
                수령대기
              </Font>
            ) : (
              <Font fontType="body2" color="state/success">
                수령완료
              </Font>
            )}
          </ProgressStatusTextArea>
        </ProgressStatusArea>
        {/* 배송 */}
        {/* {deliveryStatus !== COMMON_CODE.DELIVERY_STATUS.DEFAULT && (
          <>
            <ProgressStatusBarArea>
              {statusText === TP_FLOW_STATUS_CODE.DELIVERY_COLLECTED ||
              statusText === TP_FLOW_STATUS_CODE.DELIVERY_FINISHED ? (
                <ProgressStatusBar />
              ) : (
                <ProgressStatusBar borderColor={COLOR['disabled/foreground']} />
              )}
            </ProgressStatusBarArea>
            <ProgressStatusArea>
              <ProgressStatusIconArea>
                {statusText === TP_FLOW_STATUS_CODE.DELIVERY_FINISHED ? (
                  <SvgIcon
                    width={16}
                    height={16}
                    icon="check_large"
                    color="bs_teal"
                  />
                ) : statusText === TP_FLOW_STATUS_CODE.DELIVERY_COLLECTED ? (
                  <ReactBootstrapIcons.CircleFill
                    size={16}
                    color={COLOR['state/success']}
                  />
                ) : (
                  <ReactBootstrapIcons.CircleFill
                    size={16}
                    color={COLOR['disabled/foreground']}
                  />
                )}
              </ProgressStatusIconArea>
              <ProgressStatusTextArea>
                {statusText === TP_FLOW_STATUS_CODE.DELIVERY_FINISHED ? (
                  <Font fontType="body3" color={COLOR['state/success']}>
                    배송완료
                  </Font>
                ) : statusText === TP_FLOW_STATUS_CODE.DELIVERY_COLLECTED ? (
                  <Font fontType="body3" color={COLOR['state/success']}>
                    배송 중
                  </Font>
                ) : (
                  <Font fontType="body3" color={COLOR['disabled/foreground']}>
                    배송완료
                  </Font>
                )}
              </ProgressStatusTextArea>
            </ProgressStatusArea>
          </>
        )} */}
      </ProgressStatusContainer>
    </Container>
  );
}

export default ProgressStatus;
