import * as Style from './index.style';

interface ToggleButtonProps {
  isActive: boolean;
  onClickToggle: (e?: React.MouseEvent) => void;
}

function ToggleButton({ isActive, onClickToggle }: ToggleButtonProps) {
  return (
    <Style.ToggleWrapper isActive={isActive} onClick={(e) => onClickToggle(e)}>
      <Style.ToggleCircle isActive={isActive} />
    </Style.ToggleWrapper>
  );
}

export default ToggleButton;
