import { Button, COLOR, Font } from '@components/atoms';
import SvgIcon from '@components/atoms/images';
import { medicineGenericCodeKoreanUnit } from '@components/organisms/managementMedicineTable/config/domain';
import { ManagedMedicineItemType } from '@services/myDoctor/types';
import { useEffect, useRef } from 'react';
import { SelectedMedicineListFilteredByMasterCode } from '../..';
import * as Style from './index.style';

function formatNumberWithCommas(value: string) {
  if (!value) return '';
  return new Intl.NumberFormat().format(Number(value));
}

interface MedicineUnitPriceProps {
  isMedicineSumInputAreaFocus: boolean;
  selectedMedicine: ManagedMedicineItemType | null;
  selectMedicineUnits: string[];
  medicinePriceRowList: SelectedMedicineListFilteredByMasterCode[];
  selectedMedicineListFilteredByMasterCode: SelectedMedicineListFilteredByMasterCode[];
  handleClickMedicineUnit: (
    medicinePriceRow: SelectedMedicineListFilteredByMasterCode,
    selectedUnit: string,
  ) => void;
  handleClickUnitDropDownOpen: (id: number) => void;
  handleChangeMedicineSumInput: (
    e: React.ChangeEvent<HTMLInputElement>,
    medicinePriceRow: SelectedMedicineListFilteredByMasterCode,
  ) => void;
  handleAddMedicinePriceEachUnitRow: () => void;
  handleDeleteMedicinePriceEachUnitRow: (
    deletedMedicinePriceRow: SelectedMedicineListFilteredByMasterCode,
  ) => void;
}

function MedicineUnitPrice({
  isMedicineSumInputAreaFocus,
  selectedMedicine,
  selectMedicineUnits,
  medicinePriceRowList,
  selectedMedicineListFilteredByMasterCode,
  handleClickMedicineUnit,
  handleClickUnitDropDownOpen,
  handleChangeMedicineSumInput,
  handleAddMedicinePriceEachUnitRow,
  handleDeleteMedicinePriceEachUnitRow,
}: MedicineUnitPriceProps) {
  const unitDropDownRef = useRef<Array<HTMLDivElement | null>>([]);
  const medicineSumInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isMedicineSumInputAreaFocus) {
      medicineSumInputRef.current?.focus();
    }
  }, [isMedicineSumInputAreaFocus]);

  return (
    <Style.ModalBodyContentArea>
      <Style.SubTitleArea>
        <Style.SubTitleLeftArea>
          <Font fontType="body1_medium" color="fill/black">
            처방 단위당 금액
          </Font>
          <Style.RequiredUI>
            <Font fontType="body3_medium" color="blue500">
              필수
            </Font>
          </Style.RequiredUI>
        </Style.SubTitleLeftArea>
        {selectedMedicineListFilteredByMasterCode.length !==
          medicinePriceRowList.length && (
          <Button
            padding="9px 16px 9px 12px"
            backgroundColor={COLOR['label/lightBlue']}
            borderRadius="8px"
            onClick={handleAddMedicinePriceEachUnitRow}
            title={
              <Style.UnitAddButtonArea>
                <SvgIcon
                  icon="addCircleIcon"
                  width={16}
                  height={16}
                  color="fill/accent"
                />
                <Font fontType="body2_medium" color="fill/accent">
                  단위 추가
                </Font>
              </Style.UnitAddButtonArea>
            }
          />
        )}
      </Style.SubTitleArea>

      {medicinePriceRowList.length > 0 ? (
        medicinePriceRowList.map((medicinePriceRow, idx) => {
          const {
            id,
            price,
            isDropDownOpen,
            isAlreadyExist,
            product_total_quantity,
          } = medicinePriceRow;

          return (
            <Style.MedicinePriceEachUnitRow key={id}>
              <Style.UnitDropDownArea>
                <Style.MedicineUnitInputArea
                  isDisabled={isAlreadyExist}
                  ref={(el) => {
                    unitDropDownRef.current[idx] = el;
                  }}
                  onClick={() => {
                    if (isAlreadyExist) return;
                    handleClickUnitDropDownOpen(id);
                  }}
                >
                  {selectedMedicine && (
                    <Font
                      fontType="body1"
                      color={
                        isAlreadyExist ? 'disabled/foreground' : 'fill/dark'
                      }
                    >
                      {`${product_total_quantity}${
                        medicineGenericCodeKoreanUnit[
                          medicinePriceRow.generic_name_code
                        ]
                      }`}
                    </Font>
                  )}
                  <SvgIcon
                    icon="chevronDown"
                    width={20}
                    height={20}
                    color={isAlreadyExist ? 'disabled/foreground' : 'fill/dark'}
                  />
                </Style.MedicineUnitInputArea>
                {isDropDownOpen &&
                  selectedMedicine &&
                  selectMedicineUnits.length > 0 && (
                    <Style.UnitDropDown
                      isScroll={selectMedicineUnits.length > 4}
                    >
                      {selectMedicineUnits
                        .sort((a, b) => Number(a) - Number(b))
                        .map((selectMedicineUnit) => {
                          return (
                            <Style.UnitArea
                              key={selectMedicineUnit}
                              onClick={() => {
                                handleClickMedicineUnit(
                                  medicinePriceRow,
                                  selectMedicineUnit,
                                );
                              }}
                            >
                              <Font
                                fontType="body1"
                                color="fill/dark"
                                hoverColor="fill/accent"
                              >
                                {`${selectMedicineUnit}${
                                  medicineGenericCodeKoreanUnit[
                                    selectedMedicine.generic_name_code
                                  ]
                                }`}
                              </Font>
                            </Style.UnitArea>
                          );
                        })}
                    </Style.UnitDropDown>
                  )}
              </Style.UnitDropDownArea>
              <Style.MedicineSumInputArea
                isFocus={isMedicineSumInputAreaFocus}
                isDisabled={isAlreadyExist}
              >
                <Style.MedicineSearchInput
                  placeholder="숫자만 입력해주세요"
                  ref={medicineSumInputRef}
                  value={formatNumberWithCommas(price ? String(price) : '')}
                  onChange={(e) =>
                    handleChangeMedicineSumInput(e, medicinePriceRow)
                  }
                  disabled={isAlreadyExist}
                />
              </Style.MedicineSumInputArea>
              <Font fontType="body1_medium" color="fill/black">
                원
              </Font>
              {isAlreadyExist ? (
                <Style.EmptyArea />
              ) : (
                <Style.MedicinePriceEachUnitRowDeleteButtonArea
                  onClick={() =>
                    handleDeleteMedicinePriceEachUnitRow(medicinePriceRow)
                  }
                >
                  <SvgIcon
                    icon="closeCircle"
                    width={24}
                    height={24}
                    color="fill/medium"
                  />
                </Style.MedicinePriceEachUnitRowDeleteButtonArea>
              )}
            </Style.MedicinePriceEachUnitRow>
          );
        })
      ) : (
        <Style.EmptyAddMedicineUnitArea>
          <Font fontType="body2_medium" color="fill/medium">
            금액을 입력할 처방 단위를 추가해주세요
          </Font>
        </Style.EmptyAddMedicineUnitArea>
      )}
    </Style.ModalBodyContentArea>
  );
}

export default MedicineUnitPrice;
