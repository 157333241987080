import { GetManagedMedicineItemType } from '@services/myDoctor/types';
import { useAppDispatch, useAppSelector } from '@stores/hooks';
import {
  closeModal_medicineManagementEditModal,
  openModal_medicineManagementEditModal,
  selectMedicineManagementEditModalTargetMedicine,
  selectMedicineManagementEditModalVisible,
  set_targetMedicine_medicineManagementEditModal,
} from '@stores/modalStore/medicineManagementEditModal';
import { useCallback } from 'react';

const useMedicineManagementEditModalHook = () => {
  const dispatch = useAppDispatch();
  const isVisible = useAppSelector(selectMedicineManagementEditModalVisible);
  const targetMedicine = useAppSelector(
    selectMedicineManagementEditModalTargetMedicine,
  );

  const closeModal = useCallback(() => {
    dispatch(closeModal_medicineManagementEditModal());
  }, [dispatch]);

  const openModal = useCallback(() => {
    dispatch(openModal_medicineManagementEditModal());
  }, [dispatch]);

  const setManagementMedicineTargetMedicine = (
    medicine: GetManagedMedicineItemType,
  ) => {
    dispatch(set_targetMedicine_medicineManagementEditModal(medicine));
  };

  return {
    isVisible,
    targetMedicine,
    closeModal,
    openModal,
    setManagementMedicineTargetMedicine,
  };
};

export default useMedicineManagementEditModalHook;
