import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: calc(100vw - 20px);
  margin-bottom: 40px;
`;

export const CardArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
`;
