import { COLOR } from '@components/atoms';
import styled from 'styled-components';

export const ModalBodyContentArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const MedicineCategoryButtonsArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

export const SubTitleArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const RequiredUI = styled.div`
  padding: 2px 8px;

  border-radius: 4px;
  background-color: ${COLOR['label/lightBlue']};
`;

export const SubTitleLeftArea = styled.div`
  display: flex;
  gap: 6px;
`;

export const MedicineSelectedButtonArea = styled.div`
  display: flex;
  gap: 4px;
`;
