import { Font } from '@components/atoms';
import { ReactNode } from 'react';
import styled from 'styled-components';

export default function ContentItem({
  title,
  children,
}: {
  title: string;
  children: ReactNode;
}) {
  return (
    <ContentItemContainer>
      <FontContainer>
        <Font fontType="h4" noWhiteSpace>
          {title}
        </Font>
      </FontContainer>

      {children}
    </ContentItemContainer>
  );
}

const ContentItemContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 0 10px;
  gap: 1.25rem;
`;

const FontContainer = styled.div`
  flex-shrink: 0;
  width: 100px;
`;
