import { COLOR } from '@components/atoms/color';
import * as Style from './index.style';

function NudgeComponent({ required }: { required: boolean }) {
  return (
    <Style.Container
      backgroundColor={
        required ? COLOR['label/lightBlue'] : COLOR['fill/light']
      }
      color={required ? COLOR['fill/accent'] : COLOR['fill/dark']}
    >
      {required ? '필수' : '선택'}
    </Style.Container>
  );
}

export default NudgeComponent;
