import { BColor } from '@components/meraki-ui/BColor';
import { IconDetailProps } from '@components/meraki-ui/BIcon/svg.type';

export default function CalendarCheckIcon({
  width,
  height,
  color,
}: IconDetailProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.8536 9.14645C13.0488 9.34171 13.0488 9.65829 12.8536 9.85355L9.85355 12.8536C9.75979 12.9473 9.63261 13 9.5 13C9.36739 13 9.24021 12.9473 9.14645 12.8536L7.64645 11.3536C7.45118 11.1583 7.45118 10.8417 7.64645 10.6464C7.84171 10.4512 8.15829 10.4512 8.35355 10.6464L9.5 11.7929L12.1464 9.14645C12.3417 8.95118 12.6583 8.95118 12.8536 9.14645Z"
        fill={BColor[color]}
      />
      <path
        d="M5.5 2C5.77614 2 6 2.22386 6 2.5V3H14V2.5C14 2.22386 14.2239 2 14.5 2C14.7761 2 15 2.22386 15 2.5V3H16C17.1046 3 18 3.89543 18 5V16C18 17.1046 17.1046 18 16 18H4C2.89543 18 2 17.1046 2 16V5C2 3.89543 2.89543 3 4 3H5V2.5C5 2.22386 5.22386 2 5.5 2ZM3 6V16C3 16.5523 3.44772 17 4 17H16C16.5523 17 17 16.5523 17 16V6H3Z"
        fill={BColor[color]}
      />
    </svg>
  );
}
