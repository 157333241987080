export async function decryptData(encData: string): Promise<string> {
  const key = process.env.REACT_APP_DECRYPT_KEY;
  const iv = key?.substring(0, 16);

  // key를 암호화 키로 변환
  const cryptoKey = await window.crypto.subtle.importKey(
    'raw',
    new TextEncoder().encode(key),
    { name: 'AES-CBC', length: 256 },
    false,
    ['decrypt'],
  );

  // Base64 디코딩
  const base64Data = encData
    .replace(/-/g, '+')
    .replace(/_/g, '/')
    .padEnd(encData.length + ((4 - (encData.length % 4)) % 4), '=');

  const encryptedData = Uint8Array.from(atob(base64Data), (c) =>
    c.charCodeAt(0),
  );

  // 복호화
  const decryptedData = await window.crypto.subtle.decrypt(
    { name: 'AES-CBC', iv: new TextEncoder().encode(iv) },
    cryptoKey,
    encryptedData,
  );

  return new TextDecoder().decode(decryptedData);
}
