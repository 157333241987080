import { useEffect, useState } from 'react';

function LoadingDots({ children }: { children: React.ReactNode }) {
  const [dots, setDots] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => {
        if (prevDots.length >= 3) {
          return '';
        }
        return `${prevDots}.`;
      });
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {children}
      {dots}
    </>
  );
}

export default LoadingDots;
