import { IconDetailProps } from '@components/atoms/images';
import { COLOR } from '../color';

function CheckLargeIcon({ width, height, color }: IconDetailProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M13.4854 1.43144C14.0606 0.856186 14.9933 0.856186 15.5686 1.43144C16.1368 1.99964 16.1437 2.91655 15.5895 3.4933L7.74859 13.2944C7.73727 13.3085 7.72518 13.322 7.71237 13.3349C7.13712 13.9101 6.20444 13.9101 5.62919 13.3349L0.431442 8.13712C-0.143814 7.56186 -0.143814 6.62919 0.431442 6.05393C1.0067 5.47867 1.93937 5.47867 2.51463 6.05393L6.6264 10.1657L13.4463 1.4755C13.4584 1.46005 13.4715 1.44533 13.4854 1.43144Z"
        fill={COLOR[color]}
      />
    </svg>
  );
}

export default CheckLargeIcon;
