import { PharmacistWorkingTimeType } from 'types/userInformationType';

export function isPharmacyOpenNow(schedule: PharmacistWorkingTimeType[]) {
  const now = new Date();
  const koreaTimeZone = 'Asia/Seoul';
  const currentDay = new Intl.DateTimeFormat('en-US', {
    weekday: 'short',
    timeZone: koreaTimeZone,
  })
    .format(now)
    .toUpperCase();
  const currentTime = new Intl.DateTimeFormat('ko-KR', {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
    timeZone: koreaTimeZone,
  }).format(now);

  const formattedCurrentTime = currentTime.replace(/:/g, ':').split(' ')[0];
  const todaySchedule = schedule.find((day) => day.week_day === currentDay);

  if (!todaySchedule || todaySchedule.off) {
    return false;
  }
  const { start, end } = todaySchedule;

  if (!start || !end) {
    return false;
  }

  return formattedCurrentTime >= start && formattedCurrentTime <= end;
}
