import { Font } from '@components/atoms';
import BFlex from '@components/meraki-ui/BFlex';
import { commonHooks } from '@hooks';
import {
  TelepharmacyListCompletedStatusType,
  TelepharmacyListDeliveryMethodType,
  TelepharmacyListIsUninsuredType,
} from '@services/myDoctor/types/index.d';
import { useAppDispatch, useAppSelector } from '@stores/hooks';
import {
  getTelepharmacyList_completed,
  selectTelepharmacyCount_completed,
  selectTelepharmacyList_completed,
} from '@stores/telepharmacyStore/telepharmacyList';
import { isNil } from 'es-toolkit';
import moment from 'moment';
import { useEffect, useState } from 'react';
import TelepharmacyTable from '../telepharmacyTable';
import CompletedTelepharmacyCarousel from './CompletedTelepharmacyCarousel';
import { CompletedTelepharmacyTableFilterType } from './index.d';
import * as Style from './index.style';
import { CSVDownloadButton, FilterComponent } from './sections';
import ListItem from './sections/listItem';

export const LISTITEM_FLEX = [
  { flex: 100, title: '최종상태' },
  { flex: 100, title: '수령방법' },
  { flex: 120, title: '대체조제' },
  { flex: 130, title: '환자명(예약번호)' },
  { flex: 140, title: '연락처' },
  { flex: 120, title: '약제비' },
  { flex: 214, title: '결제상태' },
];

function CompletedTelepharmacyTable() {
  const dispatch = useAppDispatch();
  const { useQuery } = commonHooks;
  const query = useQuery();

  const [isLoading, setIsLoading] = useState(true);
  const telepharmacyData = useAppSelector(selectTelepharmacyList_completed);
  const telepharmacyCount = useAppSelector(selectTelepharmacyCount_completed);

  // 필터
  const [limit, setLimit] = useState<number>(30);
  const [from_date, setFrom_date] = useState<string | undefined>(
    moment().subtract(90, 'days').format('YYYY-MM-DD'),
  );
  const [to_date, setTo_date] = useState<string | undefined>(
    moment().format('YYYY-MM-DD'),
  );
  const [statusString, setStatusString] = useState<string | undefined>('ALL');
  const [delivery_method, setDelivery_method] = useState<
    TelepharmacyListDeliveryMethodType | undefined
  >(undefined);
  const [is_uninsured, setIs_uninsured] = useState<
    TelepharmacyListIsUninsuredType | undefined
  >(undefined);
  const [q_type, setQ_type] = useState<string | undefined>(undefined);
  const [q, setQ] = useState<string | undefined>(undefined);

  const handleFilters = (filters: CompletedTelepharmacyTableFilterType) => {
    setFrom_date(filters.from_date);
    setTo_date(filters.to_date);
    setStatusString(filters.statusString);
    setDelivery_method(filters.delivery_method);
    setIs_uninsured(filters.is_uninsured);
    setQ_type(filters.q_type);
    setQ(filters.q);
  };

  const pageQuery = query.get('page');
  const page = Number(pageQuery) || 0;
  const maxPage = telepharmacyCount
    ? Math.floor(telepharmacyCount / limit) + 1
    : 0;

  const getCompleteStatus = (type?: string) => {
    let result: TelepharmacyListCompletedStatusType | undefined;
    switch (type) {
      case 'DELIVERY_FINISH':
        result = 'delivery_finished';
        break;
      case 'DELIVERY_INPROGRESS':
        result = 'delivery_inprogress';
        break;
      case 'DELIVERY_PICKED_WAITED':
        result = 'delivery_pickup_waited';
        break;
      case 'PATIENT_CANCEL':
        result = 'cancel_by_patient';
        break;
      case 'PHARMACIST_CANCEL':
        result = 'cancel_by_pharmacist';
        break;
      default:
        break;
    }

    return result;
  };

  const completed_status = getCompleteStatus(statusString);

  // csv용 필터
  const [csvFilter, setCSVFilter] =
    useState<CompletedTelepharmacyTableFilterType>({
      statusString,
      delivery_method,
      from_date,
      to_date,
      is_uninsured,
      q_type,
      q,
    });

  useEffect(() => {
    setCSVFilter({
      statusString,
      delivery_method,
      from_date,
      to_date,
      is_uninsured,
      q_type,
      q,
    });
  }, [
    delivery_method,
    from_date,
    is_uninsured,
    q,
    q_type,
    statusString,
    to_date,
  ]);

  useEffect(() => {
    setIsLoading(true);

    let patient_name: string | undefined;
    let phone_num: string | undefined;
    let address: string | undefined;
    let hospital_name: string | undefined;
    let doctor_name: string | undefined;
    let waybill_num: string | undefined;

    if (q_type === 'patient_name') patient_name = q;
    if (q_type === 'phone_num') phone_num = q;
    if (q_type === 'address') address = q;
    if (q_type === 'hospital_name') hospital_name = q;
    if (q_type === 'doctor_name') doctor_name = q;
    if (q_type === 'waybill_num') waybill_num = q;

    dispatch(
      getTelepharmacyList_completed({
        offset: page * limit,
        limit,
        completed_status,
        from_date,
        to_date,
        delivery_method,
        is_tp_uninsured: is_uninsured,
        patient_name,
        phone_num,
        address,
        hospital_name,
        doctor_name,
        waybill_num,
      }),
    ).then(() => {
      setIsLoading(false);
    });
    return () => setIsLoading(false);
  }, [
    dispatch,
    page,
    limit,
    q_type,
    q,
    completed_status,
    from_date,
    to_date,
    delivery_method,
    is_uninsured,
  ]);

  return (
    <Style.Container>
      <Style.ListArea>
        <Style.TitleArea>
          <Font fontType="display2" color="fill/black">
            이전 내역
          </Font>
          <CSVDownloadButton
            csvFilter={csvFilter}
            telepharmacyCount={telepharmacyCount}
          />
        </Style.TitleArea>
        <Style.OptionArea>
          <FilterComponent handleFilters={handleFilters} />
        </Style.OptionArea>
        {!isNil(telepharmacyCount) && (
          <TelepharmacyTable
            isTableItemsLoading={isLoading}
            tableHeaderItems={LISTITEM_FLEX}
            telepharmacyCount={telepharmacyCount}
            telepharmacyData={telepharmacyData}
            emptyMessage="아직 조제 및 수거가 완료된 내역이 없어요"
            renderTableBodyItem={(item) => {
              return <ListItem key={item.id} item={item} />;
            }}
          />
        )}
      </Style.ListArea>

      <BFlex width="1024px" margin="1.25rem 0 0 0">
        <CompletedTelepharmacyCarousel />
      </BFlex>
    </Style.Container>
  );
}

export default CompletedTelepharmacyTable;
