import { BColor } from '@components/meraki-ui/BColor';
import { IconDetailProps } from '@components/meraki-ui/BIcon/svg.type';

export default function BellIcon({ width, height, color }: IconDetailProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 18C11.1046 18 12 17.1046 12 16H8C8 17.1046 8.89543 18 10 18Z"
        fill={BColor[color]}
      />
      <path
        d="M10 3.91825L9.20281 4.07925C7.37552 4.44829 6.00002 6.06463 6.00002 8C6.00002 8.62782 5.86566 10.1972 5.54116 11.7422C5.38006 12.5091 5.16454 13.3076 4.87809 14H15.1219C14.8355 13.3076 14.62 12.5091 14.4589 11.7422C14.1344 10.1972 14 8.62782 14 8C14 6.06462 12.6245 4.44827 10.7972 4.07924L10 3.91825ZM16.2193 14C16.4426 14.4474 16.7015 14.801 17 15H3C3.29853 14.801 3.55745 14.4474 3.7807 14C4.67922 12.1994 5.00002 8.87916 5.00002 8C5.00002 5.57934 6.7202 3.56045 9.00484 3.09904C9.00164 3.06646 9 3.03342 9 3C9 2.44772 9.44772 2 10 2C10.5523 2 11 2.44772 11 3C11 3.03342 10.9984 3.06646 10.9952 3.09903C13.2798 3.56042 15 5.57932 15 8C15 8.87916 15.3208 12.1994 16.2193 14Z"
        fill={BColor[color]}
      />
    </svg>
  );
}
