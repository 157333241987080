import { commonHooks } from '@hooks';
import { notifyOtherClients } from '@hooks/featureHooks/useSocket';
import { myDoctorAPI } from '@services';
import { useAppDispatch, useAppSelector } from '@stores/hooks';
import {
  closeModal_finishTelepharmacyModal,
  selectFinishTelepharmacyModalItem,
  selectFinishTelepharmacyModalVisible,
} from '@stores/modalStore/finishTelepharmacyModal';
import { closeModal_recheckRequestModal } from '@stores/modalStore/recheckRequestModal';
import {
  getTelepharmacyList_confirmed,
  getTelepharmacyList_finished_parcel,
  getTelepharmacyList_finished_quick,
} from '@stores/telepharmacyStore/telepharmacyList';
import { FormEvent, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const useFinishTelepharmacyModalHook = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { useInput } = commonHooks;
  const isVisible = useAppSelector(selectFinishTelepharmacyModalVisible);
  const item = useAppSelector(selectFinishTelepharmacyModalItem);

  const [isPageLoading, setIsPageLoading] = useState(false);

  const { useQuery } = commonHooks;
  const query = useQuery();
  const pageQuery = query.get('page');
  const page = Number(pageQuery) || 0;

  // 약제 종류
  const [internal, setInternal] = useState(true);
  const [external, setExternal] = useState(false);
  const [injection, setInjection] = useState(false);

  // 투약 일수
  const [medicine_day_num, onChangeMedicine_day_num, setMedicine_day_num] =
    useInput('');
  const [
    isMedicine_day_num_not_decided_yet,
    setIsMedicine_day_num_not_decided_yet,
  ] = useState(false);

  // 투약 횟수
  const [morning, setMorning] = useState(false);
  const [lunch, setLunch] = useState(false);
  const [dinner, setDinner] = useState(false);
  const [beforeSleep, setBeforeSleep] = useState(false);
  const [medicine_period_num, setMedicine_period_num] = useState<number>(0);

  // 필요 시
  const [whenever_patient_want, setWhenever_patient_want] = useState(false);

  // 추가 안내 참고
  const [is_supplement_info_exposed, setIs_supplement_info_exposed] =
    useState(false);

  // 투약 시점
  const [before_meal, setBefore_meal] = useState(false);
  const [after_meal, setAfter_meal] = useState(false);

  // 추가 안내
  const [is_directly_input, setIs_Directly_input] = useState(false);
  const [supplement_info, setSupplement_info] = useState('');

  const onChangeSupplement_info = (e: FormEvent<HTMLTextAreaElement>) => {
    if (e.currentTarget.value.length > 500) return;
    setSupplement_info(e.currentTarget.value);
  };

  const closeModal = useCallback(() => {
    setIsPageLoading(false);
    setInternal(true);
    setExternal(false);
    setInjection(false);
    setMedicine_day_num('');
    setMorning(false);
    setLunch(false);
    setDinner(false);
    setBeforeSleep(false);
    setMedicine_period_num(0);
    setWhenever_patient_want(false);
    setIs_supplement_info_exposed(false);
    setBefore_meal(false);
    setAfter_meal(false);
    setSupplement_info('');
    setIsMedicine_day_num_not_decided_yet(false);
    setIs_Directly_input(false);
    dispatch(closeModal_finishTelepharmacyModal());
    dispatch(closeModal_recheckRequestModal());
  }, [dispatch, setMedicine_day_num]);

  const getMedicineGuideData = () => {
    const id = item.id ? item.id : 0;
    const medicineGuideParams = {
      medicine_type: [] as string[],
      medicine_day_num: Number(medicine_day_num),
      whenever_patient_want,
      is_supplement_info_exposed,
      medicine_by_meal: [] as string[],
      supplement_info,
      tpp_id: id,
      medicine_time: [] as string[],
    };

    if (internal) medicineGuideParams.medicine_type.push('internal');
    if (external) medicineGuideParams.medicine_type.push('external');
    if (injection) medicineGuideParams.medicine_type.push('injection');

    if (before_meal || after_meal) {
      if (before_meal) medicineGuideParams.medicine_by_meal.push('before_meal');
      if (after_meal) medicineGuideParams.medicine_by_meal.push('after_meal');
    }

    if (morning) medicineGuideParams.medicine_time.push('morning');
    if (lunch) medicineGuideParams.medicine_time.push('lunch');
    if (dinner) medicineGuideParams.medicine_time.push('evening');
    if (beforeSleep) medicineGuideParams.medicine_time.push('night');

    return medicineGuideParams;
  };

  const finishTelepharmacy = ({
    defaultMedicineGuide,
    isPickup,
    id,
  }: {
    defaultMedicineGuide?: any;
    isPickup?: boolean;
    id?: number;
  }) => {
    console.log(68695, id);
    if (isPickup && id) {
      myDoctorAPI
        .postFinishTelepharmacy({}, id)
        .then((responseJSON) => {
          console.log(999, responseJSON);
          alert('약국 방문 요청이 완료되었습니다.');
          closeModal();
          Promise.all([
            dispatch(
              getTelepharmacyList_confirmed({
                limit: 30,
                offset: page,
              }),
            ),
            dispatch(
              getTelepharmacyList_finished_parcel({
                limit: 30,
                offset: page,
              }),
            ),
            dispatch(
              getTelepharmacyList_finished_quick({
                limit: 30,
                offset: page,
              }),
            ),
          ]).then(() =>
            setTimeout(() => {
              notifyOtherClients('telepharmacy_payment');
              navigate('/home/waitForDispense');
            }, 700),
          );
        })
        .finally(() => setIsPageLoading(false));

      return;
    }

    if (Number(medicine_day_num) < 0) {
      alert('투약 일수는 0일 이상이어야 합니다.');
      return;
    }

    if (Number(medicine_day_num) > 365) {
      alert('투약 일수는 최대 365일 까지만 가능합니다.');
      return;
    }

    setIsPageLoading(true);

    const medicineGuideParams = defaultMedicineGuide ?? getMedicineGuideData();

    const idfromModal = item.id ? item.id : 0;
    // 추후 아래 내용으로 변경하기
    myDoctorAPI
      .postMedicineGuide(medicineGuideParams)
      .then((response) => {
        console.log(888, response);

        myDoctorAPI
          .postFinishTelepharmacy({}, idfromModal)
          .then((responseJSON) => {
            console.log(5858, responseJSON);
            alert('수거 요청이 완료되었습니다.');
            closeModal();
            Promise.all([
              dispatch(
                getTelepharmacyList_confirmed({
                  limit: 30,
                  offset: page,
                }),
              ),
              dispatch(
                getTelepharmacyList_finished_parcel({
                  limit: 30,
                  offset: page,
                }),
              ),
              dispatch(
                getTelepharmacyList_finished_quick({
                  limit: 30,
                  offset: page,
                }),
              ),
            ]).then(() =>
              setTimeout(() => {
                notifyOtherClients('telepharmacy_payment');
                navigate('/home/confirmedTreatment/pick-up');
              }, 700),
            );
          })
          .finally(() => setIsPageLoading(false));
      })
      .catch((e) => {
        console.log(345, e);
        alert('복약 안내 요청에 실패했습니다.');
        setIsPageLoading(false);
      });
  };

  const buttonVisible: boolean =
    external ||
    injection ||
    (internal && whenever_patient_want) ||
    (internal &&
      medicine_day_num.length > 0 &&
      (morning || lunch || dinner || beforeSleep)) ||
    (internal && isMedicine_day_num_not_decided_yet);

  useEffect(() => {
    let count = 0;
    if (morning) count += 1;
    if (lunch) count += 1;
    if (dinner) count += 1;
    if (beforeSleep) count += 1;
    setMedicine_period_num(count);
  }, [beforeSleep, dinner, lunch, morning]);

  return {
    isPageLoading,
    isVisible,
    item,
    closeModal,
    finishTelepharmacy,
    internal,
    setInternal,
    external,
    setExternal,
    injection,
    setInjection,
    medicine_day_num,
    onChangeMedicine_day_num,
    setMedicine_day_num,
    isMedicine_day_num_not_decided_yet,
    setIsMedicine_day_num_not_decided_yet,
    morning,
    setMorning,
    lunch,
    setLunch,
    dinner,
    setDinner,
    beforeSleep,
    setBeforeSleep,
    medicine_period_num,
    setMedicine_period_num,
    whenever_patient_want,
    setWhenever_patient_want,
    is_directly_input,
    setIs_Directly_input,
    is_supplement_info_exposed,
    setIs_supplement_info_exposed,
    before_meal,
    setBefore_meal,
    after_meal,
    setAfter_meal,
    supplement_info,
    setSupplement_info,
    onChangeSupplement_info,
    buttonVisible,
    getMedicineGuideData,
  };
};

export default useFinishTelepharmacyModalHook;
