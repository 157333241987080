import styled from 'styled-components';

export const TitleArea = styled.div``;

export const DescriptionArea = styled.div`
  margin-bottom: 40px;
`;

export const TextInputArea = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TextInputContent = styled.div`
  &:first-child {
    margin-bottom: 20px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
