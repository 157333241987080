import { Button, COLOR, Font, TextInput } from '@components/atoms';
import { ModalCommonStructure as Modal } from '@components/molecules';
import { useAppDispatch } from '@stores/hooks';
import { closeModal_setFirstLoginModal } from '@stores/modalStore/setFirstLoginModal';
import { getSessionStorageState } from '@utils/storageHandler';
import { Controller, useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import {
  EChangePasswordFormFields,
  IChangePasswordPasswordFormData,
  useChangePasswordSubmit,
} from '../hooks';
import { SetFirstLoginModalTemplateType } from './index.d';

function SetFirstLoginModalTemplate({
  isVisible,
}: SetFirstLoginModalTemplateType) {
  const email = getSessionStorageState('email');
  const dispatch = useAppDispatch();
  const { control, formState } =
    useFormContext<IChangePasswordPasswordFormData>();

  const passwordError = formState.errors?.[EChangePasswordFormFields.PASSWORD];
  const passwordErrorMessage =
    formState?.errors?.[EChangePasswordFormFields.PASSWORD]?.message;
  const passwordConfirmError =
    formState.errors?.[EChangePasswordFormFields.PASSWORD_CONFIRM];
  const passwordConfirmErrorMessage =
    formState?.errors?.[EChangePasswordFormFields.PASSWORD_CONFIRM]?.message;

  const error = passwordError || passwordConfirmError;
  const disabled = error || Object.keys(formState.dirtyFields).length !== 2;

  const { submit } = useChangePasswordSubmit();

  return (
    <Modal
      isOpen={isVisible}
      closeButton={false}
      modalHeader={
        <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
          <Font fontType="display2" color="fill/dark">
            비밀번호 재설정
          </Font>
          <Font fontType="body1_medium">
            {
              '영문/숫자/특수문자를 모두 포함하여\n최소 8자 이상의 비밀번호를 설정해주세요.'
            }
          </Font>
        </div>
      }
      modalBody={
        <>
          <div style={{ paddingBottom: 20 }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: 20,
                padding: 16,
                borderRadius: 8,
                backgroundColor: '#F9FAFA',
              }}
            >
              <Font fontType="body1" color="fill/medium">
                가입 이메일
              </Font>
              <Font fontType="body1_medium" color="fill/dark">
                {email}
              </Font>
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Controller
              control={control}
              name={EChangePasswordFormFields.PASSWORD}
              render={({ field: { onChange, value } }) => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 8,
                      paddingBottom: 20,
                    }}
                  >
                    <TextInput
                      value={value}
                      onChange={onChange}
                      placeholder="비밀번호를 입력해주세요"
                      type="password"
                      fontSize={16}
                      title="비밀번호"
                      errorMessage={passwordErrorMessage}
                    />
                    {passwordError && value.length > 0 && (
                      <div style={{ paddingLeft: 20 }}>
                        <Font fontType="body2" color="state/distructive">
                          {passwordErrorMessage}
                        </Font>
                      </div>
                    )}
                  </div>
                );
              }}
            />
            <Controller
              control={control}
              name={EChangePasswordFormFields.PASSWORD_CONFIRM}
              render={({ field: { onChange, value } }) => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 8,
                      paddingBottom: 20,
                    }}
                  >
                    <TextInput
                      value={value}
                      onChange={onChange}
                      placeholder="비밀번호를 다시 입력해주세요"
                      type="password"
                      fontSize={16}
                      title="비밀번호 확인"
                      errorMessage={passwordConfirmErrorMessage}
                    />
                    {passwordConfirmError && value.length > 0 && (
                      <div style={{ paddingLeft: 20 }}>
                        <Font fontType="body2" color="state/distructive">
                          {passwordConfirmErrorMessage}
                        </Font>
                      </div>
                    )}
                  </div>
                );
              }}
            />
          </div>
        </>
      }
      modalFooter={
        <LoginButtonArea>
          <div
            style={{
              flex: 1,
            }}
          >
            <Button
              width="100%"
              padding="16px 12px"
              borderRadius="12px"
              title={
                <Font fontType="body1" color="fill/dark">
                  다음에 하기
                </Font>
              }
              onClick={() => {
                dispatch(closeModal_setFirstLoginModal());
              }}
              backgroundColor={COLOR['fill/white']}
              borderColor={COLOR['border/outline']}
            />
          </div>
          <div style={{ flex: 1 }}>
            <Button
              width="100%"
              padding="16px 12px"
              borderRadius="12px"
              title={
                <Font
                  fontType="body1"
                  color={disabled ? 'disabled/foreground' : 'fill/white'}
                >
                  재설정
                </Font>
              }
              onClick={() => {
                if (disabled) return;
                submit();
              }}
              backgroundColor={
                disabled ? COLOR['disabled/background'] : COLOR['fill/accent']
              }
            />
          </div>
        </LoginButtonArea>
      }
    />
  );
}

export default SetFirstLoginModalTemplate;
const LoginButtonArea = styled.div`
  width: 100%;
  display: flex;
  gap: 12px;
`;
