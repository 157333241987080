import { COLOR } from '@components/atoms';
import BeatLoader from 'react-spinners/BeatLoader';
import { Container } from './index.style';

function PageLoader() {
  return (
    <Container>
      <BeatLoader size={20} loading color={COLOR['fill/accent']} />
    </Container>
  );
}

export default PageLoader;
