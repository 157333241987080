import BButton from '@components/meraki-ui/BButton';
import { ButtonType } from '@components/meraki-ui/BButton/BButton.constant';
import { BColor } from '@components/meraki-ui/BColor';
import BFlex from '@components/meraki-ui/BFlex';
import { IconType } from '@components/meraki-ui/BIcon/svg.type';
import { memo, ReactNode } from 'react';

interface BModalFooterProps {
  onClick?: () => void | Promise<void>;
  buttonText: string | ReactNode;
  borderTopExisted?: boolean;
  buttonType?: ButtonType;
  buttonIcon?: IconType;
  padding?: string;
}

function BModalFooter({
  onClick,
  padding,
  borderTopExisted,
  buttonText,
  buttonType,
  buttonIcon,
}: BModalFooterProps) {
  return (
    <BFlex
      isFull
      borderTop={borderTopExisted ? `1px solid ${BColor.mono05}` : undefined}
      padding={padding}
    >
      {typeof buttonText === 'string' ? (
        <BButton
          icon={buttonIcon}
          text={buttonText}
          buttonType={buttonType ?? 'primary'}
          onClick={onClick}
          size="heightForFullWidth"
          height={60}
        />
      ) : (
        buttonText
      )}
    </BFlex>
  );
}

export default memo(BModalFooter);
