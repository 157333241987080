import { BColor } from '@components/meraki-ui/BColor';
import { IconDetailProps } from '@components/meraki-ui/BIcon/svg.type';

export default function DoorIcon({ width, height, color }: IconDetailProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 12C10.2239 12 10 11.5523 10 11C10 10.4477 10.2239 10 10.5 10C10.7761 10 11 10.4477 11 11C11 11.5523 10.7761 12 10.5 12Z"
        fill={BColor[color]}
      />
      <path
        d="M12.8276 2.12228C12.9371 2.21724 13 2.35506 13 2.5V3H13.5C14.3284 3 15 3.67158 15 4.5V17H16.5C16.7761 17 17 17.2239 17 17.5C17 17.7761 16.7761 18 16.5 18H3.5C3.22386 18 3 17.7761 3 17.5C3 17.2239 3.22386 17 3.5 17H5V3.5C5 3.25118 5.18297 3.04022 5.42929 3.00503L12.4293 2.00503C12.5728 1.98453 12.7181 2.02731 12.8276 2.12228ZM13.5 4H13V17H14V4.5C14 4.22386 13.7761 4 13.5 4ZM6 3.93365V17H12V3.07651L6 3.93365Z"
        fill={BColor[color]}
      />
    </svg>
  );
}
