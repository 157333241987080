import { BColor } from '@components/meraki-ui/BColor';
import { IconDetailProps } from '@components/meraki-ui/BIcon/svg.type';

export default function PrescriptionIcon({
  width,
  height,
  color,
}: IconDetailProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.5 7.5C9.5 7.22386 9.27614 7 9 7C8.72385 7 8.5 7.22386 8.5 7.5V8.13397L7.95096 7.81699C7.71182 7.67892 7.40602 7.76085 7.26795 8C7.12988 8.23915 7.21182 8.54494 7.45096 8.68301L8 9L7.45096 9.31699C7.21181 9.45506 7.12987 9.76085 7.26795 10C7.40602 10.2391 7.71181 10.3211 7.95096 10.183L8.5 9.86602V10.5C8.5 10.7761 8.72385 11 9 11C9.27614 11 9.5 10.7761 9.5 10.5V9.86602L10.049 10.183C10.2882 10.3211 10.594 10.2391 10.7321 10C10.8701 9.76085 10.7882 9.45506 10.549 9.31699L10 9L10.549 8.68301C10.7882 8.54494 10.8701 8.23915 10.732 8C10.594 7.76085 10.2882 7.67892 10.049 7.81699L9.5 8.13397V7.5Z"
        fill={BColor[color]}
      />
      <path
        d="M7.5 12C7.22386 12 7 12.2239 7 12.5C7 12.7761 7.22386 13 7.5 13H12.5C12.7761 13 13 12.7761 13 12.5C13 12.2239 12.7761 12 12.5 12H7.5Z"
        fill={BColor[color]}
      />
      <path
        d="M7.5 14C7.22386 14 7 14.2239 7 14.5C7 14.7761 7.22386 15 7.5 15H12.5C12.7761 15 13 14.7761 13 14.5C13 14.2239 12.7761 14 12.5 14H7.5Z"
        fill={BColor[color]}
      />
      <path
        d="M16 16V6.5L11.5 2H6C4.89543 2 4 2.89543 4 4V16C4 17.1046 4.89543 18 6 18H14C15.1046 18 16 17.1046 16 16ZM11.5 5C11.5 5.82843 12.1716 6.5 13 6.5H15V16C15 16.5523 14.5523 17 14 17H6C5.44772 17 5 16.5523 5 16V4C5 3.44772 5.44772 3 6 3H11.5V5Z"
        fill={BColor[color]}
      />
    </svg>
  );
}
