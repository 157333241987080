import { useAppDispatch, useAppSelector } from '@stores/hooks';
import {
  closeModal_medicineSoldOutAlertModal,
  openModal_medicineSoldOutAlertModal,
  selectMedicineSoldOutAlertModalTargetMedicine,
  selectMedicineSoldOutAlertModalVisible,
  set_targetMedicine_medicineSoldOutAlertModal,
} from '@stores/modalStore/medicineSoldOutAlertModalStore';

import { useCallback } from 'react';

const useMedicineSoldOutAlertModalHook = () => {
  const dispatch = useAppDispatch();
  const isVisible = useAppSelector(selectMedicineSoldOutAlertModalVisible);

  const targetMedicine = useAppSelector(
    selectMedicineSoldOutAlertModalTargetMedicine,
  );

  const closeModal = useCallback(() => {
    dispatch(closeModal_medicineSoldOutAlertModal());
  }, [dispatch]);

  const openModal = useCallback(() => {
    dispatch(openModal_medicineSoldOutAlertModal());
  }, [dispatch]);

  const setMedicineSoldOutAlertTargetMedicine = (
    medicineType: 'reject' | 'auto-reject',
  ) => {
    dispatch(set_targetMedicine_medicineSoldOutAlertModal(medicineType));
  };

  return {
    isVisible,
    targetMedicine,
    closeModal,
    openModal,
    setMedicineSoldOutAlertTargetMedicine,
  };
};

export default useMedicineSoldOutAlertModalHook;
