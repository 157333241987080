import { Font } from '@components/atoms';
import { memo } from 'react';
import { ELoginType } from '../../hooks';

type ILoginTypeTabViewProps = {
  updateLoginType: (loginType: ELoginType) => void;
  selectedLoginType: ELoginType;
  clearMessage: () => void;
};

export const LoginTypeTabView = memo<ILoginTypeTabViewProps>(
  ({ updateLoginType, selectedLoginType, clearMessage }) => {
    const tabs = [
      { type: ELoginType.EMAIL, label: '이메일 로그인' },
      { type: ELoginType.PHONE, label: '휴대폰 로그인' },
    ];

    return (
      <div
        style={{
          flex: 1,
          backgroundColor: '#F1F2F4',
          borderRadius: 12,
          padding: 4,
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        {tabs.map((tab, index) => {
          const { type, label } = tab;
          const isSelected = selectedLoginType === type;
          const handlePressTab = () => {
            updateLoginType(type);
            clearMessage();
          };
          return (
            <div
              onClick={handlePressTab}
              key={index}
              style={{
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                padding: '12px 8px',
                backgroundColor: isSelected ? 'white' : 'transparent',
                borderRadius: 8,
                minWidth: 196,
                cursor: 'pointer',
              }}
            >
              <Font
                fontType="h4"
                color={isSelected ? 'fill/accent' : 'greyscale5'}
              >
                {label}
              </Font>
            </div>
          );
        })}
      </div>
    );
  },
);
