import styled from 'styled-components';
import { COLOR } from '../color';
import { TextInputContentType } from './index.d';

export const TextInputContainer = styled.div`
  width: 100%;
`;

export const TextInputTitle = styled.div`
  margin-bottom: 10px;
`;

export const TextInputComponent = styled.input<TextInputContentType>`
  width: ${(props) => (props.width ? `${props.width}px` : '100%')};
  height: ${(props) => (props.height ? props.height : 48)}px;
  padding-left: 20px;
  border: 1px solid ${COLOR['fill/medium']};
  border-radius: 4px;
  font-weight: 400;
  font-size: ${(props) => props.fontSize || '18px'};
  &::-webkit-input-placeholder {
    color: ${COLOR['fill/medium']};
  }
  &:-ms-input-placeholder {
    color: ${COLOR['fill/medium']};
  }
  &::placeholder {
    color: ${COLOR['fill/medium']};
  }
`;

export const TextAreaComponent = styled.textarea<TextInputContentType>`
  width: ${(props) => (props.width ? `${props.width}px` : '100%')};
  height: ${(props) => (props.height ? props.height : 48)}px;
  padding: 20px;
  border-width: 1px;
  border-color: ${COLOR['fill/medium']};
  font: 400;
  font-size: ${(props) => props.fontSize || '18px'};
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  border-radius: 4px;
  font-size: ${(props) => props.fontSize || '18px'};
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : ''};
`;
