import { Font } from '@components/atoms';
import { getPaymentInformationList } from '@components/organisms/utils/domain';
import { TelepharmacyItemType } from 'types/telepharmacyType';
import { PageStatus } from '../..';
import ReservationInformationItem from '../reservationInformationPartItem/ReservationInformationItem';
import * as Style from './index.style';

interface PaymentInformationPartItemProps {
  item: TelepharmacyItemType;
  status: PageStatus;
}

function PaymentInformationPartItem({
  item,
  status,
}: PaymentInformationPartItemProps) {
  return (
    <Style.RightContentItem>
      <Style.ContentHeaderArea>
        <Style.ContentHeaderTitle>
          <Font fontType="h1" color="fill/black">
            결제정보
          </Font>
        </Style.ContentHeaderTitle>
      </Style.ContentHeaderArea>

      <Style.ContentBodyArea>
        {getPaymentInformationList({ item, status }).map(
          (reservationInformationItem) => {
            return (
              <ReservationInformationItem
                reservationInformationItem={reservationInformationItem}
                key={reservationInformationItem.id}
              />
            );
          },
        )}
      </Style.ContentBodyArea>
    </Style.RightContentItem>
  );
}

export default PaymentInformationPartItem;
