import styled from 'styled-components';

type Props = {
  address?: string;
  entrancePassword?: string;
  trackingNumber?: string;
  deliveryCompany?: string;
  addressMemo?: string;
};

function ParcelInfo({
  address = '-',
  addressMemo = '-',
  entrancePassword = '비밀번호 없이 출입 가능',
  deliveryCompany = '택배사 접수가 진행 중입니다.',
  trackingNumber = '운송장 등록 후 노출됩니다',
}: Props) {
  return (
    <Container>
      <Title>배송 정보 (택배)</Title>
      <ContentsWrapper>
        <Row>
          <Key>주소</Key>
          <Value>{address}</Value>
        </Row>
        <Row>
          <Key>공동현관 비밀번호</Key>
          <Value>{entrancePassword}</Value>
        </Row>
        <Row>
          <Key>택배사</Key>
          <Value>{deliveryCompany}</Value>
        </Row>
        <Row>
          <Key>운송장 번호</Key>
          <Value>{trackingNumber}</Value>
        </Row>
        <Row>
          <Key>배송 요청사항</Key>
          <Value>{addressMemo}</Value>
        </Row>
      </ContentsWrapper>
    </Container>
  );
}

export default ParcelInfo;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  padding: 20px;
  border-top: 8px solid #f1f2f4;
  border-bottom: none;
  width: 100%;
`;

const Title = styled.div`
  color: #1e2024;

  font-family: Pretendard, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
`;

const ContentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

const Row = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
`;

const Key = styled.div`
  width: 120px;
  color: #9fa1a8;

  font-family: Pretendard, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

const Value = styled.div`
  flex: 1 0 0;
  color: #474a52;

  font-family: Pretendard, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
`;
