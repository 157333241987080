import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '@stores';
import { ChangeServerURLModalType } from './index.d';

const initialState: ChangeServerURLModalType = {
  isVisible: false,
};

export const changeServerURLModal = createSlice({
  name: 'modal/changeServerURLModal',
  initialState,
  reducers: {
    openModal_changeServerURLModal: (state) => {
      state.isVisible = true;
    },
    closeModal_changeServerURLModal: (state) => {
      state.isVisible = false;
    },
  },
});

export const {
  openModal_changeServerURLModal,
  closeModal_changeServerURLModal,
} = changeServerURLModal.actions;

export const selectChangeServerURLModalVisible = (state: RootState) =>
  state.changeServerURLModal.isVisible;

export default changeServerURLModal.reducer;
