import { COLOR } from '@components/atoms';
import styled from 'styled-components';

export const Container = styled.div`
  /* padding: 40px 40px 20px 40px; */
  justify-content: center;
  display: flex;
`;

export const ProgressStatusContainer = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
`;

export const ProgressStatusArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ProgressStatusIconArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
`;

export const ProgressStatusTextArea = styled.div``;

export const ProgressStatusBarArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 76px;
  height: 48px;
`;

export const ProgressStatusBar = styled.div<{ borderColor?: string }>`
  width: 76px;
  height: 2px;
  border-bottom: 2px solid
    ${({ borderColor }) => borderColor || COLOR['state/success']};
`;
