import { Font } from '@components/atoms';
import { ReactNode } from 'react';
import * as Style from './index.style';

const ListContent = {
  Element: ({
    children,
    flex,
    flexDirection = 'row',
    border = '',
  }: {
    children?: ReactNode;
    flex: number | string;
    flexDirection?: 'row' | 'column';
    border?: string;
  }) => {
    return (
      <Style.DefaultListContent
        flexDirection={flexDirection}
        flex={flex}
        border={border}
      >
        {children}
      </Style.DefaultListContent>
    );
  },
  Regular: ({
    children,
    flex,
    flexDirection = 'row',
    border = '',
  }: {
    children?: ReactNode;
    flex: number | string;
    flexDirection?: 'row' | 'column';
    border?: string;
  }) => {
    return (
      <Style.DefaultListContent
        flexDirection={flexDirection}
        flex={flex}
        border={border}
      >
        <Font fontType="body2_medium" color="fill/dark">
          {children}
        </Font>
      </Style.DefaultListContent>
    );
  },
  Bold: ({
    children,
    flex,
    flexDirection = 'row',
    border = '',
  }: {
    children: ReactNode;
    flex: number | string;
    flexDirection?: 'row' | 'column';
    border?: string;
  }) => {
    return (
      <Style.DefaultListContent
        flexDirection={flexDirection}
        flex={flex}
        border={border}
      >
        <div style={{ padding: '0 16px' }}>
          <Font fontType="h5" color="fill/white">
            {children}
          </Font>
        </div>
      </Style.DefaultListContent>
    );
  },
};

export default ListContent;
