import { useAppDispatch, useAppSelector } from '@stores/hooks';
import {
  closeModal_findIdPasswordModal,
  selectFindIdPasswordModalVisible,
} from '@stores/modalStore/findIdPasswordModal';
import { useCallback } from 'react';

const useFindIdPasswordModalHook = () => {
  const dispatch = useAppDispatch();
  const isVisible = useAppSelector(selectFindIdPasswordModalVisible);

  const closeModal = useCallback(() => {
    dispatch(closeModal_findIdPasswordModal());
  }, [dispatch]);

  return { isVisible, closeModal };
};

export default useFindIdPasswordModalHook;
