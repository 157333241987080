import { Font, TextInput } from '@components/atoms';
import { memo } from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { EmailLoginFormData, EmailLoginFormFields } from '../../hooks';

type ILoginEmailInputComponentProps = {};

export const LoginEmailInputComponent = memo<ILoginEmailInputComponentProps>(
  ({}) => {
    const { control, formState } = useFormContext<EmailLoginFormData>();

    const errors = formState.errors?.[EmailLoginFormFields.EMAIL];
    const errorMessage =
      formState?.errors?.[EmailLoginFormFields.EMAIL]?.message;
    return (
      <Controller
        control={control}
        name={EmailLoginFormFields.EMAIL}
        render={({ field: { onChange, value } }) => {
          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 8,
                paddingBottom: 20,
              }}
            >
              <TextInput
                value={value}
                onChange={onChange}
                placeholder="이메일 아이디를 입력해주세요"
                type="text"
                fontSize={16}
                title="이메일"
                errorMessage={errorMessage}
              />
              {errors && value.length > 0 && (
                <div style={{ paddingLeft: 20 }}>
                  <Font fontType="body2" color="state/distructive">
                    {errorMessage}
                  </Font>
                </div>
              )}
            </div>
          );
        }}
      />
    );
  },
);
