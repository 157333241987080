import { COLOR, Font } from '@components/atoms';
import InfoIcon from '@components/atoms/images/InfoIcon';
import { EFormType } from '@pages/loginPage';
import useLoginPageHook from '@pages/loginPage/hooks';
import { memo, useCallback } from 'react';
import { useFindIdPasswordTimer } from '../find-id-password/hooks';
import { FindIdPasswordCtaView } from '../find-id-password/views';
import { ELoginType, useLoginSubmitEmail, useLoginSubmitPhone } from './hooks';
import { LoginCtaView, LoginFormView, LoginTypeTabView } from './views';

type ILoginContainerProps = {
  updateFormType: (type: EFormType) => void;
  selectedLoginType: ELoginType;
  updateLoginType: (type: ELoginType) => void;
};

export const LoginContainer = memo<ILoginContainerProps>(
  ({ updateFormType, selectedLoginType, updateLoginType }) => {
    const { openSignupModal } = useLoginPageHook();

    const openFindIdPasswordModal = useCallback(() => {
      updateFormType(EFormType.FIND_ID_PASSWORD);
      // window.open('https://form.typeform.com/to/iWsUlerS', '_black');
    }, [updateFormType]);

    const { formattedTime, startTimer, shouldTriggerTimer, resetTimer } =
      useFindIdPasswordTimer();

    const {
      submit: submitPhone,
      // tokenFromServer,
      errorMessage,
      isLoginSuccess,
      retry,
      clearMessage,
      updateErrorMessage,
    } = useLoginSubmitPhone(startTimer, resetTimer);
    const { submit: submitEmail } = useLoginSubmitEmail(updateErrorMessage);

    if (isLoginSuccess !== undefined && !isLoginSuccess) {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: 40,
            backgroundColor: 'white',
            borderTopRightRadius: 16,
            borderBottomRightRadius: 16,
            width: 480,
          }}
        >
          <div style={{ flex: 1 }}>
            <div
              style={{
                paddingBottom: 30,
                gap: 8,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Font fontType="display2">로그인 요청 거절</Font>
              <Font fontType="body1_medium" color="fill/medium">
                휴대폰 번호 로그인 요청이 거절되었습니다.
              </Font>
            </div>
          </div>
          <FindIdPasswordCtaView
            updateFormType={updateFormType}
            submit={submitPhone}
            shouldRetry
            retry={retry}
          />
        </div>
      );
    }

    if (shouldTriggerTimer) {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: 40,
            backgroundColor: 'white',
            borderTopRightRadius: 16,
            borderBottomRightRadius: 16,
            width: 480,
          }}
        >
          <div style={{ flex: 1 }}>
            <div
              style={{
                paddingBottom: 30,
                gap: 8,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Font fontType="display2">본인 인증</Font>
              <Font fontType="body1_medium" color="fill/medium">
                본인 확인을 위해
                <span
                  style={{
                    fontSize: 16,
                    letterSpacing: '-0.02em',
                    color: COLOR['fill/accent'],
                  }}
                >
                  {' '}
                  카카오톡 메시지
                </span>
                {
                  '가 전송됩니다.\n메시지의 인증수락 버튼을 누르면 자동로그인이 진행됩니다.\n(카카오톡 발송 실패 시 문자로 대체 발송됩니다.)'
                }
              </Font>
            </div>
            <div style={{ display: 'flex', gap: 20, flexDirection: 'column' }}>
              {errorMessage && (
                <div style={{}}>
                  <div
                    style={{
                      backgroundColor: '#FFF0F0',
                      borderRadius: 8,
                      padding: '8px 12px',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: 8,
                    }}
                  >
                    <InfoIcon
                      width={16}
                      height={16}
                      color="state/distructive"
                    />
                    <Font fontType="body2" color="state/distructive">
                      {errorMessage}
                    </Font>
                  </div>
                </div>
              )}
              <LoginFormView
                openFindIdPasswordModal={openFindIdPasswordModal}
                selectedLoginType={selectedLoginType}
                shouldTriggerTimer={shouldTriggerTimer}
                formattedTime={formattedTime}
                submitEmail={submitEmail}
                submitPhone={submitPhone}
              />
            </div>
          </div>
          <LoginCtaView
            openSignupModal={openSignupModal}
            selectedLoginType={selectedLoginType}
            submitEmail={submitEmail}
            submitPhone={submitPhone}
            shouldTriggerTimer={shouldTriggerTimer}
            retry={retry}
          />
        </div>
      );
    }

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: 40,
          backgroundColor: 'white',
          borderTopRightRadius: 16,
          borderBottomRightRadius: 16,
          width: 480,
        }}
      >
        <div style={{ flex: 1 }}>
          <div style={{ paddingBottom: 30 }}>
            <Font fontType="display2">로그인</Font>
          </div>
          <div style={{ display: 'flex', gap: 20, flexDirection: 'column' }}>
            <LoginTypeTabView
              updateLoginType={updateLoginType}
              selectedLoginType={selectedLoginType}
              clearMessage={clearMessage}
            />
            {errorMessage && (
              <div style={{}}>
                <div
                  style={{
                    backgroundColor: '#FFF0F0',
                    borderRadius: 8,
                    padding: '8px 12px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: 8,
                  }}
                >
                  <InfoIcon width={16} height={16} color="state/distructive" />
                  <Font fontType="body2" color="state/distructive">
                    {errorMessage}
                  </Font>
                </div>
              </div>
            )}
            {!shouldTriggerTimer && selectedLoginType === ELoginType.PHONE && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 4,
                }}
              >
                <Font fontType="body1_medium">대표 약사 휴대폰 번호</Font>
                <Font fontType="body2_medium" color="greyscale5">
                  휴대폰 번호는
                  <span style={{ color: COLOR['fill/accent'] }}>
                    {' 대표 약사님의 번호'}
                  </span>
                  {'를 입력해주세요.\n인증 메시지는 '}
                  <span style={{ color: COLOR['fill/accent'] }}>
                    알림톡을 받고 있는 모든 번호
                  </span>
                  로 발송됩니다.
                </Font>
              </div>
            )}

            <LoginFormView
              openFindIdPasswordModal={openFindIdPasswordModal}
              selectedLoginType={selectedLoginType}
              shouldTriggerTimer={shouldTriggerTimer}
              formattedTime={formattedTime}
              submitEmail={submitEmail}
              submitPhone={submitPhone}
            />
          </div>
        </div>
        <LoginCtaView
          openSignupModal={openSignupModal}
          selectedLoginType={selectedLoginType}
          submitEmail={submitEmail}
          submitPhone={submitPhone}
          retry={retry}
        />
      </div>
    );
  },
);
