import { IconDetailProps } from '@components/atoms/images';
import { COLOR } from '../color';

function CloseCircle({ width, height, color }: IconDetailProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23ZM16.0662 7.93352C16.4567 8.32404 16.4567 8.95721 16.0662 9.34773L13.4147 11.9993L16.0666 14.6511C16.4571 15.0417 16.4571 15.6748 16.0666 16.0654C15.676 16.4559 15.0429 16.4559 14.6523 16.0654L12.0005 13.4135L9.34871 16.0652C8.95818 16.4558 8.32502 16.4558 7.93449 16.0652C7.54397 15.6747 7.54397 15.0416 7.93449 14.651L10.5863 11.9993L7.93483 9.34784C7.54431 8.95731 7.54431 8.32415 7.93483 7.93363C8.32536 7.5431 8.95852 7.5431 9.34905 7.93363L12.0005 10.5851L14.652 7.93352C15.0425 7.54299 15.6757 7.54299 16.0662 7.93352Z"
        fill={COLOR[color]}
      />
    </svg>
  );
}

export default CloseCircle;
