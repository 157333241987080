import { COLOR } from '@components/atoms';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 12px;
  align-items: flex-start;
  padding: 12px 16px;
  border-radius: 8px;
  background-color: ${COLOR['fill/light']};
`;

export const MedicineContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const MedicineNamesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const MedicineInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: flex-start;
`;

export const MedicineNamesTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 120px;
`;

export const MedicineNamesContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 466px;
`;

export const RotateIcon = styled.div<{ showMoreMedicine: boolean }>`
  rotate: ${({ showMoreMedicine }) => (showMoreMedicine ? '180deg' : '0deg')};
`;

export const ShowMedicineNameToggleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
  cursor: pointer;
`;
