import { IconDetailProps } from '@components/atoms/images';

function PinkPillIcon({ width, height, color }: IconDetailProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
    >
      <circle cx="9.00002" cy="9.00033" r="8.33333" fill="#FFB8B8" />
      <rect
        x="5.46442"
        y="4.28711"
        width="11.6667"
        height="1.66667"
        rx="0.833333"
        transform="rotate(45 5.46442 4.28711)"
        fill="white"
      />
    </svg>
  );
}

export default PinkPillIcon;
