import { GetManagedMedicineItemType } from '@services/myDoctor/types';
import { useAppDispatch, useAppSelector } from '@stores/hooks';
import {
  closeModal_medicineManagementModal,
  openModal_medicineManagementModal,
  selectMedicineManagementModalTargetMedicine,
  selectMedicineManagementModalVisible,
  set_targetMedicine_medicineManagementModal,
} from '@stores/modalStore/medicineManagementModal';

import { useCallback } from 'react';

const useMedicineManagementModalHook = () => {
  const dispatch = useAppDispatch();
  const isVisible = useAppSelector(selectMedicineManagementModalVisible);
  const targetMedicine = useAppSelector(
    selectMedicineManagementModalTargetMedicine,
  );

  const closeModal = useCallback(() => {
    dispatch(closeModal_medicineManagementModal());
  }, [dispatch]);

  const openModal = useCallback(() => {
    dispatch(openModal_medicineManagementModal());
  }, [dispatch]);

  const setManagementMedicineTargetMedicine = (
    medicine: GetManagedMedicineItemType,
  ) => {
    dispatch(set_targetMedicine_medicineManagementModal(medicine));
  };

  return {
    isVisible,
    targetMedicine,
    closeModal,
    openModal,
    setManagementMedicineTargetMedicine,
  };
};

export default useMedicineManagementModalHook;
