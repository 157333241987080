import { useAppDispatch, useAppSelector } from '@stores/hooks';
import {
  closeModal_changeServerURLModal,
  selectChangeServerURLModalVisible,
} from '@stores/modalStore/changeServerURLModal';
import { commonHooks } from '@hooks';
import { useCallback } from 'react';
import { storageHandler } from '@utils';
import { serverURL } from '@constants';

const useChangeServerURLModalHook = () => {
  const dispatch = useAppDispatch();
  const isVisible = useAppSelector(selectChangeServerURLModalVisible);
  const { useInput } = commonHooks;
  const { setLocalStorageState, removeLocalStorageState } = storageHandler;
  const [serverURLText, onChangeServerURLText] = useInput('');

  const closeModal = useCallback(() => {
    dispatch(closeModal_changeServerURLModal());
  }, [dispatch]);

  const setServerURL = useCallback(() => {
    try {
      setLocalStorageState(serverURL.SERVER_URL_KEY, serverURLText);
      alert('서버를 변경했습니다.');
      closeModal();
      window.location.reload();
    } catch (e) {
      alert('서버 변경에 실패했습니다.');
    }
  }, [closeModal, serverURLText, setLocalStorageState]);

  const resetServerURL = useCallback(() => {
    try {
      removeLocalStorageState(serverURL.SERVER_URL_KEY);
      alert('default 서버로 변경했습니다.');
      closeModal();
      window.location.reload();
    } catch (e) {
      alert('서버 변경에 실패했습니다.');
    }
  }, [closeModal, removeLocalStorageState]);

  const buttonVisible = serverURLText.length > 0;

  return {
    isVisible,
    serverURLText,
    onChangeServerURLText,
    buttonVisible,
    closeModal,
    setServerURL,
    resetServerURL,
  };
};

export default useChangeServerURLModalHook;
