import { BoxContainer, COLOR } from '@components/atoms';
import { styleVariables } from '@constants';
import styled from 'styled-components';

const { BREAK_POINTS } = styleVariables;

export const ContentItem = styled(BoxContainer)`
  display: flex;
  flex-direction: column;
  padding: 32px;
  border-radius: 16px;
  box-shadow: 1px 1px 20px 1px rgba(196, 196, 196, 0.3);
  width: 600px;
  margin-bottom: 12px;
  margin-right: 12px;
`;

export const RightContentItem = styled(ContentItem)`
  width: 500px;
  @media (max-width: ${BREAK_POINTS.size1440}px) {
    width: 600px;
  }
`;

export const ContentHeaderArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ContentHeaderTitle = styled.div``;

export const ContentBodyArea = styled.div``;

export const ContentBodyContainer = styled.div`
  display: flex;
  padding: 12px 0;
`;

export const ContentBodyTitleArea = styled.div`
  display: flex;
  width: 180px;
  align-items: flex-start;
`;

export const ContentBodyIcon = styled.div`
  margin-right: 8px;
`;

export const ContentBodyTitle = styled.div``;

export const ContentBodyText = styled.div`
  margin-right: 16px;
  width: 260px;
  height: 100%;
  word-wrap: break-word;
`;

export const ShowMoreTextArea = styled.div`
  width: 100%;
  font-size: 16px;
  line-height: 1.5;
  & a {
    color: ${COLOR['fill/medium']};
  }
`;

export const ContentVertical = styled.div`
  display: flex;
  flex-direction: column;
  width: 360px;
`;

export const ImageListArea = styled.div`
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
`;

export const ImageListRowArea = styled.div`
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
`;

export const ImageIcon = styled.img`
  width: 84px;
  height: 84px;
  margin-right: 5px;
  cursor: pointer;
`;

export const ImageSize = styled.div`
  display: inline;
  width: 84px;
  height: 84px;
  margin-right: 5px;
`;
