import { BColor } from '@components/meraki-ui/BColor';
import { IconDetailProps } from '@components/meraki-ui/BIcon/svg.type';

export default function CalendarPlusIcon({
  width,
  height,
  color,
}: IconDetailProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 9C10.2761 9 10.5 9.22386 10.5 9.5V11H12C12.2761 11 12.5 11.2239 12.5 11.5C12.5 11.7761 12.2761 12 12 12H10.5V13.5C10.5 13.7761 10.2761 14 10 14C9.72386 14 9.5 13.7761 9.5 13.5V12H8C7.72386 12 7.5 11.7761 7.5 11.5C7.5 11.2239 7.72386 11 8 11H9.5V9.5C9.5 9.22386 9.72386 9 10 9Z"
        fill={BColor[color]}
      />
      <path
        d="M5.5 2C5.77614 2 6 2.22386 6 2.5V3H14V2.5C14 2.22386 14.2239 2 14.5 2C14.7761 2 15 2.22386 15 2.5V3H16C17.1046 3 18 3.89543 18 5V16C18 17.1046 17.1046 18 16 18H4C2.89543 18 2 17.1046 2 16V5C2 3.89543 2.89543 3 4 3H5V2.5C5 2.22386 5.22386 2 5.5 2ZM3 6V16C3 16.5523 3.44772 17 4 17H16C16.5523 17 17 16.5523 17 16V6H3Z"
        fill={BColor[color]}
      />
    </svg>
  );
}
