import { useAppDispatch, useAppSelector } from '@stores/hooks';
import {
  closeModal_companyInfoModal,
  selectCompanyInfoModalVisible,
} from '@stores/modalStore/companyInfoModal';
import { useCallback } from 'react';

const useCompanyInfoModalHook = () => {
  const dispatch = useAppDispatch();
  const isVisible = useAppSelector(selectCompanyInfoModalVisible);

  const closeModal = useCallback(() => {
    dispatch(closeModal_companyInfoModal());
  }, [dispatch]);

  return { isVisible, closeModal };
};

export default useCompanyInfoModalHook;
