export const mobileTokenStorage = {
  MOBILE_ACCESS_TOKEN_KEY: 'mobileAccessToken',

  get mobileAccessToken() {
    return localStorage.getItem(this.MOBILE_ACCESS_TOKEN_KEY);
  },

  setAccessToken(mobileAccessToken: string) {
    localStorage.setItem(this.MOBILE_ACCESS_TOKEN_KEY, mobileAccessToken);
  },

  hasAccessToken() {
    return Boolean(this.mobileAccessToken);
  },

  removeAccessToken() {
    localStorage.removeItem(this.MOBILE_ACCESS_TOKEN_KEY);
  },
};

export const telepharmcyStorage = {
  TELEPHARMACY_ID: 'telepharmacy_id',

  get telepharmacyId() {
    return localStorage.getItem(this.TELEPHARMACY_ID);
  },

  setTelepharmacyId(telepharmacyId: number) {
    localStorage.setItem(this.TELEPHARMACY_ID, String(telepharmacyId));
  },

  removetelepharmacyId() {
    localStorage.removeItem(this.TELEPHARMACY_ID);
  },
};
