import { Font } from '@components/atoms';
import SvgIcon from '@components/atoms/images';
import AllowToast from '../allowToast';

function CopyCompleteToast() {
  return (
    <AllowToast
      icon={<SvgIcon icon="checkCircleIcon" color="state/success" width={24} />}
      title={
        <Font fontType="h4" color="fill/black">
          복사 완료
        </Font>
      }
    />
  );
}

export default CopyCompleteToast;
