import { QUERY_KEY } from '@constants/react-query';
import { getPrescriptionDetail } from '@mobile/api';
import { useQuery } from '@tanstack/react-query';

import { useLocation } from 'react-router-dom';

export type TPDetailLocationState = {
  state: { prescriptionId?: string };
};

const usePrescriptionDetail = () => {
  // usePrescriptionDetail을 Accept에도 써야하는 상황이 일어남.
  // 타입은 TPDetailLocationState지만 TPAcceptLocationState에도 presciriptID가 있기에 문제는 안됨.
  const { state } = useLocation() as TPDetailLocationState;

  return useQuery({
    queryFn: () =>
      getPrescriptionDetail({
        prescriptionId: String(state?.prescriptionId || 0),
      }),
    queryKey: [QUERY_KEY.TP_PRESCRIPTION, `${state?.prescriptionId || 0}`],
    retry: false,
  });
};

export default usePrescriptionDetail;
