import { ColorType } from '@components/meraki-ui/BColor';
import { useCallback, useState } from 'react';

interface useBButtonProps {
  textColor: ColorType;
  iconHoverColor?: ColorType;
  iconActiveColor?: ColorType;
  textHoverColor?: ColorType;
  textActiveColor?: ColorType;
  iconColor: ColorType;
}

export function useBButton({
  textColor,
  iconHoverColor,
  iconActiveColor,
  textHoverColor,
  textActiveColor,
  iconColor,
}: useBButtonProps) {
  const [currentTextColor, setCurrentTextColor] =
    useState<ColorType>(textColor);

  const [currentIconColor, setCurrentIconColor] =
    useState<ColorType>(iconColor);

  const onMouseOver = useCallback(() => {
    if (textHoverColor) {
      setCurrentTextColor(textHoverColor);
    }

    if (iconHoverColor) {
      setCurrentIconColor(iconHoverColor);
    }
  }, [iconHoverColor, textHoverColor]);

  const onMouseLeave = useCallback(() => {
    if (currentTextColor !== textColor) {
      setCurrentTextColor(textColor);
    }

    if (currentIconColor !== iconColor) {
      setCurrentIconColor(iconColor);
    }
  }, [currentIconColor, currentTextColor, iconColor, textColor]);

  const onMouseDownCapture = useCallback(() => {
    if (textActiveColor) {
      setCurrentTextColor(textActiveColor);
    }

    if (iconActiveColor) {
      setCurrentIconColor(iconActiveColor);
    }
  }, [iconActiveColor, textActiveColor]);

  const onMouseUpCapture = useCallback(() => {
    if (textHoverColor) {
      setCurrentTextColor(textHoverColor);
    }

    if (iconHoverColor) {
      setCurrentIconColor(iconHoverColor);
    }
  }, [iconHoverColor, textHoverColor]);

  return {
    currentTextColor,
    currentIconColor,
    onMouseOver,
    onMouseLeave,
    onMouseDownCapture,
    onMouseUpCapture,
  };
}
