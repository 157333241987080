export default function LoadingLogoIcon() {
  return (
    <svg
      width="149"
      height="32"
      viewBox="0 0 149 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M100.506 6.56399H107.915C109.725 6.56399 111.313 8.563 111.313 10.5385V22.4818C111.313 24.4603 109.756 26.078 107.804 26.1277L105.456 26.1874H100.506L94.5 26.1875V32H99.9596H108.589C113.509 32 117.498 27.9592 117.498 22.9747V22.4956V10.5385C117.498 5.27053 113.587 1 108.763 1H99.9596H94.5V6.56399H99.9596H100.506ZM120.782 21.3438H126.636C130.2 21.3438 131.697 22.0455 131.859 25.2591L132.102 29.2566C132.142 30.354 132.305 31.3337 132.588 32H138.58C138.296 31.2946 138.094 30.354 138.013 29.1783L137.73 24.8672C137.487 20.7914 136.07 18.7926 132.71 18.2832C136.433 17.225 138.296 14.5601 138.296 10.4842C138.296 4.48801 134.734 1.00002 128.013 1.00002L120.782 1V6.8125L127.081 6.81251C130.359 6.81251 132.345 7.23137 132.345 10.8762C132.345 14.5208 130.321 15.8534 127.042 15.8534H120.782V21.3438ZM145.343 32C147.087 32 148.5 30.5713 148.5 28.8088C148.5 27.0464 147.087 25.6176 145.343 25.6176C143.6 25.6176 142.187 27.0464 142.187 28.8088C142.187 30.5713 143.6 32 145.343 32Z"
        fill="#3478F6"
      />
      <path
        d="M24.6635 0H19.8163V32H24.6635V15.8743H29.366V11.9596H24.6635V0ZM17.9715 22.6981C17.7907 21.5129 17.6098 20.22 17.3928 18.963C14.4989 19.5376 9.94113 20.0763 6.1068 20.0763H5.23865V3.12458H0.5V24.2065H3.71939C7.69841 24.2065 13.8478 23.6678 17.9715 22.6981Z"
        fill="#3478F6"
      />
      <path
        d="M54.5147 0H49.7398V23.2009H54.5147V12.9293H59.0001V9.01459H54.5147V0ZM55.1658 31.1021V27.367H38.1283V20.9383H33.3897V31.1021H55.1658ZM45.2906 17.6341V2.62177H30.0256V17.6341H45.2906ZM40.5519 13.899H34.7281V6.3569H40.5519V13.899Z"
        fill="#3478F6"
      />
      <path
        d="M65.592 9.66105C65.592 7.3266 67.4369 5.78227 69.6796 5.78227C71.9223 5.78227 73.8395 7.3266 73.8395 9.66105C73.8395 11.9596 71.9223 13.5398 69.6796 13.5398C67.4369 13.5398 65.592 11.9596 65.592 9.66105ZM86.5 0H81.7252V32H86.5V0ZM60.9619 9.66105C60.9619 14.2941 64.7239 17.3109 69.6796 17.3109C74.6715 17.3109 78.542 14.2941 78.542 9.66105C78.542 5.06397 74.6715 2.01122 69.6796 2.01122C64.7239 2.01122 60.9619 5.06397 60.9619 9.66105ZM80.5676 24.0629L80.2421 20.3277C77.7823 20.5432 73.8395 20.8664 69.4987 20.9742C66.2793 21.0819 62.9514 21.0819 59.2256 21.0819L59.7682 25.248C62.662 25.284 66.6049 25.1403 70.1498 24.9607C73.4777 24.8171 78.144 24.3861 80.5676 24.0629Z"
        fill="#3478F6"
      />
    </svg>
  );
}
