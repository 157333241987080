import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '@stores';
import { ModifyMedicineGuideModalType } from './index.d';

const initialState: ModifyMedicineGuideModalType = {
  isVisible: false,
  item: {},
};

export const modifyMedicineGuideModal = createSlice({
  name: 'modal/modifyMedicineGuideModal',
  initialState,
  reducers: {
    openModal_modifyMedicineGuideModal: (state, action) => {
      state.isVisible = true;
      state.item = action.payload.item;
    },
    closeModal_modifyMedicineGuideModal: (state) => {
      state.isVisible = false;
      state.item = {};
    },
  },
});

export const {
  openModal_modifyMedicineGuideModal,
  closeModal_modifyMedicineGuideModal,
} = modifyMedicineGuideModal.actions;

export const selectModifyMedicineGuideModalVisible = (state: RootState) =>
  state.modifyMedicineGuideModal.isVisible;
export const selectModifyMedicineGuideModalItem = (state: RootState) =>
  state.modifyMedicineGuideModal.item;

export default modifyMedicineGuideModal.reducer;
