import { Button, COLOR, Font } from '@components/atoms';
import SvgIcon from '@components/atoms/images';
import BModal from '@components/meraki-ui/BModal';
import {
  findCompoundingFee,
  MedicineCompoundingFeeConfigValueType,
  medicineGenericCodeKoreanUnit,
  medicineGenericCodeUnit,
  medicineNameConfig,
} from '@components/organisms/managementMedicineTable/config/domain';
import { commonHooks } from '@hooks';
import { myDoctorAPI } from '@services/myDoctor';
import { ManagedMedicineItemType } from '@services/myDoctor/types';
import { useAppDispatch, useAppSelector } from '@stores/hooks';
import { getManagementMedicineList } from '@stores/telepharmacyStore/telepharmacyList';
import { selectUserInformationPharmacistData } from '@stores/userInformationStore';
import { isNil } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import {
  MedicineCompoundingFee,
  MedicineUnitPrice,
  PaymentPriceExpect,
} from './components';

import useMedicineUnitManagementEditModal from './hooks';
import * as Style from './index.style';

export interface SelectedMedicineListFilteredByMasterCode
  extends ManagedMedicineItemType {
  price?: number | null;
  isDropDownOpen?: boolean;
  isAlreadyExist?: boolean;
}

function MedicineUnitManagementEditModal() {
  const pharmacist = useAppSelector(selectUserInformationPharmacistData);
  const { isVisible, closeModal, targetMedicine } =
    useMedicineUnitManagementEditModal();

  const [selectedMedicine, setSelectedMedicine] =
    useState<ManagedMedicineItemType | null>(null);
  const [medicineUnitPrice, setMedicineUnitPrice] = useState<string>('');
  const [hasCompoundingFee, setHasCompoundingFee] = useState<boolean | null>(
    null,
  );
  const [medicineCompoundingFees, setMedicineCompoundingFees] = useState<
    MedicineCompoundingFeeConfigValueType[] | null
  >(null);
  const [isDisabledAddMedicineButton, setIsDisabledAddMedicineButton] =
    useState(true);

  const handleChangeMedicineUnitPrice = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const inputValue = e.target.value;
    const filteredValue = inputValue.replace(/[^0-9]/g, '');
    const isEmpty = filteredValue === '';
    if (filteredValue.length > 9) return;

    setMedicineUnitPrice(isEmpty ? '' : String(parseInt(filteredValue, 10)));
  };

  const handleChangeMedicineSumInput = (
    e: React.ChangeEvent<HTMLInputElement>,
    quantity: number,
  ) => {
    if (!medicineCompoundingFees) return;
    const inputValue = e.target.value;
    const filteredValue = inputValue.replace(/[^0-9]/g, '');
    const isEmpty = filteredValue === '';
    if (filteredValue.length > 9) return;

    setMedicineCompoundingFees((prev) => {
      return prev!.map((item) =>
        item.quantity === quantity
          ? {
              ...item,
              price: isEmpty ? null : parseInt(filteredValue, 10),
            }
          : item,
      );
    });
  };
  const { useQuery } = commonHooks;
  const query = useQuery();
  const dispatch = useAppDispatch();
  const [limit, setLimit] = useState<number>(200);
  const [isLoading, setIsLoading] = useState(false);

  const pageQuery = query.get('page');
  const page = Number(pageQuery) || 0;

  const handleEditMedicine = async () => {
    if (!selectedMedicine) return;
    if (!medicineCompoundingFees) return;
    if (!targetMedicine || !targetMedicine.id) return;
    if (!pharmacist || !pharmacist.pharmacy_id) return;
    // if (isLoading) return;

    setIsLoading(true);

    try {
      const patchManagedUnitMedicineParam = {
        id: targetMedicine.id,
        pharmacy_medicine: {
          id: targetMedicine.id,
          standard_code: selectedMedicine.standard_code,
          created: null,
          modified: null,
          generic_name_code: selectedMedicine.generic_name_code,
          is_active: true,
          pharmacy: pharmacist.pharmacy_id,
          master_code: selectedMedicine.master_code,
          name: selectedMedicine.name || '',
        },
        created: null,
        modified: null,
        unit: medicineGenericCodeUnit[selectedMedicine.generic_name_code],
        quantity: 1,
        price: Number(medicineUnitPrice),
        is_deleted: false,
        extra: hasCompoundingFee
          ? {
              preparation_fee: medicineCompoundingFees,
            }
          : {},
      };

      await myDoctorAPI
        .putManagementUnitMedicine(patchManagedUnitMedicineParam)
        .then(() => {
          dispatch(
            getManagementMedicineList({
              id: pharmacist.pharmacy_id || 0,
              offset: page * limit,
              limit,
            }),
          );
        })
        .then(() => {
          setIsLoading(false);
          closeModal();
        });
    } catch (e) {
      console.log('handleAddMedicine error', e);
    }
  };

  useEffect(() => {
    if (targetMedicine && targetMedicine?.id) {
      setSelectedMedicine({
        id: targetMedicine.id,
        standard_code: targetMedicine.pharmacy_medicine.standard_code,
        generic_name_code: targetMedicine.pharmacy_medicine.generic_name_code,
        korean_product_name:
          medicineNameConfig[targetMedicine.pharmacy_medicine.standard_code],
        product_total_quantity: String(targetMedicine.quantity),
        dosage_form:
          medicineGenericCodeKoreanUnit[
            targetMedicine.pharmacy_medicine.generic_name_code
          ],
        packaging_type: targetMedicine.unit,
        special_general_type: '전문의약품',
        generic_name: null,
        master_code: targetMedicine.pharmacy_medicine.master_code,
        name: targetMedicine.pharmacy_medicine.name,
        default: false,
      });

      setMedicineUnitPrice(String(targetMedicine.price));

      if (targetMedicine.extra.preparation_fee) {
        setMedicineCompoundingFees(targetMedicine.extra?.preparation_fee);
        setHasCompoundingFee(!!targetMedicine.extra?.preparation_fee[0]);
        return;
      }

      setMedicineCompoundingFees(
        findCompoundingFee(targetMedicine.pharmacy_medicine.generic_name_code),
      );
      setHasCompoundingFee(false);
    }
  }, [closeModal, targetMedicine]);

  const isMedicineAddButtonActive = useMemo(() => {
    const isMedicineSelected = Boolean(selectedMedicine);
    const isUnitPriceSet =
      Boolean(medicineUnitPrice) && Number(medicineUnitPrice) > 0;
    const isCompoundingFeeValid =
      hasCompoundingFee === false ||
      (hasCompoundingFee === true &&
        medicineCompoundingFees &&
        medicineCompoundingFees.every((fee) => !isNil(fee.price)));
    return isMedicineSelected && isUnitPriceSet && isCompoundingFeeValid;
  }, [
    hasCompoundingFee,
    medicineCompoundingFees,
    medicineUnitPrice,
    selectedMedicine,
  ]);

  useEffect(() => {
    if (isMedicineAddButtonActive) {
      setIsDisabledAddMedicineButton(false);
    } else {
      setIsDisabledAddMedicineButton(true);
    }
  }, [isMedicineAddButtonActive]);

  return (
    <BModal
      open={isVisible}
      width={960}
      padding={30}
      onOpenChange={() => {
        closeModal();
      }}
    >
      <Style.ModalArea>
        <Style.TitleArea>
          <Style.TitleContentArea>
            <Font fontType="display2" color="fill/black">
              비급여 의약품 정보 수정
            </Font>
            <Style.TitleContentNoticeArea>
              <SvgIcon
                icon="infoIcon"
                color="fill/medium"
                width={16}
                height={16}
              />
              <Font fontType="body2" color="fill/medium">
                의약품 정보 수정 후 [저장하기] 버튼을 클릭하셔야 최종
                반영됩니다.
              </Font>
            </Style.TitleContentNoticeArea>
          </Style.TitleContentArea>

          <Style.TitleButtonsArea>
            <Button
              padding="16px 66px"
              borderRadius="12px"
              borderColor={COLOR['border/outline']}
              backgroundColor={COLOR['fill/white']}
              title={
                <Font fontType="body1" color="fill/dark">
                  취소
                </Font>
              }
              onClick={closeModal}
            />
            <Button
              padding="16px 66px"
              borderRadius="12px"
              backgroundColor={
                isDisabledAddMedicineButton
                  ? COLOR['disabled/background']
                  : COLOR['fill/accent']
              }
              title={
                <Font
                  fontType="body1"
                  color={
                    isDisabledAddMedicineButton
                      ? 'disabled/foreground'
                      : 'fill/white'
                  }
                >
                  저장하기
                </Font>
              }
              onClick={handleEditMedicine}
              disabled={isDisabledAddMedicineButton}
            />
          </Style.TitleButtonsArea>
        </Style.TitleArea>

        <Style.ModalBodyArea>
          <Style.ModalLeftBodyArea
            medicineCompoundingFeesLength={medicineCompoundingFees?.length}
          >
            <Style.ModalLeftBodyTitleArea>
              <Font fontType="h2" color="fill/black">
                {targetMedicine?.pharmacy_medicine.name}
              </Font>
              <Font fontType="body1" color="fill/medium">
                {targetMedicine?.pharmacy_medicine.master_code}
              </Font>
            </Style.ModalLeftBodyTitleArea>

            <MedicineUnitPrice
              selectedMedicine={selectedMedicine}
              medicineUnitPrice={medicineUnitPrice}
              handleChangeMedicineUnitPrice={handleChangeMedicineUnitPrice}
            />

            <MedicineCompoundingFee
              hasCompoundingFee={hasCompoundingFee}
              selectedMedicine={selectedMedicine}
              medicineCompoundingFees={medicineCompoundingFees}
              setHasCompoundingFee={setHasCompoundingFee}
              handleChangeMedicineSumInput={handleChangeMedicineSumInput}
            />
          </Style.ModalLeftBodyArea>

          <Style.ModalRightBodyArea>
            <PaymentPriceExpect
              isShowPaymentPriceExpectArea={isMedicineAddButtonActive}
              hasCompoundingFee={hasCompoundingFee}
              medicineUnitPrice={medicineUnitPrice}
              selectedMedicine={selectedMedicine}
              medicineCompoundingFees={medicineCompoundingFees}
            />
          </Style.ModalRightBodyArea>
        </Style.ModalBodyArea>
      </Style.ModalArea>
    </BModal>
  );
}

export default MedicineUnitManagementEditModal;
