import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  position: relative;
  flex: 1;
  flex-direction: column;
  padding: 40px 60px;
`;

export const Breadcrumb = styled.div``;

export const Header = styled.div`
  display: flex;
  height: 56px;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  &.smallHeader {
    width: 612px;
  }
`;

export const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
`;

export const HeaderRight = styled.div`
  display: flex;
  margin-right: 12px;
`;

export const HeaderButtonArea = styled.div`
  margin-left: 8px;
`;

export const HeaderButtonIcon = styled.img`
  width: 16px;
  height: 16px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Content = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  gap: 12px;

  &.smallContent {
    flex-direction: column;
  }
`;

export const LeftContent = styled.div`
  flex: 4;
  overflow: hidden;
`;

export const RightContent = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
