import { Blank, Font } from '@components/atoms';
import { HospitalInformationPartItemType } from './index.d';
import * as Style from './index.style';

function HospitalInformationPartItem({
  item,
}: HospitalInformationPartItemType) {
  const hospitalInformationList = [
    {
      id: 0,
      title: '병원명',
      text: item.tm?.hospital_name || '-',
    },
    {
      id: 1,
      title: '의사명',
      text: item.tm?.doctor?.name || '-',
    },
    {
      id: 2,
      title: '병원 연락처',
      text: item.tm?.hospital_phone || '-',
    },
  ];

  return (
    <Style.RightContentItem>
      <Style.ContentHeaderArea>
        <Style.ContentHeaderTitle>
          <Font fontType="h1" color="fill/black">
            병원정보
          </Font>
        </Style.ContentHeaderTitle>
      </Style.ContentHeaderArea>
      <Blank appoint="Vertical" size={12} />
      <Style.ContentBodyArea>
        {hospitalInformationList.map((hospitalInformationItem) => {
          return (
            <Style.ContentBodyContainer key={hospitalInformationItem.id}>
              <Style.ContentBodyTitleArea>
                <Style.ContentBodyTitle>
                  <Font fontType="body1" color="fill/medium">
                    {hospitalInformationItem.title}
                  </Font>
                </Style.ContentBodyTitle>
              </Style.ContentBodyTitleArea>
              <Style.ContentBodyTextArea>
                <Style.ContentBodyText>
                  <Font fontType="h4" color="fill/black">
                    {hospitalInformationItem.text}
                  </Font>
                </Style.ContentBodyText>
              </Style.ContentBodyTextArea>
            </Style.ContentBodyContainer>
          );
        })}
      </Style.ContentBodyArea>
    </Style.RightContentItem>
  );
}

export default HospitalInformationPartItem;
