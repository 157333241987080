import { Font } from '../index';
import checkBox_checked from './icon/checkBox_checked.png';
import checkBox_unChecked from './icon/checkBox_unChecked.png';
import { CheckBoxType } from './index.d';
import { Check, Container } from './index.style';

function CheckBox({ title, onClick, isSelected }: CheckBoxType) {
  return (
    <Container onClick={() => onClick()}>
      <Check src={isSelected ? checkBox_checked : checkBox_unChecked} />
      <Font fontType={isSelected ? 'body1' : 'body1_medium'} color="fill/dark">
        {title}
      </Font>
    </Container>
  );
}

export default CheckBox;
