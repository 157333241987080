import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '@stores';
import { FinishTelepharmacyModalType } from './index.d';

const initialState: FinishTelepharmacyModalType = {
  isVisible: false,
  item: {},
};

export const finishTelepharmacyModal = createSlice({
  name: 'modal/finishTelepharmacyModal',
  initialState,
  reducers: {
    openModal_finishTelepharmacyModal: (state, action) => {
      state.isVisible = true;
      state.item = action.payload.item;
    },
    closeModal_finishTelepharmacyModal: (state) => {
      state.isVisible = false;
      state.item = {};
    },
  },
});

export const {
  openModal_finishTelepharmacyModal,
  closeModal_finishTelepharmacyModal,
} = finishTelepharmacyModal.actions;

export const selectFinishTelepharmacyModalVisible = (state: RootState) =>
  state.finishTelepharmacyModal.isVisible;
export const selectFinishTelepharmacyModalItem = (state: RootState) =>
  state.finishTelepharmacyModal.item;

export default finishTelepharmacyModal.reducer;
