import { GetManagedMedicineItemType } from '@services/myDoctor/types';
import { useAppDispatch, useAppSelector } from '@stores/hooks';
import {
  closeModal_medicineUnitManagementEditModal,
  openModal_medicineUnitManagementEditModal,
  selectMedicineUnitManagementEditModalTargetMedicine,
  selectMedicineUnitManagementEditModalVisible,
  set_targetMedicine_medicineUnitManagementEditModal,
} from '@stores/modalStore/medicineUnitManagementEditModal';
import { useCallback } from 'react';

const useMedicineUnitManagementEditModal = () => {
  const dispatch = useAppDispatch();
  const isVisible = useAppSelector(
    selectMedicineUnitManagementEditModalVisible,
  );
  const targetMedicine = useAppSelector(
    selectMedicineUnitManagementEditModalTargetMedicine,
  );

  const closeModal = useCallback(() => {
    dispatch(closeModal_medicineUnitManagementEditModal());
  }, [dispatch]);

  const openModal = useCallback(() => {
    dispatch(openModal_medicineUnitManagementEditModal());
  }, [dispatch]);

  const setManagementMedicineUnitTargetMedicine = (
    medicine: GetManagedMedicineItemType,
  ) => {
    dispatch(set_targetMedicine_medicineUnitManagementEditModal(medicine));
  };

  return {
    isVisible,
    targetMedicine,
    closeModal,
    openModal,
    setManagementMedicineUnitTargetMedicine,
  };
};

export default useMedicineUnitManagementEditModal;
