import { IconDetailProps } from '@components/atoms/images';
import { COLOR } from '../color';

function SpeechIcon({ width, height, color }: IconDetailProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M17.5625 1.02429H2.43752C1.63543 1.02429 1.02432 1.6736 1.02432 2.43749V12.4062C1.02432 13.2083 1.67363 13.8194 2.43752 13.8194H3.31599V18.1736C3.31599 18.4792 3.50696 18.7465 3.77432 18.8611C3.85071 18.8993 3.96529 18.8993 4.04168 18.8993C4.23266 18.8993 4.42363 18.8229 4.57641 18.6701L9.27432 13.7812H17.4861C18.2882 13.7812 18.8993 13.1319 18.8993 12.368V2.43749C18.9757 1.6736 18.3264 1.02429 17.5625 1.02429ZM9.04516 7.74651L7.74654 9.65624H6.25696L7.05904 7.74651C6.6771 7.47915 6.48613 7.09721 6.48613 6.63888C6.48613 6.18054 6.63891 5.7986 6.90627 5.53124C7.17363 5.26388 7.55557 5.1111 7.97571 5.1111C8.39585 5.1111 8.7396 5.22568 9.04516 5.49304C9.31252 5.7604 9.46529 6.10415 9.46529 6.52429C9.46529 6.94443 9.31252 7.36457 9.04516 7.74651ZM13.3611 7.74651L12.0625 9.65624H10.5729L11.375 7.74651C10.9931 7.47915 10.8021 7.09721 10.8021 6.63888C10.8021 6.18054 10.9549 5.7986 11.2222 5.53124C11.4896 5.26388 11.8715 5.1111 12.2917 5.1111C12.7118 5.1111 13.0556 5.22568 13.3611 5.49304C13.6285 5.7604 13.7813 6.10415 13.7813 6.52429C13.7813 6.94443 13.5903 7.36457 13.3611 7.74651Z"
        fill={COLOR[color]}
      />
    </svg>
  );
}

export default SpeechIcon;
