import { BColor } from '@components/meraki-ui/BColor';
import { IconDetailProps } from '@components/meraki-ui/BIcon/svg.type';

export default function DuplicateIcon({
  width,
  height,
  color,
}: IconDetailProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.1538 2.61548H7.69231C6.67271 2.61548 5.84615 3.44203 5.84615 4.46163C4.82655 4.46163 4 5.28818 4 6.30779V15.5386C4 16.5582 4.82655 17.3847 5.84615 17.3847H12.3077C13.3273 17.3847 14.1538 16.5582 14.1538 15.5386C15.1734 15.5386 16 14.712 16 13.6924V4.46163C16 3.44203 15.1734 2.61548 14.1538 2.61548ZM14.1538 14.6155V6.30779C14.1538 5.28818 13.3273 4.46163 12.3077 4.46163H6.76923C6.76923 3.95183 7.18251 3.53856 7.69231 3.53856H14.1538C14.6636 3.53856 15.0769 3.95183 15.0769 4.46163V13.6924C15.0769 14.2022 14.6636 14.6155 14.1538 14.6155ZM4.92308 6.30779C4.92308 5.79798 5.33635 5.38471 5.84615 5.38471H12.3077C12.8175 5.38471 13.2308 5.79798 13.2308 6.30779V15.5386C13.2308 16.0484 12.8175 16.4616 12.3077 16.4616H5.84615C5.33635 16.4616 4.92308 16.0484 4.92308 15.5386V6.30779Z"
        fill={BColor[color]}
      />
    </svg>
  );
}
