import { FormEvent, useState } from 'react';
import { OnChangeType } from './index.d';

const useInput = (
  initialValue: string,
  validator?: (value: string) => boolean,
) => {
  const [text, setText] = useState(initialValue);
  const onChange = (
    event: FormEvent<HTMLInputElement> | FormEvent<HTMLTextAreaElement>,
  ) => {
    const {
      currentTarget: { value },
    } = event;
    if (validator === undefined) {
      setText(value);
    } else {
      const willdata = validator(value);
      if (willdata) {
        setText(value);
      }
    }
  };
  return [text, onChange, setText] as [string, OnChangeType, typeof setText];
};

export default useInput;
