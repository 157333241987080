import { Button, COLOR, Font } from '@components/atoms';
import { PageStatus } from '@components/organisms/telepharmacyDetailInformation';
import CopyCompleteToast from '@components/organisms/toast/copyCompleteToast';
import { GetPrescriptionDetail } from '@services/myDoctor/types';
import { useAppSelector } from '@stores/hooks';
import { selectUserInformationPharmacistData } from '@stores/userInformationStore';
import { handleCopyClipBoard } from '@utils/clipboard';
import { Mixpanel } from '@utils/mixpanel';
import { isNil } from 'lodash';
import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { OCR_INFO_LISTITEM_FLEX } from '../..';
import ListContent from '../listContent';
import * as Style from './index.style';

function ListItem({
  item,
  prescriptionId,
  pageType,
}: {
  item: GetPrescriptionDetail;
  prescriptionId: number | undefined;
  pageType: PageStatus;
}) {
  const pharmacist = useAppSelector(selectUserInformationPharmacistData);

  const onClick = useCallback(async (copyText: string) => {
    if (copyText !== '-') {
      try {
        await handleCopyClipBoard(copyText);
        toast(<CopyCompleteToast />, { position: 'top-center' });
      } catch (error) {
        console.error(error);
      }
    }
  }, []);

  const canShowGenericName = item.drug_code;
  const canShowDrugName = item.drug_name;
  const showDosingInfoError =
    isNil(item.one_dose) ||
    isNil(item.dosing_per_day) ||
    isNil(item.total_dosing_days);
  const showDosingInfoAllError =
    isNil(item.one_dose) &&
    isNil(item.dosing_per_day) &&
    isNil(item.total_dosing_days);

  return (
    <Style.ListItem>
      <ListContent.Element
        flex={OCR_INFO_LISTITEM_FLEX[0].flex}
        flexDirection={canShowGenericName ? 'row' : 'column'}
        justifyContent="space-between"
      >
        <Font
          fontType="body2_medium"
          color={canShowGenericName ? 'fill/black' : 'state/distructive'}
        >
          {canShowGenericName ? item.drug_code : '-'}
        </Font>
        {canShowGenericName && (
          <Button
            title={
              <Font fontType="body2" color="fill/dark">
                복사
              </Font>
            }
            padding="5px 12px"
            onClick={() => {
              onClick(item.drug_code);
              Mixpanel.track('[Click] Copy Drug Code', {
                Status: pageType,
                'TP ID': prescriptionId,
                'PH ID': pharmacist.pharmacy_id,
                'Drug Code': item.drug_code,
                'Drug Name': item.drug_name,
              });
            }}
            backgroundColor={COLOR['fill/white']}
            borderColor={COLOR['border/outline']}
            borderRadius="6px"
          />
        )}
        {!canShowGenericName && (
          <Font fontType="body3" color="state/distructive">
            처방전 확인 필요
          </Font>
        )}
      </ListContent.Element>

      <ListContent.Element
        flex={OCR_INFO_LISTITEM_FLEX[1].flex}
        flexDirection={canShowDrugName ? 'row' : 'column'}
        justifyContent="space-between"
      >
        <Font
          fontType="body2_medium"
          color={canShowDrugName ? 'fill/black' : 'state/distructive'}
        >
          {canShowDrugName ? item.drug_name : '-'}
        </Font>
        {canShowDrugName && (
          <Button
            title={
              <Font fontType="body2" color="fill/dark">
                복사
              </Font>
            }
            padding="5px 12px"
            onClick={() => {
              onClick(item.drug_name);
              Mixpanel.track('[Click] Copy Drug Name', {
                Status: pageType,
                'TP ID': prescriptionId,
                'PH ID': pharmacist.pharmacy_id,
                'Drug Code': item.drug_code,
                'Drug Name': item.drug_name,
              });
            }}
            backgroundColor={COLOR['fill/white']}
            borderColor={COLOR['border/outline']}
            borderRadius="6px"
          />
        )}
        {!canShowDrugName && (
          <Font fontType="body3" color="state/distructive">
            처방전 확인 필요
          </Font>
        )}
      </ListContent.Element>

      <ListContent.Element
        flex={OCR_INFO_LISTITEM_FLEX[2].flex}
        flexDirection={showDosingInfoError ? 'column' : 'row'}
      >
        <Font
          fontType="body2_medium"
          color={showDosingInfoError ? 'state/distructive' : 'fill/black'}
        >
          {showDosingInfoAllError
            ? '- / - / -'
            : `${Number(item.one_dose) || '-'}/${item.dosing_per_day || '-'}/${
                item.total_dosing_days || '-'
              }`}
        </Font>
        {showDosingInfoError && (
          <Font fontType="body3" color="state/distructive">
            처방전 확인 필요
          </Font>
        )}
      </ListContent.Element>
    </Style.ListItem>
  );
}

export default ListItem;
