import styled from 'styled-components';

export const Image = styled.img`
  height: 80vh;
  width: auto;
`;

export const LeftButtonIcon = styled.div`
  position: absolute;
  top: 40vh;
  left: 30px;
  cursor: pointer;
`;

export const RightButtonIcon = styled.div`
  position: absolute;
  top: 40vh;
  right: 30px;
  cursor: pointer;
`;
