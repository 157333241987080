import { BColor } from '@components/meraki-ui/BColor';
import { IconDetailProps } from '@components/meraki-ui/BIcon/svg.type';

export default function QuestionMarkInCircleIcon({
  width,
  height,
  color,
}: IconDetailProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 13.25C5.10051 13.25 2.75 10.8995 2.75 8C2.75 5.10051 5.10051 2.75 8 2.75C10.8995 2.75 13.25 5.10051 13.25 8C13.25 10.8995 10.8995 13.25 8 13.25ZM8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z"
        fill={BColor[color]}
      />
      <path
        d="M5.94133 6.33961C5.93564 6.44177 6.01993 6.52454 6.12225 6.52454H6.74062C6.84417 6.52454 6.92651 6.4401 6.94042 6.33748C7.00714 5.84539 7.34488 5.48669 7.94691 5.48669C8.46107 5.48669 8.93239 5.74377 8.93239 6.36267C8.93239 6.83875 8.6515 7.05774 8.20876 7.39099C7.70412 7.75757 7.30421 8.18604 7.33278 8.8811L7.33501 9.04371C7.33641 9.14625 7.41994 9.22864 7.52249 9.22864H8.13075C8.23431 9.22864 8.31825 9.14469 8.31825 9.04114V8.96204C8.31825 8.42407 8.52296 8.26697 9.07521 7.84802C9.53224 7.50049 10.0083 7.11487 10.0083 6.30554C10.0083 5.17249 9.05141 4.625 8.00404 4.625C7.05373 4.625 6.01223 5.06796 5.94133 6.33961ZM7.10902 10.6616C7.10902 11.0615 7.42799 11.3567 7.86598 11.3567C8.32301 11.3567 8.63722 11.0615 8.63722 10.6616C8.63722 10.2474 8.32301 9.95703 7.86598 9.95703C7.42799 9.95703 7.10902 10.2474 7.10902 10.6616Z"
        fill={BColor[color]}
      />
    </svg>
  );
}
