import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@stores';
import { RejectTelepharmacyModalType } from './index.d';

const initialState: RejectTelepharmacyModalType = {
  isVisible: false,
  item: {},
};

export const rejectTelepharmacyModal = createSlice({
  name: 'modal/rejectTelepharmacyModal',
  initialState,
  reducers: {
    openModal_rejectTelepharmacyModal: (
      state,
      action: PayloadAction<RejectTelepharmacyModalType>,
    ) => {
      state.isVisible = true;
      state.item = action.payload.item;
    },
    closeModal_rejectTelepharmacyModal: (state) => {
      state.isVisible = false;
      state.item = {};
    },
  },
});

export const {
  openModal_rejectTelepharmacyModal,
  closeModal_rejectTelepharmacyModal,
} = rejectTelepharmacyModal.actions;

export const selectRejectTelepharmacyModalVisible = (state: RootState) =>
  state.rejectTelepharmacyModal.isVisible;
export const selectRejectTelepharmacyModalItem = (state: RootState) =>
  state.rejectTelepharmacyModal.item;

export default rejectTelepharmacyModal.reducer;
