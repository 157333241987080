import { Font, TextInput } from '@components/atoms';
import { DebouncedFunc } from 'lodash';
import { memo, useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { PhoneLoginFormData, PhoneLoginFormFields } from '../../hooks';

type ILoginPhoneNumberInputComponentProps = {
  submitPhone: DebouncedFunc<() => void>;
};

export const LoginPhoneNumberInputComponent =
  memo<ILoginPhoneNumberInputComponentProps>(({ submitPhone }) => {
    const { control, formState } = useFormContext<PhoneLoginFormData>();

    const errors = formState.errors?.[PhoneLoginFormFields.PHONE_NUMBER];
    const errorMessage =
      formState?.errors?.[PhoneLoginFormFields.PHONE_NUMBER]?.message;

    const onPressEnter = useCallback(
      (e: any) => {
        if (e.key === 'Enter') {
          submitPhone();
        }
      },
      [submitPhone],
    );

    return (
      <Controller
        control={control}
        name={PhoneLoginFormFields.PHONE_NUMBER}
        render={({ field: { onChange, value } }) => {
          return (
            <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
              <TextInput
                value={value}
                onChange={onChange}
                onKeyPress={onPressEnter}
                placeholder="휴대폰 번호를 입력해주세요"
                type="text"
                fontSize={16}
                errorMessage={value.length > 0 && errorMessage}
              />
              {errors && value.length > 0 && (
                <div style={{ paddingLeft: 20 }}>
                  <Font fontType="body2" color="state/distructive">
                    {errorMessage}
                  </Font>
                </div>
              )}
            </div>
          );
        }}
      />
    );
  });
