import { SPACING_VARIABLES } from '@constants/styleVariables';
import styled from 'styled-components';

export const EmptyArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(100vw - ${SPACING_VARIABLES.lnbWidth}px);
  height: calc(100vh - 156px);
`;

export const EmptyIconArea = styled.div`
  margin-bottom: 20px;
`;

export const EmptyFontArea = styled.div``;
