import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '@stores';
import { RecheckRequestModalType } from './index.d';

const initialState: RecheckRequestModalType = {
  isVisible: false,
  item: {},
};

export const recheckRequestModal = createSlice({
  name: 'modal/recheckRequestModal',
  initialState,
  reducers: {
    openModal_recheckRequestModal: (state, action) => {
      state.isVisible = true;
      state.item = action.payload.item;
    },
    closeModal_recheckRequestModal: (state) => {
      state.isVisible = false;
      state.item = {};
    },
  },
});

export const { openModal_recheckRequestModal, closeModal_recheckRequestModal } =
  recheckRequestModal.actions;

export const selectRecheckRequestModalVisible = (state: RootState) =>
  state.recheckRequestModal.isVisible;
export const selectRecheckRequestModalItem = (state: RootState) =>
  state.recheckRequestModal.item;

export default recheckRequestModal.reducer;
