import { BColor } from '@components/meraki-ui/BColor';
import { IconDetailProps } from '@components/meraki-ui/BIcon/svg.type';

export default function XInCircleIcon({
  width,
  height,
  color,
}: IconDetailProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10C17 13.866 13.866 17 10 17ZM10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18Z"
        fill={BColor[color]}
      />
      <path
        d="M6.64645 6.64645C6.84171 6.45118 7.15829 6.45118 7.35355 6.64645L10 9.29289L12.6464 6.64645C12.8417 6.45118 13.1583 6.45118 13.3536 6.64645C13.5488 6.84171 13.5488 7.15829 13.3536 7.35355L10.7071 10L13.3536 12.6464C13.5488 12.8417 13.5488 13.1583 13.3536 13.3536C13.1583 13.5488 12.8417 13.5488 12.6464 13.3536L10 10.7071L7.35355 13.3536C7.15829 13.5488 6.84171 13.5488 6.64645 13.3536C6.45118 13.1583 6.45118 12.8417 6.64645 12.6464L9.29289 10L6.64645 7.35355C6.45118 7.15829 6.45118 6.84171 6.64645 6.64645Z"
        fill={BColor[color]}
      />
    </svg>
  );
}
