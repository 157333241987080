import styled from 'styled-components';

export const Container = styled.div<{
  backgroundColor: string;
  color: string;
}>`
  font-size: 11px;
  color: ${({ color }) => color};
  background-color: ${({ backgroundColor }) => backgroundColor};
  width: 30px;
  height: 18px;
  border-radius: 4px;
  padding: 2px 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
