import styled from 'styled-components';
import { StatusTagStyleType } from './index.d';

export const StatusTagContainer = styled.div<{ data: StatusTagStyleType }>`
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
