import useChangeServerURLModalHook from './hooks';
import ChangeServerURLModalTemplate from './templates';

function ChangeServerURLModal() {
  const {
    isVisible,
    serverURLText,
    onChangeServerURLText,
    buttonVisible,
    closeModal,
    setServerURL,
    resetServerURL,
  } = useChangeServerURLModalHook();

  return (
    <ChangeServerURLModalTemplate
      isVisible={isVisible}
      serverURLText={serverURLText}
      onChangeServerURLText={onChangeServerURLText}
      buttonVisible={buttonVisible}
      closeModal={closeModal}
      setServerURL={setServerURL}
      resetServerURL={resetServerURL}
    />
  );
}

export default ChangeServerURLModal;
