import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '@stores';

interface InitialStateType {
  isVisible: boolean;
  selectedCategory: string | null;
}

const initialState: InitialStateType = {
  isVisible: false,
  selectedCategory: null,
};

export const medicineUnitManagementModal = createSlice({
  name: 'modal/medicineUnitManagementModal',
  initialState,
  reducers: {
    openModal_medicineUnitManagementModal: (state) => {
      state.isVisible = true;
    },
    closeModal_medicineUnitManagementModal: (state) => {
      state.isVisible = false;
    },
    set_selectedMedicineCategory: (state, action) => {
      state.selectedCategory = action.payload;
    },
  },
});

export const {
  openModal_medicineUnitManagementModal,
  closeModal_medicineUnitManagementModal,
  set_selectedMedicineCategory,
} = medicineUnitManagementModal.actions;

export const selectedMedicineUnitManagementModalVisible = (state: RootState) =>
  state.medicineUnitManagementModal.isVisible;

export const setSelectedMedicineCategory = (state: RootState) =>
  state.medicineUnitManagementModal.selectedCategory;

export default medicineUnitManagementModal.reducer;
