import { Blank, Font } from '@components/atoms';
import ShowMoreText from 'react-show-more-text';
import { SymptomInformationPartItemType } from './index.d';
import * as Style from './index.style';

function SymptomInformationPartItem({ item }: SymptomInformationPartItemType) {
  const symptomInformationList = [
    {
      id: 0,
      icon: null,
      title: '증상',
      item,
      text: item.tm?.symptom_text,
      symptom_image1: item.tm?.symptom_image1,
      symptom_image2: item.tm?.symptom_image2,
      symptom_image3: item.tm?.symptom_image3,
      symptom_image4: item.tm?.symptom_image4,
    },
  ];

  return (
    <Style.RightContentItem>
      <Style.ContentHeaderArea>
        <Style.ContentHeaderTitle>
          <Font fontType="h1">증상정보</Font>
        </Style.ContentHeaderTitle>
      </Style.ContentHeaderArea>
      <Blank appoint="Vertical" size={12} />
      <Style.ContentBodyArea>
        {symptomInformationList.map((symptomInformationItem) => {
          return (
            <Style.ContentBodyContainer key={symptomInformationItem.id}>
              <Style.ContentBodyTitleArea>
                {symptomInformationItem.icon ? (
                  <Style.ContentBodyIcon>
                    {symptomInformationItem.icon}
                  </Style.ContentBodyIcon>
                ) : null}
                <Style.ContentBodyTitle>
                  <Font fontType="body1">{symptomInformationItem.title}</Font>
                </Style.ContentBodyTitle>
              </Style.ContentBodyTitleArea>
              <Style.ContentVertical>
                <Style.ContentBodyText>
                  <Style.ShowMoreTextArea>
                    <ShowMoreText
                      lines={2}
                      more="더보기"
                      less="접기"
                      expanded={false}
                      truncatedEndingComponent="... "
                    >
                      {symptomInformationItem.text}
                    </ShowMoreText>
                  </Style.ShowMoreTextArea>
                </Style.ContentBodyText>
                {/* <Style.ImageListArea>
                  <Style.ImageListRowArea>
                    {symptomInformationItem.symptom_image1 ? (
                      <Style.ImageIcon
                        src={symptomInformationItem.symptom_image1}
                        onClick={() =>
                          dispatch(
                            openModal_symptomImageModal({
                              item: symptomInformationItem.item,
                              index: 0,
                            }),
                          )
                        }
                      />
                    ) : (
                      <Style.ImageSize />
                    )}
                    {symptomInformationItem.symptom_image2 ? (
                      <Style.ImageIcon
                        src={symptomInformationItem.symptom_image2}
                        onClick={() =>
                          dispatch(
                            openModal_symptomImageModal({
                              item: symptomInformationItem.item,
                              index: 1,
                            }),
                          )
                        }
                      />
                    ) : (
                      <Style.ImageSize />
                    )}
                  </Style.ImageListRowArea>
                  <Style.ImageListRowArea>
                    {symptomInformationItem.symptom_image3 ? (
                      <Style.ImageIcon
                        src={symptomInformationItem.symptom_image3}
                        onClick={() =>
                          dispatch(
                            openModal_symptomImageModal({
                              item: symptomInformationItem.item,
                              index: 2,
                            }),
                          )
                        }
                      />
                    ) : (
                      <Style.ImageSize />
                    )}
                    {symptomInformationItem.symptom_image4 ? (
                      <Style.ImageIcon
                        src={symptomInformationItem.symptom_image4}
                        onClick={() =>
                          dispatch(
                            openModal_symptomImageModal({
                              item: symptomInformationItem.item,
                              index: 3,
                            }),
                          )
                        }
                      />
                    ) : (
                      <Style.ImageSize />
                    )}
                  </Style.ImageListRowArea>
                </Style.ImageListArea> */}
              </Style.ContentVertical>
            </Style.ContentBodyContainer>
          );
        })}
      </Style.ContentBodyArea>
      <Blank appoint="Vertical" size={12} />
    </Style.RightContentItem>
  );
}

export default SymptomInformationPartItem;
