import { memo, ReactNode } from 'react';

import { COLOR } from '@components/atoms';
import { BTheme, SpaceType } from '@components/meraki-ui/BTheme';
import { getLength } from '@components/meraki-ui/style';
import * as Dialog from '@radix-ui/react-dialog';
import styled, { keyframes } from 'styled-components';

interface BModalProps {
  open: boolean;
  onOpenChange: () => void;

  width: number;
  height?: number;
  padding: SpaceType;
  disableOnOpenChangeByClickOverlay?: boolean;

  children: ReactNode;
}

function BModal({
  children,
  open,
  onOpenChange,
  width,
  height,
  padding,
  disableOnOpenChangeByClickOverlay,
}: BModalProps) {
  return (
    <Dialog.Root
      open={open}
      onOpenChange={disableOnOpenChangeByClickOverlay ? () => {} : onOpenChange}
    >
      <Dialog.Portal>
        <StyledOverlay />
        <StyledContent padding={padding} width={width} height={height}>
          {children}
        </StyledContent>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

const overlayShow = keyframes`
  0%: { opacity: 0; }
  100%: { opacity: 1; }
`;

const contentShow = keyframes`
  0%: { opacity: 0; transform: translate(-50%, -48%) scale(.96); }
  100%: { opacity: 1; transform: translate(-50%, -50%) scale(1); }
`;

const StyledOverlay = styled(Dialog.Overlay)`
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  z-index: 9999;
  @media (prefers-reduced-motion: no-preference): {
    animation: ${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1);
  },
`;

const StyledContent = styled(Dialog.Content)<
  Omit<BModalProps, 'open' | 'onOpenChange' | 'children'>
>`
  background-color: ${COLOR['fill/white']};
  border-radius: 1.5rem;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 9999;
  transform: translate(-50%, -50%);
  width: ${(props) => getLength(props.width)};
  max-height: ${(props) =>
    props.height ? getLength(props.height) : props.height};

  overflow: ${(props) => (props.height ? 'auto' : undefined)};
  padding: ${(props) => BTheme.space[props.padding]};

  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
  @media (prefers-reduced-motion: no-preference) : {
    animation: ${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1);
  }

  &:focus: {
    outline: none;
  }
`;

export default memo(BModal);
