import { TPDetailLocationState } from '@mobile/api/hooks/usePrescriptionDetail';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

type Props = {
  patientName?: string;
  reservationCode?: string;
  mixpanelData: {
    'TP ID': number;
    'PH ID': string | null;
    'Symptom Keyword': string;
    'Delivery Method': string;
    'OCR Success': boolean;
    'Non-insured Medicine': (string | null | undefined)[];
  };
};

function BottomCTA({ patientName, reservationCode, mixpanelData }: Props) {
  const { id } = useParams();

  const {
    state: { prescriptionId },
  } = useLocation() as TPDetailLocationState;
  const tppId = Number(id);

  const navigate = useNavigate();

  const goToAcceptPage = () => {
    return navigate(`/mobile/request/accept/${tppId}`, {
      state: { patientName, reservationCode, prescriptionId, mixpanelData },
    });
  };

  const goToRejectPage = () => {
    return navigate(`/mobile/request/reject/${tppId}`, {
      state: { patientName, reservationCode, prescriptionId, mixpanelData },
    });
  };

  return (
    <Container>
      <Text>조제 수락을 누르면 예상 약제비를 알려드려요</Text>
      <ButtonWrapper>
        <RejectButton onClick={goToRejectPage}>조제 거절</RejectButton>
        <AcceptButton onClick={goToAcceptPage}>조제 수락</AcceptButton>
      </ButtonWrapper>
    </Container>
  );
}

export default BottomCTA;

const Container = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  width: 100%;
  padding: 12px 20px 28px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;

  border-top: 1px solid var(--border-divider, #f1f2f4);
  background: var(--fill-white, #fff);
`;

const Text = styled.div`
  color: #2f6eff;
  text-align: center;

  font-family: Pretendard, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`;

const RejectButton = styled.button`
  border: 1px solid #dddfe3;
  display: flex;
  width: 100px;
  padding: 16px 12px;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border-radius: 12px;

  color: #474a52;
  text-align: center;

  font-family: Pretendard, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;

  letter-spacing: -0.32px;
`;

const AcceptButton = styled.button`
  border: none;
  display: flex;
  padding: 16px 12px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;

  border-radius: 12px;
  background: #2f6eff;

  color: #ffffff;
  text-align: center;

  font-family: Pretendard, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
`;
