import { BoxContainer } from '@components/atoms';
import styled from 'styled-components';

export const ContentItem = styled(BoxContainer)`
  display: flex;
  flex-direction: column;
  padding: 30px;
  border-radius: 16px;
  box-shadow: 1px 1px 20px 1px rgba(196, 196, 196, 0.3);
  width: 600px;
`;

export const RightContentItem = styled(ContentItem)`
  width: 480px;
`;
export const LeftContentItem = styled(ContentItem)``;

export const ContentHeaderArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ContentHeaderTitle = styled.div``;

export const ContentHeaderTitleIcon = styled.img`
  width: 24px;
  height: 24px;
`;

export const ContentBodyArea = styled.div``;

export const ContentProgressArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
