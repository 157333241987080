import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '@stores';
import { SetFirstLoginModalType } from './index.d';

const initialState: SetFirstLoginModalType = {
  isVisible: false,
};

export const setFirstLoginModal = createSlice({
  name: 'modal/setFirstLoginModal',
  initialState,
  reducers: {
    openModal_setFirstLoginModal: (state) => {
      state.isVisible = true;
    },
    closeModal_setFirstLoginModal: (state) => {
      state.isVisible = false;
    },
  },
});

export const { openModal_setFirstLoginModal, closeModal_setFirstLoginModal } =
  setFirstLoginModal.actions;

export const selectSetFirstLoginVisible = (state: RootState) =>
  state.setFirstLoginModal.isVisible;

export default setFirstLoginModal.reducer;
