import { Button, COLOR, Font } from '@components/atoms';
import { EFormType } from '@pages/loginPage';
import { DebouncedFunc } from 'lodash';
import { memo } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import {
  EFindIdPasswordFormFields,
  IFindIdPasswordFormData,
} from '../../hooks/find-id-password-form/find-id-password-form.type';

type IFindIdPasswordCtaViewProps = {
  updateFormType: (type: EFormType) => void;
  submit: DebouncedFunc<() => void>;
  shouldRetry?: boolean;
  retry?: () => void;
};

export const FindIdPasswordCtaView = memo<IFindIdPasswordCtaViewProps>(
  ({ updateFormType, submit, shouldRetry, retry }) => {
    const { formState } = useFormContext<IFindIdPasswordFormData>();

    const error = formState.errors?.[EFindIdPasswordFormFields.PHONE_NUMBER];

    const disabled = error || Object.keys(formState.dirtyFields).length !== 1;

    if (shouldRetry) {
      return (
        <LoginButtonArea>
          <div
            style={{
              flex: 1,
            }}
          >
            <Button
              width="100%"
              padding="16px 12px"
              borderRadius="12px"
              title={
                <Font fontType="body1" color="fill/dark">
                  처음으로
                </Font>
              }
              onClick={() => {
                if (retry) {
                  retry();
                }
              }}
              backgroundColor={COLOR['fill/white']}
              borderColor={COLOR['border/outline']}
            />
          </div>
          <div style={{ flex: 2 }}>
            <Button
              width="100%"
              padding="16px 12px"
              borderRadius="12px"
              title={
                <Font fontType="body1" color="fill/accent">
                  다시 시도하기
                </Font>
              }
              onClick={() => {
                if (retry) {
                  retry();
                }
              }}
              backgroundColor="#EBF3FF"
            />
          </div>
        </LoginButtonArea>
      );
    }

    return (
      <LoginButtonArea>
        <div
          style={{
            flex: 1,
          }}
        >
          <Button
            width="100%"
            padding="16px 12px"
            borderRadius="12px"
            title={
              <Font fontType="body1" color="fill/dark">
                이전으로
              </Font>
            }
            onClick={() => updateFormType(EFormType.LOGIN)}
            backgroundColor={COLOR['fill/white']}
            borderColor={COLOR['border/outline']}
          />
        </div>
        <div style={{ flex: 2 }}>
          <Button
            width="100%"
            padding="16px 12px"
            borderRadius="12px"
            title={
              <Font
                fontType="body1"
                color={disabled ? 'disabled/foreground' : 'fill/white'}
              >
                인증메시지 전송
              </Font>
            }
            onClick={() => {
              if (disabled) return;
              submit();
            }}
            backgroundColor={
              disabled ? COLOR['disabled/background'] : COLOR['fill/accent']
            }
          />
        </div>
      </LoginButtonArea>
    );
  },
);

const LoginButtonArea = styled.div`
  width: 100%;
  display: flex;
  gap: 12px;
`;
