import { BColor } from '@components/meraki-ui/BColor';
import { IconDetailProps } from '@components/meraki-ui/BIcon/svg.type';

export default function CallIcon({ width, height, color }: IconDetailProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.19714 4.16243C5.978 3.88068 5.56152 3.85475 5.30913 4.10714L4.40445 5.01183C3.9814 5.43487 3.82589 6.0342 4.01071 6.55991C4.75295 8.67108 5.96813 10.6534 7.65738 12.3426C9.34663 14.0319 11.3289 15.247 13.4401 15.9893C13.9658 16.1741 14.5651 16.0186 14.9882 15.5956L15.8929 14.6909C16.1452 14.4385 16.1193 14.022 15.8376 13.8029L13.8197 12.2334C13.6757 12.1214 13.4883 12.0819 13.3114 12.1261L11.396 12.6049C10.8757 12.735 10.3254 12.5826 9.94614 12.2034L7.79664 10.0539C7.41743 9.67465 7.26499 9.12429 7.39506 8.60402L7.87391 6.68859C7.91814 6.51169 7.87858 6.32428 7.76663 6.18035L6.19714 4.16243ZM4.64918 3.44719C5.29853 2.79784 6.37006 2.86455 6.93385 3.58943L8.50334 5.60735C8.79136 5.97766 8.89314 6.45982 8.77936 6.91495L8.3005 8.83038C8.24995 9.0326 8.3092 9.24652 8.45659 9.39391L10.6061 11.5434C10.7535 11.6908 10.9674 11.7501 11.1696 11.6995L13.0851 11.2206C13.5402 11.1069 14.0223 11.2086 14.3927 11.4967L16.4106 13.0661C17.1354 13.6299 17.2022 14.7015 16.5528 15.3508L15.6481 16.2555C15.0009 16.9028 14.0328 17.187 13.1305 16.8698C10.8903 16.0822 8.78759 14.7927 6.99743 13.0026C5.20727 11.2124 3.91783 9.10965 3.13023 6.86947C2.81303 5.96723 3.09724 4.99913 3.74449 4.35187L4.64918 3.44719Z"
        fill={BColor[color]}
      />
    </svg>
  );
}
