// @ts-nocheck
import { useEffect, useRef } from 'react';

function useIntervalWhen(
  callback,
  intervalDurationMs = 0,
  when = true,
  startImmediate = false,
  clearOption = false,
) {
  const savedRefCallback = useRef();
  const intervalRef = useRef();

  useEffect(() => {
    savedRefCallback.current = callback;
  });

  useEffect(() => {
    if (when) {
      const internalCallback = () => {
        savedRefCallback.current?.();
      };

      if (startImmediate) {
        internalCallback();
      }

      if (!clearOption) {
        const interval = window.setInterval(
          internalCallback,
          intervalDurationMs,
        );
        intervalRef.current = interval;
        return () => window.clearInterval(intervalRef.current);
      }
    }
  }, [when, intervalDurationMs, startImmediate, clearOption]);

  useEffect(() => {
    return () => {
      window.clearInterval(intervalRef.current);
    };
  }, []);
}

export default useIntervalWhen;
