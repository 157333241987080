import { useState } from 'react';

const useToggleHeader = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen((prev) => !prev);

    if (!isOpen) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'auto';
    }
  };

  return { isOpen, handleToggle };
};

export default useToggleHeader;
