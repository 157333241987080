import { COLOR } from '@components/atoms';
import styled from 'styled-components';

export const ModalBodyContentArea = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

export const TitleArea = styled.div`
  display: flex;
  gap: 2px;
  width: 88px;
  padding: 14px 0;
`;

export const MedicineSearchInputArea = styled.div<{ isFocus: boolean }>`
  display: flex;
  gap: 8px;
  padding: 12px 16px;
  width: 360px;
  border: ${(props) =>
    `1px solid ${
      props.isFocus ? COLOR['fill/black'] : COLOR['border/outline']
    }`};
  border-radius: 8px;
  cursor: pointer;
`;

export const SelectedMedicineArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 3px 6px 3px 8px;
  border-radius: 4px;
  background-color: ${COLOR['dim/thick']};
`;

export const MedicineSearchInput = styled.input`
  width: 100%;
  border: none;
  font-size: 16px;
  line-height: 24px;
  ::placeholder {
    color: ${COLOR['fill/medium']};
  }
  :disabled {
    background-color: ${COLOR['fill/light']};
    color: ${COLOR['disabled/foreground']};
  }
`;

export const MedicineListDropDownArea = styled.div`
  position: absolute;
  top: 388px;
  left: 160px;
  overflow-y: scroll;
  width: 360px;
  height: 200px;
  border-radius: 8px;
  border: 1px solid ${COLOR['border/outline']};
  background-color: ${COLOR['fill/white']};
`;

export const MedicineItemArea = styled.div`
  padding: 14px 16px;
  cursor: pointer;
  :hover {
    background-color: ${COLOR['hover/white']};
  }
`;
