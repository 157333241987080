import { useAppSelector } from '@stores/hooks';
import { selectUserInformationIsLogin } from '@stores/userInformationStore';
import { useNavigate } from 'react-router-dom';
import { commonHooks } from '@hooks';
import { useEffect } from 'react';
import { storageHandler } from '@utils';
import { tokenList } from '@constants';

const { REST_AUTH_TOKEN } = tokenList;

const useLinkGatePageHook = () => {
  const navigate = useNavigate();
  const { getLocalStorageState } = storageHandler;
  const { useQuery } = commonHooks;
  const query = useQuery();

  const type = query.get('type');
  const treatmentId = query.get('treatmentId');

  const isLogin = useAppSelector(selectUserInformationIsLogin);

  useEffect(() => {
    if (getLocalStorageState(REST_AUTH_TOKEN.ACCESS_TOKEN) && isLogin) {
      switch (type) {
        case 'Home':
          navigate('/home');
          break;
        case 'NewReservation':
          if (treatmentId) {
            navigate(`/home/newReservation/requested?id=${treatmentId}`);
          } else {
            navigate(`/home/newReservation/requested`);
          }
          break;
        case 'AcceptedTreatment':
          if (treatmentId) {
            navigate(
              `/home/newReservation/accepted/newReservationAcceptedDetail?id=${treatmentId}`,
            );
          } else {
            navigate(`/home/newReservation/accepted`);
          }
          break;
        case 'ConfirmedTreatment':
          if (treatmentId) {
            navigate(
              `/home/confirmedTreatment/confirmed/confirmedTreatmentConfirmedDetail?id=${treatmentId}`,
            );
          } else {
            navigate(`/home/confirmedTreatment/confirmed`);
          }
          break;
        case 'OngoingQuick':
          if (treatmentId) {
            navigate(
              `/home/confirmedTreatment/ongoing-quick/confirmedTreatmentOngoingQuickDetail?id=${treatmentId}`,
            );
          } else {
            navigate(`/home/confirmedTreatment/ongoing-quick`);
          }
          break;
        case 'OngoingParcel':
          if (treatmentId) {
            navigate(
              `/home/confirmedTreatment/ongoing-parcel/confirmedTreatmentOngoingParcelDetail?id=${treatmentId}`,
            );
          } else {
            navigate(`/home/confirmedTreatment/ongoing-parcel`);
          }
          break;
        case 'CompleteTreatment':
          navigate(`/home/completeTreatment?offset=0`);
          break;
        case 'CompleteTreatmentDetail':
          if (treatmentId) {
            navigate(
              `/home/completeTreatment/completeTreatmentDetail?id=${treatmentId}`,
            );
          } else {
            navigate(`/home/completeTreatment?offset=0`);
          }
          break;
        default:
          navigate('/home');
          break;
      }
    } else {
      const queryArray = [];
      if (type) queryArray.push(`type=${type}`);
      if (treatmentId) queryArray.push(`treatmentId=${treatmentId}`);

      const queryString = `/login?${queryArray.join('&')}`;

      navigate(queryString);
    }
  }, [navigate, isLogin, treatmentId, type, getLocalStorageState]);
};

export default useLinkGatePageHook;
