import { COLOR, Font } from '@components/atoms';
import SvgIcon from '@components/atoms/images';
import styled from 'styled-components';

interface HeaderProps {
  closeOffDayModal: () => void;
}

export default function Header({ closeOffDayModal }: HeaderProps) {
  return (
    <HeaderContainer>
      <Font fontType="h1">휴가 및 공휴일 등록</Font>
      <SvgIcon
        icon="xLg"
        width={14}
        color="greyscale6"
        onClick={closeOffDayModal}
      />
    </HeaderContainer>
  );
}

const HeaderContainer = styled.div`
  display: flex;
  padding: 1.25rem 2.5rem;
  width: 100%;
  border-bottom: 1px solid ${COLOR['fill/mediumLight']};
  justify-content: space-between;
  align-items: center;
`;
