import { InputContainer } from './index.style';

interface InputInterface {
  value: number | string;
  onChange: Function;
  width?: number;
  onKeyPress?: Function;
}

function Input({
  value,
  onChange,
  width = 100,
  onKeyPress = () => null,
}: InputInterface) {
  return (
    <InputContainer
      onChange={(e) => onChange(e)}
      value={value}
      width={width}
      onKeyPress={(e) => onKeyPress(e)}
    />
  );
}

export default Input;
