import { Blank, COLOR, Font } from '@components/atoms';
import PdfViewer from '@components/atoms/\bpdfViewer';
import OcrResultTable from '@components/organisms/ocrResultTable';
import PrescriptionItemHeader from '@components/organisms/telepharmacyDetailInformation/sections/prescriptionItem/PrescriptionItemHeader';

import { CallOutMessage } from '@components/molecules';
import PrescriptionQrCodeImage from '@components/organisms/prescriptionQrCodeImage';
import usePrescriptionQrCodeImage from '@components/organisms/prescriptionQrCodeImage/hooks';
import SubstitutePrescriptionInfo from '@components/organisms/substitutePrescriptionInfo';
import { useEffect, useMemo, useState } from 'react';
import { pdfjs } from 'react-pdf';
import { useLocation } from 'react-router-dom';
import { TelepharmacyType } from 'types/index';
import { PageStatus } from '../..';
import * as Style from './index.style';

export type PdfPositionType = {
  x1: number;
  x2: number;
  y1: number;
  y2: number;
};

type TelepharmacyItemType = TelepharmacyType.TelepharmacyItemType;

type PrescriptionPartItemType = {
  item: TelepharmacyItemType;
  pageType: PageStatus;
};

function PrescriptionItem({ item, pageType }: PrescriptionPartItemType) {
  const route = useLocation();
  const [numPages, setNumPages] = useState<number | null>(null);

  const isShowSubstitutePrescriptionInfo = useMemo(() => {
    return !route.pathname.includes('completeTreatmentDetail');
  }, [route.pathname]);

  useEffect(() => {
    if (item.tm && item.tm.prescriptions[0].image) {
      try {
        const pdfDocument = pdfjs.getDocument(item.tm.prescriptions[0].image);
        pdfDocument.promise.then((doc) => {
          setNumPages(doc._pdfInfo.numPages);
        });
      } catch (err) {
        console.log('PrescriptionItemHeader', err);
      }
    }
  }, [item]);

  const isMultiplePrescription = numPages && numPages > 1;

  const { data: qrCodeList, isError } = usePrescriptionQrCodeImage({
    prescriptionId: item.tm?.prescriptions[0]?.id || 0,
  });

  const hasQrCode = qrCodeList && qrCodeList?.length > 0;

  return (
    <Style.LeftContentItem>
      <PrescriptionItemHeader item={item} numPages={numPages} />

      <Blank appoint="Vertical" size={20} />

      {isShowSubstitutePrescriptionInfo && (
        <>
          <SubstitutePrescriptionInfo
            tmId={item?.tm?.id}
            isSubstitutedPatient={item.is_substituted_patient}
          />
          <Blank appoint="Vertical" size={12} />
        </>
      )}

      {hasQrCode && (
        <PrescriptionQrCodeImage
          prescriptionId={item.tm?.prescriptions[0].id}
          qrCodeList={qrCodeList}
          isError={isError}
        />
      )}

      <Blank appoint="Vertical" size={20} />

      <OcrResultTable
        tmId={item?.tm?.id}
        prescriptionId={item.tm?.prescriptions[0].id}
        pageType={pageType}
      />
      <Blank appoint="Vertical" size={20} />

      {isMultiplePrescription && (
        <div>
          <CallOutMessage
            iconColor="fill/light"
            backgroundColor={COLOR.greyscale6}
          >
            <Font fontType="body1" color="fill/light">
              {`접수된 처방전이 총 ${numPages}장입니다.
            스크롤을 내려 모든 처방전을 확인해주세요.`}
            </Font>
          </CallOutMessage>
          <Blank appoint="Vertical" size={20} />
        </div>
      )}

      <PdfViewer link={item?.tm?.prescriptions[0].image} height="960px" />
    </Style.LeftContentItem>
  );
}

export default PrescriptionItem;
