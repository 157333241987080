import { Blank, Button, COLOR, Font, TextInput } from '@components/atoms';
import SvgIcon from '@components/atoms/images';
import {
  CallOutMessage,
  ModalCommonStructure as Modal,
  PageLoader,
} from '@components/molecules';
import { formatters } from '@functions';
import { notifyOtherClients } from '@hooks/featureHooks/useSocket';
import { myDoctorAPI } from '@services';
import { useAppDispatch, useAppSelector } from '@stores/hooks';
import {
  closeModal_acceptTelepharmacyModal,
  selectAcceptTelepharmacyModalItem,
  selectAcceptTelepharmacyModalVisible,
} from '@stores/modalStore/acceptTelepharmacyModal';
import { getTelepharmacyCount } from '@stores/telepharmacyStore/telepharmacyCount';
import {
  getTelepharmacyList_accepted,
  getTelepharmacyList_requested,
} from '@stores/telepharmacyStore/telepharmacyList';
import { FormEvent, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Style from './index.style';

function AcceptTelepharmacyModal() {
  const { phoneFormatter, priceFormatter } = formatters;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const isOpen = useAppSelector(selectAcceptTelepharmacyModalVisible);
  const item = useAppSelector(selectAcceptTelepharmacyModalItem);
  const [isPageLoading, setIsPageLoading] = useState(false);

  const [amount, setAmount] = useState('');
  const [isSubstituteSelected, setIsSubstituteSelected] = useState(false); // false = 처방전대로 조제, true = 동일성분 대체조제
  const [substituteReasonTitle, setSubstituteReasonTitle] = useState<
    'sameIngredientDifferentManufacturer' | 'otherOption' | null
  >(null);
  const [substituteReasonContent, setSubstituteReasonContent] = useState('');
  const [isConfirmButtonActive, setIsConfirmButtonActive] = useState(false);

  const [isZeroAmount, setIsZeroAmount] = useState(false);
  const [amountDisable, setAmountDisable] = useState(false);

  const substituteReasonRef = useRef<HTMLTextAreaElement>(null);

  const onChangeAmount = (e: FormEvent<HTMLInputElement>) => {
    setAmount(priceFormatter.uncommaFormatter(e.currentTarget.value));
  };

  useEffect(() => {
    setAmount('');
    setIsSubstituteSelected(false);
    setSubstituteReasonTitle(null);
    setSubstituteReasonContent('');
  }, []);

  const acceptTelepharmacy = () => {
    if (
      window.confirm(
        `${item.tm?.patient?.name} 환자의 조제를 수락하시겠습니까?`,
      )
    ) {
      setIsPageLoading(true);

      const id = item.id ? item.id : 0;

      myDoctorAPI
        .postAcceptTelepharmacy(
          {
            amount: Number(amount),
            guide: '',
            is_uninsured: null,
            is_substituted_pharmacy: isSubstituteSelected,
            substitute_reason_pharmacy: substituteReasonContent,
          },
          id,
        )
        .then((responseJSON) => {
          if (responseJSON.id) {
            alert('조제를 수락하였습니다.');
            closeModal();
            Promise.all([
              dispatch(getTelepharmacyCount()),
              dispatch(getTelepharmacyList_requested({ limit: 60, offset: 0 })),
              dispatch(getTelepharmacyList_accepted({ limit: 60, offset: 0 })),
            ]).then(() => {
              setTimeout(() => navigate('/home/newReservation/requested'), 700);
              notifyOtherClients('telepharmacy');
            });
          } else {
            alert('요청에 실패했습니다.');
            window.location.reload();
          }
        })
        .finally(() => setIsPageLoading(false));
    }
  };

  const closeModal = () => {
    setIsPageLoading(false);
    dispatch(closeModal_acceptTelepharmacyModal());
    setAmount('');
    setIsZeroAmount(false);
    setAmountDisable(false);
  };

  useEffect(() => {
    if (isSubstituteSelected) {
      setSubstituteReasonTitle('sameIngredientDifferentManufacturer');
    } else {
      setSubstituteReasonTitle(null);
    }
  }, [isSubstituteSelected]);

  useEffect(() => {
    if (substituteReasonTitle === 'otherOption') {
      substituteReasonRef.current?.focus();
    } else {
      setSubstituteReasonContent('');
    }
  }, [substituteReasonTitle]);

  useEffect(() => {
    if (amount.length > 0) {
      if (substituteReasonTitle === 'otherOption') {
        if (substituteReasonContent.length === 0) {
          setIsConfirmButtonActive(false);
          return;
        }
      }
      setIsConfirmButtonActive(true);
    } else {
      setIsConfirmButtonActive(false);
    }
  }, [amount, substituteReasonContent, substituteReasonTitle]);

  useEffect(() => {
    if (isZeroAmount) setAmount('0');
    else setAmount('');

    return () => setAmount('');
  }, [isZeroAmount]);

  return (
    <>
      {isPageLoading && <PageLoader />}
      <Modal
        isRecheckModal
        isOpen={isOpen}
        onRequestClose={() => closeModal()}
        modalHeader={
          <Style.TitleArea>
            <Font fontType="h1">조제수락</Font>
          </Style.TitleArea>
        }
        modalBody={
          <>
            <Style.DescriptionArea>
              <CallOutMessage
                backgroundColor={COLOR.red100}
                iconColor="state/distructive"
              >
                <Font fontType="body2" color="state/distructive">
                  대체조제가 필요한 경우, 대체조제 여부 및 사유를 선택해주세요.
                </Font>
              </CallOutMessage>
            </Style.DescriptionArea>

            <Style.IsSubstituteSelectArea>
              <Style.SubTitleArea>
                <Font fontType="body1_medium">대체조제 여부</Font>
                <Style.RequiredUI>
                  <Font fontType="body3_medium" color="fill/accent">
                    필수
                  </Font>
                </Style.RequiredUI>
              </Style.SubTitleArea>
              <Style.IsSubstituteSelectButtonsArea>
                <Button
                  width="50%"
                  padding="9px 0"
                  borderColor={
                    isSubstituteSelected
                      ? COLOR['border/outline']
                      : COLOR['fill/accent']
                  }
                  borderRadius="8px"
                  backgroundColor={
                    isSubstituteSelected
                      ? COLOR['fill/white']
                      : COLOR['label/lightBlue']
                  }
                  title={
                    <Font
                      fontType="body2_medium"
                      color={isSubstituteSelected ? 'fill/dark' : 'fill/accent'}
                    >
                      처방전대로 조제
                    </Font>
                  }
                  onClick={() => {
                    setIsSubstituteSelected((prev) => !prev);
                  }}
                />
                <Button
                  width="50%"
                  padding="9px 0"
                  borderColor={
                    isSubstituteSelected
                      ? COLOR['fill/accent']
                      : COLOR['border/outline']
                  }
                  borderRadius="8px"
                  backgroundColor={
                    isSubstituteSelected
                      ? COLOR['label/lightBlue']
                      : COLOR['fill/white']
                  }
                  title={
                    <Font
                      fontType="body2_medium"
                      color={isSubstituteSelected ? 'fill/accent' : 'fill/dark'}
                    >
                      동일성분 대체조제
                    </Font>
                  }
                  onClick={() => {
                    setIsSubstituteSelected((prev) => !prev);
                  }}
                />
              </Style.IsSubstituteSelectButtonsArea>
            </Style.IsSubstituteSelectArea>

            {isSubstituteSelected && (
              <Style.SubstituteReasonArea>
                <Style.SubTitleArea>
                  <Font fontType="body1_medium">대체조제 사유</Font>
                  <Style.RequiredUI>
                    <Font fontType="body3_medium" color="fill/accent">
                      필수
                    </Font>
                  </Style.RequiredUI>
                </Style.SubTitleArea>

                <Style.SubstituteReasonRadiosArea>
                  <Style.SubstituteReasonRadioArea
                    onClick={() =>
                      setSubstituteReasonTitle(
                        'sameIngredientDifferentManufacturer',
                      )
                    }
                  >
                    <Style.SubstituteReasonRadio
                      type="radio"
                      id="0"
                      name="substituteReason"
                      value="0"
                      checked={
                        substituteReasonTitle ===
                        'sameIngredientDifferentManufacturer'
                      }
                    />
                    <Style.SubstituteReasonLabel htmlFor="0">
                      <Font fontType="body1_medium" color="fill/black">
                        동일 성분의 다른 제약사 약으로 조제됩니다.
                      </Font>
                    </Style.SubstituteReasonLabel>
                  </Style.SubstituteReasonRadioArea>
                  <Style.SubstituteReasonRadioArea
                    onClick={() => setSubstituteReasonTitle('otherOption')}
                  >
                    <Style.SubstituteReasonRadio
                      type="radio"
                      id="1"
                      name="substituteReason"
                      value="1"
                      checked={substituteReasonTitle === 'otherOption'}
                    />
                    <Style.SubstituteReasonLabel htmlFor="1">
                      <Font fontType="body1_medium" color="fill/black">
                        기타 (직접 입력)
                      </Font>
                    </Style.SubstituteReasonLabel>
                  </Style.SubstituteReasonRadioArea>
                </Style.SubstituteReasonRadiosArea>

                {substituteReasonTitle === 'otherOption' && (
                  <Style.SubstituteReasonTextareaArea
                    ref={substituteReasonRef}
                    placeholder="대체사유를 입력해주세요."
                    value={substituteReasonContent}
                    onChange={(e) => setSubstituteReasonContent(e.target.value)}
                  />
                )}
              </Style.SubstituteReasonArea>
            )}

            <Style.PatientPaymentArea>
              <Style.SubTitleArea>
                <Font fontType="body1_medium">약제비</Font>
                <Style.RequiredUI>
                  <Font fontType="body3_medium" color="fill/accent">
                    필수
                  </Font>
                </Style.RequiredUI>
              </Style.SubTitleArea>

              <Style.InformationColumnArea>
                <Style.AmountInputArea>
                  <TextInput
                    onChange={onChangeAmount}
                    width={394}
                    height={52}
                    value={`${priceFormatter.prettyPriceFormatter(amount)}`}
                    type="text"
                    placeholder="숫자만 입력해주세요"
                    disabled={amountDisable}
                    fontSize={16}
                  />
                  <Font fontType="body1_medium" color="fill/black">
                    원
                  </Font>
                </Style.AmountInputArea>
                <Style.CheckButtonMargin />
                <Style.CheckBoxButtonArea
                  onClick={() => {
                    setIsZeroAmount(!isZeroAmount);
                    setAmountDisable(!amountDisable);
                  }}
                >
                  <Style.CheckboxIconArea>
                    {isZeroAmount ? (
                      <SvgIcon
                        icon="checkboxCheckedIcon"
                        width={20}
                        height={20}
                        color="fill/accent"
                      />
                    ) : (
                      <SvgIcon
                        icon="checkboxIcon"
                        width={20}
                        height={20}
                        color="fill/mediumLight"
                      />
                    )}
                  </Style.CheckboxIconArea>
                  <Style.CheckboxIconArea>
                    <Font fontType="body1_medium">
                      약제비가 0원인 조제입니다.
                    </Font>
                  </Style.CheckboxIconArea>
                </Style.CheckBoxButtonArea>
              </Style.InformationColumnArea>
            </Style.PatientPaymentArea>
          </>
        }
        modalFooter={
          <Style.FooterArea>
            <Style.ConfirmButtonArea>
              <Button
                onClick={closeModal}
                backgroundColor={COLOR['fill/white']}
                width="100%"
                height="60px"
                borderRadius="8px"
                borderColor={COLOR['border/outline']}
                visible
                title={
                  <Style.ButtonContainer>
                    <Font fontType="body1" color="fill/dark">
                      취소
                    </Font>
                  </Style.ButtonContainer>
                }
              />
              <Blank appoint="Horizontal" size={12} />
              <Button
                onClick={acceptTelepharmacy}
                disableColor={COLOR['disabled/background']}
                backgroundColor={COLOR['fill/accent']}
                width="100%"
                height="60px"
                borderRadius="8px"
                visible={isConfirmButtonActive}
                title={
                  <Style.ButtonContainer>
                    <Font
                      fontType="body1_medium"
                      color={
                        isConfirmButtonActive
                          ? 'fill/white'
                          : 'disabled/foreground'
                      }
                    >
                      수락하기
                    </Font>
                  </Style.ButtonContainer>
                }
              />
            </Style.ConfirmButtonArea>
          </Style.FooterArea>
        }
      />
    </>
  );
}

export default AcceptTelepharmacyModal;
