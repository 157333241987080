import { useAppSelector } from '@stores/hooks';
import { selectSetFirstLoginVisible } from '@stores/modalStore/setFirstLoginModal';
import { FormProvider } from 'react-hook-form';
import { useChangePasswordForm } from './hooks';
import SetFirstLoginModalTemplate from './templates';

function SetFirstLoginModal() {
  const isVisible = useAppSelector(selectSetFirstLoginVisible);

  const { methods } = useChangePasswordForm();

  return (
    <FormProvider {...methods}>
      <SetFirstLoginModalTemplate isVisible={isVisible} />
    </FormProvider>
  );
}

export default SetFirstLoginModal;
