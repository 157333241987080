import { COLOR } from '@components/atoms';
import styled from 'styled-components';

export const DefaultListContent = styled.div<{
  flex: number;
  flexDirection: 'row' | 'column';
  border?: string;
  isPointer?: boolean;
  justifyContent?: string;
}>`
  flex: ${({ flex }) => flex};
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection};
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  align-items: ${({ flexDirection }) =>
    flexDirection === 'row' ? 'center' : 'flex-start'};
  padding: 8px 16px;
  height: 56px;
  word-break: break-all;
  border-right: ${({ border }) =>
    border === 'none' ? 'none' : `1px solid ${COLOR['border/divider']}`};
  border-bottom: ${({ border }) =>
    border === 'none' ? 'none' : `1px solid ${COLOR['border/divider']}`};
  cursor: ${({ isPointer }) => (isPointer ? 'pointer' : 'default')};

  &:last-child {
    border-right: none;
  }
`;
