import { useCallback, useState } from 'react';
import { pdfjs } from 'react-pdf';

const usePDF = () => {
  const [isPDFShow, setIsPDFShow] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [prescriptionNumPages, setPrescriptionNumPages] = useState(0);

  const handleDocumentLoadSuccess = useCallback(
    ({ numPages }: pdfjs.PDFDocumentProxy) => {
      setPrescriptionNumPages(numPages);
    },
    [],
  );

  const toggleShowPDF = useCallback(() => {
    setIsPDFShow((prev) => !prev);
  }, []);

  const handlePrevPage = useCallback(() => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  }, [pageNumber, setPageNumber]);

  const handleNextPage = useCallback(() => {
    if (pageNumber < prescriptionNumPages) {
      setPageNumber(pageNumber + 1);
    }
  }, [prescriptionNumPages, pageNumber, setPageNumber]);

  return {
    isPDFShow,
    pageNumber,
    prescriptionNumPages,
    handleDocumentLoadSuccess,
    toggleShowPDF,
    handlePrevPage,
    handleNextPage,
  };
};

export default usePDF;
