import { Button, COLOR, Font } from '@components/atoms';

import BFlex from '@components/meraki-ui/BFlex';
import BTypography from '@components/meraki-ui/BTypography';
import {
  CallOutMessage,
  ModalCommonStructure as Modal,
} from '@components/molecules';
import { ReactNode } from 'react';

interface VATModalProps {
  open: boolean;
  onClose: () => void;
  callOutMessage: string;
  title: string | ReactNode;
}

function EmptyVATModal({
  open,
  onClose,
  title,
  callOutMessage,
}: VATModalProps) {
  return (
    <Modal
      isOpen={open}
      onRequestClose={onClose}
      modalHeader={
        <Font fontType="h1" color="greyscale6">
          {title}
        </Font>
      }
      modalBody={
        <CallOutMessage>
          <BTypography size={16} color="mono08" text={callOutMessage} />
        </CallOutMessage>
      }
      modalFooter={
        <BFlex isFull>
          <Button
            onClick={onClose}
            backgroundColor={COLOR['fill/accent']}
            width="100%"
            height="56px"
            borderRadius="12px"
            title={
              <Font fontType="body1" color="fill/white">
                확인
              </Font>
            }
          />
        </BFlex>
      }
    />
  );
}

export default EmptyVATModal;
