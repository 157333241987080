export default function ErrorPageIcon() {
  return (
    <svg
      width="140"
      height="60"
      viewBox="0 0 140 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 48.6954V39.2953L24.6852 0.779626H38.759V38.3576H46.1144V48.6954H38.759V59.2204H27.1762V48.6954H0ZM25.2251 1.77755H37.7679V39.3555H45.1233V47.6975H37.7679V58.2225H28.1672V47.6975H0.991041V39.5894L25.2251 1.77755ZM11.2886 39.3555V38.9657L27.7027 13.316H28.3221V39.3555H11.2886ZM27.331 38.3576V15.7391L12.8567 38.3576H27.331ZM86.4892 52.0455C82.5898 57.2517 76.9049 60 69.8839 60C62.8433 60 57.1486 57.2418 53.2492 52.0305C49.378 46.8567 47.3493 39.3661 47.3687 30C47.3493 20.67 49.3985 13.1805 53.2785 7.99851C57.1854 2.78054 62.8783 0 69.8839 0C76.8899 0 82.5732 2.78088 86.4751 7.99901C90.35 13.1809 92.3991 20.6704 92.3991 30C92.3991 39.3858 90.3606 46.8766 86.4892 52.0455ZM48.3597 30C48.321 11.6008 56.4506 0.997921 69.8839 0.997921C83.3171 0.997921 91.408 11.6008 91.408 30C91.408 48.5161 83.3558 59.0021 69.8839 59.0021C56.3732 59.0021 48.321 48.4771 48.3597 30ZM62.4403 15.1757C60.5963 18.4228 59.5127 23.3611 59.4934 30.0016C59.4936 36.7019 60.5687 41.6164 62.4092 44.8254C64.2158 47.9754 66.7522 49.4872 69.8838 49.5062C72.9949 49.4872 75.5323 47.9765 77.3445 44.8245C79.1899 41.6149 80.2743 36.6999 80.2743 30.0003C80.2549 23.3605 79.1713 18.4226 77.3274 15.1757C75.5141 11.9826 72.9832 10.4349 69.8839 10.4158C66.7845 10.4349 64.2537 11.9826 62.4403 15.1757ZM69.8839 9.41788C62.8769 9.45686 58.5411 16.5515 58.5024 30C58.5024 43.5655 62.8382 50.4652 69.8839 50.5042C76.8908 50.4652 81.2653 43.5655 81.2653 30C81.2266 16.5515 76.8908 9.45686 69.8839 9.41788ZM93.8856 48.6954V39.2953L118.571 0.779626H132.645V38.3576H140V48.6954H132.645V59.2204H121.062V48.6954H93.8856ZM119.111 1.77755H131.654V39.3555H139.009V47.6975H131.654V58.2225H122.053V47.6975H94.8767V39.5894L119.111 1.77755ZM105.174 39.3555V38.9657L121.588 13.316H122.208V39.3555H105.174ZM121.217 38.3576V15.7391L106.742 38.3576H121.217Z"
        fill="#474747"
      />
    </svg>
  );
}
