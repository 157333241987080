import { IconDetailProps } from '@components/atoms/images';
import { COLOR } from '../color';

function CheckIcon({ width, height, color }: IconDetailProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.9234 4.31344C14.1186 4.5087 14.1186 4.82528 13.9234 5.02055L6.92338 12.0205C6.82961 12.1143 6.70243 12.167 6.56982 12.167C6.43722 12.167 6.31004 12.1143 6.21627 12.0205L2.71627 8.52055C2.52101 8.32528 2.52101 8.0087 2.71627 7.81344C2.91153 7.61818 3.22812 7.61818 3.42338 7.81344L6.56982 10.9599L13.2163 4.31344C13.4115 4.11818 13.7281 4.11818 13.9234 4.31344Z"
        fill={COLOR[color]}
      />
    </svg>
  );
}

export default CheckIcon;
