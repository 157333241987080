import { Button, COLOR, Font } from '@components/atoms';
import { MedicineCompoundingFeeConfigValueType } from '@components/organisms/managementMedicineTable/config/domain';
import { priceFormatter } from '@functions/formatters';
import { ManagedMedicineItemType } from '@services/myDoctor/types';
import { isNil } from 'lodash';
import { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import * as Style from './index.style';

interface MedicineUnitPriceProps {
  hasCompoundingFee: boolean | null;
  isMedicineSumInputAreaFocus: boolean;
  selectedMedicine: ManagedMedicineItemType | null;
  medicineCompoundingFees: MedicineCompoundingFeeConfigValueType[] | null;
  setHasCompoundingFee: Dispatch<SetStateAction<boolean | null>>;
  handleChangeMedicineSumInput: (
    e: React.ChangeEvent<HTMLInputElement>,
    quantity: number,
  ) => void;
}

function MedicineCompoundingFee({
  hasCompoundingFee,
  isMedicineSumInputAreaFocus,
  selectedMedicine,
  medicineCompoundingFees,
  setHasCompoundingFee,
  handleChangeMedicineSumInput,
}: MedicineUnitPriceProps) {
  const medicineSumInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isMedicineSumInputAreaFocus) {
      medicineSumInputRef.current?.focus();
    }
  }, [isMedicineSumInputAreaFocus]);

  return (
    <Style.ModalBodyArea>
      <Style.SubTitleArea>
        <Font fontType="body1_medium" color="fill/black">
          조제료
        </Font>
        <Font fontType="body1_medium" color="state/distructive">
          *
        </Font>
      </Style.SubTitleArea>

      <Style.ModalBodyContentArea>
        <Style.ModalBodyTabArea>
          <Button
            width="172px"
            padding="9px 16px"
            backgroundColor={
              hasCompoundingFee ? COLOR['label/lightBlue'] : COLOR['fill/white']
            }
            borderColor={
              hasCompoundingFee ? COLOR['fill/accent'] : COLOR['border/outline']
            }
            borderRadius="8px"
            title={
              <Style.HasCompoundingFeeButtonContent>
                <Font
                  fontType="body2_medium"
                  color={hasCompoundingFee ? 'fill/accent' : 'fill/dark'}
                >
                  조제료가 있어요
                </Font>
              </Style.HasCompoundingFeeButtonContent>
            }
            onClick={() => {
              setHasCompoundingFee(true);
            }}
          />
          <Button
            width="172px"
            padding="9px 16px"
            backgroundColor={
              hasCompoundingFee === false
                ? COLOR['label/lightBlue']
                : COLOR['fill/white']
            }
            borderColor={
              hasCompoundingFee === false
                ? COLOR['fill/accent']
                : COLOR['border/outline']
            }
            borderRadius="8px"
            title={
              <Style.HasCompoundingFeeButtonContent>
                <Font
                  fontType="body2_medium"
                  color={
                    hasCompoundingFee === false ? 'fill/accent' : 'fill/dark'
                  }
                >
                  조제료가 없어요
                </Font>
              </Style.HasCompoundingFeeButtonContent>
            }
            onClick={() => {
              setHasCompoundingFee(false);
            }}
          />
        </Style.ModalBodyTabArea>
        <Style.ModalBodyNoticeArea>
          <Style.ModalBodyNoticeTextArea>
            <Font fontType="body2" color="fill/dark">
              비급여는 급여와 달리 조제료가 필수 부과 대상이 아니며, 약국별
              정책에 따라 조제료를 지정이 가능합니다.
            </Font>
            <Style.ModalBodyNoticeLinkArea>
              <Font fontType="body2_medium" color="fill/dark" underline>
                <Style.NoticeLink
                  href="https://merakiplace.notion.site/970118027f2e4295982b792dac343ed5?pvs=25"
                  target="_blank"
                >
                  관련 심평원 답변 보기
                </Style.NoticeLink>
              </Font>
            </Style.ModalBodyNoticeLinkArea>
          </Style.ModalBodyNoticeTextArea>
        </Style.ModalBodyNoticeArea>

        {hasCompoundingFee && medicineCompoundingFees ? (
          <Style.ModalBodyUnitPriceArea>
            {medicineCompoundingFees.map((medicineCompoundingFee) => {
              const { price, quantity } = medicineCompoundingFee;

              return (
                <Style.MedicinePriceEachUnitRow key={quantity}>
                  <Style.UnitDropDownArea>
                    <Style.MedicineUnitInputArea>
                      {selectedMedicine && (
                        <Font fontType="body1" color="fill/black">
                          {`${quantity}일`}
                        </Font>
                      )}
                    </Style.MedicineUnitInputArea>
                  </Style.UnitDropDownArea>
                  <Style.MedicineSumInputArea
                    placeholder="숫자만 입력해주세요"
                    ref={medicineSumInputRef}
                    value={priceFormatter.commaFormatter(
                      isNil(price) ? '' : String(price),
                    )}
                    onChange={(e) => handleChangeMedicineSumInput(e, quantity)}
                  />

                  <Font fontType="body1_medium" color="fill/black">
                    원
                  </Font>
                </Style.MedicinePriceEachUnitRow>
              );
            })}
          </Style.ModalBodyUnitPriceArea>
        ) : null}
      </Style.ModalBodyContentArea>
    </Style.ModalBodyArea>
  );
}

export default MedicineCompoundingFee;
