import ChevronLeft from '@assets/svg/ChevronLeft';
import { useLayoutEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

type Props = {
  requestCount: number;
};

function Header({ requestCount }: Props) {
  const navigate = useNavigate();
  const ref = useRef<HTMLDivElement>(null);

  const goBack = () => {
    return navigate('/mobile/request');
  };

  useLayoutEffect(() => {
    if (ref.current) {
      document.documentElement.style.setProperty(
        '--detail-header-height',
        `${ref.current.offsetHeight}px`,
      );
    }
  }, []);

  return (
    <Container ref={ref}>
      <ChevronLeft onClick={goBack} />
      <Text>{`신규 요청 ${requestCount}건 확인`}</Text>
    </Container>
  );
}

export default Header;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  height: 52px;
  padding: 0px 20px;
  gap: 4px;
  border-bottom: 1px solid #f1f2f4;
  z-index: 100;
  background-color: #474a52;
`;

const Text = styled.div`
  color: #ffffff;
  padding-top: 2px;

  font-family: Pretendard, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
`;
