import { Font, TextInput } from '@components/atoms';
import { memo } from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { EResetPasswordFormFields, IResetPasswordFormData } from '../../hooks';

type IResetPasswordFormViewProps = {};

export const ResetPasswordFormView = memo<IResetPasswordFormViewProps>(({}) => {
  const { control, formState } = useFormContext<IResetPasswordFormData>();

  const passwordError = formState.errors?.[EResetPasswordFormFields.PASSWORD];
  const passwordErrorMessage =
    formState?.errors?.[EResetPasswordFormFields.PASSWORD]?.message;
  const passwordConfirmError =
    formState.errors?.[EResetPasswordFormFields.PASSWORD_CONFIRM];
  const passwordConfirmErrorMessage =
    formState?.errors?.[EResetPasswordFormFields.PASSWORD_CONFIRM]?.message;

  return (
    <div>
      <Controller
        control={control}
        name={EResetPasswordFormFields.PASSWORD}
        render={({ field: { onChange, value } }) => {
          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 8,
                paddingBottom: 20,
              }}
            >
              <TextInput
                value={value}
                onChange={onChange}
                placeholder="비밀번호를 입력해주세요"
                type="password"
                fontSize={16}
                title="비밀번호"
                errorMessage={passwordErrorMessage}
              />
              {passwordError && value.length > 0 && (
                <div style={{ paddingLeft: 20 }}>
                  <Font fontType="body2" color="state/distructive">
                    {passwordErrorMessage}
                  </Font>
                </div>
              )}
            </div>
          );
        }}
      />
      <Controller
        control={control}
        name={EResetPasswordFormFields.PASSWORD_CONFIRM}
        render={({ field: { onChange, value } }) => {
          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 8,
                paddingBottom: 20,
              }}
            >
              <TextInput
                value={value}
                onChange={onChange}
                placeholder="비밀번호를 다시 입력해주세요"
                type="password"
                fontSize={16}
                title="비밀번호 확인"
                errorMessage={passwordConfirmErrorMessage}
              />
              {passwordConfirmError && value.length > 0 && (
                <div style={{ paddingLeft: 20 }}>
                  <Font fontType="body2" color="state/distructive">
                    {passwordConfirmErrorMessage}
                  </Font>
                </div>
              )}
            </div>
          );
        }}
      />
    </div>
  );
});
