import { BColor } from '@components/meraki-ui/BColor';
import { IconDetailProps } from '@components/meraki-ui/BIcon/svg.type';

export default function ArrowLeftInCircleIcon({
  width,
  height,
  color,
}: IconDetailProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2ZM13.5 9.5C13.7761 9.5 14 9.72386 14 10C14 10.2761 13.7761 10.5 13.5 10.5H7.70711L9.85355 12.6464C10.0488 12.8417 10.0488 13.1583 9.85355 13.3536C9.65829 13.5488 9.34171 13.5488 9.14645 13.3536L6.14645 10.3536C5.95118 10.1583 5.95118 9.84171 6.14645 9.64645L9.14645 6.64645C9.34171 6.45118 9.65829 6.45118 9.85355 6.64645C10.0488 6.84171 10.0488 7.15829 9.85355 7.35355L7.70711 9.5H13.5Z"
        fill={BColor[color]}
      />
    </svg>
  );
}
