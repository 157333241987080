import { COLOR } from '@components/atoms';
import { Z_INDEX } from '@constants/styleVariables';
import { PropsWithChildren } from 'react';
import ReactModal from 'react-modal';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: Z_INDEX.modal,
  },
  overlay: {
    backgroundColor: COLOR['dim/thick'],
    zIndex: Z_INDEX.modal,
    cursor: 'pointer',
  },
};
interface ModalProps {
  isOpen: boolean;
  customContentStyle?: Record<string, string>;
  customOverlayStyle?: Record<string, string>;
  onClose: () => void;
}

function Modal({
  isOpen,
  children,
  customContentStyle,
  customOverlayStyle,
  onClose,
}: PropsWithChildren<ModalProps>) {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={{
        content: { ...customStyles.content, ...customContentStyle },
        overlay: { ...customStyles.overlay, ...customOverlayStyle },
      }}
    >
      {children}
    </ReactModal>
  );
}

export default Modal;
