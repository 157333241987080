import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '@stores';
import { FindIdPasswordModalType } from './index.d';

const initialState: FindIdPasswordModalType = {
  isVisible: false,
};

export const findIdPasswordModal = createSlice({
  name: 'modal/findIdPasswordModal',
  initialState,
  reducers: {
    openModal_findIdPasswordModal: (state) => {
      state.isVisible = true;
    },
    closeModal_findIdPasswordModal: (state) => {
      state.isVisible = false;
    },
  },
});

export const { openModal_findIdPasswordModal, closeModal_findIdPasswordModal } =
  findIdPasswordModal.actions;

export const selectFindIdPasswordModalVisible = (state: RootState) =>
  state.findIdPasswordModal.isVisible;

export default findIdPasswordModal.reducer;
