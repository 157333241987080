import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '@stores';

interface InitialStateType {
  isVisible: boolean;
  modalType: 'on' | 'off' | null;
  targetMedicine: null;
}

const initialState: InitialStateType = {
  isVisible: false,
  modalType: null,
  targetMedicine: null,
};

export const pharmacyAutoConfirmedModal = createSlice({
  name: 'modal/pharmacyAutoConfirmedModal',
  initialState,
  reducers: {
    openModal_pharmacyAutoConfirmedModal: (state) => {
      state.isVisible = true;
    },
    closeModal_pharmacyAutoConfirmedModal: (state) => {
      state.isVisible = false;
    },
    set_modalType_pharmacyAutoConfirmedModal: (state, action) => {
      state.modalType = action.payload;
    },
    set_targetMedicine_pharmacyAutoConfirmedModal: (state, action) => {
      state.targetMedicine = action.payload;
    },
  },
});

export const {
  openModal_pharmacyAutoConfirmedModal,
  closeModal_pharmacyAutoConfirmedModal,
  set_modalType_pharmacyAutoConfirmedModal,
  set_targetMedicine_pharmacyAutoConfirmedModal,
} = pharmacyAutoConfirmedModal.actions;

export const selectPharmacyAutoConfirmedModalVisible = (state: RootState) =>
  state.pharmacyAutoConfirmedModal.isVisible;

export const selectPharmacyAutoConfirmedModalType = (state: RootState) =>
  state.pharmacyAutoConfirmedModal.modalType;

export const selectPharmacyAutoConfirmedModalTargetMedicine = (
  state: RootState,
) => state.pharmacyAutoConfirmedModal.targetMedicine;

export default pharmacyAutoConfirmedModal.reducer;
