import { COLOR } from '@components/atoms';
import { SPACING_VARIABLES, Z_INDEX } from '@constants/styleVariables';
import styled from 'styled-components';

export const UpdateNudgeContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  gap: 32px;
  justify-content: center;
  padding: 12px 24px;
  width: 100%;
  height: ${SPACING_VARIABLES.updateNudge}px;
  background-color: ${COLOR['fill/black']};
  z-index: ${Z_INDEX.updateNudge};
`;

export const ReloadButtonArea = styled.div`
  display: flex;
  gap: 4px;
  padding: 9px 16px 9px 12px;
`;
