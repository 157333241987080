import Caution from '@assets/svg/Caution';
import { Button, COLOR, Font } from '@components/atoms';
import SvgIcon from '@components/atoms/images';
import { postPrescriptionQrCodeReportFailure } from '@components/organisms/prescriptionQrCodeImage/apis';
import AllowToast from '@components/organisms/toast/mobile/allowToast';
import { QUERY_KEY, queryClient } from '@constants/react-query';
import { Z_INDEX } from '@constants/styleVariables';
import { PrescriptionDetail } from '@mobile/api';
import { useMutation } from '@tanstack/react-query';

import { uniqueId } from 'lodash';
import { useState } from 'react';
import { Sheet } from 'react-modal-sheet';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import MedicineInfoItem from './MedicineInfoItem';
import usePrescriptionQrCodeImage from './hooks';

type Props = {
  medicine_info?: PrescriptionDetail['info'];
  prescriptionId: number | undefined;
  onToggle: () => void;
  handleMixpanelEvent: () => void;
};

const CustomSheet = styled(Sheet)<{ height: string }>`
  .react-modal-sheet-backdrop {
    z-index: ${Z_INDEX.bottomSheetBackdrop} !important;
    background-color: ${COLOR['dim/medium']} !important;
  }
  .react-modal-sheet-container {
    height: ${(props) => props.height} !important;
    z-index: ${Z_INDEX.bottomSheet} !important;
  }
  .react-modal-sheet-header {
    height: 20px !important;
  }
  .react-modal-sheet-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

function MedicineInfo({
  medicine_info,
  prescriptionId,
  onToggle,
  handleMixpanelEvent,
}: Props) {
  const { data: qrCodeList, refetch } = usePrescriptionQrCodeImage({
    prescriptionId,
  });

  const refetchQrCodeList = () => {
    refetch();
  };

  const [
    isPrescriptionQrCodeBottomSheetOpen,
    setIsPrescriptionQrCodeBottomSheetOpen,
  ] = useState(false);

  const medicineInfo = medicine_info?.[0]?.prescription_contents
    ? medicine_info?.[0]?.prescription_contents
    : [
        {
          drug_code: null,
          drug_name: null,
          one_dose: null,
          dosing_per_day: null,
          total_dosing_days: null,
          self_pay_rate_code: null,
          generic_name: null,
        },
      ];

  const isDataNullInMedcineInfo = () => {
    return (
      medicineInfo.length === 0 ||
      medicineInfo.some((info) => {
        return (
          info.drug_code === null ||
          info.drug_name === null ||
          info.one_dose === null ||
          info.dosing_per_day === null ||
          info.total_dosing_days === null ||
          info.self_pay_rate_code === null
        );
      })
    );
  };

  const handleTogglePrescriptionQrCodeBottomSheet = () => {
    setIsPrescriptionQrCodeBottomSheetOpen((prev) => !prev);
  };

  const handleClickPrescriptionQrCodeBottomSheet = () => {
    handleTogglePrescriptionQrCodeBottomSheet();
  };

  return (
    <Container>
      <CustomSheet
        isOpen={isPrescriptionQrCodeBottomSheetOpen}
        onClose={handleTogglePrescriptionQrCodeBottomSheet}
        initialSnap={1}
        height={qrCodeList && qrCodeList.data.length > 1 ? '80%' : '70%'}
      >
        <CustomSheet.Container>
          <CustomSheet.Header />
          <CustomSheet.Content>
            <BottomSheetBody>
              <BottomSheetTitle>
                <Font fontType="h1" color="fill/black">
                  처방전 QR 코드
                </Font>
                <Font fontType="body1" color="fill/accent">
                  {qrCodeList &&
                    qrCodeList.data.length > 0 &&
                    `총 ${qrCodeList.data.length}개`}
                </Font>
              </BottomSheetTitle>
              {qrCodeList &&
                qrCodeList.data.length > 0 &&
                qrCodeList.data?.map((qrCode) => {
                  return (
                    <QrCodeViewer
                      key={uniqueId()}
                      refetchQrCodeList={refetchQrCodeList}
                      {...qrCode}
                    />
                  );
                })}
            </BottomSheetBody>

            <BottomSheetFooter>
              <Button
                title={
                  <Font fontType="body1_medium" color="fill/white">
                    닫기
                  </Font>
                }
                width="100%"
                backgroundColor={COLOR['fill/dark']}
                borderRadius="12px"
                padding="16px 12px"
                onClick={() => {
                  handleTogglePrescriptionQrCodeBottomSheet();
                  queryClient.invalidateQueries({
                    queryKey: [
                      QUERY_KEY.TP_PRESCRIPTION_QR_CODE_IMAGE,
                      prescriptionId,
                    ],
                  });
                }}
              />
            </BottomSheetFooter>
          </CustomSheet.Content>
        </CustomSheet.Container>
        <Sheet.Backdrop onTap={handleTogglePrescriptionQrCodeBottomSheet} />
      </CustomSheet>

      <TitleSection>
        <Title>처방 의약품</Title>
      </TitleSection>

      <ButtonSection>
        {qrCodeList?.data.length !== 0 && (
          <Button
            title={
              <QrCodeButtonContent>
                <SvgIcon icon="qrIcon" width={16} color="fill/accent" />
                <Font fontType="body2_medium" color="fill/accent">
                  처방전 QR 코드
                </Font>
              </QrCodeButtonContent>
            }
            backgroundColor={COLOR['label/lightBlue']}
            borderRadius="8px"
            padding="9px 31px 9px 27px"
            onClick={handleClickPrescriptionQrCodeBottomSheet}
          />
        )}
        <Button
          {...(qrCodeList?.data.length === 0 && { width: '100%' })}
          title={
            <QrCodeButtonContent>
              <SvgIcon icon="historyIcon" width={16} color="fill/dark" />
              <Font fontType="body2_medium" color="fill/dark">
                처방전 보기
              </Font>
            </QrCodeButtonContent>
          }
          backgroundColor={COLOR['fill/white']}
          borderColor={COLOR['border/outline']}
          borderRadius="8px"
          padding="9px 31px 9px 27px"
          onClick={() => {
            onToggle();
            handleMixpanelEvent();
          }}
        />
      </ButtonSection>

      {isDataNullInMedcineInfo() && (
        <CautionContainer>
          <Caution />
          <CautionText>
            {
              '처방전 데이터를 불러오지 못했습니다.\n[처방전 보기]를 눌러 처방전을 확인해주세요.'
            }
          </CautionText>
        </CautionContainer>
      )}

      {!isDataNullInMedcineInfo() && (
        <ContentsSection>
          {medicineInfo.map((info, index) => {
            return (
              <ContentsSection key={info.drug_code ? info.drug_code : index}>
                <MedicineInfoItem
                  key={info.drug_code ? info.drug_code : index}
                  daily_doses={info.dosing_per_day ?? '-'}
                  medicine_name={info.drug_name ?? '-'}
                  medicine_number={info.drug_code ?? '-'}
                  one_dose={info.one_dose ?? '-'}
                  total_days_taken={info.total_dosing_days ?? '-'}
                  medicine_generic={info.generic_name}
                  insured_text={info.self_pay_rate_code ?? '-'}
                />
              </ContentsSection>
            );
          })}
        </ContentsSection>
      )}
    </Container>
  );
}

export default MedicineInfo;

function QrCodeViewer(data: {
  id: number;
  prescription_id: number;
  page_index: number;
  qr_index: number;
  image: string;
  is_recognition_failure_reported: boolean;
  recognition_failure_reported_at: string;
  refetchQrCodeList: () => void;
}) {
  const {
    id,
    image,
    page_index,
    qr_index,
    prescription_id,
    is_recognition_failure_reported,
    refetchQrCodeList,
  } = data;
  const [isQrCodeErrorReporting, setIsQrCodeErrorReporting] = useState(
    is_recognition_failure_reported,
  );

  const { mutateAsync: clickPrescriptionQrCodeFailReport } = useMutation({
    mutationFn: () =>
      postPrescriptionQrCodeReportFailure({
        prescriptionId: prescription_id,
        pageIndex: page_index,
        qrIndex: qr_index,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.TP_PRESCRIPTION_QR_CODE_IMAGE, prescription_id],
      });
      setIsQrCodeErrorReporting(true);
      refetchQrCodeList();
      toast(
        <AllowToast
          title={
            <>
              <Font fontType="body2" color="fill/white">
                오류 제보 완료
              </Font>
              <Font fontType="body2" color="fill/white">
                빠른 시일 내에 개선하겠습니다.
              </Font>
            </>
          }
        />,
        { position: 'top-center' },
      );
    },
    onError: () => {
      toast(
        <AllowToast
          title={
            <>
              <Font fontType="body2" color="fill/white">
                오류 제보 실패
              </Font>
              <Font fontType="body2" color="fill/white">
                잠시 후에 다시 시도해주세요.
              </Font>
            </>
          }
        />,
        { position: 'top-center' },
      );
      setIsQrCodeErrorReporting(false);
    },
  });

  return (
    <BiggerQrCodeContainer>
      <ImgContainer>
        <QrImage src={image} alt={`QR코드 ${page_index}-${qr_index}`} />
      </ImgContainer>
      <QrCodeBottomContainer>
        <Font fontType="body2" color="fill/medium">
          {`QR코드 ${page_index + 1}-${qr_index + 1}`}
        </Font>
        <Button
          disabled={isQrCodeErrorReporting}
          title={
            <Font
              fontType="body2"
              color={
                isQrCodeErrorReporting ? 'disabled/foreground' : 'fill/dark'
              }
            >
              {isQrCodeErrorReporting ? '제보 완료' : '오류 제보'}
            </Font>
          }
          borderColor={
            isQrCodeErrorReporting ? 'none' : COLOR['border/outline']
          }
          backgroundColor={
            isQrCodeErrorReporting
              ? COLOR['disabled/background']
              : COLOR['fill/white']
          }
          padding="5px 12px"
          onClick={() => {
            clickPrescriptionQrCodeFailReport();
          }}
        />
      </QrCodeBottomContainer>
    </BiggerQrCodeContainer>
  );
}

const Container = styled.section`
  display: flex;
  width: 100%;
  padding: 20px 20px 40px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  border-top: 4px solid #f1f2f4;
  border-bottom: 4px solid #f1f2f4;
`;

const TitleSection = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: space-between;
`;

const Title = styled.div`
  color: #1e2024;

  font-family: Pretendard, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
`;

const ButtonSection = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
`;

const QrCodeButtonContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
`;

const BottomSheetTitle = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: center;
  align-items: center;
  padding: 20px;
  scroll-snap-align: start;
`;

const BottomSheetBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  padding: 12px 20px;

  overflow-y: scroll;
  scroll-snap-type: y mandatory;
`;

const BottomSheetErrorText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ImgContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200x;
  height: 200px;
  cursor: pointer;
`;

const QrImage = styled.img`
  max-width: 200px;
  max-height: 200px;
  width: auto;
  height: auto;
`;

const BiggerQrCodeContainer = styled.div`
  width: 200px;
  scroll-snap-align: center;
`;

const QrCodeBottomContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BottomSheetFooter = styled.div`
  display: flex;
  padding: 12px 20px 20px 20px;
  border-top: 1px solid ${COLOR['border/divider']};
`;

const ContentsSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const CautionContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 10px 18px;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: #f9fafa;
`;

const CautionText = styled.div`
  color: #474a52;

  font-family: Pretendard, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  white-space: pre-line;
`;
