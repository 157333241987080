import { IconDetailProps } from '@components/atoms/images';

function ShotIcon({ width, height, color }: IconDetailProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M13.5764 1.88086L8.17065 7.2866L10.7133 9.82925L16.119 4.42348L13.5764 1.88086Z"
        fill="#C6E2FE"
      />
      <path
        d="M13.6294 0.952054C13.4468 0.769534 13.1993 0.666992 12.9411 0.666992C12.683 0.666992 12.4355 0.769534 12.253 0.952054C12.0705 1.13457 11.9679 1.38212 11.9679 1.64025C11.9679 1.89837 12.0705 2.14592 12.253 2.32844L15.6719 5.74682C15.8544 5.92932 16.102 6.03189 16.3601 6.03189C16.6182 6.03189 16.8658 5.92932 17.0483 5.74682C17.2308 5.56428 17.3333 5.31675 17.3333 5.05864C17.3333 4.80048 17.2308 4.55296 17.0483 4.37041L13.6294 0.952054Z"
        fill="#4785FF"
      />
      <path
        d="M8.56948 3.21385C8.8416 2.94226 9.19672 2.80566 9.55238 2.80566C9.73496 2.80562 9.91573 2.84165 10.0843 2.9117C10.2529 2.98176 10.406 3.08441 10.5348 3.21385L14.7858 7.46543C14.9153 7.59428 15.018 7.74742 15.0881 7.91613C15.1582 8.0848 15.1942 8.26565 15.1942 8.44833C15.1942 8.63096 15.1582 8.81185 15.0881 8.98052C15.018 9.14918 14.9153 9.30237 14.7858 9.43122L9.65063 14.5664C9.52178 14.6959 9.36859 14.7986 9.19993 14.8687C9.03122 14.9388 8.85036 14.9749 8.66773 14.9749C8.48506 14.9749 8.30421 14.9388 8.13554 14.8687C7.96683 14.7986 7.81369 14.6959 7.68479 14.5664L7.19361 15.0576C7.06475 15.187 6.91157 15.2898 6.7429 15.3599C6.57424 15.4299 6.39338 15.4661 6.21071 15.4661C6.02803 15.4661 5.84718 15.4299 5.67852 15.3599C5.50985 15.2898 5.35667 15.187 5.22781 15.0576L4.57632 14.4061L1.83674 17.1461C1.70507 17.2695 1.53062 17.3367 1.35026 17.3338C1.16989 17.3308 0.997746 17.2579 0.870194 17.1303C0.742636 17.0028 0.669673 16.8306 0.66672 16.6502C0.663762 16.4699 0.73105 16.2954 0.854354 16.1638L3.59391 13.4237L2.94256 12.7724C2.81322 12.6435 2.71055 12.4904 2.6405 12.3217C2.57046 12.1531 2.5344 11.9723 2.5344 11.7897C2.5344 11.6071 2.57046 11.4263 2.6405 11.2577C2.71055 11.0891 2.81322 10.936 2.94256 10.8071L3.43428 10.3154C3.30485 10.1865 3.2021 10.0333 3.13204 9.86467C3.06194 9.69596 3.02587 9.51511 3.02587 9.33248C3.02587 9.14981 3.06194 8.96895 3.13204 8.80029C3.2021 8.63158 3.30485 8.47844 3.43428 8.34954L8.56948 3.21385Z"
        fill="#C6E2FE"
      />
      <path
        d="M4.65033 8.90858C4.58232 8.84489 4.52779 8.76816 4.49003 8.68299C4.45227 8.59776 4.43203 8.50587 4.43052 8.41269C4.42896 8.3195 4.44617 8.22699 4.48113 8.14061C4.51609 8.05423 4.56809 7.97572 4.63396 7.90985C4.69988 7.84393 4.77834 7.79198 4.86472 7.75702C4.9511 7.72206 5.04362 7.70485 5.1368 7.70636C5.22998 7.70792 5.32192 7.72815 5.4071 7.76592C5.49228 7.80368 5.569 7.85821 5.6327 7.92622L5.89695 8.19096C6.02029 8.32266 6.08754 8.49711 6.08461 8.67747C6.08162 8.85783 6.00868 9.02997 5.88111 9.15753C5.75359 9.2851 5.58141 9.35805 5.40105 9.36098C5.22069 9.36396 5.04624 9.29666 4.91458 9.17337L4.65033 8.90858ZM6.68863 6.87029C6.62062 6.80659 6.56614 6.72986 6.52833 6.64464C6.49057 6.55947 6.47033 6.46753 6.46882 6.37439C6.46726 6.2812 6.48452 6.18864 6.51948 6.10227C6.55444 6.01589 6.60639 5.93743 6.67226 5.87155C6.73818 5.80563 6.81664 5.75368 6.90302 5.71872C6.9894 5.68376 7.08196 5.66655 7.17514 5.66806C7.26828 5.66957 7.36022 5.68981 7.4454 5.72762C7.53062 5.76538 7.60735 5.81987 7.67104 5.88788L7.93529 6.15266C8.05859 6.28432 8.12588 6.45876 8.1229 6.63913C8.11997 6.81949 8.04698 6.99167 7.91946 7.11919C7.79189 7.24676 7.61971 7.31975 7.43935 7.32268C7.25899 7.32566 7.08454 7.25837 6.95288 7.13507L6.68863 6.87029Z"
        fill="white"
      />
      <path
        d="M9.01006 1.68838C8.82756 1.50586 8.57999 1.40332 8.32188 1.40332C8.06377 1.40332 7.8162 1.50586 7.63365 1.68838C7.45116 1.8709 7.34863 2.11845 7.34863 2.37657C7.34863 2.63469 7.45116 2.88225 7.63365 3.06475L14.8361 10.2672C15.0185 10.4497 15.266 10.5522 15.5241 10.5523C15.7821 10.5524 16.0297 10.4499 16.2122 10.2674C16.3947 10.085 16.4973 9.83749 16.4973 9.57942C16.4974 9.32135 16.3949 9.07383 16.2125 8.89129L9.01006 1.68838Z"
        fill="#4785FF"
      />
    </svg>
  );
}

export default ShotIcon;
