import { BColor } from '@components/meraki-ui/BColor';
import { IconDetailProps } from '@components/meraki-ui/BIcon/svg.type';

export default function PipetteIcon({ width, height, color }: IconDetailProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5 2.50004C15.0286 2.02863 14.2643 2.02863 13.7929 2.50004L10.6465 5.64648L10 5.00004C9.80478 4.80478 9.48819 4.80478 9.29293 5.00004C9.09767 5.1953 9.09767 5.51188 9.29293 5.70714L10.4394 6.85359L3.29293 14C3.19916 14.0938 3.14648 14.221 3.14648 14.3536V16.1465L2.29293 17C2.09767 17.1953 2.09767 17.5119 2.29293 17.7071C2.48819 17.9024 2.80478 17.9024 3.00004 17.7071L3.85359 16.8536H5.64648C5.77909 16.8536 5.90627 16.8009 6.00004 16.7071L13.1465 9.5607L14.2929 10.7071C14.4882 10.9024 14.8048 10.9024 15 10.7071C15.1953 10.5119 15.1953 10.1953 15 10L14.3536 9.35359L17.5 6.20714C17.9714 5.73574 17.9714 4.97144 17.5 4.50004L15.5 2.50004ZM4.14648 14.5607L11.1465 7.5607L12.4394 8.85359L5.43938 15.8536H4.14648V14.5607Z"
        fill={BColor[color]}
      />
    </svg>
  );
}
