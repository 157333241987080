import http from '@mobile/api/httpInstance';

export type GetPrescriptionQrCodeImageResponse = {
  data: PrescriptionQrCodeImage[];
  meta: object;
};

export interface PrescriptionQrCodeImage {
  id: number;
  prescription_id: number;
  page_index: number;
  qr_index: number;
  image: string;
  is_recognition_failure_reported: boolean;
  recognition_failure_reported_at: string;
}

export const getPrescriptionQrCodeImage = (prescriptionId: number) => {
  return http.get<GetPrescriptionQrCodeImageResponse>(
    `prescription/${prescriptionId}/qr-codes`,
  );
};

interface PostPrescriptionQrCodeReportFailureParams {
  prescriptionId: number;
  pageIndex: number;
  qrIndex: number;
}

export const postPrescriptionQrCodeReportFailure = ({
  prescriptionId,
  pageIndex,
  qrIndex,
}: PostPrescriptionQrCodeReportFailureParams) => {
  return http.post(
    `prescription/${prescriptionId}/qr-codes/${pageIndex}-${qrIndex}/report-recog-failure`,
  );
};
