export enum EmailLoginFormFields {
  'EMAIL' = 'EMAIL',
  'PASSWORD' = 'PASSWORD',
}

export enum PhoneLoginFormFields {
  'PHONE_NUMBER' = 'PHONE_NUMBER',
}

export type EmailLoginFormData = {
  [EmailLoginFormFields.EMAIL]: string;
  [EmailLoginFormFields.PASSWORD]: string;
};

export type PhoneLoginFormData = {
  [PhoneLoginFormFields.PHONE_NUMBER]: string;
};
