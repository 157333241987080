import { COLOR } from '@components/atoms';
import styled from 'styled-components';

export const ModalBodyContentArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const SubTitleArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SubTitleLeftArea = styled.div`
  display: flex;
  gap: 6px;
`;

export const RequiredUI = styled.div`
  padding: 2px 8px;

  border-radius: 4px;
  background-color: ${COLOR['label/lightBlue']};
`;

export const UnitAddButtonArea = styled.div`
  display: flex;
  gap: 4px;
`;

export const MedicinePriceEachUnitRow = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const UnitDropDownArea = styled.div`
  position: relative;
  border-radius: 8px;
`;

export const MedicineUnitInputArea = styled.div<{
  isDisabled: boolean | undefined;
}>`
  display: flex;
  justify-content: space-between;
  width: 100px;
  padding: 14px 16px;
  border: 1px solid ${COLOR['border/outline']};
  border-radius: 8px;
  cursor: ${(props) => (props.isDisabled ? 'disabled' : 'pointer')};
  background-color: ${(props) =>
    props.isDisabled ? COLOR['disabled/background'] : COLOR['fill/white']};
`;

export const UnitArea = styled.div`
  padding: 14px 16px;
  cursor: pointer;
  :hover {
    background-color: ${COLOR.blue50};
  }
`;

export const UnitDropDown = styled.div<{ isScroll: boolean }>`
  position: absolute;
  overflow-y: ${(props) => (props.isScroll ? 'scroll' : 'hidden')};
  width: 100px;
  max-height: 208px;
  top: 58px;
  border-radius: 8px;
  border: 1px solid ${COLOR['border/outline']};
  background-color: ${COLOR['fill/white']};
  z-index: 1;
`;

export const MedicineSumInputArea = styled.div<{
  isFocus: boolean;
  isDisabled: boolean | undefined;
}>`
  flex: 4;
  display: flex;
  width: 302px;
  gap: 8px;
  padding: 12px 16px;
  border: ${(props) =>
    `1px solid ${
      props.isFocus ? COLOR['fill/black'] : COLOR['border/outline']
    }`};
  border-radius: 8px;
  background-color: ${(props) =>
    props.isDisabled ? COLOR['disabled/background'] : COLOR['fill/white']};
  cursor: ${(props) => (props.isDisabled ? 'disabled' : 'pointer')};
`;

export const MedicineSearchInput = styled.input`
  width: 100%;
  border: none;
  font-size: 16px;
  line-height: 24px;
  ::placeholder {
    color: ${COLOR['fill/medium']};
  }
  :disabled {
    background-color: ${COLOR['fill/light']};
    color: ${COLOR['disabled/foreground']};
  }
`;

export const EmptyArea = styled.div`
  width: 24px;
  height: 24px;
`;

export const MedicinePriceEachUnitRowDeleteButtonArea = styled.div`
  cursor: pointer;
`;

export const EmptyAddMedicineUnitArea = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 50px;
  padding: 14px 16px;
  background-color: ${COLOR['fill/light']};
  border-radius: 8px;
`;
