import { BlankType } from './index.d';
import { HorizontalBlank, VerticalBlank } from './index.style';

function Blank({ size, appoint }: BlankType) {
  switch (appoint) {
    case 'Horizontal':
      return <HorizontalBlank size={size} />;
    case 'Vertical':
      return <VerticalBlank size={size} />;
    default:
      return <HorizontalBlank size={size} />;
  }
}

export default Blank;
