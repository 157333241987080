import { COLOR } from '@components/atoms';
import styled from 'styled-components';

export const ModalBodyContentArea = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

export const TitleArea = styled.div`
  display: flex;
  gap: 2px;
  width: 88px;
  padding: 14px 0;
`;

export const MedicineUnitPriceArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
`;

export const MedicineUnitPriceInputArea = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
`;

export const MedicineUnitArea = styled.div`
  display: flex;
  align-items: center;
  min-width: 100px;
  height: 52px;
  padding: 0 16px;
  border: 1px solid ${COLOR['border/outline']};
  border-radius: 8px;
`;

export const MedicineSearchInput = styled.input<{ isError: boolean }>`
  width: 302px;
  gap: 8px;
  padding: 12px 16px;
  width: 100%;
  border-radius: 8px;
  font-size: 16px;
  line-height: 24px;
  border: ${(props) =>
    `1px solid ${
      props.isError ? COLOR['state/distructive'] : COLOR['border/outline']
    }`};
  color: ${({ isError }) =>
    isError ? COLOR['state/distructive'] : COLOR['fill/black']};
  ::placeholder {
    color: ${COLOR['fill/medium']};
  }
  :disabled {
    background-color: ${COLOR['fill/light']};
    color: ${COLOR['disabled/foreground']};
  }
`;

export const InputErrorArea = styled.div`
  width: 194px;
  margin-right: 20px;
`;
