import { ReactBootstrapIcons } from '@assets';
import {
  Blank,
  Button,
  CheckBox,
  COLOR,
  Font,
  TextInput,
} from '@components/atoms';
import { styleVariables } from '@constants';
import { commonHooks } from '@hooks';
import {
  TelepharmacyListDeliveryMethodType,
  TelepharmacyListIsUninsuredType,
} from '@services/myDoctor/types/index.d';
import moment from 'moment';
import { FormEvent, useState } from 'react';
import { FilterComponentType } from './index.d';
import * as Style from './index.style';

const { BREAK_POINTS } = styleVariables;

const PERIOD_TEXT = {
  ALL: '전체',
  TODAY: '오늘',
  SEVEN_DAYS: '최근 7일',
  THIRTY_DAYS: '최근 30일',
  NINETY_DAYS: '최근 90일',
};

const DELIVERY_METHOD_TEXT = {
  ALL: '전체',
  QUICK: '퀵',
  PARCEL: '택배',
};

const STATUS_TEXT = {
  ALL: '전체',
  DELIVERY_FINISH: '배송완료',
  DELIVERY_INPROGRESS: '배송 중',
  DELIVERY_PICKED_WAITED: '수거대기',
  PATIENT_CANCEL: '환자취소',
  PHARMACIST_CANCEL: '약사취소',
};

const INSURED_TEXT = {
  ALL: '전체',
  INSURED: '급여',
  NON_INSURED: '비급여',
};

const DETAIL_CONDITION_TEXT = {
  PATIENT_NAME: '환자명',
  PHONE_NUMBER: '연락처',
  ADDRESS: '주소',
  HOSPITAL_NAME: '병원명',
  DOCTOR_NAME: '의사명',
  WAYBILL_NUMBER: '운송장번호',
};

function FilterComponent({ handleFilters }: FilterComponentType) {
  const { useWindowSize } = commonHooks;
  const { windowWidth } = useWindowSize();

  const [isFilterAreaOpen, setIsFilterAreaOpen] = useState<boolean>(false);
  const [periodCheckBox, setPeriodCheckBox] = useState<string>(
    PERIOD_TEXT.NINETY_DAYS,
  );
  const [periodStartDate, setPeriodStartDate] = useState<string>(
    moment().subtract(90, 'days').format('YYYY-MM-DD'),
  );
  const [periodEndDate, setPeriodEndDate] = useState<string>(
    moment().format('YYYY-MM-DD'),
  );
  const [statusCheckBox, setStatusCheckBox] = useState<string>(STATUS_TEXT.ALL);
  const [delivery_methodCheckBox, setDelivery_methodCheckBox] =
    useState<string>(DELIVERY_METHOD_TEXT.ALL);
  const [insuredCheckBox, setInsuredCheckBox] = useState<string>(
    INSURED_TEXT.ALL,
  );
  const [detailCondition, setDetailCondition] = useState<string>(
    DETAIL_CONDITION_TEXT.PATIENT_NAME,
  );
  const [detailText, setDetailText] = useState<string>('');

  const [isDetailFilterClicked, setIsDetailFilterClicked] = useState(false);

  const clickDetailFilter = () => {
    setIsDetailFilterClicked(!isDetailFilterClicked);
  };

  const handleDetailFilter = (text: string) => {
    setDetailCondition(text);
    setDetailText('');
  };

  const onChangeDetailText = (e: FormEvent<HTMLInputElement>) => {
    setDetailText(e.currentTarget.value);
  };

  const onChangeStartDate = (e: FormEvent<HTMLInputElement>) => {
    setPeriodStartDate(e.currentTarget.value);
  };

  const onChangeEndDate = (e: FormEvent<HTMLInputElement>) => {
    setPeriodEndDate(e.currentTarget.value);
  };

  const onResetFilter = () => {
    setPeriodCheckBox(PERIOD_TEXT.NINETY_DAYS);
    setPeriodStartDate(moment().subtract(90, 'days').format('YYYY-MM-DD'));
    setPeriodEndDate(moment().format('YYYY-MM-DD'));
    setStatusCheckBox(STATUS_TEXT.ALL);
    setDelivery_methodCheckBox(DELIVERY_METHOD_TEXT.ALL);
    setInsuredCheckBox(INSURED_TEXT.ALL);
    handleDetailFilter(DETAIL_CONDITION_TEXT.PATIENT_NAME);
    setDetailText('');
  };

  const searchFilteredTelepharmacy = () => {
    let statusString: string;

    switch (statusCheckBox) {
      case STATUS_TEXT.ALL:
        statusString = 'ALL';
        break;
      case STATUS_TEXT.DELIVERY_FINISH:
        statusString = 'DELIVERY_FINISH';
        break;
      case STATUS_TEXT.DELIVERY_INPROGRESS:
        statusString = 'DELIVERY_INPROGRESS';
        break;
      case STATUS_TEXT.DELIVERY_PICKED_WAITED:
        statusString = 'DELIVERY_PICKED_WAITED';
        break;
      case STATUS_TEXT.PATIENT_CANCEL:
        statusString = 'PATIENT_CANCEL';
        break;
      case STATUS_TEXT.PHARMACIST_CANCEL:
        statusString = 'PHARMACIST_CANCEL';
        break;
      default:
        statusString = 'ALL';
        break;
    }

    let delivery_method: TelepharmacyListDeliveryMethodType | undefined;

    switch (delivery_methodCheckBox) {
      case DELIVERY_METHOD_TEXT.ALL:
        delivery_method = 'all';
        break;
      case DELIVERY_METHOD_TEXT.QUICK:
        delivery_method = 'quick';
        break;
      case DELIVERY_METHOD_TEXT.PARCEL:
        delivery_method = 'parcel';
        break;
      default:
        delivery_method = 'all';
        break;
    }

    let is_uninsured: TelepharmacyListIsUninsuredType | undefined;

    switch (insuredCheckBox) {
      case INSURED_TEXT.ALL:
        is_uninsured = 'all';
        break;
      case INSURED_TEXT.INSURED:
        is_uninsured = 'false';
        break;
      case INSURED_TEXT.NON_INSURED:
        is_uninsured = 'true';
        break;
      default:
        is_uninsured = 'all';
        break;
    }

    let q_type: string | undefined;

    switch (detailCondition) {
      case DETAIL_CONDITION_TEXT.PATIENT_NAME:
        q_type = 'patient_name';
        break;
      case DETAIL_CONDITION_TEXT.PHONE_NUMBER:
        q_type = 'phone_num';
        break;
      case DETAIL_CONDITION_TEXT.ADDRESS:
        q_type = 'address';
        break;
      case DETAIL_CONDITION_TEXT.HOSPITAL_NAME:
        q_type = 'hospital_name';
        break;
      case DETAIL_CONDITION_TEXT.DOCTOR_NAME:
        q_type = 'doctor_name';
        break;
      case DETAIL_CONDITION_TEXT.WAYBILL_NUMBER:
        q_type = 'waybill_num';
        break;
      default:
        q_type = undefined;
        break;
    }

    handleFilters({
      from_date: periodStartDate,
      to_date: periodEndDate,
      statusString,
      delivery_method,
      is_uninsured,
      q_type,
      q: detailText,
    });
  };

  return (
    <Style.Container
      isFilterAreaOpen={isFilterAreaOpen}
      onClick={() => setIsDetailFilterClicked(false)}
    >
      {!isFilterAreaOpen ? (
        <Style.FilterItemArea>
          <Style.FilterItemRightArea>
            <Style.SearchButtonContainer
              width={170}
              height={35}
              borderColor={COLOR['fill/accent']}
              onClick={() => setIsFilterAreaOpen(true)}
            >
              <Font fontType="body1" color="fill/dark">
                기간
              </Font>
              <Font fontType="h4" color="fill/accent">
                {periodCheckBox}
              </Font>
              <ReactBootstrapIcons.ChevronDown color={COLOR['fill/dark']} />
            </Style.SearchButtonContainer>
            {Number(windowWidth) >= BREAK_POINTS.size1440 && (
              <>
                <Blank appoint="Horizontal" size={8} />
                <Style.SearchButtonContainer
                  width={160}
                  height={35}
                  borderColor={COLOR['fill/accent']}
                  onClick={() => setIsFilterAreaOpen(true)}
                >
                  <Font fontType="body1" color="fill/dark">
                    배송방법
                  </Font>
                  <Font fontType="h4" color="fill/accent">
                    {delivery_methodCheckBox}
                  </Font>
                  <ReactBootstrapIcons.ChevronDown color={COLOR['fill/dark']} />
                </Style.SearchButtonContainer>
              </>
            )}
            <Blank appoint="Horizontal" size={8} />
            <Style.SearchButtonContainer
              width={160}
              height={35}
              borderColor={COLOR['fill/accent']}
              onClick={() => setIsFilterAreaOpen(true)}
            >
              <Font fontType="body1" color="fill/dark">
                진행상태
              </Font>
              <Font fontType="h4" color="fill/accent">
                {statusCheckBox}
              </Font>
              <ReactBootstrapIcons.ChevronDown color={COLOR['fill/dark']} />
            </Style.SearchButtonContainer>
            {Number(windowWidth) >= BREAK_POINTS.size1440 && (
              <>
                <Blank appoint="Horizontal" size={8} />
                <Style.SearchButtonContainer
                  width={180}
                  height={35}
                  borderColor={COLOR['fill/accent']}
                  onClick={() => setIsFilterAreaOpen(true)}
                >
                  <Font fontType="body1" color="fill/dark">
                    급여/비급여
                  </Font>
                  <Font fontType="h4" color="fill/accent">
                    {insuredCheckBox}
                  </Font>
                  <ReactBootstrapIcons.ChevronDown color={COLOR['fill/dark']} />
                </Style.SearchButtonContainer>
              </>
            )}
            <Blank appoint="Horizontal" size={8} />
            <Style.SearchButtonContainer
              width={100}
              height={35}
              borderColor={COLOR['fill/mediumLight']}
              onClick={() => {
                setIsFilterAreaOpen(true);
                onResetFilter();
              }}
            >
              <Font fontType="body1" color="fill/dark">
                초기화
              </Font>
              <ReactBootstrapIcons.ArrowRepeat color={COLOR['fill/dark']} />
            </Style.SearchButtonContainer>
          </Style.FilterItemRightArea>
        </Style.FilterItemArea>
      ) : (
        <>
          <Style.FilterItemArea>
            <Style.FilterItemLeftArea>
              <Font fontType="h4" color="fill/dark">
                기간
              </Font>
            </Style.FilterItemLeftArea>
            <Style.FilterItemRightArea>
              <Style.CheckBoxArea>
                <Style.CheckBoxShortWidth>
                  <CheckBox
                    title={PERIOD_TEXT.ALL}
                    isSelected={periodCheckBox === PERIOD_TEXT.ALL}
                    onClick={() => {
                      setPeriodCheckBox(PERIOD_TEXT.ALL);
                      setPeriodStartDate('');
                      setPeriodEndDate('');
                    }}
                  />
                </Style.CheckBoxShortWidth>
                <Style.CheckBoxShortWidth>
                  <CheckBox
                    title={PERIOD_TEXT.TODAY}
                    isSelected={periodCheckBox === PERIOD_TEXT.TODAY}
                    onClick={() => {
                      setPeriodCheckBox(PERIOD_TEXT.TODAY);
                      setPeriodStartDate(moment().format('YYYY-MM-DD'));
                      setPeriodEndDate(moment().format('YYYY-MM-DD'));
                    }}
                  />
                </Style.CheckBoxShortWidth>
                <Style.CheckBoxLongWidth>
                  <CheckBox
                    title={PERIOD_TEXT.SEVEN_DAYS}
                    isSelected={periodCheckBox === PERIOD_TEXT.SEVEN_DAYS}
                    onClick={() => {
                      setPeriodCheckBox(PERIOD_TEXT.SEVEN_DAYS);
                      setPeriodStartDate(
                        moment().subtract(7, 'days').format('YYYY-MM-DD'),
                      );
                      setPeriodEndDate(moment().format('YYYY-MM-DD'));
                    }}
                  />
                </Style.CheckBoxLongWidth>
                <Style.CheckBoxLongWidth>
                  <CheckBox
                    title={PERIOD_TEXT.THIRTY_DAYS}
                    isSelected={periodCheckBox === PERIOD_TEXT.THIRTY_DAYS}
                    onClick={() => {
                      setPeriodCheckBox(PERIOD_TEXT.THIRTY_DAYS);
                      setPeriodStartDate(
                        moment().subtract(30, 'days').format('YYYY-MM-DD'),
                      );
                      setPeriodEndDate(moment().format('YYYY-MM-DD'));
                    }}
                  />
                </Style.CheckBoxLongWidth>
                <Style.CheckBoxLongWidth>
                  <CheckBox
                    title={PERIOD_TEXT.NINETY_DAYS}
                    isSelected={periodCheckBox === PERIOD_TEXT.NINETY_DAYS}
                    onClick={() => {
                      setPeriodCheckBox(PERIOD_TEXT.NINETY_DAYS);
                      setPeriodStartDate(
                        moment().subtract(90, 'days').format('YYYY-MM-DD'),
                      );
                      setPeriodEndDate(moment().format('YYYY-MM-DD'));
                    }}
                  />
                </Style.CheckBoxLongWidth>
              </Style.CheckBoxArea>
              <Blank appoint="Horizontal" size={16} />
              <Style.DatePickerArea>
                <Style.DatePickerInput
                  value={periodStartDate}
                  onChange={onChangeStartDate}
                />
                <Blank appoint="Horizontal" size={8} />
                ~
                <Blank appoint="Horizontal" size={8} />
                <Style.DatePickerInput
                  value={periodEndDate}
                  onChange={onChangeEndDate}
                />
              </Style.DatePickerArea>
            </Style.FilterItemRightArea>
          </Style.FilterItemArea>
          <Style.FilterItemArea>
            <Style.FilterItemLeftArea>
              <Font fontType="h4" color="fill/dark">
                배송방법
              </Font>
            </Style.FilterItemLeftArea>
            <Style.FilterItemRightArea>
              <Style.CheckBoxArea>
                <Style.CheckBoxShortWidth>
                  <CheckBox
                    title={DELIVERY_METHOD_TEXT.ALL}
                    isSelected={
                      delivery_methodCheckBox === DELIVERY_METHOD_TEXT.ALL
                    }
                    onClick={() =>
                      setDelivery_methodCheckBox(DELIVERY_METHOD_TEXT.ALL)
                    }
                  />
                </Style.CheckBoxShortWidth>
                <Style.CheckBoxShortWidth>
                  <CheckBox
                    title={DELIVERY_METHOD_TEXT.QUICK}
                    isSelected={
                      delivery_methodCheckBox === DELIVERY_METHOD_TEXT.QUICK
                    }
                    onClick={() =>
                      setDelivery_methodCheckBox(DELIVERY_METHOD_TEXT.QUICK)
                    }
                  />
                </Style.CheckBoxShortWidth>
                <Style.CheckBoxShortWidth>
                  <CheckBox
                    title={DELIVERY_METHOD_TEXT.PARCEL}
                    isSelected={
                      delivery_methodCheckBox === DELIVERY_METHOD_TEXT.PARCEL
                    }
                    onClick={() =>
                      setDelivery_methodCheckBox(DELIVERY_METHOD_TEXT.PARCEL)
                    }
                  />
                </Style.CheckBoxShortWidth>
              </Style.CheckBoxArea>
            </Style.FilterItemRightArea>
          </Style.FilterItemArea>
          <Style.FilterItemArea>
            <Style.FilterItemLeftArea>
              <Font fontType="h4" color="fill/dark">
                진행상태
              </Font>
            </Style.FilterItemLeftArea>
            <Style.FilterItemRightArea>
              <Style.CheckBoxArea>
                <Style.CheckBoxShortWidth>
                  <CheckBox
                    title={STATUS_TEXT.ALL}
                    isSelected={statusCheckBox === STATUS_TEXT.ALL}
                    onClick={() => setStatusCheckBox(STATUS_TEXT.ALL)}
                  />
                </Style.CheckBoxShortWidth>
                <Style.CheckBoxLongWidth>
                  <CheckBox
                    title={STATUS_TEXT.DELIVERY_FINISH}
                    isSelected={statusCheckBox === STATUS_TEXT.DELIVERY_FINISH}
                    onClick={() =>
                      setStatusCheckBox(STATUS_TEXT.DELIVERY_FINISH)
                    }
                  />
                </Style.CheckBoxLongWidth>
                <Style.CheckBoxLongWidth>
                  <CheckBox
                    title={STATUS_TEXT.DELIVERY_INPROGRESS}
                    isSelected={
                      statusCheckBox === STATUS_TEXT.DELIVERY_INPROGRESS
                    }
                    onClick={() =>
                      setStatusCheckBox(STATUS_TEXT.DELIVERY_INPROGRESS)
                    }
                  />
                </Style.CheckBoxLongWidth>
                <Style.CheckBoxLongWidth>
                  <CheckBox
                    title={STATUS_TEXT.DELIVERY_PICKED_WAITED}
                    isSelected={
                      statusCheckBox === STATUS_TEXT.DELIVERY_PICKED_WAITED
                    }
                    onClick={() =>
                      setStatusCheckBox(STATUS_TEXT.DELIVERY_PICKED_WAITED)
                    }
                  />
                </Style.CheckBoxLongWidth>
                <Style.CheckBoxLongWidth>
                  <CheckBox
                    title={STATUS_TEXT.PATIENT_CANCEL}
                    isSelected={statusCheckBox === STATUS_TEXT.PATIENT_CANCEL}
                    onClick={() =>
                      setStatusCheckBox(STATUS_TEXT.PATIENT_CANCEL)
                    }
                  />
                </Style.CheckBoxLongWidth>
                <Style.CheckBoxLongWidth>
                  <CheckBox
                    title={STATUS_TEXT.PHARMACIST_CANCEL}
                    isSelected={
                      statusCheckBox === STATUS_TEXT.PHARMACIST_CANCEL
                    }
                    onClick={() =>
                      setStatusCheckBox(STATUS_TEXT.PHARMACIST_CANCEL)
                    }
                  />
                </Style.CheckBoxLongWidth>
              </Style.CheckBoxArea>
            </Style.FilterItemRightArea>
          </Style.FilterItemArea>

          <Style.FilterItemArea>
            <Style.FilterItemLeftArea>
              <Font fontType="h4" color="fill/dark">
                상세조건
              </Font>
            </Style.FilterItemLeftArea>
            <Style.FilterItemRightArea>
              <Button
                width="220px"
                height="36px"
                borderColor={COLOR['fill/medium']}
                backgroundColor={COLOR['fill/white']}
                title={
                  <Style.IconButtonItem>
                    <Font fontType="body1" color="fill/dark">
                      {detailCondition}
                    </Font>
                    <Blank appoint="Horizontal" size={92} />
                    {isDetailFilterClicked ? (
                      <ReactBootstrapIcons.ChevronUp
                        size={16}
                        color={COLOR['fill/black']}
                      />
                    ) : (
                      <ReactBootstrapIcons.ChevronDown
                        size={16}
                        color={COLOR['fill/black']}
                      />
                    )}
                  </Style.IconButtonItem>
                }
                onClick={(event) => {
                  event.stopPropagation();
                  clickDetailFilter();
                }}
                onBlur={() => {
                  setIsDetailFilterClicked(false);
                }}
              />
              {isDetailFilterClicked ? (
                <Style.OptionRightAreaDropdownList
                  onMouseDown={(event) => event.preventDefault()}
                >
                  <Style.OptionRightAreaDropdownItem
                    onClick={() => {
                      handleDetailFilter(DETAIL_CONDITION_TEXT.PATIENT_NAME);
                      clickDetailFilter();
                    }}
                  >
                    {DETAIL_CONDITION_TEXT.PATIENT_NAME}
                  </Style.OptionRightAreaDropdownItem>
                  <Blank appoint="Vertical" size={16} />
                  <Style.OptionRightAreaDropdownItem
                    onClick={() => {
                      handleDetailFilter(DETAIL_CONDITION_TEXT.PHONE_NUMBER);
                      clickDetailFilter();
                    }}
                  >
                    {DETAIL_CONDITION_TEXT.PHONE_NUMBER}
                  </Style.OptionRightAreaDropdownItem>
                  <Blank appoint="Vertical" size={16} />
                  <Style.OptionRightAreaDropdownItem
                    onClick={() => {
                      handleDetailFilter(DETAIL_CONDITION_TEXT.ADDRESS);
                      clickDetailFilter();
                    }}
                  >
                    {DETAIL_CONDITION_TEXT.ADDRESS}
                  </Style.OptionRightAreaDropdownItem>
                  <Blank appoint="Vertical" size={16} />
                  <Style.OptionRightAreaDropdownItem
                    onClick={() => {
                      handleDetailFilter(DETAIL_CONDITION_TEXT.HOSPITAL_NAME);
                      clickDetailFilter();
                    }}
                  >
                    {DETAIL_CONDITION_TEXT.HOSPITAL_NAME}
                  </Style.OptionRightAreaDropdownItem>
                  <Blank appoint="Vertical" size={16} />
                  <Style.OptionRightAreaDropdownItem
                    onClick={() => {
                      handleDetailFilter(DETAIL_CONDITION_TEXT.DOCTOR_NAME);
                      clickDetailFilter();
                    }}
                  >
                    {DETAIL_CONDITION_TEXT.DOCTOR_NAME}
                  </Style.OptionRightAreaDropdownItem>
                  <Blank appoint="Vertical" size={16} />
                  <Style.OptionRightAreaDropdownItem
                    onClick={() => {
                      handleDetailFilter(DETAIL_CONDITION_TEXT.WAYBILL_NUMBER);
                      clickDetailFilter();
                    }}
                  >
                    {DETAIL_CONDITION_TEXT.WAYBILL_NUMBER}
                  </Style.OptionRightAreaDropdownItem>
                </Style.OptionRightAreaDropdownList>
              ) : null}
              <Blank appoint="Horizontal" size={16} />
              <Style.OptionRightAreaTextInputArea>
                <TextInput
                  value={detailText}
                  type="text"
                  onChange={onChangeDetailText}
                  height={38}
                  placeholder={
                    detailCondition === DETAIL_CONDITION_TEXT.PHONE_NUMBER
                      ? '010-1234-5678 또는 01012345678'
                      : `${detailCondition}(을)를 입력해주세요.`
                  }
                  fontSize={16}
                />
              </Style.OptionRightAreaTextInputArea>
            </Style.FilterItemRightArea>
          </Style.FilterItemArea>
          <Style.ButtonArea>
            <Button
              width="148px"
              height="48px"
              borderColor={COLOR['fill/accent']}
              backgroundColor={COLOR['fill/white']}
              title={
                <Style.IconButtonItem>
                  <Font fontType="h4" color="fill/accent">
                    초기화
                  </Font>
                </Style.IconButtonItem>
              }
              onClick={() => onResetFilter()}
            />
            <Blank appoint="Horizontal" size={8} />
            <Button
              width="148px"
              height="48px"
              borderColor={COLOR['fill/accent']}
              backgroundColor={COLOR['fill/accent']}
              title={
                <Style.IconButtonItem>
                  <Font fontType="h4" color="fill/white">
                    조회하기
                  </Font>
                </Style.IconButtonItem>
              }
              onClick={() => searchFilteredTelepharmacy()}
            />
          </Style.ButtonArea>
        </>
      )}
    </Style.Container>
  );
}

export default FilterComponent;
