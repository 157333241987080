import { COLOR, Font } from '@components/atoms';
import Button from '@components/atoms/button';
import SvgIcon from '@components/atoms/images';
import styled from 'styled-components';

interface FooterProps {
  height: number;
  onClick: () => Promise<void>;
}

function Footer({ height, onClick }: FooterProps) {
  return (
    <FooterContainer height={height}>
      <Button
        title={
          <ButtonTextContainer>
            <SvgIcon icon="check" color="fill/white" width={17} />
            <Font fontType="body1" color="fill/white">
              휴무 등록하기
            </Font>
          </ButtonTextContainer>
        }
        onClick={onClick}
        backgroundColor={COLOR['fill/black']}
        width="136px"
        height="48px"
      />
    </FooterContainer>
  );
}

const FooterContainer = styled.div<{ height: number }>`
  display: flex;
  justify-content: flex-end;

  width: 100%;
  height: ${(props) => props.height}rem;
  padding: 1.25rem 2.5rem;

  border-top: 1px solid ${COLOR['fill/mediumLight']};
`;

const ButtonTextContainer = styled.div`
  display: flex;
  gap: 0.625rem;
`;

export default Footer;
