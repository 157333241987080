import { Font, TextArea } from '@components/atoms';
import { ChangeEvent } from 'react';
import {
  BottomArea,
  LabelBox,
  RadioBox,
  TextAreaContainer,
  TopArea,
} from './index.style';

interface TextButtonProps {
  radioName: string;
  title: string;
  isSelected: boolean;
  onClick: () => void;
  value: string | number;
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  type: 'text' | 'password' | 'number';
  placeholder?: string;
  height?: number | string;
  width?: number | string;
  fontSize?: number | string;
}

function TextButtonWithTextArea({
  radioName,
  title,
  onClick,
  isSelected,
  value,
  onChange,
  type,
  placeholder,
  height,
  width,
  fontSize,
}: TextButtonProps) {
  return (
    <TextAreaContainer isSelected={isSelected}>
      <TopArea>
        <RadioBox
          isSelected={isSelected}
          name={radioName}
          id={title}
          type="radio"
          onClick={onClick}
        />
        <LabelBox htmlFor={title}>
          <Font fontType="body1_medium" color={'fill/black'}>
            {title}
          </Font>
        </LabelBox>
      </TopArea>
      {isSelected ? (
        <BottomArea>
          <TextArea
            value={value}
            onChange={onChange}
            type={type}
            placeholder={placeholder}
            height={height}
            width={width}
            fontSize={fontSize}
          />
        </BottomArea>
      ) : null}
    </TextAreaContainer>
  );
}

export default TextButtonWithTextArea;
