import { Button, COLOR, Font } from '@components/atoms';
import SvgIcon from '@components/atoms/images';
import { notificationOval } from '@components/atoms/lottie';
import { TOAST_ID } from '@components/organisms/utils/config';
import useMedicineUnitManagementModalHook from '@pages/wrapper/modals/medicineUnitManagementModal/hooks';
import { myDoctorAPI } from '@services/myDoctor';
import { useAppDispatch, useAppSelector } from '@stores/hooks';
import { getPharmacyAutoConfirmed } from '@stores/telepharmacyStore/telepharmacyList';
import { selectUserInformationPharmacistData } from '@stores/userInformationStore';
import {
  getBrowserInfo,
  getNotificationPermissionStatus,
} from '@utils/browser';
import Lottie from 'lottie-react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import AllowToast from '../allowToast';
import NotificationToastUI from './components/notificationToastUI';
import * as Style from './index.style';

interface NotificationToastProps {
  showNotificationToastType:
    | 'notification'
    | 'price-register'
    | 'wegovy-register'
    | 'auto-confirm'
    | 'prepareMedicine-probability-up';
}

function NotificationToast({
  showNotificationToastType,
}: NotificationToastProps) {
  const userBrowserInfo = getBrowserInfo();
  const permissionStatus = getNotificationPermissionStatus();
  const pharmacist = useAppSelector(selectUserInformationPharmacistData);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { openModal: openRegisterMedicineUnitModal, setSelectedCategory } =
    useMedicineUnitManagementModalHook();

  const isEdgeNotificationAllow =
    userBrowserInfo === 'Edge' &&
    permissionStatus === '이 브라우저는 알림을 지원하지 않습니다.';

  const isChormeNotificationAllow =
    userBrowserInfo === 'Chrome' &&
    permissionStatus === '이 브라우저는 알림을 지원하지 않습니다.';

  const handleClickNotificationMethod = () => {
    if (!window) return;

    if (userBrowserInfo === 'Chrome') {
      window.open(
        'https://merakiplace.notion.site/Chrome-17d7f40596d2447e8f557e56800863df?pvs=25',
        '_blank',
      );
    } else if (userBrowserInfo === 'Edge') {
      window.open(
        'https://merakiplace.notion.site/Edge-7ebedfa4378b41988602e3e8a2f710cb',
        '_blank',
      );
    } else {
      window.open(
        'https://merakiplace.notion.site/Edge-7ebedfa4378b41988602e3e8a2f710cb',
        '_blank',
      );
    }
  };

  const handleClickPriceRegister = () => {
    navigate('/home/medicineManagement');
  };

  const handleClickAutoConfirm = async () => {
    if (!pharmacist.pharmacy_id) return;

    await myDoctorAPI
      .patchPharmacyAutoConfirmed({
        id: pharmacist.pharmacy_id || 0,
        auto_confirmed: true,
      })
      .then(() => {
        dispatch(
          getPharmacyAutoConfirmed({
            id: pharmacist.pharmacy_id || 0,
          }),
        );
      })
      .then(() => {
        toast(
          <AllowToast
            icon={
              <SvgIcon
                icon="checkCircleIcon"
                color="state/success"
                width={24}
              />
            }
            title={
              <Font fontType="h4" color="fill/black">
                비급여 자동수락 기능이 켜졌어요!
              </Font>
            }
          />,
          { position: 'top-center' },
        );
      });
  };

  const handleRegisterPrepareMedicineProbabilityUp = () => {
    window.open(
      'https://merakiplace.notion.site/UP-5045622fdf6e4d4abc9a1c836dc46b9a?pvs=4',
      '_blank',
    );
  };

  return (
    <>
      {showNotificationToastType === 'notification' && (
        <NotificationToastUI
          icon={
            <div>
              <Lottie animationData={notificationOval} style={{ width: 32 }} />
            </div>
          }
          title={
            <Font fontType="h3" color="fill/black">
              조제 접수 알림을 켜주세요!
            </Font>
          }
          description={
            <Font fontType="body2" color="fill/dark">
              {isChormeNotificationAllow && (
                <>
                  크롬 브라우저의 알림 설정이 꺼져 있어요.
                  <br />
                  알림을 켜고 조제요청을 더 쉽게 확인해 보세요!
                </>
              )}
              {isEdgeNotificationAllow && (
                <>
                  엣지 브라우저의 알림 설정이 꺼져 있어요.
                  <br />
                  알림을 켜고 조제요청을 더 쉽게 확인해 보세요!
                </>
              )}
              {!isEdgeNotificationAllow && !isChormeNotificationAllow && (
                <>
                  현재 조제 접수 알림이 비활성화된 상태에요.
                  <br /> 알림을 켜면 새로운 조제 요청을 바로 알려드려요!
                </>
              )}
            </Font>
          }
          button={
            <Button
              padding="5px 9px 5px 12px"
              backgroundColor={COLOR['fill/accent']}
              borderRadius="6px"
              onClick={handleClickNotificationMethod}
              title={
                <Style.NotificationMethodButtonArea>
                  <Font fontType="body2_medium" color="fill/white">
                    알림 설정법 보기
                  </Font>
                  <Style.ChevronDownIconArea>
                    <SvgIcon icon="chevronDown" width={16} color="fill/white" />
                  </Style.ChevronDownIconArea>
                </Style.NotificationMethodButtonArea>
              }
            />
          }
        />
      )}

      {showNotificationToastType === 'price-register' && (
        <NotificationToastUI
          icon={
            <div>
              <SvgIcon icon="priceIcon" width={24} color="yellow900" />
            </div>
          }
          title={
            <Font fontType="h3" color="fill/black">
              <>
                비급여 의약품 가격을 등록하고,
                <br />
                자동으로 조제를 수락하세요!
              </>
            </Font>
          }
          description={
            <Font fontType="body2" color="fill/dark">
              <>
                환자들에게 비급여 가격을 노출하고,
                <br />
                처방전 확인 없이 결제까지 한 번에 가능해요.
              </>
            </Font>
          }
          button={
            <Button
              padding="5px 9px 5px 12px"
              backgroundColor={COLOR['fill/accent']}
              borderRadius="6px"
              onClick={() => {
                handleClickPriceRegister();
                toast.dismiss();
              }}
              title={
                <Style.NotificationMethodButtonArea>
                  <Font fontType="body2_medium" color="fill/white">
                    비급여 가격 등록하기
                  </Font>
                  <Style.ChevronDownIconArea>
                    <SvgIcon icon="chevronDown" width={16} color="fill/white" />
                  </Style.ChevronDownIconArea>
                </Style.NotificationMethodButtonArea>
              }
            />
          }
        />
      )}

      {showNotificationToastType === 'wegovy-register' && (
        <NotificationToastUI
          icon={
            <div>
              <SvgIcon icon="speakerIcon" width={24} color="yellow900" />
            </div>
          }
          title={
            <Font fontType="h3" color="fill/black">
              의약품 관리 ‘위고비’ 추가
            </Font>
          }
          description={
            <Font fontType="body2" color="fill/dark">
              지금 의약품 관리에서 등록할 수 있어요.
            </Font>
          }
          button={
            <Button
              padding="5px 9px 5px 12px"
              backgroundColor={COLOR['fill/accent']}
              borderRadius="6px"
              onClick={() => {
                handleClickPriceRegister();
                toast.dismiss();
                openRegisterMedicineUnitModal();
                setSelectedCategory('다이어트 주사');
              }}
              title={
                <Style.NotificationMethodButtonArea>
                  <Font fontType="body2_medium" color="fill/white">
                    가격 등록하기
                  </Font>
                  <Style.ChevronDownIconArea>
                    <SvgIcon icon="chevronDown" width={16} color="fill/white" />
                  </Style.ChevronDownIconArea>
                </Style.NotificationMethodButtonArea>
              }
            />
          }
        />
      )}

      {showNotificationToastType === 'auto-confirm' && (
        <NotificationToastUI
          icon={
            <div>
              <SvgIcon icon="priceIcon" width={24} color="yellow900" />
            </div>
          }
          title={
            <Font fontType="h3" color="fill/black">
              자동수락 기능을 사용해 보세요!
            </Font>
          }
          description={
            <Font fontType="body2" color="fill/dark">
              <>
                비급여 처방전을 확인할 필요 없이
                <br />
                결제까지 한 번에 가능해요.
              </>
            </Font>
          }
          button={
            <Button
              padding="5px 9px 5px 12px"
              backgroundColor={COLOR['fill/accent']}
              borderRadius="6px"
              onClick={() => {
                handleClickAutoConfirm();
                toast.dismiss();
              }}
              title={
                <Style.NotificationMethodButtonArea>
                  <Font fontType="body2_medium" color="fill/white">
                    자동수락 기능 사용하기
                  </Font>
                  <Style.ChevronDownIconArea>
                    <SvgIcon icon="chevronDown" width={16} color="fill/white" />
                  </Style.ChevronDownIconArea>
                </Style.NotificationMethodButtonArea>
              }
            />
          }
        />
      )}

      {showNotificationToastType === 'prepareMedicine-probability-up' && (
        <NotificationToastUI
          icon={
            <div>
              <SvgIcon icon="speakerIcon" width={24} color="yellow900" />
            </div>
          }
          title={
            <Font fontType="h3" color="fill/black">
              <>
                우리 약국이 취급하는
                <br />
                의약품 내역을 제출해 보세요.
              </>
            </Font>
          }
          description={
            <Font fontType="body2" color="fill/dark">
              <>
                약국에서 조제 가능한 처방전을 가진 환자에게
                <br /> ‘조제 확률이 높은 약국’으로 노출돼요.
              </>
            </Font>
          }
          button={
            <Button
              padding="5px 9px 5px 12px"
              backgroundColor={COLOR['fill/accent']}
              borderRadius="6px"
              onClick={() => {
                handleRegisterPrepareMedicineProbabilityUp();
                localStorage.setItem(
                  TOAST_ID.NOTIFICATION_PREPARE_MEDICINE_PROBABILITY_UP,
                  'isRead',
                );
                toast.dismiss();
              }}
              title={
                <Style.NotificationMethodButtonArea>
                  <Font fontType="body2_medium" color="fill/white">
                    지금 등록하기
                  </Font>
                  <Style.ChevronDownIconArea>
                    <SvgIcon icon="chevronDown" width={16} color="fill/white" />
                  </Style.ChevronDownIconArea>
                </Style.NotificationMethodButtonArea>
              }
            />
          }
          executeWhenCloseButtonClicked={() => {
            localStorage.setItem(
              TOAST_ID.NOTIFICATION_PREPARE_MEDICINE_PROBABILITY_UP,
              'isRead',
            );
          }}
        />
      )}
    </>
  );
}

export default NotificationToast;
