import { BColor } from '@components/meraki-ui/BColor';
import { IconDetailProps } from '@components/meraki-ui/BIcon/svg.type';

export default function CheckListIcon({
  width,
  height,
  color,
}: IconDetailProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.57143 14.8571C6.57143 14.5416 6.82727 14.2857 7.14286 14.2857H17.4286C17.7442 14.2857 18 14.5416 18 14.8571C18 15.1727 17.7442 15.4286 17.4286 15.4286H7.14286C6.82727 15.4286 6.57143 15.1727 6.57143 14.8571Z"
        fill={BColor[color]}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.57143 10.2857C6.57143 9.97012 6.82727 9.71429 7.14286 9.71429H17.4286C17.7442 9.71429 18 9.97012 18 10.2857C18 10.6013 17.7442 10.8571 17.4286 10.8571H7.14286C6.82727 10.8571 6.57143 10.6013 6.57143 10.2857Z"
        fill={BColor[color]}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.57143 5.71429C6.57143 5.39869 6.82727 5.14286 7.14286 5.14286H17.4286C17.7442 5.14286 18 5.39869 18 5.71429C18 6.02988 17.7442 6.28571 17.4286 6.28571H7.14286C6.82727 6.28571 6.57143 6.02988 6.57143 5.71429Z"
        fill={BColor[color]}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.2612 4.16737C5.48436 4.39052 5.48436 4.75233 5.2612 4.97549L3.54692 6.68978C3.32376 6.91293 2.96195 6.91293 2.7388 6.68978L2.16737 6.11835C1.94421 5.89519 1.94421 5.53338 2.16737 5.31022C2.39052 5.08707 2.75233 5.08707 2.97549 5.31022L3.14286 5.47759L4.45308 4.16737C4.67624 3.94421 5.03805 3.94421 5.2612 4.16737Z"
        fill={BColor[color]}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.2612 8.7388C5.48436 8.96195 5.48436 9.32376 5.2612 9.54692L3.54692 11.2612C3.32376 11.4844 2.96195 11.4844 2.7388 11.2612L2.16737 10.6898C1.94421 10.4666 1.94421 10.1048 2.16737 9.88165C2.39052 9.6585 2.75233 9.6585 2.97549 9.88165L3.14286 10.049L4.45308 8.7388C4.67624 8.51564 5.03805 8.51564 5.2612 8.7388Z"
        fill={BColor[color]}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.2612 13.3102C5.48436 13.5334 5.48436 13.8952 5.2612 14.1183L3.54692 15.8326C3.32376 16.0558 2.96195 16.0558 2.7388 15.8326L2.16737 15.2612C1.94421 15.038 1.94421 14.6762 2.16737 14.4531C2.39052 14.2299 2.75233 14.2299 2.97549 14.4531L3.14286 14.6205L4.45308 13.3102C4.67624 13.0871 5.03805 13.0871 5.2612 13.3102Z"
        fill={BColor[color]}
      />
    </svg>
  );
}
