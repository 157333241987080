import { COLOR } from '@components/atoms';
import styled from 'styled-components';

export const ModalArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const TitleArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ModalBodyArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
`;

export const ModalBodyWarningArea = styled.div`
  display: flex;
  gap: 4px;

  padding: 8px 12px;
  background-color: ${COLOR.red100};
  border-radius: 8px;
`;

export const ModalBodyContentArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const MedicineCategoryButtonsArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

export const MedicineSearchInputArea = styled.div<{ isFocus: boolean }>`
  display: flex;
  gap: 8px;
  padding: 12px 16px;
  width: 100%;
  border: ${(props) =>
    `1px solid ${
      props.isFocus ? COLOR['fill/black'] : COLOR['border/outline']
    }`};
  border-radius: 8px;
  cursor: pointer;
`;

export const MedicineSearchDisabledInputArea = styled.div`
  display: flex;
  gap: 8px;
  padding: 12px 16px;
  width: 100%;
  border: 1px solid ${COLOR['border/outline']};
  background-color: ${COLOR['fill/light']};
  border-radius: 8px;
  cursor: disabled;
`;

export const MedicineSumInputsArea = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const MedicineUnitInputArea = styled.div`
  flex: 1;
  display: flex;
  width: fit-content;
  gap: 8px;
  padding: 12px 16px;
  border: 1px solid ${COLOR['border/outline']};
  border-radius: 8px;
  cursor: pointer;
  background-color: ${COLOR['fill/light']};
`;

export const MedicineSumInputArea = styled.div<{ isFocus: boolean }>`
  flex: 4;
  display: flex;
  width: 302px;
  gap: 8px;
  padding: 12px 16px;
  border: ${(props) =>
    `1px solid ${
      props.isFocus ? COLOR['fill/black'] : COLOR['border/outline']
    }`};
  border-radius: 8px;
  cursor: pointer;
`;

export const MedicineSearchDisabledInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  background-color: ${COLOR['fill/light']};
`;

export const SelectedMedicineArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  padding: 3px 6px 3px 8px;
  border-radius: 4px;
  background-color: ${COLOR['dim/thick']};
`;

export const MedicineSearchInput = styled.input`
  width: 100%;
  border: none;
  font-size: 16px;
  line-height: 24px;
  ::placeholder {
    color: ${COLOR['fill/medium']};
  }
  :disabled {
    background-color: ${COLOR['fill/light']};
  }
`;

export const MedicineComboBoxArea = styled.div`
  position: absolute;
  overflow-y: scroll;
  width: 420px;
  height: 240px;
  top: 406px;
  border-radius: 8px;
  border: 1px solid ${COLOR['border/outline']};
  background-color: ${COLOR['fill/white']};
`;

export const MedicineItemArea = styled.div`
  padding: 14px 16px;
  cursor: pointer;
  :hover {
    background-color: ${COLOR['hover/white']};
  }
`;

export const MedicineSelectedButtonArea = styled.div`
  display: flex;
  gap: 4px;
`;

export const SubTitleArea = styled.div`
  display: flex;
  gap: 6px;
`;

export const RequiredUI = styled.div`
  padding: 2px 8px;
  border-radius: 4px;
  background-color: ${COLOR['label/lightBlue']};
`;

export const ModalFooterArea = styled.div`
  padding-top: 30px;
  border-top: 1px solid ${COLOR['border/divider']};
  display: flex;
  gap: 12px;
`;
