import styled, { css } from 'styled-components';

type Props = {
  type: 'default' | 'insured';
  text: string;
  isUnInsured?: boolean;
};

function Badge({ type, text, isUnInsured }: Props) {
  return (
    <Container type={type} isUnInsured={isUnInsured}>
      {text}
    </Container>
  );
}

export default Badge;

const Container = styled.div<Omit<Props, 'text'>>`
  display: flex;
  padding: 3px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  font-family: Pretendard, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;

  ${({ type, isUnInsured }) => css`
    background-color: ${type === 'default'
      ? '#f1f2f4'
      : isUnInsured
      ? '#FFF8DB'
      : '#E5FAEC'};
    color: ${type === 'default'
      ? '#686B73'
      : isUnInsured
      ? '#FFB200'
      : '#00C785'};
  `};
`;
