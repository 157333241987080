import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '@stores';
import { SignupModalType } from './index.d';

const initialState: SignupModalType = {
  isVisible: false,
};

export const signupModal = createSlice({
  name: 'modal/signupModal',
  initialState,
  reducers: {
    openModal_signupModal: (state) => {
      state.isVisible = true;
    },
    closeModal_signupModal: (state) => {
      state.isVisible = false;
    },
  },
});

export const { openModal_signupModal, closeModal_signupModal } =
  signupModal.actions;

export const selectSignupModalVisible = (state: RootState) =>
  state.signupModal.isVisible;

export default signupModal.reducer;
