import { useCallback, useRef } from 'react';

import DatePicker from 'react-datepicker';

import DatePickerInput from '@components/atoms/datePicker/DatePickerInput';
import { getSubInjectTimeFormatter } from '@functions/formatters/timeFormatter';
import ContentItem from '@pages/timeManagementPage/registerOffDayModal/ContentItem';

interface DatepickerItemProps {
  date: Date | null;
  title: string;
  onDateChange: (date: Date | null) => void;
  onDateInputChange: (value: string) => void;
}

function DatepickerItem({
  date,
  onDateChange,
  onDateInputChange,
  title,
}: DatepickerItemProps) {
  const dateRef = useRef<DatePicker>(null);
  const dateInputRef = useRef<HTMLInputElement>(null);

  const onInputClick = useCallback(() => {
    dateRef.current?.setOpen(true);
  }, []);

  return (
    <ContentItem title={title}>
      <DatePicker
        ref={dateRef}
        selected={date}
        onChange={onDateChange}
        onInputClick={onInputClick}
        customInput={
          <DatePickerInput
            value={getSubInjectTimeFormatter(date?.toString())}
            onChange={onDateInputChange}
            onClick={onInputClick}
            ref={dateInputRef}
            height={38}
            width={260}
            fontSize={16}
          />
        }
        withPortal
      />
    </ContentItem>
  );
}

export default DatepickerItem;
