import { memo, useCallback, useMemo, useState } from 'react';

import styled from 'styled-components';

import AlarmIcon from '@components/meraki-ui/BIcon/AlarmIcon';
import ArrowLeftInCircleIcon from '@components/meraki-ui/BIcon/ArrowLeftInCircleIcon';
import ArrowRightInCircleIcon from '@components/meraki-ui/BIcon/ArrowRightInCircleIcon';
import BellIcon from '@components/meraki-ui/BIcon/BellIcon';
import BulbIcon from '@components/meraki-ui/BIcon/BulbIcon';
import CalendarCheckIcon from '@components/meraki-ui/BIcon/CalendarCheckIcon';
import CalendarIcon from '@components/meraki-ui/BIcon/CalendarIcon';
import CalendarPlusIcon from '@components/meraki-ui/BIcon/CalendarPlusIcon';
import CallIcon from '@components/meraki-ui/BIcon/CallIcon';
import ChangeIcon from '@components/meraki-ui/BIcon/ChangeIcon';
import ChatDefaultIcon from '@components/meraki-ui/BIcon/ChatDefaultIcon';
import ChatQuotesIcon from '@components/meraki-ui/BIcon/ChatQuotesIcon';
import CheckIcon from '@components/meraki-ui/BIcon/CheckIcon';
import CheckListIcon from '@components/meraki-ui/BIcon/CheckListIcon';
import ChevronDownIcon from '@components/meraki-ui/BIcon/ChevronDown';
import ClassificationIcon from '@components/meraki-ui/BIcon/ClassificationIcon';
import ClockIcon from '@components/meraki-ui/BIcon/ClockIcon';
import CreditCardIcon from '@components/meraki-ui/BIcon/CreditCardIcon';
import CupIcon from '@components/meraki-ui/BIcon/CupIcon';
import DoorIcon from '@components/meraki-ui/BIcon/DoorIcon';
import DropdownIcon from '@components/meraki-ui/BIcon/DropdownIcon';
import DuplicateIcon from '@components/meraki-ui/BIcon/DuplicateIcon';
import ErrorPageIcon from '@components/meraki-ui/BIcon/ErrorPageIcon';
import ExclamationDefaultIcon from '@components/meraki-ui/BIcon/ExclamationDefaultIcon';
import ExclamationInCircleIcon from '@components/meraki-ui/BIcon/ExclamationInCircleIcon';
import GNBLogo from '@components/meraki-ui/BIcon/GNBLogo';
import HealthWellIcon from '@components/meraki-ui/BIcon/HealthWellIcon';
import HeartIcon from '@components/meraki-ui/BIcon/HeartIcon';
import HospitalIcon from '@components/meraki-ui/BIcon/HospitalIcon';
import IDCardIcon from '@components/meraki-ui/BIcon/IDCardIcon';
import LoadingIcon from '@components/meraki-ui/BIcon/LoadingIcon';
import LoadingLogoIcon from '@components/meraki-ui/BIcon/LoadingLogoIcon';
import LoginLogo from '@components/meraki-ui/BIcon/LoginLogo';
import MinusIcon from '@components/meraki-ui/BIcon/MinusIcon';
import PauseInCircleIcon from '@components/meraki-ui/BIcon/PauseInCircleIcon';
import PauseInSquareIcon from '@components/meraki-ui/BIcon/PauseInSquare';
import PencilSquareIcon from '@components/meraki-ui/BIcon/PencilSquareIcon';
import PipetteIcon from '@components/meraki-ui/BIcon/PipetteIcon';
import PlusIcon from '@components/meraki-ui/BIcon/PlusIcon';
import PrescriptionIcon from '@components/meraki-ui/BIcon/PrescriptionIcon';
import PressureIcon from '@components/meraki-ui/BIcon/PressureIcon';
import QuestionMarkInCircleIcon from '@components/meraki-ui/BIcon/QuestionMarkInCircleIcon';
import ReplayInCircleIcon from '@components/meraki-ui/BIcon/ReplayInCircleIcon';
import ResetIcon from '@components/meraki-ui/BIcon/ResetIcon';
import SearchIcon from '@components/meraki-ui/BIcon/SearchIcon';
import SheetIcon from '@components/meraki-ui/BIcon/SheetIcon';
import SneakersIcon from '@components/meraki-ui/BIcon/SneakersIcon';
import { BIconProps, IconType } from '@components/meraki-ui/BIcon/svg.type';
import UploadIcon from '@components/meraki-ui/BIcon/UploadIcon';
import UserIcon from '@components/meraki-ui/BIcon/UserIcon';
import UserIDIcon from '@components/meraki-ui/BIcon/UserIDIcon';
import WaterDropIcon from '@components/meraki-ui/BIcon/WaterDropIcon';
import WeightIcon from '@components/meraki-ui/BIcon/WeightIcon';
import XIcon from '@components/meraki-ui/BIcon/XIcon';
import XInCircleIcon from '@components/meraki-ui/BIcon/XInCircleIcon';

function BIcon({
  icon,
  width: inputWidth,
  height,
  color: inputColor,
  onClick,
}: BIconProps) {
  const [mouseOver, setMouseOver] = useState<boolean>(false);

  const onMouseOver = useCallback(() => {
    setMouseOver(true);
  }, []);

  const onMouseLeave = useCallback(() => {
    setMouseOver(false);
  }, []);

  const iconMap: Record<IconType, JSX.Element> = useMemo(() => {
    const width = inputWidth ?? 20;
    const color = inputColor ?? 'primary08';
    const backgroundHoverColor = inputColor ?? 'primary09';

    const props = {
      width,
      color: mouseOver ? backgroundHoverColor : color,
      height,
    };

    return {
      exclamation_in_circle: <ExclamationInCircleIcon {...props} />,
      plus: <PlusIcon {...props} />,
      minus: <MinusIcon {...props} />,
      x: <XIcon {...props} />,
      x_in_circle: <XInCircleIcon {...props} />,
      exclamation_default: <ExclamationDefaultIcon {...props} />,
      calendar: <CalendarIcon {...props} />,
      calendar_plus: <CalendarPlusIcon {...props} />,
      calendar_check: <CalendarCheckIcon {...props} />,
      check_list: <CheckListIcon {...props} />,
      clock: <ClockIcon {...props} />,
      user_id: <UserIDIcon {...props} />,
      alarm: <AlarmIcon {...props} />,
      user: <UserIcon {...props} />,
      bulb: <BulbIcon {...props} />,
      arrow_right: <ArrowRightInCircleIcon {...props} />,
      arrow_left: <ArrowLeftInCircleIcon {...props} />,
      cup: <CupIcon {...props} />,
      door: <DoorIcon {...props} />,
      pencil_square: <PencilSquareIcon {...props} />,

      chat_default: <ChatDefaultIcon {...props} />,
      chat_quotes: <ChatQuotesIcon {...props} />,
      id_card: <IDCardIcon {...props} />,
      sheet: <SheetIcon {...props} />,
      duplicate: <DuplicateIcon {...props} />,
      call: <CallIcon {...props} />,
      classification: <ClassificationIcon {...props} />,
      change: <ChangeIcon {...props} />,
      check: <CheckIcon {...props} />,
      health_well: <HealthWellIcon {...props} />,
      sneakers: <SneakersIcon {...props} />,
      upload: <UploadIcon {...props} />,
      prescription: <PrescriptionIcon {...props} />,
      pause_in_circle: <PauseInCircleIcon {...props} />,
      pause_in_square: <PauseInSquareIcon {...props} />,

      credit_card: <CreditCardIcon {...props} />,
      dropdown: <DropdownIcon {...props} />,
      heart: <HeartIcon {...props} />,
      bell: <BellIcon {...props} />,
      pipette: <PipetteIcon {...props} />,
      chevron_down: <ChevronDownIcon {...props} />,
      search: <SearchIcon {...props} />,
      pressure: <PressureIcon {...props} />,
      replay_in_circle: <ReplayInCircleIcon {...props} />,

      water_drop: <WaterDropIcon {...props} />,
      weight: <WeightIcon {...props} />,
      question_mark_in_circle: <QuestionMarkInCircleIcon {...props} />,
      reset: <ResetIcon {...props} />,

      // no props
      loading: <LoadingIcon />,
      loading_logo: <LoadingLogoIcon />,
      login_logo: <LoginLogo />,
      gnb_logo: <GNBLogo />,
      hospital: <HospitalIcon />,
      error_page: <ErrorPageIcon />,
    };
  }, [height, inputColor, inputWidth, mouseOver]);

  return (
    <IconContainer
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      clickable={Boolean(onClick)}
      onClick={onClick}
    >
      {iconMap[icon]}
    </IconContainer>
  );
}

const IconContainer = styled.div<{ clickable: boolean }>`
  cursor: ${(props) => (props.clickable ? 'pointer' : undefined)};
  display: flex;
  align-items: center;
`;

export default memo(BIcon);
