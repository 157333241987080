import * as Style from './index.style';

interface AllowToastProps {
  title: JSX.Element;
}

function AllowToast({ title }: AllowToastProps) {
  return <Style.Container>{title}</Style.Container>;
}

export default AllowToast;
