function Caution() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.666748 8.00008C0.666748 3.94999 3.94999 0.666748 8.00008 0.666748C12.0502 0.666748 15.3334 3.94999 15.3334 8.00008C15.3334 12.0502 12.0502 15.3334 8.00008 15.3334C3.94999 15.3334 0.666748 12.0502 0.666748 8.00008ZM8.83341 5.00008C8.83341 5.46032 8.46032 5.83341 8.00008 5.83341C7.53984 5.83341 7.16675 5.46032 7.16675 5.00008C7.16675 4.53984 7.53984 4.16675 8.00008 4.16675C8.46032 4.16675 8.83341 4.53984 8.83341 5.00008ZM8.00008 11.8334C8.36827 11.8334 8.66675 11.5349 8.66675 11.1667L8.66675 7.16675C8.66675 6.79856 8.36827 6.50008 8.00008 6.50008C7.63189 6.50008 7.33341 6.79856 7.33341 7.16675L7.33341 11.1667C7.33341 11.5349 7.63189 11.8334 8.00008 11.8334Z"
        fill="#FFCC00"
      />
    </svg>
  );
}

export default Caution;
