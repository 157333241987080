import { COLOR } from '@components/atoms';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 38px;
  padding: 10px 16px;
  border-radius: 4px;
  border: 1px solid ${COLOR['fill/medium']};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const IconArea = styled.div`
  cursor: pointer;
`;

export const TextInputArea = styled.div`
  font-size: 14px;
`;
