import { BColor } from '@components/meraki-ui/BColor';
import { IconDetailProps } from '@components/meraki-ui/BIcon/svg.type';

export default function ResetIcon({ width, height, color }: IconDetailProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6508 9.25H15.6001C15.7591 9.25 15.8459 9.43541 15.7442 9.55753L14.2695 11.3272C14.1945 11.4171 14.0564 11.4171 13.9814 11.3272L12.5067 9.55753C12.4049 9.43541 12.4918 9.25 12.6508 9.25Z"
        fill={BColor[color]}
      />
      <path
        d="M4.40075 10.75H7.35011C7.50908 10.75 7.59592 10.5646 7.49415 10.4425L6.01947 8.67285C5.94451 8.5829 5.80635 8.5829 5.73139 8.67285L4.25671 10.4425C4.15494 10.5646 4.24178 10.75 4.40075 10.75Z"
        fill={BColor[color]}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0004 6.25C8.83606 6.25 7.79585 6.78022 7.10744 7.61379C6.97556 7.77348 6.7392 7.79602 6.57951 7.66414C6.41982 7.53226 6.39728 7.2959 6.52916 7.13621C7.35394 6.13752 8.60289 5.5 10.0004 5.5C12.2067 5.5 14.0414 7.08738 14.4262 9.18225C14.4304 9.20478 14.4344 9.22736 14.4382 9.25H13.6755C13.3282 7.53834 11.8144 6.25 10.0004 6.25ZM6.32537 10.75C6.67263 12.4617 8.18646 13.75 10.0004 13.75C11.1648 13.75 12.205 13.2198 12.8934 12.3862C13.0253 12.2265 13.2617 12.204 13.4213 12.3359C13.581 12.4677 13.6036 12.7041 13.4717 12.8638C12.6469 13.8625 11.398 14.5 10.0004 14.5C7.79415 14.5 5.95941 12.9126 5.57462 10.8177C5.57048 10.7952 5.56651 10.7726 5.56271 10.75H6.32537Z"
        fill={BColor[color]}
      />
    </svg>
  );
}
