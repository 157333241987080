import { COLOR } from '@components/atoms';
import styled from 'styled-components';

export const ListItem = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 56px;
  background-color: ${COLOR['fill/white']};
  cursor: pointer;
`;

export const IconButtonItem = styled.div`
  display: flex;
  padding: 5px 12px 5px 9px;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

export const ListItemIcon = styled.img`
  width: 16px;
  height: 16px;
`;

export const ButtonItemArea = styled.div`
  display: flex;
  position: absolute;
  top: 8px;
  right: 8px;
`;

export const ButtonItem = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${COLOR['fill/dark']};
  width: 40px;
  height: 40px;
  &:hover {
    background-color: ${COLOR['fill/black']};
  }
`;

export const AccordionTextArea = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AccordionToggleButtonArea = styled.div<{
  isOpenAccordion: boolean;
}>`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
  transition: transform 0.3s ease;
  transform: rotate(${({ isOpenAccordion }) => (isOpenAccordion ? 180 : 0)}deg);
`;

export const ManagementMedicineExtraInfoContainer = styled.div<{
  isOpenAccordion: boolean;
  accordionHeight: string;
}>`
  overflow-y: scroll;
  width: 100%;
  height: ${({ isOpenAccordion, accordionHeight }) =>
    isOpenAccordion ? accordionHeight : '0'};
  transition: height 330ms ease-in-out;
  background-color: ${COLOR['fill/light']};
`;

export const ManagementMedicineExtraInfoArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 20px 52px 30px 52px;
  border-bottom: 1px solid ${COLOR['border/divider']};
`;

export const ManagementMedicineExtraInfoTitleArea = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

export const ManagementMedicineExtraInfoTitle = styled.div`
  width: 168px;
  padding: 0 16px;
`;

export const ManagementMedicineExtraInfoTextArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ManagementMedicineExtraUnitText = styled.div`
  width: 48px;
`;

export const ManagementMedicineExtraInfoRowTextArea = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 168px;
  padding: 0 16px;
`;

export const ManagementMedicineExtraInfoResultTitleArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  /* width: 388px; */
  padding: 0 16px;
`;

export const ManagementMedicineExtraInfoResultTextArea = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  /* width: 388px; */
`;

export const ManagementMedicineExtraInfoDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${COLOR['border/outline']};
`;

export const ManagementMedicineExtraInfoContentArea = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;
