import useSignupModalHook from './hooks';
import SignupModalTemplate from './templates';

function SignupModal() {
  const { isVisible, closeModal } = useSignupModalHook();

  return <SignupModalTemplate isVisible={isVisible} closeModal={closeModal} />;
}

export default SignupModal;
