import { BColor } from '@components/meraki-ui/BColor';
import { IconDetailProps } from '@components/meraki-ui/BIcon/svg.type';

export default function SheetIcon({ width, height, color }: IconDetailProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.93629 3.5H4.92042C3.79832 3.5 2.88867 4.39543 2.88867 5.5V16C2.88867 17.1046 3.79832 18 4.92042 18H15.0791C16.2013 18 17.1109 17.1046 17.1109 16V5.5C17.1109 4.39543 16.2013 3.5 15.0791 3.5H14.0633V4.5H15.0791C15.6402 4.5 16.095 4.94772 16.095 5.5V16C16.095 16.5523 15.6402 17 15.0791 17H4.92042C4.35937 17 3.90454 16.5523 3.90454 16V5.5C3.90454 4.94772 4.35937 4.5 4.92042 4.5H5.93629V3.5Z"
        fill={BColor[color]}
      />
      <path
        d="M11.5236 3C11.8041 3 12.0315 3.22386 12.0315 3.5V4.5C12.0315 4.77614 11.8041 5 11.5236 5H8.47597C8.19545 5 7.96804 4.77614 7.96804 4.5V3.5C7.96804 3.22386 8.19545 3 8.47597 3H11.5236ZM8.47597 2C7.6344 2 6.95216 2.67157 6.95216 3.5V4.5C6.95216 5.32843 7.6344 6 8.47597 6H11.5236C12.3652 6 13.0474 5.32843 13.0474 4.5V3.5C13.0474 2.67157 12.3652 2 11.5236 2H8.47597Z"
        fill={BColor[color]}
      />
    </svg>
  );
}
