import useGetReadyModalHook from './hooks';
import GetReadyModalTemplate from './templates';

function GetReadyModal() {
  const { isVisible, title, closeModal } = useGetReadyModalHook();

  return (
    <GetReadyModalTemplate
      isVisible={isVisible}
      title={title}
      closeModal={closeModal}
    />
  );
}

export default GetReadyModal;
