import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '@stores';
import { SymptomImageModalType } from './index.d';

const initialState: SymptomImageModalType = {
  isVisible: false,
  item: undefined,
  index: 0,
};

export const symptomImageModal = createSlice({
  name: 'modal/symptomImageModal',
  initialState,
  reducers: {
    openModal_symptomImageModal: (state, action) => {
      state.isVisible = true;
      state.item = action.payload.item;
      state.index = action.payload.index;
    },
    closeModal_symptomImageModal: (state) => {
      state.isVisible = false;
    },
    changeSymptomImageModalIndex: (state, action) => {
      if (action.payload >= 0 && action.payload <= 3) {
        state.index = action.payload;
      }
    },
  },
});

export const {
  openModal_symptomImageModal,
  closeModal_symptomImageModal,
  changeSymptomImageModalIndex,
} = symptomImageModal.actions;

export const selectSymptomImageModalVisible = (state: RootState) =>
  state.symptomImageModal.isVisible;
export const selectSymptomImageModalItem = (state: RootState) =>
  state.symptomImageModal.item;
export const selectSymptomImageModalIndex = (state: RootState) =>
  state.symptomImageModal.index;

export default symptomImageModal.reducer;
