import useSymptomImageModalHook from './hooks';
import SymptomImageModalTemplate from './templates';

function SymptomImageModal() {
  const { isVisible, closeModal, imageIndex, minusImageIndex, plusImageIndex } =
    useSymptomImageModalHook();

  return (
    <SymptomImageModalTemplate
      isVisible={isVisible}
      closeModal={closeModal}
      imageIndex={imageIndex}
      minusImageIndex={minusImageIndex}
      plusImageIndex={plusImageIndex}
    />
  );
}

export default SymptomImageModal;
