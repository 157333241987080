import { COLOR } from '@components/atoms';
import styled from 'styled-components';

export const PaginationArea = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
`;

export const PaginationContainer = styled.div`
  display: flex;
  margin-top: 20px;
  border-radius: 4px;
  border: 1px solid ${COLOR['disabled/foreground']};
`;

export const PaginationItem = styled.div<{
  isClicked: boolean;
  isFirst: boolean;
  isLast: boolean;
}>`
  cursor: pointer;
  padding: 10px 12px;
  background-color: ${({ isClicked }) =>
    isClicked ? COLOR['fill/accent'] : COLOR['fill/white']};
  border-right: ${({ isLast }) =>
    isLast ? 'none' : `1px solid ${COLOR['disabled/foreground']}`};
  border-radius: ${({ isFirst, isLast }) => {
    if (isFirst) return '4px 0px 0px 4px';
    if (isLast) return '0px 4px 4px 0px';
    return 'none';
  }};
`;
