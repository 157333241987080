import { FocusEvent, forwardRef, MouseEvent, ReactNode, useMemo } from 'react';
import styled from 'styled-components';
import { COLOR } from '../color';

interface ButtonProps {
  visible?: boolean;
  title: ReactNode;
  onClick: (
    e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
  ) => void | Promise<void>;
  width?: string;
  height?: string;
  padding?: string;
  backgroundColor: string;
  textColor?: string;
  borderColor?: string;
  disableColor?: string;
  borderRadius?: string;
  onFocus?: (e: FocusEvent<HTMLButtonElement, Element>) => void;
  onBlur?: (e: FocusEvent<HTMLButtonElement, Element>) => void;
  disabled?: boolean;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      visible = true,
      backgroundColor,
      borderColor,
      width,
      height,
      padding,
      title,
      onClick,
      disableColor,
      textColor,
      borderRadius,
      onFocus,
      onBlur,
      disabled,
    },
    ref,
  ) => {
    const buttonColor = useMemo(() => {
      if (visible) {
        return backgroundColor;
      }

      return disableColor;
    }, [backgroundColor, disableColor, visible]);

    return (
      <ButtonContainer
        disabled={disabled}
        ref={ref}
        width={width}
        height={height}
        padding={padding}
        backgroundColor={buttonColor}
        borderColor={borderColor}
        textColor={textColor}
        visible={visible}
        onClick={visible ? onClick : undefined}
        borderRadius={borderRadius}
        onFocus={onFocus}
        onBlur={onBlur}
      >
        {title}
      </ButtonContainer>
    );
  },
);

const ButtonContainer = styled.button<{
  width?: string;
  height?: string;
  padding?: string;
  backgroundColor?: string;
  textColor?: string;
  borderColor?: string;
  visible: boolean;
  borderRadius?: string;
  disabled?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => (props.width ? `${props.width}` : `fit-content`)};
  padding: ${(props) => (props.padding ? `${props.padding}` : '0px')};
  border: ${(props) =>
    props.borderColor ? `1px solid ${props.borderColor}` : '0px'};
  height: ${(props) => (props.height ? `${props.height}` : 'auto')};
  background-color: ${(props) => props.backgroundColor ?? COLOR['fill/light']};
  color: ${(props) => props.textColor ?? COLOR['fill/black']};
  cursor: ${(props) =>
    props.disabled ? 'default' : props.visible ? 'pointer' : 'default'};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '4px'};
  &:hover {
    background-color: ${({ backgroundColor }) =>
      backgroundColor === COLOR['fill/accent']
        ? COLOR['hover/accent']
        : backgroundColor === COLOR['fill/dark']
        ? COLOR['hover/dark']
        : backgroundColor === COLOR['label/lightBlue']
        ? COLOR['hover/lightBlue']
        : backgroundColor === COLOR['fill/white']
        ? COLOR['hover/white']
        : backgroundColor === COLOR['state/distructive']
        ? COLOR['hover/distructive']
        : backgroundColor};
  }
  &:active {
    background-color: ${({ backgroundColor }) =>
      backgroundColor === COLOR['fill/accent']
        ? COLOR['pressed/accent']
        : backgroundColor === COLOR['fill/dark']
        ? COLOR['pressed/dark']
        : backgroundColor === COLOR['label/lightBlue']
        ? COLOR['pressed/lightBlue']
        : backgroundColor === COLOR['fill/white']
        ? COLOR['pressed/white']
        : backgroundColor === COLOR['state/distructive']
        ? COLOR['pressed/distructive']
        : backgroundColor};
  }
`;

export default Button;
