import { Font } from '@components/atoms';
import SvgIcon from '@components/atoms/images';
import {
  dietShotSearchList,
  dutasterideSearchList,
  eatingAcneMedicineSearchList,
  finasterideSearchList,
  linimentAcneMedicineSearchList,
  medicineCategoryList,
  MedicineCategoryType,
  searchMedicineList,
} from '@components/organisms/managementMedicineTable/config/domain';
import { ManagedMedicineItemType } from '@services/myDoctor/types';
import { useAppSelector } from '@stores/hooks';
import { selectMedicineManagementList } from '@stores/telepharmacyStore/telepharmacyList';
import { hangulIncludes } from '@toss/hangul';
import { useEffect, useRef, useState } from 'react';
import * as Style from './index.style';

const getFilteredMedicineListInCategory = (
  medicineList: ManagedMedicineItemType[],
  searchValue: string,
) => {
  return medicineList.filter((medicine) => {
    if (
      hangulIncludes(
        `(${medicine.master_code}) ${medicine.korean_product_name}`,
        searchValue,
      )
    ) {
      return medicine;
    }

    return null;
  });
};

interface MedicineSearchProps {
  selectedMedicineCategory: MedicineCategoryType;
  medicineSearchInputValue: string;
  selectedMedicine: ManagedMedicineItemType | null;
  handleChangeMedicineSearchInput: (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => void;
  handleSelectMedicine: (medicine: ManagedMedicineItemType) => void;
  handleClickSearchMedicineReset: () => void;
}

function MedicineSearch({
  selectedMedicineCategory,
  medicineSearchInputValue,
  selectedMedicine,
  handleChangeMedicineSearchInput,
  handleSelectMedicine,
  handleClickSearchMedicineReset,
}: MedicineSearchProps) {
  const managementMedicineList = useAppSelector(selectMedicineManagementList);

  const [filteredSearchMedicineList, setFilteredSearchMedicineList] =
    useState(searchMedicineList);
  const [isMedicineSearchInputFocus, setIsMedicineSearchInputFocus] =
    useState(false);

  const medicineSearchInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (selectedMedicineCategory.label === medicineCategoryList[0].label) {
      setFilteredSearchMedicineList(finasterideSearchList);
    } else if (
      selectedMedicineCategory.label === medicineCategoryList[1].label
    ) {
      setFilteredSearchMedicineList(dutasterideSearchList);
    } else if (
      selectedMedicineCategory.label === medicineCategoryList[2].label
    ) {
      setFilteredSearchMedicineList(dietShotSearchList);
    } else if (
      selectedMedicineCategory.label === medicineCategoryList[3].label
    ) {
      setFilteredSearchMedicineList(eatingAcneMedicineSearchList);
    } else if (
      selectedMedicineCategory.label === medicineCategoryList[4].label
    ) {
      setFilteredSearchMedicineList(linimentAcneMedicineSearchList);
    }
  }, [selectedMedicineCategory]);

  useEffect(() => {
    if (selectedMedicineCategory.label === medicineCategoryList[0].label) {
      setFilteredSearchMedicineList(
        getFilteredMedicineListInCategory(
          finasterideSearchList,
          medicineSearchInputValue,
        ),
      );
    } else if (
      selectedMedicineCategory.label === medicineCategoryList[1].label
    ) {
      setFilteredSearchMedicineList(
        getFilteredMedicineListInCategory(
          dutasterideSearchList,
          medicineSearchInputValue,
        ),
      );
    } else if (
      selectedMedicineCategory.label === medicineCategoryList[2].label
    ) {
      setFilteredSearchMedicineList(
        getFilteredMedicineListInCategory(
          dietShotSearchList,
          medicineSearchInputValue,
        ),
      );
    } else if (
      selectedMedicineCategory.label === medicineCategoryList[3].label
    ) {
      setFilteredSearchMedicineList(
        getFilteredMedicineListInCategory(
          eatingAcneMedicineSearchList,
          medicineSearchInputValue,
        ),
      );
    } else if (
      selectedMedicineCategory.label === medicineCategoryList[4].label
    ) {
      setFilteredSearchMedicineList(
        getFilteredMedicineListInCategory(
          linimentAcneMedicineSearchList,
          medicineSearchInputValue,
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [medicineSearchInputValue]);

  useEffect(() => {
    if (isMedicineSearchInputFocus) {
      medicineSearchInputRef.current?.focus();
    }
  }, [isMedicineSearchInputFocus]);

  return (
    <Style.ModalBodyContentArea>
      <Style.SubTitleLeftArea>
        <Font fontType="body1_medium" color="fill/black">
          약품명
        </Font>
        <Style.RequiredUI>
          <Font fontType="body3_medium" color="blue500">
            필수
          </Font>
        </Style.RequiredUI>
      </Style.SubTitleLeftArea>
      <Style.SubTitleArea />
      <Style.MedicineSearchInputArea
        isFocus={isMedicineSearchInputFocus}
        onClick={() => {
          if (selectedMedicine) {
            setIsMedicineSearchInputFocus(false);
            return;
          }
          setIsMedicineSearchInputFocus(true);
        }}
      >
        <SvgIcon
          icon="searchIcon"
          width={18}
          color={
            isMedicineSearchInputFocus || selectedMedicine
              ? 'fill/black'
              : 'fill/medium'
          }
        />
        {selectedMedicine ? (
          <Style.SelectedMedicineArea onClick={handleClickSearchMedicineReset}>
            <Font fontType="body2_medium" color="fill/white">
              {`(${selectedMedicine.master_code}) ${selectedMedicine.korean_product_name}`}
            </Font>
            <SvgIcon icon="xSm" width={10} color="fill/white" />
          </Style.SelectedMedicineArea>
        ) : (
          <Style.MedicineSearchInput
            placeholder="보험코드 혹은 약품명을 입력해주세요"
            value={medicineSearchInputValue}
            ref={medicineSearchInputRef}
            onChange={handleChangeMedicineSearchInput}
            onBlur={() => setIsMedicineSearchInputFocus(false)}
          />
        )}
      </Style.MedicineSearchInputArea>
      {isMedicineSearchInputFocus && (
        <Style.MedicineListDropDownArea>
          {filteredSearchMedicineList.map((medicine) => {
            const { id, master_code, korean_product_name } = medicine;

            return (
              <Style.MedicineItemArea
                key={id}
                onMouseDown={() => {
                  handleSelectMedicine(medicine);
                  setIsMedicineSearchInputFocus(false);
                }}
              >
                <Font fontType="body1" color="fill/dark">
                  {`(${master_code}) ${korean_product_name}`}
                </Font>
              </Style.MedicineItemArea>
            );
          })}
        </Style.MedicineListDropDownArea>
      )}
    </Style.ModalBodyContentArea>
  );
}

export default MedicineSearch;
