import { BColor } from '@components/meraki-ui/BColor';
import { IconDetailProps } from '@components/meraki-ui/BIcon/svg.type';

export default function ClockIcon({ width, height, color }: IconDetailProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5162 3.0179C10.3453 3.00529 10.1735 2.99893 10.0012 2.99893V1.99878C10.1981 1.99878 10.3944 2.00604 10.5898 2.02045L10.5162 3.0179ZM12.5209 3.46805C12.2001 3.34431 11.8706 3.24437 11.5351 3.16904L11.7543 2.19319C12.1377 2.27928 12.5142 2.3935 12.8808 2.53491L12.5209 3.46805ZM13.8908 4.17882C13.7475 4.08311 13.6012 3.99295 13.452 3.90845L13.945 3.03824C14.1155 3.13481 14.2827 3.23785 14.4464 3.34723C14.6101 3.4566 14.7693 3.57171 14.9238 3.69226L14.3085 4.48073C14.1733 4.37525 14.034 4.27453 13.8908 4.17882ZM15.7252 5.96872C15.5272 5.68762 15.3088 5.42148 15.0717 5.17247L15.7961 4.48283C16.067 4.76741 16.3166 5.07157 16.5429 5.39282L15.7252 5.96872ZM16.4693 7.3208C16.4034 7.16164 16.3318 7.00536 16.2547 6.85222L17.1481 6.40255C17.2362 6.57757 17.318 6.75617 17.3934 6.93806C17.4687 7.11994 17.5371 7.3041 17.5986 7.49015L16.6489 7.80388C16.5951 7.6411 16.5353 7.47996 16.4693 7.3208ZM17.0002 9.82817C16.9917 9.48446 16.958 9.14182 16.8992 8.80307L17.8846 8.63209C17.9518 9.01923 17.9904 9.41081 18 9.80362L17.0002 9.82817ZM16.8677 11.3658C16.9013 11.1969 16.9286 11.0271 16.9496 10.857L17.9422 10.9794C17.9183 11.1739 17.8871 11.3679 17.8487 11.5609C17.8103 11.754 17.7648 11.9452 17.7126 12.134L16.7487 11.8673C16.7944 11.702 16.8341 11.5348 16.8677 11.3658ZM15.9161 13.7455C16.1 13.4551 16.2623 13.1514 16.4016 12.8371L17.316 13.2424C17.1567 13.6016 16.9713 13.9486 16.7611 14.2806L15.9161 13.7455ZM14.9517 14.9505C15.0735 14.8287 15.1905 14.7027 15.3025 14.5729L16.0598 15.2262C15.9318 15.3745 15.7981 15.5185 15.6589 15.6577L14.9517 14.9505Z"
        fill={BColor[color]}
      />
      <path
        d="M10.0012 2.99893C8.84988 2.99893 7.71631 3.28287 6.70093 3.82561C5.68555 4.36834 4.81969 5.15311 4.18004 6.11041C3.5404 7.0677 3.14671 8.16797 3.03386 9.31376C2.92101 10.4595 3.09248 11.6155 3.53308 12.6792C3.97367 13.7429 4.66979 14.6815 5.55978 15.4119C6.44977 16.1423 7.50616 16.6419 8.63537 16.8665C9.76457 17.0911 10.9317 17.0338 12.0335 16.6996C13.1353 16.3654 14.1376 15.7646 14.9517 14.9505L15.6589 15.6577C14.7285 16.5881 13.583 17.2747 12.3238 17.6567C11.0647 18.0386 9.73077 18.1041 8.44025 17.8474C7.14972 17.5907 5.94243 17.0197 4.9253 16.185C3.90816 15.3503 3.11259 14.2776 2.60906 13.0619C2.10552 11.8463 1.90956 10.5252 2.03853 9.21573C2.1675 7.90626 2.61743 6.6488 3.34845 5.55475C4.07947 4.4607 5.06903 3.56382 6.22946 2.94355C7.3899 2.32328 8.6854 1.99878 10.0012 1.99878V2.99893Z"
        fill={BColor[color]}
      />
      <path
        d="M9.50113 4.99923C9.77731 4.99923 10.0012 5.22312 10.0012 5.49931V10.7099L13.2498 12.5662C13.4896 12.7033 13.5729 13.0087 13.4358 13.2485C13.2988 13.4883 12.9933 13.5716 12.7535 13.4346L9.25302 11.4343C9.09721 11.3453 9.00105 11.1796 9.00105 11.0001V5.49931C9.00105 5.22312 9.22495 4.99923 9.50113 4.99923Z"
        fill={BColor[color]}
      />
    </svg>
  );
}
