import { lightImage, ReactBootstrapIcons } from '@assets';
import {
  Blank,
  Button,
  CheckButton,
  COLOR,
  Font,
  TextArea,
  TextInput,
} from '@components/atoms';
import {
  ModalCommonStructure as Modal,
  PageLoader,
} from '@components/molecules';
import { formatters } from '@functions';
import ReactTooltip from 'react-tooltip';
import { ModifyMedicineGuideModalTemplateType } from './index.d';
import * as Style from './index.style';
import { NudgeComponent } from './sections';

const modalCustomStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0',
    width: '580px',
    backgroundColor: COLOR['fill/white'],
    maxHeight: '80vh',
    borderRadius: '16px',
  },
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 100,
  },
};

function ModifyMedicineGuideModalTemplate({
  isPageLoading,
  isVisible,
  item,
  closeModal,
  modifyMedicineGuide,
  internal,
  setInternal,
  external,
  setExternal,
  injection,
  setInjection,
  medicine_day_num,
  onChangeMedicine_day_num,
  setMedicine_day_num,
  isMedicine_day_num_not_decided_yet,
  setIsMedicine_day_num_not_decided_yet,
  morning,
  setMorning,
  lunch,
  setLunch,
  dinner,
  setDinner,
  beforeSleep,
  setBeforeSleep,
  medicine_period_num,
  setMedicine_period_num,
  whenever_patient_want,
  setWhenever_patient_want,
  is_supplement_info_exposed,
  setIs_supplement_info_exposed,
  before_meal,
  setBefore_meal,
  after_meal,
  setAfter_meal,
  supplement_info,
  setSupplement_info,
  onChangeSupplement_info,
  buttonVisible,
}: ModifyMedicineGuideModalTemplateType) {
  const { phoneFormatter } = formatters;
  return (
    <>
      {isPageLoading && <PageLoader />}
      <Modal
        isOpen={isVisible}
        modalStyle={modalCustomStyle}
        onRequestClose={() => closeModal()}
        modalHeader={
          <Style.TitleArea>
            <Font fontType="h1" color="fill/dark">
              복약 안내 수정
            </Font>
          </Style.TitleArea>
        }
        modalBody={
          <>
            <Blank appoint="Vertical" size={20} />
            <Style.DescriptionArea>
              <Style.DescriptionTitleArea>
                <Font fontType="h1" color="fill/dark">
                  {item.tm?.patient?.name} 환자 (
                  {phoneFormatter.lastFourPhoneNumberFormatter(item.tm?.phone)})
                  복약 안내
                </Font>
              </Style.DescriptionTitleArea>
              <Style.DescriptionDetailArea>
                <Style.DescriptionDetailLeftArea>
                  <NudgeComponent required />
                </Style.DescriptionDetailLeftArea>
                <Style.DescriptionDetailMiddleArea>
                  <Font fontType="h4" color="fill/dark">
                    약제 종류
                  </Font>
                </Style.DescriptionDetailMiddleArea>
                <Style.DescriptionDetailRightArea>
                  <Style.CheckBoxArea>
                    <CheckButton
                      onClick={() => setInternal(!internal)}
                      isSelected={internal}
                      title="내용제"
                    />
                  </Style.CheckBoxArea>
                  <Style.CheckBoxArea>
                    <CheckButton
                      onClick={() => setExternal(!external)}
                      isSelected={external}
                      title="외용제"
                    />
                  </Style.CheckBoxArea>
                  <Style.CheckBoxArea>
                    <CheckButton
                      onClick={() => setInjection(!injection)}
                      isSelected={injection}
                      title="주사제"
                    />
                  </Style.CheckBoxArea>
                </Style.DescriptionDetailRightArea>
              </Style.DescriptionDetailArea>
              <Style.DescriptionDetailArea>
                <Style.DescriptionDetailLeftArea>
                  <NudgeComponent required />
                </Style.DescriptionDetailLeftArea>
                <Style.DescriptionDetailMiddleArea>
                  <Font fontType="h4" color="fill/dark">
                    투약 일수
                  </Font>
                </Style.DescriptionDetailMiddleArea>
                <Style.DescriptionDetailRightArea>
                  <Style.InputArea>
                    <Font fontType="body1" color="fill/dark">
                      총
                    </Font>
                    <Blank appoint="Horizontal" size={8} />
                    <TextInput
                      value={medicine_day_num}
                      type="text"
                      onChange={onChangeMedicine_day_num}
                      fontSize={16}
                      placeholder="숫자만 입력해주세요."
                      width={160}
                      height={38}
                      disabled={
                        (external && !internal && !injection) ||
                        isMedicine_day_num_not_decided_yet
                      }
                    />
                    <Blank appoint="Horizontal" size={8} />
                    <Font fontType="body1" color="fill/dark">
                      일
                    </Font>
                  </Style.InputArea>
                  <Style.CheckBoxArea>
                    <CheckButton
                      onClick={() =>
                        setIsMedicine_day_num_not_decided_yet(
                          !isMedicine_day_num_not_decided_yet,
                        )
                      }
                      isSelected={isMedicine_day_num_not_decided_yet}
                      title="투약 일수 미정입니다."
                    />
                  </Style.CheckBoxArea>
                </Style.DescriptionDetailRightArea>
              </Style.DescriptionDetailArea>
              <Style.DescriptionDetailArea>
                <Style.DescriptionDetailLeftArea>
                  <NudgeComponent required />
                </Style.DescriptionDetailLeftArea>
                <Style.DescriptionDetailMiddleArea>
                  <Style.DescriptionDetailMiddleTextArea>
                    <Font fontType="h4" color="fill/dark">
                      투약 횟수
                    </Font>
                  </Style.DescriptionDetailMiddleTextArea>
                  <Style.DescriptionDetailMiddleTextArea>
                    <Font fontType="body2" color="fill/dark">
                      (1일 {medicine_period_num}회)
                    </Font>
                  </Style.DescriptionDetailMiddleTextArea>
                </Style.DescriptionDetailMiddleArea>
                <Style.DescriptionDetailRightArea>
                  <Style.CheckBoxArea>
                    <CheckButton
                      onClick={() => setMorning(!morning)}
                      isSelected={morning}
                      title="아침"
                    />
                  </Style.CheckBoxArea>
                  <Style.CheckBoxArea>
                    <CheckButton
                      onClick={() => setLunch(!lunch)}
                      isSelected={lunch}
                      title="점심"
                    />
                  </Style.CheckBoxArea>
                  <Style.CheckBoxArea>
                    <CheckButton
                      onClick={() => setDinner(!dinner)}
                      isSelected={dinner}
                      title="저녁"
                    />
                  </Style.CheckBoxArea>
                  <Style.CheckBoxArea>
                    <CheckButton
                      onClick={() => setBeforeSleep(!beforeSleep)}
                      isSelected={beforeSleep}
                      title="취침 전"
                    />
                  </Style.CheckBoxArea>
                  <Style.CheckBoxArea>
                    <CheckButton
                      onClick={() =>
                        setWhenever_patient_want(!whenever_patient_want)
                      }
                      isSelected={whenever_patient_want}
                      title="필요 시"
                    />
                  </Style.CheckBoxArea>
                  <Style.CheckBoxArea>
                    <CheckButton
                      onClick={() =>
                        setIs_supplement_info_exposed(
                          !is_supplement_info_exposed,
                        )
                      }
                      isSelected={is_supplement_info_exposed}
                      title="추가 안내 참고"
                    />
                    <Style.CheckBoxIconArea>
                      <ReactBootstrapIcons.QuestionCircle
                        size={14}
                        color={COLOR['fill/dark']}
                        data-for="showToolTipGuide_2"
                        data-tip
                      />
                      <ReactTooltip
                        id="showToolTipGuide_2"
                        place="left"
                        isCapture
                      >
                        <Style.TooltipMessageArea>
                          <Blank appoint="Vertical" size={4} />
                          <Style.TooltipMessage>
                            <Style.TooltipImage src={lightImage} alt="light" />
                            추가 안내 참고
                          </Style.TooltipMessage>
                          <Blank appoint="Vertical" size={12} />
                          <Style.TooltipMessage>
                            처방된 약제마다 투약 횟수가 상이한 경우,
                          </Style.TooltipMessage>
                          <Blank appoint="Vertical" size={4} />
                          <Style.TooltipMessage>
                            이 항목을 체크하고 추가 안내에 상세한 복약
                          </Style.TooltipMessage>
                          <Blank appoint="Vertical" size={4} />
                          <Style.TooltipMessage>
                            안내를 기입해주세요.
                          </Style.TooltipMessage>
                          <Blank appoint="Vertical" size={4} />
                        </Style.TooltipMessageArea>
                      </ReactTooltip>
                    </Style.CheckBoxIconArea>
                  </Style.CheckBoxArea>
                </Style.DescriptionDetailRightArea>
              </Style.DescriptionDetailArea>
              <Style.DescriptionDetailArea>
                <Style.DescriptionDetailLeftArea>
                  <NudgeComponent required={false} />
                </Style.DescriptionDetailLeftArea>
                <Style.DescriptionDetailMiddleArea>
                  <Font fontType="h4" color="fill/dark">
                    투약 시점
                  </Font>
                </Style.DescriptionDetailMiddleArea>
                <Style.DescriptionDetailRightArea>
                  <Style.CheckBoxArea>
                    <CheckButton
                      onClick={() => setAfter_meal(!after_meal)}
                      isSelected={after_meal}
                      title="식사 후"
                    />
                  </Style.CheckBoxArea>
                  <Style.CheckBoxArea>
                    <CheckButton
                      onClick={() => setBefore_meal(!before_meal)}
                      isSelected={before_meal}
                      title="식사 전"
                    />
                  </Style.CheckBoxArea>
                </Style.DescriptionDetailRightArea>
              </Style.DescriptionDetailArea>
              <Style.DescriptionDetailArea>
                <Style.DescriptionDetailLeftArea>
                  <NudgeComponent required={false} />
                </Style.DescriptionDetailLeftArea>
                <Style.DescriptionDetailMiddleArea>
                  <Font fontType="h4" color="fill/dark">
                    추가 안내
                  </Font>
                </Style.DescriptionDetailMiddleArea>
                <Style.DescriptionDetailRightArea>
                  <Style.MultiLineInputArea>
                    <TextArea
                      value={supplement_info}
                      type="text"
                      onChange={onChangeSupplement_info}
                      fontSize={16}
                      placeholder="환자에게 추가로 안내할 주의사항을 적어주세요."
                      width={307}
                      height={120}
                    />
                    <Blank appoint="Vertical" size={8} />
                    <Font fontType="body2" color="fill/dark">
                      {supplement_info.length}/500
                    </Font>
                  </Style.MultiLineInputArea>
                </Style.DescriptionDetailRightArea>
              </Style.DescriptionDetailArea>
            </Style.DescriptionArea>
          </>
        }
        modalFooter={
          <Button
            onClick={() => modifyMedicineGuide()}
            disableColor={COLOR['fill/medium']}
            backgroundColor={COLOR['fill/accent']}
            visible={buttonVisible}
            width="136px"
            padding="16px 12px"
            title={
              <Style.ButtonContainer>
                <ReactBootstrapIcons.Check2 color={COLOR['fill/white']} />
                <Blank appoint="Horizontal" size={4} />
                <Font fontType="body2" color="fill/white">
                  수정 완료하기
                </Font>
              </Style.ButtonContainer>
            }
          />
        }
      />
    </>
  );
}

export default ModifyMedicineGuideModalTemplate;
