import { COLOR } from '@components/atoms';
import styled from 'styled-components';

export const ListContainer = styled.div``;

export const ListDefaultItem = styled.div`
  display: flex;
  flex-direction: row;
  height: 56px;
  width: 100%;
`;

export const ListHeaderItem = styled(ListDefaultItem)`
  background-color: ${COLOR['fill/dark']};

  white-space: break-spaces;
`;

export const EmptyContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 41px;
  margin: 4px 0;
  padding: 12px;
  background-color: ${COLOR['fill/light']};
  border-radius: 8px;
`;

export const LoaderArea = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 20px 0;
`;

export const PrescriptionErrorCase = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  background-color: ${COLOR['fill/white']};
  border-bottom: 1px solid ${COLOR['border/outline']};
`;
