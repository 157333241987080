import { serverURL } from '@constants/index';
import { mobileTokenStorage } from '@mobile/utils';
import { detect } from 'detect-browser';
import { ApiError, AuthError } from './customError';
import Http, { HttpResponse } from './http';

type ResponseMobileAuth400 = {
  InvalidToken: string[];
};

type ResponseMobileAuth400Null = {
  token: string[];
};

const isMobileAuth400ErrorData = (
  data: object,
): data is ResponseMobileAuth400 => {
  return 'InvalidToken' in data;
};

const isMobileAuth400NullErrorData = (
  data: object,
): data is ResponseMobileAuth400Null => {
  return 'token' in data;
};

const BASE_URL = serverURL.ServerConstant();

const browser = detect();

const http = new Http(BASE_URL, {
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'X-OS-Type': browser?.name || '',
    'X-App-Type': `pharmacist_admin_${process.env.REACT_APP_SERVER}`,
    'X-Device-Name': browser?.os ? `${browser?.os} pc` : 'pc',
    'X-App-Version': '1.0.0',
    'X-Ip-Address': '',
  },
});

http.registerInterceptor({
  onRequest: (config) => {
    if (!mobileTokenStorage.mobileAccessToken) return config;

    config.headers = {
      ...config.headers,
      'X-Pharmacist-Mobile-Token': mobileTokenStorage.mobileAccessToken,
    };

    return config;
  },

  onResponse: async <T extends object>(response: HttpResponse<T>) => {
    if (response.ok) return response;

    if (
      isMobileAuth400ErrorData(response.data) ||
      isMobileAuth400NullErrorData(response.data) ||
      response.status === 401 ||
      response.status === 403
    ) {
      throw new AuthError(
        response.statusText,
        response.status,
        response.config,
      );
    }

    throw new ApiError(response.statusText, response.status, response.config);
  },
});

export default http;
