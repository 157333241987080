import { QUERY_KEY } from '@constants/react-query';
import { getTPProcessCount } from '@mobile/api';
import { useQuery } from '@tanstack/react-query';

const useTPProcessCount = () => {
  return useQuery({
    queryFn: () => getTPProcessCount(),
    queryKey: [QUERY_KEY.TP_PROCESS_COUNT],
    retry: false,
  });
};

export default useTPProcessCount;
