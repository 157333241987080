import { QUERY_KEY } from '@constants/react-query';
import { useQuery } from '@tanstack/react-query';
import { isNil } from 'es-toolkit';
import { getTelepharmacyItem } from '../apis';

interface UseTpProgressItem {
  telepharmacyId: string | null;
}

export const useTpProgressItem = ({ telepharmacyId }: UseTpProgressItem) => {
  return useQuery({
    queryKey: [QUERY_KEY.TP_PROGRESS_ITEM, telepharmacyId],
    queryFn: () => {
      return getTelepharmacyItem(Number(telepharmacyId));
    },
    enabled: !isNil(telepharmacyId),
    select: (data) => data.data,
  });
};
