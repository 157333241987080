import { BoxContainer } from '@components/atoms';
import { COLOR } from '@components/atoms/color';
import styled from 'styled-components';

export const Container = styled.div<{ isOpen: boolean }>`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
`;

export const PageTitleArea = styled.div`
  margin-bottom: 20px;
`;

export const CardArea = styled(BoxContainer)`
  display: flex;
  padding: 30px;

  border-radius: 8px;
  box-shadow: 1px 1px 20px 1px rgba(196, 196, 196, 0.3);
  margin-bottom: 20px;
  flex-direction: column;
  width: 500px;
`;

export const OpenTimeCardArea = styled(CardArea)``;

export const RestDayCardArea = styled(CardArea)`
  padding: 32px 40px;
  justify-content: space-between;
`;

export const CardHeaderArea = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const CardHeaderTitleArea = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const CardHeaderButtonArea = styled.div``;

export const CardAlertArea = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5rem 0;
  gap: 1.5rem;
`;

export const NoOffDayContainer = styled.div`
  display: flex;
  background-color: ${COLOR['fill/light']};
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px 0;
`;
