import { INotice } from '@components/meraki-ui/BModal/BNoticeModal';
import { ICommon } from '@components/meraki-ui/type';
import { usePersonalInfo } from '@hooks/featureHooks/usePersonalInfo';
import useSWR from 'swr';

export const useNotice = () => {
  const { personalInfoReport } = usePersonalInfo();

  const { data } = useSWR<{ data: ICommon<INotice> }>(
    personalInfoReport?.data?.agree
      ? {
          path: 'pharmacist-api/app/notice-popup/',
        }
      : null,
  );

  return { noticeList: data?.data?.results };
};
