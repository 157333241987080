import { ReactBootstrapIcons } from '@assets';
import { Blank, Button, COLOR, Font } from '@components/atoms';
import { pathnameList } from '@constants';
import { MedicineGuidePartItemType } from './index.d';
import * as Style from './index.style';

const { COMMON_PATHNAME, PHARMACIST_ADMIN_PATHNAME } = pathnameList;

function MedicineGuidePartItem({
  pathname,
  openModifyMedicineGuide,
  item,
}: MedicineGuidePartItemType) {
  // 복약 안내 관련
  const medicineTypeArray: string[] = [];
  const medicineTimeArray: string[] = [];
  const medicineByMealArray: string[] = [];
  const medicineDayNum = item.medicine_guide?.medicine_day_num || 0;
  const medicinePeriodNum = item.medicine_guide?.medicine_period_num || 0;
  const isSupplementInfoExposedString = item.medicine_guide
    ?.is_supplement_info_exposed
    ? '추가 안내 참고'
    : '';
  const supplementInfoString = item.medicine_guide?.supplement_info || '-';
  const wheneverPatientWantString = item.medicine_guide?.whenever_patient_want
    ? '필요 시'
    : '';

  if (item.medicine_guide?.medicine_type?.includes('internal'))
    medicineTypeArray.push('내용제');
  if (item.medicine_guide?.medicine_type?.includes('external'))
    medicineTypeArray.push('외용제');
  if (item.medicine_guide?.medicine_type?.includes('injection'))
    medicineTypeArray.push('주사제');

  if (item.medicine_guide?.medicine_time?.includes('morning'))
    medicineTimeArray.push('아침');
  if (item.medicine_guide?.medicine_time?.includes('lunch'))
    medicineTimeArray.push('점심');
  if (item.medicine_guide?.medicine_time?.includes('evening'))
    medicineTimeArray.push('저녁');
  if (item.medicine_guide?.medicine_time?.includes('night'))
    medicineTimeArray.push('취침 전');

  if (item.medicine_guide?.medicine_by_meal?.includes('before_meal'))
    medicineByMealArray.push('식사 전');
  if (item.medicine_guide?.medicine_by_meal?.includes('after_meal'))
    medicineByMealArray.push('식사 후');

  const medicineTypeString =
    medicineTypeArray.length > 0 ? medicineTypeArray.join(' ㆍ ') : '-';
  const medicineTimeString = medicineTimeArray.join(' ㆍ ');
  const medicineByMealString =
    medicineByMealArray.length > 0 ? medicineByMealArray.join(' ㆍ ') : '-';

  const medicinePeriodNumArray: string[] = [];
  if (medicinePeriodNum && medicineTimeString)
    medicinePeriodNumArray.push(
      `1일 ${medicinePeriodNum}회 (${medicineTimeString})`,
    );
  if (wheneverPatientWantString)
    medicinePeriodNumArray.push(wheneverPatientWantString);
  if (isSupplementInfoExposedString)
    medicinePeriodNumArray.push(isSupplementInfoExposedString);
  const medicinePeriodNumString =
    medicinePeriodNumArray.length > 0 ? medicinePeriodNumArray.join(', ') : '-';

  // 리스트
  const medicineGuideList = [
    {
      id: 0,
      title: '약제 종류',
      text: medicineTypeString,
    },
    {
      id: 1,
      title: '투약 횟수',
      text: medicinePeriodNumString,
    },
    {
      id: 2,
      title: '투약 일수',
      text: medicineDayNum ? `총 ${medicineDayNum}일분` : '-',
    },
    {
      id: 3,
      title: '투약 시점',
      text: medicineByMealString,
    },
    {
      id: 4,
      title: '추가 안내',
      text: supplementInfoString,
    },
  ];

  return pathname === COMMON_PATHNAME.COMPLETE_TREATMENT_DETAIL ||
    pathname ===
      PHARMACIST_ADMIN_PATHNAME.CONFIRMED_TREATMENT_ONGOING_PARCEL_DETAIL ||
    pathname ===
      PHARMACIST_ADMIN_PATHNAME.CONFIRMED_TREATMENT_ONGOING_QUICK_DETAIL ? (
    <Style.RightContentItem>
      <Style.ContentHeaderArea>
        <Style.ContentHeaderTitle>
          <Font fontType="h1" color="fill/black">
            복약 안내
          </Font>
        </Style.ContentHeaderTitle>
        <Style.ContentHeaderButton>
          <Button
            onClick={() => openModifyMedicineGuide()}
            width="80px"
            height="32px"
            borderColor={COLOR['fill/black']}
            backgroundColor={COLOR['fill/white']}
            title={
              <Style.ButtonContainer>
                <ReactBootstrapIcons.PencilSquare color={COLOR['fill/black']} />
                <Blank appoint="Horizontal" size={8} />
                <Font fontType="body3" color="fill/black">
                  수정
                </Font>
              </Style.ButtonContainer>
            }
          />
        </Style.ContentHeaderButton>
      </Style.ContentHeaderArea>
      <Blank appoint="Vertical" size={12} />
      <Style.ContentBodyArea>
        {medicineGuideList.map((medicineGuideItem) => {
          return (
            <Style.ContentBodyContainer key={medicineGuideItem.id}>
              <Style.ContentBodyTitleArea>
                <Style.ContentBodyTitle>
                  <Font fontType="body1">{medicineGuideItem.title}</Font>
                </Style.ContentBodyTitle>
              </Style.ContentBodyTitleArea>
              <Style.ContentBodyTextArea>
                <Style.ContentBodyText>
                  <Font fontType="body1" color="fill/black">
                    {medicineGuideItem.text}
                  </Font>
                </Style.ContentBodyText>
              </Style.ContentBodyTextArea>
            </Style.ContentBodyContainer>
          );
        })}
      </Style.ContentBodyArea>
    </Style.RightContentItem>
  ) : null;
}

export default MedicineGuidePartItem;
