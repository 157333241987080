import { COLOR } from '@components/atoms';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background: #102759;
`;

export const LoginArea = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 12px;
`;

export const LogoArea = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #2f6eff;
  padding: 40px;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  gap: 12px;
  width: 480px;
`;

export const MainLogo = styled.img`
  width: 432px;
  height: auto;
  margin-bottom: 16px;
`;

export const SubLogo = styled.img`
  width: 220px;
  height: 32px;
`;

export const TextInputArea = styled.div`
  width: 100%;
`;

export const SignupFindIdPasswordContainer = styled.div`
  width: 100%;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const SignupArea = styled.div`
  margin-right: 12px;
`;

export const VerticalLine = styled.div`
  width: 1px;
  height: 70%;
  border: 1px solid ${COLOR['fill/dark']};
`;

export const FindIdPasswordArea = styled.div`
  margin-left: 12px;
`;

export const LoginButtonArea = styled.div`
  width: 100%;
  display: flex;
  gap: 12px;
`;
