import { getSubInjectTimeFormatter } from '@functions/formatters/timeFormatter';
import React, { useCallback, useRef } from 'react';

import DatePicker from 'react-datepicker';

import styled from 'styled-components';
import BDatePickerInput from './BDatepickerInput';

interface BDatepickerProps {
  date: Date | null;
  width?: number;
  onDateChange: (date: Date | null) => void;
  onDateInputChange: (value: string) => void;
}

function BDatepicker({
  date,
  width,
  onDateChange,
  onDateInputChange,
}: BDatepickerProps) {
  const dateRef = useRef<DatePicker>(null);
  const dateInputRef = useRef<HTMLInputElement>(null);

  const onInputClick = useCallback(() => {
    dateRef.current?.setOpen(true);
  }, []);

  return (
    <StyledDatePicker
      ref={dateRef}
      selected={date}
      onChange={onDateChange}
      onInputClick={onInputClick}
      customInput={
        <BDatePickerInput
          width={width}
          value={getSubInjectTimeFormatter(date?.toString())}
          onChange={onDateInputChange}
          onClick={onInputClick}
          ref={dateInputRef}
        />
      }
    />
  );
}

const StyledDatePicker = styled(DatePicker)`
  display: flex;
  min-width: 100%;
  z-index: 1;

  & > .react-datepicker-wrapper {
    display: flex;
  }

  &.react-datepicker__input-container {
    display: flex;
  }
`;

export default BDatepicker;
