import { IconDetailProps } from '@components/atoms/images';

function CheckboxCheckedIcon({ width, height, color }: IconDetailProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M0 2.5C0 1.11929 1.11929 0 2.5 0H17.5C18.8807 0 20 1.11929 20 2.5V17.5C20 18.8807 18.8807 20 17.5 20H2.5C1.11929 20 0 18.8807 0 17.5V2.5Z"
        fill="#2F6EFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.382 4.99782C16.7284 5.30089 16.7635 5.82736 16.4604 6.17372L8.80416 14.9237C8.64687 15.1035 8.42 15.2071 8.18114 15.2083C7.94229 15.2095 7.7144 15.1081 7.55534 14.9299L3.54492 10.4373C3.23843 10.094 3.2683 9.56719 3.61164 9.2607C3.95498 8.95421 4.48178 8.98409 4.78827 9.32743L8.17077 13.1166L15.2061 5.07622C15.5092 4.72985 16.0356 4.69476 16.382 4.99782Z"
        fill="white"
      />
    </svg>
  );
}

export default CheckboxCheckedIcon;
