import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '@stores';

interface InitialStateType {
  isVisible: boolean;
  // leftButtonCallback: () => void;
  // rightButtonCallback: () => void;
}

const initialState: InitialStateType = {
  isVisible: false,
  // leftButtonCallback: () => {},
  // rightButtonCallback: () => {},
};

export const commonModal = createSlice({
  name: 'modal/commonModal',
  initialState,
  reducers: {
    openCommonModal: (state) => {
      state.isVisible = true;
    },
    closeCommonModal: (state) => {
      state.isVisible = false;
    },

    // TODO toolkit이 아닌 zustand 같은 라이브러리로 함수를 전역으로 관리하는 것이 가능한지 서칭 필요
    setLeftButtonCallback: (state, action) => {
      // state.leftButtonCallback = action.payload;
    },
    // TODO toolkit이 아닌 zustand 같은 라이브러리로 함수를 전역으로 관리하는 것이 가능한지 서칭 필요
    setRightButtonCallback: (state, action) => {
      // state.rightButtonCallback = action.payload;
    },
  },
});

export const {
  openCommonModal,
  closeCommonModal,
  setLeftButtonCallback,
  setRightButtonCallback,
} = commonModal.actions;

export const selectCommonModalVisible = (state: RootState) =>
  state.commonModal.isVisible;

export default commonModal.reducer;
