import { IconDetailProps } from '@components/atoms/images';
import { COLOR } from '../color';

export default function ExclamationIcon({
  width,
  height,
  color,
}: IconDetailProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.0698 8.16699C16.0698 12.5853 12.4881 16.167 8.06982 16.167C3.65155 16.167 0.0698242 12.5853 0.0698242 8.16699C0.0698242 3.74871 3.65155 0.166992 8.06982 0.166992C12.4881 0.166992 16.0698 3.74871 16.0698 8.16699ZM8.06982 4.16699C7.53441 4.16699 7.11605 4.62928 7.16933 5.16203L7.52007 8.66947C7.54832 8.95191 7.78598 9.16699 8.06982 9.16699C8.35367 9.16699 8.59133 8.95191 8.61958 8.66947L8.97032 5.16203C9.0236 4.62928 8.60524 4.16699 8.06982 4.16699ZM8.07137 10.167C7.51908 10.167 7.07137 10.6147 7.07137 11.167C7.07137 11.7193 7.51908 12.167 8.07137 12.167C8.62365 12.167 9.07137 11.7193 9.07137 11.167C9.07137 10.6147 8.62365 10.167 8.07137 10.167Z"
        fill={COLOR[color]}
      />
    </svg>
  );
}
