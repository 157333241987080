import { BColor } from '@components/meraki-ui/BColor';
import { IconDetailProps } from '@components/meraki-ui/BIcon/svg.type';

export default function PauseInSquareIcon({
  width,
  height,
  color,
}: IconDetailProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.25 7C7.55964 7 7 7.55964 7 8.25V11.75C7 12.4404 7.55964 13 8.25 13C8.94036 13 9.5 12.4404 9.5 11.75V8.25C9.5 7.55964 8.94036 7 8.25 7Z"
        fill={BColor[color]}
      />
      <path
        d="M11.75 7C11.0596 7 10.5 7.55964 10.5 8.25V11.75C10.5 12.4404 11.0596 13 11.75 13C12.4404 13 13 12.4404 13 11.75V8.25C13 7.55964 12.4404 7 11.75 7Z"
        fill={BColor[color]}
      />
      <path
        d="M2 6C2 4.89543 2.89543 4 4 4H16C17.1046 4 18 4.89543 18 6V14C18 15.1046 17.1046 16 16 16H4C2.89543 16 2 15.1046 2 14V6ZM17 6C17 5.44772 16.5523 5 16 5H4C3.44772 5 3 5.44772 3 6V14C3 14.5523 3.44772 15 4 15H16C16.5523 15 17 14.5523 17 14V6Z"
        fill={BColor[color]}
      />
    </svg>
  );
}
