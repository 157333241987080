import cookie from 'react-cookies';

import axios from 'axios';

import { getServerURL } from '@constants/serverURL';
import { defaultHeaders } from '@utils/fetchAPI/fetchForClient';
import { APIMethod, APIOption, BaseConfig } from './type';

export abstract class BaseAPI {
  private readonly API_ROUTER: string;

  private AUTH_TOKEN = '';

  private readonly API_TYPE: 'apis' | 'payment' = 'apis';

  constructor(URL: string, type?: 'apis' | 'payment') {
    this.API_ROUTER = URL;
    this.API_TYPE = type || 'apis';
  }

  private configFactory(config?: BaseConfig) {
    const authToken = this.getJwtToken();
    const BASE_CONFIG: BaseConfig = {
      headers: {
        ...defaultHeaders.headers,
        Authorization: `Bearer ${authToken}`,
      },
    };
    return { ...BASE_CONFIG, ...config };
  }

  private async methodFactory<T, K = unknown>(
    method: APIMethod,
    option: APIOption<K>,
  ): Promise<T> {
    const { endPoint, config, body, params } = option;

    const URL = getServerURL(this.API_TYPE) + this.API_ROUTER + endPoint;
    const CONFIG = this.configFactory({ ...config, params });

    const noNeedBodyMethod =
      method === APIMethod.GET || method === APIMethod.DELETE;

    try {
      const { data } = await axios.request<T>({
        method,
        url: URL,
        ...(noNeedBodyMethod ? {} : { data: body }),
        ...CONFIG,
      });

      return data;
    } catch (error) {
      throw new Error(`API Error: ${(error as any).message}`);
    }
  }

  protected async get<T, K = unknown>(option: APIOption<K>) {
    return this.methodFactory<T>(APIMethod.GET, option);
  }

  protected async post<T, K = unknown>(option: APIOption<K>) {
    return this.methodFactory<T, K>(APIMethod.POST, option);
  }

  protected async put<T, K = unknown>(option: APIOption<K>) {
    return this.methodFactory<T, K>(APIMethod.PUT, option);
  }

  protected async patch<T, K = unknown>(option: APIOption<K>) {
    return this.methodFactory<T>(APIMethod.PATCH, option);
  }

  protected async delete<T, K = unknown>(option: APIOption<K>) {
    return this.methodFactory<T>(APIMethod.DELETE, option);
  }

  private getJwtToken = () => {
    const accessToken = cookie.load('accessToken');
    this.AUTH_TOKEN = accessToken;

    return this.AUTH_TOKEN || accessToken || '';
  };

  // private errorObject = {
  //   [APIMethod.GET]: { [APIResponseStatus.getSuccess]: 11 },
  //   [APIMethod.POST]: 'api 요청에 실패했습니다.',
  // };
  //
  // private statusFactory(method: APIMethod, status: APIResponseStatus) {
  //   switch (method) {
  //     case APIMethod.GET:
  //   }
  // }
}
