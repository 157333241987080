import Font from '@components/atoms/font';
import TextInput from '@components/atoms/textInput';
import CallOutMessage from '@components/molecules/callOutMessage';
import ContentItem from '@pages/timeManagementPage/registerOffDayModal/ContentItem';
import DatepickerItem from '@pages/timeManagementPage/registerOffDayModal/DatepickerItem';
import { ChangeEvent } from 'react';
import styled from 'styled-components';

interface ContentProps {
  startDate: Date | null;
  // endDate: Date | null;
  onStartDateChange: (dateParam: Date | null) => void;
  // onEndDateChange: (dateParam: Date | null) => void;
  onStartDateInputChange: (value: string) => void;
  // onEndDateInputChange: (value: string) => void;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  offDayName: string;
}

function Content({
  startDate,
  // endDate,
  onStartDateChange,
  // onEndDateChange,
  onStartDateInputChange,
  // onEndDateInputChange,
  onChange,
  offDayName,
}: ContentProps) {
  return (
    <ContentWrapper>
      <CallOutMessage>
        <Font fontType="body1">
          {/* 공휴일 휴무 및 1일 휴가는 시작일과 종료일을 동일하게 설정해주세요. */}
          휴무명은 환자에게는 노출되지 않습니다.
        </Font>
      </CallOutMessage>

      <ContentContainer>
        <DatepickerItem
          title="휴무일"
          date={startDate}
          onDateChange={onStartDateChange}
          onDateInputChange={onStartDateInputChange}
        />

        {/* <DatepickerItem
          title="휴무 종료일"
          date={endDate}
          onDateChange={onEndDateChange}
          onDateInputChange={onEndDateInputChange}
        /> */}

        <ContentItem title="휴무명">
          <TextInput
            value={offDayName}
            onChange={onChange}
            type="text"
            width={260}
            height={38}
            paddingLeft={12}
            placeholder="휴무명을 입력해주세요 (예: 공휴일)"
          />
        </ContentItem>
      </ContentContainer>
    </ContentWrapper>
  );
}

const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 20px 40px 40px;
`;

const ContentContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1.25rem;
  align-items: center;
  padding-top: 40px;
`;

export default Content;
