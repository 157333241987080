import MedicineManagementTableV2 from '@components/organisms/managementMedicineTableV2';
import PharmacyAutoConfirmed from '@components/organisms/pharmacyAutoConfirmed';
import useCommonModal from '@pages/wrapper/modals/commonModal/hooks';
import { useEffect } from 'react';
import * as Style from './index.style';

function MedicineManagementPage() {
  const { openModal, closeModal } = useCommonModal({});

  useEffect(() => {
    openModal();
  }, [openModal]);

  return (
    <>
      <Style.MedicineManagementPageContainer>
        <Style.MedicineManagementPageContent>
          <PharmacyAutoConfirmed />
          <MedicineManagementTableV2 />
        </Style.MedicineManagementPageContent>
      </Style.MedicineManagementPageContainer>
    </>
  );
}

export default MedicineManagementPage;
