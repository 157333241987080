import { QUERY_KEY } from '@constants/react-query';
import { getTPProcessDetail } from '@mobile/api';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';

const useTPProcessDetail = () => {
  const { id } = useParams();
  const tppId = Number(id);

  const navigate = useNavigate();

  return useQuery({
    queryFn: () => getTPProcessDetail({ tppId }),
    queryKey: [QUERY_KEY.TP_PROCESS_DETAIL, id],
    retry: false,
  });
};

export default useTPProcessDetail;
