import { QUERY_KEY } from '@constants/react-query';
import { useSuspenseQuery } from '@tanstack/react-query';
import { getPrescriptionQrCodeImage } from '../apis';

interface UsePrescriptionQrCodeImageProps {
  prescriptionId: number;
}

const usePrescriptionQrCodeImage = ({
  prescriptionId,
}: UsePrescriptionQrCodeImageProps) => {
  return useSuspenseQuery({
    queryKey: [QUERY_KEY.TP_PRESCRIPTION_QR_CODE_IMAGE, prescriptionId],
    queryFn: () => {
      return getPrescriptionQrCodeImage(prescriptionId);
    },
    select: (data) => data?.data,
  });
};

export default usePrescriptionQrCodeImage;
