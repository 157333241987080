import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { EFindIdPasswordFormFields } from './find-id-password-form.type';

export const useFindIdPasswordForm = () => {
  const findIdPasswordFormSchema = {
    [EFindIdPasswordFormFields.PHONE_NUMBER]: yup
      .string()
      .matches(/^\d+$/, '숫자만 입력해주세요') // 숫자가 아닌 문자열에 대한 예외 처리
      .matches(
        /^(01[016789]{1})-?[0-9]{3,4}-?[0-9]{4}$/,
        '휴대폰 번호 형식에 맞지 않아요 (예시: 01012345678)',
      ),
  };

  const schema = yup.object().shape(findIdPasswordFormSchema);

  const defaultValue = {
    [EFindIdPasswordFormFields.PHONE_NUMBER]: '',
  };

  const methods = useForm({
    defaultValues: {
      ...defaultValue,
    },
    mode: 'all',
    resolver: yupResolver(schema),
  });

  return {
    methods,
  };
};
