import { Mixpanel } from '@utils/mixpanel';

export const loginMixpanelEvents = {
  pageLogin: '[Page] Login',

  clickSignUp: '[Click] Sign Up',
  clickFindIDAndPW: '[Click] Find ID/PW',

  successLogin: '[Success] Login',
  failLogin: '[Fail] Login',
} as const;

type LoginMixpanelEvents = keyof typeof loginMixpanelEvents;
type LoginMixpanelEventsParams = Exclude<
  LoginMixpanelEvents,
  'successLogin' | 'failLogin'
>;

const loginMixpanelEventsMap: Record<LoginMixpanelEventsParams, () => void> = {
  pageLogin: () => Mixpanel.track(loginMixpanelEvents.pageLogin),

  clickSignUp: () => Mixpanel.track(loginMixpanelEvents.clickSignUp),
  clickFindIDAndPW: () => Mixpanel.track(loginMixpanelEvents.clickFindIDAndPW),
};

export const trackLoginMixpanelEvents = (
  loginMixpanelEvent: LoginMixpanelEventsParams,
) => {
  return loginMixpanelEventsMap[loginMixpanelEvent];
};

export const trackSuccessLoginMixpanelEvents = (
  userID?: string,
  userType?: string,
) => {
  if (userID) {
    Mixpanel?.identify(userID);
    Mixpanel.people?.set({
      'User Type': userType,
    });
  }
};

export const trackFailLoginMixpanelEvents = (errorMessage?: string) =>
  Mixpanel.track(loginMixpanelEvents.failLogin, {
    'Error Message': errorMessage,
  });
