import { ICommon } from '@components/meraki-ui/type';
import fetchForClient from '@utils/fetchAPI/fetchForClient';
import { logOnError } from '@utils/log';
import { useCallback, useMemo, useState } from 'react';

import useSWR from 'swr';

interface IPersonalInfo {
  agree?: null | boolean;
  id: number;
  link: string | null;
  start_at?: number;
  content?: string;
  client_type: 'pharmacist-api';
  policy_type: 'partnerTerms';
  version: number;
  title: '파트너이용약관';
}

export const usePersonalInfo = () => {
  const [personInfoReportModalVisible, personInfoReportModalControl] =
    useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const openPersonInfoReportModal = useCallback(() => {
    personInfoReportModalControl(true);
  }, []);

  const closePersonInfoReportModal = useCallback(() => {
    personInfoReportModalControl(false);
  }, []);

  const { data } = useSWR<{
    status: number;
    data: ICommon<IPersonalInfo>;
  }>({
    path: 'pharmacist-api/policy-page/',
  });

  const personalInfo = useMemo(
    () => data?.data?.results,
    [data?.data?.results],
  );

  const { data: personalInfoReport, mutate: mutatePersonalInfoReport } =
    useSWR<{
      status: number;
      data?: IPersonalInfo;
      error?: string;
      error_code?: string;
    }>(
      personalInfo?.length
        ? {
            path: `${personalInfo?.[0]?.client_type}/policy-page/${personalInfo?.[0]?.policy_type}/${personalInfo?.[0]?.version}/`,
          }
        : null,
    );

  const onAgreePersonalInfoReport = useCallback(async () => {
    if (personalInfo?.length) {
      try {
        setIsLoading(true);
        await fetchForClient({
          path: `${personalInfo?.[0]?.client_type}/policy-page/${personalInfo?.[0]?.policy_type}/${personalInfo?.[0]?.version}/agreement/`,
          method: 'POST',
          data: {
            agree: true,
          },
        });

        // await mutateAgreement();
        await mutatePersonalInfoReport();
        setIsLoading(false);
        closePersonInfoReportModal();
        // router.reload();
      } catch (error) {
        setIsLoading(false);
        logOnError('error', error as Error);
      }
    }
  }, [personalInfo, mutatePersonalInfoReport, closePersonInfoReportModal]);

  const onDetail = useCallback(() => {
    if (window) {
      window.open('/termsOfService', '_blank');
    }
  }, []);

  return {
    personalInfoReport,
    personInfoReportModalVisible,
    openPersonInfoReportModal,
    closePersonInfoReportModal,
    onAgreePersonalInfoReport,
    isLoading,
    onDetail,
  };
};
