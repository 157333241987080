import { COLOR } from '@components/atoms';
import styled from 'styled-components';

export const TitleArea = styled.div`
  display: flex;
  align-items: center;
`;

export const TitleIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-left: 8px;
`;

export const DescriptionArea = styled.div`
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const DescriptionTitleArea = styled.div`
  margin-bottom: 5px;
`;

export const DescriptionDetailArea = styled.div`
  display: flex;
  margin: 30px 0;
  &:last-child {
    margin: 0;
  }
`;

export const DescriptionDetailLeftArea = styled.div`
  margin-right: 8px;
  padding-top: 3px;
`;

export const DescriptionDetailMiddleArea = styled.div`
  margin-right: 72px;
`;

export const DescriptionDetailMiddleTextArea = styled.div``;

export const DescriptionDetailRightArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
  width: 300px;
`;

export const CheckBoxArea = styled.div`
  display: flex;
  margin-right: 16px;
  position: relative;
`;

export const CheckBoxIconArea = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: -20px;
  top: 5px;
  cursor: pointer;
`;

export const InputArea = styled.div`
  display: flex;
  align-items: center;
`;

export const MultiLineInputArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ButtonIcon = styled.img`
  width: 14px;
  height: 14px;
  margin-right: 8px;
`;

export const TooltipMessageArea = styled.div``;

export const TooltipMessage = styled.div``;

export const TooltipImage = styled.img`
  width: 12px;
  height: 12px;
  margin-right: 4px;
`;

export const ContentBodyContainer = styled.div`
  display: flex;
  padding: 12px 0;
  justify-content: space-between;
`;

export const ContentBodyTitleArea = styled.div`
  display: flex;
  width: 180px;
  align-items: flex-start;
`;

export const ContentBodyTitle = styled.div``;

export const ContentBodyTextArea = styled.div`
  width: 480px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const ContentBodyText = styled.div`
  width: 100%;
  word-wrap: break-word;
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
`;

export const ContentBodyTextLeft = styled.div`
  width: 320px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const ContentBodyTextRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const ContentBodyTextRightTop = styled.div``;

export const ContentBodyTextRightBottom = styled.div``;

export const HorizontalLine = styled.div`
  width: 480px;
  height: 1px;
  background-color: ${COLOR['fill/light']};
  margin: 10px 0;
`;
