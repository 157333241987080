import { createSlice } from '@reduxjs/toolkit';
import { GetManagedMedicineItemType } from '@services/myDoctor/types';
import { RootState } from '@stores';

interface InitialStateType {
  isVisible: boolean;
  targetMedicine: GetManagedMedicineItemType | null;
}

const initialState: InitialStateType = {
  isVisible: false,
  targetMedicine: null,
};

export const medicineUnitManagementEditModal = createSlice({
  name: 'modal/medicineUnitManagementEditModal',
  initialState,
  reducers: {
    openModal_medicineUnitManagementEditModal: (state) => {
      state.isVisible = true;
    },
    closeModal_medicineUnitManagementEditModal: (state) => {
      state.isVisible = false;
    },

    set_targetMedicine_medicineUnitManagementEditModal: (state, action) => {
      state.targetMedicine = action.payload;
    },
  },
});

export const {
  openModal_medicineUnitManagementEditModal,
  closeModal_medicineUnitManagementEditModal,
  set_targetMedicine_medicineUnitManagementEditModal,
} = medicineUnitManagementEditModal.actions;

export const selectMedicineUnitManagementEditModalVisible = (
  state: RootState,
) => state.medicineUnitManagementEditModal.isVisible;

export const selectMedicineUnitManagementEditModalTargetMedicine = (
  state: RootState,
) => state.medicineUnitManagementEditModal.targetMedicine;

export default medicineUnitManagementEditModal.reducer;
