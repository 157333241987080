import { useState } from 'react';

export type ReasonOption =
  | 'noStock'
  | 'usedPrescription'
  | 'prescriptionIssue'
  | 'controlledMedication'
  | 'other';

export type CheckboxOption = {
  id: ReasonOption;
  label: string;
  needsInput?: boolean;
  placeholder?: string;
};

const useHandleRejectReason = () => {
  const [selectedOption, setSelectedOption] = useState<ReasonOption | null>(
    null,
  );
  const [textAreaContent, setTextAreaContent] = useState<string>('');
  const [reason, setReason] = useState<string>('');

  const checkBoxOptions: CheckboxOption[] = [
    { id: 'noStock', label: '약 재고가 없으며, 대체조제도 불가능합니다.' },
    { id: 'usedPrescription', label: '이미 사용된 처방전입니다.' },
    {
      id: 'prescriptionIssue',
      label: '처방전에 문제가 있어 병원에 다시 확인이 필요합니다.',
      needsInput: true,
      placeholder:
        '(예시)\n주민등록번호가 이상합니다.\n처방전 파일이 비어있습니다.\n다른 환자의 처방전입니다.',
    },
    { id: 'controlledMedication', label: '향정신성의약품이 처방되었습니다.' },
    {
      id: 'other',
      label: '기타',
      needsInput: true,
      placeholder: '조제 거절 사유를 입력해주세요.',
    },
  ];

  const handleSelect = (id: ReasonOption) => {
    setSelectedOption(id);
    const label = checkBoxOptions.find((option) => option.id === id)?.label;
    if (checkBoxOptions.find((option) => option.id === id)?.needsInput) {
      setReason(label || '');
      setTextAreaContent('');
    } else {
      setReason(label || '');
    }
  };

  const handleTextAreaChange = (text: string) => {
    setTextAreaContent(text);

    const selectedLabel = checkBoxOptions.find(
      (option) => option.id === selectedOption,
    )?.label;
    setReason(`${selectedLabel} : ${text}`);
  };

  return {
    reason,
    selectedOption,
    textAreaContent,
    checkBoxOptions,
    handleSelect,
    handleTextAreaChange,
  };
};

export default useHandleRejectReason;
