import { createSlice } from '@reduxjs/toolkit';
import { GetManagedMedicineItemType } from '@services/myDoctor/types';
import { RootState } from '@stores';

interface InitialStateType {
  isVisible: boolean;
  targetMedicine: GetManagedMedicineItemType | null;
}

const initialState: InitialStateType = {
  isVisible: false,
  targetMedicine: null,
};

export const medicineManagementEditModal = createSlice({
  name: 'modal/medicineManagementEditModal',
  initialState,
  reducers: {
    openModal_medicineManagementEditModal: (state) => {
      state.isVisible = true;
    },
    closeModal_medicineManagementEditModal: (state) => {
      state.isVisible = false;
    },

    set_targetMedicine_medicineManagementEditModal: (state, action) => {
      state.targetMedicine = action.payload;
    },
  },
});

export const {
  openModal_medicineManagementEditModal,
  closeModal_medicineManagementEditModal,
  set_targetMedicine_medicineManagementEditModal,
} = medicineManagementEditModal.actions;

export const selectMedicineManagementEditModalVisible = (state: RootState) =>
  state.medicineManagementEditModal.isVisible;

export const selectMedicineManagementEditModalTargetMedicine = (
  state: RootState,
) => state.medicineManagementEditModal.targetMedicine;

export default medicineManagementEditModal.reducer;
