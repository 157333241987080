import { ReactBootstrapIcons } from '@assets';
import { COLOR } from '@components/atoms';
import Modal from 'react-modal';
import { SymptomImageModalTemplateType } from './index.d';
import * as Style from './index.style';

export const modalStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    paddingBottom: '20px',
    backgroundColor: COLOR['fill/white'],
  },
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: COLOR['dim/medium'],
    zIndex: 100,
  },
};

function SymptomImageModalTemplate({
  isVisible,
  closeModal,
  imageIndex,
  minusImageIndex,
  plusImageIndex,
}: SymptomImageModalTemplateType) {
  return (
    <Modal
      isOpen={isVisible}
      style={modalStyle}
      onRequestClose={() => closeModal()}
      appElement={document.getElementById('root') as HTMLElement}
    >
      <Style.Image src={imageIndex} />
      <Style.LeftButtonIcon>
        <ReactBootstrapIcons.ArrowLeftCircleFill
          color={COLOR['fill/medium']}
          opacity={0.5}
          size={32}
          onClick={() => minusImageIndex()}
        />
      </Style.LeftButtonIcon>
      <Style.RightButtonIcon>
        <ReactBootstrapIcons.ArrowRightCircleFill
          color={COLOR['fill/medium']}
          opacity={0.5}
          size={32}
          onClick={() => plusImageIndex()}
        />
      </Style.RightButtonIcon>
    </Modal>
  );
}

export default SymptomImageModalTemplate;
