import styled from 'styled-components';
import {
  CheckboxOption,
  ReasonOption,
} from '../../hooks/useHandleRejectReason';

type Props = {
  option: CheckboxOption;
  isSelected: boolean;
  onSelect: (id: ReasonOption) => void;
  onTextAreaChange?: (text: string) => void;
};

function CheckboxOptionItem({
  option,
  isSelected,
  onSelect,
  onTextAreaChange,
}: Props) {
  return (
    <div style={{ width: '100%' }}>
      <CheckBoxRow>
        <CheckBox
          type="checkbox"
          checked={isSelected}
          onChange={() => onSelect(option.id)}
        />
        <CheckBoxText>{option.label}</CheckBoxText>
      </CheckBoxRow>
      {isSelected && option.needsInput && (
        <TextArea
          placeholder={option.placeholder}
          onChange={(e) => onTextAreaChange && onTextAreaChange(e.target.value)}
        />
      )}
    </div>
  );
}

export default CheckboxOptionItem;

const CheckBox = styled.input`
  appearance: none;
  width: 20px;
  height: 20px;
  background-image: url('https://d7qkbi83dtokl.cloudfront.net/prod/web/pharmacistAdmin/image/checkBox.png');
  background-size: 100% 100%;
  background-position: 50%;

  &:checked {
    background-image: url('https://d7qkbi83dtokl.cloudfront.net/prod/web/pharmacistAdmin/image/checkFilledBox.png');
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  height: 138px;
  padding: 12px 16px;
  border-radius: 8px;
  border: 1px solid #dddfe3;

  color: #474a52;
  font-family: Pretendard, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;

  &::placeholder {
    color: #9fa1a8;
    font-weight: 400;
  }
`;

const CheckBoxRow = styled.label`
  display: flex;
  padding: 8px 0px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
`;

const CheckBoxText = styled.div`
  color: #474a52;
  font-family: Pretendard, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
`;
