import { COLOR } from '@components/atoms';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 6px;
  align-items: flex-start;
  padding: 14px 16px;
  width: 100%;
  border-radius: 8px;
  background-color: ${COLOR['fill/light']};
`;

export const MedicineTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const MedicineContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const MedicineNamesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const MedicineInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const DotIconContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 9px;
`;

export const MedicineNamesTitle = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6px;
`;

export const MedicineNamesFoldContent = styled.div<{
  showMoreMedicine: boolean;
}>`
  display: ${({ showMoreMedicine }) => (showMoreMedicine ? '' : '-webkit-box')};
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  padding-left: 28px;
  width: 100%;
`;

export const MedicineNamesContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 28px;
`;

export const RotateIcon = styled.div<{ showMoreMedicine: boolean }>`
  rotate: ${({ showMoreMedicine }) => (showMoreMedicine ? '180deg' : '0deg')};
`;

export const ShowMedicineNameToggleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
  padding-left: 28px;
  cursor: pointer;
`;
