import { COLOR, Font } from '@components/atoms';
import { Document, Page } from 'react-pdf';
import styled from 'styled-components';
import { PDFViewerType } from './index.d';

function PDFViewer({ precriptionURL }: PDFViewerType) {
  return (
    <Container>
      <Button onClick={() => window.open(precriptionURL, '_blank')}>
        <Font fontType="h5" color="fill/accent">
          처방전 원본보기
        </Font>
      </Button>
      <Document file={precriptionURL}>
        <Page pageNumber={1} width={420} />
      </Document>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;

  /* height: 100%; */
`;

const Button = styled.div`
  cursor: pointer;
  display: flex;
  height: 50px;
  width: 100%;
  align-items: center;
  justify-content: center;

  border: 1px solid ${COLOR['fill/accent']};
  margin-bottom: 20px;
`;

export default PDFViewer;
