import useCompanyInfoModalHook from './hooks';
import CompanyInfoModalTemplate from './templates';

function CompanyInfoModal() {
  const { isVisible, closeModal } = useCompanyInfoModalHook();

  return (
    <CompanyInfoModalTemplate isVisible={isVisible} closeModal={closeModal} />
  );
}

export default CompanyInfoModal;
