import { useAppDispatch, useAppSelector } from '@stores/hooks';
import {
  closeModal_getReadyModal,
  selectGetReadyModalTitle,
  selectGetReadyModalVisible,
} from '@stores/modalStore/getReadyModal';
import { useCallback } from 'react';

const useGetReadyModalHook = () => {
  const dispatch = useAppDispatch();
  const isVisible = useAppSelector(selectGetReadyModalVisible);
  const title = useAppSelector(selectGetReadyModalTitle);

  const closeModal = useCallback(() => {
    dispatch(closeModal_getReadyModal());
  }, [dispatch]);

  return { isVisible, title, closeModal };
};

export default useGetReadyModalHook;
