import { Font } from '@components/atoms';
import { CallOutMessage } from '@components/molecules';
import { COMMON_CODE } from '@constants/commonCode';
import { formatters } from '@functions';
import OffDayContainer from '@pages/timeManagementPage/OffDayContainer';
import WorkingTimeList from '@pages/timeManagementPage/WorkingTimeList';
import { getDoctorOffSchedule } from '@stores/doctorOffScheduleStore';
import { selectGlobalNavBarOpen } from '@stores/globalNavBarStore';
import { useAppDispatch, useAppSelector } from '@stores/hooks';
import {
  getPharmacyWorkingTimeData,
  selectPharmacyWorkingTimeData,
  selectUserInformationPharmacistData,
  selectUserInformationUserData,
} from '@stores/userInformationStore';
import { useEffect, useState } from 'react';
import { UserInformationType } from 'types/index.d';
import {
  CardAlertArea,
  CardHeaderArea,
  CardHeaderTitleArea,
  Container,
  OpenTimeCardArea,
  PageTitleArea,
} from './index.style';

const initialWorkingTimeState: UserInformationType.PharmacistWorkingTimeType = {
  id: 0,
  week_day: 'MON',
  start: '00:00:00',
  end: '00:00:00',
  off: false,
};

const weekDays = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'] as const;

const initialWorkingTimeList: UserInformationType.PharmacistWorkingTimeType[] =
  weekDays.map((weekDay) => ({
    ...initialWorkingTimeState,
    week_day: weekDay,
  }));

function TimeManagementPage() {
  const { timeFormatter } = formatters;
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(selectGlobalNavBarOpen);
  const userData = useAppSelector(selectUserInformationUserData);
  const { pharmacy_id } = useAppSelector(selectUserInformationPharmacistData);
  const pharmacist_working_time = useAppSelector(selectPharmacyWorkingTimeData);

  const [workingTimes, setWorkingTimes] = useState<
    UserInformationType.PharmacistWorkingTimeType[]
  >([]);

  // 영업시간 fetch
  useEffect(() => {
    setWorkingTimes(() => {
      const new_doctor_working_time = pharmacist_working_time?.length
        ? pharmacist_working_time.slice()
        : initialWorkingTimeList;

      new_doctor_working_time.sort(timeFormatter.getWorkingTimeSortedByWeekDay);

      return [...new_doctor_working_time];
    });

    return () => setWorkingTimes([]);
  }, [pharmacist_working_time, timeFormatter.getWorkingTimeSortedByWeekDay]);

  // 휴무일 fetch
  useEffect(() => {
    if (!pharmacy_id) return;
    dispatch(getDoctorOffSchedule({ limit: 60, offset: 0 }));
    dispatch(getPharmacyWorkingTimeData(Number(pharmacy_id)));
  }, [dispatch, pharmacy_id]);

  return (
    <Container isOpen={isOpen}>
      <PageTitleArea>
        <Font fontType="h1">영업시간 및 휴무 관리</Font>
      </PageTitleArea>
      <OpenTimeCardArea>
        <CardHeaderArea>
          <CardHeaderTitleArea>
            <Font fontType="h2">나만의닥터 영업시간</Font>
          </CardHeaderTitleArea>
        </CardHeaderArea>
        <CardAlertArea>
          {userData.user_type === COMMON_CODE.USER_TYPE.NURSE ? (
            <CallOutMessage iconColor="greyscale6">
              <Font fontType="body3">
                간호사 계정에서는 영업시간 변경 기능이 아직 출시 준비 중입니다.
              </Font>
            </CallOutMessage>
          ) : (
            <CallOutMessage>
              <Font fontType="body3">
                영업 시간은 20분 단위로 입력 가능합니다.
              </Font>
            </CallOutMessage>
          )}
        </CardAlertArea>
        <WorkingTimeList
          workingTimes={workingTimes}
          pharmacyId={Number(pharmacy_id)}
        />
      </OpenTimeCardArea>
      <OffDayContainer />
    </Container>
  );
}

export default TimeManagementPage;
