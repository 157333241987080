// export const Color = {
//   black100: '#000000',
//   black90: '#2F2F2F',
//   black80: '#6D6D6D',
//   black60: '#ABABAB',
//   gray: '#C4C4C4',
//   white100: '#FFFFFF',
//   white90: '#FEFEFE',
//   white80: '#FAFAFA',
//   white60: '#F2F2F2',
//   white40: '#F8F9FA',
//   bg: '#F0F1F4',
//   blueGray: '#EAECF0',
//   mainBlue: '#3478F6',
//   blue40: '#E3EFFF',
//   blue20: '#EFF5FD',
//   yellow10: '#FFFBEF',
//   red_alert: '#F63434',
//   sub_pink: '#FF9999',
//   sub_orange: '#FFB190',
//   sub_yellow: '#FFE5A4',
//   sub_green: '#20C997',
//   sub_blueGreen: '#4DB9B9',
//   sub_blueSky: '#EBF1FE',
//   sub_purple: '#C89DEB',
//   sub_gray: '#E9E9E9',
//   kakao_yellow: '#FEDC2C',
//   bs_blue: '#3478F6',
//   bs_dark: '#212529',
//   bs_teal: '#20C997',
//   bs_gray: '#F4F4F4',
//   bs_red: '#DC3545',
//   bs_yellow: '#FFC107',
//   guide_gray: '#D7D7D7',
//   bs_dark_gray: '#343A40',
//   mono08: '#474747',
//   mono07: '#919191',
//   mono06: '#B8B8B8',
//   mono05: '#DCDCDC',
//   mono02: '#F1F1F1',
//   mono01: '#FCFCFC',
//   borderColor: 'rgba(0, 0, 0, 0.1)',
//   modalBorderBottom: '#DDDDDD',
//   xIconColor: '#6C757D',

const COLOR_PALETTE_TINY = {
  blue50: '#F5FAFF',
  blue100: '#EBF5FF',
  blue300: '#99C5FF',
  blue500: '#4785FF',
  blue700: '#2F6EFF',
  blue900: '#1750D3',
  red50: '#FFFAFA',
  red100: '#FFF0F0',
  red300: '#FFB8B8',
  red500: '#FF7070',
  red700: '#FF4747',
  red900: '#E82C2C',
  yellow50: '#FFFCF0',
  yellow100: '#FFF8DB',
  yellow300: '#FFEDA3',
  yellow500: '#FFE066',
  yellow700: '#FFCC00',
  yellow900: '#FFB200',
  green50: '#F0FFF5',
  green100: '#E5FAEC',
  green300: '#B2F0CC',
  green500: '#66E0A3',
  green700: '#00C785',
  green900: '#00AD74',
};

const COLOR_PALETTE_GREYSCALE = {
  greyscale0: '#FFFFFF',
  greyscale1: '#F9FAFA',
  greyscale2: '#F1F2F4',
  greyscale3: '#DDDFE3',
  greyscale4: '#C3C5CB',
  greyscale5: '#9FA1A8',
  greyscale6: '#686B73',
  greyscale7: '#474A52',
  greyscale8: '#1E2024',
  greyscale9: '#000000',
};

const COLOR_PALETTE = {
  ...COLOR_PALETTE_TINY,
  ...COLOR_PALETTE_GREYSCALE,
};

export const COLOR = {
  'fill/accent': COLOR_PALETTE.blue700,
  'fill/black': COLOR_PALETTE.greyscale8,
  'fill/dark': COLOR_PALETTE.greyscale7,
  'fill/medium': COLOR_PALETTE.greyscale5,
  'fill/mediumLight': COLOR_PALETTE.greyscale3,
  'fill/light': COLOR_PALETTE.greyscale1,
  'fill/white': COLOR_PALETTE.greyscale0,
  'bg/primary': COLOR_PALETTE.greyscale0,
  'bg/secondary': '#E9EBF0',
  'border/divider': COLOR_PALETTE.greyscale2,
  'border/outline': '#DDDFE3',
  'state/warning': COLOR_PALETTE.yellow700,
  'state/distructive': COLOR_PALETTE.red700,
  'state/success': COLOR_PALETTE.green700,
  'dim/thick': '#22242aB3',
  'dim/medium': '#22242a99',
  'dim/light': '#22242a66',
  'disabled/foreground': COLOR_PALETTE.greyscale4,
  'disabled/background': COLOR_PALETTE.greyscale1,
  'hover/accent': '#2A63E5',
  'hover/lightBlue': '#DFE7F2',
  'hover/distructive': '#E54040',
  'hover/dark': '#313338',
  'hover/light': '#ECECED',
  'hover/white': '#F2F2F2',
  'pressed/accent': '#214DB3',
  'pressed/lightBlue': '#D3DBE5',
  'pressed/distructive': '#B23232',
  'pressed/dark': '#040505',
  'pressed/light': '#DFE0E0',
  'pressed/white': '#E6E6E6',
  'label/lightBlue': COLOR_PALETTE.blue100,
  'label/lightRed': COLOR_PALETTE.red100,
  'label/lightGreen': COLOR_PALETTE.green100,
  'label/lightYellow': COLOR_PALETTE.yellow100,

  ...COLOR_PALETTE_TINY,
  ...COLOR_PALETTE_GREYSCALE,
};

export type ColorType = keyof typeof COLOR;
