import { Font, LoadingDots } from '@components/atoms';
import SvgIcon from '@components/atoms/images';
import { priceFormatter } from '@functions/formatters';
import { useAppSelector } from '@stores/hooks';
import { selectUserInformationPharmacistData } from '@stores/userInformationStore';
import { isNil } from 'lodash';
import { Suspense, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useSubstitutePrescriptionInfo from './hooks';
import * as Style from './index.style';

interface SubstitutePrescriptionInfoProps {
  tmId: number | undefined;
  isSubstitutedPatient?: boolean | null;
}

function SubstitutePrescriptionInfo({
  tmId,
  isSubstitutedPatient,
}: SubstitutePrescriptionInfoProps) {
  const pharmacist = useAppSelector(selectUserInformationPharmacistData);
  const location = useLocation();

  const tppId = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get('id');
  }, [location.search]);

  return (
    <Style.Container>
      <Suspense
        fallback={
          <>
            <SvgIcon icon="timeIcon" width={20} color="fill/medium" />
            <div>
              <Font fontType="h5" color="fill/black">
                <LoadingDots>정보를 불러오는 중입니다</LoadingDots>
              </Font>
            </div>
          </>
        }
      >
        {!isNil(tmId) && !isNil(pharmacist.pharmacy_id) && !isNil(tppId) && (
          <SubstitutePrescriptionInfoContent
            tmId={tmId}
            pharmacyId={pharmacist.pharmacy_id}
            tppId={tppId}
            isSubstitutedPatient={isSubstitutedPatient}
          />
        )}
      </Suspense>
    </Style.Container>
  );
}

interface SubstitutePrescriptionInfoContentProps {
  tmId: number;
  pharmacyId: number;
  tppId: string;
  isSubstitutedPatient?: boolean | null;
}

function SubstitutePrescriptionInfoContent({
  tmId,
  pharmacyId,
  tppId,
  isSubstitutedPatient,
}: SubstitutePrescriptionInfoContentProps) {
  const { data } = useSubstitutePrescriptionInfo({
    tmId,
    pharmacyId,
    tppId,
  });

  const showMoreButton = useMemo(() => {
    if (data?.medicine_list.length < 3) {
      return false;
    }

    return true;
  }, [data]);
  const [showMoreMedicine, setShowMoreMedicine] = useState(false);

  return (
    <>
      <SvgIcon
        icon={isSubstitutedPatient ? 'changeIcon' : 'infoIcon'}
        width={20}
        color={isSubstitutedPatient ? 'fill/accent' : 'green700'}
      />
      <Style.MedicineContentContainer>
        <Font fontType="h4" color="fill/black">
          {data?.medicine_list.length === 0
            ? isSubstitutedPatient
              ? '동일 성분 대체조제에 동의한 환자입니다.'
              : '처방전 그대로 조제를 희망하는 환자입니다.'
            : ''}
          {data?.medicine_list.length > 0 &&
            (isSubstitutedPatient
              ? '아래 의약품으로 대체조제를 희망하는 환자입니다.'
              : '처방전 그대로 조제를 희망하는 환자입니다.')}
        </Font>

        {data?.medicine_list.length > 0 && (
          <>
            <Style.MedicineNamesContainer>
              <Style.MedicineInfoContainer>
                <Style.MedicineNamesTitle>
                  <Font fontType="body2_medium" color="fill/medium">
                    {` • ${
                      isSubstitutedPatient ? '대체가능한 의약품' : '의약품명'
                    }`}
                  </Font>
                </Style.MedicineNamesTitle>

                <Style.MedicineNamesContent>
                  <Font
                    fontType="body2_medium"
                    color="fill/dark"
                    noWhiteSpace={!showMoreMedicine}
                  >
                    {data?.medicine_list
                      .map((medicineName) => `${medicineName}`)
                      .join(', ')}
                  </Font>
                </Style.MedicineNamesContent>

                {showMoreButton && (
                  <Style.ShowMedicineNameToggleContainer
                    onClick={() => {
                      setShowMoreMedicine((prev) => !prev);
                    }}
                  >
                    <Font fontType="body2_medium" color="fill/medium">
                      {showMoreMedicine ? '접기' : '더보기'}
                    </Font>
                    <Style.RotateIcon showMoreMedicine={showMoreMedicine}>
                      <SvgIcon
                        icon="chevronDown"
                        width={16}
                        color="fill/medium"
                      />
                    </Style.RotateIcon>
                  </Style.ShowMedicineNameToggleContainer>
                )}
              </Style.MedicineInfoContainer>

              <Style.MedicineInfoContainer>
                <Style.MedicineNamesTitle>
                  <Font fontType="body2_medium" color="fill/medium">
                    • 총 약제비
                  </Font>
                </Style.MedicineNamesTitle>

                <Style.MedicineNamesContent>
                  <Font fontType="body2_medium" color="fill/dark">
                    {`${priceFormatter.commaFormatter(
                      String(data.whole_price),
                    )}원 = 약가 ${priceFormatter.commaFormatter(
                      String(data.multiplied_price),
                    )}원 (${priceFormatter.commaFormatter(
                      String(data.quantity),
                    )}알 * ${priceFormatter.commaFormatter(
                      String(data.price),
                    )}원) + 조제료 ${priceFormatter.commaFormatter(
                      String(data.preparation_fee),
                    )}원
                  (${priceFormatter.commaFormatter(
                    String(data.total_dosing_days),
                  )}일 기준)`}
                  </Font>
                </Style.MedicineNamesContent>
              </Style.MedicineInfoContainer>
            </Style.MedicineNamesContainer>
          </>
        )}
      </Style.MedicineContentContainer>
    </>
  );
}

export default SubstitutePrescriptionInfo;
