import { useAppSelector } from '@stores/hooks';
import { selectIsVersionUp } from '@stores/versionStore';
import { ReactNode } from 'react';
import * as Style from './index.style';

function ConfirmedTreatmentPage({ children }: { children: ReactNode }) {
  const isVersionUp = useAppSelector(selectIsVersionUp);

  return (
    <Style.Container isVersionUp={isVersionUp}>
      <Style.CardArea>{children}</Style.CardArea>
    </Style.Container>
  );
}

export default ConfirmedTreatmentPage;
