export default function GNBLogo() {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.7653 25.5897H23.4358C24.5761 25.5897 25.5775 26.8794 25.5775 28.1539V35.8592C25.5775 37.1357 24.596 38.1794 23.3653 38.2114L21.8855 38.2499H18.7653L14.98 38.25V42H18.4212H23.8604C26.9616 42 29.4756 39.3931 29.4756 36.1772V35.8681V28.1539C29.4756 24.7552 27.0109 22 23.97 22H18.4212H14.98V25.5897H18.4212H18.7653ZM31.5457 35.125H35.2356C37.4816 35.125 38.4254 35.5777 38.5273 37.6511L38.6804 40.2301C38.7061 40.9381 38.8087 41.5702 38.9867 42H42.7636C42.5848 41.5449 42.4573 40.9381 42.4067 40.1795L42.2279 37.3982C42.0747 34.7687 41.1815 33.4791 39.0637 33.1504C41.4108 32.4677 42.5848 30.7484 42.5848 28.1188C42.5848 24.2503 40.3395 22 36.1032 22L31.5457 22V25.75L35.5157 25.75C37.5822 25.75 38.8336 26.0202 38.8336 28.3717C38.8336 30.7231 37.5578 31.5828 35.4913 31.5828H31.5457V35.125ZM47.0268 42C48.1256 42 49.0164 41.0782 49.0164 39.9412C49.0164 38.8041 48.1256 37.8824 47.0268 37.8824C45.928 37.8824 45.0372 38.8041 45.0372 39.9412C45.0372 41.0782 45.928 42 47.0268 42Z"
        fill="#212529"
      />
    </svg>
  );
}
