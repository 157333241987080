export const COMMON_CODE = {
  // 성별
  GENDER_TYPE: {
    MALE: 'GENDER_TYPE__MALE', // 남자
    FEMALE: 'GENDER_TYPE__FEMALE', // 여자
  },
  // 원격 조제
  TP_METHOD: {
    NONE: 'TP_METHOD__NONE', // 원격조제 이용안함
    PICKUP: 'TP_METHOD__PICKUP', // 직접픽업
    PARCEL: 'TP_METHOD__PARCEL', // 택배
    QUICK: 'TP_METHOD__QUICK', // 반짝 퀵 배송
    ECONOMY_QUICK: 'TP_METHOD__ECONOMY_QUICK', // 일반 퀵 배송
    COLD_CHAIN: 'TP_METHOD__COLD_CHAIN', // 신선배송
  },
  // 원격 조제 상테
  TP_STATUS: {
    FINISHED: 'TP_STATUS__FINISHED', // 정상 종료
    REJECTED: 'TP_STATUS__REJECTED', // 조제 거절
    ACCEPTED: 'TP_STATUS__ACCEPTED', // 조제 수락
    PRE_CONFIRMED: 'TP_STATUS__PRE_CONFIRMED', // 의약외품을 구매하기로 결정한 경우에만 거쳐가는 STATUS
    REQUESTED: 'TP_STATUS__REQUESTED', // 조제 요청 보냄
    PRE_REQUESTED: 'TP_STATUS__PRE_REQUESTED', // 조제 요청 보내기전, 우선순위 낮아 요청 보내지 않은 상태
    CONFIRMED: 'TP_STATUS__CONFIRMED', // 약사가 제시한 가격을 환자가 승인함
    P_CANCEL: 'TP_STATUS__P_CANCEL', // 환자가 조제를 취소함
    PH_CANCEL: 'TP_STATUS__PH_CANCEL', // 약사가 조제를 취소함
    FAX_OCR: 'TP_STATUS__FAX_OCR', // 픽업시, 비식별화된 처방전 FAX를 보냄
    FAX_ORIGIN: 'TP_STATUS__FAX_ORIGIN', // 픽업시, 원본 처방전 FAX를 보냄
  },
  // 결제 상태
  PAYMENT_STATUS: {
    FINISHED: 'PAYMENT_STATUS__FINISHED', // 결제 완료
    FAILED: 'PAYMENT_STATUS__FAILED', // 결제 실패
    REQUESTED: 'PAYMENT_STATUS__REQUESTED', // 결제 요청
    DEFAULT: 'PAYMENT_STATUS__DEFAULT', // 결제 할지 말지 결정 안됨
    CANCELED: 'PAYMENT_STATUS__CANCELED', // 결제 취소됨
  },
  // 원격 진료 상태
  TM_STATUS: {
    P_CANCEL: 'TM_STATUS__P_CANCEL', // 환자의 진료 취소, 의사가 해당 상태를 FINISHED로 옮길수 있다
    D_CANCEL: 'TM_STATUS__D_CANCEL', // 의사의 진료 취소
    D_NOSHOW: 'TM_STATUS__D_NOSHOW', // 의사의 진료 노쇼
    P_NOSHOW: 'TM_STATUS__P_NOSHOW', // 환자의 진료 노쇼
    FINISHED: 'TM_STATUS__FINISHED', // 정상적으로 의사가 진료를 본 후 완료됨
    REJECTED: 'TM_STATUS__REJECTED', // 의사가 진료 거절함
    ACCEPTED: 'TM_STATUS__ACCEPTED', // 의사가 진료 수락하고 원격진료 대기중
    REQUESTED: 'TM_STATUS__REQUESTED', // 진료 요청함
    D_CHANGE_TIME: 'TM_STATUS__D_CHANGE_TIME', // 진료 시간 변경 요청
  },
  // 원격 진료 대상자
  TM_PROXY_TYPE: {
    ALL: 'TM_PROXY_TYPE__ALL', // 대리 진료. 접수 결제 진료 모두 대리인이 진행
    APPOINTMENT: 'TM_PROXY_TYPE__APPOINTMENT', // 대리접수. 대리인이 접수 결제, 피대리인이 진료 받음
    SELF: 'TM_PROXY_TYPE__SELF', // 대리가 아닌 본인 진료
  },
  // 가족 타입
  FAMILY_TYPE: {
    PARTNER: 'FAMILY_TYPE__PARTNER', // 배우자
    SIBLING: 'FAMILY_TYPE__SIBLING', // 형제 자매
    CHILDREN: 'FAMILY_TYPE__CHILDREN', // 직계 비속
    GRAND: 'FAMILY_TYPE__GRAND', // 직계 존속 및 배우자의 직계 존속
    PARENT: 'FAMILY_TYPE__PARENT', // 부모
  },
  // 가족 초대 상태
  FAMILY_INVITE_STATUS: {
    REJECTED: 'FAMILY_INVITE_STATUS__REJECTED', // 초대 거절함
    ACCEPTED: 'FAMILY_INVITE_STATUS__ACCEPTED', // 초대 수락함
    REQUEST: 'FAMILY_INVITE_STATUS__REQUEST', // 초대 요청함
  },
  // 유저 타입
  USER_TYPE: {
    DEVELOPER: 'USER_TYPE__DEVELOPER', // 메라키 플레이스 개발자
    MERAKI: 'USER_TYPE__MERAKI', // 메라키 플레이스 임직원
    PHARMACIST: 'USER_TYPE__PHARMACIST', // 약사
    NURSE: 'USER_TYPE__NURSE', // 간호사
    DOCTOR: 'USER_TYPE__DOCTOR', // 의사
    WARD: 'USER_TYPE__WARD', // 하위 계정. 전화번호, 카카오 계정이 없는 아기 노약자 등
    PATIENT: 'USER_TYPE__PATIENT', // 일반 사용자, 환자
  },
  // 처방전
  PRSC_STATUS: {
    VALID: 'PRSC_STATUS__VALID', // 처방전 이상없음
    ACCUSED: 'PRSC_STATUS__ACCUSED', // 처방전 신고받음
    DEFAULT: 'PRSC_STATUS__DEFAULT', // 처방전
  },
  // 배송 상태
  DELIVERY_STATUS: {
    ERROR: 'DELIVERY_STATUS__ERROR', // 배송장애
    FINISHED: 'DELIVERY_STATUS__FINISHED', // 배송완료
    STARTED: 'DELIVERY_STATUS__STARTED', // 배송시작
    REQUESTED: 'DELIVERY_STATUS__REQUESTED', // 배송요청
    DEFAULT: 'DELIVERY_STATUS__DEFAULT', // 배송요청 전
    COLLECTED: 'DELIVERY_STATUS__COLLECTED', // 배송상품수거완료
    CANCEL: 'DELIVERY_STATUS__CANCEL', // 배송취소
    REJECTED: 'DELIVERY_STATUS__REJECTED', // 배송거절
    DEPATCHED: 'DELIVERY_STATUS__DEPATCHED', // 배송기사방문예정, 택베에서만 사용
    PICKUP_REQUESTED: 'DELIVERY_STATUS__PICKUP_REQUESTED',
    PICKUP_FINISHED: 'DELIVERY_STATUS__PICKUP_FINISHED',
  },
};
