import { Response } from '@constants/apis/types';
import fetchAPI from '@utils/fetchAPI';

const MY_DOCTOR_TYPE = 'myDoctor';

export type GetPrescriptionQrCodeImageResponse = PrescriptionQrCodeImage[];

export interface PrescriptionQrCodeImage {
  id: number;
  prescription_id: number;
  page_index: number;
  qr_index: number;
  image: string;
  is_recognition_failure_reported: boolean;
  recognition_failure_reported_at: string;
}

export const getPrescriptionQrCodeImage = (
  prescriptionId: number,
): Promise<Response<GetPrescriptionQrCodeImageResponse>> => {
  return fetchAPI.get(
    `prescription/${prescriptionId}/qr-codes`,
    {},
    MY_DOCTOR_TYPE,
  );
};

interface PostPrescriptionQrCodeReportFailureParams {
  prescriptionId: number;
  pageIndex: number;
  qrIndex: number;
}

export const postPrescriptionQrCodeReportFailure = ({
  prescriptionId,
  pageIndex,
  qrIndex,
}: PostPrescriptionQrCodeReportFailureParams) => {
  return fetchAPI.post(
    `prescription/${prescriptionId}/qr-codes/${pageIndex}-${qrIndex}/report-recog-failure`,
    {},
    MY_DOCTOR_TYPE,
  );
};
