import mixpanel, { Dict, Query } from 'mixpanel-browser';

export const mixpanelConfig = () => {
  let token: string;
  let api_host: string;
  let debug = false;

  switch (process.env.REACT_APP_NEXT_PUBLIC_MERAKI) {
    case 'PRODUCTION':
      token = '2b90a235d191ad061416e8d232a1ad19';
      api_host = 'https://api.mixpanel.com';
      break;
    default:
      token = '4d288d5a8ab099d4f2b937680ada081c';
      api_host = 'https://api.mixpanel.com';
      debug = true;
      break;
  }
  return {
    api_host,
    token,
    debug,
  };
};

export const Mixpanel = {
  identify: (id: string) => {
    mixpanel.identify(id);
  },
  alias: (id: string) => {
    mixpanel.alias(id);
  },
  track: (name: string, props?: Dict) => {
    mixpanel.track(name, props);
  },
  track_links: (query: Query, name: string) => {
    mixpanel.track_links(query, name, {
      referrer: document.referrer,
    });
  },
  people: {
    set: (props: Dict) => {
      mixpanel.people.set(props);
    },
  },
};
