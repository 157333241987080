import { Font } from '@components/atoms';
import { CallOutMessage } from '@components/molecules';
import { commonCode } from '@constants';
import { formatters } from '@functions';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { TelepharmacyType } from 'types/index.d';

const { COMMON_CODE } = commonCode;

const STATUS_TEXT = {
  TP_REQUESTED: '요청 후 10분 동안 미수락 시 조제가 자동으로 취소됩니다.',
  TP_STATUS__FINISHED: '환자가 약을 수령했다면, 방문 완료를 눌러주세요.',
  TP_STATUS_COMPLETE: '환자가 약을 수령했습니다.',
  TP_ACCEPTED:
    '환자의 결제를 기다리고 있습니다. 수락 후 20분 경과 시 자동결제가 진행됩니다.',
  TP_CONFIRMED:
    '약이 준비되면 약국 방문 요청을 눌러주세요.\n 환자에게 약국 방문 요청 메시지가 전송됩니다.',
  TP_REJECTED: '취소사유',
  TP_P_CANCEL: '취소사유',
  TP_PH_CANCEL: '취소사유',
  DELIVERY_REQUESTED_QUICK: '기사님을 찾고 있어요.',
  DELIVERY_REQUESTED_PARCEL: '운송장을 발급하고 있어요.',
  DELIVERY_STARTED_QUICK: '기사님 예상 방문시간',
  DELIVERY_STARTED_PARCEL: '운송장',
  DELIVERY_COLLECTED_QUICK: '예상 배달시간',
  DELIVERY_COLLECTED_PARCEL: '운송장',
  DELIVERY_FINISHED_QUICK: '배달완료 일시',
  DELIVERY_FINISHED_PARCEL: '배송완료 일시',
  DELIVERY_REJECTED_QUICK:
    '기사님 연결이 지연 중입니다. 배달이 가능한 기사님을 찾고 있어요.',
  DELIVERY_REJECTED_PARCEL: '운송장 발급이 지연 중입니다.',
  DELIVERY_CANCEL: '취소사유',
  PAYMENT_FAILED: '등록된 환자의 결제수단으로 결제에 실패했습니다.',
  PAYMENT_CANCELED: '결제가 취소되었습니다.',
  DELIVERY_STATUS__PICKUP_REQUESTED: '',
};

function ProgressText({
  tpStatus,
  deliveryStatus,
  paymentStatus,
  item,
}: {
  tpStatus?: string;
  deliveryStatus?: string;
  paymentStatus?: string;
  item: TelepharmacyType.TelepharmacyItemType;
}) {
  const { pathname } = useLocation();

  const { timeFormatter } = formatters;
  const quickPickEstimatedTime = item.tp_delivery?.quick_pick_estimated_time
    ? moment(item.tp_delivery?.quick_pick_estimated_time).unix()
    : moment().unix();

  const quickPickEstimatedMinute = item.tp_delivery?.quick_pick_estimated_time
    ? parseInt(String((quickPickEstimatedTime - moment().unix()) / 60 + 1), 10)
    : '-';

  const quickDropEstimatedTime = item.tp_delivery?.quick_drop_estimated_time
    ? moment(item.tp_delivery?.quick_drop_estimated_time).unix()
    : moment().unix();

  const quickDropEstimatedMinute = item.tp_delivery?.quick_drop_estimated_time
    ? parseInt(String((quickDropEstimatedTime - moment().unix()) / 60 + 1), 10)
    : '-';

  const deliveryEndDate = item.tp_delivery?.delivery_end_date
    ? timeFormatter.getInjectTimeFormatter(item.tp_delivery?.delivery_end_date)
    : '-';

  const trackingNumber = item.tp_delivery?.tracking_number || '-';

  let statusText = STATUS_TEXT.TP_REQUESTED;
  if (tpStatus === COMMON_CODE.TP_STATUS.REQUESTED) {
    statusText = STATUS_TEXT.TP_REQUESTED;
  } else if (
    tpStatus === COMMON_CODE.TP_STATUS.ACCEPTED ||
    tpStatus === COMMON_CODE.TP_STATUS.PRE_CONFIRMED
  ) {
    if (deliveryStatus === 'DELIVERY_STATUS__DEFAULT') {
      statusText = '환자가 약제비를 확인하고 결제하는 것을 기다리고 있습니다.';
    } else {
      statusText = STATUS_TEXT.TP_ACCEPTED;
    }
  } else if (tpStatus === COMMON_CODE.TP_STATUS.CONFIRMED) {
    statusText = STATUS_TEXT.TP_CONFIRMED;
  } else if (tpStatus === COMMON_CODE.TP_STATUS.REJECTED) {
    statusText = `${STATUS_TEXT.TP_REJECTED}: ${item.reject_reason || '-'}`;
  } else if (tpStatus === COMMON_CODE.TP_STATUS.P_CANCEL) {
    statusText = `${STATUS_TEXT.TP_P_CANCEL}: ${item.cancel_reason || '-'}`;
  } else if (tpStatus === COMMON_CODE.TP_STATUS.PH_CANCEL) {
    statusText = `${STATUS_TEXT.TP_PH_CANCEL}: ${item.cancel_reason || '-'}`;
  } else if (tpStatus === COMMON_CODE.TP_STATUS.FINISHED) {
    statusText = STATUS_TEXT.TP_STATUS__FINISHED;

    if (pathname.includes('completeTreatmentDetail')) {
      statusText = STATUS_TEXT.TP_STATUS_COMPLETE;
    }

    if (
      deliveryStatus === COMMON_CODE.DELIVERY_STATUS.REQUESTED ||
      deliveryStatus === COMMON_CODE.DELIVERY_STATUS.DEPATCHED ||
      deliveryStatus === COMMON_CODE.DELIVERY_STATUS.DEFAULT
    ) {
      statusText =
        item.method === COMMON_CODE.TP_METHOD.QUICK ||
        item.method === COMMON_CODE.TP_METHOD.ECONOMY_QUICK
          ? STATUS_TEXT.DELIVERY_REQUESTED_QUICK
          : STATUS_TEXT.DELIVERY_REQUESTED_PARCEL;
    } else if (deliveryStatus === COMMON_CODE.DELIVERY_STATUS.COLLECTED) {
      statusText =
        item.method === COMMON_CODE.TP_METHOD.QUICK ||
        item.method === COMMON_CODE.TP_METHOD.ECONOMY_QUICK
          ? `${STATUS_TEXT.DELIVERY_COLLECTED_QUICK}: ${quickDropEstimatedMinute}분 뒤`
          : `${STATUS_TEXT.DELIVERY_COLLECTED_PARCEL}: ${trackingNumber}`;
    } else if (deliveryStatus === COMMON_CODE.DELIVERY_STATUS.STARTED) {
      statusText =
        item.method === COMMON_CODE.TP_METHOD.QUICK ||
        item.method === COMMON_CODE.TP_METHOD.ECONOMY_QUICK
          ? `${STATUS_TEXT.DELIVERY_STARTED_QUICK}: ${quickPickEstimatedMinute}분 뒤`
          : `${STATUS_TEXT.DELIVERY_STARTED_PARCEL}: ${trackingNumber}`;
    } else if (deliveryStatus === COMMON_CODE.DELIVERY_STATUS.FINISHED) {
      statusText =
        item.method === COMMON_CODE.TP_METHOD.QUICK ||
        item.method === COMMON_CODE.TP_METHOD.ECONOMY_QUICK
          ? `${STATUS_TEXT.DELIVERY_FINISHED_QUICK}: ${deliveryEndDate}`
          : `${STATUS_TEXT.DELIVERY_FINISHED_PARCEL}: ${deliveryEndDate}`;
    } else if (deliveryStatus === COMMON_CODE.DELIVERY_STATUS.REJECTED) {
      statusText =
        item.method === COMMON_CODE.TP_METHOD.QUICK ||
        item.method === COMMON_CODE.TP_METHOD.ECONOMY_QUICK
          ? STATUS_TEXT.DELIVERY_REJECTED_QUICK
          : STATUS_TEXT.DELIVERY_REJECTED_PARCEL;
    } else if (deliveryStatus === COMMON_CODE.DELIVERY_STATUS.CANCEL) {
      statusText = STATUS_TEXT.DELIVERY_CANCEL;
    }
  }

  // 결제 실패 및 취소 시
  if (paymentStatus === COMMON_CODE.PAYMENT_STATUS.FAILED) {
    statusText = STATUS_TEXT.PAYMENT_FAILED;
  } else if (paymentStatus === COMMON_CODE.PAYMENT_STATUS.CANCELED) {
    statusText = STATUS_TEXT.PAYMENT_CANCELED;
  }

  return (
    <CallOutMessage iconColor="fill/medium">
      <Font fontType="body1" color="greyscale6">
        {statusText}
      </Font>
    </CallOutMessage>
  );
}

export default ProgressText;
