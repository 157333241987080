import { Font } from '@components/atoms';
import styled from 'styled-components';

type Props = {
  medicine_number: string;
  medicine_name: string;
  one_dose: string;
  daily_doses: string;
  total_days_taken: string;
  medicine_generic?: string | null;
  insured_text: string;
};

function MedicineInfoItem({
  daily_doses,
  medicine_name,
  medicine_number,
  one_dose,
  total_days_taken,
  medicine_generic,
  insured_text,
}: Props) {
  return (
    <Container>
      <ItemContainer>
        <Description>
          <Font fontType="body1" color="fill/medium">
            보험코드
          </Font>
        </Description>
        <Font fontType="body1_medium" color="fill/accent">
          {medicine_number}
        </Font>
      </ItemContainer>
      <ItemContainer>
        <Description>
          <Font fontType="body1" color="fill/medium">
            약품명
          </Font>
        </Description>
        <Text>{medicine_name}</Text>
      </ItemContainer>
      <ItemContainer>
        <Description>
          <Font fontType="body1" color="fill/medium">
            투약 정보
          </Font>
        </Description>
        <Text>{`${one_dose}/${daily_doses}/${total_days_taken})`}</Text>
      </ItemContainer>
    </Container>
  );
}

export default MedicineInfoItem;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-self: stretch;
`;

const Text = styled.div`
  overflow: hidden;
  color: #474a52;
  text-overflow: ellipsis;

  font-family: Pretendard, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
`;

const Description = styled.div`
  width: 80px;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 12px;
`;
