import { IconDetailProps } from '@components/atoms/images';

function ReceiptIcon({ width, height, color }: IconDetailProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M5.33333 4.00008C4.96514 4.00008 4.66667 4.29856 4.66667 4.66675C4.66667 5.03494 4.96514 5.33341 5.33333 5.33341L10.6667 5.33341C11.0349 5.33341 11.3333 5.03494 11.3333 4.66675C11.3333 4.29856 11.0349 4.00008 10.6667 4.00008L5.33333 4.00008Z"
        fill="#474A52"
      />
      <path
        d="M4.66667 7.33341C4.66667 6.96522 4.96514 6.66675 5.33333 6.66675L10.6667 6.66675C11.0349 6.66675 11.3333 6.96522 11.3333 7.33341C11.3333 7.7016 11.0349 8.00008 10.6667 8.00008L5.33333 8.00008C4.96514 8.00008 4.66667 7.7016 4.66667 7.33341Z"
        fill="#474A52"
      />
      <path
        d="M5.33333 9.33341C4.96514 9.33341 4.66667 9.63189 4.66667 10.0001C4.66667 10.3683 4.96514 10.6667 5.33333 10.6667H8.66667C9.03486 10.6667 9.33333 10.3683 9.33333 10.0001C9.33333 9.63189 9.03486 9.33341 8.66667 9.33341H5.33333Z"
        fill="#474A52"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 15.3334C12.0504 15.3334 12.1003 15.3316 12.1498 15.3279C12.2105 15.3316 12.2717 15.3334 12.3333 15.3334C13.9902 15.3334 15.3333 13.9903 15.3333 12.3334C15.3333 11.2933 14.804 10.3768 14 9.8386V2.66675C14 1.56218 13.1046 0.666748 12 0.666748H4C2.89543 0.666748 2 1.56218 2 2.66675V13.3334C2 14.438 2.89543 15.3334 4 15.3334H12ZM9.33333 12.3334C9.33333 12.9501 9.51942 13.5234 9.83852 14.0001H4C3.63181 14.0001 3.33333 13.7016 3.33333 13.3334V2.66675C3.33333 2.29856 3.63181 2.00008 4 2.00008H12C12.3682 2.00008 12.6667 2.29856 12.6667 2.66675V9.35172C12.5572 9.33963 12.446 9.33341 12.3333 9.33341C10.6765 9.33341 9.33333 10.6766 9.33333 12.3334ZM12.3333 10.6667C13.2268 10.6667 13.956 11.3698 13.9981 12.2528C13.9994 12.2795 14 12.3064 14 12.3334C14 13.2539 13.2538 14.0001 12.3333 14.0001C11.4129 14.0001 10.6667 13.2539 10.6667 12.3334C10.6667 11.4129 11.4129 10.6667 12.3333 10.6667Z"
        fill="#474A52"
      />
    </svg>
  );
}

export default ReceiptIcon;
