import {
  buttonProps,
  ButtonReturnType,
  ButtonSizeType,
  ButtonType,
  sizeProps,
} from '@components/meraki-ui/BButton/BButton.constant';
import { useBButton } from '@components/meraki-ui/BButton/useBButton';
import { BColor } from '@components/meraki-ui/BColor';
import BIcon from '@components/meraki-ui/BIcon';
import { IconType } from '@components/meraki-ui/BIcon/svg.type';
import BTypography from '@components/meraki-ui/BTypography';
import { SizeReturnType } from '@components/meraki-ui/type';
import { FocusEvent, forwardRef, memo, MouseEvent, useMemo } from 'react';

import BeatLoader from 'react-spinners/BeatLoader';
import styled from 'styled-components';

interface BButtonProps {
  text: string;

  buttonType: ButtonType;
  size: ButtonSizeType;

  zIndex?: number;
  height?: number;
  isLoading?: boolean;
  isDisabled?: boolean;
  icon?: IconType;
  iconWidth?: number;
  iconPosition?: 'left' | 'right';
  fitText?: boolean;

  onClick?: (
    e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
  ) => void | Promise<void>;

  onFocus?: (e: FocusEvent<HTMLButtonElement, Element>) => void;
  onBlur?: (e: FocusEvent<HTMLButtonElement, Element>) => void;
}

const BButton = forwardRef<HTMLButtonElement, BButtonProps>(
  function ForwardedButton(
    {
      text,
      height,
      buttonType,
      icon,
      zIndex,
      isLoading,
      isDisabled,
      iconWidth,
      iconPosition = 'left',
      fitText = true,
      size,
      onClick,
      onFocus,
      onBlur,
    },
    ref,
  ) {
    const {
      backgroundColor,
      textColor,
      iconHoverColor,
      iconActiveColor,
      textHoverColor,
      textActiveColor,
      backgroundHoverColor,
      backgroundActiveColor,
      iconColor,
      padding,
      fontSize,
      border,
      hoverBorder,
    } = useMemo(() => {
      return { ...buttonProps[buttonType], ...sizeProps[size] };
    }, [buttonType, size]);

    const {
      // currentTextColor,
      // currentIconColor,
      onMouseOver,
      onMouseLeave,
      onMouseDownCapture,
      onMouseUpCapture,
    } = useBButton({
      textColor,
      iconHoverColor,
      iconActiveColor,
      textHoverColor,
      textActiveColor,
      iconColor,
    });

    const onButtonClick = useMemo(
      () => (isDisabled ? () => {} : onClick),
      [isDisabled, onClick],
    );

    return (
      <ButtonContainer
        ref={ref}
        buttonType={buttonType}
        height={height}
        width={size === 'heightForFullWidth' ? '100%' : undefined}
        size={size}
        isDisabled={isDisabled}
        zIndex={zIndex}
        onMouseOver={onMouseOver}
        onMouseDownCapture={onMouseDownCapture}
        onMouseUpCapture={onMouseUpCapture}
        onMouseLeave={onMouseLeave}
        onClick={onButtonClick}
        onFocus={onFocus}
        onBlur={onBlur}
        backgroundHoverColor={backgroundHoverColor}
        backgroundActiveColor={backgroundActiveColor}
        backgroundColor={backgroundColor}
        padding={padding}
        border={border}
        hoverBorder={hoverBorder}
      >
        {isLoading ? (
          <BeatLoader size={fontSize / 2} loading color={BColor.primary05} />
        ) : (
          <>
            {icon && iconPosition === 'left' && (
              <BIcon
                icon={icon}
                width={iconWidth ?? icon ? 20 : 0}
                color={iconColor ?? 'mono08'}
              />
            )}

            <BTypography
              text={text}
              color={textColor}
              size={fontSize}
              isBold
              fitText={fitText}
            />

            {icon && iconPosition === 'right' && (
              <BIcon
                icon={icon}
                width={iconWidth ?? 0}
                color={iconColor ?? 'mono08'}
              />
            )}
          </>
        )}
      </ButtonContainer>
    );
  },
);

const ButtonContainer = styled.button<
  Pick<
    BButtonProps,
    'buttonType' | 'size' | 'height' | 'isDisabled' | 'zIndex'
  > &
    Pick<
      ButtonReturnType,
      | 'backgroundColor'
      | 'backgroundHoverColor'
      | 'border'
      | 'hoverBorder'
      | 'backgroundActiveColor'
    > &
    Pick<SizeReturnType, 'padding'> & { width?: string }
>`
  display: flex;
  align-items: center;
  cursor: ${(props) => (props.isDisabled ? 'default' : 'pointer')};
  justify-content: center;
  width: ${(props) => props.width};
  height: ${(props) => `${props.height}px`};

  background-color: ${(props) => BColor[props.backgroundColor]};

  gap: 0.25rem;
  padding: ${(props) => props.padding};

  border: ${(props) => (props.border ? props.border : 0)};
  border-radius: ${(props) => (props.size === 'chip' ? '0.25rem' : '0.5rem')};

  z-index: ${(props) => props.zIndex};

  &:hover {
    background-color: ${(props) =>
      props.backgroundHoverColor
        ? BColor[props.backgroundHoverColor]
        : BColor[props.backgroundColor]};

    border: ${(props) => props.hoverBorder ?? props.border ?? 0};
  }

  &:active {
    background-color: ${(props) =>
      props.backgroundActiveColor && BColor[props.backgroundActiveColor]};
  }

  &:focus {
    outline: none;
  }

  transition: all 0.2s ease-out;
`;

export default memo(BButton);
