import useFinishTelepharmacyModalHook from './hooks';
import FinishTelepharmacyModalTemplate from './templates';

function FinishTelepharmacyModal() {
  const {
    isPageLoading,
    isVisible,
    item,
    closeModal,
    finishTelepharmacy,
    internal,
    setInternal,
    external,
    setExternal,
    injection,
    setInjection,
    medicine_day_num,
    onChangeMedicine_day_num,
    setMedicine_day_num,
    isMedicine_day_num_not_decided_yet,
    setIsMedicine_day_num_not_decided_yet,
    morning,
    setMorning,
    lunch,
    setLunch,
    dinner,
    setDinner,
    beforeSleep,
    setBeforeSleep,
    medicine_period_num,
    setMedicine_period_num,
    whenever_patient_want,
    setWhenever_patient_want,
    is_directly_input,
    setIs_Directly_input,
    is_supplement_info_exposed,
    setIs_supplement_info_exposed,
    before_meal,
    setBefore_meal,
    after_meal,
    setAfter_meal,
    supplement_info,
    setSupplement_info,
    onChangeSupplement_info,
    buttonVisible,
    getMedicineGuideData,
  } = useFinishTelepharmacyModalHook();

  return (
    <FinishTelepharmacyModalTemplate
      isPageLoading={isPageLoading}
      isVisible={isVisible}
      item={item}
      closeModal={closeModal}
      finishTelepharmacy={finishTelepharmacy}
      internal={internal}
      setInternal={setInternal}
      external={external}
      setExternal={setExternal}
      injection={injection}
      setInjection={setInjection}
      medicine_day_num={medicine_day_num}
      onChangeMedicine_day_num={onChangeMedicine_day_num}
      setMedicine_day_num={setMedicine_day_num}
      isMedicine_day_num_not_decided_yet={isMedicine_day_num_not_decided_yet}
      setIsMedicine_day_num_not_decided_yet={
        setIsMedicine_day_num_not_decided_yet
      }
      morning={morning}
      setMorning={setMorning}
      lunch={lunch}
      setLunch={setLunch}
      dinner={dinner}
      setDinner={setDinner}
      beforeSleep={beforeSleep}
      setBeforeSleep={setBeforeSleep}
      medicine_period_num={medicine_period_num}
      setMedicine_period_num={setMedicine_period_num}
      whenever_patient_want={whenever_patient_want}
      setWhenever_patient_want={setWhenever_patient_want}
      is_directly_input={is_directly_input}
      setIs_Directly_input={setIs_Directly_input}
      is_supplement_info_exposed={is_supplement_info_exposed}
      setIs_supplement_info_exposed={setIs_supplement_info_exposed}
      before_meal={before_meal}
      setBefore_meal={setBefore_meal}
      after_meal={after_meal}
      setAfter_meal={setAfter_meal}
      supplement_info={supplement_info}
      setSupplement_info={setSupplement_info}
      onChangeSupplement_info={onChangeSupplement_info}
      buttonVisible={buttonVisible}
      getMedicineGuideData={getMedicineGuideData}
    />
  );
}

export default FinishTelepharmacyModal;
