import { Button, COLOR, Font, TextButton } from '@components/atoms';
import {
  ModalCommonStructure as Modal,
  PageLoader,
  TextButtonWithTextArea,
} from '@components/molecules';
import { featureHooks } from '@hooks';
import { usePrescriptionDetailList } from '@hooks/featureHooks/useFetchPrescriptionDetailList';
import { notifyOtherClients } from '@hooks/featureHooks/useSocket';
import { myDoctorAPI } from '@services/myDoctor';
import { useAppDispatch, useAppSelector } from '@stores/hooks';
import {
  closeModal_rejectTelepharmacyModal,
  selectRejectTelepharmacyModalItem,
  selectRejectTelepharmacyModalVisible,
} from '@stores/modalStore/rejectTelepharmacyModal';
import { getTelepharmacyCount } from '@stores/telepharmacyStore/telepharmacyCount';
import { getTelepharmacyList_requested } from '@stores/telepharmacyStore/telepharmacyList';
import { FormEvent, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useMedicineSoldOutAlertModalHook from '../medicineSoldOutAlertModal/hooks';
import * as Style from './index.style';

const wegovyMasterCodes = ['654400661', '654400671', '654400681']; // .25 .5 1.0

const REJECT_RADIO_NAME = 'rejectRadio';

function RejectTelepharmacyModal() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { useFetchCancelReason } = featureHooks;
  const {
    openModal: openMedicineSoldOutAlertModal,
    setMedicineSoldOutAlertTargetMedicine,
  } = useMedicineSoldOutAlertModalHook();
  const cancelReasonList = useFetchCancelReason({ type: 'TP' });

  const [selectedId, setSelectedId] = useState<number>();
  const isOpen = useAppSelector(selectRejectTelepharmacyModalVisible);
  const item = useAppSelector(selectRejectTelepharmacyModalItem);

  const [isPageLoading, setIsPageLoading] = useState(false);

  const prescriptionId = item.id;
  const tmId = item.tm?.id;

  const { data: prescriptionDetailList } = usePrescriptionDetailList({
    tmId,
    prescriptionId,
  });

  const shouldShowWegovySoldOutAlert = useMemo(() => {
    if (!prescriptionDetailList) return false;

    const isSoldOutReason = selectedId === 9 || selectedId === 100; // 재고 없음, 기타
    const isWegovyPrescription = wegovyMasterCodes.some((wegovyMasterCode) => {
      return wegovyMasterCode === prescriptionDetailList[0]?.drug_code;
    });

    return isSoldOutReason && isWegovyPrescription;
  }, [prescriptionDetailList, selectedId]);

  const [rejectReason, setRejectReason] = useState('');
  const [prescriptionProblemReason, setPrescriptionProblemReason] =
    useState('');
  const [extraReason, setExtraReason] = useState('');

  const selectReject = (choosedId: number, text: string) => {
    setSelectedId(choosedId);
    setRejectReason(text);
    setPrescriptionProblemReason('');
    setExtraReason('');
  };

  const selectPrescriptionProblemReject = (choosedId: number, text: string) => {
    setSelectedId(choosedId);
    setRejectReason('');
    setPrescriptionProblemReason(text);
    setExtraReason('');
  };

  const selectExtraReject = (choosedId: number, text: string) => {
    setSelectedId(choosedId);
    setRejectReason('');
    setPrescriptionProblemReason('');
    setExtraReason(text);
  };

  const onChangePrescriptionProblemReason = (
    e: FormEvent<HTMLTextAreaElement>,
  ) => {
    setPrescriptionProblemReason(e.currentTarget.value);
  };

  const onChangeExtraReason = (e: FormEvent<HTMLTextAreaElement>) => {
    setExtraReason(e.currentTarget.value);
  };

  const closeModal = () => {
    setIsPageLoading(false);
    dispatch(closeModal_rejectTelepharmacyModal());
    setRejectReason('');
    setPrescriptionProblemReason('');
    setExtraReason('');
    setSelectedId(undefined);
  };

  const reject = () => {
    if (
      window.confirm(
        `${item.tm?.patient?.name} 환자의 조제를 거절하시겠습니까?`,
      )
    ) {
      setIsPageLoading(true);

      let reason;
      if (selectedId === 100) {
        reason = extraReason;
      } else if (selectedId === 101) {
        reason = `처방전에 문제가 있어 병원에 다시 확인이 필요합니다. ${prescriptionProblemReason}`;
      } else {
        reason = rejectReason;
      }
      const id = item.id ? item.id : 0;

      myDoctorAPI
        .postRejectTelepharmacy({ reject_reason: reason }, id)
        .then((responseJSON) => {
          if (responseJSON.id) {
            alert('조제를 거절했습니다.');
            closeModal();
            if (shouldShowWegovySoldOutAlert) {
              setMedicineSoldOutAlertTargetMedicine('reject');
              openMedicineSoldOutAlertModal();
            }
            Promise.all([
              dispatch(getTelepharmacyCount()),
              dispatch(getTelepharmacyList_requested({ limit: 60, offset: 0 })),
            ]).then(() => {
              setTimeout(() => navigate('/home/newReservation/requested'), 700);
              notifyOtherClients('telepharmacy');
            });
          } else {
            alert('요청에 실패했습니다.');
            window.location.reload();
          }
        })
        .finally(() => setIsPageLoading(false));
    }
  };

  const buttonVisible =
    rejectReason.length >= 1 ||
    prescriptionProblemReason.length >= 1 ||
    extraReason.length >= 1;

  return (
    <>
      {isPageLoading && <PageLoader />}
      <Modal
        isOpen={isOpen}
        onRequestClose={() => closeModal()}
        modalHeader={
          <Style.TitleArea>
            <Font fontType="h1" color="fill/dark">
              조제 거절
            </Font>
          </Style.TitleArea>
        }
        modalBody={
          <>
            <Style.DescriptionArea>
              <Font fontType="h3" color="fill/black">
                취소 사유
              </Font>
              <Style.RequireContainer>
                <Font fontType="body3_medium" color="blue500">
                  필수
                </Font>
              </Style.RequireContainer>
            </Style.DescriptionArea>
            <Style.RejectButtonContainer>
              {cancelReasonList.map((cancelReasonItem) => (
                <Style.TextButtonArea key={cancelReasonItem.id}>
                  <TextButton
                    radioName={REJECT_RADIO_NAME}
                    isSelected={selectedId === cancelReasonItem.id}
                    title={cancelReasonItem.text}
                    onClick={() =>
                      selectReject(cancelReasonItem.id, cancelReasonItem.text)
                    }
                    key={String(cancelReasonItem.id)}
                  />
                </Style.TextButtonArea>
              ))}
              <Style.TextButtonArea>
                <TextButtonWithTextArea
                  radioName={REJECT_RADIO_NAME}
                  isSelected={selectedId === 101}
                  title="처방전에 문제가 있어 병원에 다시 확인이 필요합니다."
                  onClick={() =>
                    selectPrescriptionProblemReject(
                      101,
                      prescriptionProblemReason,
                    )
                  }
                  key={String(101)}
                  value={prescriptionProblemReason}
                  onChange={onChangePrescriptionProblemReason}
                  type="text"
                  placeholder="(예시)&#13;&#10;ㆍ 주민등록번호가 이상합니다.&#13;&#10;ㆍ 처방전 파일이 비어있습니다.&#13;&#10;ㆍ 다른 환자의 처방전입니다."
                  height={120}
                  fontSize="16px"
                />
              </Style.TextButtonArea>
              <Style.TextButtonArea style={{ marginBottom: '0' }}>
                <TextButtonWithTextArea
                  radioName={REJECT_RADIO_NAME}
                  isSelected={selectedId === 100}
                  title="기타"
                  onClick={() => selectExtraReject(100, extraReason)}
                  key={String(100)}
                  value={extraReason}
                  onChange={onChangeExtraReason}
                  type="text"
                  placeholder="진료 거절 사유를 입력해주세요."
                  height={120}
                  fontSize="16px"
                />
              </Style.TextButtonArea>
            </Style.RejectButtonContainer>
          </>
        }
        modalFooter={
          <Style.FooterContainer>
            <Button
              width="204px"
              padding="16px 12px"
              borderRadius="12px"
              onClick={() => closeModal()}
              backgroundColor={COLOR['fill/white']}
              borderColor={COLOR['border/outline']}
              title={
                <Style.ButtonContainer>
                  <Font fontType="body1" color="fill/dark">
                    취소
                  </Font>
                </Style.ButtonContainer>
              }
            />
            <Button
              width="204px"
              padding="16px 12px"
              borderRadius="12px"
              onClick={() => reject()}
              disableColor={COLOR['fill/medium']}
              backgroundColor={COLOR['state/distructive']}
              visible={buttonVisible}
              title={
                <Style.ButtonContainer>
                  <Font fontType="body1" color="fill/white">
                    거절하기
                  </Font>
                </Style.ButtonContainer>
              }
            />
          </Style.FooterContainer>
        }
      />
    </>
  );
}

export default RejectTelepharmacyModal;
