import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '@stores';
import { NoticeModalType } from './index.d';

const initialState: NoticeModalType = {
  isVisible: false,
};

export const noticeModal = createSlice({
  name: 'modal/noticeModal',
  initialState,
  reducers: {
    openModal_noticeModal: (state) => {
      state.isVisible = true;
    },
    closeModal_noticeModal: (state) => {
      state.isVisible = false;
    },
  },
});

export const { openModal_noticeModal, closeModal_noticeModal } =
  noticeModal.actions;

export const selectNoticeModalVisible = (state: RootState) =>
  state.noticeModal.isVisible;

export default noticeModal.reducer;
