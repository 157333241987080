import { COLOR } from '@components/atoms';
import styled from 'styled-components';

export const TitleArea = styled.div``;

export const DescriptionArea = styled.div`
  display: flex;
  gap: 4px;
  margin-bottom: 12px;
`;

export const TextButtonArea = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const CancelButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FooterContainer = styled.div`
  display: flex;
  gap: 12px;
  padding-top: 30px;
  border-top: 1px solid ${COLOR['border/divider']};
`;

export const RequireContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 2px 8px;
  border-radius: 4px;
  background-color: ${COLOR['label/lightBlue']};
`;
