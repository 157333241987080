import { ReactBootstrapIcons } from '@assets';
import { Blank, Button, COLOR, Font, TextInput } from '@components/atoms';
import {
  CallOutMessage,
  ModalCommonStructure as Modal,
} from '@components/molecules';
import { ChangeServerURLModalTemplateType } from './index.d';
import * as Style from './index.style';

function ChangeServerURLModalTemplate({
  isVisible,
  serverURLText,
  onChangeServerURLText,
  buttonVisible,
  closeModal,
  setServerURL,
  resetServerURL,
}: ChangeServerURLModalTemplateType) {
  return (
    <Modal
      isOpen={isVisible}
      onRequestClose={() => closeModal()}
      modalHeader={
        <Style.TitleArea>
          <Font fontType="display2">서버 설정하기</Font>
        </Style.TitleArea>
      }
      modalBody={
        <>
          <Style.DescriptionArea>
            <CallOutMessage>
              <Font fontType="body1">변경하실 서버 주소를 입력해주세요.</Font>
            </CallOutMessage>
          </Style.DescriptionArea>
          <Style.TextInputArea>
            <Style.TextInputContent>
              <TextInput
                value={serverURLText}
                type="text"
                onChange={onChangeServerURLText}
                fontSize={16}
                placeholder="ex) https://dev-api.merakiplace.work/"
              />
            </Style.TextInputContent>
          </Style.TextInputArea>
        </>
      }
      modalFooter={
        <>
          <Button
            onClick={() => resetServerURL()}
            backgroundColor={COLOR.green500}
            visible
            width="140px"
            title={
              <Style.ButtonContainer>
                <ReactBootstrapIcons.Check2 color={COLOR['fill/white']} />
                <Blank appoint="Horizontal" size={4} />
                <Font fontType="body2" color="fill/white">
                  기존 서버로 변경
                </Font>
              </Style.ButtonContainer>
            }
          />
          <Blank appoint="Horizontal" size={8} />
          <Button
            onClick={() => setServerURL()}
            disableColor={COLOR['fill/medium']}
            backgroundColor={COLOR['fill/accent']}
            visible={buttonVisible}
            width="128px"
            title={
              <Style.ButtonContainer>
                <ReactBootstrapIcons.Check2 color={COLOR['fill/white']} />
                <Blank appoint="Horizontal" size={4} />
                <Font fontType="body2" color="fill/white">
                  서버 설정하기
                </Font>
              </Style.ButtonContainer>
            }
          />
        </>
      }
    />
  );
}

export default ChangeServerURLModalTemplate;
