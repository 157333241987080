import { coldChainImage, parcelImage, pickupImage, quickImage } from '@assets';
import { Button, COLOR, Font } from '@components/atoms';
import SvgIcon from '@components/atoms/images';
import ListContent from '@components/organisms/telepharmacyTable/components/listContent';
import {
  convertSubstitutedPatientStatus,
  convertSubstitutedPharmacyStatus,
} from '@components/organisms/utils/domain';
import { commonCode } from '@constants';
import { formatters } from '@functions';
import { commonHooks } from '@hooks';
import { myDoctorAPI } from '@services';
import printJS from 'print-js';
import { MouseEvent } from 'react';
import { MyDoctorModelType } from 'types/index.d';
import { LISTITEM_FLEX } from '../../index';
import StatusTag from '../statusTag';
import * as Style from './index.style';

const { COMMON_CODE } = commonCode;

function ListItem({
  item,
}: {
  item: MyDoctorModelType.TelepharmacyModelType.TelepharmacyListItemModelType;
}) {
  const { phoneFormatter, priceFormatter } = formatters;
  const { useWindowSize } = commonHooks;
  const { windowWidth } = useWindowSize();

  let deliveryType;
  let iconURL;

  switch (item.method) {
    case COMMON_CODE.TP_METHOD.PARCEL:
      deliveryType = '택배';
      iconURL = parcelImage;
      break;
    case COMMON_CODE.TP_METHOD.PICKUP:
      deliveryType = '방문수령';
      iconURL = pickupImage;
      break;
    case COMMON_CODE.TP_METHOD.QUICK:
    case COMMON_CODE.TP_METHOD.ECONOMY_QUICK:
      deliveryType = '퀵';
      iconURL = quickImage;
      break;
    case COMMON_CODE.TP_METHOD.COLD_CHAIN:
      deliveryType = '냉장';
      iconURL = coldChainImage;
      break;
    default:
      deliveryType = '택배';
      iconURL = parcelImage;
      break;
  }

  const fetchPrescriptionData = async (tp_id: number | undefined) => {
    const { data } = await myDoctorAPI.getTelepharmacyTpprocessPrescriptions(
      Number(tp_id),
    );
    return data;
  };

  const isSubstitutedTextColorRed = item.is_substituted_patient === false;

  return (
    <Style.ListItem
      to={`/home/newReservation/accepted/newReservationAcceptedDetail?id=${item.id}`}
    >
      <ListContent.Element flex={LISTITEM_FLEX[0].flex}>
        <Style.ListItemLayout>
          <StatusTag status={item.status} paymentStatus={item.payment_status} />
        </Style.ListItemLayout>
      </ListContent.Element>

      <ListContent.Element flex={LISTITEM_FLEX[1].flex}>
        <Style.ListItemLayout>
          <Font fontType="body2">{deliveryType}</Font>
        </Style.ListItemLayout>
      </ListContent.Element>

      <ListContent.Element flex={LISTITEM_FLEX[2].flex}>
        <Style.SubstitutedArea>
          <Font
            fontType="body2"
            color={
              isSubstitutedTextColorRed ? 'state/distructive' : 'fill/black'
            }
          >
            {`환자 : ${convertSubstitutedPatientStatus(
              item.is_substituted_patient,
            )}`}
          </Font>
          <Font
            fontType="body2"
            color={
              isSubstitutedTextColorRed ? 'state/distructive' : 'fill/black'
            }
          >
            {`약사 : ${convertSubstitutedPharmacyStatus(
              item.is_substituted_pharmacy,
            )}`}
          </Font>
        </Style.SubstitutedArea>
      </ListContent.Element>

      <ListContent.Element flex={LISTITEM_FLEX[3].flex}>
        <Style.ListItemLayout>
          <Style.PatientInfoRow>
            <Font fontType="body2_medium" color="fill/black">
              {item.patient?.name}
            </Font>
            <Font fontType="body3" color="fill/medium">
              {item.telemedicine?.telemedicine_hash}
            </Font>
          </Style.PatientInfoRow>
        </Style.ListItemLayout>
      </ListContent.Element>

      <ListContent.Element flex={LISTITEM_FLEX[4].flex}>
        <div style={{ padding: '0px 16px' }}>
          <Font fontType="body2">
            {phoneFormatter.prettyPhoneNumberFormatter(
              item.patient?.phone || item.requester?.phone,
            ) || '-'}
          </Font>
        </div>
      </ListContent.Element>

      <ListContent.Element flex={LISTITEM_FLEX[5].flex}>
        <Style.ListItemLayout>
          <Font fontType="body2">
            {`${priceFormatter.commaFormatter(
              item.amount ? `${item.amount?.toString()}원` : '-',
            )}`}
          </Font>
        </Style.ListItemLayout>
      </ListContent.Element>

      <Style.ListButtonItem
        flex={LISTITEM_FLEX[6].flex}
        flexDirection="row"
        border=""
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '8px',
            marginLeft: '16px',
          }}
        >
          {item.has_subscription ? (
            <Button
              height="32px"
              borderColor={COLOR['border/outline']}
              backgroundColor={COLOR['fill/white']}
              borderRadius="4px"
              title={
                <>
                  <Style.IconButtonItem
                    data-for={`showToolTipPrint_${item.id}`}
                    data-tip
                  >
                    <SvgIcon
                      icon="faxIcon"
                      width={16}
                      height={16}
                      color="fill/dark"
                    />
                    <Font fontType="body2" color="fill/dark">
                      인쇄
                    </Font>
                  </Style.IconButtonItem>
                </>
              }
              onClick={async (event: MouseEvent<HTMLButtonElement>) => {
                event.stopPropagation();
                event.preventDefault();
                // 처방전 수정기능 이후엔 멀쩡한 처방전으로 출력하는 기능이 추가되어야함.
                const prescriptionData = await fetchPrescriptionData(item.id);
                printJS({
                  printable: prescriptionData[0].image,
                  type: 'pdf',
                  showModal: true,
                });
              }}
            />
          ) : null}
          {item.has_subscription ? (
            <Button
              height="32px"
              borderColor={COLOR['border/outline']}
              backgroundColor={COLOR['fill/white']}
              borderRadius="4px"
              title={
                <>
                  <Style.IconButtonItem
                    data-for={`showToolTipPdf_${item.id}`}
                    data-tip
                  >
                    <SvgIcon
                      icon="receiptIcon"
                      width={16}
                      height={16}
                      color="fill/dark"
                    />
                    <Font fontType="body2" color="fill/dark">
                      처방전
                    </Font>
                  </Style.IconButtonItem>
                </>
              }
              onClick={async (event: MouseEvent<HTMLButtonElement>) => {
                event.stopPropagation();
                event.preventDefault();
                // 처방전 수정기능 이후엔 멀쩡한 처방전으로 출력하는 기능이 추가되어야함.
                const prescriptionData = await fetchPrescriptionData(item.id);
                window.open(prescriptionData ? prescriptionData[0].image : '');
              }}
            />
          ) : null}
        </div>
      </Style.ListButtonItem>
    </Style.ListItem>
  );
}

export default ListItem;
