import { BColor } from '@components/meraki-ui/BColor';
import { IconDetailProps } from '@components/meraki-ui/BIcon/svg.type';

export default function WeightIcon({ width, height, color }: IconDetailProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 14.5C18 15.3284 17.3284 16 16.5 16L3.5 16C2.67157 16 2 15.3284 2 14.5L2 5.5C2 4.67157 2.67157 4 3.5 4L16.5 4C17.3284 4 18 4.67157 18 5.5L18 14.5ZM16.5 15C16.7761 15 17 14.7761 17 14.5L17 5.5C17 5.22386 16.7761 5 16.5 5L3.5 5C3.22386 5 3 5.22386 3 5.5L3 14.5C3 14.7761 3.22386 15 3.5 15L16.5 15Z"
        fill={BColor[color]}
      />
      <path
        d="M10 9.5C10 9.77614 9.77614 10 9.5 10L4.5 10C4.22386 10 4 9.77614 4 9.5L4 6.5C4 6.22386 4.22386 6 4.5 6L9.5 6C9.77614 6 10 6.22386 10 6.5L10 9.5Z"
        fill={BColor[color]}
      />
    </svg>
  );
}
