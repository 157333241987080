import { mobileParcel, mobilePickup, mobileQuick } from '@assets/index';
import ChevronRight from '@assets/svg/ChevronRight';
import Badge from '@mobile/components/common/Badge';
import { formatDate } from '@mobile/pages/TPDetail/utils';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

type Props = {
  index: number;
  deliveryMethod?: string;
  patientName?: string;
  reservationCode?: string;
  rejectTime?: string;
  symptom?: string;
  tppId: number;
  prescriptionId: number;
};

function RequestItem({
  index,
  deliveryMethod = '-',
  patientName = '-',
  rejectTime = '-',
  reservationCode = '-',
  symptom = '-',
  tppId,
  prescriptionId,
}: Props) {
  const navigate = useNavigate();

  const renderImage = () => {
    if (deliveryMethod === 'PARCEL') return mobileParcel;
    if (deliveryMethod === 'QUICK') return mobileQuick;
    return mobilePickup;
  };

  const goToDetail = () => {
    return navigate(`/mobile/request/detail/${tppId}`, {
      state: { prescriptionId },
    });
  };

  return (
    <Wrapper index={index} onClick={goToDetail}>
      <Container>
        <RejectTime>{`${formatDate(rejectTime)} 만료 예정`}</RejectTime>
        <InfoContainer>
          <Image src={renderImage()} style={{ width: 56, height: 56 }} />
          <Info>
            <PatientInfo>
              <NameWrapper>
                <Name>{`${patientName} 환자`}</Name>
                <ReservationCode>
                  {reservationCode.toUpperCase().split('-')[0]}
                </ReservationCode>
              </NameWrapper>
              <ChevronRight />
            </PatientInfo>
            <BadgeArea>
              <Badge type="default" text={symptom} />
            </BadgeArea>
          </Info>
        </InfoContainer>
      </Container>
    </Wrapper>
  );
}

export default RequestItem;

const Wrapper = styled.div<{ index: number }>`
  padding: 20px;
  border-top: ${({ index }) =>
    `1px solid ${index !== 0 ? '#F1F2F4' : '#FFFFFF'}`};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
`;

const RejectTime = styled.div`
  color: #9fa1a8;

  font-family: Pretendard, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  align-self: stretch;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
`;

const Image = styled.img`
  width: 48px;
  height: 56px;
`;

const PatientInfo = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const NameWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const Name = styled.div`
  color: #1e2024;

  font-family: Pretendard, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
`;

const BadgeArea = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const ReservationCode = styled.div`
  color: #9fa1a8;

  font-family: Pretendard, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.32px;
`;
