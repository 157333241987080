import usePharmacyInfo from '@mobile/api/hooks/usePharmacyInfo';
import useTPProcessCount from '@mobile/api/hooks/useTPprocessCount';
import AuthError from '@mobile/pages/AuthError';
import { MouseEventHandler, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

type Props = {
  onToggle: () => void;
};

function PharmacyInfo({ onToggle }: Props) {
  const navigate = useNavigate();

  const {
    data: countData,
    isError: isTPProcessCountError,
    error: tPPProcessCountError,
  } = useTPProcessCount();
  const { data: pharmacyInfo } = usePharmacyInfo();
  const isPharmacyOpen = pharmacyInfo ? pharmacyInfo.is_open_now : false;

  const handleEventBubbling: MouseEventHandler = (event) => {
    event.stopPropagation();
  };

  const goToCustomerCenter = () => {
    onToggle();

    if (window) {
      const w: any = window;

      try {
        w.ChannelIO('showMessenger');
      } catch (e) {
        w.open('https://my-doctor-pt.channel.io/lounge', '_blank');
      }
    }
  };

  const goToAnnouncement = () => {
    onToggle();

    return window.open('https://mydoctor-notice-p.oopy.io/');
  };

  const goToRequestList = () => {
    onToggle();

    return navigate('/mobile/request');
  };

  useEffect(() => {
    if (isTPProcessCountError) {
      if (tPPProcessCountError instanceof AuthError) {
        navigate('/mobile/auth-error');
      }
    }
  }, [isTPProcessCountError, navigate, tPPProcessCountError]);

  return (
    <Container onClick={onToggle}>
      <Wrapper onClick={handleEventBubbling}>
        <DescriptionSection>
          <PharmacyName>{pharmacyInfo?.pharmacy_name}</PharmacyName>
          <WorkingTime>{pharmacyInfo?.working_time_display_text}</WorkingTime>
        </DescriptionSection>

        <OnOffBadge isOn={isPharmacyOpen}>
          {isPharmacyOpen ? 'ON' : 'OFF'}
        </OnOffBadge>
      </Wrapper>
      <ItemList onClick={handleEventBubbling}>
        <Item onClick={goToRequestList}>
          접수대기 <WaitingCount>{countData?.requested}</WaitingCount>
        </Item>
        <Item onClick={goToAnnouncement}>공지사항</Item>
        <Item onClick={goToCustomerCenter}>고객센터</Item>
      </ItemList>
    </Container>
  );
}

export default PharmacyInfo;

const Container = styled.div`
  position: fixed;
  top: var(--header-height);
  left: 0;
  right: 0;
  height: 100vh;
  height: 100dvh;
  background-color: rgba(30, 32, 36, 0.6);
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  padding: 20px;
  background-color: white;
`;

const DescriptionSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  flex: 1 0 0;
`;

const PharmacyName = styled.div`
  color: #1e2024;

  font-family: Pretendard, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  align-self: stretch;
`;

const WorkingTime = styled.div`
  color: #9fa1a8;

  font-family: Pretendard, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  align-self: stretch;
`;

const OnOffBadge = styled.div<{ isOn: boolean }>`
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: ${({ isOn }) => (isOn ? '#00c785' : '#FFB200')};

  color: #ffffff;
  font-family: Pretendard, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
`;

const ItemList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 20px 20px 20px;
  gap: 8px;
  background-color: #ffffff;
`;

const Item = styled.div`
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  gap: 4px;
  padding: 8px 0px;

  color: #1e2024;

  font-family: Pretendard, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  cursor: pointer;
`;

const WaitingCount = styled.div`
  color: #2f6eff;

  font-family: Pretendard, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
`;
