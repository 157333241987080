import { Button, COLOR, Font } from '@components/atoms';
import SvgIcon from '@components/atoms/images';
import BModal from '@components/meraki-ui/BModal';
import {
  medicineCategoryList,
  medicineGenericCodeKoreanUnit,
  medicineGenericCodeUnit,
  medicineNameConfig,
  searchMedicineList,
} from '@components/organisms/managementMedicineTable/config/domain';
import { commonHooks } from '@hooks';
import { myDoctorAPI } from '@services/myDoctor';
import { ManagedMedicineItemType } from '@services/myDoctor/types';
import { useAppDispatch, useAppSelector } from '@stores/hooks';
import {
  getManagementMedicineList,
  selectMedicineManagementList,
} from '@stores/telepharmacyStore/telepharmacyList';
import { selectUserInformationPharmacistData } from '@stores/userInformationStore';
import { useEffect, useState } from 'react';
import MedicineCategory from './components/medicineCategory';
import MedicineSearch from './components/medicineSearch';
import MedicineUnitPrice from './components/medicineUnitPrice';
import useMedicineManagementModalHook from './hooks';
import * as Style from './index.style';

export interface SelectedMedicineListFilteredByMasterCode
  extends ManagedMedicineItemType {
  price?: number | null;
  isDropDownOpen?: boolean;
  isAlreadyExist?: boolean;
}

function MedicineManagementModal() {
  const pharmacist = useAppSelector(selectUserInformationPharmacistData);
  const {
    isVisible,
    targetMedicine,
    closeModal: closeRegisterModal,
  } = useMedicineManagementModalHook();
  const managementMedicineList = useAppSelector(selectMedicineManagementList);

  const [selectedMedicineCategory, setSelectedMedicineCategory] = useState(
    medicineCategoryList[0],
  );

  const [selectedMedicine, setSelectedMedicine] =
    useState<ManagedMedicineItemType | null>(null);
  const [medicineSearchInputValue, setMedicineSearchInputValue] = useState('');
  const [medicinePriceRowList, setMedicinePriceRowList] = useState<
    SelectedMedicineListFilteredByMasterCode[]
  >([]);
  const [
    selectedMedicineListFilteredByMasterCode,
    setSelectedMedicineListFilteredByMasterCode,
  ] = useState<SelectedMedicineListFilteredByMasterCode[]>([]);
  const [isShowMedicineUnitPrice, setIsShowMedicineUnitPrice] = useState(false);
  const [selectMedicineDefaultUnit, setSelectMedicineDefaultUnit] =
    useState('');
  const [selectMedicineUnits, setSelectMedicineUnits] = useState<string[]>([]);
  const [isMedicineSumInputAreaFocus, setIsMedicineSumInputAreaFocus] =
    useState(false);

  const [isDisabledAddMedicineButton, setIsDisabledAddMedicineButton] =
    useState(true);

  useEffect(() => {
    if (targetMedicine && targetMedicine?.id) {
      const findMedicineCategoryList = medicineCategoryList.find((item) =>
        item.codes.includes(targetMedicine.pharmacy_medicine.generic_name_code),
      );

      if (findMedicineCategoryList) {
        setSelectedMedicineCategory(findMedicineCategoryList);
      }

      setSelectedMedicine({
        id: targetMedicine.id,
        standard_code: targetMedicine.pharmacy_medicine.standard_code,
        generic_name_code: targetMedicine.pharmacy_medicine.generic_name_code,
        korean_product_name:
          medicineNameConfig[targetMedicine.pharmacy_medicine.standard_code],
        product_total_quantity: String(targetMedicine.quantity),
        dosage_form:
          medicineGenericCodeKoreanUnit[
            targetMedicine.pharmacy_medicine.generic_name_code
          ],
        packaging_type: targetMedicine.unit,
        special_general_type: '전문의약품',
        generic_name: null,
        master_code: targetMedicine.pharmacy_medicine.master_code,
        name: targetMedicine.pharmacy_medicine.name,
        default: false,
      });
      setMedicineSearchInputValue(
        `(${targetMedicine.pharmacy_medicine.master_code}) ${
          medicineNameConfig[targetMedicine.pharmacy_medicine.standard_code]
        }`,
      );
      setIsShowMedicineUnitPrice(true);
    }
  }, [targetMedicine]);

  const handleChangeMedicineSumInput = (
    e: React.ChangeEvent<HTMLInputElement>,
    medicinePriceRow: SelectedMedicineListFilteredByMasterCode,
  ) => {
    const inputValue = e.target.value;
    const filteredValue = inputValue.replace(/[^0-9]/g, '');

    setMedicinePriceRowList((prev) =>
      prev.map((item) =>
        item.product_total_quantity === medicinePriceRow.product_total_quantity
          ? {
              ...item,
              price: filteredValue ? parseInt(filteredValue, 10) : null,
            }
          : item,
      ),
    );
  };

  const init = () => {
    setMedicineSearchInputValue('');
    setIsShowMedicineUnitPrice(false);
    setSelectedMedicine(null);
    setSelectMedicineUnits([]);
    setIsMedicineSumInputAreaFocus(false);
    setSelectedMedicineCategory(medicineCategoryList[0]);
  };

  const handleClickMedicineCategory = (medicine: {
    id: number;
    label: string;
    codes: string[];
  }) => {
    setMedicineSearchInputValue('');
    setSelectedMedicineCategory(medicine);
    setIsShowMedicineUnitPrice(false);
    setSelectedMedicine(null);
    setSelectMedicineUnits([]);
    setIsMedicineSumInputAreaFocus(false);
  };

  const { useQuery } = commonHooks;
  const query = useQuery();
  const dispatch = useAppDispatch();
  const [limit, setLimit] = useState<number>(30);
  const [isLoading, setIsLoading] = useState(false);

  const pageQuery = query.get('page');
  const page = Number(pageQuery) || 0;

  const handleChangeMedicineSearchInput = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const inputValue = e.target.value;
    setMedicineSearchInputValue(inputValue);
  };

  const handleClickSearchMedicineReset = () => {
    setSelectedMedicine(null);
    setIsShowMedicineUnitPrice(false);
  };

  const handleAddMedicine = async () => {
    if (!selectedMedicine) return;
    // if (isLoading) return;

    setIsLoading(true);

    try {
      const postManagedMedicineParam = medicinePriceRowList
        .filter((item) => !item.isAlreadyExist)
        .map((medicinePriceRow) => {
          return {
            pharmacy_medicine: {
              standard_code: medicinePriceRow.standard_code,
              name: medicinePriceRow.korean_product_name,
              is_active: true,
              master_code: medicinePriceRow.master_code,
              generic_name_code: medicinePriceRow.generic_name_code,
              pharmacy: pharmacist.pharmacy_id || 0,
            },
            unit: medicineGenericCodeUnit[medicinePriceRow.generic_name_code],
            quantity: medicinePriceRow.product_total_quantity,
            price: medicinePriceRow.price,
            is_deleted: false,
          };
        });

      await myDoctorAPI
        .postManagementMedicineList(postManagedMedicineParam)
        .then(() => {
          dispatch(
            getManagementMedicineList({
              id: pharmacist.pharmacy_id || 0,
              offset: page * limit,
              limit,
            }),
          );
        })
        .then(() => {
          setIsLoading(false);
          closeRegisterModal();
        });
    } catch (e) {
      console.log('handleAddMedicine error', e);
    }
  };

  const handleAddMedicinePriceEachUnitRow = () => {
    const differingMedicine = selectedMedicineListFilteredByMasterCode
      .sort(
        (a, b) =>
          Number(a.product_total_quantity) - Number(b.product_total_quantity),
      )
      .find(
        (selectedMedicineFilteredByMasterCode) =>
          !medicinePriceRowList.some(
            (medicinePriceRow) =>
              medicinePriceRow.product_total_quantity ===
              selectedMedicineFilteredByMasterCode.product_total_quantity,
          ),
      );

    if (differingMedicine) {
      setMedicinePriceRowList((prev) => [
        ...prev,
        { ...differingMedicine, price: null, isDropDownOpen: false },
      ]);
    }
  };

  const handleDeleteMedicinePriceEachUnitRow = (
    deletedMedicinePriceRow: SelectedMedicineListFilteredByMasterCode,
  ) => {
    setMedicinePriceRowList((prev) =>
      prev.filter(
        (item) =>
          item.product_total_quantity !==
          deletedMedicinePriceRow.product_total_quantity,
      ),
    );
  };

  const handleClickUnitDropDownOpen = (id: number) => {
    setMedicinePriceRowList((prev) => {
      return prev.map((item) => {
        if (item.id === id) {
          return { ...item, isDropDownOpen: !item.isDropDownOpen };
        }
        if (item.isDropDownOpen) {
          return { ...item, isDropDownOpen: !item.isDropDownOpen };
        }

        return item;
      });
    });
  };

  const handleClickMedicineUnit = (
    medicinePriceRow: SelectedMedicineListFilteredByMasterCode,
    selectedUnit: string,
  ) => {
    const changeMedicine = selectedMedicineListFilteredByMasterCode.find(
      (item) => item.product_total_quantity === selectedUnit,
    );

    if (changeMedicine) {
      setMedicinePriceRowList((prevList) =>
        prevList.map((item) =>
          item.id === medicinePriceRow.id
            ? {
                ...changeMedicine,
                isDropDownOpen: !item.isDropDownOpen,
                price: medicinePriceRow.price,
              }
            : item,
        ),
      );
    }
  };

  const handleSelectMedicine = (medicine: ManagedMedicineItemType) => {
    const medicineListResponseFilteredByMasterCode =
      managementMedicineList?.filter(
        (managementMedicine) =>
          managementMedicine.pharmacy_medicine.master_code ===
          medicine.master_code,
      );
    const filteredMedicineListByMasterCode = searchMedicineList.filter(
      (item) => {
        if (item.master_code === medicine.master_code) {
          return item;
        }
        return null;
      },
    );

    if (
      medicineListResponseFilteredByMasterCode?.length ===
      filteredMedicineListByMasterCode.length
    ) {
      alert(
        `${
          medicineNameConfig[medicine.standard_code]
        }의 단위별 가격을 수정하시려면 표에서 [수정] 버튼을 눌러 수정해주세요.`,
      );

      return;
    }

    setSelectedMedicine(medicine);
    setMedicineSearchInputValue(
      `(${medicine.master_code}) ${medicine.korean_product_name}`,
    );
    // setIsMedicineSearchInputFocus(false);
    setIsShowMedicineUnitPrice(true);
  };

  useEffect(() => {
    if (managementMedicineList && selectedMedicine && isShowMedicineUnitPrice) {
      const medicineListResponseFilteredByMasterCode =
        managementMedicineList?.filter(
          (managementMedicine) =>
            managementMedicine.pharmacy_medicine.master_code ===
            selectedMedicine?.master_code,
        );
      const selectedMedicineMasterCode = selectedMedicine.master_code;
      const selectedMedicineUnitList: string[] = [];
      const filteredMedicineListByMasterCode = searchMedicineList.filter(
        (item) => {
          if (item.master_code === selectedMedicineMasterCode) {
            const unit = item.product_total_quantity;
            selectedMedicineUnitList.push(unit);
            return item;
          }
          return null;
        },
      );
      const defaultMedicineListByMasterCode =
        filteredMedicineListByMasterCode.find((item) => item.default);
      selectedMedicineUnitList.sort((a, b) => Number(a) - Number(b));
      const selectedMedicineUnitSet: Set<string> = new Set(
        selectedMedicineUnitList,
      );

      if (defaultMedicineListByMasterCode) {
        if (medicineListResponseFilteredByMasterCode.length >= 0) {
          const alreadyExistMedicineList: Array<SelectedMedicineListFilteredByMasterCode | null> =
            filteredMedicineListByMasterCode
              .map((filteredMedicineByMasterCode) => {
                let alreadyExistMedicine: SelectedMedicineListFilteredByMasterCode | null =
                  null;
                medicineListResponseFilteredByMasterCode.forEach(
                  (medicineResponseFilteredByMasterCode) => {
                    if (
                      medicineResponseFilteredByMasterCode.pharmacy_medicine
                        .standard_code ===
                      filteredMedicineByMasterCode.standard_code
                    ) {
                      alreadyExistMedicine = {
                        ...filteredMedicineByMasterCode,
                        price: medicineResponseFilteredByMasterCode.price,
                        isAlreadyExist: true,
                      };
                    }
                  },
                );

                if (alreadyExistMedicine) {
                  return alreadyExistMedicine;
                }

                return null;
              })
              .filter((item) => item !== null);

          const isAlreadyExistMedicineUnitDefault =
            alreadyExistMedicineList.find(
              (isAlreadyExistMedicine) => isAlreadyExistMedicine?.default,
            );

          setSelectMedicineUnits(Array.from(selectedMedicineUnitSet));
          setSelectedMedicineListFilteredByMasterCode(
            filteredMedicineListByMasterCode,
          );
          setSelectMedicineDefaultUnit(
            defaultMedicineListByMasterCode?.product_total_quantity || '',
          );
          if (alreadyExistMedicineList.length === 0) {
            setMedicinePriceRowList([
              {
                ...defaultMedicineListByMasterCode,
                price: null,
                isDropDownOpen: false,
              },
            ]);
          } else if (isAlreadyExistMedicineUnitDefault) {
            const alreadyExistMedicineListTypeGuard =
              alreadyExistMedicineList as SelectedMedicineListFilteredByMasterCode[];
            const standardCodes = alreadyExistMedicineListTypeGuard.map(
              (alreadyExistMedicineTypeGuard) =>
                alreadyExistMedicineTypeGuard.standard_code,
            );
            const notYetExistFilteredMedicineListByMasterCode =
              filteredMedicineListByMasterCode.filter(
                (filteredMedicineByMasterCode) => {
                  if (
                    standardCodes.includes(
                      filteredMedicineByMasterCode.standard_code,
                    )
                  ) {
                    return false;
                  }

                  return filteredMedicineByMasterCode;
                },
              );

            if (notYetExistFilteredMedicineListByMasterCode.length > 0) {
              setMedicinePriceRowList([
                ...alreadyExistMedicineListTypeGuard,
                notYetExistFilteredMedicineListByMasterCode[0],
              ]);
            } else {
              setMedicinePriceRowList([...alreadyExistMedicineListTypeGuard]);
            }
          } else if (alreadyExistMedicineList.length > 0) {
            const alreadyExistMedicineListTypeGuard =
              alreadyExistMedicineList as SelectedMedicineListFilteredByMasterCode[];
            setMedicinePriceRowList([
              ...alreadyExistMedicineListTypeGuard,
              {
                ...defaultMedicineListByMasterCode,
                price: null,
                isDropDownOpen: false,
              },
            ]);
          }
        }
      }
    }
  }, [isShowMedicineUnitPrice, managementMedicineList, selectedMedicine]);

  useEffect(() => {
    init();
  }, [isVisible]);

  useEffect(() => {
    const hasEveryMedicinePriceRowPrice = medicinePriceRowList.every(
      (item) => item.price && String(item.price).length >= 3,
    );

    if (
      hasEveryMedicinePriceRowPrice &&
      selectedMedicine?.korean_product_name &&
      medicinePriceRowList.length > 0
    ) {
      setIsDisabledAddMedicineButton(false);
    } else {
      setIsDisabledAddMedicineButton(true);
    }
  }, [medicinePriceRowList, selectedMedicine]);

  useEffect(() => {
    const initMedicineUnits = selectedMedicineListFilteredByMasterCode.map(
      (item) => item.product_total_quantity,
    );
    const filteredUnits = initMedicineUnits.filter(
      (selectedMedicineUnit) =>
        !medicinePriceRowList.some(
          (medicinePriceRow) =>
            medicinePriceRow.product_total_quantity === selectedMedicineUnit,
        ),
    );
    setSelectMedicineUnits(filteredUnits);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [medicinePriceRowList]);

  return (
    <BModal
      open={isVisible}
      width={480}
      padding={30}
      onOpenChange={() => {
        init();
        closeRegisterModal();
      }}
    >
      <Style.ModalArea>
        <Style.TitleArea>
          <Font fontType="h1">의약품 추가</Font>
          <SvgIcon
            icon="xLg"
            width={16}
            height={16}
            color="fill/black"
            onClick={closeRegisterModal}
          />
        </Style.TitleArea>

        <Style.ModalBodyArea>
          <Style.ModalBodyWarningArea>
            <SvgIcon
              icon="infoIcon"
              color="state/distructive"
              width={16}
              height={16}
            />
            <Font fontType="body2" color="state/distructive">
              의약품 가격은 사입가가 아닌 조제료가 포함된 판매가로 등록해
              주세요.
            </Font>
          </Style.ModalBodyWarningArea>

          <MedicineCategory
            selectedMedicineCategory={selectedMedicineCategory}
            handleClickMedicineCategory={handleClickMedicineCategory}
          />

          <MedicineSearch
            selectedMedicineCategory={selectedMedicineCategory}
            medicineSearchInputValue={medicineSearchInputValue}
            selectedMedicine={selectedMedicine}
            handleChangeMedicineSearchInput={handleChangeMedicineSearchInput}
            handleSelectMedicine={handleSelectMedicine}
            handleClickSearchMedicineReset={handleClickSearchMedicineReset}
          />

          {isShowMedicineUnitPrice && (
            <MedicineUnitPrice
              isMedicineSumInputAreaFocus={isMedicineSumInputAreaFocus}
              selectedMedicine={selectedMedicine}
              selectMedicineUnits={selectMedicineUnits}
              medicinePriceRowList={medicinePriceRowList}
              selectedMedicineListFilteredByMasterCode={
                selectedMedicineListFilteredByMasterCode
              }
              handleClickMedicineUnit={handleClickMedicineUnit}
              handleClickUnitDropDownOpen={handleClickUnitDropDownOpen}
              handleChangeMedicineSumInput={handleChangeMedicineSumInput}
              handleAddMedicinePriceEachUnitRow={
                handleAddMedicinePriceEachUnitRow
              }
              handleDeleteMedicinePriceEachUnitRow={
                handleDeleteMedicinePriceEachUnitRow
              }
            />
          )}
        </Style.ModalBodyArea>

        <Style.ModalFooterArea>
          <Button
            width="204px"
            height="56px"
            borderRadius="12px"
            backgroundColor={COLOR['fill/white']}
            borderColor={COLOR['border/outline']}
            title={
              <Font fontType="body1" color="fill/dark">
                취소
              </Font>
            }
            onClick={closeRegisterModal}
          />
          <Button
            width="204px"
            height="56px"
            borderRadius="12px"
            backgroundColor={
              isDisabledAddMedicineButton
                ? COLOR['disabled/background']
                : COLOR['fill/accent']
            }
            title={
              <Font
                fontType="body1"
                color={
                  isDisabledAddMedicineButton
                    ? 'disabled/foreground'
                    : 'fill/white'
                }
              >
                추가하기
              </Font>
            }
            onClick={handleAddMedicine}
            disabled={isDisabledAddMedicineButton}
          />
        </Style.ModalFooterArea>
      </Style.ModalArea>
    </BModal>
  );
}

export default MedicineManagementModal;
