import { useAppDispatch } from '@stores/hooks';
import { closeModal_setFirstLoginModal } from '@stores/modalStore/setFirstLoginModal';
import { firstPasswordSetting } from '@stores/userInformationStore';
import { debounce } from 'lodash';
import { useCallback } from 'react';
import {
    SubmitErrorHandler,
    SubmitHandler,
    useFormContext,
} from 'react-hook-form';
import { IChangePasswordPasswordFormData } from '../change-password-form';

export const useChangePasswordSubmit = () => {
  const { handleSubmit } = useFormContext<IChangePasswordPasswordFormData>();
  const dispatch = useAppDispatch();

  const resetPassword = useCallback(
    async (password: string, passwordConfirm: string) => {
      dispatch(firstPasswordSetting({ password, passwordConfirm }));
    },
    [dispatch],
  );

  const onFormError: SubmitErrorHandler<IChangePasswordPasswordFormData> =
    useCallback((errors) => {
      console.log(errors);
    }, []);

  const onFormSubmit: SubmitHandler<IChangePasswordPasswordFormData> =
    useCallback(
      async (data) => {
        const { PASSWORD_CONFIRM, PASSWORD } = data;

        await resetPassword(PASSWORD, PASSWORD_CONFIRM);

        dispatch(closeModal_setFirstLoginModal());
      },
      [resetPassword, dispatch],
    );

  const submit = debounce(() => {
    handleSubmit(onFormSubmit, onFormError)();
  }, 500);

  return { submit };
};
