import { Font } from '@components/atoms';
import { CallOutMessage } from '@components/molecules';
import { WorkingTimeListItem } from '@pages/timeManagementPage/templates/sections';
import { UserInformationType } from 'types/index';

interface WorkingTimeListProps {
  workingTimes: UserInformationType.PharmacistWorkingTimeType[];
  pharmacyId: number;
}

export default function WorkingTimeList({
  pharmacyId,
  workingTimes,
}: WorkingTimeListProps) {
  return (
    <>
      {workingTimes?.length ? (
        workingTimes.map((workingTimeItem) => (
          <WorkingTimeListItem
            workingTimeItem={workingTimeItem}
            pharmacyId={pharmacyId}
            key={workingTimeItem.week_day}
          />
        ))
      ) : (
        <CallOutMessage>
          <Font fontType="body3">
            현재 영업시간 등록이 되어있지 않습니다. 영업시간을 추가하면 영업시간
            외엔 접수를 받지 않을 수 있습니다.
          </Font>
        </CallOutMessage>
      )}
    </>
  );
}
