import { mobileClose, mobileHamburger, mobileLogo } from '@assets/index';
import { PropsWithChildren, ReactNode, useLayoutEffect, useRef } from 'react';
import styled from 'styled-components';

type Props = {
  children: ReactNode;
  isOpen: boolean;
  onToggle: () => void;
};

function Header({ children, isOpen, onToggle }: PropsWithChildren<Props>) {
  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (ref.current) {
      document.documentElement.style.setProperty(
        '--header-height',
        `${ref.current.offsetHeight}px`,
      );
    }
  }, []);

  return (
    <Wrapper ref={ref}>
      <Container isOpen={isOpen}>
        <MobileLogo src={mobileLogo} />
        <ToggleIcon
          src={isOpen ? mobileClose : mobileHamburger}
          onClick={onToggle}
        />
      </Container>
      {isOpen ? children : null}
    </Wrapper>
  );
}

export default Header;

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  z-index: 100;
  background-color: #ffffff;
`;

const Container = styled.header<{ isOpen: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  height: 52px;
  border-bottom: ${({ isOpen }) =>
    `1px solid ${isOpen ? '#ffffff' : '#f1f2f4'}`};
`;

const MobileLogo = styled.img`
  width: 83px;
  height: 24px;
`;

const ToggleIcon = styled.img`
  width: 24px;
  height: 24px;
`;
